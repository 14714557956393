import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Logout from 'components/Logout'
import SmsServiceConsentDialog from 'components/SmsServiceConsentDialog'
import AdminTemplate from 'components/Template'
import actionsState from 'components/actionsState'
import Error404 from 'components/error404'
import GroupsHome, { getGroupsUrl } from 'components/groups'
import MachineDetails, { getMachineDetailsBaseUrl } from 'components/machineDetails'
import MachinesHome, { getMachinesUrl } from 'components/machines'
import MapView from 'components/mapView'
import NotificationsHome, { getNotificationsUrl } from 'components/notifications'
import NotificationsRulesSettingsHome, { getNotificationsRulesUrl } from 'components/notificationsRulesSettings'
import RolesHome, { getRolesUrl } from 'components/roles'
import UsersHome, { getUsersUrl } from 'components/users'
import ChartsHome, { getChartsUrl } from 'modules/charts'
import ConfigurationsHome, { getConfigurationsUrl } from 'modules/configurationsCS100'
import CS500ConfigurationsHome, { getCS500ConfigurationsUrl } from 'modules/configurationsCS500'
import Dashboards from 'modules/dashboards'
import DevicesHome, { getDevicesUrl } from 'modules/devices'
import GeofencesHome, { getGeofencesUrl } from 'modules/geofences'
import GpsTrackingHome from 'modules/geotracking'
import GroupDashboards, { GroupDashboardsEmpty } from 'modules/groupDashboards'
import Maintenances from 'modules/maintenances'
import { MAINTENANCES_TEMPLATE_URL } from 'modules/maintenances/urls'
import RegisterHome, { getRegisterUrl } from 'modules/register'
import ReportsHome, { getReportsUrl } from 'modules/reports'
import SentCANHistoric, { SENT_CAN_HISTORIC_BASE_URL } from 'modules/sentCANHistoric'
import SmsServiceConsent from 'modules/smsServiceConsent'
import { SMS_SERVICE_CONSENT_TEMPLATE_URL } from 'modules/smsServiceConsent/urls'

import ProfileLoader from './ProfileLoader'
import { getLastOpenURL, clearStoredURL } from '../../utils/urlStorage'
import * as R from '../constants'
import { getMapUrl, getGeotrackingRoute } from '../helper'

// `/(map|nodesOverview)` => to share the same MainMap instance and avoid not necessary
// mounts each time we change from a route to another.
// Why? To increase performance and to avoid flickering effect.
// NOTE: Ugly pattern because Switch does not accept proper Route inheritance.

const LoggedRoutes = ({
  cachedRegisterPath,
  canReadActionsState,
  canReadCharts,
  canReadConfig,
  canReadCsNodeDetails,
  canReadGeofence,
  canReadGeotracking,
  canReadGroups,
  canReadMap,
  canReadNodes,
  canReadNotifications,
  canReadNotificationsSettings,
  canReadReports,
  canReadRoles,
  canReadUsers,
  canViewDeviceDashboards,
  canViewGroupDashboards,
  history
}) => {
  const [lastOpenURL, setLastOpenURL] = useState(getLastOpenURL())

  useEffect(() => {
    const lastOpenedURL = getLastOpenURL()
    const { search = '' } = location || {}
    let groupId = ''
    if (lastOpenedURL.includes('?groupId=')) {
      ;[, groupId] = lastOpenedURL.split('?groupId=')
    } else if (search.startsWith('?groupId=')) {
      groupId = search.replace('?groupId=', '')
    }
    if (groupId) {
      sessionStorage.setItem('group_id', groupId)
      localStorage.setItem('group_id', groupId)
    }
    setLastOpenURL(lastOpenedURL)
    clearStoredURL()
  }, [getLastOpenURL, location])

  const unmatchRedirection = () => {
    let redirectionUrl = '/'
    if (canReadMap) {
      redirectionUrl = getMapUrl()
    } else if (canReadNodes) {
      redirectionUrl = getMachinesUrl()
    } else {
      redirectionUrl = getNotificationsUrl()
    }
    return redirectionUrl
  }

  const callbackRedirection = () => {
    if (lastOpenURL) {
      return lastOpenURL
    } else if (cachedRegisterPath && cachedRegisterPath.includes('register/signupcs10')) {
      return cachedRegisterPath + history.location.search
    } else {
      return '/'
    }
  }

  return (
    <ProfileLoader>
      <AdminTemplate>
        <Switch>
          {canReadNodes && <Route component={MachinesHome} exact path={getMachinesUrl()} />}
          {canReadMap && <Route component={MapView} exact path={getMapUrl()} />}
          {canViewDeviceDashboards && (
            <Route component={Dashboards} exact path='/nodes/:nodeFamily/:nodeId/dashboards' />
          )}
          {canViewGroupDashboards && <Route component={GroupDashboardsEmpty} exact path='/dashboards/' />}
          {canViewGroupDashboards && <Route component={GroupDashboards} exact path='/dashboards/:groupDashboardId' />}
          <Route component={NotificationsHome} path={getNotificationsUrl()} />
          {canReadActionsState && (
            <Route component={actionsState.components.ActionsState} exact path={R.ACTIONSSTATE} />
          )}
          {canReadCsNodeDetails && <Route component={MachineDetails} path={getMachineDetailsBaseUrl()} />}
          {canReadUsers && <Route component={UsersHome} path={getUsersUrl()} />}
          {canReadGroups && <Route component={GroupsHome} path={getGroupsUrl()} />}
          {canReadNotificationsSettings && (
            <Route component={NotificationsRulesSettingsHome} path={getNotificationsRulesUrl()} />
          )}
          {canReadRoles && <Route component={RolesHome} path={getRolesUrl()} />}
          {canReadGeofence && <Route component={GeofencesHome} path={getGeofencesUrl()} />}
          {canReadGeotracking && <Route component={GpsTrackingHome} path={getGeotrackingRoute()} />}
          {canReadReports && <Route component={ReportsHome} path={getReportsUrl()} />}
          {canReadCharts && <Route component={ChartsHome} path={getChartsUrl()} />}
          <Route component={RegisterHome} path={getRegisterUrl()} />
          {canReadConfig && <Route component={ConfigurationsHome} path={getConfigurationsUrl()} />}
          {canReadConfig && <Route component={CS500ConfigurationsHome} path={getCS500ConfigurationsUrl()} />}
          {canReadNodes && <Route component={DevicesHome} path={getDevicesUrl()} />}

          {process.env.REACT_APP_MAINTENANCES_ENABLED && canReadNotifications && (
            <Route component={Maintenances} path={MAINTENANCES_TEMPLATE_URL} />
          )}
          {canViewDeviceDashboards && <Route component={SentCANHistoric} exact path={SENT_CAN_HISTORIC_BASE_URL} />}
          <Route component={SmsServiceConsent} path={SMS_SERVICE_CONSENT_TEMPLATE_URL} />
          <Route component={Logout} exact path={R.LOGOUT} />
          <Redirect exact from='/callback' to={callbackRedirection()} />
          <Redirect exact from='/' to={unmatchRedirection()} />
          <Route component={Error404} path='*' />
        </Switch>
        <SmsServiceConsentDialog />
      </AdminTemplate>
    </ProfileLoader>
  )
}

LoggedRoutes.propTypes = {
  cachedRegisterPath: PropTypes.string.isRequired,
  canReadActionsState: PropTypes.bool.isRequired,
  canReadCharts: PropTypes.bool.isRequired,
  canReadConfig: PropTypes.bool.isRequired,
  canReadCsNodeDetails: PropTypes.bool.isRequired,
  canReadGeofence: PropTypes.bool.isRequired,
  canReadGeotracking: PropTypes.bool.isRequired,
  canReadGroups: PropTypes.bool.isRequired,
  canReadMap: PropTypes.bool.isRequired,
  canReadNodes: PropTypes.bool.isRequired,
  canReadNotifications: PropTypes.bool.isRequired,
  canReadNotificationsSettings: PropTypes.bool.isRequired,
  canReadReports: PropTypes.bool.isRequired,
  canReadRoles: PropTypes.bool.isRequired,
  canReadUsers: PropTypes.bool.isRequired,
  canViewDeviceDashboards: PropTypes.bool.isRequired,
  canViewGroupDashboards: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
}

export { LoggedRoutes }
