import { defineMessages } from 'react-intl'

const namespace = 'machineDetails'

const messages = defineMessages({
  error400Message: {
    id: `${namespace}.error400Message`,
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: `${namespace}.error403Message`,
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: `${namespace}.error406Message`,
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    defaultMessage: 'No description available.'
  },
  error409Message: {
    id: `${namespace}.error409Message`,
    defaultMessage: 'Data integrity violation: Configuration name it`s already in use'
  },
  error415Message: {
    id: `${namespace}.error415Message`,
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: `${namespace}.error422Message`,
    defaultMessage: 'Validation failed'
  },
  error500Message: {
    id: `${namespace}.error500Message`,
    defaultMessage: 'Validation failed'
  },
  error: {
    id: `${namespace}.error`,
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    defaultMessage: 'Invalid credentials'
  },
  return: {
    id: `${namespace}.return`,
    defaultMessage: 'Return'
  },
  nodeInformation: {
    id: `${namespace}.nodeInformation`,
    defaultMessage: 'Device information'
  },
  activityInformation: {
    id: `${namespace}.activityInformation`,
    defaultMessage: 'Activity information'
  },
  dataPlanInformation: {
    id: `${namespace}.dataPlanInformation`,
    defaultMessage: 'Data plan information'
  },
  signals: {
    id: `${namespace}.signals`,
    defaultMessage: 'Signals'
  },
  configuration: {
    id: `${namespace}.configuration`,
    defaultMessage: 'Configuration'
  },
  lastTelemetryMessage: {
    id: `${namespace}.lastTelemetryMessage`,
    defaultMessage: 'Last telemetry message'
  },
  deviceFirmwareLabel: {
    id: `${namespace}.deviceFirmwareLabel`,
    defaultMessage: 'Device firmware'
  },
  radioFirmwareLabel: {
    id: `${namespace}.radioFirmwareLabel`,
    defaultMessage: 'Radio firmware'
  },
  name: {
    id: `${namespace}.name`,
    defaultMessage: 'Name'
  },
  value: {
    id: `${namespace}.value`,
    defaultMessage: 'Value'
  },
  lastUpdated: {
    id: `${namespace}.lastUpdated`,
    defaultMessage: 'Last updated'
  },
  firstActivityTime: {
    id: `${namespace}.firstActivityTime`,
    defaultMessage: 'First activity time'
  },
  lastActivityTime: {
    id: `${namespace}.lastActivityTime`,
    defaultMessage: 'Last activity time'
  },
  totalActivityTime: {
    id: `${namespace}.totalActivityTime`,
    defaultMessage: 'Total activity time'
  },
  cloudConnection: {
    id: `${namespace}.cloudConnection`,
    defaultMessage: 'Cloud connection'
  },
  crcErrors: {
    id: `${namespace}.crcErrors`,
    defaultMessage: 'CRC Errors'
  },
  category: {
    id: `${namespace}.category`,
    defaultMessage: 'Category'
  },
  description: {
    id: `${namespace}.description`,
    defaultMessage: 'Error Description'
  },
  date: {
    id: `${namespace}.date`,
    defaultMessage: 'Date'
  },
  dm1: {
    id: `${namespace}.dm1`,
    defaultMessage: 'DM1 Messages'
  },
  spn: {
    id: `${namespace}.spn`,
    defaultMessage: 'SPN'
  },
  fmi: {
    id: `${namespace}.fmi`,
    defaultMessage: 'FMI'
  },
  address: {
    id: `${namespace}.address`,
    defaultMessage: 'Address'
  },
  oc: {
    id: `${namespace}.oc`,
    defaultMessage: 'OC'
  },
  noDM1MessagesAvailable: {
    id: `${namespace}.noDM1MessagesAvailable`,
    defaultMessage: 'No DM1 messages available'
  },
  noCRCMessagesAvailable: {
    id: `${namespace}.noCRCMessagesAvailable`,
    defaultMessage: 'No CRC error messages available'
  },
  prePage: {
    id: `${namespace}.prePage`,
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    defaultMessage: 'Last'
  },
  advancedSignals: {
    id: `${namespace}.advancedSignals`,
    defaultMessage: 'Advanced signals'
  },
  configureYourSignals: {
    id: `${namespace}.configureYourSignals`,
    defaultMessage: 'Configure your signals'
  },
  openConfiguration: {
    id: `${namespace}.openConfiguration`,
    defaultMessage: 'Open configuration'
  },
  firmware: {
    id: `${namespace}.firmware`,
    defaultMessage: 'Firmware'
  },
  remoteFirmwareUpdateStatus: {
    id: `${namespace}.remoteFirmwareUpdateStatus`,
    defaultMessage: 'Remote firmware update status'
  },
  requestedStep: {
    id: `${namespace}.requestedStep`,
    defaultMessage: 'Requested'
  },
  downloadingStep: {
    id: `${namespace}.downloadingStep`,
    defaultMessage: 'Downloading'
  },
  manualRebootRequiredStep: {
    id: `${namespace}.manualRebootRequiredStep`,
    defaultMessage: 'Manual reboot required'
  },
  installingStep: {
    id: `${namespace}.installingStep`,
    defaultMessage: 'Installing'
  },
  completedStep: {
    id: `${namespace}.completedStep`,
    defaultMessage: 'Completed'
  },
  notAttempted: {
    id: `${namespace}.notAttempted`,
    defaultMessage: 'Not attempted'
  },
  failedStep: {
    id: `${namespace}.failedFirmware`,
    defaultMessage: 'Failed'
  },
  deviceTypeLabel: {
    id: `${namespace}.deviceTypeLabel`,
    defaultMessage: 'Device type'
  },
  machineInformation: {
    id: `${namespace}.machineInformation`,
    defaultMessage: 'Machine information'
  },
  model: {
    id: `${namespace}.model`,
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    defaultMessage: 'Machine type'
  },
  brand: {
    id: `${namespace}.brand`,
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    defaultMessage: 'Machine serial number'
  },
  title: {
    id: `${namespace}.title`,
    defaultMessage: 'Machine details'
  },
  mobileSignal: {
    id: `${namespace}.mobileSignal`,
    defaultMessage: 'Mobile signal'
  },
  lastGPSMessage: {
    id: `${namespace}.lastGPSMessage`,
    defaultMessage: 'Last GPS message'
  },
  lastGPSMessageOlderThan24Hours: {
    id: `${namespace}.lastGPSMessageOlderThan24Hours`,
    defaultMessage: 'The last GPS message is older than 24 hours'
  }
})

export default messages
