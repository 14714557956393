import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import SentCANHistoric from './SentCANHistoric'
import { SENT_CAN_HISTORIC_BASE_URL } from './urls'

const SentCANHistoricHome = props => {
  const { canViewDeviceDashboards } = props
  return (
    <Switch>
      {canViewDeviceDashboards && <Route component={SentCANHistoric} exact path={SENT_CAN_HISTORIC_BASE_URL} />}
    </Switch>
  )
}

SentCANHistoricHome.propTypes = {
  canViewDeviceDashboards: PropTypes.bool.isRequired
}

export default SentCANHistoricHome
