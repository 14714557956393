import axios from 'axios'
import { createAction } from 'redux-actions'

import { actions } from 'ducks/login'
import { actions as themeActions } from 'ducks/theme'
import { actions as hactions, client, logError } from 'utils/http'

import * as aT from './actionTypes'
import { getSessionGroupId, getLastGroupId } from './reducer/cache'

const groupsLoaded = createAction(aT.GROUPS_LOADED, groups => groups)

const fetchGroupHierarchy = () => dispatch => {
  const { promise } = dispatch(hactions.getGroupHierarchy())
  return promise
}

const fetchGroupHierarchyWithNotifications = () => dispatch => {
  const { promise } = dispatch(hactions.getGroupHierarchyWithNotifications())
  return promise
}

const getPaginatedGroups = (limit, offset) => dispatch =>
  dispatch(hactions.getGroups(limit, offset)).promise.then(({ payload }) => payload)

const getGroups = () => dispatch =>
  (async () => {
    const limit = 10
    let offset = 0
    let groups = []
    let numOfTries = 1
    /* eslint-disable no-loop-func */
    while (groups.length === 0 && numOfTries < 4) {
      try {
        const groupsResponse = await dispatch(getPaginatedGroups(limit, offset))
        const { groups: responseGroups, total, count } = groupsResponse.data
        groups = groups.concat(responseGroups)
        if (count < total) {
          offset += count
          const groupRequests = []
          while (offset < total) {
            groupRequests.push(dispatch(getPaginatedGroups(limit, offset)))
            offset += limit
          }
          const groupsResponses = await Promise.all(groupRequests)
          // eslint-disable-next-line no-shadow
          groupsResponses.forEach(groupsResponse => {
            groups = groups.concat(groupsResponse.data.groups)
          })
        }
        let currentGroup = groups.find(group => group.id === getSessionGroupId())
        if (typeof currentGroup === 'undefined') currentGroup = groups.find(group => group.id === getLastGroupId())
        if (typeof currentGroup === 'undefined') {
          currentGroup = groups.find(group => group.group_name.toUpperCase() === 'DANFOSS DEFAULT GROUP')
        }
        await client.getToken(currentGroup.id)
      } catch (e) {
        groups = []
        offset = 0
        if (numOfTries === 3) {
          dispatch(actions.unauthorize())
        }
        numOfTries += 1
      }
    }
    /* eslint-enable no-loop-func */
    dispatch(groupsLoaded({ groups }))
    return Promise.resolve({ data: { group: groups } })
  })()

const getUsersOfGroup = groupName => dispatch =>
  dispatch(hactions.getUsersOfGroup(groupName)).promise.then(({ payload }) => payload)

const chunkArray = (myArray, chunk_size) => {
  var index = 0
  var arrayLength = myArray.length
  var tempArray = []

  let myChunk = []
  for (index = 0; index < arrayLength; index += chunk_size) {
    myChunk = myArray.slice(index, index + chunk_size)
    tempArray.push(myChunk)
  }

  return tempArray
}

const getUsersOfGroupDetailsCall = (groupId, usersEmails) => dispatch => {
  const { promise } = dispatch(hactions.getGroupUsersDetails(groupId, usersEmails))
  return promise.then(({ payload }) => {
    return payload
  })
}

const getUsersOfGroupDetails = (groupId, usersEmails) => dispatch =>
  (async () => {
    //NOTE: Avoid to request more than 10 users details in one call
    const numMaxOfUsers = 10
    let users = []
    if (usersEmails.length <= numMaxOfUsers) {
      const usersResponse = await dispatch(getUsersOfGroupDetailsCall(groupId, usersEmails))
      users = usersResponse.data.users
    } else {
      const usersEmailsArrays = chunkArray(usersEmails, numMaxOfUsers)
      try {
        const usersDetailsRequests = []
        let numOfUsersDetailsArraysTreated = 0
        while (numOfUsersDetailsArraysTreated < usersEmailsArrays.length) {
          const currentArray = usersEmailsArrays[numOfUsersDetailsArraysTreated]
          usersDetailsRequests.push(dispatch(getUsersOfGroupDetailsCall(groupId, currentArray)))
          numOfUsersDetailsArraysTreated = numOfUsersDetailsArraysTreated + 1
        }

        const usersDetailsResponses = await Promise.all(usersDetailsRequests)
        usersDetailsResponses.forEach(usersDetailsResponse => {
          users = users.concat(usersDetailsResponse.data.users)
        })
      } catch (e) {
        users = []
        return users
      }
    }

    return Promise.resolve({ data: { users } })
  })()

const createGroup = (parentGroupId, groupName) => dispatch =>
  dispatch(hactions.createGroup(parentGroupId, groupName)).promise.then(({ payload }) => payload)

const updateGroup = (groupId, groupName) => dispatch =>
  dispatch(hactions.updateGroup(groupId, groupName)).promise.then(({ payload }) => payload)

const deleteGroup = groupId => dispatch =>
  dispatch(hactions.deleteGroup(groupId)).promise.then(({ payload }) => payload)

const addUsersToGroup = (groupId, users, permissions) => dispatch =>
  dispatch(hactions.addUsersToGroup(groupId, users, permissions)).promise.then(({ payload }) => payload)

const removeUsersFromGroup = (groupId, users) => dispatch =>
  dispatch(hactions.removeUsersFromGroup(groupId, users)).promise.then(({ payload }) => payload)

const changeGroup = groupId => dispatch => {
  dispatch(hactions.getGroups()).promise.then(({ payload }) => payload)
  // eslint-disable-next-line no-shadow
  client
    .getToken(groupId)
    .then(() => {
      return client.getGroupTheme(groupId)
    })
    .then(({ data }) =>
      data.file ? client.getGroupThemeConfigUrl(groupId, data.hashId) : Promise.reject('No theme config file')
    )
    .then(({ data: url }) => client.getGroupThemeConfig(url))
    .then(response => {
      dispatch(themeActions.setTheme(response.data))
    })
    .catch(error => {
      dispatch(themeActions.setTheme({}))
      logError(error)
    })
    .finally(() => {
      dispatch(createAction(aT.GROUP_CHANGE, selectedGroupId => selectedGroupId)({ groupId }))
    })
}

const addDevicesToGroup = (groupId, deviceIds, deviceType, originGroupId) => dispatch =>
  dispatch(hactions.addDevicesToGroup(groupId, deviceIds, deviceType, originGroupId)).promise.then(
    ({ payload }) => payload
  )

const removeDevicesFromGroup = (groupId, deviceIds, deviceType) => dispatch =>
  dispatch(hactions.removeDevicesFromGroup(groupId, deviceIds, deviceType)).promise.then(({ payload }) => payload)

const getUserDetails = (limit, offset) => async dispatch => {
  const email = localStorage.getItem('email')
  const encodedEmail = encodeURIComponent(email)
  const accessTokenDIPProfileAPI = localStorage.getItem('dip_token')
  let userInfoResponse
  if (accessTokenDIPProfileAPI) {
    userInfoResponse = await axios.get(`${process.env.REACT_APP_DIP_API}/users/${encodedEmail}?user_id_type=EMAIL`, {
      headers: {
        Authorization: `Bearer ${accessTokenDIPProfileAPI}`,
        'Content-Type': 'application/json-patch+json; charset=utf-8'
      }
    })
  }

  const firstName = userInfoResponse?.data?.first_name || ''
  const lastName = userInfoResponse?.data?.last_name || ''
  return dispatch(hactions.getUserDetails(limit, offset, firstName, lastName)).promise.then(({ payload }) => payload)
}

const getAllRolesOfGroup = groupId => dispatch =>
  dispatch(hactions.getAllRolesOfGroup(groupId)).promise.then(({ payload }) => payload)

const createRole = (groupId, roleName, permissions) => dispatch =>
  dispatch(hactions.createRole(groupId, roleName, permissions)).promise.then(({ payload }) => payload)

const getDetailsOfRole = (groupId, roleId) => dispatch =>
  dispatch(hactions.getDetailsOfRole(groupId, roleId)).promise.then(({ payload }) => payload)

const updateRole = (groupId, groupRoleId, roleName, permissions) => dispatch =>
  dispatch(hactions.updateRole(groupId, groupRoleId, roleName, permissions)).promise.then(({ payload }) => payload)

const assignRoleToUser = (groupId, groupRoleId, users) => dispatch =>
  dispatch(hactions.assignRoleToUser(groupId, groupRoleId, users)).promise.then(({ payload }) => payload)

const getDetailedUsersOfGroup = (groupId, limit, offset) => dispatch =>
  dispatch(hactions.getDetailedUsersOfGroup(groupId, limit, offset)).promise.then(({ payload }) => payload)

const deleteRole = (groupId, roleId) => dispatch =>
  dispatch(hactions.deleteRole(groupId, roleId)).promise.then(({ payload }) => payload)

const addUsersToGroupWithRole = (groupId, groupRoleId, users) => dispatch =>
  dispatch(hactions.addUsersToGroupWithRole(groupId, groupRoleId, users)).promise.then(({ payload }) => payload)

export {
  fetchGroupHierarchy,
  fetchGroupHierarchyWithNotifications,
  getPaginatedGroups,
  getGroups,
  getUsersOfGroup,
  getUsersOfGroupDetails,
  createGroup,
  updateGroup,
  deleteGroup,
  addUsersToGroup,
  removeUsersFromGroup,
  changeGroup,
  addDevicesToGroup,
  removeDevicesFromGroup,
  getUserDetails,
  getAllRolesOfGroup,
  createRole,
  getDetailsOfRole,
  updateRole,
  assignRoleToUser,
  getDetailedUsersOfGroup,
  deleteRole,
  addUsersToGroupWithRole
}
