import { defineMessages } from 'react-intl'

const namespace = 'MobileSignal'

const messages = defineMessages({
  noSignal: {
    id: `${namespace}.noSignal`,
    defaultMessage: 'No signal'
  },
  poor: {
    id: `${namespace}.poor`,
    defaultMessage: 'Poor'
  },
  average: {
    id: `${namespace}.average`,
    defaultMessage: 'Average'
  },
  good: {
    id: `${namespace}.good`,
    defaultMessage: 'Good'
  },
  excellent: {
    id: `${namespace}.excellent`,
    defaultMessage: 'Excellent'
  },
  great: {
    id: `${namespace}.great`,
    defaultMessage: 'Great'
  },
  unknown: {
    id: `${namespace}.unknown`,
    defaultMessage: 'Unknown'
  }
})
export default messages
