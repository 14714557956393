import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

const PageTitle = ({ title = '', button, back }) => {
  const showButton = () => {
    if (button !== null) {
      if (button.primary === undefined) {
        button.primary = true // eslint-disable-line no-param-reassign
      }
      return (
        <Link
          className='button-link'
          style={{ ...button.disabled && { cursor: 'default', pointerEvents: 'none' } }}
          to={button.path}
        >
          <Button className='page-title-button' disabled={button.disabled} style={{ marginTop: 20 }}>
            {button.icon && <Icon className={'zmdi ' + button.icon} />}
            {button.label}
          </Button>
        </Link>
      )
    } else {
      return null
    }
  }

  const buttonToShow = showButton()
  return (
    <div style={{ marginRight: 0, marginLeft: 0 }}>
      {back ? (
        <Link className='text-link' style={{ textDecoration: 'none' }} to={back}>
          <div style={{ float: 'left', width: 'fit-content' }}>
            <h1>
              <ArrowBackIosIcon style={{ fontSize: 20 }} />
              {title}
            </h1>
          </div>
        </Link>
      ) : (
        <div style={{ float: 'left', width: 'fit-content' }}>
          <h1>{title}</h1>
        </div>
      )}
      {buttonToShow !== null ? <div style={{ float: 'right' }}>{buttonToShow}</div> : null}
    </div>
  )
}

PageTitle.propTypes = {
  back: PropTypes.string,
  button: PropTypes.any,
  title: PropTypes.string.isRequired
}

PageTitle.defaultProps = {
  back: '',
  button: null
}

export default PageTitle
