import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getMachinesUrl } from 'components/machines'
import { isEditing, finishEditDashboard, getStaticData, getDashboardSettings } from 'ducks/dashboards'
import { utils as groupUtils } from 'ducks/groups'
import { utils as themeUtils } from 'ducks/theme'
import { utils } from 'ducks/websocket'
import * as c from 'utils/constants'

import Dashboards from './Dashboards'

const mapStateToProps = (state, ownProps) => {
  return {
    editing: isEditing(state),
    eid: getStaticData(state)[1]?.value || '',
    isSubscribedTo: (nodeId, topic) => utils.isSubscribedTo(state, nodeId, topic),
    settings: getDashboardSettings(state),
    groupId: groupUtils.getSelectedGroup(state).id,
    canCreateDeviceDashboards: groupUtils.hasPermission(state, c.DEVICEDASHBOARD_CREATE),
    nodesUrl: getMachinesUrl(),
    dashboardsBarColor: themeUtils.getSideBarColor(state),
    fontColor: themeUtils.getFontColor(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    finishEditDashboard: () => dispatch(finishEditDashboard())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboards))

export { getDashboardsUrl } from './urls'
