import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { logError } from 'utils/http'

import messages from './messages'

const styles = {
  groupLabel: {
    color: 'black',
    fontWeight: 'bold'
  },
  checkbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  checked: {}
}

class DM1TableSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.columnsOptions = [
      { name: 'Name', id: 'name', columnType: 'machine' },
      { name: 'EID', id: 'eid', columnType: 'machine' },
      { name: 'Date', id: 'timestamp', columnType: 'dm1' },
      { name: 'SPN', id: 'SPN', columnType: 'dm1' },
      { name: 'Address', id: 'ADDRESS', columnType: 'dm1' },
      { name: 'FMI', id: 'FMI', columnType: 'dm1' },
      { name: 'OC', id: 'OC', columnType: 'dm1' }
    ]

    this.state = {
      isLoading: true,
      devices: []
    }
  }

  componentDidMount() {
    this.getCS500Devices()
  }

  getCS500Devices = () => {
    const { getDynamicCSNodes, groupId, isGroupWidget, onSettingsChange, columns } = this.props
    const filters = {
      filters: {
        models: [
          {
            modelName: 'Device',
            columns: [
              {
                device_type: 'CS500'
              }
            ]
          }
        ]
      }
    }
    const deviceFields = {
      Device: ['eid', 'name', 'device_type']
    }

    this.setState(
      {
        isLoading: true
      },
      () => {
        getDynamicCSNodes(groupId, deviceFields, filters)
          .then(response => {
            const { devices } = response.data
            this.setState({
              devices
            })
            const allColumnsSelected = columns.length === this.columnsOptions.length
            const eids = devices.map(device => device.eid)
            onSettingsChange({ allColumnsSelected, ...isGroupWidget && { eids } })
          })
          .catch(error => {
            logError(error)
          })
          .finally(() => {
            this.setState({
              isLoading: false
            })
          })
      }
    )
  }

  handleDevicesChange = (event, devices) => {
    const { onSettingsChange } = this.props
    const eids = devices.map(device => device.eid)
    onSettingsChange({
      eids,
      eidsErrorText: '',
      isGroupWidget: false,
      ...eids.length === 0 && { columns: [], columnsErrorText: '', allColumnsSelected: false }
    })
  }

  handleIsGroupWidgetChange = event => {
    const { onSettingsChange } = this.props
    const { devices } = this.state
    const isGroupWidget = event.target.checked
    const eids = devices.map(device => device.eid)
    onSettingsChange({
      isGroupWidget,
      ...isGroupWidget && { eids, eidsErrorText: '' }
    })
  }

  handleColumnsChange = (event, newColumns) => {
    const { onSettingsChange } = this.props
    const notAllColumnsSelected = newColumns.length < this.columnsOptions.length
    const columns = newColumns.map(({ name, id, columnType }) => ({ name, id, columnType }))
    onSettingsChange({ columns, columnsErrorText: '', ...notAllColumnsSelected && { allColumnsSelected: false } })
  }

  handleAllColumnsSelected = event => {
    const { columns: currentColumns, onSettingsChange } = this.props
    const allColumnsSelected = event.target.checked
    const columns = allColumnsSelected ? [...this.columnsOptions] : [...currentColumns]
    onSettingsChange({
      columns,
      columnsErrorText: '',
      allColumnsSelected
    })
  }

  render() {
    const { classes, columns, columnsErrorText, isGroupWidget, eids, eidsErrorText, allColumnsSelected } = this.props
    const { isLoading, devices } = this.state
    const groupedColumns = this.columnsOptions.map(column => {
      const columnGroupType = this.formatMessage(messages[column.columnType + 'Fields'])
      return { ...column, columnGroupType }
    })

    return isLoading ? (
      <Grid container justify='center'>
        <CircularProgress />
      </Grid>
    ) : (
      <Grid container spacing={2}>
        <Grid item md={4} xs={8}>
          {isGroupWidget ? (
            <TextField
              disabled
              fullWidth
              helperText={this.formatMessage(messages.machinesHelperText)}
              label={this.formatMessage(messages.machines)}
              value={this.formatMessage(messages.allMachinesSelected)}
            />
          ) : (
            <Autocomplete
              fullWidth
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.eid === value.eid}
              id='devices'
              multiple
              onChange={this.handleDevicesChange}
              options={devices}
              renderInput={params => (
                <TextField
                  {...params}
                  error={eidsErrorText !== ''}
                  helperText={eidsErrorText}
                  label={this.formatMessage(messages.machines)}
                />
              )}
              value={devices.filter(device => eids.includes(device.eid))}
            />
          )}
        </Grid>
        <Grid item md={2} xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isGroupWidget}
                classes={{ root: classes.checkbox, checked: classes.checked }}
                onChange={this.handleIsGroupWidgetChange}
              />
            }
            label={this.formatMessage(messages.allMachines)}
          />
        </Grid>
        <Grid item md={4} xs={8}>
          <Autocomplete
            classes={{ groupLabel: classes.groupLabel }}
            disabled={eids.length === 0}
            fullWidth
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            groupBy={option => option.columnGroupType}
            id='columns'
            multiple
            onChange={this.handleColumnsChange}
            options={groupedColumns}
            renderInput={params => (
              <TextField
                {...params}
                error={columnsErrorText !== ''}
                helperText={columnsErrorText}
                label={this.formatMessage(messages.columns)}
              />
            )}
            value={groupedColumns.filter(column => columns.map(({ id }) => id).includes(column.id))}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={allColumnsSelected}
                classes={{ root: classes.checkbox, checked: classes.checked }}
                disabled={eids.length === 0}
                onChange={this.handleAllColumnsSelected}
              />
            }
            label={this.formatMessage(messages.allColumns)}
          />
        </Grid>
      </Grid>
    )
  }
}

DM1TableSettings.propTypes = {
  allColumnsSelected: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  columnsErrorText: PropTypes.string.isRequired,
  eids: PropTypes.array.isRequired,
  eidsErrorText: PropTypes.string.isRequired,
  getDynamicCSNodes: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isGroupWidget: PropTypes.bool.isRequired,
  onSettingsChange: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(DM1TableSettings))
