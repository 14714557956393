import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link as RouterLink } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/CancelOutlined'
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
import SendIcon from '@material-ui/icons/Send'

import { getSentCANHistoricUrl } from 'modules/sentCANHistoric/urls'

import messages from './messages'
import { getDashboardsUrl } from '../../urls'

const styles = (theme, props) => {
  return {}
}

class SendCANMessage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isDelivering: false,
      status: '',
      isDialogOpen: false
    }
  }

  handleDeliverClick = () => {
    const {
      data: { settings }
    } = this.props
    if (settings?.isConfirmationChecked) this.setState({ isDialogOpen: true })
    else this.handleDeliver()
  }

  handleConfirmationClick = () => {
    this.handleCloseDialog()
    this.handleDeliver()
  }

  handleDeliver = () => {
    const {
      eid,
      setCS500ValuePulse,
      data: { settings },
      groupId
    } = this.props
    this.setState({ isDelivering: true, status: '' })
    const { selectedMessage, responseTimeoutValue: responseTimeOut } = settings
    const valuePulse = 'SendCANmsg' + (selectedMessage + 1)
    setCS500ValuePulse(eid, valuePulse, { data: true }, { groupId, responseTimeOut })
      .then(() => {
        this.setState({ status: 'delivered' })
      })
      .catch(() => {
        this.setState({ status: 'failed' })
      })
      .finally(() => {
        this.setState({ isDelivering: false })
      })
  }

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false })
  }

  render() {
    const {
      data: { settings },
      intl,
      editing,
      staticData
    } = this.props
    const { isDelivering, status, isDialogOpen } = this.state

    const { value: deviceName = '' } = staticData.find(dataField => dataField.name === 'name') || {}
    const { value: deviceId = '' } = staticData.find(dataField => dataField.name === 'id')

    const messageId = 'SendCANmsg' + (settings.selectedMessage + 1)

    if (typeof settings?.selectedMessage === 'number') {
      return (
        <React.Fragment>
          <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12}>
              <Button
                disabled={editing || isDelivering}
                fullWidth
                onClick={this.handleDeliverClick}
                style={
                  isDelivering
                    ? { backgroundColor: '#E9E9E9', color: '#C8C8C8' }
                    : { backgroundColor: settings.buttonColor, color: 'white' }
                }
              >
                <SendIcon fontSize='small' style={{ marginRight: 5 }} />
                {settings.nameValue}
                {isDelivering && (
                  <CircularProgress size={28} style={{ color: settings.buttonColor, position: 'absolute' }} />
                )}
              </Button>
            </Grid>
            {status && (
              <Grid item style={{ padding: 0 }} xs={12}>
                {status === 'delivered' ? (
                  <Grid alignItems='center' container justifyContent='center' spacing={1} style={{ color: '#009541' }}>
                    <Grid item>
                      <CheckIcon />
                    </Grid>
                    <Grid item style={{ marginTop: -2, marginLeft: -2 }}>
                      {intl.formatMessage(messages.delivered)}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid alignItems='center' container justifyContent='center' spacing={1} style={{ color: '#D9534F' }}>
                    <Grid item>
                      <CancelIcon />
                    </Grid>
                    <Grid item style={{ marginTop: -2, marginLeft: -2 }}>
                      {intl.formatMessage(messages.failed)}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider light />
            </Grid>
            <Grid item xs={12}>
              {editing ? (
                <div style={{ textAlign: 'center' }}>{intl.formatMessage(messages.sentHistory)}</div>
              ) : (
                <Link
                  color='#337aa8'
                  component={RouterLink}
                  style={{ textAlign: 'center' }}
                  to={{
                    pathname: getSentCANHistoricUrl(deviceId),
                    state: { from: getDashboardsUrl(deviceId), messageId }
                  }}
                >
                  {intl.formatMessage(messages.sentHistory)}
                </Link>
              )}
            </Grid>
          </Grid>
          {settings?.isConfirmationChecked && (
            <Dialog
              fullWidth
              keepMounted
              maxWidth='sm'
              onClose={this.handleCloseDialog}
              open={isDialogOpen}
              scroll='paper'
            >
              <DialogTitle>
                {intl.formatMessage(messages.sendCANMessage)}
                <IconButton
                  onClick={this.handleCloseDialog}
                  style={{
                    position: 'absolute',
                    right: 3,
                    top: 3
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {intl.formatMessage(messages.confirmationText, { messageName: settings.nameValue, deviceName })}
              </DialogContent>
              <DialogActions>
                <Button className='cancel-button' onClick={this.handleCloseDialog}>
                  {intl.formatMessage(messages.cancel)}
                </Button>
                <Button className='primary-action-button' onClick={this.handleConfirmationClick}>
                  {intl.formatMessage(messages.send)}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </React.Fragment>
      )
    } else
      return (
        <div className='notConfigured'>
          <Typography gutterBottom={false}>{intl.formatMessage(messages.widgetNotConfigured)}</Typography>
        </div>
      )
  }
}

SendCANMessage.propTypes = {
  data: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  eid: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  setCS500ValuePulse: PropTypes.func.isRequired,
  staticData: PropTypes.array.isRequired
}

export default withStyles(styles)(injectIntl(SendCANMessage))
