import { defineMessages } from 'react-intl'

const namespace = 'Notifications'

const messages = defineMessages({
  title: {
    id: `${namespace}.title`,
    description: 'Notifications title',
    defaultMessage: 'Notifications'
  },
  actions: {
    id: `${namespace}.actions`,
    description: 'Actions table header column',
    defaultMessage: 'Actions'
  },
  viewActionDetail: {
    id: `${namespace}.viewActionDetail`,
    description: 'View action detail button label',
    defaultMessage: 'View action detail'
  },
  closeNotification: {
    id: `${namespace}.closeNotification`,
    description: 'Close notification button label',
    defaultMessage: 'Close notification'
  },
  reopenNotification: {
    id: `${namespace}.reopenNotification`,
    description: 'Reopen notification button label',
    defaultMessage: 'Reopen notification'
  },
  acknowledgeNotification: {
    id: `${namespace}.acknowledgeNotification`,
    description: 'Acknowledge notification button label',
    defaultMessage: 'Acknowledge notification'
  },
  unacknowledgeNotification: {
    id: `${namespace}.unacknowledgeNotification`,
    description: 'Unacknowledge notification button label',
    defaultMessage: 'Unacknowledge notification'
  },
  showingRows: {
    id: `${namespace}.showingRows`,
    description: 'Notifications table total text showing rows',
    defaultMessage: 'Showing notifications'
  },
  toTable: {
    id: `${namespace}.toTable`,
    description: 'Notifications table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Notifications table total text of',
    defaultMessage: 'of'
  },
  noNotificationsAvailable: {
    id: `${namespace}.noNotificationsAvailable`,
    description: 'No notifications available warning text',
    defaultMessage: 'No notifications available'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Notifications previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Notifications next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'Notifications first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Notifications last page button text',
    defaultMessage: 'Last'
  },
  filterByOrderableColumns: {
    id: `${namespace}.filterByOrderableColumns`,
    description: 'Filter by orderable columns placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  date: {
    id: `${namespace}.date`,
    description: 'Date table header column label',
    defaultMessage: 'Date'
  },
  placeholder: {
    id: `${namespace}.placeholder`,
    description: 'Table header column filter placeholder',
    defaultMessage: 'Enter'
  },
  source: {
    id: `${namespace}.source`,
    description: 'Source table header column label',
    defaultMessage: 'Source'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name table header column label',
    defaultMessage: 'Name'
  },
  description: {
    id: `${namespace}.description`,
    description: 'Description table header column label',
    defaultMessage: 'Description'
  },
  severity: {
    id: `${namespace}.severity`,
    description: 'Severity table header column label',
    defaultMessage: 'Severity'
  },
  status: {
    id: `${namespace}.status`,
    description: 'Status table header column label',
    defaultMessage: 'Status'
  },
  fromAndToDatesAreTheSame: {
    id: `${namespace}.fromAndToDatesAreTheSame`,
    description: 'From and To dates are the same warning',
    defaultMessage: '"From" and "To" dates are the same.'
  },
  toDateIsBeforeFromDate: {
    id: `${namespace}.toDateIsBeforeFromDate`,
    description: 'To date is before from date warning',
    defaultMessage: '"To" date is before "From" date.'
  },
  dates: {
    id: `${namespace}.dates`,
    description: 'Dates filter title',
    defaultMessage: 'DATES'
  },
  from: {
    id: `${namespace}.from`,
    description: 'From floating label text',
    defaultMessage: 'From'
  },
  to: {
    id: `${namespace}.to`,
    description: 'To floating label text',
    defaultMessage: 'To'
  },
  severityFilterTitle: {
    id: `${namespace}.severityFilterTitle`,
    description: 'Severity filter title',
    defaultMessage: 'SEVERITY'
  },
  high: {
    id: `${namespace}.high`,
    description: 'Severity high checkbox',
    defaultMessage: 'high'
  },
  medium: {
    id: `${namespace}.medium`,
    description: 'Severity medium checkbox',
    defaultMessage: 'medium'
  },
  low: {
    id: `${namespace}.low`,
    description: 'Severity low checkbox',
    defaultMessage: 'low'
  },
  statusFilterTitle: {
    id: `${namespace}.statusFilterTitle`,
    description: 'Status filter title',
    defaultMessage: 'STATUS'
  },
  open: {
    id: `${namespace}.open`,
    description: 'Status open checkbox',
    defaultMessage: 'open'
  },
  acknowledged: {
    id: `${namespace}.acknowledged`,
    description: 'Status acknowledged checkbox',
    defaultMessage: 'acknowledged'
  },
  closed: {
    id: `${namespace}.closed`,
    description: 'Status closed checkbox',
    defaultMessage: 'closed'
  },
  archived: {
    id: `${namespace}.archived`,
    description: 'Archived option title',
    defaultMessage: 'ARCHIVED'
  },
  viewArchived: {
    id: `${namespace}.viewArchived`,
    description: 'View archived option label',
    defaultMessage: 'View archived'
  },
  selected: {
    id: `${namespace}.selected`,
    description: 'Selected notifications button label',
    defaultMessage: 'Selected'
  },
  clearSelection: {
    id: `${namespace}.clearSelection`,
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },
  acknowledge: {
    id: `${namespace}.acknowledge`,
    description: 'Acknowledge button label',
    defaultMessage: 'Acknowledge'
  },
  unacknowledge: {
    id: `${namespace}.unacknowledge`,
    description: 'Unacknowledge button label',
    defaultMessage: 'Unacknowledge'
  },
  reopen: {
    id: `${namespace}.reopen`,
    description: 'Reopen button label',
    defaultMessage: 'Reopen'
  },
  close: {
    id: `${namespace}.close`,
    description: 'Close button label',
    defaultMessage: 'Close'
  },
  dateDetailLabel: {
    id: `${namespace}.dateDetailLabel`,
    description: 'Date detail label',
    defaultMessage: 'Date'
  },
  severityDetailLabel: {
    id: `${namespace}.severityDateDetailLabel`,
    description: 'Date detail label',
    defaultMessage: 'Severity'
  },
  currentStatusDetailLabel: {
    id: `${namespace}.currentStatusDetailLabel`,
    description: 'Current status detail label',
    defaultMessage: 'Current status'
  },
  yes: {
    id: `${namespace}.yes`,
    description: 'Yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: `${namespace}.no`,
    description: 'No',
    defaultMessage: 'No'
  },
  archivedDetailLabel: {
    id: `${namespace}.archivedDetailLabel`,
    description: 'Archived detail label',
    defaultMessage: 'Archived'
  },
  descriptionDetailLabel: {
    id: `${namespace}.descriptionDetailLabel`,
    description: 'Description detail label',
    defaultMessage: 'Description:'
  },
  historyDetailLabel: {
    id: `${namespace}.historyDetailLabel`,
    description: 'History detail label',
    defaultMessage: 'History'
  },
  noEventsAvailable: {
    id: `${namespace}.noEventsAvailable`,
    description: 'No events available warning text',
    defaultMessage: 'No events available'
  },
  user: {
    id: `${namespace}.user`,
    description: 'User table header column label',
    defaultMessage: 'User'
  },
  observations: {
    id: `${namespace}.observations`,
    description: 'Observations table header column label',
    defaultMessage: 'Observations'
  },
  error400Message: {
    id: `${namespace}.error400Message`,
    description: 'Error 400 message',
    defaultMessage: 'Bad request.'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    description: 'Error 404 message',
    defaultMessage: 'API not available at the moment. Try it later.'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Undefined error title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    description: 'Undefined error message',
    defaultMessage: 'No description avaliable.'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  yesClose: {
    id: `${namespace}.yesClose`,
    description: 'Yes close button label',
    defaultMessage: 'Yes, close'
  },
  closeNotificationWarning: {
    id: `${namespace}.closeNotificationWarning`,
    description: 'Notification closing warning text',
    defaultMessage: 'Are you sure you want to "close" this notification?'
  },
  observation: {
    id: `${namespace}.observation`,
    description: 'Observation floating label text',
    defaultMessage: 'Observation'
  },
  yesReopen: {
    id: `${namespace}.yesReopen`,
    description: 'Yes reopen button label',
    defaultMessage: 'Yes, reopen'
  },
  reopenNotificationWarning: {
    id: `${namespace}.reopenNotificationWarning`,
    description: 'Notification reopening warning text',
    defaultMessage: 'Are you sure you want to "reopen" this notification?'
  },
  yesAcknowledge: {
    id: `${namespace}.yesAcknowledge`,
    description: 'Yes acknowledge button label',
    defaultMessage: 'Yes, acknowledge'
  },
  acknowledgeNotificationWarning: {
    id: `${namespace}.acknowledgeNotificationWarning`,
    description: 'Notification acknowledging warning text',
    defaultMessage: 'Are you sure you want to "acknowledge" this notification?'
  },
  yesUnacknowledge: {
    id: `${namespace}.yesUnacknowledge`,
    description: 'Yes unacknowledge button label',
    defaultMessage: 'Yes, unacknowledge'
  },
  unacknowledgeNotificationWarning: {
    id: `${namespace}.unacknowledgeNotificationWarning`,
    description: 'Notification unacknowledging warning text',
    defaultMessage: 'Are you sure you want to "unacknowledge" this notification?'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  ok: {
    id: `${namespace}.ok`,
    description: 'Ok button label',
    defaultMessage: 'Ok'
  },
  clear: {
    id: `${namespace}.clear`,
    description: 'Clear button label',
    defaultMessage: 'Clear'
  },
  deviceName: {
    id: `${namespace}.deviceName`,
    defaultMessage: 'Device name'
  },
  deviceType: {
    id: `${namespace}.deviceType`,
    defaultMessage: 'Device type'
  },
  reason: {
    id: `${namespace}.reason`,
    defaultMessage: 'Reason'
  },
  type: {
    id: `${namespace}.type`,
    defaultMessage: 'Type'
  },
  signal: {
    id: `${namespace}.signal`,
    defaultMessage: 'Signal'
  },
  geofence: {
    id: `${namespace}.geofence`,
    defaultMessage: 'Geofence'
  },
  maintenance: {
    id: `${namespace}.maintenance`,
    defaultMessage: 'Maintenance'
  },
  notificationDetails: {
    id: `${namespace}.notificationDetails`,
    defaultMessage: 'Notification details'
  },
  machineName: {
    id: `${namespace}.machineName`,
    defaultMessage: 'Machine name'
  },
  eid: {
    id: `${namespace}.eid`,
    defaultMessage: 'EID'
  },
  methods: {
    id: `${namespace}.methods`,
    defaultMessage: 'Methods'
  },
  enterYourObservations: {
    id: `${namespace}.enterYourObservations`,
    defaultMessage: 'Enter your observations'
  },
  confirm: {
    id: `${namespace}.confirm`,
    defaultMessage: 'Confirm'
  },
  textareaTitle: {
    id: `${namespace}.textareaTitle`,
    defaultMessage: '{action} the notification'
  },
  notifications: {
    id: `${namespace}.notifications`,
    defaultMessage: 'Notifications'
  },
  myProfile: {
    id: `${namespace}.myProfile`,
    defaultMessage: 'My Profile'
  },
  notificationsDisabled: {
    id: `${namespace}.notificationsDisabled`,
    defaultMessage: 'Notifications disabled'
  },
  notificationsNoReceived: {
    id: `${namespace}.notificationsNoReceived`,
    defaultMessage: 'Notifications are not being received'
  },
  notificationTitle: {
    id: `${namespace}.notificationTitle`,
    defaultMessage: '{machineName} notification'
  },
  serverErrorClarification: {
    id: `${namespace}.serverErrorClarification`,
    defaultMessage:
      'Please try again or refresh the page. \
      If the error persists, contact <a>Plus+1 Connect Support</a>.'
  },
  serverError: {
    id: `${namespace}.serverError`,
    defaultMessage: 'Server error'
  },
  success: {
    id: `${namespace}.success`,
    defaultMessage: 'Success'
  },
  notificationSuccessfullyUpdated: {
    id: `${namespace}.notificationSuccessfullyUpdated`,
    defaultMessage: 'Notification successfully {status}'
  },
  opened: {
    id: `${namespace}.opened`,
    defaultMessage: 'opened'
  },
  exit: {
    id: `${namespace}.exit`,
    defaultMessage: 'Exit'
  },
  notAccessible: {
    id: `${namespace}.notAccessible`,
    defaultMessage: 'Not accessible'
  },
  notAccessibleMessage: {
    id: `${namespace}.notAccessibleMessage`,
    defaultMessage:
      'The resource you are trying to access does not exist ' +
      'or is not available to you. If you think this may be an error, ' +
      'contact <a>Plus+1 Connect Support</a>.'
  },
  reopened: {
    id: `${namespace}.reopened`,
    defaultMessage: 'reopened'
  },
  unacknowledged: {
    id: `${namespace}.unacknowledged`,
    defaultMessage: 'unacknowledged'
  }
})

export default messages
