import { connect } from 'react-redux'

import { utils } from 'ducks/login'

import NotAccessibleErrorAlert from './NotAccessibleErrorAlert'

const mapStateToProps = state => ({
  user: utils.getLoggedUser(state)
})

export default connect(mapStateToProps)(NotAccessibleErrorAlert)
