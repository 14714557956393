import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import messages from './messages'
import { GNSS_GEOFENCE_FIELDS } from '../constants'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  },
  checkbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  disabledCheckbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#000000',
      opacity: 0.3
    }
  },
  checked: {}
}

const GPSGeofenceSettings = ({
  altitudeLogTime,
  altitudeLogTimeErrorMessage,
  classes,
  geofenceEnabled,
  geofenceLatitude,
  geofenceLatitudeErrorMessage,
  geofenceLogToPortal,
  geofenceLongitude,
  geofenceLongitudeErrorMessage,
  geofenceRadius,
  geofenceRadiusErrorMessage,
  geofenceSamplePeriod,
  geofenceSamplePeriodErrorMessage,
  geofenceSendImmediately,
  gnssAltitudeEnabled,
  gnssHeadingEnabled,
  gnssPositionEnabled,
  gnssSpeedEnabled,
  handleFieldChange,
  headingLogTime,
  headingLogTimeErrorMessage,
  intl,
  isEditable,
  positionLogTime,
  positionLogTimeErrorMessage,
  speedLogTime,
  speedLogTimeErrorMessage
}) => {
  const { formatMessage } = intl

  return (
    <Card
      style={{
        height: '100%',
        borderRadius: 0
      }}
    >
      <div className='col-md-6' style={{ padding: 0 }}>
        <CardHeader style={{ padding: 16 }} title={formatMessage(messages.gpsSettings)} />
        <CardContent style={{ padding: 16 }}>
          <Grid container spacing={3}>
            {isEditable ? (
              <Grid item lg={12} md={12} sm={12} style={{ padding: '12px 12px 0px 12px' }} xs={12}>
                <div
                  className='col-md-2'
                  style={{
                    width: gnssPositionEnabled ? '48px' : '100%',
                    padding: 0
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={gnssPositionEnabled}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        id={GNSS_GEOFENCE_FIELDS.gnssPositionEnabled}
                        onClick={event => {
                          handleFieldChange(GNSS_GEOFENCE_FIELDS.gnssPositionEnabled, event.target.checked)
                        }}
                      />
                    }
                    label={!gnssPositionEnabled ? formatMessage(messages.enablePositionLogging) : ''}
                    style={{ marginTop: '15px', marginBottom: 0 }}
                  />
                </div>
                {gnssPositionEnabled && (
                  <div
                    className='col-md-10'
                    style={{ padding: 0, width: 'calc(100% - 48px)', maxWidth: 'calc(100% - 48px)' }}
                  >
                    <FormControl
                      error={gnssPositionEnabled && positionLogTimeErrorMessage !== ''}
                      style={{ width: '100%' }}
                    >
                      <InputLabel>{formatMessage(messages.positionLoggingTime)}</InputLabel>
                      <Input
                        disabled={!gnssPositionEnabled}
                        id={GNSS_GEOFENCE_FIELDS.positionLogTime}
                        inputProps={{ min: 10, max: 4294967 }}
                        onChange={event => {
                          handleFieldChange(GNSS_GEOFENCE_FIELDS.positionLogTime, event.target.value)
                        }}
                        onWheel={event => event.target.blur()}
                        placeholder='(s)'
                        type='number'
                        value={positionLogTime}
                      />
                      <FormHelperText>{gnssPositionEnabled && positionLogTimeErrorMessage}</FormHelperText>
                    </FormControl>
                  </div>
                )}
              </Grid>
            ) : (
              <Grid item md={6} xs={6}>
                <Typography>{formatMessage(messages.positionLoggingTime)}</Typography>
                <label>{gnssPositionEnabled ? positionLogTime : 0}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={12} md={12} sm={12} style={{ padding: '12px 12px 0px 12px' }} xs={12}>
                <div
                  className='col-md-2'
                  style={{
                    width: gnssAltitudeEnabled ? '48px' : '100%',
                    padding: 0
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={gnssAltitudeEnabled}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        id={GNSS_GEOFENCE_FIELDS.gnssAltitudeEnabled}
                        onClick={event => {
                          handleFieldChange(GNSS_GEOFENCE_FIELDS.gnssAltitudeEnabled, event.target.checked)
                        }}
                      />
                    }
                    label={!gnssAltitudeEnabled ? formatMessage(messages.enableAltitudeLogging) : ''}
                    style={{ marginTop: '15px', marginBottom: 0 }}
                  />
                </div>
                {gnssAltitudeEnabled && (
                  <div
                    className='col-md-10'
                    style={{ padding: 0, width: 'calc(100% - 48px)', maxWidth: 'calc(100% - 48px)' }}
                  >
                    <FormControl
                      error={gnssAltitudeEnabled && altitudeLogTimeErrorMessage !== ''}
                      style={{ width: '100%' }}
                    >
                      <InputLabel>{formatMessage(messages.altitudeLoggingTime)}</InputLabel>
                      <Input
                        disabled={!gnssAltitudeEnabled}
                        id={GNSS_GEOFENCE_FIELDS.altitudeLogTime}
                        inputProps={{ min: 10, max: 4294967 }}
                        onChange={event => {
                          handleFieldChange(GNSS_GEOFENCE_FIELDS.altitudeLogTime, event.target.value)
                        }}
                        onWheel={event => event.target.blur()}
                        placeholder='(s)'
                        type='number'
                        value={altitudeLogTime}
                      />
                      <FormHelperText>{gnssAltitudeEnabled && altitudeLogTimeErrorMessage}</FormHelperText>
                    </FormControl>
                  </div>
                )}
              </Grid>
            ) : (
              <Grid item md={6} xs={6}>
                <Typography>{formatMessage(messages.altitudeLoggingTime)}</Typography>
                <label>{gnssAltitudeEnabled ? altitudeLogTime : 0}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={12} md={12} sm={12} style={{ padding: '12px 12px 0px 12px' }} xs={12}>
                <div
                  className='col-md-2'
                  style={{
                    width: gnssSpeedEnabled ? '48px' : '100%',
                    padding: 0
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={gnssSpeedEnabled}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        id={GNSS_GEOFENCE_FIELDS.gnssSpeedEnabled}
                        onClick={event => {
                          handleFieldChange(GNSS_GEOFENCE_FIELDS.gnssSpeedEnabled, event.target.checked)
                        }}
                      />
                    }
                    label={!gnssSpeedEnabled ? formatMessage(messages.enableSpeedLogging) : ''}
                    style={{ marginTop: '15px', marginBottom: 0 }}
                  />
                </div>
                {gnssSpeedEnabled && (
                  <div
                    className='col-md-10'
                    style={{ padding: 0, width: 'calc(100% - 48px)', maxWidth: 'calc(100% - 48px)' }}
                  >
                    <FormControl error={gnssSpeedEnabled && speedLogTimeErrorMessage !== ''} style={{ width: '100%' }}>
                      <InputLabel>{formatMessage(messages.speedLoggingTime)}</InputLabel>
                      <Input
                        disabled={!gnssSpeedEnabled}
                        id={GNSS_GEOFENCE_FIELDS.speedLogTime}
                        inputProps={{ min: 10, max: 4294967 }}
                        onChange={event => {
                          handleFieldChange(GNSS_GEOFENCE_FIELDS.speedLogTime, event.target.value)
                        }}
                        onWheel={event => event.target.blur()}
                        placeholder='(s)'
                        type='number'
                        value={speedLogTime}
                      />
                      <FormHelperText>{gnssSpeedEnabled && speedLogTimeErrorMessage}</FormHelperText>
                    </FormControl>
                  </div>
                )}
              </Grid>
            ) : (
              <Grid item md={6} xs={6}>
                <Typography>{formatMessage(messages.speedLoggingTime)}</Typography>
                <label>{gnssSpeedEnabled ? speedLogTime : 0}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={12} md={12} sm={12} style={{ padding: '12px 12px 0px 12px' }} xs={12}>
                <div
                  className='col-md-2'
                  style={{
                    width: gnssHeadingEnabled ? '48px' : '100%',
                    padding: 0
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={gnssHeadingEnabled}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        id={GNSS_GEOFENCE_FIELDS.gnssHeadingEnabled}
                        onClick={event => {
                          handleFieldChange(GNSS_GEOFENCE_FIELDS.gnssHeadingEnabled, event.target.checked)
                        }}
                      />
                    }
                    label={!gnssHeadingEnabled ? formatMessage(messages.enableHeadingLogging) : ''}
                    style={{ marginTop: '15px', marginBottom: 0 }}
                  />
                </div>
                {gnssHeadingEnabled && (
                  <div
                    className='col-md-10'
                    style={{ padding: 0, width: 'calc(100% - 48px)', maxWidth: 'calc(100% - 48px)' }}
                  >
                    <FormControl
                      error={gnssHeadingEnabled && headingLogTimeErrorMessage !== ''}
                      style={{ width: '100%' }}
                    >
                      <InputLabel>{formatMessage(messages.headingLoggingTime)}</InputLabel>
                      <Input
                        disabled={!gnssHeadingEnabled}
                        id={GNSS_GEOFENCE_FIELDS.headingLogTime}
                        inputProps={{ min: 10, max: 4294967 }}
                        onChange={event => {
                          handleFieldChange(GNSS_GEOFENCE_FIELDS.headingLogTime, event.target.value)
                        }}
                        onWheel={event => event.target.blur()}
                        placeholder='(s)'
                        type='number'
                        value={headingLogTime}
                      />
                      <FormHelperText>{gnssHeadingEnabled && headingLogTimeErrorMessage}</FormHelperText>
                    </FormControl>
                  </div>
                )}
              </Grid>
            ) : (
              <Grid item md={6} xs={6}>
                <Typography>{formatMessage(messages.headingLoggingTime)}</Typography>
                <label>{gnssHeadingEnabled ? headingLogTime : 0}</label>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </div>
      <div className='col-md-6' style={{ padding: 0 }}>
        <CardHeader style={{ padding: 16 }} title={formatMessage(messages.geofenceSettings)} />
        <CardContent style={{ padding: 16 }}>
          <Grid container spacing={3}>
            {isEditable ? (
              <Grid item lg={12} md={12} sm={12} style={{ padding: '12px 12px 0px 12px' }} xs={12}>
                <div
                  className='col-md-2'
                  style={{
                    width: '100%',
                    padding: 0
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={geofenceEnabled}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        id={GNSS_GEOFENCE_FIELDS.geofenceEnabled}
                        onClick={event => {
                          handleFieldChange(GNSS_GEOFENCE_FIELDS.geofenceEnabled, event.target.checked)
                        }}
                      />
                    }
                    label={formatMessage(messages.enableGeofence)}
                    style={{ marginTop: '15px', marginBottom: 0 }}
                  />
                </div>
              </Grid>
            ) : (
              <Grid item md={6} xs={6}>
                <Typography>{formatMessage(messages.geofenceEnabled)}</Typography>
                <label>{geofenceEnabled ? formatMessage(messages.true) : formatMessage(messages.false)}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={12} md={12} sm={12} style={{ padding: '12px 12px 6px 12px' }} xs={12}>
                <FormControl error={geofenceLatitudeErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.latitude)}</InputLabel>
                  <Input
                    disabled={!geofenceEnabled}
                    id={GNSS_GEOFENCE_FIELDS.geofenceLatitude}
                    inputProps={{ min: -900000000, max: 900000000 }}
                    onChange={event => {
                      handleFieldChange(GNSS_GEOFENCE_FIELDS.geofenceLatitude, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    placeholder='-900000000 ≤ latitude ≤ 900000000'
                    type='number'
                    value={geofenceLatitude}
                  />
                  <FormHelperText>{geofenceLatitudeErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item md={6} xs={6}>
                <Typography>{formatMessage(messages.latitude)}</Typography>
                <label>{geofenceLatitude}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={12} md={12} sm={12} style={{ padding: '12px 12px 6px 12px' }} xs={12}>
                <FormControl error={geofenceLongitudeErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.longitude)}</InputLabel>
                  <Input
                    disabled={!geofenceEnabled}
                    id={GNSS_GEOFENCE_FIELDS.geofenceLongitude}
                    inputProps={{ min: -900000000, max: 900000000 }}
                    onChange={event => {
                      handleFieldChange(GNSS_GEOFENCE_FIELDS.geofenceLongitude, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    placeholder='-1800000000 ≤ longitude ≤ 1800000000'
                    type='number'
                    value={geofenceLongitude}
                  />
                  <FormHelperText>{geofenceLongitudeErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item md={6} xs={6}>
                <Typography>{formatMessage(messages.longitude)}</Typography>
                <label>{geofenceLongitude}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={12} md={12} sm={12} style={{ padding: '12px 12px 6px 12px' }} xs={12}>
                <FormControl error={geofenceRadiusErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.radius)}</InputLabel>
                  <Input
                    disabled={!geofenceEnabled}
                    id={GNSS_GEOFENCE_FIELDS.geofenceRadius}
                    inputProps={{ min: 10, max: 4294967295 }}
                    onChange={event => {
                      handleFieldChange(GNSS_GEOFENCE_FIELDS.geofenceRadius, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    placeholder='radius > 10 m'
                    type='number'
                    value={geofenceRadius}
                  />
                  <FormHelperText>{geofenceRadiusErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item md={6} xs={6}>
                <Typography>{formatMessage(messages.radius)}</Typography>
                <label>{geofenceRadius}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={12} md={12} sm={12} style={{ padding: '12px 12px 0 12px' }} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={geofenceLogToPortal}
                      classes={{
                        root: geofenceEnabled ? classes.checkbox : classes.disabledCheckbox,
                        checked: classes.checked
                      }}
                      disabled={!geofenceEnabled}
                      id={GNSS_GEOFENCE_FIELDS.geofenceLogToPortal}
                      onClick={event => {
                        handleFieldChange(GNSS_GEOFENCE_FIELDS.geofenceLogToPortal, event.target.checked)
                      }}
                    />
                  }
                  label={formatMessage(messages.geofenceLogToPortal)}
                />
              </Grid>
            ) : (
              <Grid item md={6} xs={6}>
                <Typography>{formatMessage(messages.geofenceLogToPortal)}</Typography>
                <label>{geofenceLogToPortal ? formatMessage(messages.true) : formatMessage(messages.false)}</label>
              </Grid>
            )}
            {isEditable && geofenceLogToPortal ? (
              <Grid item lg={12} md={12} sm={12} style={{ padding: '12px 12px 0 12px' }} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={geofenceSendImmediately}
                      classes={{
                        root: geofenceEnabled ? classes.checkbox : classes.disabledCheckbox,
                        checked: classes.checked
                      }}
                      disabled={!geofenceEnabled}
                      id={GNSS_GEOFENCE_FIELDS.geofenceSendImmediately}
                      onClick={event => {
                        handleFieldChange(GNSS_GEOFENCE_FIELDS.geofenceSendImmediately, event.target.checked)
                      }}
                    />
                  }
                  label={formatMessage(messages.geofenceSendImmediately)}
                />
              </Grid>
            ) : 
              !isEditable && (
                <Grid item md={6} xs={6}>
                  <Typography>{formatMessage(messages.geofenceSendImmediately)}</Typography>
                  <label>
                    {geofenceSendImmediately ? formatMessage(messages.true) : formatMessage(messages.false)}
                  </label>
                </Grid>
              )
            }
            {isEditable && geofenceLogToPortal ? (
              <Grid item lg={12} md={12} sm={12} style={{ padding: '12px 12px 12px 12px' }} xs={12}>
                <FormControl error={geofenceSamplePeriodErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.geofenceSamplePeriod)}</InputLabel>
                  <Input
                    disabled={!geofenceEnabled}
                    id={GNSS_GEOFENCE_FIELDS.geofenceSamplePeriod}
                    inputProps={{ min: 0, max: 4294967 }}
                    onChange={event => {
                      handleFieldChange(GNSS_GEOFENCE_FIELDS.geofenceSamplePeriod, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    placeholder='(s)'
                    type='number'
                    value={geofenceSamplePeriod}
                  />
                  <FormHelperText>{geofenceSamplePeriodErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : 
              !isEditable && (
                <Grid item md={6} xs={6}>
                  <Typography>{formatMessage(messages.geofenceSamplePeriod)}</Typography>
                  <label>{geofenceSamplePeriod}</label>
                </Grid>
              )
            }
          </Grid>
        </CardContent>
      </div>
    </Card>
  )
}

GPSGeofenceSettings.propTypes = {
  altitudeLogTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  altitudeLogTimeErrorMessage: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  geofenceEnabled: PropTypes.bool.isRequired,
  geofenceLatitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  geofenceLatitudeErrorMessage: PropTypes.string.isRequired,
  geofenceLogToPortal: PropTypes.bool.isRequired,
  geofenceLongitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  geofenceLongitudeErrorMessage: PropTypes.string.isRequired,
  geofenceRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  geofenceRadiusErrorMessage: PropTypes.string.isRequired,
  geofenceSamplePeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  geofenceSamplePeriodErrorMessage: PropTypes.string.isRequired,
  geofenceSendImmediately: PropTypes.bool.isRequired,
  gnssAltitudeEnabled: PropTypes.bool.isRequired,
  gnssHeadingEnabled: PropTypes.bool.isRequired,
  gnssPositionEnabled: PropTypes.bool.isRequired,
  gnssSpeedEnabled: PropTypes.bool.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  headingLogTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  headingLogTimeErrorMessage: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  positionLogTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  positionLogTimeErrorMessage: PropTypes.string.isRequired,
  speedLogTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  speedLogTimeErrorMessage: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(GPSGeofenceSettings))
