import { saveAs } from 'file-saver'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined'

import DropdownMenu from 'components/DropdownMenu'
import { ReactComponent as CookiesIcon } from 'icons/cookies.svg'
import { ReactComponent as DictionaryIcon } from 'icons/dictionary.svg'
import { client, logError } from 'utils/http'

import messages from './messages'

const styles = {
  menuItem: {
    height: '44px',
    padding: '10px 16px 16px 16px',
    gap: '12px',
    color: '#3F3F3F'
  },
  menuItemText: {
    color: '#3F3F3F',
    paddingLeft: 2
  },
  menuItemIcon: {
    padding: 0,
    marginRight: 0,
    color: '#686868'
  },
  menuSubheader: {
    height: '34px',
    padding: '11px 16px 0px 16px',
    gap: '8px',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'start',
    color: '#686868'
  }
}

const HelpDropdown = ({ intl, user, classes, groupId, ...other }) => {
  const { formatMessage } = intl

  let supportLink = process.env.REACT_APP_SUPPORT_LINK
  if (user?.email) supportLink += '&plus1_email=' + user.email
  supportLink += '#tab-overview'

  return (
    <div
      className='no-notifications-tab'
      style={{
        position: 'relative',
        height: 50,
        width: 64
      }}
    >
      <DropdownMenu
        icon={<HelpIcon style={{ padding: 0, margin: 0, textAlign: 'center' }} />}
        subheader={<div className={classes.menuSubheader}>{formatMessage(messages.help)}</div>}
        {...other}
      >
        <MenuItem
          className={classes.menuItem}
          component='a'
          href={supportLink}
          name='Status page'
          rel='noopener noreferrer'
          target='_blank'
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <HelpOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            className={classes.menuItemText}
            inset
            primary={formatMessage(messages.support)}
            primaryTypographyProps={{ style: { color: '#3F3F3F' } }}
          />
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          name='User guide'
          onClick={async () => {
            try {
              const userGuideUrlResponse = await client.getUserGuideUrl({ groupId })
              const userGuideUrl = userGuideUrlResponse.data
              const blob = await fetch(userGuideUrl).then(r => r.blob())
              saveAs(blob, 'PLUS1ConnectUserGuide.pdf')
            } catch (error) {
              logError(error)
            }
          }}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <DictionaryIcon />
          </ListItemIcon>
          <ListItemText
            className={classes.menuItemText}
            inset
            primary={formatMessage(messages.userGuide)}
            primaryTypographyProps={{ style: { color: '#3F3F3F' } }}
          />
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          component='a'
          href={process.env.REACT_APP_STATUS_PAGE_LINK}
          name='Status page'
          rel='noopener noreferrer'
          target='_blank'
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <VerifiedUserOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            className={classes.menuItemText}
            inset
            primary={formatMessage(messages.statusPage)}
            primaryTypographyProps={{ style: { color: '#3F3F3F' } }}
          />
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          name='Cookies'
          onClick={event => {
            event.preventDefault()
            if (window.CookieConsent.renew) {
              window.CookieConsent.renew()
            }
          }}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <CookiesIcon />
          </ListItemIcon>
          <ListItemText
            className={classes.menuItemText}
            inset
            primary={formatMessage(messages.cookies)}
            primaryTypographyProps={{ style: { color: '#3F3F3F' } }}
          />
        </MenuItem>
      </DropdownMenu>
    </div>
  )
}

HelpDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  user: PropTypes.object
}

HelpDropdown.defaultProps = {
  user: null
}

export default withStyles(styles)(injectIntl(HelpDropdown))
