import PropTypes from 'prop-types'
import React from 'react'

import Chip from '@material-ui/core/Chip'

import messages from './messages'

// Destructure props like so, and use default arguments as a way of setting defaultProps
const DataChip = ({ chipText = '', inline = false, intl }) => {
  const { formatMessage } = intl
  let backColor
  let textColor
  let displayStyle = 'block'
  let chipLabel

  if (inline) {
    displayStyle = 'inline-block'
  }

  switch (chipText) {
    case 'waiting':
      backColor = '#f0ad4e'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.waiting)
      break
    case 'executing':
      backColor = '#0077FF'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.executing)
      break
    case 'completed':
      backColor = 'rgb(0, 149, 65)'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.completed)
      break
    case 'failed':
      backColor = '#d9534f'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.failed)
      break
    case 'canceled':
      backColor = '#d9534f'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.canceled)
      break
    case 'low':
      backColor = '#0077FF'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.low)
      break
    case 'medium':
      backColor = '#f0ad4e'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.medium)
      break
    case 'high':
      backColor = '#d9534f'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.high)
      break
    case 'open':
      backColor = '#d9534f'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.open)
      break
    case 'closed':
      backColor = '#009541'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.closed)
      break
    case 'ackd':
      backColor = '#0077FF'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.ackd)
      break
    case 'ACKD':
      backColor = '#0077FF'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.ackdUpperCase)
      break
    case 'acknowledged':
      backColor = '#0077FF'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.acknowledged)
      break
    case 'EnabledYes':
      backColor = '#009541'
      textColor = '#ffffff'
      chipText = 'yes' // eslint-disable-line no-param-reassign
      chipLabel = formatMessage(messages.enabledYes)
      break
    case 'EnabledNo':
      backColor = '#d9534f'
      textColor = '#ffffff'
      chipText = 'no' // eslint-disable-line no-param-reassign
      chipLabel = formatMessage(messages.enabledNo)
      break
    case 'active':
      backColor = 'rgb(0, 149, 65)'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.active)
      break
    case 'ready':
      backColor = 'rgb(240, 173, 78)'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.ready)
      break
    case 'unavailble':
      backColor = '#d9534f'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.unavailable)
      break
    case 'deactivated':
      backColor = '#707070'
      textColor = '#ffffff'
      chipLabel = formatMessage(messages.deactivated)
      break
    default:
      backColor = 'rgb(224, 224, 224)'
      textColor = 'rgba(0, 0, 0, 0.87)'
      chipLabel = chipText
  }

  return (
    <Chip
      className='actionStateStatus'
      label={<span style={{ color: textColor, fontSize: 14 }}>{chipLabel}</span>}
      style={{
        backgroundColor: backColor,
        display: displayStyle,
        color: textColor,
        height: 'fit-content',
        width: 'fit-content'
      }}
    />
  )
}

DataChip.propTypes = {
  chipText: PropTypes.string.isRequired,
  inline: PropTypes.bool
}

export default DataChip
