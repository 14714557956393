export const ALARMS_ON_DM1_EXISTENCE_RULE = 'ALARMS_ON_DM1_EXISTENCE_RULE'
export const ALARMS_DM1_RULE = 'ALARMS_DM1_RULE'
export const ALARMS_GPS_RULE = 'ALARMS_GPS_RULE'
export const ALARMS_ON_SIGNALS_RULE = 'ALARMS_ON_SIGNALS_RULE'
export const GEOFENCE_ENTER_RULE = 'GEOFENCE_ENTER_RULE'
export const GEOFENCE_EXIT_RULE = 'GEOFENCE_EXIT_RULE'
export const MAINTENANCE = 'MAINTENANCE'

export const NOTIFICATION_TYPES = {
  LIVE_NOTIFICATION: 'LIVE_NOTIFICATION',
  EMAIL_NOTIFICATION: 'EMAIL_NOTIFICATION',
  SMS_NOTIFICATION: 'SMS_NOTIFICATION'
}

export const NOTIFICATIONS_DETAILS_MODE = {
  PC: 'PC',
  MOBILE: 'MOBILE'
}

export const NOTIFICATION_ACTIONS = {
  UNACKNOWLEDGE: 'UNACKNOWLEDGE',
  REOPEN: 'REOPEN',
  ACKNOWLEDGE: 'ACKNOWLEDGE',
  CLOSE: 'CLOSE'
}
