import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import MuiAlert from '@material-ui/lab/Alert'

import Alert from 'components/Alert'
import Loading from 'components/Loading'
import { adapter } from 'utils/cs500'
import download from 'utils/fileHandler/download'
import { logError } from 'utils/http'

import DeviceStatus from './DeviceStatus'
import ViewConfigurationForm from './ViewConfigurationForm'
import ViewConfigurationFormRaw from './ViewConfigurationFormRaw'
import ViewConfigurationFormTitle from './ViewConfigurationFormTitle'
import messages from './messages'

class ViewConfiguration extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      alertMessagesConfiguration: false,
      alertMessagesTextConfiguration: [''],
      alertMessagesTitleConfiguration: '',
      alertMessagesTypeConfiguration: 'danger',
      deviceStatus: 'Unknown',
      isFormatted: true,
      isStandardApplication: true,
      lastOnline: 'Unknown',
      loadingMetadata: true,
      loadingNVConfiguration: true,
      metadata: {},
      nvConfiguration: {},
      sendCANMessageStatus1: 0,
      sendCANMessageStatus2: 0,
      sendCANMessageStatus3: 0,
      sendCANMessageStatus4: 0,
      title: props.device ? props.device.name : '...'
    }
  }

  componentDidMount = () => {
    const { deviceEid } = this.props

    if (deviceEid) {
      this.getCSNodeByEID()
      this.getDeviceMetadata()
      this.getDeviceNonVolatileConfiguration()
    }
  }

  componentDidUpdate = prevProps => {
    const { device, deviceEid } = this.props

    if (JSON.stringify(prevProps.device) !== JSON.stringify(device) && device) {
      this.getCSNodeByEID()
    }
    if (prevProps.deviceEid !== deviceEid && deviceEid) {
      this.getDeviceMetadata()
      this.getDeviceNonVolatileConfiguration()
    }
  }

  componentWillUnmount() {
    const { history, location, resetLocalConfiguration } = this.props

    if (history.location.pathname !== location.pathname) {
      resetLocalConfiguration()
    }
  }

  handleSetValuePulse = canMessageId => {
    const { deviceEid, groupId, setCS500ValuePulse } = this.props

    const stateKey = 'sendCANMessageStatus' + (canMessageId + 1)
    const valuePulse = 'SendCANmsg' + (canMessageId + 1)
    const body = { data: true }

    this.setState({
      [stateKey]: 1
    })

    setCS500ValuePulse(deviceEid, valuePulse, body, { groupId })
      .then(() => {
        this.setState({
          [stateKey]: 2
        })
      })
      .catch(() => {
        this.setState({
          [stateKey]: 3
        })
      })
  }

  getCSNodeByEID = () => {
    const { deviceEid, getCSNodeByEID, groupId } = this.props

    this.setState({
      deviceStatus: 'Unknown',
      lastOnline: 'Unknown'
    })

    getCSNodeByEID(deviceEid, groupId)
      .then(response => {
        this.setState({
          deviceStatus: response.data.deviceStatus,
          lastOnline: response.data.lastActivityTime,
          title: response.data.name
        })
      })
      .catch(response => {
        const { error } = { ...response }
        if (error && error.response) {
          const message = error.response.data ? ': ' + error.response.data : ''
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '400'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error400Message) + message]
              })
              break
            case 401: // Invalid credentials
              this.setState({
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '401'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error401Message) + message]
              })
              break
            case 403: // Access denied
              this.setState({
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '403'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error403Message) + message]
              })
              break
            case 404: // API url not found
              this.setState({
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '404'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error404Message) + message]
              })
              break
            case 406: // Not acceptable
              this.setState({
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '406'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error406Message) + message]
              })
              break
            case 409: // Not acceptable
              this.setState({
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '409'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error409Message) + message]
              })
              break
            case 415: // Not acceptable
              this.setState({
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '415'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error415Message) + message]
              })
              break
            case 422: // Not acceptable
              this.setState({
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '422'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error422Message) + message]
              })
              break
            case 500: // Unexpected error
              this.setState({
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '500'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error500Message) + message]
              })
              break
            default:
              this.setState({
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesTextConfiguration: [this.formatMessage(messages.errorUndefinedMessage) + message]
              })
              logError(error)
          }
        } else {
          this.setState({
            alertMessagesConfiguration: true,
            alertMessagesTypeConfiguration: 'danger',
            alertMessagesTitleConfiguration: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesTextConfiguration: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(error)
        }
      })
  }

  getDeviceMetadata = () => {
    const { deviceEid, getDeviceMetadata, groupId } = this.props

    getDeviceMetadata(groupId, deviceEid)
      .then(response => {
        this.setState({
          loadingMetadata: false,
          metadata: response.data
        })
      })
      .catch(response => {
        const { error } = { ...response }
        if (error && error.response) {
          const message = error.response.data && error.response.data.message ? ': ' + error.response.data.message : ''
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                loadingMetadata: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '400'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error400Message) + message]
              })
              break
            case 401: // Invalid credentials
              this.setState({
                loadingMetadata: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '401'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error401Message) + message]
              })
              break
            case 403: // Access denied
              this.setState({
                loadingMetadata: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '403'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error403Message) + message]
              })
              break
            case 404: // API url not found
              this.setState({
                loadingMetadata: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '404'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error404Message) + message]
              })
              break
            case 406: // Not acceptable
              this.setState({
                loadingMetadata: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '406'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error406Message) + message]
              })
              break
            case 409: // Not acceptable
              this.setState({
                loadingMetadata: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '409'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error409Message) + message]
              })
              break
            case 415: // Not acceptable
              this.setState({
                loadingMetadata: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '415'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error415Message) + message]
              })
              break
            case 422: // Not acceptable
              this.setState({
                loadingMetadata: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '422'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error422Message) + message]
              })
              break
            case 500: // Unexpected error
              this.setState({
                loadingMetadata: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '500'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error500Message) + message]
              })
              break
            default:
              this.setState({
                loadingMetadata: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesTextConfiguration: [this.formatMessage(messages.errorUndefinedMessage) + message]
              })
              logError(error)
          }
        } else {
          this.setState({
            loadingMetadata: false,
            alertMessagesConfiguration: true,
            alertMessagesTypeConfiguration: 'danger',
            alertMessagesTitleConfiguration: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesTextConfiguration: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(error)
        }
      })
  }

  getDeviceNonVolatileConfiguration = () => {
    const { deviceEid, getDeviceNonVolatileConfiguration, groupId, setLocalConfiguration } = this.props

    getDeviceNonVolatileConfiguration(groupId, deviceEid)
      .then(response => {
        this.setState(
          {
            loadingNVConfiguration: false,
            nvConfiguration: response.data
          },
          () => {
            try {
              const adaptedConfiguration = adapter.cs500ConfigToCs100Config(response.data)
              const isStandardApplication = adaptedConfiguration.isStandardApplication
              setLocalConfiguration(adaptedConfiguration)
              this.setState({
                isStandardApplication,
                isFormatted: isStandardApplication
              })
            } catch (error) {
              this.setState({
                isStandardApplication: false,
                isFormatted: false
              })
            }
          }
        )
      })
      .catch(response => {
        const { error } = { ...response }
        if (error && error.response) {
          const message = error.response.data && error.response.data.message ? ': ' + error.response.data.message : ''
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                loadingNVConfiguration: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '400'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error400Message) + message]
              })
              break
            case 401: // Invalid credentials
              this.setState({
                loadingNVConfiguration: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '401'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error401Message) + message]
              })
              break
            case 403: // Access denied
              this.setState({
                loadingNVConfiguration: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '403'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error403Message) + message]
              })
              break
            case 404: // API url not found
              this.setState({
                loadingNVConfiguration: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '404'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error404Message) + message]
              })
              break
            case 406: // Not acceptable
              this.setState({
                loadingNVConfiguration: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '406'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error406Message) + message]
              })
              break
            case 409: // Not acceptable
              this.setState({
                loadingNVConfiguration: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '409'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error409Message) + message]
              })
              break
            case 415: // Not acceptable
              this.setState({
                loadingNVConfiguration: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '415'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error415Message) + message]
              })
              break
            case 422: // Not acceptable
              this.setState({
                loadingNVConfiguration: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '422'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error422Message) + message]
              })
              break
            case 500: // Unexpected error
              this.setState({
                loadingNVConfiguration: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '500'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error500Message) + message]
              })
              break
            default:
              this.setState({
                loadingNVConfiguration: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesTextConfiguration: [this.formatMessage(messages.errorUndefinedMessage) + message]
              })
              logError(error)
          }
        } else {
          this.setState({
            loadingNVConfiguration: false,
            alertMessagesConfiguration: true,
            alertMessagesTypeConfiguration: 'danger',
            alertMessagesTitleConfiguration: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesTextConfiguration: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(error)
        }
      })
  }

  downloadMetadata = () => {
    const { deviceEid } = this.props
    const { metadata } = this.state

    const fileName = deviceEid.replaceAll(':', '') + '-CS500-metadata.json'
    const blob = new Blob([JSON.stringify(metadata, null, 2)], { type: 'application/octet-stream' })
    download(blob, fileName, 'application/octet-stream')
  }

  downloadNVConfiguration = () => {
    const { deviceEid } = this.props
    const { nvConfiguration } = this.state

    const fileName = deviceEid.replaceAll(':', '') + '-CS500-config.json'
    const blob = new Blob([JSON.stringify(nvConfiguration, null, 2)], { type: 'application/octet-stream' })
    download(blob, fileName, 'application/octet-stream')
  }

  handleViewFormChange = event => {
    this.setState({
      isFormatted: event.target.checked
    })
  }

  renderViewConfigurationLoadingAndError = () => {
    const {
      alertMessagesConfiguration,
      alertMessagesTextConfiguration,
      alertMessagesTitleConfiguration,
      alertMessagesTypeConfiguration,
      loadingMetadata,
      loadingNVConfiguration
    } = this.state

    if (loadingMetadata || loadingNVConfiguration) {
      return <Loading />
    } else if (alertMessagesConfiguration) {
      return (
        <Alert
          alertType={alertMessagesTypeConfiguration}
          messageText={alertMessagesTextConfiguration}
          messageTitle={alertMessagesTitleConfiguration}
          showAlert={alertMessagesConfiguration}
        />
      )
    }
  }

  renderViewConfigurationContent = () => {
    const { configState, configurationsUrl } = this.props
    const {
      alertMessagesConfiguration,
      loadingMetadata,
      loadingNVConfiguration,
      sendCANMessageStatus1,
      sendCANMessageStatus2,
      sendCANMessageStatus3,
      sendCANMessageStatus4
    } = this.state

    if (loadingMetadata || loadingNVConfiguration || alertMessagesConfiguration) {
      return (
        <div
          className='container-fluid'
          style={{ position: 'fixed', width: '100%', marginTop: 20, paddingTop: '150px' }}
        >
          {this.renderViewConfigurationLoadingAndError()}
        </div>
      )
    } else {
      return (
        <div style={{ margin: 20 }}>
          <div className='col-xs-12' style={{ margin: '0px 55px 10px 0px' }}>
            <MuiAlert severity='info'>
              {this.formatMessage(messages.firmwareVersionAlert, {
                firmware: <b>{this.formatMessage(messages.firmware)}</b>,
                version: <b>{configState.applicationVersion || '-'}</b>
              })}
            </MuiAlert>
          </div>
          <ViewConfigurationForm
            configurationsUrl={configurationsUrl}
            nvConfiguration={configState}
            onSetValuePulse={this.handleSetValuePulse}
            sendCANMessageStatus1={sendCANMessageStatus1}
            sendCANMessageStatus2={sendCANMessageStatus2}
            sendCANMessageStatus3={sendCANMessageStatus3}
            sendCANMessageStatus4={sendCANMessageStatus4}
          />
        </div>
      )
    }
  }

  renderViewConfigurationRawContent = () => {
    const {
      alertMessagesConfiguration,
      isFormatted,
      isStandardApplication,
      loadingMetadata,
      loadingNVConfiguration,
      metadata,
      nvConfiguration
    } = this.state

    if (loadingMetadata || loadingNVConfiguration || alertMessagesConfiguration) {
      return (
        <div className='container-fluid' style={{ marginTop: 20 }}>
          {this.renderViewConfigurationLoadingAndError()}
        </div>
      )
    } else {
      return (
        <React.Fragment>
          <div className='col-md-12' style={{ textAlign: 'end' }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={isFormatted}
                    disabled={!isStandardApplication}
                    onChange={this.handleViewFormChange}
                    value='viewForm'
                  />
                }
                label={isFormatted ? this.formatMessage(messages.formatted) : this.formatMessage(messages.raw)}
                labelPlacement='start'
              />
            </FormControl>
          </div>
          <ViewConfigurationFormRaw
            downloadMetadata={this.downloadMetadata}
            downloadNVConfiguration={this.downloadNVConfiguration}
            metadata={metadata}
            nvConfiguration={nvConfiguration}
          />
        </React.Fragment>
      )
    }
  }

  render() {
    const { isFormatted, title, deviceStatus, isStandardApplication, lastOnline } = this.state

    const { configurationsUrl, isSidebarCollapsed } = this.props

    return isFormatted ? (
      <div
        className='content-container'
        id='viewConfigurationContent'
        style={{ position: 'relative', overflowX: 'hidden', marginTop: '0px' }}
      >
        <div
          className='col-12'
          style={{
            backgroundColor: '#efefef',
            padding: isSidebarCollapsed ? '20px 35px 0px 35px' : '20px 35px 0px 35px'
          }}
        >
          <div className='col-md-9' style={{ height: '100%', padding: 0 }}>
            <ViewConfigurationFormTitle configurationsUrl={configurationsUrl} title={title} />
          </div>
          <div className='col-md-3' style={{ textAlign: 'end', padding: 0, height: '100%', marginTop: 20 }}>
            <Button className='primary-action-button' onClick={this.downloadNVConfiguration} style={{ minWidth: 100 }}>
              {this.formatMessage(messages.download)}
            </Button>
          </div>
        </div>
        <div
          className='col-xs-12'
          style={{
            backgroundColor: '#efefef',
            padding: isSidebarCollapsed ? '0px 35px 0px 35px' : '0px 35px 0px 35px'
          }}
        >
          <div className='col-xs-6' style={{ padding: 0, height: 38 }}>
            <DeviceStatus deviceStatus={deviceStatus} getCSNodeByEID={this.getCSNodeByEID} lastOnline={lastOnline} />
          </div>
          <div className='col-xs-6' style={{ textAlign: 'end', padding: 0 }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={isFormatted}
                    disabled={!isStandardApplication}
                    onChange={this.handleViewFormChange}
                    value='viewForm'
                  />
                }
                label={isFormatted ? this.formatMessage(messages.formatted) : this.formatMessage(messages.raw)}
                labelPlacement='start'
              />
            </FormControl>
          </div>
        </div>

        {this.renderViewConfigurationContent()}
      </div>
    ) : (
      <div
        className='content-container'
        id='viewConfigurationRawContent'
        style={{ padding: '20px', height: 'calc(100vh - 50px)' }}
      >
        <div className='container-fluid'>
          <ViewConfigurationFormTitle configurationsUrl={configurationsUrl} title={title} />
        </div>

        {this.renderViewConfigurationRawContent()}
      </div>
    )
  }
}

ViewConfiguration.propTypes = {
  configState: PropTypes.object.isRequired,
  configurationsUrl: PropTypes.string.isRequired,
  device: PropTypes.object,
  deviceEid: PropTypes.string.isRequired,
  getCSNodeByEID: PropTypes.func.isRequired,
  getDeviceMetadata: PropTypes.func.isRequired,
  getDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  getNewestNVConfigurationDefinition: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isSidebarCollapsed: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  resetLocalConfiguration: PropTypes.func.isRequired,
  setCS500ValuePulse: PropTypes.func.isRequired,
  setLocalConfiguration: PropTypes.func.isRequired
}

ViewConfiguration.defaultProps = {
  device: null
}

export default withRouter(injectIntl(ViewConfiguration))
