import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'ConfigurationsCS500.title',
    description: 'Configuration title',
    defaultMessage: 'Configurations'
  },
  createConfiguration: {
    id: 'ConfigurationsCS500.createConfiguration',
    description: 'Add configuration button label',
    defaultMessage: 'New configuration'
  },
  prePage: {
    id: 'ConfigurationsCS500.prePage',
    description: 'Previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: 'ConfigurationsCS500.nextPage',
    description: 'Next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: 'ConfigurationsCS500.firstPage',
    description: 'First page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: 'ConfigurationsCS500.lastPage',
    description: 'Last page button text',
    defaultMessage: 'Last'
  },
  showingRows: {
    id: 'ConfigurationsCS500.showingRows',
    description: 'Nodes table total text showing rows',
    defaultMessage: 'Showing configurations'
  },
  to: {
    id: 'ConfigurationsCS500.to',
    description: 'Nodes table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: 'ConfigurationsCS500.of',
    description: 'Nodes table total text of',
    defaultMessage: 'of'
  },
  noConfigurationsText: {
    id: 'ConfigurationsCS500.noConfigurationsText',
    description: 'No configurations text',
    defaultMessage: 'No configurations available'
  },
  locationID: {
    id: 'ConfigurationsCS500.locationID',
    description: 'Location ID',
    defaultMessage: 'Location ID'
  },
  name: {
    id: 'ConfigurationsCS500.name',
    description: 'name',
    defaultMessage: 'Name'
  },
  deviceType: {
    id: 'ConfigurationsCS500.deviceType',
    description: 'Device type',
    defaultMessage: 'Device type'
  },
  eid: {
    id: 'ConfigurationsCS500.eid',
    description: 'EID',
    defaultMessage: 'EID'
  },
  actions: {
    id: 'ConfigurationsCS500.actions',
    description: 'actions',
    defaultMessage: 'Actions'
  },
  viewConfiguration: {
    id: 'ConfigurationsCS500.viewConfiguration',
    description: 'View configuration icon button tooltip',
    defaultMessage: 'View configuration'
  },
  editConfiguration: {
    id: 'ConfigurationsCS500.editConfiguration',
    description: 'Edit configuration icon button tooltip',
    defaultMessage: 'Edit configuration'
  },
  downloadConfiguration: {
    id: 'ConfigurationsCS500.downloadConfiguration',
    description: 'Download configuration icon button tooltip',
    defaultMessage: 'Download configuration'
  },
  nvConfigurationUpdatedSuccessfully: {
    id: 'ConfigurationsCS500.nvConfigurationUpdatedSuccessfully',
    description: 'configuration successfully applied',
    defaultMessage: 'configuration successfully applied'
  },
  errorRequestingData: {
    id: 'ConfigurationsCS500.errorRequestingData',
    description: 'No device ',
    defaultMessage: 'No devices available'
  },
  thereAreNoCS500: {
    id: 'ConfigurationsCS500.thereAreNoCS500',
    description: 'There are no CS500 devices message',
    defaultMessage: 'There are no CS500 devices to show.'
  },
  error: {
    id: 'ConfigurationsCS500.error',
    description: 'Error text',
    defaultMessage: 'Error {number}'
  },
  error400Message: {
    id: 'ConfigurationsCS500.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error401Message: {
    id: 'ConfigurationsCS500.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error403Message: {
    id: 'ConfigurationsCS500.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'ConfigurationsCS500.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'ConfigurationsCS500.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  error500Message: {
    id: 'ConfigurationsCS500.error500Message',
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error'
  },
  errorUndefinedTitle: {
    id: 'ConfigurationsCS500.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'ConfigurationsCS500.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available'
  },
  lastTelemetryMessage: {
    id: 'ConfigurationsCS500.lastTelemetryMessage',
    description: 'Last telemetry message',
    defaultMessage: 'Last telemetry message'
  },
  placeholder: {
    id: 'ConfigurationsCS500.placeholder',
    description: 'Placeholder text',
    defaultMessage: 'Enter {column}'
  }
})

export default messages
