import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { utils as groupUtils } from 'ducks/groups'
import { utils, actions } from 'ducks/map'
import csNode from 'modules/csNode'

import Panel from './Panel'

const mapStateToProps = state => {
  const { nd } = utils.getSelectedNode(state)
  if (nd) {
    return {
      nodeId: nd.id,
      name: nd.name,
      deviceType: nd.deviceType,
      node: nd,
      deviceStatus: nd.deviceStatus,
      groupId: groupUtils.getSelectedGroup(state).id
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      unselectNode: actions.unselectNode,
      getDevice: csNode.actions.fetchCSNode
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Panel)
