import { defineMessages } from 'react-intl'

const namespace = 'SendCAN'

const messages = defineMessages({
  title: {
    id: `${namespace}.title`,
    defaultMessage: 'SEND CAN MESSAGE WIDGET'
  },
  cancel: {
    id: `${namespace}.cancel`,
    defaultMessage: 'Cancel'
  },
  save: {
    id: `${namespace}.save`,
    defaultMessage: 'Save'
  },
  CANMessage: {
    id: `${namespace}.CANMessage`,
    defaultMessage: 'CAN message'
  },
  messageName: {
    id: `${namespace}.messageName`,
    defaultMessage: 'Message name (max {maxLength} chars)'
  },
  buttonBackgroundColor: {
    id: `${namespace}.buttonBackgroundColor`,
    defaultMessage: 'Button background color'
  },
  responseTimeout: {
    id: `${namespace}.responseTimeout`,
    defaultMessage: 'Response timeout (5-300)'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    defaultMessage: 'This field is required'
  },
  confirmationPrompt: {
    id: `${namespace}.confirmationPrompt`,
    defaultMessage: 'Confirmation prompt'
  },
  requireConfirmation: {
    id: `${namespace}.requireConfirmation`,
    defaultMessage: 'Require confimation before sending the message'
  },
  seconds: {
    id: `${namespace}.seconds`,
    defaultMessage: 'seconds'
  },
  message: {
    id: `${namespace}.message`,
    defaultMessage: 'Message {messageId}'
  },
  invalidResponseTimeoutValue: {
    id: `${namespace}.invalidResponseTimeoutValue`,
    defaultMessage: 'Invalid response timeout value'
  },
  widgetNotConfigured: {
    id: `${namespace}.widgetNotConfigured`,
    defaultMessage: 'Widget not configured'
  },
  sentHistory: {
    id: `${namespace}.sentHistory`,
    defaultMessage: 'Sent history'
  },
  delivered: {
    id: `${namespace}.delivered`,
    defaultMessage: 'Delivered'
  },
  failed: {
    id: `${namespace}.failed`,
    defaultMessage: 'Failed'
  },
  sendCANMessage: {
    id: `${namespace}.sendCANMessage`,
    defaultMessage: 'SEND CAN MESSAGE'
  },
  send: {
    id: `${namespace}.send`,
    defaultMessage: 'Send'
  },
  confirmationText: {
    id: `${namespace}.confirmationText`,
    defaultMessage: 'Are you sure you want to send the {messageName} message to the device {deviceName}?'
  },
  maxLengthChars: {
    id: `${namespace}.maxLengthChars`,
    defaultMessage: 'Max length {maxLength} chars'
  }
})

export default messages
