import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Pallete from '@material-ui/icons/Palette'

import { ValidatedTextField } from 'components/Form'
import PictureEditor from 'components/PictureEditor'
import { ColorPicker } from 'components/common'

import messages from './messages'

const styles = {
  colorPicker: {
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: 0,
    cursor: 'pointer',
    display: 'block',
    height: 26,
    marginTop: 21,
    width: '100%',

    '&:hover': {
      border: '3px solid rgba(0, 0, 0, 0.5)'
    }
  }
}

const getValidator = formatMessage =>
  function (value = '') {
    const wordCharacterRegExp = /^[\w]*$/
    const isValid = wordCharacterRegExp.test(value)
    return isValid ? null : formatMessage(messages.invalidSubpath)
  }

const EditTheme = ({
  logoBig,
  logoBigName,
  appBarColor,
  title,
  favicon,
  faviconName,
  onFieldChange,
  loginPath,
  loginText,
  loginImage,
  loginImageName,
  sideBarColor,
  fontColor,
  intl,
  classes
}) => {
  const { formatMessage } = intl
  const { colorPicker } = classes
  const style = { margin: '1em 0' }

  return (
    <Card style={style}>
      <CardHeader
        avatar={<Pallete />}
        subheader={formatMessage(messages.customizeTheTheme)}
        title={formatMessage(messages.themeTitle)}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              id='title'
              label={formatMessage(messages.pageTitle)}
              name='title'
              onChange={e => onFieldChange({ title: e.target.value })}
              value={title}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <PictureEditor
              fullWidth
              label={`${formatMessage(messages.logo)} (480x90)`}
              name={logoBigName}
              onChange={(newLogoBig, newLogoBigName) =>
                onFieldChange({ logoBig: newLogoBig, logoBigName: newLogoBigName })
              }
              picture={logoBig}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <PictureEditor
              fullWidth
              label={`${formatMessage(messages.favicon)} (32x32)`}
              name={faviconName}
              onChange={(newFavicon, newFaviconName) =>
                onFieldChange({ favicon: newFavicon, faviconName: newFaviconName })
              }
              picture={favicon}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink>{formatMessage(messages.appBarColor)}</InputLabel>
              <ColorPicker
                className={colorPicker}
                onChange={newAppBarColor => onFieldChange({ appBarColor: newAppBarColor })}
                value={appBarColor}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink>{formatMessage(messages.appSideBarColor)}</InputLabel>
              <ColorPicker
                className={colorPicker}
                onChange={newSideBarColor => onFieldChange({ sideBarColor: newSideBarColor })}
                value={sideBarColor}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink>{formatMessage(messages.appFontColor)}</InputLabel>
              <ColorPicker
                className={colorPicker}
                onChange={newFontColor => onFieldChange({ fontColor: newFontColor })}
                value={fontColor}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <ValidatedTextField
              id='loginPath'
              label={formatMessage(messages.loginPath)}
              name='loginPath'
              onChange={(newLoginPath, isLoginPathValid) =>
                onFieldChange({ loginPath: newLoginPath, isLoginPathValid })
              }
              validator={getValidator(formatMessage)}
              value={loginPath}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <PictureEditor
              fullWidth
              label={`${formatMessage(messages.loginImage)} (1900x860)`}
              name={loginImageName}
              onChange={(newLoginImage, newLoginImageName) =>
                onFieldChange({ loginImage: newLoginImage, loginImageName: newLoginImageName })
              }
              picture={loginImage}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              id='loginText'
              label={formatMessage(messages.loginText)}
              name='loginText'
              onChange={e => onFieldChange({ loginText: e.target.value })}
              value={loginText}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

EditTheme.propTypes = {
  appBarColor: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  favicon: PropTypes.string.isRequired,
  faviconName: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  loginImage: PropTypes.string.isRequired,
  loginImageName: PropTypes.string.isRequired,
  loginPath: PropTypes.string.isRequired,
  loginText: PropTypes.string.isRequired,
  logoBig: PropTypes.string.isRequired,
  logoBigName: PropTypes.string.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  sideBarColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(EditTheme))
