import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { injectIntl } from 'react-intl'

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { DateTimePicker } from '@material-ui/pickers'

import { utcTimeToBrowserLocalNoSeconds } from 'utils/timeFormat'

import messages from './messages'

const styles = {
  dateTimePickerRoot: {
    width: '100%'
  }
}

const GpsQuerySelector = React.memo(props => {
  const {
    classes,
    dateFromTimestamp,
    dateToTimestamp,
    intl: { formatMessage },
    isRealtimeGpsTrackingRunning,
    isRealtimeTrackingSourceSelected,
    onDateFromChange,
    onDateToChange,
    onTrackingSourceChange,
    onTrailChange,
    trackingSource,
    trackingSourceOptions,
    trail
  } = props

  const TRAIL_OPTIONS = [
    { value: 50, label: '50 ' + formatMessage(messages.points) },
    { value: 100, label: '100 ' + formatMessage(messages.points) },
    { value: 250, label: '250 ' + formatMessage(messages.points) },
    { value: 500, label: '500 ' + formatMessage(messages.points) },
    { value: 1000, label: '1000 ' + formatMessage(messages.points) }
  ]

  const handleTrailChange = useCallback(
    event => {
      onTrailChange(event.target.value)
    },
    [onTrailChange]
  )

  const handleTrackingSourceChange = useCallback(
    event => {
      onTrackingSourceChange(event.target.value)
    },
    [onTrackingSourceChange]
  )

  function renderSelectMenuWithLabels(itemList = []) {
    return itemList.map((elem, i) => {
      return (
        <MenuItem key={i} style={{ fontSize: '14px' }} value={elem.value}>
          {elem.label}
        </MenuItem>
      )
    })
  }

  const maxDate = dateToTimestamp || moment().valueOf()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Typography color='inherit' style={{ fontSize: '14px' }} variant='subtitle2'>
            {formatMessage(messages.trail)}
          </Typography>
          <Select onChange={handleTrailChange} style={{ fontSize: '14px' }} value={trail}>
            {renderSelectMenuWithLabels(TRAIL_OPTIONS)}
          </Select>
        </FormControl>
      </Grid>

      <Grid container item spacing={1} xs={12}>
        <Grid item xs={12}>
          <FormControl disabled={isRealtimeGpsTrackingRunning} fullWidth>
            <Typography color='inherit' style={{ fontSize: '14px' }} variant='subtitle2'>
              {formatMessage(messages.trackingSource)}
            </Typography>
            <RadioGroup name='trackingSource' onChange={handleTrackingSourceChange} row value={trackingSource}>
              <FormControlLabel
                control={<Radio />}
                label={trackingSourceOptions[0].label}
                value={trackingSourceOptions[0].value}
              />
              <FormControlLabel
                control={<Radio />}
                label={trackingSourceOptions[1].label}
                value={trackingSourceOptions[1].value}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item style={{ marginTop: '0px' }} xs={12}>
          <DateTimePicker
            ampm={false}
            cancelLabel={formatMessage(messages.cancel)}
            classes={{ root: classes.dateTimePickerRoot }}
            clearLabel={formatMessage(messages.clear)}
            clearable
            disableFuture
            disabled={isRealtimeTrackingSourceSelected}
            label={formatMessage(messages.from)}
            labelFunc={() => utcTimeToBrowserLocalNoSeconds(dateFromTimestamp)}
            maxDate={maxDate}
            maxDateMessage={formatMessage(messages.maxDateMessage)}
            okLabel={formatMessage(messages.ok)}
            onChange={onDateFromChange}
            strictCompareDates
            value={dateFromTimestamp || null}
          />
        </Grid>

        <Grid item style={{ marginTop: '0px' }} xs={12}>
          <DateTimePicker
            ampm={false}
            cancelLabel={formatMessage(messages.cancel)}
            classes={{ root: classes.dateTimePickerRoot }}
            clearLabel={formatMessage(messages.clear)}
            clearable
            disableFuture
            disabled={isRealtimeTrackingSourceSelected}
            label={formatMessage(messages.to)}
            labelFunc={() => utcTimeToBrowserLocalNoSeconds(dateToTimestamp)}
            minDate={dateFromTimestamp}
            minDateMessage={formatMessage(messages.minDateMessage)}
            okLabel={formatMessage(messages.ok)}
            onChange={onDateToChange}
            strictCompareDates
            value={dateToTimestamp || null}
          />
        </Grid>
      </Grid>
    </Grid>
  )
})

GpsQuerySelector.propTypes = {
  classes: PropTypes.object.isRequired,
  dateFromTimestamp: PropTypes.number.isRequired,
  dateToTimestamp: PropTypes.number.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isRealtimeGpsTrackingRunning: PropTypes.bool.isRequired,
  isRealtimeTrackingSourceSelected: PropTypes.bool.isRequired,
  onDateFromChange: PropTypes.func.isRequired,
  onDateToChange: PropTypes.func.isRequired,
  onTrackingSourceChange: PropTypes.func.isRequired,
  onTrailChange: PropTypes.func.isRequired,
  trackingSource: PropTypes.string.isRequired,
  trackingSourceOptions: PropTypes.array.isRequired,
  trail: PropTypes.number.isRequired
}

export default injectIntl(withStyles(styles)(GpsQuerySelector))
