import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { withRouter, matchPath } from 'react-router-dom'

import { NOTIFICATIONS_DETAIL_TEMPLATE_URL } from 'components/notifications'
import { utils as map } from 'ducks/map'
import { utils as template } from 'ducks/template'

import AdminTemplate from './AdminTemplate'

const mapStateToProps = (state, { location }) => {
  const match = matchPath(location.pathname, { path: NOTIFICATIONS_DETAIL_TEMPLATE_URL, exact: true, strict: false })
  return {
    isCollapsed: template.isCollapsed(state),
    isMapMounted: map.getIsMounted(state),
    isMobileNotificationDetails: isMobile && location.pathname === `/notifications/${match?.params?.notificationId}`,
    isMobile
  }
}

export default withRouter(connect(mapStateToProps)(AdminTemplate))
