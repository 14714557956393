import { connect } from 'react-redux'

import { utils as groupUtils } from 'ducks/groups'
import { utils } from 'ducks/login'

import NotificationsDetailContent from './NotificationsDetailContent'

const mapStateToProps = state => ({
  user: utils.getLoggedUser(state),
  groupId: groupUtils.getSelectedGroup(state).id
})

export default connect(mapStateToProps)(NotificationsDetailContent)
