import { connect } from 'react-redux'

import { utils } from 'ducks/login'

import Error404 from './Error404'

const mapStateToProps = state => ({
  user: utils.getLoggedUser(state)
})

export default connect(mapStateToProps)(Error404)
