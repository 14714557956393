import IntlMessageFormat from 'intl-messageformat'
import _isEqual from 'lodash/isEqual'

import translations from 'i18n/locales'
import download from 'utils/fileHandler/download'

import { ERROR_IN_CONFIG_MODE, SELECT_DEVICES_MODE, UPDATE_CONFIG_MODE } from './constants'
import {
  getSignalsWithAdaptedParameters,
  transformValuesForAPI,
  transformVirtualParametersValuesForAPI,
  validationSchema
} from '../config'

const isLegacyVersion = false

const SIGNED_32_BIT_NEGATIVE_MIN = -2147483648
const SIGNED_32_BIT_POSITIVE_MAX = 2147483647

const getSignalOverflowErrors = signals =>
  signals.reduce((acc, cur, index) => {
    if (cur.signalSource === 2) {
      const rawValue = Math.pow(2, cur.lengthOfBits) - 1
      const value = rawValue * cur.multiplier + cur.offset
      if (value < SIGNED_32_BIT_NEGATIVE_MIN || value > SIGNED_32_BIT_POSITIVE_MAX) {
        return [
          ...acc,
          {
            path: `signals[${index}].overflowErrorMessage`,
            message: 'With current signal configuration, output may overflow S32 bit range'
          }
        ]
      } else return acc
    } else return acc
  }, [])

export const handleDownloadConfiguration = (
  configState,
  localConfigurationError,
  setState,
  configurationDefinition
) => {
  const i18n = translations[localStorage.getItem('user_language')]

  // Modify multiplier and offset
  const adaptedSignals = getSignalsWithAdaptedParameters(configState.signals)
  const newConfigState = {
    ...configState,
    signals: adaptedSignals
  }

  validationSchema(isLegacyVersion)
    .validate(newConfigState, {
      abortEarly: false,
      context: {
        loopTime: newConfigState.loopTime,
        signalsToLog: newConfigState.signals.length,
        configuredSignals: newConfigState.signals.map(s => s.signalId)
      }
    })
    .then(content => {
      const signalOverflowErrors = getSignalOverflowErrors(content.signals)
      if (signalOverflowErrors.length > 0) {
        return Promise.reject({ isSignalOverflowError: true, signalOverflowErrors })
      } else {
        const configAdapted = {
          compileTime: configurationDefinition.compileTime,
          applicationVersion: configurationDefinition.applicationVersion,
          parameters: transformValuesForAPI(content, false),
          virtualParameters: transformVirtualParametersValuesForAPI(content)
        }

        const fileName = 'CS500-config.json'
        const blob = new Blob([JSON.stringify(configAdapted, null, 2)], { type: 'application/octet-stream' })
        download(blob, fileName, 'application/octet-stream')
      }
    })
    .catch(error => {
      setState({
        isApplyConfigurationDialogOpen: true,
        applyConfigurationDialogMode: ERROR_IN_CONFIG_MODE,
        alertMessagesType: 'danger',
        alertMessagesTitle: i18n['NewConfigurationCS500.generalError'],
        alertMessagesText: i18n['NewConfigurationCS500.generalErrorDescriptionDownload']
      })
      if (error.isSignalOverflowError) localConfigurationError(error.signalOverflowErrors)
      else localConfigurationError(error.inner)
    })
}

export const handleApplyConfigurationButtonClick = (configState, localConfigurationError, setState) => {
  const i18n = translations[localStorage.getItem('user_language')]

  // Modify multiplier y offset
  const adaptedSignals = getSignalsWithAdaptedParameters(configState.signals)
  const newConfigState = {
    ...configState,
    signals: adaptedSignals
  }

  validationSchema(isLegacyVersion)
    .validate(newConfigState, {
      abortEarly: false,
      context: {
        loopTime: newConfigState.loopTime,
        signalsToLog: newConfigState.signals.length,
        // logPeriods: newConfigState.signals.map(s => parseInt(s.samplePeriod)),
        // triggerLogPeriods: newConfigState.signals.map(s => parseInt(s.samplePeriodTriggered)),
        configuredSignals: newConfigState.signals.map(s => s.signalId)
      }
    })
    .then(content => {
      const signalOverflowErrors = getSignalOverflowErrors(content.signals)
      if (signalOverflowErrors.length > 0) {
        return Promise.reject({ isSignalOverflowError: true, signalOverflowErrors })
      } else {
        const configAdapted = {
          parameters: transformValuesForAPI(content, false),
          virtualParameters: transformVirtualParametersValuesForAPI(content)
        }
        setState({
          configAdapted,
          isApplyConfigurationDialogOpen: true,
          applyConfigurationDialogMode: SELECT_DEVICES_MODE
        })
      }
    })
    .catch(error => {
      setState({
        isApplyConfigurationDialogOpen: true,
        applyConfigurationDialogMode: ERROR_IN_CONFIG_MODE,
        alertMessagesType: 'danger',
        alertMessagesTitle: i18n['NewConfigurationCS500.generalError'],
        alertMessagesText: i18n['NewConfigurationCS500.generalErrorDescriptionApply']
      })
      if (error.isSignalOverflowError) localConfigurationError(error.signalOverflowErrors)
      else localConfigurationError(error.inner)
    })
}

export const handleSelectDevices = (event, selectedDevice, selectedDevices, setState) => {
  const i18n = translations[localStorage.getItem('user_language')]

  const isSelected = event.target.checked

  if (isSelected) {
    setState({
      ['configUpdateAlertType|' + selectedDevice.EID]: 'warning',
      ['configUpdateStatusText|' + selectedDevice.EID]: i18n['NewConfigurationCS500.waiting'],
      selectedDevices: [...selectedDevices, selectedDevice]
    })
  } else {
    setState({
      ['configUpdateAlertType|' + selectedDevice.EID]: null,
      ['configUpdateStatusText|' + selectedDevice.EID]: null,
      selectedDevices: selectedDevices.filter(device => device.id !== selectedDevice.id)
    })
  }
}

const getDeltaConfiguration = (originalConfig, adaptedConfig) => {
  const newParameters = adaptedConfig.parameters
  const originalParameters = originalConfig.parameters
  const newVirtualParameters = adaptedConfig.virtualParameters || []
  const originalVirtualParameters = originalConfig.virtualParameters || []

  const newSignals = newParameters.filter(newSignal => newSignal.id.includes('SignalSource_NV'))
  const originalSignals = originalParameters.filter(originalSignal => originalSignal.id.includes('SignalSource_NV'))
  const newSendCANMessages = newParameters.filter(newParameter => newParameter.id.includes('SignalDest_NV'))
  const originalSendCANMessages = originalParameters.filter(originalParameter =>
    originalParameter.id.includes('SignalDest_NV')
  )

  const deltaConfig = {
    parameters: [],
    virtualParameters: []
  }
  newParameters.forEach(newParam => {
    const foundParameter = originalParameters.find(originalParam => originalParam.id === newParam.id)
    if (!foundParameter || foundParameter.value !== newParam.value) {
      deltaConfig.parameters.push(newParam)
    }
  })

  originalSignals.forEach(originalSignal => {
    const foundSignal = newSignals.find(newSignal => newSignal.id === originalSignal.id)
    if (!foundSignal && originalSignal.value !== 0) {
      deltaConfig.parameters.push({
        id: originalSignal.id,
        value: 0
      })
      deltaConfig.virtualParameters.push(
        {
          id: originalSignal.id,
          name: null
        },
        {
          id: originalSignal.id,
          unit: null
        }
      )
    }
  })

  originalSendCANMessages.forEach(originalSendCANMessage => {
    const foundSendCANMessage = newSendCANMessages.find(
      newSendCANMessage => newSendCANMessage.id === originalSendCANMessage.id
    )
    if (!foundSendCANMessage && originalSendCANMessage.value !== 0) {
      deltaConfig.parameters.push({
        id: originalSendCANMessage.id,
        value: 0
      })
    }
  })

  newVirtualParameters.forEach(newVirtualParam => {
    const foundVirtualParameter = originalVirtualParameters.find(
      originalVirtualParam => originalVirtualParam.id === newVirtualParam.id
    )
    if (!foundVirtualParameter || !_isEqual(foundVirtualParameter, newVirtualParam)) {
      deltaConfig.virtualParameters.push(newVirtualParam)
    }
  })

  return deltaConfig
}

export const applyConfiguration = (
  selectedDevices,
  setState,
  getDeviceNonVolatileConfiguration,
  groupId,
  setDeviceNonVolatileConfiguration,
  config
) => {
  const i18n = translations[localStorage.getItem('user_language')]
  setState({
    applyConfigurationDialogMode: UPDATE_CONFIG_MODE
  })

  selectedDevices.forEach(device => {
    const deviceEid = device.EID

    setState({
      ['configUpdateStatusText|' + device.EID]: i18n['NewConfigurationCS500.applying'],
      ['configUpdateAlertType|' + device.EID]: 'warning'
    })

    getDeviceNonVolatileConfiguration(groupId, deviceEid)
      .then(response => {
        const originalConfig = response.data

        const deltaConfig = getDeltaConfiguration(originalConfig, config)
        const isConfigToApplyEmpty = deltaConfig.parameters.length === 0 && deltaConfig.virtualParameters.length === 0

        if (isConfigToApplyEmpty) {
          setState({
            ['configUpdateStatusText|' + device.EID]: i18n['NewConfigurationCS500.sameConfig'],
            ['configUpdateAlertType|' + device.EID]: 'info'
          })
        } else {
          const configToApply = {
            parameters: deltaConfig.parameters,
            virtualParameters: deltaConfig.virtualParameters
          }

          setDeviceNonVolatileConfiguration(groupId, deviceEid, configToApply)
            .then(() => {
              setState({
                ['configUpdateStatusText|' + device.EID]:
                  i18n['NewConfigurationCS500.nvConfigurationUpdatedSuccessfully'],
                ['configUpdateAlertType|' + device.EID]: 'success'
              })
            })
            .catch(err => {
              setState({
                applyingConfiguration: false
              })
              const { error } = { ...err }
              if (error.response) {
                const message = error.response.data?.message ? ': ' + error.response.data.message : ''
                switch (error.response.status) {
                  case 400:
                    setState({
                      ['configUpdateStatusText|' + device.EID]:
                        new IntlMessageFormat(i18n['NewConfigurationCS500.error']).format({ number: '400' }) +
                        ': ' +
                        i18n['NewConfigurationCS500.error400Message'] +
                        message,
                      ['configUpdateAlertType|' + device.EID]: 'danger'
                    })
                    break
                  case 401:
                    setState({
                      ['configUpdateStatusText|' + device.EID]:
                        new IntlMessageFormat(i18n['NewConfigurationCS500.error']).format({ number: '401' }) +
                        ': ' +
                        i18n['NewConfigurationCS500.error401Message'] +
                        message,
                      ['configUpdateAlertType|' + device.EID]: 'danger'
                    })
                    break
                  case 403:
                    setState({
                      ['configUpdateStatusText|' + device.EID]:
                        new IntlMessageFormat(i18n['NewConfigurationCS500.error']).format({ number: '403' }) +
                        ': ' +
                        i18n['NewConfigurationCS500.error403Message'] +
                        message,
                      ['configUpdateAlertType|' + device.EID]: 'danger'
                    })
                    break
                  case 404:
                    setState({
                      ['configUpdateStatusText|' + device.EID]:
                        new IntlMessageFormat(i18n['NewConfigurationCS500.error']).format({ number: '404' }) +
                        ': ' +
                        i18n['NewConfigurationCS500.error404Message'] +
                        message,
                      ['configUpdateAlertType|' + device.EID]: 'danger'
                    })
                    break
                  case 406:
                    setState({
                      ['configUpdateStatusText|' + device.EID]:
                        new IntlMessageFormat(i18n['NewConfigurationCS500.error']).format({ number: '406' }) +
                        ': ' +
                        i18n['NewConfigurationCS500.error406Message'] +
                        message,
                      ['configUpdateAlertType|' + device.EID]: 'danger'
                    })
                    break
                  case 409:
                    setState({
                      ['configUpdateStatusText|' + device.EID]:
                        new IntlMessageFormat(i18n['NewConfigurationCS500.error']).format({ number: '409' }) +
                        ': ' +
                        i18n['NewConfigurationCS500.error409Message'] +
                        message,
                      ['configUpdateAlertType|' + device.EID]: 'danger'
                    })
                    break
                  case 415:
                    setState({
                      ['configUpdateStatusText|' + device.EID]:
                        new IntlMessageFormat(i18n['NewConfigurationCS500.error']).format({ number: '415' }) +
                        ': ' +
                        i18n['NewConfigurationCS500.error415Message'] +
                        message,
                      ['configUpdateAlertType|' + device.EID]: 'danger'
                    })
                    break
                  case 422:
                    setState({
                      ['configUpdateStatusText|' + device.EID]:
                        new IntlMessageFormat(i18n['NewConfigurationCS500.error']).format({ number: '422' }) +
                        ': ' +
                        i18n['NewConfigurationCS500.error422Message'] +
                        message,
                      ['configUpdateAlertType|' + device.EID]: 'danger'
                    })
                    break
                  case 500:
                    setState({
                      ['configUpdateStatusText|' + device.EID]:
                        new IntlMessageFormat(i18n['NewConfigurationCS500.error']).format({ number: '500' }) +
                        ': ' +
                        i18n['NewConfigurationCS500.error500Message'] +
                        message,
                      ['configUpdateAlertType|' + device.EID]: 'danger'
                    })
                    break
                  default:
                    setState({
                      ['configUpdateStatusText|' + device.EID]:
                        i18n['NewConfigurationCS500.errorUndefinedTitle'] +
                        ': ' +
                        i18n['NewConfigurationCS500.errorUndefinedMessage'] +
                        message,
                      ['configUpdateAlertType|' + device.EID]: 'danger'
                    })
                }
              } else {
                setState({
                  ['configUpdateStatusText|' + device.EID]:
                    i18n['NewConfigurationCS500.errorUndefinedTitle'] +
                    ': ' +
                    i18n['NewConfigurationCS500.errorUndefinedMessage'],
                  ['configUpdateAlertType|' + device.EID]: 'danger'
                })
              }
            })
        }
      })
      .catch(response => {
        const { error } = { ...response }

        const errorText = error?.response?.data?.message ? error.response.data.message : ''
        setState({
          ['configUpdateAlertType|' + device.EID]: 'danger',
          ['configUpdateStatusText|' + device.EID]: errorText
        })
      })
  })
}
