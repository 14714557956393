import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { utils as groupUtils } from 'ducks/groups'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import Home from './Home'

const mapStateToProps = (state, { location }) => {
  const notificationGroupId = location?.search ? location?.search.replace('?groupId=', '') : ''
  return {
    canReadNotifications: resolvePermissions(state, c.NOTIFICATION_READ),
    notificationGroupId,
    currentGroupId: groupUtils.getSelectedGroup(state).id,
    defaultGroupId: groupUtils.getDefaultGroupId(state)
  }
}

export default withRouter(connect(mapStateToProps)(Home))

export { NotificationsBell } from './components'
export { getNotificationsUrl, NOTIFICATIONS_DETAIL_TEMPLATE_URL } from './urls'
