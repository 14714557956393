import { defineMessages } from 'react-intl'

const namespace = 'gmaps.AssignMachinesStep'

const messages = defineMessages({
  errorText: {
    id: `${namespace}.errorText`,
    description: 'Error title',
    defaultMessage: 'Error'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error400Text: {
    id: `${namespace}.error400Text`,
    description: 'Error 400 text',
    defaultMessage: 'Bad request'
  },
  error401Text: {
    id: `${namespace}.error401Text`,
    description: 'Error 401 text',
    defaultMessage: 'Invalid credentials'
  },
  error403Text: {
    id: `${namespace}.error403Text`,
    description: 'Error 403 text',
    defaultMessage: 'Access denied'
  },
  error404Text: {
    id: `${namespace}.error404Text`,
    description: 'Error 404 text',
    defaultMessage: 'API url not found'
  },
  error406Text: {
    id: `${namespace}.error406Text`,
    description: 'Error 406 text',
    defaultMessage: 'Not acceptable'
  },
  error409Text: {
    id: `${namespace}.error409Text`,
    description: 'Error 409 text',
    defaultMessage: 'Data integrity violation.'
  },
  error415Text: {
    id: `${namespace}.error415Text`,
    description: 'Error 415 text',
    defaultMessage: 'Unsupported media type'
  },
  error422Text: {
    id: `${namespace}.error422Text`,
    description: 'Error 422 text',
    defaultMessage: 'Validation failed'
  },
  error500Text: {
    id: `${namespace}.error500Text`,
    description: 'Error 500 text',
    defaultMessage: 'Access denied'
  },
  error500ToSHow: {
    id: `${namespace}.error500ToSHow`,
    description: 'Error 500 to show message',
    defaultMessage: 'Server error'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedText: {
    id: `${namespace}.errorUndefinedText`,
    description: 'Error undefined text',
    defaultMessage: 'No description avaliable.'
  },
  errorRequestingMachines: {
    id: `${namespace}.errorRequestingMachines`,
    description: 'Error requesting machines',
    defaultMessage: 'Error requesting machines'
  },
  errorRequestingData: {
    id: `${namespace}.errorRequestingData`,
    description: 'No machines ',
    defaultMessage: 'No machines available'
  },
  assignedMachines: {
    id: `${namespace}.assignedMachines`,
    description: 'Assigned machines',
    defaultMessage: 'Assigned machines'
  },
  assignMachines: {
    id: `${namespace}.assignMachines`,
    description: 'Assign machines button label',
    defaultMessage: 'Assign machines'
  },
  viewAssignedMachines: {
    id: `${namespace}.viewAssignedMachines`,
    description: 'View assigned machines button label',
    defaultMessage: 'View assigned machines'
  },
  stepBack: {
    id: `${namespace}.stepBack`,
    description: 'Back',
    defaultMessage: 'BACK'
  },
  nextStep: {
    id: `${namespace}.nextStep`,
    description: 'Next',
    defaultMessage: 'NEXT'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Notifications previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Notifications next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'Notifications first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Notifications last page button text',
    defaultMessage: 'Last'
  },
  noMachines: {
    id: `${namespace}.noMachines`,
    description: 'No machines available message',
    defaultMessage: 'No machines are available.'
  },
  unassignMachines: {
    id: `${namespace}.unassignMachines`,
    description: 'Unassign machines title',
    defaultMessage: 'Unassign {number} machines'
  },
  unassignMachinesError: {
    id: `${namespace}.unassignMachinesError`,
    description: 'Unassign machines error text',
    defaultMessage: 'An error occurred while trying to unassign the selected machines.'
  },
  assignAtLeastAMachine: {
    id: `${namespace}.assignAtLeastAMachine`,
    description: 'Assign a machine text',
    defaultMessage: 'At least one machine must be assigned to the notification.'
  },
  machinesUnassignedCorrectly: {
    id: `${namespace}.machinesUnassignedCorrectly`,
    description: 'Machines unassigned correctly message',
    defaultMessage: 'Machines unassigned correctly.'
  },
  unassignMachine: {
    id: `${namespace}.unassignMachine`,
    description: 'Unassign machine button title',
    defaultMessage: 'Unassign machine'
  },
  showingRows: {
    id: `${namespace}.showingRows`,
    description: 'Assign machines table total text showing rows',
    defaultMessage: 'Showing machines'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Assign machines table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Assign machines table total text of',
    defaultMessage: 'of'
  },
  selectedMachines: {
    id: `${namespace}.selectedMachines`,
    description: 'Selected machines button label',
    defaultMessage: 'Selected machines'
  },
  clearSelection: {
    id: `${namespace}.clearSelection`,
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },
  unassign: {
    id: `${namespace}.unassign`,
    description: 'Unassign button label',
    defaultMessage: 'Unassign'
  },
  searchPlaceholder: {
    id: `${namespace}.searchPlaceholder`,
    description: 'Search placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name table header column label',
    defaultMessage: 'Name'
  },
  deviceType: {
    id: `${namespace}.deviceType`,
    description: 'Device type table column header label',
    defaultMessage: 'Device type'
  },
  eid: {
    id: `${namespace}.eid`,
    description: 'EID  table header column label',
    defaultMessage: 'EID'
  },
  activeConfiguration: {
    id: `${namespace}.activeConfiguration`,
    description: 'Active configuration text',
    defaultMessage: 'Active configuration'
  },
  activeConfigurationVersion: {
    id: `${namespace}.activeConfigurationVersion`,
    description: 'Active configuration version text',
    defaultMessage: 'Active configuration version'
  },
  actions: {
    id: `${namespace}.actions`,
    description: 'Actions table header column label',
    defaultMessage: 'Actions'
  },
  confirmMachineUnassignament: {
    id: `${namespace}.confirmMachineUnassignament`,
    description: 'Confirm machine unassignment message',
    defaultMessage: 'Confirm machine unassignment:'
  },
  noMachinesUnassigned: {
    id: `${namespace}.noMachinesUnassigned`,
    description: 'No machines unassigned message',
    defaultMessage: 'No machines unassigned'
  },
  thereAreCurrentlyNoMachines: {
    id: `${namespace}.thereAreCurrentlyNoMachines`,
    description: 'There are currently no machines message',
    defaultMessage: 'There are currently no machines.'
  },
  noMachinesAssigned: {
    id: `${namespace}.noMachinesAssigned`,
    description: 'No machines assigned message',
    defaultMessage: 'No machines assigned'
  },
  machinesAssignedCorrectly: {
    id: `${namespace}.machinesAssignedCorrectly`,
    description: 'Machines assigned correctly message',
    defaultMessage: 'Machines assigned correctly.'
  },
  machineConditionTextNoSelectableMachines: {
    id: `${namespace}.machineConditionTextNoSelectableMachines`,
    description: 'Machine no selectables explanation',
    defaultMessage: 'Non-selectable machines are already assigned to the notification.'
  },
  placeholder: {
    id: `${namespace}.placeholder`,
    description: 'Placeholder text',
    defaultMessage: 'Enter {column}'
  },
  machineModel: {
    id: `${namespace}.machineModel`,
    description: 'Machine model',
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    description: 'Machine type',
    defaultMessage: 'Machine type'
  },
  machineBrand: {
    id: `${namespace}.machineBrand`,
    description: 'Machine brand',
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    description: 'Machine serial number',
    defaultMessage: 'Machine serial number'
  },
  lastTelemetryMessage: {
    id: `${namespace}.lastTelemetryMessage`,
    description: 'Last telemetry message',
    defaultMessage: 'Last telemetry message'
  },
  firmwareVersion: {
    id: `${namespace}.firmwareVersion`,
    description: 'Firmware version',
    defaultMessage: 'Firmware version'
  },
  deviceConfiguration: {
    id: `${namespace}.deviceConfiguration`,
    description: 'Device configuration',
    defaultMessage: 'Device configuration'
  },
  deviceConfigurationVersion: {
    id: `${namespace}.deviceConfigurationVersion`,
    description: 'Device configuration version',
    defaultMessage: 'Device configuration version'
  }
})

export default messages
