import { isEqual, cloneDeep } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import 'core-js/proposals/promise-all-settled'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'

import Alert from 'components/Alert'
import PageTitle from 'components/PageTitle'
import DataPlans from 'modules/dataPlans'
import { DEFAULT_GROUP_ID } from 'utils/constants'
import { logError, mapDynamicDevicesFilterObject } from 'utils/http'
import client from 'utils/http/client'

import DevicesTable from './DevicesTable'
import messages from '../messages'
import { getRegisterDeviceUrl } from '../urls'

class Devices extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      devices: [],
      SIMStatuses: [],
      noDataText: <CircularProgress />,
      start: 0,
      sizePerPage: 10,
      count: 0,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: [''],
      closeFunction: false,
      personalDataplans: [],
      groupDataplans: [],
      dataPlansError: false,
      dataPlansLoaded: false,

      filter: {},
      sort: {}
    }
  }

  componentDidMount() {
    const {
      location: { state, pathname },
      history
    } = this.props
    if (state?.action) {
      const { action } = state
      this.setState({
        alertMessages: true,
        alertMessagesType: 'success',
        alertMessagesTitle: '',
        closeFunction: true,
        alertMessagesText: [this.formatMessage(messages[action])]
      })
      history.replace(pathname, undefined)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      groupId,
      history,
      loadingGroup,
      location: { state, pathname }
    } = this.props

    if (typeof state === 'string' && prevProps.location.state === undefined) {
      this.setState({
        alertMessages: true,
        alertMessagesType: 'success',
        alertMessagesTitle: '',
        closeFunction: true,
        alertMessagesText: [this.formatMessage(messages[state])]
      })
      history.replace(pathname, undefined)
    }
    if (state?.action && prevProps.location.state === undefined) {
      const { action, ...rest } = state
      this.setState({
        alertMessages: true,
        alertMessagesType: 'success',
        alertMessagesTitle: '',
        closeFunction: true,
        alertMessagesText: [this.formatMessage(messages[action], rest)]
      })
      history.replace(pathname, undefined)
    }
    if (prevProps.groupId !== groupId) {
      this.updateData('devices', 'dataplans', 'SIMStatuses')
    }
    if (prevProps.loadingGroup && !loadingGroup) {
      this.updateData('devices', 'dataplans', 'SIMStatuses')
    }
  }

  getData = async (...fields) => {
    const { canReadDataPlans, getDynamicCSNodes, groupId, getCSNodeSIMStatus, canConsumeAccessPasses } = this.props
    const { personalDataplans, groupDataplans, sizePerPage, start, SIMStatuses, filter, sort } = this.state

    try {
      if (fields.includes('dataplans')) {
        const newPersonalDataplans = canReadDataPlans ? await this.getDataPlans(personalDataplans) : []
        const newGroupDataplans = canReadDataPlans ? await this.getDataPlans(groupDataplans, groupId) : []
        this.setState({
          personalDataplans: newPersonalDataplans,
          groupDataplans: newGroupDataplans,
          dataPlansError: false,
          dataPlansLoaded: true
        })
      }

      const deviceFields = {
        Device: [
          'id',
          'device_type',
          'name',
          'eid',
          'dataplan',
          'description',
          'last_activity_time',
          'serial_number',
          'machine_model',
          'machine_type',
          'machine_serial_number'
        ]
      }
      if (canConsumeAccessPasses) {
        deviceFields.Device.push('accesspass', 'allow_consumption')
        deviceFields.DeviceAccessPassStatus = ['name']
      }
      const mappedFilters = mapDynamicDevicesFilterObject(filter, sort)
      const devicesResponse = await getDynamicCSNodes(groupId, sizePerPage, start, deviceFields, mappedFilters)
      const { devices, total } = devicesResponse.data

      const cleanEIDs = devices.map(({ eid }) => 'm' + eid.replace(/:/g, ''))
      const mobileSignalsResponse = await client.getMobileSignals({ groupId, eids: cleanEIDs })
      const updatedDevices = cloneDeep(devices)
      if (mobileSignalsResponse.data !== '') {
        const mobileSignals = mobileSignalsResponse.data?.content || []
        updatedDevices.forEach((device, index) => {
          const formattedEid = 'm' + device.eid.replace(/:/g, '')
          const mobileSignal = mobileSignals.find(mobileSignal => mobileSignal.deviceEid === formattedEid) || {}
          updatedDevices[index].mobileSignal = mobileSignal
        })
      }

      const lastGPSSignalsResponse = await client.getDevicesLastGPSLocation({
        eids: cleanEIDs,
        page: 0,
        size: cleanEIDs.length
      })
      if (lastGPSSignalsResponse.data !== '') {
        const lastGPSSignals = lastGPSSignalsResponse.data?.content || []
        updatedDevices.forEach((device, index) => {
          const formattedEid = 'm' + device.eid.replace(/:/g, '')
          const lastGPSSignal = lastGPSSignals.find(lastGPSSignal => lastGPSSignal.eid === formattedEid) || {}
          updatedDevices[index].lastGPSTimestamp = lastGPSSignal.timestamp
        })
      }

      this.setState({
        devices: updatedDevices,
        count: total
      })

      if (fields.includes('SIMStatuses')) {
        const slicedDevices = updatedDevices
        const noCS10Devices = slicedDevices.filter(device => device.device_type !== 'CS10')
        let newSIMStatuses = SIMStatuses
        const deviceSIMStatusRequests = noCS10Devices.map(device => getCSNodeSIMStatus(groupId, device.id))
        newSIMStatuses = await Promise.allSettled(deviceSIMStatusRequests)
        const SIMStatusesWithDeviceId = newSIMStatuses.map((SIMStatus, index) => ({
          ...SIMStatus,
          deviceId: noCS10Devices[index].id
        }))
        const { devices: stateDevices } = this.state
        const isTheLatestSIMStatusUpdate = isEqual(slicedDevices, stateDevices)
        if (isTheLatestSIMStatusUpdate) {
          this.setState({
            SIMStatuses: SIMStatusesWithDeviceId
          })
        }
      }
    } catch (response) {
      const { error } = { ...response }
      if (error && error.response) {
        switch (error.response.status) {
          case 400:
          case 401:
          case 403:
          case 406:
          case 500:
            this.setState({
              devices: [],
              count: 0,
              noDataText: this.formatMessage(messages.errorRequestingDevices),
              alertMessages: true,
              closeFunction: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: error.response.status.toString()
              }),
              alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
            })
            break
          case 404:
            let error404Checked = false
            if (error.response.data !== undefined) {
              let errorReceived
              if (error.response.data !== undefined) errorReceived = error.response.data
              else errorReceived = error.response.data
              if (
                errorReceived.includes('NO DEVICE EXISTS') ||
                errorReceived.includes('NO DEVICE IS ASSOCIATED TO THIS USER IN THIS GROUP')
              ) {
                error404Checked = true
                this.setState({
                  devices: [],
                  count: 0,
                  noDataText: this.formatMessage(messages.noDevicesText)
                })
              } else if (errorReceived.includes('INVALID TOKEN')) {
                error404Checked = true
                this.setState({
                  devices: [],
                  count: 0,
                  noDataText: this.formatMessage(messages.errorRequestingDevices)
                })
              }
            }
            if (!error404Checked) {
              this.setState({
                devices: [],
                count: 0,
                noDataText: this.formatMessage(messages.errorRequestingDevices),
                alertMessages: true,
                closeFunction: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {
                  number: '404'
                }),
                alertMessagesText: [this.formatMessage(messages.error404Message)]
              })
            }
            break
          default:
            this.setState({
              devices: [],
              count: 0,
              noDataText: this.formatMessage(messages.errorRequestingDevices),
              alertMessages: true,
              closeFunction: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(error)
        }
      } else {
        this.setState({
          devices: [],
          count: 0,
          noDataText: this.formatMessage(messages.errorRequestingDevices),
          alertMessages: true,
          closeFunction: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
          alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
        })
        logError(error)
      }
    }
  }

  getDataPlans = async (dataplans, groupId = '') => {
    const { canReadDataPlans, getDataPlans } = this.props
    let newDataplans = dataplans
    if (canReadDataPlans) {
      try {
        const limit = 25
        let offset = 0
        let next = true
        while (next) {
          const dataplansResponse = await getDataPlans(groupId, limit, offset)
          if (dataplansResponse.data.length === limit) {
            offset += 25
            next = true
            newDataplans = newDataplans.concat(dataplansResponse.data)
          } else {
            next = false
            newDataplans = newDataplans.concat(dataplansResponse.data)
          }
        }
      } catch (response) {
        const { error } = { ...response }
        if (error?.response?.status === 404) {
          const errorReceived = error?.response?.data
          if (errorReceived.includes('DIGITAL SERVICE PLAN RESOURCE NOT FOUND FOR THIS GROUP')) {
            this.setState({ dataPlansError: false, dataPlansLoaded: true })
          }
        } else {
          this.setState({ dataPlansError: true, dataPlansLoaded: true })
        }
      }
    } else newDataplans = []
    return newDataplans
  }

  updateData = (...fields) => {
    const updatingState = {}
    if (fields.includes('devices')) updatingState.devices = []
    if (fields.includes('SIMStatuses')) updatingState.SIMStatuses = []
    if (fields.includes('dataplans')) {
      updatingState.personalDataplans = []
      updatingState.groupDataplans = []
      updatingState.dataPlansError = false
      updatingState.dataPlansLoaded = false
    }
    this.setState({ ...updatingState, noDataText: <CircularProgress /> }, () => {
      this.getData(...fields)
    })
  }

  handlePageChange = (start, sizePerPage) => {
    this.setState(
      {
        start,
        sizePerPage
      },
      () => {
        this.updateData('devices', 'SIMStatuses')
      }
    )
  }

  handleSizePerPageList = sizePerPage => {
    this.setState({ sizePerPage }, () => {
      this.updateData('devices', 'SIMStatuses')
    })
  }

  handleFilterChange = filterObject => {
    this.setState(
      {
        start: 0,
        filter: filterObject
      },
      () => {
        this.updateData('devices', 'SIMStatuses')
      }
    )
  }

  handleSortChange = (sortName, sortOrder) => {
    this.setState(
      {
        start: 0,
        sort: {
          sortColumnKey: sortName,
          sortColumnModel: 'Device',
          sortColumnOrder: sortOrder.toUpperCase()
        }
      },
      () => {
        this.updateData('devices', 'SIMStatuses')
      }
    )
  }

  onAlertStateChange = args => {
    this.setState({
      ...args.alertMessages !== undefined && { alertMessages: args.alertMessages },
      ...args.alertMessagesType !== undefined && { alertMessagesType: args.alertMessagesType },
      ...args.alertMessagesTitle !== undefined && { alertMessagesTitle: args.alertMessagesTitle },
      ...args.alertMessagesText !== undefined && { alertMessagesText: args.alertMessagesText },
      ...args.closeFunction !== undefined && { closeFunction: args.closeFunction }
    })
  }

  closeAlert = () => {
    this.onAlertStateChange({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: [''],
      closeFunction: false
    })
  }

  render() {
    const {
      canCreateDevices,
      canReadDataPlans,
      canUseProductionTestMode,
      canWriteDevices,
      canConsumeAccessPasses,
      groupId,
      unregisterCS100Device
    } = this.props
    const {
      SIMStatuses,
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      closeFunction,
      count,
      dataPlansError,
      dataPlansLoaded,
      personalDataplans,
      groupDataplans,
      devices,
      noDataText,
      sizePerPage
    } = this.state

    const registerDeviceButton =
      canCreateDevices && groupId === DEFAULT_GROUP_ID
        ? {
          icon: 'zmdi-plus',
          label: this.formatMessage(messages.addDevice),
          path: getRegisterDeviceUrl(),
          primary: true
        }
        : null

    const personalAndGroupDataplans = [...new Set([...personalDataplans, ...groupDataplans])]
    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <PageTitle button={registerDeviceButton} title={this.formatMessage(messages.title)} />
          </div>
          <div className='row' style={{ margin: '20px 0 0 0' }}>
            <div className='col-md-12'>
              <Alert
                alertType={alertMessagesType}
                closeFunction={closeFunction ? this.closeAlert : undefined}
                messageText={alertMessagesText}
                messageTitle={alertMessagesTitle}
                showAlert={alertMessages}
              />
              <DevicesTable
                SIMStatuses={SIMStatuses}
                canConsumeAccessPasses={canConsumeAccessPasses}
                canUseProductionTestMode={canUseProductionTestMode}
                canWriteDevices={canWriteDevices}
                count={count}
                dataplans={personalAndGroupDataplans}
                devices={devices}
                getDevices={() => {
                  this.getData('devices', 'dataplans', 'SIMStatuses')
                }}
                groupId={groupId}
                noDataText={noDataText}
                onFilterChange={this.handleFilterChange}
                onPageChange={this.handlePageChange}
                onSizePerPageList={this.handleSizePerPageList}
                onSortChange={this.handleSortChange}
                sizePerPage={sizePerPage}
                unregisterCS100Device={unregisterCS100Device}
                updateDevices={() => {
                  this.updateData('devices', 'dataplans', 'SIMStatuses')
                }}
              />
            </div>
          </div>
        </div>
        <div>
          {canReadDataPlans && (
            <DataPlans
              canWriteDevices={canWriteDevices}
              dataPlansError={dataPlansError}
              dataPlansLoaded={dataPlansLoaded}
              groupDataplans={groupDataplans}
              personalDataplans={personalDataplans}
              updateDataplans={() => this.updateData('dataplans')}
              updateDevices={() => {
                this.updateData('devices', 'SIMStatuses', 'dataplans')
              }}
            />
          )}
        </div>
      </div>
    )
  }
}

Devices.propTypes = {
  canConsumeAccessPasses: PropTypes.bool.isRequired,
  canCreateDevices: PropTypes.bool.isRequired,
  canReadDataPlans: PropTypes.bool.isRequired,
  canUseProductionTestMode: PropTypes.bool.isRequired,
  canWriteDevices: PropTypes.bool.isRequired,
  getCSNodeSIMStatus: PropTypes.func.isRequired,
  getDataPlans: PropTypes.func.isRequired,
  getDynamicCSNodes: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  loadingGroup: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  unregisterCS100Device: PropTypes.func.isRequired
}

export default withRouter(injectIntl(Devices))
