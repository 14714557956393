import { PropTypes } from 'prop-types'
import React from 'react'

import LinkedLogo from './LinkedLogo'
import './styles.css'

const Logo = ({ isCollapsed = false, logo, mapUrl, isClickable = true }) => {
  return (
    <div className='logo'>
      <LinkedLogo isClickable={isClickable} isCollapsed={isCollapsed} link={mapUrl} logo={logo} />
    </div>
  )
}

Logo.propTypes = {
  isClickable: PropTypes.bool.isRequired
}

export { Logo }
