import { initialValues, initSignal, initCANMessage } from 'modules/configurationsCS500/config'
import { SIGNEDNESS_VALID_VALUES, ENDIANNESS_VALID_VALUES } from 'modules/configurationsCS500/config/config'

const sendCANMessageBaseRegExpStringTemplate = 'CANSend(##n##)_'
const signalConfigBaseRegExpStringTemplate = 'SignalConfig(##n##)__(##m##)_'
const signalVirtualParameterRegExpStringTemplate = 'SignalConfig(##n##)__(##m##)_SignalSource_NV'

function getRegExpStringWithReplacedId(signalConfigRegExpStringTemplate, n, m = '') {
  return signalConfigRegExpStringTemplate.replace('##n##', n).replace('##m##', m)
}

const signalSourceValidValues = {
  CAN_J1939: 1,
  CAN_CUSTOMIZED: 2,
  MFIO: 3
}

const sendCANMessagesSignalDestValidValues = {
  // CAN_J1939: 1, // currently not supported
  CAN_CUSTOMIZED: 2
}

const unitTypeValidValues = {
  PREDEFINED_UNIT_TYPE: 0,
  CUSTOM_UNIT_TYPE: 1
}

const generalConfigMappingRegExpTemplates = {
  nodeId: 'Node_NV',
  nodeIdErrorMessage: '',
  loopTime: 'Looptime_NV',
  loopTimeErrorMessage: '',
  nodeId0: 'CAN0_Node_NV',
  nodeId0ErrorMessage: '',
  nodeId1: 'CAN1_Node_NV',
  nodeId1ErrorMessage: '',
  baudRate0: 'CAN0_Baudrate_NV',
  baudRate0ErrorMessage: '',
  baudRate1: 'CAN1_Baudrate_NV',
  baudRate1ErrorMessage: '',
  sa0: 'CAN0_SA_NV',
  sa0ErrorMessage: '',
  sa1: 'CAN1_SA_NV',
  sa1ErrorMessage: '',
  loggingUploadPeriod0: 'Datalog_0_Period_NV',
  loggingUploadPeriod0ErrorMessage: '',
  loggingUploadPeriod1: 'Datalog_1_Period_NV',
  loggingUploadPeriod1ErrorMessage: '',
  interlinkEnabled: 'Interlink_Enable_NV',
  fotaUpdateEnabled: 'FOTA_Enable_NV',
  restartCellularModule: 'RestartCellularModule_NV',
  // GNSS
  gnssPositionEnabled: 'GNSS_Config_PositionLogTime_NV',
  positionLogTime: 'GNSS_Config_PositionLogTime_NV',
  positionLogTimeErrorMessage: '',
  gnssAltitudeEnabled: 'GNSS_Config_AltitudeLogTime_NV',
  altitudeLogTime: 'GNSS_Config_AltitudeLogTime_NV',
  altitudeLogTimeErrorMessage: '',
  gnssSpeedEnabled: 'GNSS_Config_SpeedLogTime_NV',
  speedLogTime: 'GNSS_Config_SpeedLogTime_NV',
  speedLogTimeErrorMessage: '',
  gnssHeadingEnabled: 'GNSS_Config_HeadingLogTime_NV',
  headingLogTime: 'GNSS_Config_HeadingLogTime_NV',
  headingLogTimeErrorMessage: '',
  // GEOFENCE
  geofenceEnabled: 'Geofence_Config_Enable_NV',
  geofenceLogToPortal: 'Geofence_Config_LogToPortal_NV',
  geofenceSendImmediately: 'Geofence_Config_SendImmediatly_NV',
  geofenceSamplePeriod: 'Geofence_Config_Sampleperiod_NV',
  geofenceSamplePeriodErrorMessage: '',
  geofenceLatitude: 'Geofence_Config_Latitude_NV',
  geofenceLatitudeErrorMessage: '',
  geofenceLongitude: 'Geofence_Config_Longitude_NV',
  geofenceLongitudeErrorMessage: '',
  geofenceRadius: 'Geofence_Config_Radius_NV',
  geofenceRadiusErrorMessage: '',
  // DM1
  dm1LogToPortal: 'DM1_LogToPortal_NV',
  dm1Port: 'DM1_Port_NV',
  dm1PortErrorMessage: '',
  dm1UseSA: 'DM1_Use_SA_NV',
  dm1SA: 'DM1_SA_NV',
  dm1SAErrorMessage: '',
  // MFIO settings
  inputSignal0: 'MFIO0_InputMode_NV',
  inputSignalErrorMessage0: '',
  bias0: 'MFIO0_Bias_NV',
  biasErrorMessage0: '',
  inputRange0: 'MFIO0_Range_NV',
  inputRangeErrorMessage0: '',
  digThreshLow0: 'MFIO0_DigTreshL_NV',
  digThreshLowErrorMessage0: '',
  digThreshHigh0: 'MFIO0_DigThresH_NV',
  digThreshHighErrorMessage0: '',
  inputSignal1: 'MFIO1_InputMode_NV',
  inputSignalErrorMessage1: '',
  bias1: 'MFIO1_Bias_NV',
  biasErrorMessage1: '',
  inputRange1: 'MFIO1_Range_NV',
  inputRangeErrorMessage1: '',
  digThreshLow1: 'MFIO1_DigTreshL_NV',
  digThreshLowErrorMessage1: '',
  digThreshHigh1: 'MFIO1_DigThresH_NV',
  digThreshHighErrorMessage1: '',
  inputSignal2: 'MFIO2_InputMode_NV',
  inputSignalErrorMessage2: '',
  bias2: 'MFIO2_Bias_NV',
  biasErrorMessage2: '',
  inputRange2: 'MFIO2_Range_NV',
  inputRangeErrorMessage2: '',
  digThreshLow2: 'MFIO2_DigTreshL_NV',
  digThreshLowErrorMessage2: '',
  digThreshHigh2: 'MFIO2_DigThresH_NV',
  digThreshHighErrorMessage2: '',
  inputSignal3: 'MFIO3_InputMode_NV',
  inputSignalErrorMessage3: '',
  bias3: 'MFIO3_Bias_NV',
  biasErrorMessage3: '',
  inputRange3: 'MFIO3_Range_NV',
  inputRangeErrorMessage3: '',
  digThreshLow3: 'MFIO3_DigTreshL_NV',
  digThreshLowErrorMessage3: '',
  digThreshHigh3: 'MFIO0_DigThresH_NV',
  digThreshHighErrorMessage3: '',
  // MACHINE STATE DETERMINATION
  forceFullAccessMode: 'SafeMode_ForcedNormal_NV',
  enableCan: 'SafeMode_CAN_Enable_NV',
  canPort: 'SafeMode_CAN_Port_NV',
  canPortErrorMessage: '',
  canId: 'SafeMode_CAN_CANMSG_ID_NV',
  canIdErrorMessage: '',
  byte0: 'SafeMode_CAN_CANMSG_Data_Byte0_NV',
  byte0ErrorMessage: '',
  byte1: 'SafeMode_CAN_CANMSG_Data_Byte1_NV',
  byte1ErrorMessage: '',
  byte2: 'SafeMode_CAN_CANMSG_Data_Byte2_NV',
  byte2ErrorMessage: '',
  byte3: 'SafeMode_CAN_CANMSG_Data_Byte3_NV',
  byte3ErrorMessage: '',
  byte4: 'SafeMode_CAN_CANMSG_Data_Byte4_NV',
  byte4ErrorMessage: '',
  byte5: 'SafeMode_CAN_CANMSG_Data_Byte5_NV',
  byte5ErrorMessage: '',
  byte6: 'SafeMode_CAN_CANMSG_Data_Byte6_NV',
  byte6ErrorMessage: '',
  byte7: 'SafeMode_CAN_CANMSG_Data_Byte7_NV',
  byte7ErrorMessage: '',
  enableDigitalInput: 'SafeMode_Pin_Enable_NV',
  digitalInputPin: 'SafeMode_Pin_Pin_NV',
  digitalInputPinErrorMessage: '',
  enableServiceToolButton: 'SafeMode_Button_Enable_NV'
}

const sendCANMessageMappingRegExpTemplates = {
  // Basic configuration
  signalDest: sendCANMessageBaseRegExpStringTemplate + 'SignalDest_NV',
  signalDestErrorMessage: '',
  canId: sendCANMessageBaseRegExpStringTemplate + 'ID_NV',
  canIdErrorMessage: '',
  canPort: sendCANMessageBaseRegExpStringTemplate + 'CANPort_NV',
  canPortErrorMessage: '',
  // Data source selection
  byte0: sendCANMessageBaseRegExpStringTemplate + 'Byte0_NV',
  byte0ErrorMessage: '',
  byte1: sendCANMessageBaseRegExpStringTemplate + 'Byte1_NV',
  byte1ErrorMessage: '',
  byte2: sendCANMessageBaseRegExpStringTemplate + 'Byte2_NV',
  byte2ErrorMessage: '',
  byte3: sendCANMessageBaseRegExpStringTemplate + 'Byte3_NV',
  byte3ErrorMessage: '',
  byte4: sendCANMessageBaseRegExpStringTemplate + 'Byte4_NV',
  byte4ErrorMessage: '',
  byte5: sendCANMessageBaseRegExpStringTemplate + 'Byte5_NV',
  byte5ErrorMessage: '',
  byte6: sendCANMessageBaseRegExpStringTemplate + 'Byte6_NV',
  byte6ErrorMessage: '',
  byte7: sendCANMessageBaseRegExpStringTemplate + 'Byte7_NV',
  byte7ErrorMessage: '',
  dataSource: sendCANMessageBaseRegExpStringTemplate + 'DataSource_NV',
  dataSourceErrorMessage: '',
  dataLength: sendCANMessageBaseRegExpStringTemplate + 'Datalength_NV',
  dataLengthErrorMessage: '',
  virtualSignalNumberErrorMessage: '',
  // Transmit timings
  periodTypeErrorMessage: '',
  sendPeriod: sendCANMessageBaseRegExpStringTemplate + 'Sampleperiod_NV',
  sendPeriodErrorMessage: '',
  // Trigger
  lowerThreshold: sendCANMessageBaseRegExpStringTemplate + 'LThreshold_NV',
  lowerThresholdErrorMessage: '',
  periodTypeTriggeredErrorMessage: '',
  sendPeriodTriggered: sendCANMessageBaseRegExpStringTemplate + 'SampleperiodTriggered_NV',
  sendPeriodTriggeredErrorMessage: '',
  thresholdDivider: sendCANMessageBaseRegExpStringTemplate + 'Divider_NV',
  thresholdDividerErrorMessage: '',
  triggerSource: sendCANMessageBaseRegExpStringTemplate + 'TriggerSource_NV',
  triggerSourceErrorMessage: '',
  triggerType: sendCANMessageBaseRegExpStringTemplate + 'Triggertype_NV',
  triggerTypeErrorMessage: '',
  upperThreshold: sendCANMessageBaseRegExpStringTemplate + 'UThreshold_NV',
  upperThresholdErrorMessage: ''
}

const signalConfigMappingRegExpTemplates = {
  signalIdErrorMessage: '',
  nameErrorMessage: '',
  signalSource: signalConfigBaseRegExpStringTemplate + 'SignalSource_NV',
  signalSourceErrorMessage: '',
  cch: signalConfigBaseRegExpStringTemplate + 'CCH_NV',
  cchErrorMessage: '',
  piu: signalConfigBaseRegExpStringTemplate + 'PIU_NV',
  piuErrorMessage: '',
  sml: signalConfigBaseRegExpStringTemplate + 'SML_NV',
  smlErrorMessage: '',
  ss: signalConfigBaseRegExpStringTemplate + 'SS__NV',
  ssErrorMessage: '',
  lengthOfBits: signalConfigBaseRegExpStringTemplate + '_L__NV',
  lengthOfBitsErrorMessage: '',
  multiplier: signalConfigBaseRegExpStringTemplate + '_MM_NV',
  multiplierErrorMessage: '',
  divider: signalConfigBaseRegExpStringTemplate + '_DD_NV',
  dividerErrorMessage: '',
  offset: signalConfigBaseRegExpStringTemplate + '_OO_NV',
  offsetErrorMessage: '',
  format: signalConfigBaseRegExpStringTemplate + 'Format_NV',
  formatErrorMessage: '',
  unit: signalConfigBaseRegExpStringTemplate + '_UU_NV',
  unitErrorMessage: '',
  // Filter
  filterType: signalConfigBaseRegExpStringTemplate + 'Filtertype_NV',
  filterTypeErrorMessage: '',
  cornerFrequency: signalConfigBaseRegExpStringTemplate + 'Fc_AmountSamples_NV',
  cornerFrequencyErrorMessage: '',
  quotient: signalConfigBaseRegExpStringTemplate + 'Q___NV',
  quotientErrorMessage: '',
  gain: signalConfigBaseRegExpStringTemplate + 'Gain___NV',
  gainErrorMessage: '',
  // Logging
  logToPortal: signalConfigBaseRegExpStringTemplate + 'LogToPortal_NV',
  sendImmediately: signalConfigBaseRegExpStringTemplate + 'SendImmediatly_NV',
  logTypeErrorMessage: '',
  samplePeriod: signalConfigBaseRegExpStringTemplate + 'Sampleperiod_NV',
  samplePeriodErrorMessage: '',
  // Trigger
  triggerType: signalConfigBaseRegExpStringTemplate + 'Triggertype_NV',
  triggerTypeErrorMessage: '',
  triggerSource: signalConfigBaseRegExpStringTemplate + 'TriggerSource_NV',
  triggerSourceErrorMessage: '',
  triggerLogTypeErrorMessage: '',
  samplePeriodTriggered: signalConfigBaseRegExpStringTemplate + 'SampleperiodTriggered_NV',
  samplePeriodTriggeredErrorMessage: '',
  triggerLowerThreshold: signalConfigBaseRegExpStringTemplate + 'LThreshold_NV',
  triggerLowerThresholdErrorMessage: '',
  triggerUpperThreshold: signalConfigBaseRegExpStringTemplate + 'UThreshold_NV',
  triggerUpperThresholdErrorMessage: '',
  triggerThresholdDivider: signalConfigBaseRegExpStringTemplate + 'Divider_NV',
  triggerThresholdDividerErrorMessage: '',
  triggerSendImmediately: signalConfigBaseRegExpStringTemplate + 'SendImmediatlyTrigger_NV',
  triggerSendImmediatelyErrorMessage: ''
}

function loopTimeExternalMapperAsCs100() {
  const outputProperty = 'loopTime'
  const extraConfig = {}

  extraConfig[outputProperty] = 10

  return extraConfig
}

function getSignalName(signalSourceValue, signalId, customName) {
  switch (signalSourceValue) {
    case signalSourceValidValues.CAN_J1939:
      return customName !== null ? customName : `CAN J1939 - Signal ${signalId}`
    case signalSourceValidValues.CAN_CUSTOMIZED:
      return customName !== null ? customName : `CustomCAN - Signal ${signalId}`
    case signalSourceValidValues.MFIO:
      return customName !== null ? customName : `MFIO - Signal ${signalId}`

    default:
      break
  }
}

function generalExternalMapperAsCs100(config) {
  const inputProperties = ['geofenceEnabled']
  const outputProperties = ['geofenceEnabled']
  const extraConfig = {}

  inputProperties.forEach((inputProperty, i) => {
    if (typeof config[inputProperty] === 'number') {
      extraConfig[outputProperties[i]] = config[inputProperty] !== 0 ? true : false
    }
  })

  return extraConfig
}

function gnssEnabledExternalMapperAsCs100(config) {
  const inputProperties = ['positionLogTime', 'altitudeLogTime', 'speedLogTime', 'headingLogTime']
  const outputProperties = ['gnssPositionEnabled', 'gnssAltitudeEnabled', 'gnssSpeedEnabled', 'gnssHeadingEnabled']
  const extraConfig = {}

  inputProperties.forEach((inputProperty, i) => {
    if (typeof config[inputProperty] === 'number' && config[inputProperty] !== 4294967295) {
      extraConfig[outputProperties[i]] = true
    } else {
      extraConfig[outputProperties[i]] = false
    }
  })

  return extraConfig
}

function hexNumbersExternalMapperAsCs100(config) {
  const inputProperties = ['dm1SA', 'canId', 'byte0', 'byte1', 'byte2', 'byte3', 'byte4', 'byte5', 'byte6', 'byte7']
  const outputProperties = ['dm1SA', 'canId', 'byte0', 'byte1', 'byte2', 'byte3', 'byte4', 'byte5', 'byte6', 'byte7']
  const extraConfig = {}

  inputProperties.forEach((inputProperty, i) => {
    extraConfig[outputProperties[i]] = config?.[inputProperties[i]]?.toString(16).toUpperCase() || 0
  })

  return extraConfig
}

function isExtendedCanIdSendCANMessageExternalMapperAsCs100(config) {
  const inputProperty = 'canId'
  const outputProperty = 'isExtendedCanId'
  const extraConfig = {}

  if (config[inputProperty]) {
    extraConfig[outputProperty] = (config[inputProperty] >> 31 & 0x1) === 1 ? true : false
  } else {
    extraConfig[outputProperty] = false
  }

  return extraConfig
}

function sendCANMessagesHexNumbersExternalMapperAsCs100(config) {
  const inputProperties = ['byte0', 'byte1', 'byte2', 'byte3', 'byte4', 'byte5', 'byte6', 'byte7']
  const outputProperties = ['byte0', 'byte1', 'byte2', 'byte3', 'byte4', 'byte5', 'byte6', 'byte7']
  const extraConfig = {}

  inputProperties.forEach((inputProperty, i) => {
    extraConfig[outputProperties[i]] = config?.[inputProperties[i]]?.toString(16).toUpperCase() || 0
  })

  return extraConfig
}

function signednessEndiannessExternalMapperAsCs100(config) {
  const inputProperties = ['format', 'signalSource']
  const outputProperties = ['format', 'signedness', 'endianness']
  const extraConfig = {}

  // Only for signalSource===1 (custom CAN)
  if (config[inputProperties[1]] === 2) {
    extraConfig[outputProperties[0]] = config[inputProperties[0]] & 0x111111
    extraConfig[outputProperties[1]] =
      (config[inputProperties[0]] >> 1 & 0x1) === 1
        ? SIGNEDNESS_VALID_VALUES.SIGNED
        : SIGNEDNESS_VALID_VALUES.UNSIGNED
    extraConfig[outputProperties[2]] =
      (config[inputProperties[0]] >> 0 & 0x1) === 1
        ? ENDIANNESS_VALID_VALUES.BIG_ENDIAN
        : ENDIANNESS_VALID_VALUES.LITTLE_ENDIAN
  } else {
    extraConfig[outputProperties[0]] = config[inputProperties[0]]
  }

  return extraConfig
}

function sendCANMessageshexCanIdExternalMapperAsCs100(config) {
  const inputProperty = 'canId'
  const outputProperty = 'canId'
  const extraConfig = {}

  extraConfig[outputProperty] = (config[inputProperty] & 0x7fffffff).toString(16).toUpperCase()

  return extraConfig
}

function sendCANMessagesPeriodTypeExternalMapperAsCs100(config) {
  const inputProperties = ['sendPeriod', 'sendPeriodTriggered']
  const outputProperties = ['periodType', 'periodTypeTriggered']
  const extraConfig = {}

  inputProperties.forEach((inputProperty, i) => {
    if (config[inputProperty] === 4294967295) {
      extraConfig[outputProperties[i]] = 0 // Send never
    } else if (config[inputProperty] === 0) {
      extraConfig[outputProperties[i]] = 2 // Send every loop
    } else {
      extraConfig[outputProperties[i]] = 1 // Set period
    }
  })

  return extraConfig
}

function sendCANMessagesVirtualSignalNumberExternalMapperAsCs100(config) {
  const inputProperty = 'dataSource'
  const outputProperties = ['dataSource', 'virtualSignalNumber']
  const extraConfig = {}

  if (config[inputProperty] !== 255 && config[inputProperty] !== 240) {
    extraConfig[outputProperties[0]] = 0
    extraConfig[outputProperties[1]] = config[inputProperty]
  } else {
    extraConfig[outputProperties[0]] = config[inputProperty]
    extraConfig[outputProperties[1]] = ''
  }

  return extraConfig
}

function signalExternalMapperAsCs100(config) {
  const inputProperties = ['logToPortal', 'sendImmediately', 'triggerSendImmediately']
  const extraConfig = {}

  inputProperties.forEach(inputProperty => {
    if (typeof config[inputProperty] === 'number') {
      extraConfig[inputProperty] = config[inputProperty] === 1 ? true : false
    } else if (typeof config[inputProperty] === 'string' && config[inputProperty] === '') {
      extraConfig[inputProperty] = false
    }
  })

  return extraConfig
}

function signalLogTypeExternalMapperAsCs100(config) {
  const inputProperties = ['samplePeriod', 'samplePeriodTriggered']
  const outputProperties = ['logType', 'triggerLogType']
  const extraConfig = {}

  inputProperties.forEach((inputProperty, i) => {
    if (config[inputProperty] === 4294967295) {
      extraConfig[outputProperties[i]] = 1
    } else {
      extraConfig[outputProperties[i]] = 2
    }
  })

  return extraConfig
}

function triggerSourceExternalMapperAsCs100(config) {
  const inputProperty = 'triggerSource'
  const outputProperty = 'triggerSource'
  const extraConfig = {}

  if (config[inputProperty]) {
    extraConfig[outputProperty] = config[inputProperty]
  }

  return extraConfig
}

function multiplierOffsetExternalMapperAsCs100(config) {
  const inputProperties = ['multiplier', 'offset', 'divider', 'signalSource']
  const outputProperties = ['multiplier', 'offset', 'divider']
  const extraConfig = {}

  if (config[inputProperties[3]] === 2 || config[inputProperties[3]] === 3) {
    extraConfig[outputProperties[0]] = config[inputProperties[0]] / config[inputProperties[2]]
    extraConfig[outputProperties[1]] = config[inputProperties[1]] / config[inputProperties[2]]
  }

  return extraConfig
}

function hexCanIdExternalMapperAsCs100(config) {
  const inputProperties = ['piu', 'sml', 'signalSource']
  const outputProperties = ['piu', 'sml']
  const extraConfig = {}

  if (config[inputProperties[2]] === 2) {
    extraConfig[outputProperties[0]] = '0x' + (config[inputProperties[0]] & 0x7fffffff).toString(16).toUpperCase()
    extraConfig[outputProperties[1]] = '0x' + config[inputProperties[1]].toString(16).toUpperCase()
  }

  return extraConfig
}

function isExtendedCanIdExternalMapperAsCs100(config) {
  const inputProperty = 'piu'
  const outputProperty = 'isExtendedCanId'
  const extraConfig = {}

  if (config[inputProperty]) {
    extraConfig[outputProperty] = (config[inputProperty] >> 31 & 0x1) === 1 ? true : false
  }

  return extraConfig
}

function getMappedGeneralConfigAsCs100(mappingRegExpTemplates, parameters, additionalExternalMappers = []) {
  const config = {}
  const generalConfigDefaultValues = initialValues()

  Object.keys(mappingRegExpTemplates).forEach(mappingKey => {
    const mappingRegExpString = getRegExpStringWithReplacedId(mappingRegExpTemplates[mappingKey])

    if (mappingRegExpString === '') {
      config[mappingKey] = ''
    } else {
      const mappingRegExp = new RegExp('^' + mappingRegExpString)
      const mappingParameter = parameters.find(parameter => mappingRegExp.test(parameter.id)) || {}

      if (mappingParameter.value !== undefined) {
        config[mappingKey] = mappingParameter.value
      } else {
        config[mappingKey] = generalConfigDefaultValues[mappingKey] || ''
      }
    }
  })

  const extraConfig = additionalExternalMappers.reduce((accumulatedConfig, currentMapper) => {
    let currentExtraConfig = {}
    if (typeof currentMapper === 'function') {
      currentExtraConfig = currentMapper(config)
    }

    return { ...accumulatedConfig, ...currentExtraConfig }
  }, {})

  return { ...config, ...extraConfig }
}

function getMappedSendCANMessagesAsCs100(
  mappingRegExpTemplates,
  signalDest,
  parameters,
  additionalExternalMappers = []
) {
  const config = {}
  const canMessageDefaultFields = initCANMessage()

  const sendCANMessageBaseRegExpString = getRegExpStringWithReplacedId(sendCANMessageBaseRegExpStringTemplate, '[0-3]')
  const sendCANMessageBaseRegExp = new RegExp(sendCANMessageBaseRegExpString)
  const [, n] = signalDest.id.match(sendCANMessageBaseRegExp)

  const canMessageId = Number(n)
  config.canMessageId = canMessageId

  Object.keys(mappingRegExpTemplates).forEach(mappingKey => {
    const mappingRegExpString = getRegExpStringWithReplacedId(mappingRegExpTemplates[mappingKey], n)

    if (mappingRegExpString === '') {
      config[mappingKey] = ''
    } else {
      const mappingRegExp = new RegExp(mappingRegExpString)
      const mappingParameter = parameters.find(parameter => mappingRegExp.test(parameter.id)) || {}
      if (mappingParameter.value !== undefined) {
        config[mappingKey] = mappingParameter.value
      } else {
        config[mappingKey] = canMessageDefaultFields[mappingKey] || ''
      }
    }
  })

  const extraConfig = additionalExternalMappers.reduce((accumulatedConfig, currentMapper) => {
    let currentExtraConfig = {}
    if (typeof currentMapper === 'function') {
      currentExtraConfig = currentMapper(config)
    }

    return { ...accumulatedConfig, ...currentExtraConfig }
  }, {})

  return { ...config, ...extraConfig }
}

function getMappedConfigSignalsAsCs100(
  mappingRegExpTemplates,
  signalSource,
  parameters,
  virtualParameters = [],
  additionalExternalMappers = []
) {
  const config = {}
  const signalDefaultValues = initSignal()

  const signalConfigBaseRegExpString = getRegExpStringWithReplacedId(
    signalConfigBaseRegExpStringTemplate,
    '[0-9]',
    '[0-9]'
  )
  const signalConfigBaseRegExp = new RegExp(signalConfigBaseRegExpString)
  const [, n, m] = signalSource.id.match(signalConfigBaseRegExp)

  const signalId = Number(n) * 10 + Number(m)
  config.signalId = signalId
  config.editableId = false

  let customName = null
  const signalVirtualParameterRegExpString = getRegExpStringWithReplacedId(
    signalVirtualParameterRegExpStringTemplate,
    n,
    m
  )
  if (signalVirtualParameterRegExpString !== '') {
    const mappingRegExp = new RegExp(signalVirtualParameterRegExpString)
    customName =
      virtualParameters?.find(
        parameter => mappingRegExp.test(parameter.id) && parameter.name !== undefined && parameter.name !== null
      )?.name || null
  }
  config.name = getSignalName(signalSource.value, signalId, customName)

  if (
    signalVirtualParameterRegExpString !== '' &&
    (signalSource.value === signalSourceValidValues.CAN_CUSTOMIZED ||
      signalSource.value === signalSourceValidValues.MFIO)
  ) {
    const mappingRegExp = new RegExp(signalVirtualParameterRegExpString)
    let customUnit = null
    customUnit =
      virtualParameters?.find(
        parameter => mappingRegExp.test(parameter.id) && parameter.unit !== undefined && parameter.unit !== null
      )?.unit || null
    config.customUnit = customUnit
    if (customUnit) {
      config.unitType = unitTypeValidValues.CUSTOM_UNIT_TYPE
    } else {
      config.unitType = unitTypeValidValues.PREDEFINED_UNIT_TYPE
    }
  }

  Object.keys(mappingRegExpTemplates).forEach(mappingKey => {
    const mappingRegExpString = getRegExpStringWithReplacedId(mappingRegExpTemplates[mappingKey], n, m)

    if (mappingRegExpString === '') {
      config[mappingKey] = ''
    } else {
      const mappingRegExp = new RegExp(mappingRegExpString)
      const mappingParameter = parameters.find(parameter => mappingRegExp.test(parameter.id)) || {}

      if (mappingParameter.value !== undefined) {
        config[mappingKey] = mappingParameter.value
      } else {
        config[mappingKey] = signalDefaultValues[mappingKey] || ''
      }
    }
  })

  const extraConfig = additionalExternalMappers.reduce((accumulatedConfig, currentMapper) => {
    let currentExtraConfig = {}
    if (typeof currentMapper === 'function') {
      currentExtraConfig = currentMapper(config)
    }

    return { ...accumulatedConfig, ...currentExtraConfig }
  }, {})

  return { ...config, ...extraConfig }
}

function getGeneralConfigAsCs100(parameters) {
  return getMappedGeneralConfigAsCs100(generalConfigMappingRegExpTemplates, parameters, [
    generalExternalMapperAsCs100,
    gnssEnabledExternalMapperAsCs100,
    loopTimeExternalMapperAsCs100,
    hexNumbersExternalMapperAsCs100
  ])
}

function getSendCANMessagesAsCs100(signalDest, parameters) {
  return getMappedSendCANMessagesAsCs100(sendCANMessageMappingRegExpTemplates, signalDest, parameters, [
    isExtendedCanIdSendCANMessageExternalMapperAsCs100,
    sendCANMessagesPeriodTypeExternalMapperAsCs100,
    sendCANMessagesHexNumbersExternalMapperAsCs100,
    sendCANMessagesVirtualSignalNumberExternalMapperAsCs100,
    sendCANMessageshexCanIdExternalMapperAsCs100,
    triggerSourceExternalMapperAsCs100
  ])
}

function getSignalsConfigAsCs100(signalSource, parameters, virtualParameters) {
  return getMappedConfigSignalsAsCs100(
    signalConfigMappingRegExpTemplates,
    signalSource,
    parameters,
    virtualParameters,
    [
      signalExternalMapperAsCs100,
      signalLogTypeExternalMapperAsCs100,
      isExtendedCanIdExternalMapperAsCs100,
      hexCanIdExternalMapperAsCs100,
      signednessEndiannessExternalMapperAsCs100,
      triggerSourceExternalMapperAsCs100,
      multiplierOffsetExternalMapperAsCs100
    ]
  )
}

function getMfioConfigAsCs100(signalSource, parameters, virtualParameters) {
  return getMappedConfigSignalsAsCs100(
    signalConfigMappingRegExpTemplates,
    signalSource,
    parameters,
    virtualParameters,
    [
      signalExternalMapperAsCs100,
      signalLogTypeExternalMapperAsCs100,
      isExtendedCanIdExternalMapperAsCs100,
      hexCanIdExternalMapperAsCs100,
      triggerSourceExternalMapperAsCs100,
      multiplierOffsetExternalMapperAsCs100
    ]
  )
}

function getSendCANMessagesSignalDests(parameters) {
  const sendCANMessageBaseRegExpString = getRegExpStringWithReplacedId(sendCANMessageBaseRegExpStringTemplate, '[0-3]')
  const sendCANMessageRegExpString = sendCANMessageBaseRegExpString + 'SignalDest_NV'
  const sendCANMessageRegExp = new RegExp(sendCANMessageRegExpString)

  return parameters
    .filter(parameter => sendCANMessageRegExp.test(parameter.id))
    .filter(parameter => Object.values(sendCANMessagesSignalDestValidValues).includes(parameter.value))
}

function getValidSignalSources(parameters) {
  const signalConfigBaseRegExpString = getRegExpStringWithReplacedId(
    signalConfigBaseRegExpStringTemplate,
    '[0-9]',
    '[0-9]'
  )
  const signalSourceRegExpString = signalConfigBaseRegExpString + 'SignalSource_NV'
  const signalSourceRegExp = new RegExp(signalSourceRegExpString)

  return parameters
    .filter(parameter => signalSourceRegExp.test(parameter.id))
    .filter(parameter => Object.values(signalSourceValidValues).includes(parameter.value))
}

function getIsStandardApplication(config) {
  let emptyParamsNumber = 0
  const {
    loopTime,
    logToPortal,
    gnssPositionEnabled,
    sendImmediately,
    triggerSendImmediately,
    signals,
    ...generalConfigParameters
  } = config

  Object.keys(generalConfigParameters).forEach(p => {
    if (generalConfigParameters[p] === '') {
      emptyParamsNumber++
    }
  })

  if (emptyParamsNumber === Object.keys(generalConfigParameters).length && signals.length === 0) {
    return false
  } else {
    return true
  }
}

export const cs500ConfigToCs100Config = cs500Config => {
  const { parameters, virtualParameters, applicationVersion = '0.1.0', compileTime = 0 } = cs500Config

  const params = getGeneralConfigAsCs100(parameters)
  const sendCANMessagesSignalDests = getSendCANMessagesSignalDests(parameters)
  const signalSources = getValidSignalSources(parameters)

  const config = {
    ...params,
    sendCANMessages: [],
    signals: [],
    applicationVersion,
    compileTime
  }

  sendCANMessagesSignalDests.forEach(signalDest => {
    config.sendCANMessages.push(getSendCANMessagesAsCs100(signalDest, parameters))
  })
  // Order CAN messages by ID
  config.sendCANMessages.sort((a, b) => {
    return a.canMessageId - b.canMessageId
  })

  signalSources.forEach(signalSource => {
    switch (signalSource.value) {
      case signalSourceValidValues.CAN_J1939:
      case signalSourceValidValues.CAN_CUSTOMIZED:
        config.signals.push(getSignalsConfigAsCs100(signalSource, parameters, virtualParameters))
        break
      case signalSourceValidValues.MFIO:
        config.signals.push(getMfioConfigAsCs100(signalSource, parameters, virtualParameters))
        break

      default:
        break
    }
  })

  const isStandardApplication = getIsStandardApplication(config)
  config.isStandardApplication = isStandardApplication

  return config
}
