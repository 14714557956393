import { connect } from 'react-redux'

import { utils } from 'ducks/groups'

import NotificationsGlobalActions from './NotificationsGlobalActions'

const mapStateToProps = state => ({
  groupId: utils.getSelectedGroup(state).id
})

export default connect(mapStateToProps)(NotificationsGlobalActions)
