import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'

import DataConsumptionEstimation from 'modules/configurationsCS100/DataConsumptionEstimation'
import MFIO from 'modules/configurationsCS100/MFIO'
import Signal from 'modules/configurationsCS100/Signal'
import download from 'utils/fileHandler/download'
import { logError } from 'utils/http'

import messages from './messages'
import { validationSchema, transformValuesForAPI, optionsForGeneralSettings } from '../config'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  },
  checkbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  checked: {},
  createButton: {
    color: 'white',
    width: '7%',
    backgroundColor: '#5d5d5d',
    borderRadius: '2px',
    boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.1), 0px 2px 1px -2px rgba(0,0,0,0.1)'
  },
  dialog: {
    minWidth: 600
  }
}

class NewConfigurationForm extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.state = {
      anchorEl: null,
      isDialogOpen: false,
      isLoading: false
    }
    this.formatMessage = formatMessage
  }

  componentWillUnmount() {
    const { history, location, resetLocalConfiguration } = this.props

    if (history.location.pathname !== location.pathname) {
      resetLocalConfiguration()
      this.handleCloseAlertClick()
    }
  }

  handleMfioMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMfioMenuClose = () => {
    this.setState({ anchorEl: null })
  }

  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false
    })
  }

  handleDownload = () => {
    const { configState, localConfigurationError } = this.props

    validationSchema()
      .validate(configState, { abortEarly: false })
      .then(content => {
        const configAdapted = transformValuesForAPI(content)

        const fileName = configAdapted.name + '.json'
        const blob = new Blob([JSON.stringify(configAdapted, null, 2)], { type: 'application/json' })
        download(blob, fileName, 'application/json')
      })
      .catch(error => {
        localConfigurationError(error.inner)
      })
  }

  handleSave = () => {
    const { configState, localConfigurationError } = this.props
    const { alertMessageText, alertMessageTitle, alertVisibility, ...newConfiguration } = configState

    validationSchema()
      .validate(newConfiguration, { abortEarly: false })
      .then(content => {
        const dataConsumptionEstimation = content.dataConsumptionEstimation

        if (dataConsumptionEstimation <= 25) {
          const configAdapted = transformValuesForAPI(content)
          this.saveConfiguration(configAdapted)
        } else {
          this.setState({
            isDialogOpen: true
          })
        }
      })
      .catch(error => {
        localConfigurationError(error.inner)
      })
  }

  saveConfiguration = newConfiguration => {
    const { groupId, manageLocalConfigurationAlert, onCreateConfiguration, saveConfiguration } = this.props

    this.setState({
      isLoading: true
    })

    saveConfiguration(groupId, newConfiguration)
      .then(response => {
        if (response && response.status === 201) {
          this.setState({
            isLoading: false
          })
          onCreateConfiguration()
        }
      })
      .catch(response => {
        const { error } = { ...response }
        if (error.response) {
          const message = error.response.data ? ': ' + error.response.data : ''
          switch (error.response.status) {
            case 400:
              this.setState({
                isLoading: false
              })
              manageLocalConfigurationAlert({
                dialogOpen: false,
                dialogSelectedConfigurationToDeleteTitle: '',
                dialogSelectedConfigurationToDeleteId: '',
                alertVisibility: '',
                alertMessageTitle: this.formatMessage(messages.error, { number: '400' }),
                alertMessageText: this.formatMessage(messages.error400Message) + message
              })
              break
            case 401:
              this.setState({
                isLoading: false
              })
              manageLocalConfigurationAlert({
                dialogOpen: false,
                dialogSelectedConfigurationToDeleteTitle: '',
                dialogSelectedConfigurationToDeleteId: '',
                alertVisibility: '',
                alertMessageTitle: this.formatMessage(messages.error, { number: '401' }),
                alertMessageText: this.formatMessage(messages.error401Message) + message
              })
              break
            case 403:
              this.setState({
                isLoading: false
              })
              manageLocalConfigurationAlert({
                dialogOpen: false,
                dialogSelectedConfigurationToDeleteTitle: '',
                dialogSelectedConfigurationToDeleteId: '',
                alertVisibility: '',
                alertMessageTitle: this.formatMessage(messages.error, { number: '403' }),
                alertMessageText: this.formatMessage(messages.error403Message) + message
              })
              break
            case 404:
              this.setState({
                isLoading: false
              })
              manageLocalConfigurationAlert({
                dialogOpen: false,
                dialogSelectedConfigurationToDeleteTitle: '',
                dialogSelectedConfigurationToDeleteId: '',
                alertVisibility: '',
                alertMessageTitle: this.formatMessage(messages.error, { number: '404' }),
                alertMessageText: this.formatMessage(messages.error404Message)
              })
              break
            case 406:
              this.setState({
                isLoading: false
              })
              manageLocalConfigurationAlert({
                dialogOpen: false,
                dialogSelectedConfigurationToDeleteTitle: '',
                dialogSelectedConfigurationToDeleteId: '',
                alertVisibility: '',
                alertMessageTitle: this.formatMessage(messages.error, { number: '406' }),
                alertMessageText: this.formatMessage(messages.error406Message) + message
              })
              break
            case 409:
              this.setState({
                isLoading: false
              })
              manageLocalConfigurationAlert({
                dialogOpen: false,
                dialogSelectedConfigurationToDeleteTitle: '',
                dialogSelectedConfigurationToDeleteId: '',
                alertVisibility: '',
                alertMessageTitle: this.formatMessage(messages.error, { number: '409' }),
                alertMessageText: this.formatMessage(messages.error409Message) + message
              })
              break
            case 415:
              this.setState({
                isLoading: false
              })
              manageLocalConfigurationAlert({
                dialogOpen: false,
                dialogSelectedConfigurationToDeleteTitle: '',
                dialogSelectedConfigurationToDeleteId: '',
                alertVisibility: '',
                alertMessageTitle: this.formatMessage(messages.error, { number: '415' }),
                alertMessageText: this.formatMessage(messages.error415Message) + message
              })
              break
            case 422:
              this.setState({
                isLoading: false
              })
              manageLocalConfigurationAlert({
                dialogOpen: false,
                dialogSelectedConfigurationToDeleteTitle: '',
                dialogSelectedConfigurationToDeleteId: '',
                alertVisibility: '',
                alertMessageTitle: this.formatMessage(messages.error, { number: '422' }),
                alertMessageText: this.formatMessage(messages.error422Message) + message
              })
              break
            case 500:
              this.setState({
                isLoading: false
              })
              manageLocalConfigurationAlert({
                dialogOpen: false,
                dialogSelectedConfigurationToDeleteTitle: '',
                dialogSelectedConfigurationToDeleteId: '',
                alertVisibility: '',
                alertMessageTitle: this.formatMessage(messages.error, { number: '500' }),
                alertMessageText: this.formatMessage(messages.error500Message) + message
              })
              break
            default:
              this.setState({
                isLoading: false
              })
              manageLocalConfigurationAlert({
                dialogOpen: false,
                dialogSelectedConfigurationToDeleteTitle: '',
                dialogSelectedConfigurationToDeleteId: '',
                alertVisibility: '',
                alertMessageTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessageText: this.formatMessage(messages.errorUndefinedMessage) + message
              })
              logError(error)
          }
        } else {
          this.setState({
            isLoading: false
          })
          manageLocalConfigurationAlert({
            dialogOpen: false,
            dialogSelectedConfigurationToDeleteTitle: '',
            dialogSelectedConfigurationToDeleteId: '',
            alertVisibility: '',
            alertMessageTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessageText: this.formatMessage(messages.errorUndefinedMessage)
          })
          logError(error)
        }
      })
  }

  handleCloseAlertClick = () => {
    const { manageLocalConfigurationAlert } = this.props

    manageLocalConfigurationAlert({
      alertVisibility: 'hidden',
      alertMessageTitle: '',
      alertMessageText: ''
    })
  }

  render() {
    const {
      addLocalConfigurationMFIO,
      addLocalConfigurationSignal,
      changeLocalConfigurationField,
      classes,
      configState,
      configurationsUrl,
      copyLocalConfigurationSignal,
      groupId,
      selectedCanProtocolData,
      selectedCanProtocolType
    } = this.props
    const { anchorEl, isDialogOpen, isLoading } = this.state

    const configForDataEstimation = transformValuesForAPI(configState)

    return (
      <div className='container-fluid'>
        <div className={'alert alert-danger alert-dismissible animated fadeIn ' + configState.alertVisibility}>
          <button aria-label='Close' className='close' onClick={this.handleCloseAlertClick}>
            <span aria-hidden='true'>×</span>
          </button>
          {configState.alertMessageTitle !== '' && <h4>{configState.alertMessageTitle}</h4>}
          <span className='h5'>{configState.alertMessageText}</span>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DataConsumptionEstimation configuration={configForDataEstimation} loading={false} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Card style={{ height: '100%', borderRadius: 0 }}>
              <CardHeader title={this.formatMessage(messages.generalSettings)} />
              <CardContent style={{ paddingBottom: '16px' }}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <FormControl error={configState.modelNameErrorMessage !== ''} fullWidth>
                      <InputLabel>{this.formatMessage(messages.modelName)}</InputLabel>
                      <Input
                        id='modelName'
                        onChange={event => {
                          changeLocalConfigurationField('modelName', event.target.value)
                        }}
                        value={configState.modelName}
                      />
                      <FormHelperText>{configState.modelNameErrorMessage}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl error={configState.nodeIdErrorMessage !== ''} fullWidth>
                      <InputLabel>{this.formatMessage(messages.nodeId)}</InputLabel>
                      <Input
                        id='nodeId'
                        onChange={event => {
                          changeLocalConfigurationField('nodeId', event.target.value)
                        }}
                        placeholder='0x01'
                        value={configState.nodeId}
                      />
                      <FormHelperText>{configState.nodeIdErrorMessage}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <FormControl error={configState.baudRate1ErrorMessage !== ''} fullWidth>
                      <InputLabel>{this.formatMessage(messages.baudRate1)}</InputLabel>
                      <Select
                        name='baudRate1'
                        onChange={event => {
                          changeLocalConfigurationField('baudRate1', event.target.value)
                        }}
                        value={configState.baudRate1}
                      >
                        {optionsForGeneralSettings.baudRate.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{configState.baudRate1ErrorMessage}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl error={configState.baudRate2ErrorMessage !== ''} fullWidth>
                      <InputLabel>{this.formatMessage(messages.baudRate2)}</InputLabel>
                      <Select
                        name='baudRate2'
                        onChange={event => {
                          changeLocalConfigurationField('baudRate2', event.target.value)
                        }}
                        value={configState.baudRate2}
                      >
                        {optionsForGeneralSettings.baudRate.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{configState.baudRate2ErrorMessage}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={3} style={{ padding: '0 12px 0 12px' }} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={configState.dataLoggingEnable}
                          classes={{ root: classes.checkbox, checked: classes.checked }}
                          onChange={() => {
                            changeLocalConfigurationField('dataLoggingEnable')
                          }}
                        />
                      }
                      label={this.formatMessage(messages.dataLogging)}
                    />
                  </Grid>
                  {!configState.dataLoggingEnable && (
                    <Grid item md={9} style={{ padding: '0 12px 0 12px' }} xs={12}>
                      <Typography style={{ margin: '14px 0' }}>
                        {this.formatMessage(messages.dataLoggingDisabled)}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={this.formatMessage(messages.loggingUploadPeriodDescription)}
                >
                  <Grid
                    container
                    spacing={3}
                    style={{ backgroundColor: !configState.dataLoggingEnable ? '#d3d3d3' : 'white' }}
                  >
                    <Grid item md={6} style={{ padding: '12px 0 12px 0' }} xs={12}>
                      <div className='col-md-12' style={{ padding: 0 }}>
                        <div
                          className='col-md-2'
                          style={{
                            width: configState.loggingUploadPeriodEnable ? '48px' : '100%',
                            padding: '0 0 0 12px'
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={configState.loggingUploadPeriodEnable}
                                classes={{ root: classes.checkbox, checked: classes.checked }}
                                onChange={event => {
                                  if (event.target.checked || configState.loggingBufferThresholdEnable) {
                                    changeLocalConfigurationField('loggingUploadPeriodEnable')
                                    if (!event.target.checked) {
                                      changeLocalConfigurationField('loggingUploadPeriod', 0)
                                    } else {
                                      changeLocalConfigurationField(
                                        'loggingUploadPeriod',
                                        configState.loggingUploadPeriod !== 0 ? configState.loggingUploadPeriod : 300
                                      )
                                    }
                                  }
                                }}
                              />
                            }
                            label={
                              !configState.loggingUploadPeriodEnable
                                ? this.formatMessage(messages.loggingUploadPeriod)
                                : ''
                            }
                          />
                        </div>
                        {configState.loggingUploadPeriodEnable && (
                          <div
                            className='col-md-10'
                            style={{ padding: '0 12px 0 0', width: 'calc(100% - 48px)', maxWidth: 'calc(100% - 48px)' }}
                          >
                            <FormControl error={configState.loggingUploadPeriodErrorMessage !== ''} fullWidth>
                              <InputLabel>{this.formatMessage(messages.loggingUploadPeriod)}</InputLabel>
                              <Input
                                inputProps={{ min: 60, max: 65535 }}
                                onChange={event => {
                                  changeLocalConfigurationField('loggingUploadPeriod', event.target.value)
                                }}
                                onWheel={event => event.target.blur()}
                                placeholder='(s)'
                                type='number'
                                value={configState.loggingUploadPeriod}
                              />
                              <FormHelperText>{configState.loggingUploadPeriodErrorMessage}</FormHelperText>
                            </FormControl>
                          </div>
                        )}
                      </div>
                    </Grid>
                    {configState.loggingUploadPeriodEnable && !configState.loggingBufferThresholdEnable && (
                      <Grid item md={6} xs={12}>
                        <Typography style={{ margin: '14px 0' }}>
                          {this.formatMessage(messages.loggingPeriodBufferWarning)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Tooltip>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={this.formatMessage(messages.loggingBufferThresholdDescription)}
                >
                  <Grid
                    container
                    spacing={3}
                    style={{ backgroundColor: !configState.dataLoggingEnable ? '#d3d3d3' : 'white' }}
                  >
                    <Grid item md={6} style={{ padding: '12px 0 12px 0' }} xs={12}>
                      <div className='col-md-12' style={{ padding: 0 }}>
                        <div
                          className='col-md-2'
                          style={{
                            width: configState.loggingBufferThresholdEnable ? '48px' : '100%',
                            padding: '0 0 0 12px'
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={configState.loggingBufferThresholdEnable}
                                classes={{ root: classes.checkbox, checked: classes.checked }}
                                onChange={event => {
                                  if (event.target.checked || configState.loggingUploadPeriodEnable) {
                                    changeLocalConfigurationField('loggingBufferThresholdEnable')
                                    if (!event.target.checked) {
                                      changeLocalConfigurationField('loggingBufferThreshold', 0)
                                    } else {
                                      changeLocalConfigurationField(
                                        'loggingBufferThreshold',
                                        configState.loggingBufferThreshold !== 0
                                          ? configState.loggingBufferThreshold
                                          : 20
                                      )
                                    }
                                  }
                                }}
                              />
                            }
                            label={
                              !configState.loggingBufferThresholdEnable
                                ? this.formatMessage(messages.loggingBufferThreshold)
                                : ''
                            }
                          />
                        </div>
                        {configState.loggingBufferThresholdEnable && (
                          <div
                            className='col-md-10'
                            style={{ padding: '0 12px 0 0', width: 'calc(100% - 48px)', maxWidth: 'calc(100% - 48px)' }}
                          >
                            <FormControl error={configState.loggingBufferThresholdErrorMessage !== ''} fullWidth>
                              <InputLabel>{this.formatMessage(messages.loggingBufferThreshold)}</InputLabel>
                              <Input
                                inputProps={{ min: 0, max: 65535 }}
                                onChange={event => {
                                  changeLocalConfigurationField('loggingBufferThreshold', event.target.value)
                                }}
                                onWheel={event => event.target.blur()}
                                placeholder={this.formatMessage(messages.numberOfMessages)}
                                type='number'
                                value={configState.loggingBufferThreshold}
                              />
                              <FormHelperText>{configState.loggingBufferThresholdErrorMessage}</FormHelperText>
                            </FormControl>
                          </div>
                        )}
                      </div>
                    </Grid>
                    {!configState.loggingUploadPeriodEnable && configState.loggingBufferThresholdEnable && (
                      <Grid item md={6} xs={12}>
                        <Typography style={{ margin: '14px 0' }}>
                          {this.formatMessage(messages.loggingPeriodBufferWarning)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Tooltip>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Card
              style={{
                height: '100%',
                backgroundColor: !configState.dataLoggingEnable ? '#d3d3d3' : 'white',
                borderRadius: 0
              }}
            >
              <CardHeader title={this.formatMessage(messages.gpsSettings)} />
              <CardContent>
                <div className='row'>
                  <div className='col-md-4' style={{ marginTop: '10px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={configState.gpsEnabled}
                          classes={{ root: classes.checkbox, checked: classes.checked }}
                          onClick={() => {
                            changeLocalConfigurationField('gpsEnabled')
                          }}
                        />
                      }
                      label={this.formatMessage(messages.enableGPSLogging)}
                    />
                  </div>
                  <div className='col-md-8'>
                    <FormControl
                      error={configState.gpsEnabled && configState.gpsFrequencyErrorMessage !== ''}
                      fullWidth
                    >
                      {/*<InputLabel>{this.formatMessage(messages.frequency)}</InputLabel>*/}
                      <InputLabel>{this.formatMessage(messages.loggingTime)}</InputLabel>
                      <Input
                        disabled={!configState.gpsEnabled}
                        inputProps={{ min: 60, max: 4294967295 }}
                        onChange={event => {
                          changeLocalConfigurationField('gpsFrequency', event.target.value)
                        }}
                        onWheel={event => event.target.blur()}
                        placeholder='(s)'
                        type='number'
                        value={configState.gpsFrequency}
                      />
                      <FormHelperText>{configState.gpsEnabled && configState.gpsFrequencyErrorMessage}</FormHelperText>
                    </FormControl>
                  </div>
                  <div className='col-md-12' style={{ marginTop: '10px' }}>
                    <FormControlLabel
                      control={<Checkbox checked={configState.gpsEnabled} disabled />}
                      label={this.formatMessage(messages.position)}
                      style={{ visibility: configState.gpsEnabled ? '' : 'hidden' }}
                    />
                  </div>
                  <div className='col-md-12' style={{ marginTop: '10px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={configState.gpsAltitude}
                          classes={{ root: classes.checkbox, checked: classes.checked }}
                          onClick={() => {
                            changeLocalConfigurationField('gpsAltitude')
                          }}
                        />
                      }
                      label={this.formatMessage(messages.altitude)}
                      style={{ visibility: configState.gpsEnabled ? '' : 'hidden' }}
                    />
                  </div>
                  <div className='col-md-12' style={{ marginTop: '10px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={configState.gpsHeading}
                          classes={{ root: classes.checkbox, checked: classes.checked }}
                          onClick={() => {
                            changeLocalConfigurationField('gpsHeading')
                          }}
                        />
                      }
                      label={this.formatMessage(messages.heading)}
                      style={{ visibility: configState.gpsEnabled ? '' : 'hidden' }}
                    />
                  </div>
                  <div className='col-md-12' style={{ marginTop: '10px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={configState.gpsSpeed}
                          classes={{ root: classes.checkbox, checked: classes.checked }}
                          onClick={() => {
                            changeLocalConfigurationField('gpsSpeed')
                          }}
                        />
                      }
                      label={this.formatMessage(messages.speed)}
                      style={{ visibility: configState.gpsEnabled ? '' : 'hidden' }}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Paper
              style={{
                backgroundColor: !configState.dataLoggingEnable ? '#d3d3d3' : 'white',
                borderRadius: 0
              }}
            >
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Typography component='h3' style={{ margin: '16px' }} variant='h5'>
                    {this.formatMessage(messages.mfio)}
                  </Typography>
                </Grid>
                <Grid item style={{ alignSelf: 'center', paddingRight: '1px' }}>
                  <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.addMfio)}>
                    <IconButton
                      aria-owns={anchorEl ? 'simple-menu' : undefined}
                      disabled={configState.MFIO.length >= 2}
                      onClick={this.handleMfioMenuOpen}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id='simple-menu'
                    onClose={this.handleMfioMenuClose}
                    open={Boolean(anchorEl)}
                  >
                    <MenuItem
                      disabled={configState.MFIO.filter(mfio => mfio.signalId === 0).length !== 0}
                      onClick={() => {
                        addLocalConfigurationMFIO(0)
                        this.handleMfioMenuClose()
                      }}
                    >
                      {this.formatMessage(messages.addMfio0)}
                    </MenuItem>
                    <MenuItem
                      disabled={configState.MFIO.filter(mfio => mfio.signalId === 1).length !== 0}
                      onClick={() => {
                        addLocalConfigurationMFIO(1)
                        this.handleMfioMenuClose()
                      }}
                    >
                      {this.formatMessage(messages.addMfio1)}
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
              <Grid>
                {configState.MFIO.map(current => {
                  const noErrorInSignal =
                    current.biasErrorMessage === '' &&
                    current.decimalsErrorMessage === '' &&
                    current.defaultPowerUpErrorMessage === '' &&
                    current.digThreshHighErrorMessage === '' &&
                    current.digThreshLowErrorMessage === '' &&
                    current.inputRangeErrorMessage === '' &&
                    current.inputSignalErrorMessage === '' &&
                    current.logCycleErrorMessage === '' &&
                    current.multiplierErrorMessage === '' &&
                    current.nameErrorMessage === '' &&
                    current.offsetErrorMessage === '' &&
                    current.pinFunctionErrorMessage === '' &&
                    current.unitErrorMessage === ''
                  return (
                    <React.Fragment key={current.signalId}>
                      <Divider light />
                      <MFIO
                        averageValue={current.averageValue}
                        bias={current.bias}
                        biasErrorMessage={current.biasErrorMessage}
                        cycleTimeDelay={configState.cycleTimeDelay}
                        cycleTimeDelayErrorMessage={configState.cycleTimeDelayErrorMessage}
                        dataLoggingEnable={configState.dataLoggingEnable}
                        decimals={current.decimals}
                        decimalsErrorMessage={current.decimalsErrorMessage}
                        defaultPowerUp={current.defaultPowerUp}
                        defaultPowerUpErrorMessage={current.defaultPowerUpErrorMessage}
                        digThreshHigh={current.digThreshHigh}
                        digThreshHighErrorMessage={current.digThreshHighErrorMessage}
                        digThreshLow={current.digThreshLow}
                        digThreshLowErrorMessage={current.digThreshLowErrorMessage}
                        expanded={current.expanded}
                        inputRange={current.inputRange}
                        inputRangeErrorMessage={current.inputRangeErrorMessage}
                        inputSignal={current.inputSignal}
                        inputSignalErrorMessage={current.inputSignalErrorMessage}
                        isEditable
                        lastValue={current.lastValue}
                        logCycle={current.logCycle}
                        logCycleErrorMessage={current.logCycleErrorMessage}
                        maxValue={current.maxValue}
                        minValue={current.minValue}
                        multiplier={current.multiplier}
                        multiplierErrorMessage={current.multiplierErrorMessage}
                        name={current.name}
                        nameErrorMessage={current.nameErrorMessage}
                        noErrorInSignal={noErrorInSignal}
                        offset={current.offset}
                        offsetErrorMessage={current.offsetErrorMessage}
                        pinFunction={current.pinFunction}
                        pinFunctionErrorMessage={current.pinFunctionErrorMessage}
                        powerManagementEnable={configState.powerManagementEnable}
                        powerManagementEnableErrorMessage={configState.powerManagementEnableErrorMessage}
                        shutdownDelay={configState.shutdownDelay}
                        shutdownDelayErrorMessage={configState.shutdownDelayErrorMessage}
                        signalId={current.signalId}
                        unit={current.unit}
                        unitErrorMessage={current.unitErrorMessage}
                        wakeUpFlagEnable={configState.wakeUpFlagEnable}
                        wakeUpFlagEnableErrorMessage={configState.wakeUpFlagEnableErrorMessage}
                      />
                    </React.Fragment>
                  )
                })}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              style={{
                backgroundColor: !configState.dataLoggingEnable ? '#d3d3d3' : 'white',
                borderRadius: 0
              }}
            >
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Typography component='h3' style={{ margin: '16px' }} variant='h5'>
                    {this.formatMessage(messages.canbus)}
                  </Typography>
                </Grid>
                <Grid item style={{ alignSelf: 'center', paddingRight: '1px' }}>
                  <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.addSignal)}>
                    <IconButton disabled={configState.signals.length >= 50} onClick={addLocalConfigurationSignal}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid>
                {configState.signals.map((current, index) => {
                  const noErrorInSignal =
                    current.signalIdErrorMessage === '' &&
                    current.canPortErrorMessage === '' &&
                    current.addressErrorMessage === '' &&
                    current.idMaskErrorMessage === '' &&
                    current.canIdErrorMessage === '' &&
                    current.nameErrorMessage === '' &&
                    current.unitErrorMessage === '' &&
                    current.startBitErrorMessage === '' &&
                    current.offsetErrorMessage === '' &&
                    current.multiplierErrorMessage === '' &&
                    current.frequencyErrorMessage === '' &&
                    current.sourceAddressErrorMessage === ''
                  return (
                    <React.Fragment key={index}>
                      <Divider light />
                      <Signal
                        address={current.address}
                        addressErrorMessage={current.addressErrorMessage}
                        canId={current.canId}
                        canIdErrorMessage={current.canIdErrorMessage}
                        canPort={current.canPort}
                        canPortErrorMessage={current.canPortErrorMessage}
                        copyLocalConfigurationSignal={copyLocalConfigurationSignal}
                        dataLoggingEnable={configState.dataLoggingEnable}
                        editableId={current.editableId}
                        expanded={current.expanded}
                        frequency={current.frequency}
                        frequencyErrorMessage={current.frequencyErrorMessage}
                        idMask={current.idMask}
                        idMaskErrorMessage={current.idMaskErrorMessage}
                        isEditable
                        lengthOfBits={current.lengthOfBits}
                        lengthOfBitsErrorMessage={current.lengthOfBitsErrorMessage}
                        multiplier={current.multiplier}
                        multiplierErrorMessage={current.multiplierErrorMessage}
                        name={current.name}
                        nameErrorMessage={current.nameErrorMessage}
                        noErrorInSignal={noErrorInSignal}
                        offset={current.offset}
                        offsetErrorMessage={current.offsetErrorMessage}
                        pgn={current.pgn}
                        pgnName={current.pgnName}
                        priority={current.priority}
                        priorityErrorMessage={current.priorityErrorMessage}
                        selectedCanProtocolData={selectedCanProtocolData(current.selectedCanProtocolName)}
                        selectedCanProtocolName={current.selectedCanProtocolName}
                        selectedCanProtocolType={selectedCanProtocolType(current.selectedCanProtocolName)}
                        selectedSignal={current.selectedSignal}
                        signalColor={current.signalColor}
                        signalId={current.signalId}
                        signalIdErrorMessage={current.signalIdErrorMessage}
                        signed={current.signed}
                        sourceAddress={current.sourceAddress}
                        sourceAddressErrorMessage={current.sourceAddressErrorMessage}
                        spn={current.spn}
                        spnName={current.spnName}
                        startBit={current.startBit}
                        startBitErrorMessage={current.startBitErrorMessage}
                        unit={current.unit}
                        unitErrorMessage={current.unitErrorMessage}
                      />
                    </React.Fragment>
                  )
                })}
              </Grid>
            </Paper>
          </Grid>
          <Grid container item justifyContent='flex-end'>
            <Link className='button-link' to={configurationsUrl}>
              <Button className='cancel-button' style={{ marginRight: 10 }}>
                {this.formatMessage(messages.cancel)}
              </Button>
            </Link>
            <Button
              className='secondary-action-button'
              disabled={groupId === ''}
              onClick={this.handleDownload}
              style={{ minWidth: 100, marginRight: 10 }}
            >
              {this.formatMessage(messages.download)}
            </Button>
            <Button
              className='primary-action-button'
              disabled={groupId === '' || isLoading}
              onClick={this.handleSave}
              style={{ minWidth: 100 }}
            >
              {isLoading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
              {this.formatMessage(messages.create)}
            </Button>
          </Grid>
        </Grid>
        <Dialog classes={{ paper: classes.dialog }} onClose={this.handleDialogClose} open={isDialogOpen}>
          <DialogContent style={{ padding: '12px 10px 10px 12px' }}>
            <DialogTitle style={{ padding: 12 }}>
              <IconButton
                onClick={this.handleDialogClose}
                style={{
                  position: 'absolute',
                  right: 3,
                  top: 3,
                  padding: 5
                }}
              >
                <CloseIcon />
              </IconButton>
              {this.formatMessage(messages.configurationDeniedTitle)}
            </DialogTitle>
            <DialogContentText color='textPrimary' style={{ padding: 12 }}>
              {this.formatMessage(messages.configurationDeniedText)}
            </DialogContentText>
            <DialogContentText color='textPrimary' style={{ padding: '12px 12px 0 12px' }}>
              {this.formatMessage(messages.mbPerMonth) + configState.dataConsumptionEstimation + ' MB'}
            </DialogContentText>
            <DialogContentText color='textPrimary' style={{ padding: '0 12px 0 12px' }}>
              {this.formatMessage(messages.usagePercentage) + configState.machineUsagePercentage + ' %'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus className='primary-action-button' onClick={this.handleDialogClose}>
              {this.formatMessage(messages.ok)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

NewConfigurationForm.propTypes = {
  addLocalConfigurationMFIO: PropTypes.func.isRequired,
  addLocalConfigurationSignal: PropTypes.func.isRequired,
  changeLocalConfigurationField: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  configState: PropTypes.object.isRequired,
  configurationsUrl: PropTypes.string.isRequired,
  copyLocalConfigurationSignal: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  localConfigurationError: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  manageLocalConfigurationAlert: PropTypes.func.isRequired,
  onCreateConfiguration: PropTypes.func.isRequired,
  resetLocalConfiguration: PropTypes.func.isRequired,
  saveConfiguration: PropTypes.func.isRequired,
  selectedCanProtocolData: PropTypes.func.isRequired,
  selectedCanProtocolType: PropTypes.func.isRequired
}

export default withStyles(styles)(withRouter(injectIntl(NewConfigurationForm)))
