import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import ControlPointDuplicateOutlinedIcon from '@material-ui/icons/ControlPointDuplicateOutlined'
import EditIcon from '@material-ui/icons/Edit'
import ErrorIcon from '@material-ui/icons/Error'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import messages from './messages'
import { getOptionsForSignalItem, getUnitForLimits } from '../config'
import { SIGNAL_FIELDS } from '../constants'

const styles = theme => ({
  expand: {
    transform: 'rotate(0deg)'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
    paddingLeft: 16
  },
  root: {
    padding: 0
  },
  action: {
    margin: 0
  },
  button: {
    margin: 0
  },
  goldenButton: {
    margin: 0,
    backgroundColor: '#ffd700'
  },
  actionsRoot: {
    paddingRight: 1
  },
  contentRoot: {
    padding: 16
  },
  errorButton: {
    color: 'red'
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  },
  checkbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  checked: {}
})

const Signal = ({
  intl,
  classes,
  // Signal
  isEditable,
  noErrorInSignal,
  signalId,
  signalIdErrorMessage,
  editableId,
  name,
  nameErrorMessage,
  signalSource,
  signalSourceErrorMessage,
  cch,
  cchErrorMessage,
  piu,
  piuErrorMessage,
  isExtendedCanId,
  sml,
  smlErrorMessage,
  ss,
  ssErrorMessage,
  lengthOfBits,
  lengthOfBitsErrorMessage,
  endianness,
  endiannessErrorMessage,
  signedness,
  signednessErrorMessage,
  multiplier,
  multiplierErrorMessage,
  offset,
  offsetErrorMessage,
  unit,
  unitErrorMessage,
  customUnit,
  unitType,
  // Filter
  filterType,
  filterTypeErrorMessage,
  cornerFrequency,
  cornerFrequencyErrorMessage,
  quotient,
  quotientErrorMessage,
  gain,
  gainErrorMessage,
  // Logging
  logToPortal,
  sendImmediately,
  logType,
  logTypeErrorMessage,
  samplePeriod,
  samplePeriodErrorMessage,
  // Trigger
  triggerType,
  triggerTypeErrorMessage,
  triggerSource,
  triggerSourceErrorMessage,
  triggerLowerThreshold,
  triggerLowerThresholdErrorMessage,
  triggerUpperThreshold,
  triggerUpperThresholdErrorMessage,
  triggerThresholdDivider,
  triggerThresholdDividerErrorMessage,
  triggerSendImmediately,
  triggerLogType,
  triggerLogTypeErrorMessage,
  samplePeriodTriggered,
  samplePeriodTriggeredErrorMessage,
  // Other
  handleFieldChange,
  handleSignalDelete,
  makeSignalIdEditable,
  handleSignalIdChange,
  validateNewSignalId,
  handleSignalCopy,
  signalColor,
  expanded,
  isLegacyVersion,
  overflowErrorMessage,
  // MFIO
  ...mfioInputSignals
}) => {
  const { formatMessage } = intl
  const optionsForSignalItemSelects = getOptionsForSignalItem()
  const unitTypeValidValues = {
    PREDEFINED_UNIT_TYPE: 0,
    CUSTOM_UNIT_TYPE: 1
  }

  /* eslint-disable react/jsx-key */
  return (
    <Card elevation={0} style={{ borderLeft: '10px solid ' + signalColor, borderRadius: 0 }}>
      <CardHeader
        action={
          <CardActions classes={{ root: classes.actionsRoot }}>
            {!noErrorInSignal && isEditable && (
              <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.error)}>
                <IconButton className={classes.errorButton}>
                  <ErrorIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            {isEditable && expanded && (
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={editableId ? formatMessage(messages.validateId) : formatMessage(messages.editId)}
              >
                <IconButton
                  classes={{ root: editableId ? classes.goldenButton : classes.button }}
                  onClick={() => {
                    makeSignalIdEditable(signalId, !editableId)
                    if (editableId) {
                      validateNewSignalId(signalId)
                    }
                  }}
                >
                  {editableId ? <CheckIcon fontSize='small' /> : <EditIcon fontSize='small' />}
                </IconButton>
              </Tooltip>
            )}
            {isEditable && (
              <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.duplicateSignal)}>
                <IconButton
                  classes={{ root: classes.button }}
                  onClick={() => {
                    handleSignalCopy(signalId)
                  }}
                >
                  <ControlPointDuplicateOutlinedIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            {isEditable && (
              <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.deleteSignal)}>
                <IconButton
                  classes={{ root: classes.button }}
                  onClick={() => {
                    handleSignalDelete(signalId)
                  }}
                >
                  <ClearIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={expanded ? formatMessage(messages.collapse) : formatMessage(messages.expand)}
            >
              <IconButton
                classes={{ root: classes.button }}
                onClick={() => {
                  handleFieldChange(signalId, SIGNAL_FIELDS.expanded, !expanded)
                }}
              >
                {expanded ? (
                  <ExpandMoreIcon className={(classes.expand, classes.expandOpen)} fontSize='small' />
                ) : (
                  <ExpandMoreIcon className={classes.expand} fontSize='small' />
                )}
              </IconButton>
            </Tooltip>
          </CardActions>
        }
        classes={{
          title: classes.title,
          root: classes.root,
          action: classes.action
        }}
        title={
          !editableId ? (
            <div>
              <span>{formatMessage(messages.signalId) + ' ' + parseInt(signalId) + (name ? ' | ' + name : '')}</span>
              {signalIdErrorMessage && <span style={{ color: 'red' }}>{' | ' + signalIdErrorMessage}</span>}
            </div>
          ) : (
            <div className='col-md-12' style={{ paddingLeft: 0 }}>
              <span style={{ verticalAlign: 'middle' }}>{formatMessage(messages.signalId) + ' '}</span>
              <FormControl
                error={signalIdErrorMessage !== ''}
                style={{ width: '10%', verticalAlign: 'middle', padding: '0 0 2 0' }}
              >
                <Input
                  id={SIGNAL_FIELDS.signalId + '-' + signalId}
                  inputProps={{ min: 0, max: 79 }}
                  onChange={event => {
                    handleSignalIdChange(signalId, parseInt(event.target.value))
                  }}
                  onWheel={event => event.target.blur()}
                  placeholder=' [0 - 79]'
                  type='number'
                />
                {signalIdErrorMessage !== '' ? <FormHelperText>{signalIdErrorMessage}</FormHelperText> : null}
              </FormControl>
              <span style={{ verticalAlign: 'middle' }}>{name ? ' | ' + name : ''}</span>
            </div>
          )
        }
      />
      <Collapse in={expanded}>
        <CardContent
          classes={{
            root: classes.contentRoot
          }}
          style={{ paddingBottom: '16px' }}
        >
          <Typography style={{ fontWeight: 'bold' }}>{formatMessage(messages.settings)}</Typography>
          <Divider style={{ backgroundColor: 'black', margin: '0 0 20px 0' }} />
          <React.Fragment>
            <Grid container spacing={2}>
              {/* LOG TO PORTAL */}
              {isEditable ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={logToPortal}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        onClick={event => {
                          handleFieldChange(signalId, SIGNAL_FIELDS.logToPortal, event.target.checked)
                        }}
                      />
                    }
                    label={formatMessage(messages.logToPortal)}
                    style={{ marginBottom: 0 }}
                  />
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.logToPortal)}</Typography>
                  <label>{logToPortal ? 'True' : 'False'}</label>
                </Grid>
              )}
              {/* MFIO explanation */}
              {isEditable && signalSource === 3 && (
                <Grid item lg={12} md={12} sm={12} style={{ border: '1px solid #949494', margin: 5 }} xs={12}>
                  <Typography style={{ fontWeight: 'bold', paddingBottom: 0 }}>
                    {formatMessage(messages.mfioOutputFormula)}
                  </Typography>
                  <Typography>{formatMessage(messages.mfioExplanation)}</Typography>
                </Grid>
              )}
              {/* Custom CAN explanation */}
              {isEditable && signalSource === 2 && (
                <Grid item lg={12} md={12} sm={12} style={{ border: '1px solid #949494', margin: 5 }} xs={12}>
                  <Typography style={{ fontWeight: 'bold', paddingBottom: 0 }}>
                    {formatMessage(messages.customCANOutputFormula)}
                  </Typography>
                </Grid>
              )}
              {isEditable && signalSource === 2 && overflowErrorMessage !== '' && (
                <Grid item xs={12}>
                  <Typography style={{ color: 'red' }}>{overflowErrorMessage}</Typography>
                </Grid>
              )}
              {/* SIGNAL NAME */}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={nameErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>{formatMessage(messages.name)}</InputLabel>
                    <Input
                      id={SIGNAL_FIELDS.name + '-' + signalId}
                      inputProps={{ maxLength: 300 }}
                      onChange={event => {
                        handleFieldChange(signalId, SIGNAL_FIELDS.name, event.target.value)
                      }}
                      value={name}
                    />
                    <FormHelperText>{nameErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.name)}</Typography>
                  <label>{name}</label>
                </Grid>
              )}
              {/* SIGNAL SOURCE */}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={signalSourceErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>{formatMessage(messages.signalSource)}</InputLabel>
                    <Select
                      name={SIGNAL_FIELDS.signalSource + '-' + signalId}
                      onChange={event => {
                        handleFieldChange(signalId, SIGNAL_FIELDS.signalSource, event.target.value)
                      }}
                      value={signalSource}
                    >
                      {optionsForSignalItemSelects.signalSource.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{signalSourceErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.signalSource)}</Typography>
                  <label>
                    {optionsForSignalItemSelects.signalSource.find(sigs => sigs.value === signalSource)?.label}
                  </label>
                </Grid>
              )}
              {/* CCH: J1939 & custom: Selection of the CAN-interface to be used (0, 1), MFIO: pin (0, 1, 2, 3) */}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={cchErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>
                      {signalSource === 1 || signalSource === 2
                        ? formatMessage(messages.cch_J1939_custom)
                        : formatMessage(messages.cch_MFIO)}
                    </InputLabel>
                    <Select
                      name={SIGNAL_FIELDS.cch + '-' + signalId}
                      onChange={event => {
                        handleFieldChange(signalId, SIGNAL_FIELDS.cch, event.target.value)
                      }}
                      value={cch}
                    >
                      {signalSource === 1 || signalSource === 2
                        ? optionsForSignalItemSelects.cch_J1939_custom.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                        : optionsForSignalItemSelects.cch_MFIO.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{cchErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>
                    {signalSource === 1 || signalSource === 2
                      ? formatMessage(messages.cch_J1939_custom)
                      : formatMessage(messages.cch_MFIO)}
                  </Typography>
                  <label>{cch}</label>
                </Grid>
              )}
              {/* isExtendedCanId: Only for signar source = 2 */}
              {isEditable && signalSource === 2 ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>{formatMessage(messages.canIDType)}</InputLabel>
                    <Select
                      name={SIGNAL_FIELDS.signalSource + '-' + signalId}
                      onChange={event => {
                        handleFieldChange(signalId, SIGNAL_FIELDS.isExtendedCanId, event.target.value)
                      }}
                      value={isExtendedCanId}
                    >
                      {optionsForSignalItemSelects.canIDType.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : 
                signalSource === 2 && (
                  <Grid item lg={1} md={2} sm={3} xs={4}>
                    <Typography>{formatMessage(messages.canIDType)}</Typography>
                    <label>
                      {isExtendedCanId ? formatMessage(messages.extendedCANID) : formatMessage(messages.standardCANID)}
                    </label>
                  </Grid>
                )
              }
            </Grid>
            <Grid container spacing={2}>
              {/* PIU: J1939: PGN, custom: canId, MFIO: Upper limit */}
              {isEditable && (signalSource === 1 || signalSource === 2) || isEditable && signalSource === 3 ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={piuErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>
                      {signalSource === 1
                        ? formatMessage(messages.piu_J1939)
                        : signalSource === 2
                          ? formatMessage(messages.piu_custom)
                          : formatMessage(messages.piu_MFIO) +
                          getUnitForLimits(mfioInputSignals[`${'inputSignal' + cch}`])}
                    </InputLabel>
                    <Input
                      id={SIGNAL_FIELDS.piu + '-' + signalId}
                      onChange={event => {
                        handleFieldChange(signalId, SIGNAL_FIELDS.piu, event.target.value)
                      }}
                      placeholder={signalSource === 2 ? isExtendedCanId ? '0x1FFFFFFF' : '0x7FF' : ''}
                      value={piu}
                    />
                    <FormHelperText>{piuErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : signalSource === 1 || signalSource === 2 || signalSource === 3 ? (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>
                    {signalSource === 1
                      ? formatMessage(messages.piu_J1939)
                      : signalSource === 2
                        ? formatMessage(messages.piu_custom)
                        : formatMessage(messages.piu_MFIO) + getUnitForLimits(mfioInputSignals[`${'inputSignal' + cch}`])}
                  </Typography>
                  <label>{piu}</label>
                </Grid>
              ) : null}
              {/* SML: J1939: SPN, custom: idMask, MFIO: Lower limit */}
              {isEditable && (signalSource === 1 || signalSource === 2) || isEditable && signalSource === 3 ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={smlErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>
                      {signalSource === 1
                        ? formatMessage(messages.sml_J1939)
                        : signalSource === 2
                          ? formatMessage(messages.sml_custom)
                          : formatMessage(messages.sml_MFIO) +
                          getUnitForLimits(mfioInputSignals[`${'inputSignal' + cch}`])}
                    </InputLabel>
                    <Input
                      id={SIGNAL_FIELDS.sml + '-' + signalId}
                      onChange={event => {
                        handleFieldChange(signalId, SIGNAL_FIELDS.sml, event.target.value)
                      }}
                      placeholder={signalSource === 2 ? isExtendedCanId ? '0x1FFFFFFF' : '0x7FF' : ''}
                      value={sml}
                    />
                    <FormHelperText>{smlErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : signalSource === 1 || signalSource === 2 || signalSource === 3 ? (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>
                    {signalSource === 1
                      ? formatMessage(messages.sml_J1939)
                      : signalSource === 2
                        ? formatMessage(messages.sml_custom)
                        : formatMessage(messages.sml_MFIO) + getUnitForLimits(mfioInputSignals[`${'inputSignal' + cch}`])}
                  </Typography>
                  <label>{sml}</label>
                </Grid>
              ) : null}
              {/* SS: J1939: SA, custom: Start bit, */}
              {isEditable && (signalSource === 1 || signalSource === 2) ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={ssErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>
                      {signalSource === 1 ? formatMessage(messages.ss_J1939) : formatMessage(messages.ss_custom)}
                    </InputLabel>
                    <Input
                      id={SIGNAL_FIELDS.ss + '-' + signalId}
                      onChange={event => {
                        handleFieldChange(signalId, SIGNAL_FIELDS.ss, event.target.value)
                      }}
                      value={ss}
                    />
                    <FormHelperText>{ssErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : 
                (signalSource === 1 || signalSource === 2) && (
                  <Grid item lg={1} md={2} sm={3} xs={4}>
                    <Typography>
                      {signalSource === 1 ? formatMessage(messages.ss_J1939) : formatMessage(messages.ss_custom)}
                    </Typography>
                    <label>{ss}</label>
                  </Grid>
                )
              }
              {/* Length: Only for signar source = 2 custom */}
              {isEditable && signalSource === 2 ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={lengthOfBitsErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>{formatMessage(messages.length)}</InputLabel>
                    <Input
                      id={SIGNAL_FIELDS.lengthOfBits + '-' + signalId}
                      inputProps={{ min: 1, max: 32 }}
                      onChange={event => {
                        handleFieldChange(signalId, SIGNAL_FIELDS.lengthOfBits, event.target.value)
                      }}
                      onWheel={event => event.target.blur()}
                      placeholder='[1 - 32]'
                      type='number'
                      value={lengthOfBits}
                    />
                    <FormHelperText>{lengthOfBitsErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : 
                signalSource === 2 && (
                  <Grid item lg={1} md={2} sm={3} xs={4}>
                    <Typography>{formatMessage(messages.length)}</Typography>
                    <label>{lengthOfBits}</label>
                  </Grid>
                )
              }
              {/* Endianness: Only for signal source = 2 (custom) */}
              {isLegacyVersion ? null : isEditable && signalSource === 2 ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={endiannessErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>{formatMessage(messages.endianness)}</InputLabel>
                    <Select
                      name={SIGNAL_FIELDS.endianness + '-' + signalId}
                      onChange={event => {
                        handleFieldChange(signalId, SIGNAL_FIELDS.endianness, event.target.value)
                      }}
                      value={endianness}
                    >
                      {optionsForSignalItemSelects.endianness.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{endiannessErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : 
                signalSource === 2 && (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Typography>{formatMessage(messages.endianness)}</Typography>
                    <label>{endianness}</label>
                  </Grid>
                )
              }
              {/* Signedness: Only for signal source = 2 (custom) */}
              {isLegacyVersion ? null : isEditable && signalSource === 2 ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={signednessErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>{formatMessage(messages.signedness)}</InputLabel>
                    <Select
                      name={SIGNAL_FIELDS.signedness + '-' + signalId}
                      onChange={event => {
                        handleFieldChange(signalId, SIGNAL_FIELDS.signedness, event.target.value)
                      }}
                      value={signedness}
                    >
                      {optionsForSignalItemSelects.signedness.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{signednessErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : 
                signalSource === 2 && (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Typography>{formatMessage(messages.signedness)}</Typography>
                    <label>{signedness}</label>
                  </Grid>
                )
              }
              {/* Multiplier: Only for signar source = 2 & 3 */}
              {isEditable && (signalSource === 2 || signalSource === 3) ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={multiplierErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>{formatMessage(messages.multiplier)}</InputLabel>
                    <Input
                      id={SIGNAL_FIELDS.multiplier + '-' + signalId}
                      onChange={event => {
                        handleFieldChange(signalId, SIGNAL_FIELDS.multiplier, event.target.value)
                      }}
                      onWheel={event => event.target.blur()}
                      type='number'
                      value={multiplier}
                    />
                    <FormHelperText>{multiplierErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : 
                (signalSource === 2 || signalSource === 3) && (
                  <Grid item lg={1} md={2} sm={3} xs={4}>
                    <Typography>{formatMessage(messages.multiplier)}</Typography>
                    <label>{multiplier}</label>
                  </Grid>
                )
              }
              {/* Offset: Only for signar source = 2 & 3 */}
              {isEditable && (signalSource === 2 || signalSource === 3) ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={offsetErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>{formatMessage(messages.offset)}</InputLabel>
                    <Input
                      id={SIGNAL_FIELDS.offset + '-' + signalId}
                      onChange={event => {
                        handleFieldChange(signalId, SIGNAL_FIELDS.offset, event.target.value)
                      }}
                      onWheel={event => event.target.blur()}
                      type='number'
                      value={offset}
                    />
                    <FormHelperText>{offsetErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : 
                (signalSource === 2 || signalSource === 3) && (
                  <Grid item lg={1} md={2} sm={3} xs={4}>
                    <Typography>{formatMessage(messages.offset)}</Typography>
                    <label>{offset}</label>
                  </Grid>
                )
              }
              {/* Unit type: Only for signal source = 2 & 3 */}
              {isEditable && (signalSource === 2 || signalSource === 3) && (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel>{formatMessage(messages.unitType)}</InputLabel>
                    <Select
                      name={SIGNAL_FIELDS.unitType + '-' + signalId}
                      onChange={event => {
                        handleFieldChange(signalId, SIGNAL_FIELDS.unitType, event.target.value)
                      }}
                      value={unitType}
                    >
                      {optionsForSignalItemSelects.unitType.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {/* Unit: Only for signal source = 2 & 3 */}
              {(!unitType || unitType === unitTypeValidValues.PREDEFINED_UNIT_TYPE) &&
              isEditable &&
              (signalSource === 2 || signalSource === 3) ? (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <FormControl error={unitErrorMessage !== ''} style={{ width: '100%' }}>
                      <InputLabel>{formatMessage(messages.unit)}</InputLabel>
                      <Select
                        name={SIGNAL_FIELDS.unit + '-' + signalId}
                        onChange={event => {
                          handleFieldChange(signalId, SIGNAL_FIELDS.unit, event.target.value)
                        }}
                        value={unit}
                      >
                        {optionsForSignalItemSelects.unit.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{unitErrorMessage}</FormHelperText>
                    </FormControl>
                  </Grid>
                ) : 
                unitType === unitTypeValidValues.PREDEFINED_UNIT_TYPE &&
                (signalSource === 2 || signalSource === 3) && (
                  <Grid item lg={1} md={2} sm={3} xs={4}>
                    <Typography>{formatMessage(messages.unit)}</Typography>
                    <label>{optionsForSignalItemSelects.unit.find(u => u.value === unit)?.label}</label>
                  </Grid>
                )
              }
              {unitType === unitTypeValidValues.CUSTOM_UNIT_TYPE &&
              isEditable &&
              (signalSource === 2 || signalSource === 3) ? (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <FormControl error={unitErrorMessage !== ''} style={{ width: '100%' }}>
                      <InputLabel>{formatMessage(messages.unit)}</InputLabel>
                      <Input
                        id={SIGNAL_FIELDS.customUnit + '-' + signalId}
                        onChange={event => {
                          handleFieldChange(signalId, SIGNAL_FIELDS.customUnit, event.target.value)
                        }}
                        onWheel={event => event.target.blur()}
                        type='string'
                        value={customUnit}
                      />
                      <FormHelperText>{unitErrorMessage}</FormHelperText>
                    </FormControl>
                  </Grid>
                ) : 
                unitType === unitTypeValidValues.CUSTOM_UNIT_TYPE &&
                (signalSource === 2 || signalSource === 3) && (
                  <Grid item lg={1} md={2} sm={3} xs={4}>
                    <Typography>{formatMessage(messages.unit)}</Typography>
                    <label>{customUnit}</label>
                  </Grid>
                )
              }
            </Grid>
          </React.Fragment>
          <React.Fragment>
            {signalId < 40 && (
              <React.Fragment>
                <Typography style={{ fontWeight: 'bold', paddingTop: 25 }}>{formatMessage(messages.filter)}</Typography>
                <Divider style={{ backgroundColor: 'black', margin: '0 0 20px 0' }} />
                <Grid container spacing={2}>
                  {/* Filter type */}
                  {isEditable ? (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                      <FormControl error={filterTypeErrorMessage !== ''} style={{ width: '100%' }}>
                        <InputLabel>{formatMessage(messages.filterType)}</InputLabel>
                        <Select
                          name={SIGNAL_FIELDS.filterType + '-' + signalId}
                          onChange={event => {
                            handleFieldChange(signalId, SIGNAL_FIELDS.filterType, event.target.value)
                          }}
                          value={filterType}
                        >
                          {optionsForSignalItemSelects.filterType.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{filterTypeErrorMessage}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item lg={1} md={2} sm={3} xs={4}>
                      <Typography>{formatMessage(messages.filterType)}</Typography>
                      <label>{optionsForSignalItemSelects.filterType.find(ft => ft.value === filterType)?.label}</label>
                    </Grid>
                  )}
                  {/* Corner frequency */}
                  {isEditable && filterType > 0 && filterType < 6 ? (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                      <FormControl error={cornerFrequencyErrorMessage !== ''} style={{ width: '100%' }}>
                        <InputLabel>{formatMessage(messages.cornerFrequency)}</InputLabel>
                        <Input
                          id={SIGNAL_FIELDS.cornerFrequency + '-' + signalId}
                          onChange={event => {
                            handleFieldChange(signalId, SIGNAL_FIELDS.cornerFrequency, event.target.value)
                          }}
                          onWheel={event => event.target.blur()}
                          placeholder='[0 - 5000]'
                          type='number'
                          value={cornerFrequency}
                        />
                        <FormHelperText>{cornerFrequencyErrorMessage}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : 
                    filterType > 0 &&
                    filterType < 6 && (
                      <Grid item lg={1} md={2} sm={3} xs={4}>
                        <Typography>{formatMessage(messages.cornerFrequency)}</Typography>
                        <label>{cornerFrequency}</label>
                      </Grid>
                    )
                  }
                  {isEditable && filterType > 0 && filterType < 6 ? (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                      <FormControl error={quotientErrorMessage !== ''} style={{ width: '100%' }}>
                        <InputLabel>{formatMessage(messages.quotient)}</InputLabel>
                        <Input
                          id={SIGNAL_FIELDS.quotient + '-' + signalId}
                          onChange={event => {
                            handleFieldChange(signalId, SIGNAL_FIELDS.quotient, event.target.value)
                          }}
                          onWheel={event => event.target.blur()}
                          placeholder='[0 - 10000]'
                          type='number'
                          value={quotient}
                        />
                        <FormHelperText>{quotientErrorMessage}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : 
                    filterType > 0 &&
                    filterType < 6 && (
                      <Grid item lg={1} md={2} sm={3} xs={4}>
                        <Typography>{formatMessage(messages.quotient)}</Typography>
                        <label>{quotient}</label>
                      </Grid>
                    )
                  }
                  {isEditable && filterType === 5 ? (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                      <FormControl error={gainErrorMessage !== ''} style={{ width: '100%' }}>
                        <InputLabel>{formatMessage(messages.gain)}</InputLabel>
                        <Input
                          id={SIGNAL_FIELDS.gain + '-' + signalId}
                          onChange={event => {
                            handleFieldChange(signalId, SIGNAL_FIELDS.gain, event.target.value)
                          }}
                          onWheel={event => event.target.blur()}
                          placeholder='[0 - 10000]'
                          type='number'
                          value={gain}
                        />
                        <FormHelperText>{gainErrorMessage}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : 
                    filterType === 5 && (
                      <Grid item lg={1} md={2} sm={3} xs={4}>
                        <Typography>{formatMessage(messages.gain)}</Typography>
                        <label>{gain}</label>
                      </Grid>
                    )
                  }
                </Grid>
              </React.Fragment>
            )}
            {logToPortal && (
              <React.Fragment>
                <Typography style={{ fontWeight: 'bold', paddingTop: 25 }}>
                  {formatMessage(messages.logging)}
                </Typography>
                <Divider style={{ backgroundColor: 'black', margin: '0 0 20px 0' }} />
                <Grid container spacing={2}>
                  {isEditable && signalId < 40 && filterType === 6 && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography>{formatMessage(messages.lastMinMaxAvgFilterTypeOptionAdvert)}</Typography>
                    </Grid>
                  )}
                  {/* Send immeadiately*/}
                  {isEditable && signalId < 40 && filterType !== 6 ? (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendImmediately}
                            classes={{ root: classes.checkbox, checked: classes.checked }}
                            onClick={event => {
                              handleFieldChange(signalId, SIGNAL_FIELDS.sendImmediately, event.target.checked)
                            }}
                          />
                        }
                        label={formatMessage(messages.sendImmediately)}
                        style={{ marginTop: '15px', marginBottom: 0 }}
                      />
                    </Grid>
                  ) : 
                    signalId < 40 &&
                    filterType !== 6 && (
                      <Grid item lg={1} md={2} sm={3} xs={4}>
                        <Typography>{formatMessage(messages.sendImmediately)}</Typography>
                        <label>{sendImmediately ? 'True' : 'False'}</label>
                      </Grid>
                    )
                  }
                  {isEditable && signalId < 40 && filterType !== 6 && sendImmediately && (
                    <Grid item lg={8} md={8} sm={8} style={{ marginTop: 25 }} xs={8}>
                      <Typography style={{ fontWeight: 'bold', paddingBottom: 0 }}>
                        {formatMessage(messages.sendImmediatelyExplanation)}
                      </Typography>
                    </Grid>
                  )}
                  {/* Log type */}
                  {isEditable &&
                    signalId < 40 &&
                    !sendImmediately &&
                    triggerType !== 3 &&
                    triggerType !== 4 &&
                    triggerType !== 8 &&
                    filterType !== 6 ||
                  signalId >= 40 ? (
                      <Grid item lg={3} md={4} sm={6} xs={12}>
                        <FormControl error={logTypeErrorMessage !== ''} style={{ width: '100%' }}>
                          <InputLabel>{formatMessage(messages.logType)}</InputLabel>
                          <Select
                            name={SIGNAL_FIELDS.logType + '-' + signalId}
                            onChange={event => {
                              handleFieldChange(signalId, SIGNAL_FIELDS.logType, event.target.value)
                            }}
                            value={logType}
                          >
                            {optionsForSignalItemSelects.logType.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>{logTypeErrorMessage}</FormHelperText>
                        </FormControl>
                      </Grid>
                    ) : 
                    (!sendImmediately &&
                      triggerType !== 3 &&
                      triggerType !== 4 &&
                      triggerType !== 8 &&
                      signalId < 40 ||
                      signalId >= 40) && (
                      <Grid item lg={1} md={2} sm={3} xs={4}>
                        <Typography>{formatMessage(messages.logType)}</Typography>
                        <label>{optionsForSignalItemSelects.logType.find(lt => lt.value === logType)?.label}</label>
                      </Grid>
                    )
                  }
                  {/* Sample period */}
                  {isEditable &&
                    !sendImmediately &&
                    triggerType !== 3 &&
                    triggerType !== 4 &&
                    triggerType !== 8 &&
                    logType === 2 &&
                    signalId < 40 &&
                    filterType !== 6 ||
                  signalId >= 40 && logType === 2 ? (
                      <Grid item lg={3} md={4} sm={6} xs={12}>
                        <FormControl error={samplePeriodErrorMessage !== ''} style={{ width: '100%' }}>
                          <InputLabel>{formatMessage(messages.samplePeriod)}</InputLabel>
                          <Input
                            id={SIGNAL_FIELDS.samplePeriod + '-' + signalId}
                            inputProps={{ min: 0, max: 4294967295 }}
                            onChange={event => {
                              handleFieldChange(signalId, SIGNAL_FIELDS.samplePeriod, event.target.value)
                            }}
                            onWheel={event => event.target.blur()}
                            placeholder='Multiple of 10'
                            type='number'
                            value={samplePeriod}
                          />
                          <FormHelperText>{samplePeriodErrorMessage}</FormHelperText>
                        </FormControl>
                      </Grid>
                    ) : 
                    (!sendImmediately &&
                      triggerType !== 3 &&
                      triggerType !== 4 &&
                      triggerType !== 8 &&
                      logType === 2 &&
                      signalId < 40 &&
                      filterType !== 6 ||
                      signalId >= 40 && logType === 2) && (
                      <Grid item lg={1} md={2} sm={3} xs={4}>
                        <Typography>{formatMessage(messages.samplePeriod)}</Typography>
                        <label>{samplePeriod}</label>
                      </Grid>
                    )
                  }
                </Grid>
                {signalId < 40 && !sendImmediately && filterType !== 6 && (
                  <React.Fragment>
                    <Typography style={{ fontWeight: 'bold', paddingTop: 25 }}>
                      {formatMessage(messages.trigger)}
                    </Typography>
                    <Divider style={{ backgroundColor: 'black', margin: '0 0 20px 0' }} />
                    <Grid container spacing={2}>
                      {/* Trigger type */}
                      {isEditable ? (
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                          <FormControl error={triggerTypeErrorMessage !== ''} style={{ width: '100%' }}>
                            <InputLabel>{formatMessage(messages.triggerType)}</InputLabel>
                            <Select
                              name={SIGNAL_FIELDS.triggerType + '-' + signalId}
                              onChange={event => {
                                handleFieldChange(signalId, SIGNAL_FIELDS.triggerType, event.target.value)
                              }}
                              value={triggerType}
                            >
                              {optionsForSignalItemSelects.triggerType.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>{triggerTypeErrorMessage}</FormHelperText>
                          </FormControl>
                        </Grid>
                      ) : (
                        <Grid item lg={1} md={2} sm={3} xs={4}>
                          <Typography>{formatMessage(messages.triggerType)}</Typography>
                          <label>
                            {optionsForSignalItemSelects.triggerType.find(tt => tt.value === triggerType)?.label}
                          </label>
                        </Grid>
                      )}
                      {/* Trigger source */}
                      {isEditable && triggerType !== 0 ? (
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                          <FormControl error={triggerSourceErrorMessage !== ''} style={{ width: '100%' }}>
                            <InputLabel>{formatMessage(messages.triggerSource)}</InputLabel>
                            <Input
                              id={SIGNAL_FIELDS.triggerSource + '-' + signalId}
                              inputProps={{ min: 0, max: 79 }}
                              onChange={event => {
                                handleFieldChange(signalId, SIGNAL_FIELDS.triggerSource, event.target.value)
                              }}
                              onWheel={event => event.target.blur()}
                              placeholder='Number of the virtual signal [0 - 79]'
                              type='number'
                              value={triggerSource}
                            />
                            <FormHelperText>{triggerSourceErrorMessage}</FormHelperText>
                          </FormControl>
                        </Grid>
                      ) : 
                        triggerType !== 0 && (
                          <Grid item lg={1} md={2} sm={3} xs={4}>
                            <Typography>{formatMessage(messages.triggerSource)}</Typography>
                            <label>{triggerSource}</label>
                          </Grid>
                        )
                      }
                      {/* Trigger upper threshold */}
                      {isEditable && triggerType > 0 && triggerType < 8 ? (
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                          <FormControl error={triggerUpperThresholdErrorMessage !== ''} style={{ width: '100%' }}>
                            <InputLabel>{formatMessage(messages.triggerUpperThreshold)}</InputLabel>
                            <Input
                              id={SIGNAL_FIELDS.triggerUpperThreshold + '-' + signalId}
                              inputProps={{ min: 0, max: 4294967295 }}
                              onChange={event => {
                                handleFieldChange(signalId, SIGNAL_FIELDS.triggerUpperThreshold, event.target.value)
                              }}
                              onWheel={event => event.target.blur()}
                              type='number'
                              value={triggerUpperThreshold}
                            />
                            <FormHelperText>{triggerUpperThresholdErrorMessage}</FormHelperText>
                          </FormControl>
                        </Grid>
                      ) : 
                        triggerType > 0 &&
                        triggerType < 8 && (
                          <Grid item lg={1} md={2} sm={3} xs={4}>
                            <Typography>{formatMessage(messages.triggerUpperThreshold)}</Typography>
                            <label>{triggerUpperThreshold}</label>
                          </Grid>
                        )
                      }
                      {/* Trigger lower threshold */}
                      {isEditable && (triggerType === 5 || triggerType === 6) ? (
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                          <FormControl error={triggerLowerThresholdErrorMessage !== ''} style={{ width: '100%' }}>
                            <InputLabel>{formatMessage(messages.triggerLowerThreshold)}</InputLabel>
                            <Input
                              id={SIGNAL_FIELDS.triggerLowerThreshold + '-' + signalId}
                              inputProps={{ min: 0, max: 4294967295 }}
                              onChange={event => {
                                handleFieldChange(signalId, SIGNAL_FIELDS.triggerLowerThreshold, event.target.value)
                              }}
                              onWheel={event => event.target.blur()}
                              type='number'
                              value={triggerLowerThreshold}
                            />
                            <FormHelperText>{triggerLowerThresholdErrorMessage}</FormHelperText>
                          </FormControl>
                        </Grid>
                      ) : 
                        (triggerType === 5 || triggerType === 6) && (
                          <Grid item lg={1} md={2} sm={3} xs={4}>
                            <Typography>{formatMessage(messages.triggerLowerThreshold)}</Typography>
                            <label>{triggerLowerThreshold}</label>
                          </Grid>
                        )
                      }
                      {/* Trigger threshold divider */}
                      {isEditable && triggerType !== 0 && triggerType !== 8 ? (
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                          <FormControl error={triggerThresholdDividerErrorMessage !== ''} style={{ width: '100%' }}>
                            <InputLabel>{formatMessage(messages.triggerThresholdDivider)}</InputLabel>
                            <Input
                              id={SIGNAL_FIELDS.triggerThresholdDivider + '-' + signalId}
                              inputProps={{ min: 1, max: 4294967295 }}
                              onChange={event => {
                                handleFieldChange(signalId, SIGNAL_FIELDS.triggerThresholdDivider, event.target.value)
                              }}
                              onWheel={event => event.target.blur()}
                              type='number'
                              value={triggerThresholdDivider}
                            />
                            <FormHelperText>{triggerThresholdDividerErrorMessage}</FormHelperText>
                          </FormControl>
                        </Grid>
                      ) : 
                        triggerType !== 0 &&
                        triggerType !== 8 && (
                          <Grid item lg={1} md={2} sm={3} xs={4}>
                            <Typography>{formatMessage(messages.triggerThresholdDivider)}</Typography>
                            <label>{triggerThresholdDivider}</label>
                          </Grid>
                        )
                      }
                      {/* Trigger log type */}
                      {isEditable &&
                      triggerType !== 0 &&
                      triggerType !== 3 &&
                      triggerType !== 4 &&
                      triggerType !== 8 ? (
                          <Grid item lg={3} md={4} sm={6} xs={12}>
                            <FormControl error={triggerLogTypeErrorMessage !== ''} style={{ width: '100%' }}>
                              <InputLabel>{formatMessage(messages.triggerLogType)}</InputLabel>
                              <Select
                                name={SIGNAL_FIELDS.triggerLogType + '-' + signalId}
                                onChange={event => {
                                  handleFieldChange(signalId, SIGNAL_FIELDS.triggerLogType, event.target.value)
                                }}
                                value={triggerLogType}
                              >
                                {optionsForSignalItemSelects.logType.map((option, index) => (
                                  <MenuItem key={index} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>{triggerLogTypeErrorMessage}</FormHelperText>
                            </FormControl>
                          </Grid>
                        ) : 
                        triggerType !== 0 &&
                        triggerType !== 3 &&
                        triggerType !== 4 &&
                        triggerType !== 8 && (
                          <Grid item lg={1} md={2} sm={3} xs={4}>
                            <Typography>{formatMessage(messages.triggerLogType)}</Typography>
                            <label>
                              {optionsForSignalItemSelects.logType.find(tlt => tlt.value === triggerLogType)?.label}
                            </label>
                          </Grid>
                        )
                      }
                      {/* Trigger sample period */}
                      {isEditable &&
                      triggerType !== 0 &&
                      triggerType !== 3 &&
                      triggerType !== 4 &&
                      triggerType !== 8 &&
                      triggerLogType === 2 ? (
                          <Grid item lg={3} md={4} sm={6} xs={12}>
                            <FormControl error={samplePeriodTriggeredErrorMessage !== ''} style={{ width: '100%' }}>
                              <InputLabel>{formatMessage(messages.samplePeriodTriggered)}</InputLabel>
                              <Input
                                id={SIGNAL_FIELDS.samplePeriodTriggered + '-' + signalId}
                                inputProps={{ min: 0, max: 4294967295 }}
                                onChange={event => {
                                  handleFieldChange(signalId, SIGNAL_FIELDS.samplePeriodTriggered, event.target.value)
                                }}
                                onWheel={event => event.target.blur()}
                                placeholder='Multiple of 10'
                                type='number'
                                value={samplePeriodTriggered}
                              />
                              <FormHelperText>{samplePeriodTriggeredErrorMessage}</FormHelperText>
                            </FormControl>
                          </Grid>
                        ) : 
                        triggerType !== 0 &&
                        triggerType !== 3 &&
                        triggerType !== 4 &&
                        triggerType !== 8 &&
                        triggerLogType === 2 && (
                          <Grid item lg={1} md={2} sm={3} xs={4}>
                            <Typography>{formatMessage(messages.samplePeriodTriggered)}</Typography>
                            <label>{samplePeriodTriggered}</label>
                          </Grid>
                        )
                      }
                      {/* Trigger send immediately */}
                      {isEditable && (triggerType === 3 || triggerType === 4 || triggerType === 8) ? (
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={triggerSendImmediately}
                                classes={{ root: classes.checkbox, checked: classes.checked }}
                                onClick={event => {
                                  handleFieldChange(
                                    signalId,
                                    SIGNAL_FIELDS.triggerSendImmediately,
                                    event.target.checked
                                  )
                                }}
                              />
                            }
                            label={formatMessage(messages.triggerSendImmediately)}
                            style={{ marginTop: '15px', marginBottom: 0 }}
                          />
                        </Grid>
                      ) : 
                        (triggerType === 3 || triggerType === 4 || triggerType === 8) && (
                          <Grid item lg={1} md={2} sm={3} xs={4}>
                            <Typography>{formatMessage(messages.triggerSendImmediately)}</Typography>
                            <label>{triggerSendImmediately ? 'True' : 'False'}</label>
                          </Grid>
                        )
                      }
                    </Grid>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </CardContent>
      </Collapse>
    </Card>
  )
}

Signal.propTypes = {
  cch: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  cchErrorMessage: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  cornerFrequency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  cornerFrequencyErrorMessage: PropTypes.string.isRequired,
  customUnit: PropTypes.string,
  editableId: PropTypes.bool.isRequired,
  endianness: PropTypes.number.isRequired,
  endiannessErrorMessage: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  filterType: PropTypes.number.isRequired,
  filterTypeErrorMessage: PropTypes.string.isRequired,
  gain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  gainErrorMessage: PropTypes.string.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleSignalCopy: PropTypes.func,
  handleSignalDelete: PropTypes.func.isRequired,
  handleSignalIdChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isExtendedCanId: PropTypes.bool.isRequired,
  isLegacyVersion: PropTypes.bool.isRequired,
  lengthOfBits: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lengthOfBitsErrorMessage: PropTypes.string.isRequired,
  logToPortal: PropTypes.bool.isRequired,
  logType: PropTypes.number.isRequired,
  logTypeErrorMessage: PropTypes.string.isRequired,
  makeSignalIdEditable: PropTypes.func.isRequired,
  multiplier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  multiplierErrorMessage: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nameErrorMessage: PropTypes.string.isRequired,
  noErrorInSignal: PropTypes.bool,
  offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  offsetErrorMessage: PropTypes.string.isRequired,
  overflowErrorMessage: PropTypes.string.isRequired,
  piu: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  piuErrorMessage: PropTypes.string.isRequired,
  quotient: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  quotientErrorMessage: PropTypes.string.isRequired,
  samplePeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  samplePeriodErrorMessage: PropTypes.string.isRequired,
  samplePeriodTriggered: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  samplePeriodTriggeredErrorMessage: PropTypes.string.isRequired,
  sendImmediately: PropTypes.bool.isRequired,
  signalColor: PropTypes.string.isRequired,
  signalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  signalIdErrorMessage: PropTypes.string.isRequired,
  signalSource: PropTypes.number.isRequired,
  signalSourceErrorMessage: PropTypes.string.isRequired,
  signedness: PropTypes.number.isRequired,
  signednessErrorMessage: PropTypes.string.isRequired,
  sml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  smlErrorMessage: PropTypes.string.isRequired,
  ss: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  ssErrorMessage: PropTypes.string.isRequired,
  triggerLogType: PropTypes.number.isRequired,
  triggerLogTypeErrorMessage: PropTypes.string.isRequired,
  triggerLowerThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  triggerLowerThresholdErrorMessage: PropTypes.string.isRequired,
  triggerSendImmediately: PropTypes.bool.isRequired,
  triggerSource: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  triggerSourceErrorMessage: PropTypes.string.isRequired,
  triggerThresholdDivider: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  triggerThresholdDividerErrorMessage: PropTypes.string.isRequired,
  triggerType: PropTypes.number.isRequired,
  triggerTypeErrorMessage: PropTypes.string.isRequired,
  triggerUpperThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  triggerUpperThresholdErrorMessage: PropTypes.string.isRequired,
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  unitErrorMessage: PropTypes.string.isRequired,
  unitType: PropTypes.number,
  validateNewSignalId: PropTypes.func.isRequired
}

Signal.defaultProps = {
  customUnit: undefined,
  handleSignalCopy: () => {},
  noErrorInSignal: true,
  unitType: 0
}

export default withStyles(styles)(injectIntl(React.memo(Signal)))
