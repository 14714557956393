import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import SelectMachine from 'modules/groupDashboards/Modals/SelectMachine'
import { generateDeviceData } from 'utils/deviceDataGenerator'

import messages from './messages'

const styles = theme => ({
  selectedDeviceText: {
    display: 'inline',
    marginRight: 20
  },
  devicesButton: {
    marginBottom: 15
  }
})

class ValueSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const devicesData = props.devicesData[props.eid] || {
      staticData: [{}, {}, { value: '--' }],
      dinamicDataId: '',
      dinamicData: []
    }

    const { data, size, bold, align } = props.data

    this.state = {
      node: devicesData,
      showValues: props.devicesData[props.eid] !== undefined,
      deviceEid: [props.eid],
      deviceEidError: '',
      staticData: data,
      devicesData: props.devicesData,
      size,
      bold,
      align,
      devicesTableDisplayed: false,
      devicesButtonTextKey: 'changeMachine',
      originalDevice: '',
      configurationLoading: false
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  changeSelectedDevice = device => {
    this.setState({
      deviceEid: [device.EID],
      showValues: true,
      node: generateDeviceData(device),
      originalDevice: device,
      configurationLoading: ['CS100', 'CS500'].includes(device.deviceType)
    })
  }

  updateDeviceConfiguration = configuration => {
    this.setState(({ originalDevice }) => {
      const nodeWithConfig = { ...originalDevice, deviceConfiguration: configuration }
      const node = generateDeviceData(nodeWithConfig)
      return {
        node,
        configurationLoading: false
      }
    })
  }

  handleStaticChange = event => {
    this.setState({
      staticData: event.target.value
    })
  }

  changeDevicesTableDisplay = () => {
    this.setState(state => ({
      devicesTableDisplayed: !state.devicesTableDisplayed,
      devicesButtonTextKey: state.devicesTableDisplayed ? 'changeMachine' : 'hideMachines'
    }))
  }

  closeSettings = () => {
    this.props.closeSettings()
  }

  saveSettings = () => {
    let error = false

    if (this.state.deviceEid.length === 0) {
      error = true
      this.setState({
        deviceEidError: this.formatMessage(messages.youMustSelectAMachine)
      })
    }

    if (!error) {
      const data = {
        data: this.state.staticData,
        size: this.state.size,
        bold: this.state.bold,
        align: this.state.align
      }

      const propsNode = this.props.devicesData[this.state.deviceEid[0]]
      const deviceInfo = propsNode ? '' : this.state.node

      this.props.saveSettings(data, this.state.deviceEid, deviceInfo)
    }
  }

  renderDeviceName = () => {
    const {
      node: { staticData }
    } = this.state
    const dataItem = staticData.find(({ name }) => name === 'name')
    return dataItem?.value || '--'
  }

  render() {
    const { classes } = this.props
    const {
      node: { staticData }
    } = this.state
    const selectedDeviceEid = this.state.deviceEid.length > 0 ? this.state.deviceEid[0] : ''
    const ignorableFields = ['hasConfiguration', 'deviceStatus']
    const filteredStaticData = staticData.filter(({ name }) => !ignorableFields.includes(name))

    return (
      <Dialog
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='xl'
        onClose={this.closeSettings}
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.valueWidget)}
          <IconButton
            onClick={this.closeSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText classes={{ root: classes.selectedDeviceText }} id='alert-dialog-slide-description'>
            <strong>{this.formatMessage(messages.selectedMachine)}: </strong> {this.renderDeviceName()}
          </DialogContentText>

          <Button
            className='primary-action-button'
            classes={{ root: classes.devicesButton }}
            onClick={this.changeDevicesTableDisplay}
          >
            {this.formatMessage(messages[this.state.devicesButtonTextKey])}
          </Button>
          {this.state.devicesTableDisplayed && (
            <SelectMachine
              changeSelectedDevice={this.changeSelectedDevice}
              selectedDeviceEid={selectedDeviceEid}
              updateDeviceConfiguration={this.updateDeviceConfiguration}
            />
          )}

          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '0px 0px 10px 0px' }}>
              {this.formatMessage(messages.valueWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl error={this.state.valueError} style={{ width: '100%' }}>
                <InputLabel htmlFor='staticData-label-placeholder' shrink>
                  {this.formatMessage(messages.values)}
                </InputLabel>
                {this.state.showValues ? (
                  <Select
                    displayEmpty
                    input={<Input id='staticData-label-placeholder' name='staticData' />}
                    name='staticData'
                    onChange={this.handleStaticChange}
                    value={this.state.staticData}
                  >
                    <MenuItem key='staticDataDefault' value=''>
                      {this.formatMessage(messages.selectValueToDisplay)}
                    </MenuItem>
                    {filteredStaticData.map((eachStaticData, index) => {
                      return (
                        <MenuItem key={eachStaticData.name + index} value={eachStaticData.name}>
                          {eachStaticData.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.otherValueWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='size-label-placeholder' shrink>
                  {this.formatMessage(messages.size)}
                </InputLabel>
                <Select
                  autoWidth
                  displayEmpty
                  input={<Input id='size-label-placeholder' name='size' />}
                  name='size'
                  onChange={this.handleChange('size')}
                  value={this.state.size}
                >
                  <MenuItem value={14}>14px</MenuItem>
                  <MenuItem value={16}>16px</MenuItem>
                  <MenuItem value={18}>18px</MenuItem>
                  <MenuItem value={20}>20px</MenuItem>
                  <MenuItem value={22}>22px</MenuItem>
                  <MenuItem value={24}>24px</MenuItem>
                  <MenuItem value={26}>26px</MenuItem>
                  <MenuItem value={28}>28px</MenuItem>
                  <MenuItem value={32}>32px</MenuItem>
                  <MenuItem value={36}>36px</MenuItem>
                  <MenuItem value={42}>42px</MenuItem>
                  <MenuItem value={48}>48px</MenuItem>
                  <MenuItem value={54}>54px</MenuItem>
                  <MenuItem value={62}>62px</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectSizeInPixels)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='bold-label' shrink>
                  {this.formatMessage(messages.weight)}
                </InputLabel>
                <Select
                  autoWidth={false}
                  displayEmpty
                  input={<Input id='weight-label-placeholder' name='weight' />}
                  name='weight'
                  onChange={this.handleChange('bold')}
                  value={this.state.bold}
                >
                  <MenuItem value='bold'>{this.formatMessage(messages.bold)}</MenuItem>
                  <MenuItem value='normal'>{this.formatMessage(messages.normal)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectWeight)}</FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='align-label' shrink>
                  {this.formatMessage(messages.align)}
                </InputLabel>
                <Select
                  autoWidth={false}
                  displayEmpty
                  input={<Input id='align-label-placeholder' name='align' />}
                  name='align'
                  onChange={this.handleChange('align')}
                  value={this.state.align}
                >
                  <MenuItem value='left'>{this.formatMessage(messages.left)}</MenuItem>
                  <MenuItem value='center'>{this.formatMessage(messages.center)}</MenuItem>
                  <MenuItem value='right'>{this.formatMessage(messages.right)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectAlign)}</FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button className='cancel-button' onClick={this.closeSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button
            className='primary-action-button'
            disabled={this.state.configurationLoading}
            onClick={this.saveSettings}
          >
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ValueSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  devicesData: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  saveSettings: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(ValueSettings))
