import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { getMachinesUrl } from 'components/machines'
import { actions } from 'ducks/advancedSignalsConfigDialog'
import { utils as groupUtils } from 'ducks/groups'
import csNode from 'modules/csNode'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import MachineDetailsLoader from './MachineDetailsLoader'

const mapStateToProps = (state, { match }) => {
  const { nodeId } = match.params
  return {
    nodeId,
    nodesUrl: getMachinesUrl(),
    nodeLastLocation: csNode.utils.getLastLocation(state, nodeId),
    groupId: groupUtils.getSelectedGroup(state).id,
    canConfigureAdvancedSignals:
      resolvePermissions(state, c.DEVICECONFIGURATION_READ) && resolvePermissions(state, c.DEVICECONFIGURATION_EDIT),
    nodeLastGPSTimestamp: csNode.utils.getLastGPSTimestamp(state, nodeId)
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDevice: csNode.actions.fetchCSNodeAndLocation,
      getDevicesTimeSeriesLast: csNode.actions.fetchLastLocation,
      onOpenDialog: actions.open
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MachineDetailsLoader))

export * from './url'
