import PropTypes from 'prop-types'
import React from 'react'

import MuiThemeProvider from 'components/Theme'

import Header from './Header'
import Sidebar from './Sidebar'

const AdminTemplate = ({ children, isCollapsed, isMapMounted, isMobileNotificationDetails, isMobile }) => {
  // on-canvas is used on small screens, but it is harmless in bigger screens.
  // Since I don't know how to add it in a clean way (without checking sizes here),
  // I added it for all cases.
  const className = isCollapsed || isMobileNotificationDetails ? 'app nav-collapsed-min' : 'app on-canvas'
  const containerClassName = isMapMounted ? 'content-container map-container' : 'content-container'
  const sidebarWidth = isMobile ? '0px' : isCollapsed ? '60px' : '220px'

  return (
    <MuiThemeProvider>
      <div className={className} style={{ '--sidebar-width': sidebarWidth }}>
        <Header />
        <div className='main-container'>
          {!isMobileNotificationDetails && <Sidebar isCollapsed={isCollapsed} />}
          <div className={containerClassName}>{children}</div>
        </div>
      </div>
    </MuiThemeProvider>
  )
}

AdminTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  isCollapsed: PropTypes.bool,
  isMapMounted: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  isMobileNotificationDetails: PropTypes.bool.isRequired
}

AdminTemplate.defaultProps = {
  isCollapsed: false,
  isMapMounted: false
}

export default AdminTemplate
