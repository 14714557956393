import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { DM1TableSettings, LastKnownValueTableSettings } from './TableTypeSettings'
import messages from './messages'
import { getDefaultSettingsByTableType } from './utils'

const styles = {
  checkbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  checked: {}
}

class TableSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage },
      data: { data, visualSettings },
      eids
    } = props

    this.formatMessage = formatMessage

    const settings = getDefaultSettingsByTableType(data, props?.data?.settings, eids)

    this.state = {
      data,
      settings,
      condensed: visualSettings?.condensed || false,
      defaultRowNumber: visualSettings?.defaultRowNumber || 10
    }
  }

  handleServiceChange = (event, selectedService) => {
    const id = selectedService?.id || ''
    const settings = getDefaultSettingsByTableType(id, { columns: [] })
    this.setState({ data: id, settings })
  }

  handleCondensedChange = () => {
    this.setState(state => ({
      condensed: !state.condensed
    }))
  }

  handleDefaultRowNumberChange = event => {
    this.setState({
      defaultRowNumber: event.target.value
    })
  }

  handleSettingsChange = updatedSettings => {
    this.setState(({ settings }) => ({ settings: { ...settings, ...updatedSettings } }))
  }

  getDM1TableSettings = () => {
    let settings = {}
    const {
      settings: { columns, eids, isGroupWidget }
    } = this.state
    if (eids.length === 0) {
      const eidsErrorText = this.formatMessage(messages.thisFieldIsRequired)
      this.handleSettingsChange({ eidsErrorText })
    } else if (columns.length === 0) {
      const columnsErrorText = this.formatMessage(messages.thisFieldIsRequired)
      this.handleSettingsChange({ columnsErrorText })
    } else {
      settings = {
        columns,
        eids,
        isGroupWidget
      }
    }
    return settings
  }

  getLastKnownValueTableSettings = () => {
    let settings = {}
    const {
      settings: { configurationId, columns, eids, isGroupWidget }
    } = this.state
    if (configurationId === '') {
      const configurationIdErrorText = this.formatMessage(messages.thisFieldIsRequired)
      this.handleSettingsChange({ configurationIdErrorText })
    } else if (eids.length === 0) {
      const eidsErrorText = this.formatMessage(messages.thisFieldIsRequired)
      this.handleSettingsChange({ eidsErrorText })
    } else if (columns.length === 0) {
      const columnsErrorText = this.formatMessage(messages.thisFieldIsRequired)
      this.handleSettingsChange({ columnsErrorText })
    } else {
      settings = {
        configurationId,
        columns,
        eids,
        isGroupWidget
      }
    }
    return settings
  }

  handleSettingsSave = () => {
    const { saveSettings } = this.props
    const { data, settings, condensed, defaultRowNumber } = this.state
    const widgetData = {
      data,
      visualSettings: { condensed, defaultRowNumber },
      settings
    }

    switch (data) {
      case 'dm1':
        widgetData.settings = this.getDM1TableSettings()
        break
      case 'lastKnownValue':
        widgetData.settings = this.getLastKnownValueTableSettings()
        break
    }
    const isValidSettings = Object.keys(widgetData.settings).length > 0

    if (isValidSettings) {
      saveSettings(widgetData, settings.eids)
    }
  }

  renderTableTypedSettings = tableType => {
    const { settings } = this.state
    switch (tableType) {
      case 'dm1':
        return <DM1TableSettings onSettingsChange={this.handleSettingsChange} {...settings} />
      case 'lastKnownValue':
        return <LastKnownValueTableSettings onSettingsChange={this.handleSettingsChange} {...settings} />
      default:
        return null
    }
  }

  render() {
    const { classes, closeSettings } = this.props
    const { data, settings, condensed, defaultRowNumber } = this.state
    const { configurationLoading } = settings

    const serviceOptions = [
      { name: this.formatMessage(messages.dm1Messages), id: 'dm1' },
      { name: this.formatMessage(messages.lastKnownValue), id: 'lastKnownValue' }
    ]

    return (
      <Dialog
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='xl'
        onClose={closeSettings}
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.tableWidget)}
          <IconButton
            onClick={closeSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container item md={4} sm={6} xs={12}>
            <Autocomplete
              fullWidth
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              id='tableType'
              onChange={this.handleServiceChange}
              options={serviceOptions}
              renderInput={params => <TextField {...params} label={this.formatMessage(messages.tableType)} />}
              value={serviceOptions.find(option => option.id === data) || null}
            />
          </Grid>
          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.tableWidgetSettings)}
            </span>
          </DialogContentText>
          {this.renderTableTypedSettings(data)}
          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.visualWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={condensed}
                    classes={{ root: classes.checkbox, checked: classes.checked }}
                    onChange={this.handleCondensedChange}
                  />
                }
                label={this.formatMessage(messages.condensedRows)}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='default-row-number'>{this.formatMessage(messages.defaultRowNumber)}</InputLabel>
                <Select
                  input={<Input id='default-row-number' name='defaultRowNumber' />}
                  name='defaultRowNumber'
                  onChange={this.handleDefaultRowNumberChange}
                  value={defaultRowNumber}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={200}>200</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={closeSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' disabled={configurationLoading} onClick={this.handleSettingsSave}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

TableSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  eids: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(TableSettings))
