import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import Home from './Home'

const mapStateToProps = state => ({
  canViewDeviceDashboards: resolvePermissions(state, c.DEVICEDASHBOARD_READ)
})

export default connect(mapStateToProps)(Home)
export { SENT_CAN_HISTORIC_BASE_URL } from './urls'
