import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { withRouter, matchPath } from 'react-router-dom'

import { NOTIFICATIONS_DETAIL_TEMPLATE_URL } from 'components/notifications'
import { utils } from 'ducks/theme'

import PortalLoading from './PortalLoading'

const mapStateToProps = (state, { location }) => {
  const match = matchPath(location.pathname, { path: NOTIFICATIONS_DETAIL_TEMPLATE_URL, exact: true, strict: false })
  return {
    logo: utils.getLogo(state),
    isMobileNotificationDetails: isMobile && location.pathname === `/notifications/${match?.params?.notificationId}`
  }
}

export default withRouter(connect(mapStateToProps)(PortalLoading))
