import update from 'immutability-helper'

import { initialValues, initSignal, initCANMessage } from 'modules/configurationsCS500/config'
import { SEND_CAN_MESSAGES_FIELDS } from 'modules/configurationsCS500/constants'
import { actions } from 'utils/http'

import * as at from './actionTypes'
import * as c from './constants'

const { types: ht } = actions

const initialState = initialValues()
const initialSignal = initSignal()
const initialCANMessage = initCANMessage()

const getRandomColor = () => {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const signalSourceValidValues = {
  CAN_J1939: 1,
  CAN_CUSTOMIZED: 2,
  MFIO: 3
}

const getSignalName = (signalSourceValue, signalId) => {
  switch (signalSourceValue) {
    case signalSourceValidValues.CAN_J1939:
      return `CAN J1939 - Signal ${signalId}`
    case signalSourceValidValues.CAN_CUSTOMIZED:
      return `CustomCAN - Signal ${signalId}`
    case signalSourceValidValues.MFIO:
      return `MFIO - Signal ${signalId}`
    default:
      const n = Math.floor(signalId / 10)
      const m = signalId % 10
      return `Signal${n}__${m}`
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case at.SET_CS500_CONFIGURATIONS:
      return {
        ...state,
        ...action.payload
      }

    case at.NV_CONFIG_FIELD_CHANGE:
      const { field, value } = action.payload
      let gnssConditionValueField = ''
      if (field === 'gnssPositionEnabled' && value) gnssConditionValueField = 'positionLogTime'
      else if (field === 'gnssAltitudeEnabled' && value) gnssConditionValueField = 'altitudeLogTime'
      else if (field === 'gnssSpeedEnabled' && value) gnssConditionValueField = 'speedLogTime'
      else if (field === 'gnssHeadingEnabled' && value) gnssConditionValueField = 'headingLogTime'
      const gnssConditionValue = state[gnssConditionValueField] === 4294967295 ? 300 : state[gnssConditionValueField]
      return {
        ...state,
        [field]: value,
        ...value !== undefined && { [field + 'ErrorMessage']: '' },
        ...gnssConditionValueField !== '' && { [gnssConditionValueField]: gnssConditionValue }
      }

    case at.NV_CONFIG_ERROR:
      const errors = action.payload.errors
      const newState = errors.reduce((modified, current) => {
        if (current.path.includes('overflowErrorMessage')) {
          const dividedPath = current.path.split('[')
          const signals = dividedPath[0]
          const numberOfSignal = parseInt(dividedPath[1].split(']')[0])
          return update(modified, {
            [signals]: { [numberOfSignal]: { ['overflowErrorMessage']: { $set: current.message } } }
          })
        } else {
          if (!current.path.includes('['))
            return update(modified, { [current.path + 'ErrorMessage']: { $set: current.message } })
          else {
            const dividedPath = current.path.split('[')
            const signals = dividedPath[0]
            const numberOfSignal = parseInt(dividedPath[1].split(']')[0])
            const property = dividedPath[1].split(']')[1].substring(1)
            return update(modified, {
              [signals]: { [numberOfSignal]: { [property + 'ErrorMessage']: { $set: current.message } } }
            })
          }
        }
      }, state)
      return newState

    case at.NV_CONFIG_ADD_CAN_MESSAGE:
      let newStateCanMessage
      const MAX_CAN_MESSAGES = 4

      const messageIdsInUse = state.sendCANMessages.map(s => s.canMessageId).sort((a, b) => a - b)
      if (messageIdsInUse.length < MAX_CAN_MESSAGES) {
        let nextCanMessageId

        for (let i = 0; i < MAX_CAN_MESSAGES && nextCanMessageId === undefined; i++) {
          if (i !== parseInt(messageIdsInUse[i])) {
            nextCanMessageId = i
          }
        }
        if (nextCanMessageId === undefined) nextCanMessageId = 0

        const newCANMessage = {
          ...initialCANMessage,
          canMessageId: nextCanMessageId
        }
        newStateCanMessage = {
          ...state,
          sendCANMessages: [...state.sendCANMessages, newCANMessage]
        }
      } else {
        newStateCanMessage = {
          ...state
        }
      }
      return newStateCanMessage

    case at.NV_CONFIG_ADD_SIGNAL:
      let nwState

      const idsInUse = state.signals.map(s => s.signalId).sort((a, b) => a - b)
      if (idsInUse.length < 80) {
        let nextId

        for (let i = 0; i < 80 && nextId === undefined; i++) {
          if (i !== parseInt(idsInUse[i])) {
            nextId = i
          }
        }
        if (nextId === undefined) nextId = 0

        const defaultName = getSignalName(initialSignal.signalSource, nextId)

        const newSignal = {
          ...initialSignal,
          signalId: nextId,
          name: defaultName,
          signalColor: getRandomColor()
        }
        nwState = {
          ...state,
          signals: [...state.signals, newSignal]
        }
      } else {
        nwState = {
          ...state
        }
      }
      return nwState

    case at.NV_CONFIG_COPY_CAN_MESSAGE:
      const MAX_SEND_CAN_MESSAGES = 4
      const msgIds = state.sendCANMessages.map(m => m.canMessageId).sort((a, b) => a - b)
      let newMsgState

      if (msgIds.length < MAX_SEND_CAN_MESSAGES) {
        let nextMsgId

        for (let i = 0; i < MAX_SEND_CAN_MESSAGES && nextMsgId === undefined; i++) {
          if (i !== parseInt(msgIds[i])) {
            nextMsgId = i
          }
        }
        if (nextMsgId === undefined) nextMsgId = 0

        const sendCANMessageToCopy = state.sendCANMessages.find(m => m.canMessageId === action.payload.canMessageId)

        const newCANMessage = {
          ...initialCANMessage,
          ...sendCANMessageToCopy,
          canMessageId: nextMsgId
        }

        newMsgState = {
          ...state,
          sendCANMessages: [...state.sendCANMessages, newCANMessage]
        }
      } else {
        newMsgState = {
          ...state
        }
      }
      return newMsgState

    case at.NV_CONFIG_COPY_SIGNAL:
      const ids = state.signals.map(s => s.signalId).sort((a, b) => a - b)
      let nState

      if (ids.length < 80) {
        let nxtId

        for (let i = 0; i < 80 && nxtId === undefined; i++) {
          if (i !== parseInt(ids[i])) {
            nxtId = i
          }
        }
        if (nxtId === undefined) nxtId = 0

        const {
          signalId: copyId,
          name: copyName,
          signalIdErrorMessage,
          editableId,
          ...signaToCopy
        } = state.signals.find(s => s.signalId === action.payload.signalId)

        const mfioRegExp = index => new RegExp('MFIO - Signal ##index##'.replace('##index##', index))
        const customSignalRegExp = index => new RegExp('CustomCAN - Signal ##index##'.replace('##index##', index))
        const canSignalRegExp = index => new RegExp('CAN J1939 - Signal ##index##'.replace('##index##', index))

        const getNewName = (currentName, currentId, newId) => {
          if (mfioRegExp(currentId).test(currentName)) {
            return `MFIO - Signal ${newId}`
          } else if (customSignalRegExp(currentId).test(currentName)) {
            return `CustomCAN - Signal ${newId}`
          } else if (canSignalRegExp(currentId).test(currentName)) {
            return `CAN J1939 - Signal ${newId}`
          } else {
            return currentName
          }
        }

        const nwSignal = {
          ...initialSignal,
          ...signaToCopy,
          signalId: nxtId,
          name: getNewName(copyName, copyId, nxtId),
          signalColor: getRandomColor()
        }

        nState = {
          ...state,
          signals: [...state.signals, nwSignal]
        }
      } else {
        nState = {
          ...state
        }
      }
      return nState

    case at.NV_CONFIG_DELETE_CAN_MESSAGE:
      const newCANMessages = state.sendCANMessages.filter(s => s.canMessageId !== action.payload.canMessageId)

      return {
        ...state,
        sendCANMessages: newCANMessages
      }

    case at.NV_CONFIG_DELETE_SIGNAL:
      const newSignals = state.signals.filter(s => s.signalId !== action.payload.signalId)

      return {
        ...state,
        signals: newSignals
      }

    case at.NV_CONFIG_CAN_MESSAGE_FIELD_CHANGE:
      const { canMessageId: canMssgId, field: fld, value: vl } = action.payload
      const currentCANMessageIndex = state.sendCANMessages.findIndex(s => s.canMessageId === canMssgId)
      const editedCANMessagesArray = [...state.sendCANMessages]

      editedCANMessagesArray[currentCANMessageIndex] = {
        ...state.sendCANMessages[currentCANMessageIndex],
        [fld]: vl,
        ...fld !== undefined && { [fld + 'ErrorMessage']: '' }
      }

      if (fld === SEND_CAN_MESSAGES_FIELDS.dataLength) {
        const dataLength = parseInt(vl)
        if (!isNaN(dataLength) && dataLength > 0 && dataLength < 9) {
          for (let i = dataLength; i < 8; i++) {
            editedCANMessagesArray[currentCANMessageIndex] = {
              ...editedCANMessagesArray[currentCANMessageIndex],
              ['byte' + i]: '',
              ['byte' + i + 'ErrorMessage']: ''
            }
          }
        }
      }

      return {
        ...state,
        sendCANMessages: editedCANMessagesArray
      }

    case at.NV_CONFIG_SIGNAL_FIELD_CHANGE:
      const { signalId: siId, field: fi, value: val } = action.payload

      const currentSignalIndex = state.signals.findIndex(s => s.signalId === siId)
      const editedSignalsArray = [...state.signals]
      const overflowCausingFields = ['signedness', 'multiplier', 'lengthOfBits', 'offset']

      editedSignalsArray[currentSignalIndex] = {
        ...state.signals[currentSignalIndex],
        [fi]: val,
        ...overflowCausingFields.includes(fi) && { overflowErrorMessage: '' },
        ...fi !== undefined && { [fi + 'ErrorMessage']: '' },
        ...fi === 'signalSource' &&
          val !== state.signals[currentSignalIndex].signalSource && {
          // Reset signal values
          cch: '',
          cchErrorMessage: '',
          piu: '',
          piuErrorMessage: '',
          sml: '',
          smlErrorMessage: '',
          ss: '',
          ssErrorMessage: '',
          lengthOfBits: '',
          lengthOfBitsErrorMessage: '',
          endianness: 0,
          endianessErrorMessage: '',
          signedness: 0,
          signednessErrorMessage: '',
          multiplier: '',
          multiplierErrorMessage: '',
          divider: '',
          dividerErrorMessage: '',
          offset: '',
          offsetErrorMessage: '',
          unit: '',
          unitErrorMessage: '',
          overflowErrorMessage: ''
        }
      }

      return {
        ...state,
        signals: editedSignalsArray
      }

    case at.EDITABLE_SIGNAL_ID_NVC:
      const idx = state.signals.findIndex(s => s.signalId === action.payload.signalId)
      const editableIdSignalsArray = [...state.signals]

      editableIdSignalsArray[idx] = {
        ...state.signals[idx],
        editableId: action.payload.isEditable
      }

      return {
        ...state,
        signals: editableIdSignalsArray
      }

    case at.NV_CONFIG_SIGNAL_ID_CHANGE:
      const z = state.signals.findIndex(s => s.signalId === action.payload.signalId)
      const editedIdSignalsArray = [...state.signals]

      editedIdSignalsArray[z] = {
        ...state.signals[z],
        newSignalId: action.payload.newSignalId
      }
      return {
        ...state,
        signals: editedIdSignalsArray
      }

    case at.NV_CONFIG_SIGNAL_ID_VALIDATE:
      const sIndex = state.signals.findIndex(s => s.signalId === action.payload.signalId)
      const nameInUse = state.signals.find(s => s.signalId === action.payload.signalId).name

      const currentSignalId = action.payload.signalId

      const newSignalId = state.signals[sIndex].newSignalId

      const usedIds = state.signals.map(s => s.signalId).sort((a, b) => a - b)
      const alreadyInUse = usedIds.includes(newSignalId)

      const validatedIdSignalsArray = [...state.signals]

      if (currentSignalId !== newSignalId && alreadyInUse) {
        validatedIdSignalsArray[sIndex] = {
          ...state.signals[sIndex],
          signalIdErrorMessage: 'ID already in use',
          editableId: true
        }
      } else if (newSignalId !== null && newSignalId !== undefined && newSignalId >= 0 && newSignalId <= 79) {
        const mfioRegExp = index => new RegExp('MFIO - Signal ##index##'.replace('##index##', index))
        const customSignalRegExp = index => new RegExp('CustomCAN - Signal ##index##'.replace('##index##', index))
        const canSignalRegExp = index => new RegExp('CAN J1939 - Signal ##index##'.replace('##index##', index))

        const getNewName = (currentName, currentId, newId) => {
          if (mfioRegExp(currentId).test(currentName)) {
            return `MFIO - Signal ${newId}`
          } else if (customSignalRegExp(currentId).test(currentName)) {
            return `CustomCAN - Signal ${newId}`
          } else if (canSignalRegExp(currentId).test(currentName)) {
            return `CAN J1939 - Signal ${newId}`
          } else {
            return currentName
          }
        }

        validatedIdSignalsArray[sIndex] = {
          ...state.signals[sIndex],
          signalId: newSignalId,
          signalIdErrorMessage: '',
          name: getNewName(nameInUse, currentSignalId, newSignalId),
          newSignalId: null
        }
      } else {
        validatedIdSignalsArray[sIndex] = {
          ...state.signals[sIndex],
          signalIdErrorMessage: '0 ≤ signal ID ≤ 79',
          editableId: true
        }
      }
      return {
        ...state,
        signals: validatedIdSignalsArray
      }

    case at.NV_CONFIG_MANAGE_ALERT:
      return {
        ...state,
        ...action.payload
      }

    case at.SET_NV_CONFIG:
      const { signals, sendCANMessages, ...params } = action.payload

      const sortedSignals = signals.sort((a, b) => a.signalId - b.signalId)

      const signalsWithColor = sortedSignals.map(s => {
        const signal = s
        signal.signalColor = getRandomColor()
        return {
          ...initialSignal,
          ...signal
        }
      })
      const initialCANMessages = sendCANMessages.map(scm => {
        const sendCANMessage = scm
        return {
          ...initialCANMessage,
          ...sendCANMessage
        }
      })

      return {
        ...state,
        ...params,
        signals: signalsWithColor,
        sendCANMessages: initialCANMessages,
        alertVisibility: 'hidden',
        alertMessageTitle: '',
        alertMessageText: ''
      }

    case at.RESET_NV_CONFIG:
      return initialValues()

    case ht.success(ht.CS_NODE_GET_NV_CONFIGURATION):
      const eid = action.payload.data.eid
      const parameters = action.payload.data.parameters
      const virtualParameters = action.payload.data.virtualParameters || []
      return {
        ...state,
        originalConfigs: {
          ...state.originalConfigs,
          [eid]: {
            parameters,
            virtualParameters
          }
        }
      }

    default:
      return state
  }
}

export default reducer

const getState = state => state[c.NAME]

export { getState }
