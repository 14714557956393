import { connect } from 'react-redux'

import { getStaticData } from 'ducks/dashboards'

import AddWidget from './AddWidget'

const mapStateToProps = state => ({
  staticData: getStaticData(state)
})

export default connect(mapStateToProps)(AddWidget)
