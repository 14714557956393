import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions } from 'ducks/configurationsCS500'

import GPSGeofenceSettings from './GPSGeofenceSettings'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleFieldChange: actions.changeLocalConfigurationField
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(GPSGeofenceSettings)
