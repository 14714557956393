import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { actions, utils as configUtils } from 'ducks/configurationsCS500'
import { utils as groupUtils } from 'ducks/groups'
import { utils as template } from 'ducks/template'
import csNode from 'modules/csNode'


import ViewConfigurationConnected from './ViewConfiguration'
import { getCS500ConfigurationsUrl } from '../url'

const mapStateToProps = (state, { match }) => ({
  configState: configUtils.getState(state),
  configurationsUrl: getCS500ConfigurationsUrl(),
  device: csNode.utils.getCSNodeByEID(state, match.params.deviceEid),
  deviceEid: match.params.deviceEid,
  groupId: groupUtils.getSelectedGroup(state).id,
  isSidebarCollapsed: template.isCollapsed(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCSNodeByEID: csNode.actions.fetchCSNodeByEid,
      getDeviceMetadata: csNode.actions.fetchDeviceMetadata,
      getDeviceNonVolatileConfiguration: csNode.actions.fetchDeviceNonVolatileConfiguration,
      resetLocalConfiguration: actions.resetLocalConfiguration,
      setCS500ValuePulse: csNode.actions.setCS500ValuePulse,
      setLocalConfiguration: actions.setLocalConfiguration,
      getNewestNVConfigurationDefinition: actions.fetchNewestNVConfigurationDefinition
    },
    dispatch
  )

const ViewConfiguration = withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewConfigurationConnected))

export { ViewConfiguration }
