import { connect } from 'react-redux'

import { selectors as geotrackingSelectors } from 'ducks/geotracking'

import MapView from './MapView'

const mapStateToProps = state => ({
  isGpsTrackingsLoading: geotrackingSelectors.getIsGpsTrackingsLoading(state),
  gpsTrackings: geotrackingSelectors.getGPSTrackings(state)
})

export default connect(mapStateToProps)(MapView)
