import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import messages from '../../../messages'
import { alertStyles } from '../../../styles'

const styles = {
  ...alertStyles
}

class NotAccessibleErrorAlert extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { classes, intl, user } = this.props
    let supportLink = process.env.REACT_APP_SUPPORT_LINK
    if (user?.email) supportLink += '&plus1_email=' + user.email
    supportLink += '#tab-overview'

    return (
      <Alert classes={{ message: classes.alertMessage }} severity='error'>
        <AlertTitle classes={{ root: classes.alertTitle }}>{intl.formatMessage(messages.notAccessible)}</AlertTitle>
        {intl.formatMessage(messages.notAccessibleMessage, {
          a: text => (
            <a className={classes.alertLink} href={supportLink} rel='noreferrer' target='_blank'>
              {text}
            </a>
          )
        })}
      </Alert>
    )
  }
}

NotAccessibleErrorAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  user: PropTypes.object
}

NotAccessibleErrorAlert.defaultProps = {
  user: null
}

export default withStyles(styles)(injectIntl(NotAccessibleErrorAlert))
