import { defineMessages } from 'react-intl'

const namespace = 'GPSGeofenceSettingsCS500'

const messages = defineMessages({
  gpsSettings: {
    id: `${namespace}.gpsSettings`,
    description: 'GPS settings card title',
    defaultMessage: 'GPS settings'
  },
  enablePositionLogging: {
    id: `${namespace}.enablePositionLogging`,
    description: 'Enable position logging checkbox label',
    defaultMessage: 'Enable position logging'
  },
  positionLoggingTime: {
    id: `${namespace}.positionLoggingTime`,
    description: 'positionLoggingTime input label',
    defaultMessage: 'Position logging time (s)'
  },
  enableAltitudeLogging: {
    id: `${namespace}.enableAltitudeLogging`,
    description: 'enableAltitudeLogging checkbox label',
    defaultMessage: 'Enable altitude logging'
  },
  altitudeLoggingTime: {
    id: `${namespace}.altitudeLoggingTime`,
    description: 'altitudeLoggingTime input label',
    defaultMessage: 'Altitude logging time (s)'
  },
  enableSpeedLogging: {
    id: `${namespace}.enableSpeedLogging`,
    description: 'enableSpeedLogging checkbox label',
    defaultMessage: 'Enable speed logging'
  },
  speedLoggingTime: {
    id: `${namespace}.speedLoggingTime`,
    description: 'speedLoggingTime input label',
    defaultMessage: 'Speed logging time (s)'
  },
  enableHeadingLogging: {
    id: `${namespace}.enableHeadingLogging`,
    description: 'enableHeadingLogging checkbox label',
    defaultMessage: 'Enable heading logging'
  },
  headingLoggingTime: {
    id: `${namespace}.headingLoggingTime`,
    description: 'headingLoggingTime input label',
    defaultMessage: 'Heading logging time (s)'
  },
  geofenceSettings: {
    id: `${namespace}.geofenceSettings`,
    description: 'Geofence settings card title',
    defaultMessage: 'Geofence settings'
  },
  enableGeofence: {
    id: `${namespace}.enableGeofence`,
    description: 'enableGeofence checkbox label',
    defaultMessage: 'Enable geofence'
  },
  geofenceEnabled: {
    id: `${namespace}.geofenceEnabled`,
    description: 'Geofence enabled',
    defaultMessage: 'Geofence enabled'
  },
  latitude: {
    id: `${namespace}.latitude`,
    description: 'Latitude / 0.0000001°',
    defaultMessage: 'Latitude / 0.0000001°'
  },
  longitude: {
    id: `${namespace}.longitude`,
    description: 'Longitude / 0.0000001°',
    defaultMessage: 'Longitude / 0.0000001°'
  },
  radius: {
    id: `${namespace}.radius`,
    description: 'radius',
    defaultMessage: 'Radius (m)'
  },
  geofenceLogToPortal: {
    id: `${namespace}.geofenceLogToPortal`,
    description: 'Log to portal',
    defaultMessage: 'Log to portal'
  },
  geofenceSamplePeriod: {
    id: `${namespace}.geofenceSamplePeriod`,
    description: 'Sample period',
    defaultMessage: 'Sample period (s)'
  },
  geofenceSendImmediately: {
    id: `${namespace}.geofenceSendImmediately`,
    description: 'Send immediately',
    defaultMessage: 'Send immediately'
  },
  true: {
    id: `${namespace}.true`,
    description: 'True',
    defaultMessage: 'True'
  },
  false: {
    id: `${namespace}.false`,
    description: 'False',
    defaultMessage: 'False'
  }
})

export default messages
