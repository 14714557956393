import { clone } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import DataChip from 'components/DataChip'
import MobileSignal from 'components/MobileSignal'
import SelectColumnsButton from 'components/SelectColumnsButton'
import { ReactComponent as WarningIcon } from 'icons/warning.svg'
import { utcTimeToBrowserLocalShort, utcTimeToBrowserLocal } from 'utils/timeFormat'

import Actions from './actions'
import { mapDeviceStatus } from './utils'
import messages from '../messages'

const styles = {
  root: {
    width: 340
  },
  tooltip: {
    backgroundColor: '#212121'
  },
  arrow: {
    color: '#212121'
  }
}

class DevicesTable extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      openUnregisterDialog: false,
      unregisterResponse: '',
      unregisteringProcess: '',

      page: 1,

      visibleColumns: {
        name: true,
        deviceType: true,
        eid: true,
        lastTelemetryMessage: true,
        deviceSerialNumber: false,
        simStatus: true,
        mobileSignal: false,
        lastGPSMessage: 0,
        planID: false,
        accessPassStatus: false,
        machineModel: true,
        machineType: false,
        machineSerialNumber: false
      }
    }

    this.tableRef = React.createRef()
    this.tableColumnRefs = {
      name: React.createRef(),
      deviceType: React.createRef(),
      eid: React.createRef(),
      machineModel: React.createRef(),
      machineType: React.createRef(),
      machineSerialNumber: React.createRef()
    }
    this.isAPICallsPermitted = true
  }

  componentDidMount = () => {
    const { groupId, getDevices } = this.props
    if (groupId) getDevices()
  }

  componentDidUpdate(prevProps, prevState) {
    const { groupId, getDevices } = this.props
    const { unregisteringProcess } = this.state

    if (prevProps.groupId !== groupId && groupId) {
      this.resetTableSortingAndFiltering()
      this.setState(
        {
          page: 1
        },
        () => {
          this.isAPICallsPermitted = true
          getDevices()
        }
      )
    }
    if (
      prevState.unregisteringProcess === 'unregistering' &&
      (unregisteringProcess === 'fulfilled' || unregisteringProcess === 'failed')
    ) {
      getDevices()
    }
  }

  resetTableSortingAndFiltering = () => {
    this.isAPICallsPermitted = false
    this.tableRef.current.store.sortList = []
    Object.keys(this.tableColumnRefs).forEach(key => {
      this.tableColumnRefs[key].current.cleanFiltered()
    })
  }

  handlePageChange = (page, sizePerPage) => {
    const { onPageChange } = this.props

    const pag = page > 0 ? page : 1
    this.setState(
      {
        page: pag
      },
      onPageChange((pag - 1) * sizePerPage, sizePerPage)
    )
  }

  handleFilterChange = filterObj => {
    const { onFilterChange } = this.props

    this.setState({ page: 1 }, () => {
      if (this.isAPICallsPermitted) onFilterChange(filterObj)
    })
  }

  handleSortChange = (sortName, sortOrder) => {
    const { onSortChange } = this.props

    this.setState({ page: 1 }, onSortChange(sortName, sortOrder))
  }

  handleUnregisterCS100Device = eid => {
    const { unregisterCS100Device } = this.props

    this.setState({
      unregisteringProcess: 'unregistering'
    })
    unregisterCS100Device(eid)
      .then(() => {
        this.setState({
          openUnregisterDialog: true,
          unregisteringProcess: 'fulfilled',
          unregisterResponse: 'SUCCESS' //+ (response.data && response.data.id ? response.data.id : response.data)
        })
      })
      .catch(() => {
        this.setState({
          openUnregisterDialog: true,
          unregisteringProcess: 'failed',
          unregisterResponse: 'FAILURE' //+ error.error.response.data
        })
      })
  }

  handleUnregisterDialogClose = () => {
    this.setState({
      openUnregisterDialog: false
    })
  }

  handleChangeColumnVisibility = columnId => {
    this.setState(state => {
      const updatedColumnVisibility = !state.visibleColumns[columnId]
      return {
        visibleColumns: {
          ...state.visibleColumns,
          [columnId]: updatedColumnVisibility
        }
      }
    })
  }

  showActions = () => {
    const { canUseProductionTestMode, canWriteDevices } = this.props

    if (canWriteDevices || canUseProductionTestMode) {
      return (
        <TableHeaderColumn
          dataAlign='left'
          dataField='id'
          dataFormat={this.renderActionsDetail}
          headerAlign='left'
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          width='100'
        >
          {this.formatMessage(messages.actions)}
        </TableHeaderColumn>
      )
    } else {
      return <TableHeaderColumn visible='false' width='0' />
    }
  }

  renderActionsDetail = (cell, row) => {
    const { updateDevices } = this.props
    return <Actions device={row} updateDevices={updateDevices} />
  }

  renderDateDetail = cell => utcTimeToBrowserLocalShort(cell)

  renderLastMessageDate = cell => (cell ? utcTimeToBrowserLocal(cell) : '-')

  renderSIMStatusDetail = (cell, row) => {
    let simStatusDetail = '-'
    if (row.device_type !== 'CS10') {
      if (!cell) {
        simStatusDetail = (
          <div className='sk-bounce small' style={{ marginLeft: -50 }}>
            <div className='sk-bounce-dot' />
            <div className='sk-bounce-dot' />
            <div className='sk-bounce-dot' />
          </div>
        )
      } else if (cell.toUpperCase() === 'ERROR') {
        simStatusDetail = (
          <Icon
            className='zmdi zmdi-alert-triangle'
            style={{ color: '#f0ad4e' }}
            title={this.formatMessage(messages.problemsObtainingSIMStatus)}
          />
        )
      } else simStatusDetail = mapDeviceStatus(cell)
    }
    return simStatusDetail
  }

  renderMobileSignal = cell => {
    const data = cell || {}
    return <MobileSignal data={data} />
  }

  renderLastGPSMessage = cell => {
    const { classes } = this.props
    const yesterdaySameTimeMiliseconds = moment().subtract(1, 'day').valueOf()
    if (cell) {
      if (cell < yesterdaySameTimeMiliseconds) {
        return (
          <span>
            {utcTimeToBrowserLocal(cell)}
            <Tooltip
              arrow
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              placement='right'
              title={this.formatMessage(messages.lastGPSMessageOlderThan24Hours)}
            >
              <WarningIcon style={{ marginLeft: 8 }} />
            </Tooltip>
          </span>
        )
      } else return utcTimeToBrowserLocal(cell)
    } else return '-'
  }

  renderPlanID = (cell, { accesspass }) => cell || accesspass || '-'

  renderAccessPassStatus = (cell, row) => {
    const { intl } = this.props

    let accessPassStatus = '-'
    if (row.device_type === 'CS10' && cell?.name) {
      accessPassStatus = <DataChip chipText={cell.name} inline intl={intl} />
    }
    return accessPassStatus
  }

  renderMachineField = cell => {
    return cell || '-'
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  remote(remoteObj) {
    return {
      ...remoteObj,
      pagination: true,
      sizePerPage: true,
      filter: true
    }
  }

  getDevicesWithExtraData = () => {
    const { devices, SIMStatuses } = this.props
    let devicesWithExtraData = devices
    if (SIMStatuses.length > 0) {
      devicesWithExtraData = this.getDevicesWithSIMStatus(devicesWithExtraData, SIMStatuses)
    }
    return devicesWithExtraData
  }

  getDevicesWithSIMStatus = (devices, devicesSIMStatus) =>
    devices.map(device => {
      const SIMStatus = devicesSIMStatus.find(({ deviceId }) => deviceId === device.id)
      const hasSIMStatus = SIMStatus && SIMStatus.status === 'fulfilled' && SIMStatus?.value?.data?.status?.state
      if (hasSIMStatus) {
        return {
          ...device,
          simStatus: SIMStatus.value.data.status.state
        }
      } else {
        return { ...device, simStatus: 'error' }
      }
    })

  getTableOptions = () => {
    const { sizePerPage, noDataText, onSizePerPageList } = this.props
    const { page } = this.state
    return {
      onSizePerPageList,
      sizePerPageList: [
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        }
      ],
      page,

      onPageChange: this.handlePageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,

      onFilterChange: this.handleFilterChange,
      onSortChange: this.handleSortChange,
      sizePerPage,
      noDataText
    }
  }

  render() {
    const { count, canConsumeAccessPasses, classes } = this.props
    const { openUnregisterDialog, unregisterResponse, visibleColumns } = this.state

    const deviceTypeFilterOptions = {
      CS100: 'CS100',
      CS500: 'CS500',
      CS10: 'CS10'
    }

    const filteredVisibleColumns = clone(visibleColumns)
    if (!canConsumeAccessPasses) delete filteredVisibleColumns.accessPassStatus

    return (
      <div>
        <Paper style={{ borderRadius: 0 }}>
          <Grid container style={{ padding: '20px 20px 0px' }}>
            <Grid item>
              <SelectColumnsButton
                columns={visibleColumns}
                onChangeColumnVisibility={this.handleChangeColumnVisibility}
              />
            </Grid>
          </Grid>
          <div className='table-with-pagination'>
            <BootstrapTable
              ref={this.tableRef}
              bordered={false}
              data={this.getDevicesWithExtraData()}
              exportCSV={false}
              fetchInfo={{ dataTotalSize: count }}
              options={this.getTableOptions()}
              pagination
              remote={this.remote}
            >
              <TableHeaderColumn dataField='id' hidden isKey />
              <TableHeaderColumn
                ref={this.tableColumnRefs.name}
                dataField='name'
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: this.formatMessage(messages.placeholder, { column: this.formatMessage(messages.name) })
                }}
                hidden={!visibleColumns.name}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='200'
              >
                {this.formatMessage(messages.name)}
              </TableHeaderColumn>
              <TableHeaderColumn
                ref={this.tableColumnRefs.deviceType}
                dataAlign='left'
                dataField='device_type'
                dataSort
                filter={{
                  type: 'SelectFilter',
                  delay: 400,
                  options: deviceTypeFilterOptions,
                  placeholder: this.formatMessage(messages.selectPlaceholder, {
                    column: this.formatMessage(messages.deviceType)
                  })
                }}
                headerAlign='left'
                hidden={!visibleColumns.deviceType}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.deviceType)}
              </TableHeaderColumn>
              <TableHeaderColumn
                ref={this.tableColumnRefs.eid}
                dataAlign='left'
                dataField='eid'
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: this.formatMessage(messages.placeholder, { column: this.formatMessage(messages.eid) })
                }}
                headerAlign='left'
                hidden={!visibleColumns.eid}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='120'
              >
                {this.formatMessage(messages.eid)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='last_activity_time'
                dataFormat={this.renderLastMessageDate}
                dataSort
                headerAlign='left'
                hidden={!visibleColumns.lastTelemetryMessage}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.lastTelemetryMessage)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='serial_number'
                dataSort
                headerAlign='left'
                hidden={!visibleColumns.deviceSerialNumber}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.deviceSerialNumber)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='simStatus'
                dataFormat={this.renderSIMStatusDetail}
                headerAlign='left'
                hidden={!visibleColumns.simStatus}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.simStatus)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='mobileSignal'
                dataFormat={this.renderMobileSignal}
                headerAlign='left'
                hidden={!visibleColumns.mobileSignal}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.mobileSignal)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='lastGPSTimestamp'
                dataFormat={this.renderLastGPSMessage}
                headerAlign='left'
                hidden={!visibleColumns.lastGPSMessage}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.lastGPSMessage)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='dataplan'
                dataFormat={this.renderPlanID}
                headerAlign='left'
                hidden={!visibleColumns.planID}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.planID)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='DeviceAccessPassStatus'
                dataFormat={this.renderAccessPassStatus}
                headerAlign='left'
                hidden={!canConsumeAccessPasses || !visibleColumns.accessPassStatus}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.accessPassStatus)}
              </TableHeaderColumn>
              <TableHeaderColumn
                ref={this.tableColumnRefs.machineModel}
                dataField='machine_model'
                dataFormat={this.renderMachineField}
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: this.formatMessage(messages.placeholder, { column: this.formatMessage(messages.name) })
                }}
                hidden={!visibleColumns.machineModel}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.machineModel)}
              </TableHeaderColumn>
              <TableHeaderColumn
                ref={this.tableColumnRefs.machineType}
                dataField='machine_type'
                dataFormat={this.renderMachineField}
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: this.formatMessage(messages.placeholder, { column: this.formatMessage(messages.name) })
                }}
                hidden={!visibleColumns.machineType}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.machineType)}
              </TableHeaderColumn>
              <TableHeaderColumn
                ref={this.tableColumnRefs.machineSerialNumber}
                dataField='machine_serial_number'
                dataFormat={this.renderMachineField}
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: this.formatMessage(messages.placeholder, { column: this.formatMessage(messages.name) })
                }}
                hidden={!visibleColumns.machineSerialNumber}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.machineSerialNumber)}
              </TableHeaderColumn>
              {this.showActions()}
            </BootstrapTable>
          </div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </Paper>
        <Dialog onClose={this.handleUnregisterDialogClose} open={openUnregisterDialog}>
          <DialogTitle>
            <IconButton
              onClick={this.handleUnregisterDialogClose}
              style={{
                position: 'absolute',
                right: 3,
                top: 3,
                padding: 5
              }}
            >
              <CloseIcon />
            </IconButton>
            {this.formatMessage(messages.unregisterTitle)}
          </DialogTitle>
          <DialogContent>
            <Typography color='inherit' noWrap variant='body1'>
              {unregisterResponse}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button className='cancel-button' onClick={this.handleUnregisterDialogClose}>
              {this.formatMessage(messages.cancel)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

DevicesTable.propTypes = {
  SIMStatuses: PropTypes.array.isRequired,
  canConsumeAccessPasses: PropTypes.bool.isRequired,
  canUseProductionTestMode: PropTypes.bool.isRequired,
  canWriteDevices: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  dataplans: PropTypes.array.isRequired,
  devices: PropTypes.array.isRequired,
  getDevices: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  noDataText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSizePerPageList: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  unregisterCS100Device: PropTypes.func.isRequired,
  updateDevices: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(DevicesTable))
