import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import PageTitle from 'components/PageTitle'
import { getDashboardsUrl } from 'modules/dashboards'

import SentCANHistoricTable from './SentCANHistoricTable'
import messages from '../messages'

class SentCANHistoric extends React.Component {
  getPreviousLocation = () => {
    const {
      location: { state = {} },
      match: { params = {} }
    } = this.props
    const { deviceId } = params
    const previousLocation = state?.from || getDashboardsUrl(deviceId)
    return previousLocation
  }

  render() {
    const { intl } = this.props

    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px' }}>
          <div className='container-fluid'>
            <PageTitle back={this.getPreviousLocation()} title={intl.formatMessage(messages.title)} />
          </div>
          <div className='row' id='cs' style={{ height: '100%', margin: '20px 0 0 0' }}>
            <div className='col-md-12'>
              <SentCANHistoricTable />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SentCANHistoric.propTypes = {
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withRouter(injectIntl(SentCANHistoric))
