import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import DataChip from 'components/DataChip'
import { client } from 'utils/http'
import reactBootstrapTable from 'utils/reactBootstrapTable'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import { NOTIFICATIONS_DETAILS_MODE } from '../../constants'
import messages from '../../messages'

class NotificationsEvents extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      notification: props.notification,
      notificationsEvents: [],

      count: reactBootstrapTable.count
    }
  }

  componentDidMount() {
    this.getNotificationsEvents()
  }

  getNotificationsEvents = () => {
    client
      .getNotificationsEvents(this.state.notification.hashId)
      .then(response => {
        const eventsReceived = response.data
        // Al crearse una notificación el motor de reglas la pone a OPEN y no se asigna a ningún usuario,
        // se lo asignamos al sistema
        const eventsToShow = eventsReceived.map(el => {
          if (el.userEmail !== undefined) {
            return el
          } else {
            const newElement = el
            newElement.userEmail = 'System generated'
            return newElement
          }
        })

        const eventsToShowOrdered = eventsToShow.sort((actual, before) => {
          return before.timestamp - actual.timestamp
        })

        this.setState({
          notificationsEvents: eventsToShowOrdered
        })
      })
      .catch(response => {
        this.setState({
          notificationsEvents: []
        })
      })
  }

  formatDate = (cell, row) => {
    if (row.timestamp !== undefined) {
      return <div>{utcTimeToBrowserLocal(row.timestamp)}</div>
    } else {
      return '-'
    }
  }

  formatStatus = cell => {
    let cellText

    if (cell.trim() === 'acknowledged') {
      cellText = 'ACKD'
    } else {
      cellText = cell
    }

    return <DataChip chipText={cellText} intl={this.props.intl} />
  }

  formatObservations = cell => {
    let cellWithBr = cell

    if (cell !== undefined) {
      cellWithBr = cell.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    return <div dangerouslySetInnerHTML={{ __html: cellWithBr }} />
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const { mode } = this.props
    const isMobileMode = mode === NOTIFICATIONS_DETAILS_MODE.MOBILE
    const tableOptions = {
      // No data
      noDataText: this.formatMessage(messages.noEventsAvailable),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage)
    }

    const remote = remoteObj => {
      remoteObj.search = false // eslint-disable-line no-param-reassign
      remoteObj.pagination = false // eslint-disable-line no-param-reassign
      remoteObj.sizePerPage = false // eslint-disable-line no-param-reassign
      remoteObj.sort = false // eslint-disable-line no-param-reassign
      remoteObj.filter = false // eslint-disable-line no-param-reassign

      return remoteObj
    }

    return (
      <div className='notifications-history'>
        <BootstrapTable
          ref='table'
          bordered={false}
          className='noEllipsis'
          condensed={isMobileMode}
          data={this.state.notificationsEvents}
          exportCSV={false}
          fetchInfo={{ dataTotalSize: this.state.count }}
          hover
          multiColumnSearch={false}
          options={tableOptions}
          pagination={false}
          remote={remote}
          search={false}
          searchPlaceholder={this.formatMessage(messages.filterByOrderableColumns)}
          striped={false}
        >
          <TableHeaderColumn dataField='hashId' dataFormat={this.formatDate} isKey width='180'>
            {this.formatMessage(messages.date)}
          </TableHeaderColumn>
          <TableHeaderColumn dataField='status' dataFormat={this.formatStatus} width='120'>
            {this.formatMessage(messages.status)}
          </TableHeaderColumn>
          <TableHeaderColumn dataField='userEmail' {...(isMobileMode && { width: '300' })}>
            {this.formatMessage(messages.user)}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='observations'
            dataFormat={this.formatObservations}
            {...(isMobileMode && { width: '300' })}
          >
            {this.formatMessage(messages.observations)}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    )
  }
}

NotificationsEvents.propTypes = {
  intl: PropTypes.object.isRequired,
  mode: PropTypes.string,
  notification: PropTypes.object.isRequired
}

NotificationsEvents.defaultProps = {
  mode: NOTIFICATIONS_DETAILS_MODE.PC
}

export default NotificationsEvents
