import { defineMessages } from 'react-intl'

const namespace = 'DM1TableSettings'

const messages = defineMessages({
  configurationStatus: {
    id: `${namespace}.configurationStatus`,
    description: 'Configuration status',
    defaultMessage: 'Configuration status'
  },
  configurationAvailable: {
    id: `${namespace}.configurationAvailable`,
    description: 'Available',
    defaultMessage: 'Available'
  },
  configurationNotAvailable: {
    id: `${namespace}.configurationNotAvailable`,
    description: 'Not available',
    defaultMessage: 'Not available'
  },
  configurationLoading: {
    id: `${namespace}.configurationLoading`,
    description: 'Loading',
    defaultMessage: 'Loading'
  },
  columns: {
    id: `${namespace}.columns`,
    description: 'Columns',
    defaultMessage: 'Columns'
  },
  machines: {
    id: `${namespace}.machines`,
    description: 'Machines',
    defaultMessage: 'Machines'
  },
  allMachines: {
    id: `${namespace}.allMachines`,
    description: 'All machines',
    defaultMessage: 'All machines'
  },
  allMachinesSelected: {
    id: `${namespace}.allMachinesSelected`,
    description: 'All machines selected',
    defaultMessage: 'All machines selected*'
  },
  machinesHelperText: {
    id: `${namespace}.machinesHelperText`,
    description: 'Machines selector helper text',
    defaultMessage: '*CS500 machines added in the future will also appear in the table'
  },
  allColumns: {
    id: `${namespace}.allColumns`,
    description: 'All columns checkbox label',
    defaultMessage: 'All columns'
  },
  dm1Fields: {
    id: `${namespace}.dm1Fields`,
    description: 'DM1',
    defaultMessage: 'DM1'
  },
  machineFields: {
    id: `${namespace}.machineFields`,
    description: 'Machine',
    defaultMessage: 'Machine'
  }
})

export default messages
