import { defineMessages } from 'react-intl'

const messages = defineMessages({
  helperText: {
    id: 'PictureEditor.helperText',
    description: 'Helper text',
    defaultMessage: 'Invalid image'
  },
  preview: {
    id: 'PictureEditor.preview',
    description: 'Preview alt text',
    defaultMessage: 'Preview'
  },
  browseImage: {
    id: 'PictureEditor.browseImage',
    description: 'Browse image button label',
    defaultMessage: 'Browse image'
  },
  invalidFileType: {
    id: 'PictureEditor.invalidFileType',
    description: 'Invalid file type',
    defaultMessage: 'Invalid file type'
  },
  invalidFileExtension: {
    id: 'PictureEditor.invalidFileExtension',
    description: 'Invalid file extension',
    defaultMessage: 'Invalid file extension'
  }
})

export default messages
