import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import PageTitle from 'components/PageTitle'

import NotificationsContent from './NotificationsContent'
import messages from '../messages'

class Notifications extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      secondaryGlobalQuery: props.secondaryGlobalQuery,
      notificationsTotalsReceivedData: props.notificationsTotalsReceivedData
    }
  }

  // This method is invoked right before calling the render method,
  // both on the initial mount and on subsequent updates. It should return
  // an object to update the state, or null to update nothing.
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.secondaryGlobalQuery !== prevState.secondaryGlobalQuery) {
      return {
        secondaryGlobalQuery: nextProps.secondaryGlobalQuery
      }
    } else if (nextProps.notificationsTotalsReceivedData !== prevState.notificationsTotalsReceivedData) {
      return {
        notificationsTotalsReceivedData: nextProps.notificationsTotalsReceivedData
      }
    }

    return null
  }

  render() {
    const { currentGroupId, privileges } = this.props
    const { notificationsTotalsReceivedData, secondaryGlobalQuery } = this.state

    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <PageTitle title={this.formatMessage(messages.title)} />
          </div>
          <div className='row' style={{ margin: '20px 0 0 0' }}>
            <div className='col-md-12'>
              <NotificationsContent
                currentGroupId={currentGroupId}
                notificationsTotalsData={notificationsTotalsReceivedData}
                privileges={privileges}
                secondaryGlobalQuery={secondaryGlobalQuery}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Notifications.propTypes = {
  currentGroupId: PropTypes.string,
  intl: PropTypes.string.isRequired,
  notificationsTotalsReceivedData: PropTypes.object,
  privileges: PropTypes.object.isRequired,
  secondaryGlobalQuery: PropTypes.string.isRequired
}

Notifications.defaultProps = {
  currentGroupId: '',
  notificationsTotalsReceivedData: null
}

export default injectIntl(Notifications)
