import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import MuiAlert from '@material-ui/lab/Alert'

import Alert from 'components/Alert'
import Loading from 'components/Loading'
import { adapter } from 'utils/cs500'
import download from 'utils/fileHandler/download'
import { logError } from 'utils/http'

import ApplyConfigurationDialog from './ApplyConfigurationDialog'
import EditConfigurationForm from './EditConfigurationForm'
import EditConfigurationFormTitle from './EditConfigurationFormTitle'
import messages from './messages'
import {
  applyConfiguration,
  downloadConfiguration,
  getConfiguration,
  getDevice,
  handleApplyConfigurationButtonClick
} from './utils'
import {
  getSignalsWithAdaptedParameters,
  transformValuesForAPI,
  transformVirtualParametersValuesForAPI,
  validationSchema
} from '../config'

class EditConfiguration extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props

    this.state = {
      alertMessages: false,
      alertMessagesText: [''],
      alertMessagesTitle: '',
      alertMessagesType: 'danger',
      configToApply: {},
      deviceName: props.device ? props.device.name : props.deviceEid,
      isApplyConfigurationDialogButtonVisible: true,
      isConfigLoading: true,
      isDialogOpen: false
    }

    this.formatMessage = formatMessage
  }

  componentDidMount = () => {
    const { device, deviceEid, getDeviceByEid, getDeviceNonVolatileConfiguration, groupId, setLocalConfiguration } =
      this.props

    if (!device) getDevice(deviceEid, getDeviceByEid, groupId, this.handleSetState)
    if (groupId)
      getConfiguration(
        adapter,
        deviceEid,
        this.formatMessage,
        getDeviceNonVolatileConfiguration,
        groupId,
        setLocalConfiguration,
        this.handleSetState
      )
  }

  componentDidUpdate = prevProps => {
    const { device, deviceEid, getDeviceByEid, getDeviceNonVolatileConfiguration, groupId, setLocalConfiguration } =
      this.props

    if (device && !isEqual(prevProps.device, device)) {
      getDevice(deviceEid, getDeviceByEid, groupId, this.handleSetState)
    }
    if (prevProps.groupId !== groupId && groupId) {
      getConfiguration(
        adapter,
        deviceEid,
        this.formatMessage,
        getDeviceNonVolatileConfiguration,
        groupId,
        setLocalConfiguration,
        this.handleSetState
      )
    }
  }

  componentWillUnmount() {
    const { history, location, resetLocalConfiguration } = this.props

    if (history.location.pathname !== location.pathname) {
      resetLocalConfiguration()
    }
  }

  handleSetState = ({ ...newParameters }) => {
    this.setState(state => ({
      ...state,
      ...newParameters
    }))
  }

  handleDialogClose = reason => {
    const { onEditConfiguration } = this.props
    const { alertMessagesType, alertMessagesText } = this.state
    const notRedirectToConfigurationsTable =
      alertMessagesType === 'danger' || alertMessagesType === 'warning' && alertMessagesText.length > 0
    if (reason === 'backdropClick') {
      return false
    } else {
      this.setState(
        {
          alertMessages: false,
          alertMessagesText: [''],
          alertMessagesTitle: '',
          alertMessagesType: '',
          isDialogOpen: false
        },
        () => !notRedirectToConfigurationsTable && onEditConfiguration()
      )
    }
  }

  render() {
    const {
      addLocalConfigurationCANMessage,
      addLocalConfigurationSignal,
      canEditDevice,
      configState,
      configurationsUrl,
      deviceEid,
      groupId,
      isSidebarCollapsed,
      localConfigurationError,
      setDeviceNonVolatileConfiguration
    } = this.props
    const {
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      configToApply,
      deviceName,
      isApplyConfigurationDialogButtonVisible,
      isConfigLoading,
      isDialogOpen
    } = this.state

    //console.log('configState.configState.signals[3]: ', configState?.signals[3]?.overflowErrorMessage)

    return (
      <div
        className='content-container'
        id='editConfigurationContent'
        style={{ position: 'relative', overflowX: 'hidden', marginTop: '0px' }}
      >
        <div
          className='col-xs-12'
          style={{
            backgroundColor: '#efefef',
            padding: isSidebarCollapsed ? '20px 35px 20px 35px' : '20px 35px 20px 35px'
          }}
        >
          <div className='col-xs-9' style={{ height: '100%', padding: 0 }}>
            <EditConfigurationFormTitle configurationsUrl={configurationsUrl} deviceName={deviceName} />
          </div>
          <div className='col-xs-3' style={{ textAlign: 'end', padding: 0, height: '100%', marginTop: 20 }}>
            <Button
              className='secondary-action-button'
              disabled={isConfigLoading || alertMessages || groupId === ''}
              onClick={() =>
                downloadConfiguration(
                  configState,
                  deviceEid,
                  deviceName,
                  download,
                  this.formatMessage,
                  getSignalsWithAdaptedParameters,
                  localConfigurationError,
                  this.handleSetState,
                  transformValuesForAPI,
                  transformVirtualParametersValuesForAPI,
                  validationSchema
                )
              }
              style={{ minWidth: 100, marginRight: 10 }}
            >
              {this.formatMessage(messages.download)}
            </Button>
            {canEditDevice && (
              <Button
                className='primary-action-button'
                disabled={isConfigLoading || alertMessages || groupId === ''}
                onClick={() =>
                  handleApplyConfigurationButtonClick(
                    configState,
                    deviceEid,
                    deviceName,
                    this.formatMessage,
                    getSignalsWithAdaptedParameters,
                    localConfigurationError,
                    this.handleSetState,
                    transformValuesForAPI,
                    transformVirtualParametersValuesForAPI,
                    validationSchema
                  )
                }
                style={{ minWidth: 100 }}
              >
                {this.formatMessage(messages.apply)}
              </Button>
            )}
          </div>
        </div>
        <div style={{ margin: '20px', paddingTop: '70px' }}>
          {isConfigLoading && (
            <div className='container-fluid' style={{ marginTop: 20 }}>
              <Loading />
            </div>
          )}
          {alertMessages && (
            <div className='container-fluid' style={{ marginTop: 20 }}>
              <Alert
                alertType={alertMessagesType}
                messageText={alertMessagesText}
                messageTitle={alertMessagesTitle}
                showAlert={alertMessages}
              />
            </div>
          )}
          {!isConfigLoading && !alertMessages && (
            <React.Fragment>
              <div className='col-xs-12' style={{ margin: '0px 55px 10px 0px' }}>
                <MuiAlert severity='info'>
                  {this.formatMessage(messages.firmwareVersionAlert, {
                    firmware: <b>{this.formatMessage(messages.firmware)}</b>,
                    version: <b>{configState.applicationVersion || '-'}</b>
                  })}
                </MuiAlert>
              </div>
              <EditConfigurationForm
                addLocalConfigurationCANMessage={addLocalConfigurationCANMessage}
                addLocalConfigurationSignal={addLocalConfigurationSignal}
                configState={configState}
              />
            </React.Fragment>
          )}
        </div>
        <ApplyConfigurationDialog
          alertMessagesText={alertMessagesText}
          alertMessagesTitle={alertMessagesTitle}
          alertMessagesType={alertMessagesType}
          applyConfiguration={() =>
            applyConfiguration(
              configToApply,
              deviceEid,
              deviceName,
              this.formatMessage,
              groupId,
              setDeviceNonVolatileConfiguration,
              this.handleSetState
            )
          }
          canEditDevice={canEditDevice}
          deviceEid={deviceEid}
          isApplyConfigurationDialogButtonVisible={isApplyConfigurationDialogButtonVisible}
          isDialogOpen={isDialogOpen}
          onDialogClose={this.handleDialogClose}
        />
      </div>
    )
  }
}

EditConfiguration.propTypes = {
  addLocalConfigurationCANMessage: PropTypes.func.isRequired,
  addLocalConfigurationSignal: PropTypes.func.isRequired,
  canEditDevice: PropTypes.bool.isRequired,
  configState: PropTypes.object.isRequired,
  configurationsUrl: PropTypes.string.isRequired,
  device: PropTypes.object,
  deviceEid: PropTypes.string.isRequired,
  getDeviceByEid: PropTypes.func.isRequired,
  getDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  getNewestNVConfigurationDefinition: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isSidebarCollapsed: PropTypes.bool.isRequired,
  localConfigurationError: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  onEditConfiguration: PropTypes.func.isRequired,
  resetLocalConfiguration: PropTypes.func.isRequired,
  setDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  setLocalConfiguration: PropTypes.func.isRequired
}

EditConfiguration.defaultProps = {
  device: null
}

export default injectIntl(EditConfiguration)
