import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'

import DateTimeRangeSelector from 'components/DateTimeRangeSelector'
import { adapter } from 'utils/cs500'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import messages from '../../messages'

const styles = {
  chipRoot: {
    height: 'fit-content',
    fontSize: '14px',
    color: '#FFFFFF'
  },
  chipSuccess: {
    backgroundColor: '#009541'
  },
  chipDanger: {
    backgroundColor: '#D9534F'
  },
  inputRoot: {
    color: 'rgba(0, 0, 0, 0.87) !important'
  },
  inputLabelRoot: {
    color: 'rgba(0, 0, 0, 0.54) !important'
  },
  dateTimePickerContainer: {
    marginBottom: 5,
    marginLeft: -2,
    display: 'flex',
    alignItems: 'center'
  }
}

class SentCANHistoricTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      CANmessages: [],

      total: 0,
      start: 0,
      tableOptions: {
        noDataText: <CircularProgress />,
        page: 1,
        sizePerPage: 10,
        sortName: 'createdAt',
        sortOrder: 'desc'
      },
      eid: '',
      filter: {},

      messageDetails: '',
      messageSettings: '',
      isMessageSettingsLoading: false,

      fromDateTime: moment().subtract(30, 'days').add(1, 'minute').valueOf(),
      toDateTime: moment().valueOf()
    }

    this.tableRef = React.createRef()
  }

  componentDidMount() {
    this.getDeviceData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { eid } = this.state
    if (prevState.eid === '' && eid !== '') {
      this.getSentCANHistoricalMessages()
    }
  }

  getDeviceData = () => {
    const { getCSNode, deviceId, groupId, intl } = this.props
    getCSNode(deviceId, groupId)
      .then(response => {
        const { EID: eid } = response.data
        this.setState({ eid })
      })
      .catch(error => {
        console.error(error)
        this.setState(({ tableOptions }) => ({
          tableOptions: { ...tableOptions, noDataText: intl.formatMessage(messages.errorRetrievingHistoricalData) }
        }))
      })
  }

  getSentCANHistoricalMessages = () => {
    const { getSentCANHistoricMessages, groupId, intl } = this.props
    const {
      eid,
      filter,
      start,
      tableOptions: { sizePerPage, sortOrder },
      fromDateTime,
      toDateTime
    } = this.state
    if (eid !== '') {
      this.setState(({ tableOptions }) => ({
        tableOptions: { ...tableOptions, noDataText: <CircularProgress /> },
        CANmessages: []
      }))

      const timeFrom = moment.utc(fromDateTime).format('HH:mm:ss')
      const timeTo = moment.utc(toDateTime).format('HH:mm:ss')
      const dateFrom = moment.utc(fromDateTime).format('YYYY-MM-DD')
      const dateTo = moment.utc(toDateTime).format('YYYY-MM-DD')

      const queryParams = {
        eid,
        groupId,
        offset: start,
        limit: sizePerPage,
        orderBy: sortOrder.toUpperCase(),
        timeFrom,
        timeTo,
        dateFrom,
        dateTo,
        ...filter
      }
      getSentCANHistoricMessages(queryParams)
        .then(response => {
          const { data } = response
          this.setState(({ tableOptions }) => ({
            tableOptions: { ...tableOptions, noDataText: intl.formatMessage(messages.noData) },
            total: data.total,
            CANmessages: data.dataRows.map((dataRow, index) => ({ ...dataRow, id: index }))
          }))
        })
        .catch(error => {
          this.setState(({ tableOptions }) => ({
            tableOptions: { ...tableOptions, noDataText: intl.formatMessage(messages.errorRetrievingHistoricalData) }
          }))
        })
    }
  }

  getTableOptions = () => {
    const { intl } = this.props
    const { tableOptions } = this.state
    return {
      onSizePerPageList: this.handleSizePerPageList,
      sizePerPageList: [
        {
          text: '10',
          value: 10
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        },
        {
          text: '200',
          value: 200
        }
      ],

      onPageChange: this.handlePageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: intl.formatMessage(messages.prePage),
      nextPage: intl.formatMessage(messages.nextPage),
      firstPage: intl.formatMessage(messages.firstPage),
      lastPage: intl.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(intl.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,
      onFilterChange: this.handleFilterChange,
      onSortChange: this.handleSortChange,
      exportCSVBtn: () => <button style={{ display: 'none' }} />,
      sortName: 'createdAt',
      sortOrder: 'desc',
      ...tableOptions
    }
  }

  handlePageChange = (page, sizePerPage) => {
    this.setState(
      state => ({
        start: (page - 1) * sizePerPage,
        tableOptions: {
          ...state.tableOptions,
          page,
          sizePerPage
        }
      }),
      this.getSentCANHistoricalMessages
    )
  }

  handleSizePerPageList = sizePerPage => {
    this.setState(
      state => ({
        tableOptions: { ...state.tableOptions, sizePerPage }
      }),
      this.getSentCANHistoricalMessages
    )
  }

  handleFilterChange = filterObj => {
    const filter = Object.entries(filterObj).reduce(
      (acc, [key, fieldFilter]) => ({
        ...acc,
        [key]: fieldFilter.value
      }),
      {}
    )
    this.setState({ filter }, this.getSentCANHistoricalMessages)
  }

  handleSortChange = (sortName, sortOrder) => {
    this.setState(
      ({ tableOptions }) => ({ tableOptions: { ...tableOptions, sortName, sortOrder } }),
      this.getSentCANHistoricalMessages
    )
  }

  handleCANMessageDetailClick = row => {
    const { fetchDeviceNonVolatileConfigurationByHash } = this.props
    this.setState({ messageDetails: row, isMessageSettingsLoading: true })
    const { groupId, configHash, EID, messageId } = row
    fetchDeviceNonVolatileConfigurationByHash(groupId, EID, configHash)
      .then(response => {
        const configuration = adapter.cs500ConfigToCs100Config(response[EID].originalConfiguration)
        const canMessageId = parseInt(messageId.at(-1)) - 1
        const messageSettings = configuration.sendCANMessages.find(message => message.canMessageId === canMessageId)
        if (messageSettings) {
          this.setState({ messageSettings })
        }
      })
      .catch()
      .finally(() => {
        this.setState({ isMessageSettingsLoading: false })
      })
    setTimeout(() => {
      this.setState({ isMessageSettingsLoading: false })
    }, 1000)
  }

  handleCsvDownloadClick = () => {
    this.tableRef.current.handleExportCSV()
  }

  handleCloseMessageDetails = () => {
    this.setState({ messageDetails: '' })
  }

  handleSearchRange = (min, max) => {
    this.setState(
      ({ tableOptions }) => ({
        CANmessages: [],
        start: 0,
        tableOptions: {
          ...tableOptions,
          noDataText: <CircularProgress />,
          page: 1
        },
        fromDateTime: min,
        toDateTime: max
      }),
      this.getSentCANHistoricalMessages
    )
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  formatDate = date => {
    return utcTimeToBrowserLocal(date)
  }

  formatStatus = status => {
    const { intl, classes } = this.props

    if (status.toUpperCase() === 'DELIVERED') {
      return (
        <Chip
          classes={{ root: classes.chipRoot + ' ' + classes.chipSuccess }}
          label={intl.formatMessage(messages.delivered)}
        />
      )
    } else {
      return (
        <Chip
          classes={{ root: classes.chipRoot + ' ' + classes.chipDanger }}
          label={intl.formatMessage(messages.failed)}
        />
      )
    }
  }

  formatUser = email => {
    return (
      <Typography style={{ fontSize: 14 }} title={email}>
        {email}
      </Typography>
    )
  }

  formatActions = (cell, row) => {
    return (
      <IconButton
        onClick={() => {
          this.handleCANMessageDetailClick(row)
        }}
        style={{ padding: '5px' }}
      >
        <SearchIcon />
      </IconButton>
    )
  }

  formatDataSource = dataSource => {
    const { intl } = this.props
    let formattedDataSource = '-'
    if (dataSource === 255) formattedDataSource = intl.formatMessage(messages.setBytesDirectly)
    else if (dataSource === 240) formattedDataSource = intl.formatMessage(messages.GNSSPosition)
    else if (dataSource >= 0 && dataSource <= 79) formattedDataSource = intl.formatMessage(messages.selectVirtualSignal)
    return formattedDataSource
  }

  render() {
    const {
      intl,
      classes,
      location: { state = {} }
    } = this.props
    const { CANmessages, total, messageDetails, isMessageSettingsLoading, messageSettings, fromDateTime, toDateTime } =
      this.state

    const messageIDFilterOptions = {
      SendCANmsg1: intl.formatMessage(messages.message, { number: 1 }),
      SendCANmsg2: intl.formatMessage(messages.message, { number: 2 }),
      SendCANmsg3: intl.formatMessage(messages.message, { number: 3 }),
      SendCANmsg4: intl.formatMessage(messages.message, { number: 4 })
    }

    const statusFilterOptions = {
      Delivered: intl.formatMessage(messages.delivered),
      Failed: intl.formatMessage(messages.failed)
    }

    const lowestPossibleDate = moment().subtract(30, 'days').add(1, 'minute').valueOf()
    const numOfBytes = messageSettings?.dataLength || 8

    return (
      <React.Fragment>
        <Paper style={{ borderRadius: 0 }}>
          <Grid container>
            <Grid container item style={{ padding: '20px 20px 0px' }}>
              <Grid container item justifyContent='flex-start' md={6} style={{ alignSelf: 'flex-start' }} xs={12}>
                <DateTimeRangeSelector
                  customGridContainerClass={classes.dateTimePickerContainer}
                  lowestPossibleDate={lowestPossibleDate}
                  maxDate={toDateTime}
                  minDate={fromDateTime}
                  onSearch={this.handleSearchRange}
                />
              </Grid>
              <Grid alignItems='center' container item justifyContent='flex-end' md={6} xs={12}>
                <Button
                  className='primary-action-button'
                  onClick={this.handleCsvDownloadClick}
                  startIcon={<i className='zmdi zmdi-download' style={{ fontSize: 16, paddingLeft: 4 }} />}
                  style={{ marginRight: 8 }}
                >
                  {intl.formatMessage(messages.downloadCSV)}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <div className='table-with-pagination' style={{ paddingBottom: 110, marginTop: -5 }}>
            <BootstrapTable
              ref={this.tableRef}
              bordered={false}
              condensed={false}
              csvFileName='SentCANHistoric.csv'
              data={CANmessages}
              exportCSV
              fetchInfo={{ dataTotalSize: total }}
              hover
              keyField='id'
              multiColumnSearch={false}
              options={this.getTableOptions()}
              pagination
              remote={remoteObj => ({
                ...remoteObj,
                search: false,
                sizePerPage: true,
                filter: true,
                pagination: true,
                sort: true
              })}
              search={false}
              striped={false}
            >
              <TableHeaderColumn dataField='id' hidden />
              <TableHeaderColumn
                dataField='messageId'
                filter={{
                  type: 'SelectFilter',
                  options: messageIDFilterOptions,
                  ...state?.messageId && { defaultValue: state?.messageId }
                }}
                width='150'
              >
                {intl.formatMessage(messages.messageId)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='createdAt' dataFormat={this.formatDate} dataSort width='150'>
                {intl.formatMessage(messages.date)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='status'
                dataFormat={this.formatStatus}
                filter={{ type: 'SelectFilter', options: statusFilterOptions }}
                width='150'
              >
                {intl.formatMessage(messages.status)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='email' dataFormat={this.formatUser} width='300'>
                {intl.formatMessage(messages.user)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='id' dataFormat={this.formatActions} width='80'>
                {intl.formatMessage(messages.actions)}
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </Paper>
        {messageDetails !== '' && (
          <Dialog
            aria-describedby='alert-dialog-slide-description'
            aria-labelledby='alert-dialog-slide-title'
            fullWidth
            keepMounted
            maxWidth='md'
            open={messageDetails !== ''}
            scroll='paper'
          >
            <DialogTitle>
              {intl.formatMessage(messages.messageDetails)}
              <IconButton
                onClick={this.handleCloseMessageDetails}
                style={{
                  position: 'absolute',
                  right: 3,
                  top: 3
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {isMessageSettingsLoading ? (
                <Grid container item justifyContent='center' style={{ marginBottom: 40 }} xs={12}>
                  <CircularProgress />
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid container style={{ marginBottom: 40 }}>
                    <Grid item md={3} xs={6}>
                      <label style={{ marginRight: 20 }}>{intl.formatMessage(messages.messageId)}</label>
                      {messageDetails.messageId}
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <label style={{ marginRight: 20 }}>{intl.formatMessage(messages.date)}</label>
                      {this.formatDate(messageDetails.createdAt)}
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <label style={{ marginRight: 20 }}>{intl.formatMessage(messages.user)}</label>
                      {messageDetails.email}
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <label style={{ marginRight: 20 }}>{intl.formatMessage(messages.status)}</label>
                      {this.formatStatus(messageDetails.status)}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: messageSettings.dataSource === 255 ? 0 : 10 }}>
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: 18 }}>{intl.formatMessage(messages.messageSettings)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider light />
                    </Grid>
                    <Grid container item justifyContent='space-between' xs={12}>
                      <Grid item>
                        <label style={{ marginRight: 20 }}>{intl.formatMessage(messages.CANPort)}</label>
                        {messageSettings.canPort}
                      </Grid>
                      <Grid itemType=''>
                        <label style={{ marginRight: 20 }}>{intl.formatMessage(messages.CANIdType)}</label>
                        {messageSettings.isExtendedCanId
                          ? intl.formatMessage(messages.extendedCANId)
                          : intl.formatMessage(messages.standard)}
                      </Grid>
                      <Grid item>
                        <label style={{ marginRight: 20 }}>{intl.formatMessage(messages.CANId)}</label>
                        {messageSettings.canId}
                      </Grid>
                      <Grid item>
                        <label style={{ marginRight: 20 }}>{intl.formatMessage(messages.dataSource)}</label>
                        {this.formatDataSource(messageSettings.dataSource)}
                      </Grid>
                      {messageSettings.dataSource >= 0 && messageSettings.dataSource <= 79 && (
                        <Grid item>
                          <label style={{ marginRight: 20 }}>{intl.formatMessage(messages.virtualSignalNumber)}</label>
                          {messageSettings.virtualSignalNumber}
                        </Grid>
                      )}
                      {messageSettings.dataSource === 255 && (
                        <Grid item>
                          <label style={{ marginRight: 20 }}>{intl.formatMessage(messages.dataLength)}</label>
                          {messageSettings.dataLength || 8}
                        </Grid>
                      )}
                    </Grid>
                    {messageSettings.dataSource === 255 && (
                      <React.Fragment>
                        <Grid item xs={12}>
                          <Divider light />
                        </Grid>
                        <Grid container item style={{ marginBottom: 20 }} xs={12}>
                          <Grid container item md={3} xs={6}>
                            {numOfBytes > 0 && (
                              <Grid item xs={6}>
                                <TextField
                                  InputLabelProps={{ classes: { root: classes.inputLabelRoot } }}
                                  InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot } }}
                                  disabled
                                  label={intl.formatMessage(messages.byte, { number: 0 })}
                                  value={'0x' + messageSettings.byte0}
                                />
                              </Grid>
                            )}
                            {numOfBytes > 1 && (
                              <Grid item xs={6}>
                                <TextField
                                  InputLabelProps={{ classes: { root: classes.inputLabelRoot } }}
                                  InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot } }}
                                  disabled
                                  label={intl.formatMessage(messages.byte, { number: 1 })}
                                  value={'0x' + messageSettings.byte1}
                                />
                              </Grid>
                            )}
                          </Grid>
                          <Grid container item md={3} xs={6}>
                            {numOfBytes > 2 && (
                              <Grid item xs={6}>
                                <TextField
                                  InputLabelProps={{ classes: { root: classes.inputLabelRoot } }}
                                  InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot } }}
                                  disabled
                                  label={intl.formatMessage(messages.byte, { number: 2 })}
                                  value={'0x' + messageSettings.byte2}
                                />
                              </Grid>
                            )}
                            {numOfBytes > 3 && (
                              <Grid item xs={6}>
                                <TextField
                                  InputLabelProps={{ classes: { root: classes.inputLabelRoot } }}
                                  InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot } }}
                                  disabled
                                  label={intl.formatMessage(messages.byte, { number: 3 })}
                                  value={'0x' + messageSettings.byte3}
                                />
                              </Grid>
                            )}
                          </Grid>
                          <Grid container item md={3} xs={6}>
                            {numOfBytes > 4 && (
                              <Grid item xs={6}>
                                <TextField
                                  InputLabelProps={{ classes: { root: classes.inputLabelRoot } }}
                                  InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot } }}
                                  disabled
                                  label={intl.formatMessage(messages.byte, { number: 4 })}
                                  value={'0x' + messageSettings.byte4}
                                />
                              </Grid>
                            )}
                            {numOfBytes > 5 && (
                              <Grid item xs={6}>
                                <TextField
                                  InputLabelProps={{ classes: { root: classes.inputLabelRoot } }}
                                  InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot } }}
                                  disabled
                                  label={intl.formatMessage(messages.byte, { number: 5 })}
                                  value={'0x' + messageSettings.byte5}
                                />
                              </Grid>
                            )}
                          </Grid>
                          <Grid container item md={3} xs={6}>
                            {numOfBytes > 6 && (
                              <Grid item xs={6}>
                                <TextField
                                  InputLabelProps={{ classes: { root: classes.inputLabelRoot } }}
                                  InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot } }}
                                  disabled
                                  label={intl.formatMessage(messages.byte, { number: 6 })}
                                  value={'0x' + messageSettings.byte6}
                                />
                              </Grid>
                            )}
                            {numOfBytes > 7 && (
                              <Grid item xs={6}>
                                <TextField
                                  InputLabelProps={{ classes: { root: classes.inputLabelRoot } }}
                                  InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot } }}
                                  disabled
                                  label={intl.formatMessage(messages.byte, { number: 7 })}
                                  value={'0x' + messageSettings.byte7}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                </React.Fragment>
              )}
            </DialogContent>
          </Dialog>
        )}
      </React.Fragment>
    )
  }
}

SentCANHistoricTable.propTypes = {
  classes: PropTypes.object.isRequired,
  deviceId: PropTypes.string.isRequired,
  fetchDeviceNonVolatileConfigurationByHash: PropTypes.func.isRequired,
  getCSNode: PropTypes.func.isRequired,
  getSentCANHistoricMessages: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(injectIntl(SentCANHistoricTable)))
