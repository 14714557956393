export const paperStyles = {
  paperRoot: {
    borderRadius: 0,
    padding: 20
  }
}

export const dialogStyles = {
  dialogTitle: {
    padding: '0px 8px',
    marginBottom: '16px'
  },
  dialogContent: {
    padding: '8px'
  },
  dialogContentGridContainer: {
    padding: '8px 0px',
    width: 'calc(100% - 8px)'
  }
}

export const iconButtonStyles = theme => ({
  iconButtonRoot: {
    padding: '5px'
  },
  button: {
    margin: 0,
    padding: 5,
    marginTop: -2
  },
  expand: {
    transform: 'rotate(0deg)'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }
})

export const notificationDetailsStyles = {
  header: {
    fontSize: 18,
    color: '#303030'
  }
}

export const alertStyles = {
  alertTitle: {
    color: 'inherit',
    fontSize: '16px'
  },
  alertMessage: {
    width: '100%'
  },
  alertLink: {
    color: 'inherit',
    textDecoration: 'underline',
    '&:hover': {
      color: 'inherit',
      textDecoration: 'none'
    }
  }
}
