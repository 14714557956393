import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'

import DropdownMenu from 'components/DropdownMenu'

import messages from './messages'
import LinkedMenuItem from '../LinkedMenuItem'

const styles = {
  menuSubheader: {
    height: '34px',
    padding: '11px 16px 0px 16px',
    gap: '8px',
    fontSize: '16x',
    fontWeight: 400,
    textAlign: 'start',
    color: '#686868'
  }
}

const SettingsDropdown = ({
  usersUrl,
  rolesUrl,
  groupsUrl,
  notificationsUrl,
  canReadUser = false,
  canReadGroup = false,
  canReadNotification = false,
  canReadRole = false,
  intl,
  classes,
  ...other
}) => {
  const { formatMessage } = intl
  return (
    <div
      className='no-notifications-tab'
      style={{
        position: 'relative',
        height: 50,
        width: 64
      }}
    >
      <DropdownMenu
        icon={
          <Icon
            className='fas fa-user-cog'
            style={{ padding: 0, margin: 0, textAlign: 'center', fontSize: '20px', width: '1.3em', lineHeight: 1.1 }}
          />
        }
        subheader={<div className={classes.menuSubheader}>{formatMessage(messages.management)}</div>}
        {...other}
      >
        {canReadUser && (
          <LinkedMenuItem
            icon={<Icon className='zmdi zmdi-account-o' />}
            label={formatMessage(messages.users)}
            to={usersUrl}
          />
        )}
        {canReadRole && (
          <LinkedMenuItem
            icon={<Icon className='zmdi zmdi-lock-outline' />}
            label={formatMessage(messages.roles)}
            to={rolesUrl}
          />
        )}
        {canReadGroup && (
          <LinkedMenuItem
            icon={<Icon className='zmdi zmdi-accounts-outline' />}
            label={formatMessage(messages.groups)}
            to={groupsUrl}
          />
        )}
        {canReadNotification && (
          <LinkedMenuItem
            icon={<Icon className='zmdi zmdi zmdi-notifications-none' />}
            label={formatMessage(messages.notifications)}
            to={notificationsUrl}
          />
        )}
      </DropdownMenu>
    </div>
  )
}

SettingsDropdown.propTypes = {
  canReadGroup: PropTypes.bool.isRequired,
  canReadNotification: PropTypes.bool.isRequired,
  canReadRole: PropTypes.bool.isRequired,
  canReadUser: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  groupsUrl: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  notificationsUrl: PropTypes.string.isRequired,
  rolesUrl: PropTypes.string.isRequired,
  usersUrl: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(SettingsDropdown))
