import { defineMessages } from 'react-intl'

const namespace = 'SentCANHistoric'

export default defineMessages({
  title: {
    id: `${namespace}.title`,
    defaultMessage: 'Sent CAN message history'
  },
  prePage: {
    id: `${namespace}.prePage`,
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    defaultMessage: 'Last'
  },
  showingRows: {
    id: `${namespace}.showingRows`,
    defaultMessage: 'Showing rows'
  },
  to: {
    id: `${namespace}.to`,
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    defaultMessage: 'of'
  },
  messageId: {
    id: `${namespace}.messageId`,
    defaultMessage: 'Message ID'
  },
  date: {
    id: `${namespace}.date`,
    defaultMessage: 'Date'
  },
  status: {
    id: `${namespace}.status`,
    defaultMessage: 'Status'
  },
  user: {
    id: `${namespace}.user`,
    defaultMessage: 'User'
  },
  actions: {
    id: `${namespace}.actions`,
    defaultMessage: 'Actions'
  },
  errorRetrievingHistoricalData: {
    id: `${namespace}.errorRetrievingHistoricalData`,
    defaultMessage: 'Error retrieving historical data'
  },
  message: {
    id: `${namespace}.message`,
    defaultMessage: 'Message {number}'
  },
  delivered: {
    id: `${namespace}.delivered`,
    defaultMessage: 'Delivered'
  },
  failed: {
    id: `${namespace}.failed`,
    defaultMessage: 'Failed'
  },
  downloadCSV: {
    id: `${namespace}.downloadCSV`,
    defaultMessage: 'Download CSV'
  },
  messageDetails: {
    id: `${namespace}.messageDetails`,
    defaultMessage: 'MESSAGE DETAILS'
  },
  messageSettings: {
    id: `${namespace}.messageSettings`,
    defaultMessage: 'Message settings'
  },
  CANPort: {
    id: `${namespace}.CANPort`,
    defaultMessage: 'CAN port'
  },
  CANIdType: {
    id: `${namespace}.CANIdType`,
    defaultMessage: 'CAN ID Type'
  },
  CANId: {
    id: `${namespace}.CANId`,
    defaultMessage: 'CAN ID'
  },
  dataSource: {
    id: `${namespace}.dataSource`,
    defaultMessage: 'Data source'
  },
  byte: {
    id: `${namespace}.byte`,
    defaultMessage: 'Byte {number}'
  },
  noData: {
    id: `${namespace}.noData`,
    defaultMessage: 'No data available'
  },
  extendedCANId: {
    id: `${namespace}.extendedCANId`,
    defaultMessage: 'Extended CAN ID'
  },
  standard: {
    id: `${namespace}.standard`,
    defaultMessage: 'Standard'
  },
  setBytesDirectly: {
    id: `${namespace}.setBytesDirectly`,
    defaultMessage: 'Set bytes directly'
  },
  selectVirtualSignal: {
    id: `${namespace}.selectVirtualSignal`,
    defaultMessage: 'Select virtual signal'
  },
  GNSSPosition: {
    id: `${namespace}.GNSSPosition`,
    defaultMessage: 'GNSS position'
  },
  virtualSignalNumber: {
    id: `${namespace}.virtualSignalNumber`,
    defaultMessage: 'Virtual signal number'
  },
  dataLength: {
    id: `${namespace}.dataLength`,
    defaultMessage: 'Data length'
  }
})
