import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { utils } from 'ducks/groups'

import NotificationsDetailMobile from './NotificationsDetailMobile'

const mapStateToProps = (state, { match }) => {
  const { notificationId } = match.params
  return {
    notificationId,
    groupId: utils.getSelectedGroup(state).id
  }
}

export default withRouter(connect(mapStateToProps)(NotificationsDetailMobile))
