import { DEFAULT_GROUP_ID } from 'utils/constants'

const plus1ConnectLogin = () => '/auth/login'

const csNodes = (groupId, limit, offset) => generateUrl('/devices', { groupId, limit, offset })

const dynamicCSNodes = (groupId, limit, offset, extraQueryParameters) => {
  let url = generateUrl('/dynamic/devices', { groupId, limit, offset })
  if (extraQueryParameters && Object.keys(extraQueryParameters).length > 0) {
    const extraParameters = Object.entries(extraQueryParameters).reduce((acc, [key, fields]) => {
      const newParams = fields.map(field => key + '=' + field).join('&')
      return acc + '&' + newParams
    }, '')
    url += extraParameters
  }
  return url
}

//Add random param to avoid cache
const csNodesDetails = groupId => generateUrl('/devices/list', { groupId })

const csNode = (deviceId, groupId) => generateUrl('/devices/' + deviceId, { groupId })
const csNodeByEid = (eid, groupId) => generateUrl('/devices/eid/' + eid, { groupId })

const csNodeActivate = () => '/devices/activate'
const csNodeUnregister = () => '/devices/unregister'

const csNodeSIMStatus = (groupId, deviceId) =>
  generateUrl('/devices/' + deviceId + '/sim/status', {
    /*groupId*/
  })
const csNodeSIMTest = (groupId, deviceId) => generateUrl('/devices/' + deviceId + '/sim/test', { groupId })
const csNodeSIMPause = (groupId, deviceId) => generateUrl('/devices/' + deviceId + '/sim/pause', { groupId })

// CS500 endpoints
const csNodeMetadata = (groupId, deviceEid) => generateUrl('/devices/' + deviceEid + '/meta', { groupId })
const csNodeNVConfiguration = (groupId, deviceEid) =>
  generateUrl('/devices/' + deviceEid + '/nvconfigurations/latest', { groupId })
const csNodeSetNVConfiguration = (groupId, deviceEid) =>
  generateUrl('/devices/' + deviceEid + '/nvconfigurations', { groupId })
const csNodeNVConfigurationByHash = (groupId, deviceEid, configHash) =>
  generateUrl('/devices/' + deviceEid + '/nvconfigurations/configHash/' + configHash, { groupId })
const newestNVConfigurationDefinition = () => '/nvconfigurations/definitions/newest' 
//

const csFleets = () => '/fleets'

const csFOTA = groupId => generateUrl('/firmwares', { groupId })

const configurations = (groupId, limit, offset) => generateUrl('/configurations', { groupId, limit, offset })
const configuration = (groupId, configurationId) => generateUrl('/configurations/' + configurationId, { groupId })

// Achintya: 3/11 12:34 --> Allowed updating and deleting of a shared configurations from DDG with query parameter as DDG groupId
/*const configuration2 = (groupId, configurationId) => {
  let url = configurations() + '/' + configurationId
  if (groupId) url = url + '?groupId=' + groupId
  return url
}*/

const csConfiguration = (groupId, deviceId) => generateUrl('/devices/' + deviceId + '/configuration', { groupId })

const azureNodeToken = nodeMac => '/api/v2/nodes/azureToken/' + nodeMac
const csNodeLastLocation = (deviceId, start, end) => '/api/v2/nodes/m' + deviceId + '/locationsAzureLast'
const csNodeListLastLocation = (nodeMacList, page, size) =>
  '/api/v2/nodes/locationsAzureLast?page=' + page + '&size=' + size + '&eids=' + nodeMacList

const schedulesAWS = groupId => generateUrl('/api/v2/reports', { groupId })
const scheduleAWS = (scheduleId, groupId) => generateUrl('/api/v2/reports/' + scheduleId, { groupId })
const schedules = (groupId, limit, offset) => generateUrl('/schedules', { groupId, limit, offset })
const schedule = (scheduleId, groupId) => generateUrl('/schedules/' + scheduleId, { groupId })

const dataPlan = dataPlanId => '/dataplan/' + dataPlanId
const dataPlans = (groupId, limit, offset) => generateUrl('/dataplans', { groupId, limit, offset })
const dataPlanActivate = (groupId, dataPlanId) => generateUrl('/dataplan/' + dataPlanId + '/activate', { groupId })
const numberOfDataPlans = groupId => generateUrl('/dataplans/count', { groupId })
const shareDataPlans = targetGroupId => `/groups/userResources/add/${targetGroupId}`

const numberOfAccessPasses = groupId => generateUrl('/accesspasses/count', { groupId })
const manageAccessPassConsumption = (id, groupId, allow_consumption) =>
  generateUrl(`/devices/${id}/allowConsumption`, { groupId, allow_consumption })
const shareAccessPasses = targetGroupId => `/groups/userResources/add/${targetGroupId}`

const groups = (limit, offset) => generateUrl('/groups', { limit, offset })
const usersOfGroup = groupName => '/groups/' + groupName

const usersOfGroupDetails = groupId => generateUrl('/groups/users/list', { groupId })

const createGroup = parentGroupId => '/groups/' + parentGroupId
const updateGroup = () => '/groups'
const deleteGroup = () => '/groups/delete'
const addUsersToGroup = groupId => '/groups/users/' + groupId
const removeUsersFromGroup = groupId => '/groups/users/remove/' + groupId
const addDevicesToGroup = groupId => '/groups/userResources/add/' + groupId
const removeDevicesFromGroup = groupId => '/groups/userResources/remove/' + groupId

const roles = groupId => '/groups/' + groupId + '/roles'
const role = (groupId, roleId) => '/groups/' + groupId + '/roles/' + roleId
const assignRoleToUser = groupId => '/groups/' + groupId + '/users'
const detailedUsersOfGroup = (groupId, limit, offset) => generateUrl('/groups/users/' + groupId, { limit, offset })
const deleteRole = (groupId, roleId) => '/groups/' + groupId + '/roles/' + roleId
const addUsersToGroupWithRole = groupId => '/groups/users/' + groupId

const userDetails = (/*groupId,*/ limit, offset) => generateUrl('/groups/user', { limit, offset })
const user = userId => `/user/${userId}`

const setValuePulse = (deviceEid, valuePulse, { groupId, ...queryParams }) =>
  `${generateUrl('/devices/' + deviceEid + '/valuePulse/' + valuePulse, queryParams)}${
    Object.keys(queryParams).length > 0 ? '&' : '?'
  }groupId=${groupId}`

const sentCANMesssagesHistoric = ({ groupId, ...queryParams }) =>
  generateUrl('/getSendCanHistory', queryParams) + '&groupId=' + groupId

const generateUrl = (baseUrl, queryParams) => {
  let { groupId, offset, limit, ...filteredParams } = queryParams // eslint-disable-line prefer-const

  if (groupId && groupId !== DEFAULT_GROUP_ID) filteredParams = { ...filteredParams, groupId }
  if (Number.isInteger(offset) && Number.isInteger(limit)) filteredParams = { ...filteredParams, offset, limit }
  return Object.entries(filteredParams).reduce((acc, [key, value], index) => {
    const sign = index === 0 ? '?' : '&'
    return acc + sign + key + '=' + value
  }, baseUrl)
}

export {
  plus1ConnectLogin,
  csNodes,
  dynamicCSNodes,
  csNodesDetails,
  csNode,
  csNodeLastLocation,
  csNodeListLastLocation,
  csNodeActivate,
  csNodeUnregister,
  csFleets,
  csFOTA,
  schedules,
  schedule,
  schedulesAWS,
  scheduleAWS,
  configurations,
  configuration,
  //configuration2,
  csConfiguration,
  azureNodeToken,
  csNodeByEid,
  csNodeSIMStatus,
  csNodeSIMTest,
  csNodeSIMPause,
  csNodeMetadata,
  csNodeNVConfiguration,
  csNodeSetNVConfiguration,
  csNodeNVConfigurationByHash,
  newestNVConfigurationDefinition,
  dataPlan,
  dataPlans,
  dataPlanActivate,
  numberOfDataPlans,
  shareDataPlans,
  numberOfAccessPasses,
  manageAccessPassConsumption,
  shareAccessPasses,
  groups,
  usersOfGroup,
  usersOfGroupDetails,
  createGroup,
  updateGroup,
  deleteGroup,
  addUsersToGroup,
  removeUsersFromGroup,
  addDevicesToGroup,
  removeDevicesFromGroup,
  userDetails,
  user,
  roles,
  role,
  assignRoleToUser,
  detailedUsersOfGroup,
  deleteRole,
  addUsersToGroupWithRole,
  setValuePulse,
  sentCANMesssagesHistoric
}
