import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import { Link } from '@material-ui/core'

import messages from './messages'
import { useAuth0 } from '../../../react-auth0-spa'

const SSOSignUp = ({ buttonText, intl }) => {
  const { formatMessage } = intl
  const { loginWithRedirect } = useAuth0()
  return (
    <div>
      {formatMessage(messages.signUpText)}
      <Link
        className='partm-home-sign-up-link'
        onClick={() => {
          loginWithRedirect({ signup: true })
        }}
      >
        {buttonText}
      </Link>
    </div>
  )
}

SSOSignUp.propTypes = {
  buttonText: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired
}

export default injectIntl(SSOSignUp)
