import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { ColorPicker } from 'components/common'

import messages from './messages'

const styles = theme => ({
  colorPicker: {
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: 0,
    cursor: 'pointer',
    display: 'block',
    height: 26,
    marginTop: 21,
    width: '100%',
    padding: 1,
    backgroundClip: 'content-box',

    '&:hover': {
      padding: 3
    }
  },
  dialogContentRoot: {
    overflowY: 'hidden'
  }
})

class SendCANMessageSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      data: { settings }
    } = props

    this.state = {
      ...settings,
      responseTimeoutValueError: ''
    }
  }

  handleMessageChange = event => {
    const selectedMessage = event.target.value
    this.setState({ selectedMessage })
  }

  handleMessageNameChange = event => {
    const nameValue = event.target.value
    this.setState({
      nameValue
    })
  }

  handleResponseTimeoutChange = event => {
    const { intl } = this.props
    this.setState(({ responseTimeoutValue: prevResponseTimeoutValue }) => {
      const responseTimeoutValue = event.target.value > 999 ? prevResponseTimeoutValue : event.target.value
      const isInvalidResponseTimeoutValue = responseTimeoutValue < 5 || responseTimeoutValue > 300
      return {
        responseTimeoutValue,
        responseTimeoutValueError: isInvalidResponseTimeoutValue
          ? intl.formatMessage(messages.invalidResponseTimeoutValue)
          : ''
      }
    })
  }

  handleColorChange = buttonColor => {
    this.setState({ buttonColor })
  }

  handleCheckboxChange = event => {
    const isConfirmationChecked = event.target.checked
    this.setState({ isConfirmationChecked })
  }

  handleSave = () => {
    const { saveSettings } = this.props
    const { selectedMessage, nameValue, buttonColor, responseTimeoutValue, isConfirmationChecked } = this.state

    saveSettings({
      settings: { selectedMessage, nameValue, buttonColor, responseTimeoutValue, isConfirmationChecked }
    })
  }

  render() {
    const { intl, closeSettings, classes, sendCANMessages } = this.props
    const {
      selectedMessage,
      nameValue,
      buttonColor,
      responseTimeoutValue,
      isConfirmationChecked,
      responseTimeoutValueError
    } = this.state

    return (
      <Dialog
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='sm'
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {intl.formatMessage(messages.title)}
          <IconButton
            onClick={closeSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>{intl.formatMessage(messages.CANMessage)}</InputLabel>
                <Select onChange={this.handleMessageChange} value={selectedMessage}>
                  {sendCANMessages.map(({ canMessageId }) => (
                    <MenuItem key={canMessageId} value={canMessageId}>
                      {intl.formatMessage(messages.message, { messageId: canMessageId + 1 })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                inputProps={{ maxLength: 100 }}
                label={intl.formatMessage(messages.messageName, { maxLength: 100 })}
                name='name'
                onChange={this.handleMessageNameChange}
                value={nameValue}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel shrink>{intl.formatMessage(messages.buttonBackgroundColor)}</InputLabel>
                <ColorPicker className={classes.colorPicker} onChange={this.handleColorChange} value={buttonColor} />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>{intl.formatMessage(messages.seconds)}</InputAdornment>
                }}
                error={responseTimeoutValueError}
                fullWidth
                helperText={responseTimeoutValueError || ' '}
                inputProps={{ max: '300', min: '5', maxLength: 3 }}
                label={intl.formatMessage(messages.responseTimeout)}
                name='responseTimeout'
                onChange={this.handleResponseTimeoutChange}
                type='number'
                value={responseTimeoutValue}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>{intl.formatMessage(messages.confirmationPrompt)}</FormLabel>
                <FormControlLabel
                  control={<Checkbox checked={isConfirmationChecked} onClick={this.handleCheckboxChange} />}
                  label={intl.formatMessage(messages.requireConfirmation)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={closeSettings}>
            {intl.formatMessage(messages.cancel)}
          </Button>
          <Button
            className='primary-action-button'
            disabled={selectedMessage === '' || nameValue === '' || responseTimeoutValueError}
            onClick={this.handleSave}
          >
            {intl.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

SendCANMessageSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired,
  sendCANMessages: PropTypes.array.isRequired
}

export default withStyles(styles)(injectIntl(SendCANMessageSettings))
