import * as t from './actionTypes'
import { createRequestAction } from './utils'
import * as url from '../plus1ConnectUrl'

const plus1ConnectLogin = () =>
  createRequestAction(t.PLUS1CONNECT_LOGIN, url.plus1ConnectLogin(), 'post', null, 'plus1Connect')

const getAllDevices = (groupId, limit, offset) =>
  createRequestAction(t.CS_NODE, url.csNodes(groupId, limit, offset), 'get', null, 'plus1Connect')

// eslint-disable-next-line max-params
const getDynamicDevices = (groupId, limit, offset, extraQueryParameters, filters) =>
  createRequestAction(
    t.CS_NODES_DYNAMIC,
    url.dynamicCSNodes(groupId, limit, offset, extraQueryParameters),
    'post',
    filters,
    'plus1Connect'
  )

const getDevicesDetails = (groupId, eids) =>
  createRequestAction(t.CS_NODES_DETAIL, url.csNodesDetails(groupId), 'post', { eids }, 'plus1Connect')

const getAllDevicesAndLocations = (groupId, limit, offset) =>
  createRequestAction(t.CS_NODES_AND_LOCATIONS, url.csNodes(groupId, limit, offset), 'get', null, 'plus1Connect')

const getDynamicDevicesLocationsNotifications = (groupId, limit, offset, extraQueryParameters) =>
  createRequestAction(
    t.CS_NODES_LOCATIONS_NOTIFICATIONS_DYNAMIC,
    url.dynamicCSNodes(groupId, limit, offset, extraQueryParameters),
    'post',
    null,
    'plus1Connect'
  )

const getDeviceById = (id, groupId) =>
  createRequestAction(t.CS_NODE, url.csNode(id, groupId), 'get', null, 'plus1Connect')
const getDeviceByEid = (eid, groupId) =>
  createRequestAction(t.CS_NODE_EID, url.csNodeByEid(eid, groupId), 'get', null, 'plus1Connect')
const getDeviceAndLocationById = (id, groupId) =>
  createRequestAction(t.CS_NODE_AND_LOCATION, url.csNode(id, groupId), 'get', null, 'plus1Connect')
const updateDevice = (id, groupId, body) =>
  createRequestAction(t.CS_NODE_UPDATE, url.csNode(id, groupId), 'put', body, 'plus1Connect')

const activateDevice = body =>
  createRequestAction(t.CS_NODE_ACTIVATE, url.csNodeActivate(), 'post', body, 'plus1Connect')

const unregisterCS100Device = EID =>
  createRequestAction(t.CS_NODE_UNREGISTER, url.csNodeUnregister(), 'post', { EID }, 'plus1Connect')

const getAllFleets = () => createRequestAction(t.CS_FLEET, url.csFleets(), 'get', null, 'plus1Connect')

const getAzureNodeToken = (id, nodeMac) => createRequestAction(t.CS_NODE_TOKEN, url.azureNodeToken(nodeMac), 'get', id)

const getLastLocation = (id, nodeMac) =>
  createRequestAction(t.CS_NODE_LOCATION, url.csNodeLastLocation(nodeMac), 'get', id)
const getLastLocationByNodeMacList = (nodeMacList, page, size) =>
  createRequestAction(t.CS_NODES_LOCATION, url.csNodeListLastLocation(nodeMacList, page, size), 'get')

const getAvailableFOTA = groupId => createRequestAction(t.CS_FOTA, url.csFOTA(groupId), 'get', null, 'plus1Connect')

const updateConfigurationOfDevice = (groupId, deviceId, configurationId) =>
  createRequestAction(
    t.CS_CONF_UPDATE,
    url.csConfiguration(groupId, deviceId),
    'put',
    { configurationId },
    'plus1Connect'
  )

const getDeviceSIMStatus = (groupId, deviceId) =>
  createRequestAction(t.CS_NODE_SIM_STATUS, url.csNodeSIMStatus(groupId, deviceId), 'get', null, 'plus1Connect')

const testDeviceSIM = (groupId, deviceId) =>
  createRequestAction(t.CS_NODE_SIM_TEST, url.csNodeSIMTest(groupId, deviceId), 'post', null, 'plus1Connect')

const pauseDeviceSIM = (groupId, deviceId) =>
  createRequestAction(t.CS_NODE_SIM_PAUSE, url.csNodeSIMPause(groupId, deviceId), 'post', null, 'plus1Connect')

// CS500
const getDeviceMetadata = (groupId, deviceEid) =>
  createRequestAction(t.CS_NODE_METADATA, url.csNodeMetadata(groupId, deviceEid), 'get', null, 'plus1Connect')

// CS500
const getDeviceNonVolatileConfiguration = (groupId, deviceEid) =>
  createRequestAction(
    t.CS_NODE_GET_NV_CONFIGURATION,
    url.csNodeNVConfiguration(groupId, deviceEid),
    'get',
    null,
    'plus1Connect'
  )

// CS500
const setDeviceNonVolatileConfiguration = (groupId, deviceEid, nvConfiguration) =>
  createRequestAction(
    t.CS_NODE_SET_NV_CONFIGURATION,
    url.csNodeSetNVConfiguration(groupId, deviceEid),
    'patch',
    nvConfiguration,
    'plus1Connect'
  )

const getDeviceNonVolatileConfigurationByHash = (groupId, deviceEid, configHash) =>
  createRequestAction(
    t.CS_NODE_GET_NV_CONFIGURATION_BY_HASH,
    url.csNodeNVConfigurationByHash(groupId, deviceEid, configHash),
    'get',
    null,
    'plus1Connect'
  )

const getNewestNVConfigurationDefinition = () =>
  createRequestAction(t.GET_NEWEST_NV_CONFIGURATION, url.newestNVConfigurationDefinition(), 'get', null, 'plus1Connect')

const getNumberOfAccessPasses = groupId =>
  createRequestAction(t.ACCESS_PASS, url.numberOfAccessPasses(groupId), 'get', null, 'plus1Connect')

const manageAccessPassConsumption = (id, groupId, allow_consumption) =>
  createRequestAction(
    t.ACCESS_PASS,
    url.manageAccessPassConsumption(id, groupId, allow_consumption),
    'put',
    null,
    'plus1Connect'
  )

const shareAccessPasses = (targetGroupId, body) =>
  createRequestAction(t.ACCESS_PASS, url.shareAccessPasses(targetGroupId), 'put', body, 'plus1Connect')

const setCS500ValuePulse = (deviceEid, valuePulse, body, queryParams) =>
  createRequestAction(
    t.SET_VALUE_PULSE,
    url.setValuePulse(deviceEid, valuePulse, queryParams),
    'post',
    body,
    'plus1Connect'
  )

const getSentCANHistoricMessages = queryParams =>
  createRequestAction(t.GET_SENT_CAN_MESSAGES, url.sentCANMesssagesHistoric(queryParams), 'get', null, 'plus1Connect')

export {
  activateDevice,
  getAllDevices,
  getAllDevicesAndLocations,
  getAllFleets,
  getAvailableFOTA,
  getAzureNodeToken,
  getDeviceAndLocationById,
  getDeviceByEid,
  getDeviceById,
  getDeviceMetadata,
  getDeviceNonVolatileConfiguration,
  getDeviceSIMStatus,
  getDevicesDetails,
  getDynamicDevices,
  getDynamicDevicesLocationsNotifications,
  getLastLocation,
  getLastLocationByNodeMacList,
  getNumberOfAccessPasses,
  manageAccessPassConsumption,
  pauseDeviceSIM,
  plus1ConnectLogin,
  setCS500ValuePulse,
  setDeviceNonVolatileConfiguration,
  getDeviceNonVolatileConfigurationByHash,
  getNewestNVConfigurationDefinition,
  shareAccessPasses,
  testDeviceSIM,
  unregisterCS100Device,
  updateConfigurationOfDevice,
  updateDevice,
  getSentCANHistoricMessages
}
