import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { withStyles } from '@material-ui/core/styles'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'

import SelectColumnsButton from 'components/SelectColumnsButton'
import { client, logError } from 'utils/http'

import messages from '../../messages'

const styles = {
  icon: {
    fontSize: 12,
    marginRight: 20,
    marginLeft: -4
  }
}

class NotificationsGlobalActions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null
    }
  }

  handleNotificationClose = () => {
    const { selectedNotifications, redrawNotificationsTable, clearSelectedNotifications, groupId } = this.props
    if (selectedNotifications.length > -1) {
      selectedNotifications.map(notification => {
        let notificationToUpdate = {}
        notificationToUpdate = {
          hashId: notification.hashId,
          status: 'closed',
          criticality: notification.criticality,
          ruleInstanceHashId: notification.ruleInstanceHashId,
          ruleInstanceName: notification.ruleInstanceName,
          ruleInstanceDescription: notification.ruleInstanceDescription,
          source: notification.source,
          archived: notification.archived,
          version: notification.version,
          observations: 'closed',
          deviceType: notification.deviceType,
          deviceName: notification.deviceName
        }

        client
          .modifyNotificacion(notificationToUpdate, groupId)
          .then(response => {
            //Mandar mensaje al padre para que actualice la tabla de notificaciones
            redrawNotificationsTable(notificationToUpdate)
          })
          .catch(response => {
            logError(response)
          })

        return notification
      })

      clearSelectedNotifications()
    } else {
      return false
    }
  }

  handleNotificationReopen = () => {
    const { selectedNotifications, redrawNotificationsTable, clearSelectedNotifications, groupId } = this.props
    if (selectedNotifications.length > -1) {
      selectedNotifications.map(notification => {
        let notificationToUpdate = {}
        notificationToUpdate = {
          hashId: notification.hashId,
          status: 'open',
          criticality: notification.criticality,
          ruleInstanceHashId: notification.ruleInstanceHashId,
          ruleInstanceName: notification.ruleInstanceName,
          ruleInstanceDescription: notification.ruleInstanceDescription,
          source: notification.source,
          archived: notification.archived,
          version: notification.version,
          observations: 'reopened',
          deviceType: notification.deviceType,
          deviceName: notification.deviceName
        }
        client
          .modifyNotificacion(notificationToUpdate, groupId)
          .then(response => {
            //Mandar mensaje al padre para que actualice la tabla de notificaciones
            redrawNotificationsTable(notificationToUpdate)
          })
          .catch(response => {
            logError(response)
          })

        return notification
      })

      clearSelectedNotifications()
    } else {
      return false
    }
  }

  handleNotificationAcknowledge = () => {
    const { selectedNotifications, redrawNotificationsTable, clearSelectedNotifications, groupId } = this.props
    if (selectedNotifications.length > -1) {
      selectedNotifications.map(notification => {
        let notificationToUpdate = {}
        notificationToUpdate = {
          hashId: notification.hashId,
          status: 'acknowledged',
          criticality: notification.criticality,
          ruleInstanceHashId: notification.ruleInstanceHashId,
          ruleInstanceName: notification.ruleInstanceName,
          ruleInstanceDescription: notification.ruleInstanceDescription,
          source: notification.source,
          archived: notification.archived,
          version: notification.version,
          observations: 'acknowledged',
          deviceType: notification.deviceType,
          deviceName: notification.deviceName
        }

        client
          .modifyNotificacion(notificationToUpdate, groupId)
          .then(response => {
            //Mandar mensaje al padre para que actualice la tabla de notificaciones
            redrawNotificationsTable(notificationToUpdate)
          })
          .catch(response => {
            logError(response)
          })

        return notification
      })

      clearSelectedNotifications()
    } else {
      return false
    }
  }

  handleNotificationUnacknowledge = () => {
    const { selectedNotifications, redrawNotificationsTable, clearSelectedNotifications, groupId } = this.props
    if (selectedNotifications.length > -1) {
      selectedNotifications.map(notification => {
        let notificationToUpdate = {}
        notificationToUpdate = {
          hashId: notification.hashId,
          status: 'open',
          criticality: notification.criticality,
          ruleInstanceHashId: notification.ruleInstanceHashId,
          ruleInstanceName: notification.ruleInstanceName,
          ruleInstanceDescription: notification.ruleInstanceDescription,
          source: notification.source,
          archived: notification.archived,
          version: notification.version,
          observations: 'unacknowledged',
          deviceType: notification.deviceType,
          deviceName: notification.deviceName
        }

        client
          .modifyNotificacion(notificationToUpdate, groupId)
          .then(response => {
            //Mandar mensaje al padre para que actualice la tabla de notificaciones
            redrawNotificationsTable(notificationToUpdate)
          })
          .catch(response => {
            logError(response)
          })

        return notification
      })

      clearSelectedNotifications()
    } else {
      return false
    }
  }

  handleActionsButtonClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  }

  renderActionsMenu = () => {
    const {
      selectedNotificationsStatus,
      classes,
      intl: { formatMessage }
    } = this.props
    const { anchorEl } = this.state

    const showAcknowledge =
      selectedNotificationsStatus.filter(status => {
        return status === 'open'
      }).length > 0

    const showUnacknowledge =
      selectedNotificationsStatus.filter(status => {
        return status === 'acknowledged'
      }).length > 0

    const showClose =
      selectedNotificationsStatus.filter(status => {
        return status === 'open' || status === 'acknowledged'
      }).length > 0

    const showReopen =
      selectedNotificationsStatus.filter(status => {
        return status === 'closed'
      }).length > 0

    return (
      <Popper anchorEl={anchorEl} open={Boolean(anchorEl)}>
        <Paper style={{ zIndex: 2100 }}>
          <ClickAwayListener onClickAway={this.handleMenuClose}>
            <MenuList>
              <MenuItem
                disabled={!showUnacknowledge || showUnacknowledge && (showAcknowledge || showReopen)}
                onClick={this.handleNotificationUnacknowledge}
              >
                <ListItemIcon className={classes.icon}>
                  <svg height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M7.26 3.01C7.97 1.91 9.21 1.19 10.61 1.19C12.82 1.19 14.61 2.98 14.61 5.19C14.61 6.59 13.89 7.83 12.79 8.54L7.26 3.01ZM18.61 14.36C18.59 13.26 17.98 12.25 17 11.74C16.46 11.46 15.87 11.2 15.23 10.98L18.61 14.36ZM19.8 18.38L1.42 0L0 1.41L8.89 10.3C7.08 10.53 5.5 11.09 4.22 11.75C3.22 12.26 2.61 13.29 2.61 14.41V17.19H15.78L18.39 19.8L19.8 18.38Z'
                      fill='#757575'
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText primary={formatMessage(messages.unacknowledge)} />
              </MenuItem>
              <MenuItem
                disabled={!showReopen || showReopen && (showClose || showAcknowledge || showUnacknowledge)}
                onClick={this.handleNotificationReopen}
              >
                <ListItemIcon>
                  <Icon className='zmdi zmdi-replay' />
                </ListItemIcon>
                <ListItemText primary={formatMessage(messages.reopen)} />
              </MenuItem>
              <MenuItem
                disabled={!showAcknowledge || showAcknowledge && (showReopen || showUnacknowledge)}
                onClick={this.handleNotificationAcknowledge}
              >
                <ListItemIcon>
                  <Icon className='zmdi zmdi-account' />
                </ListItemIcon>
                <ListItemText primary={formatMessage(messages.acknowledge)} />
              </MenuItem>
              <MenuItem disabled={!showClose || showClose && showReopen} onClick={this.handleNotificationClose}>
                <ListItemIcon>
                  <Icon className='zmdi zmdi-close-circle-o' />
                </ListItemIcon>
                <ListItemText primary={formatMessage(messages.close)} />
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    )
  }
  render() {
    const {
      selectedNotifications,
      clearSelectedNotifications,
      canViewAndEditNotifications,
      visibleColumns,
      onChangeColumnVisibility,
      intl: { formatMessage }
    } = this.props

    return (
      <Grid container>
        {canViewAndEditNotifications && (
          <Grid container item spacing={1} style={{ marginTop: 14 }} xs={12}>
            <Grid item>
              <Button
                disabled
                style={{
                  color: 'color: rgba(0, 0, 0, 0.87)'
                }}
              >
                {formatMessage(messages.selected)} ({selectedNotifications.length})
              </Button>
            </Grid>
            <Grid item>
              <Button className='secondary-action-button' onClick={clearSelectedNotifications}>
                {formatMessage(messages.clearSelection)}
              </Button>
            </Grid>
            <Grid item>
              <SelectColumnsButton columns={visibleColumns} onChangeColumnVisibility={onChangeColumnVisibility} />
            </Grid>
            <Grid item>
              <Button className='secondary-action-button' disabled={false} onClick={this.handleActionsButtonClick}>
                <FormatListBulletedIcon fontSize='small' style={{ marginRight: 3 }} />
                {formatMessage(messages.actions)}
                <Icon className='zmdi zmdi-caret-down' style={{ marginRight: 0, marginTop: -3 }} />
                {this.renderActionsMenu()}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}

NotificationsGlobalActions.propTypes = {
  canViewAndEditNotifications: PropTypes.bool.isRequired,
  classes: PropTypes.array.isRequired,
  clearSelectedNotifications: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  onChangeColumnVisibility: PropTypes.func.isRequired,
  redrawNotificationsTable: PropTypes.func.isRequired,
  selectedNotifications: PropTypes.array.isRequired,
  selectedNotificationsStatus: PropTypes.array.isRequired,
  visibleColumns: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(NotificationsGlobalActions))
