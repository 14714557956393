export const NAME = 'router'

// DEPRECATED
// If you find yourself defining routes in this file, please note that with
// React Router v4 each router handles its own subroutes and that each
// constant should be inside the component it belongs to.
// Check components/settings to see an example which handles many subroutes.

// Constants for route creation
export const ANY = '*'
export const LOGIN = '/'
export const MAP = '/map'
export const NODES_DASHBOARDS = '/nodes/:nodeFamily/:nodeId/dashboards'
export const ACTIONSSTATE = '/actions-state'
export const LOGOUT = '/logout'
export const GEOTRACKING = '/geotracking'
export const HOME = '/home'
export const CALLBACK = '/callback'
