import { actions } from 'utils/http'

import * as t from './actionTypes'
import * as c from './constants'

const initialState = {
  [c.GPS_TRACKINGS_LOADING]: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.types.success(t.GET_GPS_TRACKINGS):
      if (action.payload && action.payload.data && action.payload.data.content) {
        const gpsTrack = action.payload.data.content
        const EID = action.meta.previousAction.payload.request.data.nodeId
        const newPointSet = gpsTrack.reduce((ret, gpsPoint) => {
          ret.push({
            latitude: gpsPoint.location.latitude,
            longitude: gpsPoint.location.longitude,
            altitude: gpsPoint.deviceType === 'CS100' ? gpsPoint.altitude * 0.125 - 2500 : gpsPoint.altitude,
            speed: gpsPoint.deviceType === 'CS100' ? gpsPoint.speed / 256 : gpsPoint.speed * 0.01 * 3.6,
            heading: gpsPoint.deviceType === 'CS100' ? gpsPoint.heading / 128 : gpsPoint.heading * 0.01,
            timestamp: gpsPoint.timestamp
          })
          return ret
        }, [])
        return {
          ...state,
          [EID]: {
            ...state[EID],
            points: (state?.[EID]?.points || []).concat(newPointSet),
            ready: true
          }
        }
      }
      break
    case actions.types.success(t.GET_AGGREGATED_GPS_TRACKINGS):
      if (action.payload && action.payload.data && action.payload.data.content) {
        const gpsTrack = action.payload.data.content
        const EID = action.meta.previousAction.payload.request.data.nodeId
        const aggLevel = action.meta.previousAction.payload.request.data.aggLevel
        const deviceType = action.meta.previousAction.payload.request.data.deviceType

        const newPointSet = gpsTrack.map(gpsPoint => {
          return {
            timestamp: gpsPoint.timestamp,
            eid: gpsPoint.eid,
            altitude: {
              min: deviceType === 'CS100' ? gpsPoint.altitude.min * 0.125 - 2500 : gpsPoint.altitude.min,
              max: deviceType === 'CS100' ? gpsPoint.altitude.max * 0.125 - 2500 : gpsPoint.altitude.max,
              avg: deviceType === 'CS100' ? gpsPoint.altitude.avg * 0.125 - 2500 : gpsPoint.altitude.avg
            },
            speed: {
              min: deviceType === 'CS100' ? gpsPoint.speed.min / 256 : gpsPoint.speed.min * 0.01 * 3.6,
              max: deviceType === 'CS100' ? gpsPoint.speed.max / 256 : gpsPoint.speed.max * 0.01 * 3.6,
              avg: deviceType === 'CS100' ? gpsPoint.speed.avg / 256 : gpsPoint.speed.avg * 0.01 * 3.6
            },
            heading: {
              min: deviceType === 'CS100' ? gpsPoint.heading.min / 128 : gpsPoint.heading.min * 0.01,
              max: deviceType === 'CS100' ? gpsPoint.heading.max / 128 : gpsPoint.heading.max * 0.01,
              avg: deviceType === 'CS100' ? gpsPoint.heading.avg / 128 : gpsPoint.heading.avg * 0.01
            }
          }
        })

        const isReady = action.payload.data.page === action.payload.data.pages - 1
        return {
          ...state,
          [EID]: {
            ...state[EID],
            [aggLevel]: (state?.[EID]?.[aggLevel] || []).concat(newPointSet),
            ready: isReady
          }
        }
      }
      break

    case t.NEW_GPS_POINT:
      if (action.meta && action.meta.topic) {
        const EID = action.meta.topic.split('/')[1].substr(1)
        if (!(EID in state)) {
          const track = []
          track.push(action.payload)
          return { ...state, [EID]: { points: track } }
        } else {
          const track = Object.assign([], state[EID].points)
          track.unshift(action.payload)
          return { ...state, [EID]: { points: track } }
        }
      }
      break

    case t.CLEAR_GPS_TRACKING:
      const node = action.payload
      const next = { ...state }
      delete next[node]
      return next

    case t.CLEAR_GPS_TRACKINGS:
      return initialState

    case t.ADD_GPS_TRACKINGS_LOADING:
      return {
        ...state,
        [c.GPS_TRACKINGS_LOADING]: [...state[c.GPS_TRACKINGS_LOADING], action.payload]
      }
    case t.REMOVE_GPS_TRACKINGS_LOADING:
      return {
        ...state,
        [c.GPS_TRACKINGS_LOADING]: state[c.GPS_TRACKINGS_LOADING].filter(item => item !== action.payload)
      }

    default:
      return state
  }
  return state
}
