import update from 'immutability-helper'

import translations from 'i18n/locales'
import {
  initialValues,
  initSignal,
  initMFIO,
  canIdComposer,
  canIdModifier,
  decToHexCanId
} from 'modules/configurationsCS100/config'
import { PROTOCOL_TYPES } from 'modules/configurationsCS100/constants'
import * as J1939 from 'utils/cs500/maps/J1939.json'
import { actions } from 'utils/http'

import * as at from './actionTypes'
import * as c from './constants'


const { types: ht } = actions

const initialState = initialValues()
const initialSignal = initSignal()
const initialMFIO = initMFIO()

const MAX_SIGNAL_NUMBER = 50

const getRandomColor = () => {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const reducer = (state = initialState, action) => {
  let newState

  switch (action.type) {
    case at.CONFIG_FIELD_CHANGE:
      const { field, value } = action.payload
      return {
        ...state,
        [field]: value !== undefined ? value : !state[field],
        ...value !== undefined && { [field + 'ErrorMessage']: '' },
        ...field === 'gpsEnabled' &&
          !state[field] && {
          gpsFrequency: '300'
        },
        ...field === 'powerManagementEnable' &&
          value === 0 && {
          wakeUpFlagEnable: false,
          wakeUpFlagEnableErrorMessage: '',
          shutdownDelay: 120,
          shutdownDelayErrorMessage: '',
          cycleTimeDelay: 0,
          cycleTimeDelayErrorMessage: ''
        },
        ...field === 'wakeUpFlagEnable' &&
          value === true &&
          state.powerManagementEnable === 1 && {
          powerManagementEnable: 3,
          powerManagementEnableErrorMessage: '',
          shutdownDelay: 120,
          shutdownDelayErrorMessage: '',
          cycleTimeDelay: 30,
          cycleTimeDelayErrorMessage: ''
        },
        ...field === 'wakeUpFlagEnable' &&
          value === false &&
          state.powerManagementEnable === 3 && {
          powerManagementEnable: 1,
          powerManagementEnableErrorMessage: '',
          cycleTimeDelay: 0,
          cycleTimeDelayErrorMessage: ''
        }
      }

    case at.CONFIG_ADD_SIGNAL:
      const idsInUse = state.signals.map(s => s.signalId).sort((a, b) => a - b)

      if (idsInUse.length < MAX_SIGNAL_NUMBER) {
        let nextId

        for (let i = 0; i < MAX_SIGNAL_NUMBER && nextId === undefined; i++) {
          if (i + 2 !== parseInt(idsInUse[i])) {
            nextId = i + 2
          }
        }
        if (nextId === undefined) nextId = 2

        const newSignalToBeAdded = {
          ...initialSignal,
          signalId: nextId,
          signalColor: getRandomColor()
        }
        newState = {
          ...state,
          signals: [...state.signals, newSignalToBeAdded]
        }
      } else {
        newState = {
          ...state
        }
      }
      return newState

    case at.CONFIG_COPY_SIGNAL:
      const ids = state.signals.map(s => s.signalId).sort((a, b) => a - b)

      if (ids.length < MAX_SIGNAL_NUMBER) {
        let nxtId

        for (let i = 0; i < MAX_SIGNAL_NUMBER && nxtId === undefined; i++) {
          if (i + 2 !== parseInt(ids[i])) {
            nxtId = i + 2
          }
        }
        if (nxtId === undefined) nxtId = 2

        const {
          signalId: copyId,
          signalIdErrorMessage,
          editableId,
          selectedCanProtocolName,
          pgn,
          pgnName,
          spn,
          spnName,
          selectedSignal,
          ...rst
        } = state.signals.find(s => s.signalId === action.payload.signalId)

        const copiedSignalToBeAdded = {
          ...initialSignal,
          ...rst,
          signalId: nxtId,
          signalColor: getRandomColor()
        }

        newState = {
          ...state,
          signals: [...state.signals, copiedSignalToBeAdded]
        }
      } else {
        newState = {
          ...state
        }
      }
      return newState

    case at.CONFIG_ADD_MFIO:
      if (state.MFIO.length < 2) {
        const mfioToBeAdded = {
          ...initialMFIO,
          signalId: action.payload.signalId
        }

        newState = {
          ...state,
          MFIO: [...state.MFIO, mfioToBeAdded]
        }
      } else {
        newState = {
          ...state
        }
      }
      return newState

    case at.ADD_J1939_SIGNAL:
      const updatedSignalsArray = [...state.signals]

      const currSignalIndex = state.signals.findIndex(s => s.signalId === action.payload.signalId)
      const currSignal = { ...state.signals[currSignalIndex] }

      const j1939parameters = action.payload.j1939Parameters
      // new identifier
      const j1939Identifier =
        currSignal.selectedCanProtocolName === PROTOCOL_TYPES.J1939 && j1939parameters.spn
          ? j1939parameters.spn + 10000
          : action.payload.signalId

      // Only add if it has not been already used
      const idsAlreadyInUse = state.signals.map(s => s.signalId).sort((a, b) => a - b)

      if (j1939Identifier === action.payload.signalId || !idsAlreadyInUse.includes(j1939Identifier)) {
        let sourceAddress = '0x00'
        if (j1939parameters.sourceAddress) {
          sourceAddress = j1939parameters.sourceAddress
          if (Number.isInteger(sourceAddress)) {
            sourceAddress = '0x' + sourceAddress.toString(16).toUpperCase()
          }
        } else if (
          currSignal.sourceAddress &&
          currSignal.sourceAddress >= 0 &&
          currSignal.sourceAddress <= 255 &&
          currSignal.sourceAddress.includes('0x')
        ) {
          sourceAddress = currSignal.sourceAddress
        }

        let canIdentidier = ''
        if (j1939parameters.canId) {
          canIdentidier = j1939parameters.canId
          if (Number.isInteger(canIdentidier)) {
            canIdentidier = decToHexCanId(canIdentidier, j1939parameters.isExtended)
          }
        } else {
          canIdentidier = canIdComposer(j1939parameters.priority, j1939parameters.pgn, sourceAddress)
        }

        updatedSignalsArray[currSignalIndex] = {
          signalId: j1939Identifier,
          signalIdErrorMessage: '',
          expanded: currSignal.expanded,
          selectedCanProtocolName: currSignal.selectedCanProtocolName,
          name: j1939parameters.spnName,
          nameErrorMessage: '',
          canId: canIdentidier,
          canIdErrorMessage: '',
          address: j1939parameters.isExtended ? '29' : '11',
          addressErrorMessage: '',
          startBit: j1939parameters.startBit,
          startBitErrorMessage: '',
          lengthOfBits: j1939parameters.lengthOfBits,
          lengthOfBitsErrorMessage: '',
          multiplier: j1939parameters.multiplier,
          multiplierErrorMessage: '',
          offset: j1939parameters.offset,
          offsetErrorMessage: '',
          unit: j1939parameters.unit,
          unitErrorMessage: '',
          canPort: '',
          canPortErrorMessage: '',
          idMask: j1939parameters.isExtended ? '0x1FFFFFFF' : '0x7FF',
          idMaskErrorMessage: '',
          frequency: '',
          frequencyErrorMessage: '',
          sourceAddress,
          sourceAddressErrorMessage: '',
          priority: j1939parameters.priority,
          priorityErrorMessage: '',
          pgn: j1939parameters.pgn,
          pgnName: j1939parameters.pgnName,
          spn: j1939parameters.spn,
          spnName: j1939parameters.spnName,
          signed: false,
          signalColor: currSignal.signalColor,
          selectedSignal: {
            value: j1939parameters.spn ? j1939parameters.spn : j1939parameters.spnName,
            label: j1939parameters.spnName
          }
        }
      } else {
        const i18n = translations[localStorage.getItem('user_language')]

        updatedSignalsArray[currSignalIndex] = {
          ...currSignal,
          signalIdErrorMessage: i18n['Signal.signalAlreadyInUse']
        }
      }
      return {
        ...state,
        signals: updatedSignalsArray
      }

    case at.CONFIG_SIGNAL_FIELD_CHANGE:
      const { signalId: siId, field: fi, value: val } = action.payload

      const currentSignalsArray = [...state.signals]
      const currentSignalIndex = state.signals.findIndex(s => s.signalId === siId)

      currentSignalsArray[currentSignalIndex] = {
        ...currentSignalsArray[currentSignalIndex],
        [fi]: fi === 'expanded' ? !state.signals[currentSignalIndex][fi] : val,
        ...fi !== 'expanded' && { [fi + 'ErrorMessage']: '' },
        ...fi === 'address' && { canIdErrorMessage: '', idMaskErrorMessage: '' }
      }

      return {
        ...state,
        signals: currentSignalsArray
      }

    case at.CONFIG_MFIO_FIELD_CHANGE:
      const { signalId: mfioIndex, field: fil, value: va } = action.payload

      let mfioState = {}

      if (fil === 'inputSignal' && va === 'Voltage') {
        mfioState = {
          bias: 0,
          biasErrorMessage: '',
          decimals: 3,
          decimalsErrorMessage: '',
          multiplier: 0.001,
          multiplierErrorMessage: '',
          offset: 0,
          offsetErrorMessage: '',
          unit: 'V',
          unitErrorMessage: ''
        }
      } else if (fil === 'inputSignal' && va === 'Digital Input') {
        mfioState = {
          bias: 0,
          biasErrorMessage: '',
          decimals: 0,
          decimalsErrorMessage: '',
          multiplier: 1,
          multiplierErrorMessage: '',
          offset: 0,
          offsetErrorMessage: '',
          unit: '',
          unitErrorMessage: ''
        }
      } else if (fil === 'inputSignal' && va === 'Frequency') {
        mfioState = {
          bias: 0,
          biasErrorMessage: '',
          decimals: 0,
          decimalsErrorMessage: '',
          multiplier: 1,
          multiplierErrorMessage: '',
          offset: 0,
          offsetErrorMessage: '',
          unit: 'Hz',
          unitErrorMessage: ''
        }
      } else if (fil === 'inputSignal' && va === 'Current') {
        mfioState = {
          bias: 0,
          biasErrorMessage: '',
          decimals: 3,
          decimalsErrorMessage: '',
          inputRange: 0,
          inputRangeErrorMessage: '',
          multiplier: 0.001,
          multiplierErrorMessage: '',
          offset: 0,
          offsetErrorMessage: '',
          unit: 'mA',
          unitErrorMessage: ''
        }
      } else if (fil === 'inputSignal' && va === 'Resistance') {
        mfioState = {
          bias: 0,
          biasErrorMessage: '',
          decimals: 0,
          decimalsErrorMessage: '',
          inputRange: 0,
          inputRangeErrorMessage: '',
          multiplier: 1,
          multiplierErrorMessage: '',
          offset: 0,
          offsetErrorMessage: '',
          unit: 'Ohm',
          unitErrorMessage: ''
        }
      } else if (fil === 'pinFunction' && va === 1) {
        mfioState = {
          defaultPowerUp: false
        }
      }

      const mfioIdx = state.MFIO.indexOf(state.MFIO.find(e => e.signalId === parseInt(mfioIndex)))
      const MFIOUpdated = update(state.MFIO, {
        [mfioIdx]: {
          [fil]: { $set: fil === 'expanded' ? !state.MFIO[mfioIdx][fil] : va },
          ...fil !== 'expanded' && { [fil + 'ErrorMessage']: { $set: '' } }
        }
      })

      Object.assign(MFIOUpdated[mfioIdx], mfioState)

      return {
        ...state,
        MFIO: MFIOUpdated,
        powerManagementEnable: state.MFIO[0] && MFIOUpdated[0].pinFunction !== 3 ? 0 : state.powerManagementEnable
      }

    case at.CONFIG_SIGNAL_SA_CHANGE:
      const sgnIdx = state.signals.findIndex(s => s.signalId === action.payload.signalId)
      const sa = action.payload.sourceAddress
      const currSignalsArray = [...state.signals]

      const canId =
        sa && sa >= 0 && sa <= 255 && sa.includes('0x')
          ? canIdModifier(state.signals[sgnIdx].canId, sa)
          : sa === ''
            ? canIdModifier(state.signals[sgnIdx].canId, 0)
            : state.signals[sgnIdx].canId

      const saErrorMessage = sa >= 0 && sa <= 255 && sa.includes('0x') ? '' : '0x00 ≤ Source Address ≤ 0xFF'

      currSignalsArray[sgnIdx] = {
        ...currSignalsArray[sgnIdx],
        sourceAddress: sa,
        canId,
        sourceAddressErrorMessage: saErrorMessage
      }
      return {
        ...state,
        signals: currSignalsArray
      }

    case at.CONFIG_SIGNAL_PROTOCOL_NAME_CHANGE:
      const indx = state.signals.findIndex(s => s.signalId === action.payload.signalId)
      const currSgnalsArray = [...state.signals]
      const currentProtocolName = currSgnalsArray[indx].selectedCanProtocolName
      const newProtocolName = action.payload.selectedCanProtocolName

      const signalIdsAlreadyInUse = state.signals.map(s => s.signalId).sort((a, b) => a - b)
      let newSignalIdentifier

      // If the user selects a new protocol diferent from J1939, change the signalId
      for (let i = 0; i < MAX_SIGNAL_NUMBER && newSignalIdentifier === undefined; i++) {
        if (i + 2 !== parseInt(signalIdsAlreadyInUse[i])) {
          newSignalIdentifier = i + 2
        }
      }
      if (newSignalIdentifier === undefined) newSignalIdentifier = 2

      currSgnalsArray[indx] = {
        ...currSgnalsArray[indx],
        signalId:
          currentProtocolName === PROTOCOL_TYPES.J1939 && currentProtocolName !== newProtocolName
            ? newSignalIdentifier
            : currSgnalsArray[indx].signalId,
        selectedSignal: null,
        selectedCanProtocolName: newProtocolName
      }

      return {
        ...state,
        signals: currSgnalsArray
      }

    case at.CONFIG_DELETE_SIGNAL:
      const deleteSignalArray = [...state.signals]
      const j = deleteSignalArray.findIndex(s => s.signalId === action.payload.signalId)
      deleteSignalArray.splice(j, 1)
      return {
        ...state,
        signals: deleteSignalArray
      }

    case at.CONFIG_DELETE_MFIO:
      const posToDelete = state.MFIO.indexOf(state.MFIO.find(m => m.signalId === action.payload.signalId))
      const updatedMFIO = update(state.MFIO, { $splice: [[posToDelete, 1]] })
      return {
        ...state,
        MFIO: updatedMFIO
      }

    case at.EDITABLE_SIGNAL_ID:
      const idx = state.signals.findIndex(s => s.signalId === action.payload.signalId)
      const editableIdSignalArray = [...state.signals]

      editableIdSignalArray[idx] = {
        ...state.signals[idx],
        editableId: action.payload.isEditable
      }

      return {
        ...state,
        signals: editableIdSignalArray
      }

    case at.CONFIG_SIGNAL_ID_CHANGE:
      const z = state.signals.findIndex(s => s.signalId === action.payload.signalId)
      const changeIdSignalsArray = [...state.signals]

      changeIdSignalsArray[z] = {
        ...changeIdSignalsArray[z],
        newSignalId: action.payload.newSignalId
      }
      return {
        ...state,
        signals: changeIdSignalsArray
      }

    case at.CONFIG_SIGNAL_ID_VALIDATE:
      const sIndex = state.signals.findIndex(s => s.signalId === action.payload.signalId)

      const currentSignalId = action.payload.signalId

      const newSignalId = state.signals[sIndex].newSignalId

      const usedIds = state.signals.map(s => s.signalId).sort((a, b) => a - b)
      const alreadyInUse = usedIds.includes(newSignalId)

      const newSignalsArray = [...state.signals]

      if (currentSignalId !== newSignalId && alreadyInUse) {
        newSignalsArray[sIndex] = {
          ...newSignalsArray[sIndex],
          signalIdErrorMessage: 'ID already in use',
          editableId: true
        }
      } else if (newSignalId >= 2 && newSignalId <= 51) {
        newSignalsArray[sIndex] = {
          ...newSignalsArray[sIndex],
          signalId: newSignalId,
          signalIdErrorMessage: '',
          newSignalId: null
        }
      } else {
        newSignalsArray[sIndex] = {
          ...newSignalsArray[sIndex],
          signalIdErrorMessage: '2 ≤ signal ID ≤ 51',
          editableId: true
        }
      }
      return {
        ...state,
        signals: newSignalsArray
      }

    case at.CONFIG_ERROR:
      const errors = action.payload.errors
      newState = errors.reduce((modified, current) => {
        if (!current.path.includes('['))
          return update(modified, { [current.path + 'ErrorMessage']: { $set: current.message } })
        else {
          const dividedPath = current.path.split('[')
          const signals = dividedPath[0]
          const numberOfSignal = parseInt(dividedPath[1].split(']')[0])
          const property = dividedPath[1].split(']')[1].substring(1)
          return update(modified, {
            [signals]: { [numberOfSignal]: { [property + 'ErrorMessage']: { $set: current.message } } }
          })
        }
      }, state)
      return newState

    case at.CONFIG_MANAGE_ALERT:
      return {
        ...state,
        ...action.payload
      }

    case at.SET_CONFIG:
      const { signals } = action.payload

      const signalsWithColor = signals.map(s => {
        const signal = s
        signal.signalColor = getRandomColor()
        return {
          ...initialSignal,
          ...signal
        }
      })

      return {
        ...state,
        ...initialValues(),
        ...action.payload,
        protocols: { J1939: { type: J1939.protocol, data: J1939.signals } },
        signals: signalsWithColor,
        alertVisibility: 'hidden',
        alertMessageTitle: '',
        alertMessageText: ''
      }

    case at.RESET_CONFIG:
      return initialValues()

    case ht.CAN_PROTOCOLS:
      return {
        ...state,
        isProtocolListLoading: true
      }

    case ht.success(ht.CAN_PROTOCOLS):
      const defaultProtocolListItems = state.protocolList.filter(
        protocol => protocol.type === PROTOCOL_TYPES.COMMON || protocol.type === PROTOCOL_TYPES.CUSTOM
      )
      const protocolList = action.payload.data
        ? action.payload.data.protocols.map(protocol => ({
          value: protocol,
          label: protocol,
          type: PROTOCOL_TYPES.OWN
        }))
        : []

      return {
        ...state,
        protocolList: [...defaultProtocolListItems, ...protocolList],
        isProtocolListLoading: false
      }

    case ht.fail(ht.CAN_PROTOCOLS):
      const defaultProtocolList = state.protocolList.filter(
        protocol => protocol.type === PROTOCOL_TYPES.COMMON || protocol.type === PROTOCOL_TYPES.CUSTOM
      )
      return {
        ...state,
        protocolList: [...defaultProtocolList],
        isProtocolListLoading: false
      }

    case ht.CAN_PROTOCOL:
      return {
        ...state,
        protocolLoading: true
      }

    case ht.success(ht.CAN_PROTOCOL):
      const url = action.payload.config.url
      const protocolName = url.substring(url.lastIndexOf('/') + 1, url.length)
      const protocolJSON = action.payload.data
      const protocolData = protocolJSON.signals ? protocolJSON.signals : protocolJSON
      const protocolType = protocolJSON.protocol ? protocolJSON.protocol : protocolJSON.PROTOCOL

      return {
        ...state,
        protocolLoading: false,
        protocols: {
          ...state.protocols,
          [protocolName]: {
            type: protocolType,
            data: protocolData
          }
        }
      }

    case ht.fail(ht.CAN_PROTOCOL):
      return {
        ...state,
        protocolLoading: false
      }

    default:
      return state
  }
}

export default reducer

const getState = state => state[c.NAME]
const getProtocolList = state => state[c.NAME].protocolList
const getIsProtocolListLoading = state => state[c.NAME].isProtocolListLoading
const getIsProtocolLoading = state => state[c.NAME].protocolLoading
const getSelectedCanProtocolData = (state, protocolName) =>
  protocolName && state[c.NAME].protocols[protocolName] ? state[c.NAME].protocols[protocolName].data : null
const getSelectedCanProtocolType = (state, protocolName) =>
  protocolName && state[c.NAME].protocols[protocolName] ? state[c.NAME].protocols[protocolName].type : null

export {
  getState,
  getIsProtocolListLoading,
  getIsProtocolLoading,
  getProtocolList,
  getSelectedCanProtocolData,
  getSelectedCanProtocolType
}
