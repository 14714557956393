import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'NewConfigurationCS500.title',
    description: 'Title text',
    defaultMessage: 'New configuration'
  },
  virtualSignals: {
    id: 'NewConfigurationCS500.virtualSignals',
    description: 'Virtual signals paper title',
    defaultMessage: 'Virtual signals'
  },
  sendCANMessages: {
    id: 'NewConfigurationCS500.sendCANMessages',
    description: 'CAN messages paper title',
    defaultMessage: 'Send CAN messages'
  },
  addSignal: {
    id: 'NewConfigurationCS500.addSignal',
    description: 'Add signal tooltip',
    defaultMessage: 'Add signal'
  },
  addCANMessage: {
    id: 'NewConfigurationCS500.addCANMessage',
    description: 'Add CAN message tooltip',
    defaultMessage: 'Add CAN message'
  },
  cancel: {
    id: 'NewConfigurationCS500.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  download: {
    id: 'NewConfigurationCS500.download',
    description: 'Download button label',
    defaultMessage: 'Download'
  },
  apply: {
    id: 'NewConfigurationCS500.apply',
    description: 'Apply button label',
    defaultMessage: 'Apply'
  },
  applying: {
    id: 'NewConfigurationCS500.applying',
    description: 'Applying configuration',
    defaultMessage: 'Applying configuration...'
  },
  selectDevice: {
    id: 'NewConfigurationCS500.selectDevice',
    description: 'Select devices',
    defaultMessage: 'Select devices to apply the configuration:'
  },
  applyConfiguration: {
    id: 'NewConfigurationCS500.applyConfiguration',
    description: 'Apply configuration',
    defaultMessage: 'Apply configuration'
  },
  loadConfigFromPC: {
    id: 'NewConfigurationCS500.loadConfigFromPC',
    description: 'Load configuration',
    defaultMessage: 'Load configuration'
  },
  selectFileFromPC: {
    id: 'NewConfigurationCS500.selectFileFromPC',
    description: 'Select file to import from PC:',
    defaultMessage: 'Select file to import from PC:'
  },
  browse: {
    id: 'NewConfigurationCS500.browse',
    description: 'Browse file',
    defaultMessage: 'Browse file'
  },
  loadedFile: {
    id: 'NewConfigurationCS500.loadedFile',
    description: 'Loaded file',
    defaultMessage: 'Loaded file:'
  },
  loadConfiguration: {
    id: 'NewConfigurationCS500.loadConfiguration',
    description: 'Load configuration',
    defaultMessage: 'Load configuration'
  },
  validConfigFile: {
    id: 'NewConfigurationCS500.validConfigFile',
    description: 'Valid configuration file',
    defaultMessage: 'Valid configuration file'
  },
  invalidConfigFile: {
    id: 'NewConfigurationCS500.invalidConfigFile',
    description: 'Invalid configuration file',
    defaultMessage: 'Invalid configuration file'
  },
  mustBeJson: {
    id: 'NewConfigurationCS500.mustBeJson',
    description: 'Selected file has not JSON format',
    defaultMessage: 'Selected file has not JSON format.'
  },
  invalidCS500config: {
    id: 'NewConfigurationCS500.invalidCS500config',
    description: 'Selected file is not is not a valid CS500 configuration',
    defaultMessage: 'Selected file is not is not a valid CS500 configuration.'
  },
  sameConfig: {
    id: 'NewConfigurationCS500.sameConfig',
    description: 'The configuration you are about to apply is already configured in the device',
    defaultMessage: 'The configuration you are about to apply is already configured in the device.'
  },
  thereAreNoCS500: {
    id: 'NewConfigurationCS500.thereAreNoCS500',
    description: 'There are no CS500 devices message',
    defaultMessage: 'There are no CS500 devices available.'
  },
  generalError: {
    id: 'NewConfigurationCS500.generalError',
    description: 'Error',
    defaultMessage: 'Error:'
  },
  generalErrorDescriptionApply: {
    id: 'NewConfigurationCS500.generalErrorDescriptionApply',
    description: 'General error description',
    defaultMessage:
      'There are some errors in the configuration. Please, correct them before updating the configuration.'
  },
  generalErrorDescriptionDownload: {
    id: 'NewConfigurationCS500.generalErrorDescriptionDownload',
    description: 'General error description',
    defaultMessage:
      'There are some errors in the configuration. Please, correct them before downloading the configuration.'
  },
  error400Message: {
    id: 'NewConfigurationCS500.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'NewConfigurationCS500.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'NewConfigurationCS500.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found'
  },
  error406Message: {
    id: 'NewConfigurationCS500.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'NewConfigurationCS500.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'NewConfigurationCS500.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error409Message: {
    id: 'NewConfigurationCS500.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: Configuration name it\'s already in use'
  },
  error415Message: {
    id: 'NewConfigurationCS500.error415Message',
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: 'NewConfigurationCS500.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error: {
    id: 'NewConfigurationCS500.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'NewConfigurationCS500.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error500Message: {
    id: 'NewConfigurationCS500.error500Message',
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error'
  },
  configurationUpdate: {
    id: 'NewConfigurationCS500.configurationUpdate',
    description: 'Configuration update',
    defaultMessage: 'Configuration update'
  },
  devicesToUpdate: {
    id: 'NewConfigurationCS500.devicesToUpdate',
    description: 'Devices to update',
    defaultMessage: 'Devices to update:'
  },
  nvConfigurationUpdatedSuccessfully: {
    id: 'NewConfigurationCS500.nvConfigurationUpdatedSuccessfully',
    description: 'Configuration successfully applied',
    defaultMessage: 'Configuration successfully applied.'
  },
  waiting: {
    id: 'NewConfigurationCS500.waiting',
    description: 'Waiting',
    defaultMessage: 'Waiting...'
  },
  firmware: {
    id: 'NewConfigurationCS500.firmware',
    defaultMessage: 'firmware'
  },
  firmwareVersionAlert: {
    id: 'NewConfigurationCS500.firmwareVersionAlert',
    defaultMessage: 'This configuration template uses aplication {firmware} {version}'
  }
})

export default messages
