import { connect } from 'react-redux'

import { utils as groupUtils } from 'ducks/groups'
import { utils as notificationsUtils } from 'ducks/notifications'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import Notifications from './Notifications'

const mapStateToProps = state => ({
  privileges: {
    canViewAndEditNotifications:
      resolvePermissions(state, c.NOTIFICATION_READ) && resolvePermissions(state, c.NOTIFICATION_EDIT)
  },
  secondaryGlobalQuery: '',
  notificationsTotalsReceivedData: notificationsUtils.getNotificationsTotalObject(state),
  currentGroupId: groupUtils.getSelectedGroup(state).id
})

export default connect(mapStateToProps)(Notifications)
