import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import messages from './messages'

const PositionShape = {
  longitude: PropTypes.number,
  latitude: PropTypes.number
}

const Position = PropTypes.shape(PositionShape)

const TimedPosition = PropTypes.shape({
  ...PositionShape,
  timestamp: PropTypes.number
})

const MapProps = {
  containerElement: PropTypes.node,
  defaultCenter: Position,
  zoom: PropTypes.number,
  onZoomChanged: PropTypes.func,
  onCenterChanged: PropTypes.func,
  onClick: PropTypes.func
}

// Rectangle with no rotation
const SimplifiedBounds = PropTypes.shape({
  ne: Position,
  sw: Position
})

const QuadrilateralBounds = injectIntl((props, propName, componentName, intl) => {
  const { formatMessage } = intl
  if (props[propName]) {
    const QuadrilateralBoundsProps = {
      [propName]: PropTypes.arrayOf(Position)
    }

    PropTypes.checkPropTypes(QuadrilateralBoundsProps, props[propName], propName, componentName)

    if (props[propName].length !== 4) {
      return new Error(formatMessage(messages.error, { propName, componentName }))
    }
  }
  // TODO check somehow if it is required!
})

const Bounds = PropTypes.oneOfType([SimplifiedBounds, QuadrilateralBounds])

const OverlayImageProps = {
  url: PropTypes.string.isRequired,
  bounds: Bounds.isRequired
}

const OverlayImage = PropTypes.shape(OverlayImageProps)

const Polygon = PropTypes.shape({
  color: PropTypes.string,
  path: PropTypes.arrayOf(Position)
})

const ExtendedMapProps = {
  ...MapProps,
  images: PropTypes.arrayOf(OverlayImage),
  polygons: PropTypes.arrayOf(Polygon),
  lines: PropTypes.arrayOf(PropTypes.arrayOf(Position))
}

const MarkerProps = {
  position: Position,
  name: PropTypes.string,
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  zIndex: PropTypes.number,
  onClick: PropTypes.func
}

const Marker = PropTypes.shape(MarkerProps)

const LocationViewerProps = {
  position: Position,
  label: PropTypes.string
}

const LocationSelectorProps = {
  value: Position,
  onChange: PropTypes.func,
  // Not really used nowadays
  extraLocations: PropTypes.arrayOf(Position)
}

const LocationTrackerProps = {
  ...MapProps,
  positions: PropTypes.arrayOf(TimedPosition),
  highlighted: PropTypes.number
}

export {
  MapProps,
  ExtendedMapProps,
  Bounds,
  QuadrilateralBounds,
  Position,
  TimedPosition,
  Marker,
  MarkerProps,
  OverlayImage,
  OverlayImageProps,
  LocationViewerProps,
  LocationSelectorProps,
  LocationTrackerProps
}
