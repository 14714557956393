import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import ControlPointDuplicateOutlinedIcon from '@material-ui/icons/ControlPointDuplicateOutlined'
import ErrorIcon from '@material-ui/icons/Error'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import messages from './messages'
import { getOptionsForCANMessageItem } from '../config'
import { SEND_CAN_MESSAGES_FIELDS } from '../constants'

const styles = theme => ({
  errorButton: {
    color: 'red'
  },
  expand: {
    transform: 'rotate(0deg)'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
    paddingLeft: 16
  },
  root: {
    padding: 0
  },
  action: {
    margin: 0
  },
  actionsRoot: {
    paddingRight: 1
  },
  contentRoot: {
    padding: 16
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  },
  button: {
    margin: 0
  },
  checkbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  checked: {},
  gridContainer: {
    padding: 8
  }
})

const SendCANMessages = ({
  byte0,
  byte0ErrorMessage,
  byte1,
  byte1ErrorMessage,
  byte2,
  byte2ErrorMessage,
  byte3,
  byte3ErrorMessage,
  byte4,
  byte4ErrorMessage,
  byte5,
  byte5ErrorMessage,
  byte6,
  byte6ErrorMessage,
  byte7,
  byte7ErrorMessage,
  canId,
  canIdErrorMessage,
  canMessageId,
  canPort,
  canPortErrorMessage,
  classes,
  dataSource,
  dataSourceErrorMessage,
  dataLength,
  dataLengthErrorMessage,
  expanded,
  handleFieldChange,
  handleMessageCopy,
  handleMessageDelete,
  intl,
  isEditable,
  isExtendedCanId,
  lowerThreshold,
  lowerThresholdErrorMessage,
  noErrorInSendCANMessage,
  periodType,
  periodTypeErrorMessage,
  periodTypeTriggered,
  periodTypeTriggeredErrorMessage,
  sendCANMessageStatus,
  sendPeriod,
  sendPeriodErrorMessage,
  sendPeriodTriggered,
  sendPeriodTriggeredErrorMessage,
  setValuePulse,
  signalDest,
  thresholdDivider,
  thresholdDividerErrorMessage,
  triggerSource,
  triggerSourceErrorMessage,
  triggerType,
  triggerTypeErrorMessage,
  upperThreshold,
  upperThresholdErrorMessage,
  virtualSignalNumber,
  virtualSignalNumberErrorMessage,
  isLegacyVersion
}) => {
  const { formatMessage } = intl
  const optionsForCANMessageItemSelects = getOptionsForCANMessageItem()

  const handleHexadecimalValueChange = (messageId, variableId, event) => {
    const twoHexByte = /[^[0-9a-fA-F]$/
    handleFieldChange(messageId, variableId, event.target.value.replace(twoHexByte, ''))
  }

  const isDataLengthVisible = !isLegacyVersion && dataSource === 255 && signalDest === 2
  const numOfBytes = isDataLengthVisible ? dataLength : dataSource === 255 ? 8 : 0

  const renderByte = (byteValue, byteErrorMessage, number) =>
    isEditable ? (
      <Grid item xs={6}>
        <FormControl error={byteErrorMessage !== ''} style={{ width: '100%' }}>
          <InputLabel>{formatMessage(messages.byte, { number })}</InputLabel>
          <Input
            id={SEND_CAN_MESSAGES_FIELDS['byte' + number]}
            inputProps={{ maxLength: 2 }}
            onChange={event =>
              handleHexadecimalValueChange(canMessageId, SEND_CAN_MESSAGES_FIELDS['byte' + number], event)
            }
            placeholder=' [0x00 - 0xFF]'
            startAdornment={renderStartAdornment()}
            value={byteValue}
          />
          <FormHelperText>{byteErrorMessage}</FormHelperText>
        </FormControl>
      </Grid>
    ) : (
      <Grid item xs={6}>
        <Typography>{formatMessage(messages.byte, { number })}</Typography>
        <label>{'0x' + byteValue}</label>
      </Grid>
    )

  const renderStartAdornment = () => (
    <InputAdornment position='start' style={{ marginRight: 2 }}>
      <Typography
        style={{
          fontSize: 16,
          fontWeight: 'bold',
          color: 'black'
        }}
      >
        0x
      </Typography>
    </InputAdornment>
  )

  return (
    <Card elevation={0} style={{ borderRadius: 0 }}>
      <CardHeader
        action={
          <CardActions classes={{ root: classes.actionsRoot }}>
            {!noErrorInSendCANMessage && isEditable && (
              <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.error)}>
                <IconButton className={classes.errorButton}>
                  <ErrorIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            {!isEditable && (
              <React.Fragment>
                {sendCANMessageStatus === 2 && (
                  <Typography style={{ color: 'green', paddingRight: 10 }}>
                    {formatMessage(messages.messageSentSuccessfully)}
                  </Typography>
                )}
                {sendCANMessageStatus === 3 && (
                  <Typography style={{ color: 'red', paddingRight: 10 }}>
                    {formatMessage(messages.messageSsendingFailed)}
                  </Typography>
                )}
                <Button
                  className='primary-action-button'
                  disabled={sendCANMessageStatus === 1}
                  onClick={() => setValuePulse(canMessageId)}
                >
                  {sendCANMessageStatus === 1 && <CircularProgress size={24} style={{ position: 'absolute' }} />}
                  {formatMessage(messages.sendMessage)}
                </Button>
              </React.Fragment>
            )}

            {isEditable && (
              <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.duplicateMessage)}>
                <IconButton
                  classes={{ root: classes.button }}
                  onClick={() => {
                    handleMessageCopy(canMessageId)
                  }}
                >
                  <ControlPointDuplicateOutlinedIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}

            {isEditable && (
              <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.deleteMessage)}>
                <IconButton
                  classes={{ root: classes.button }}
                  onClick={() => {
                    handleMessageDelete(canMessageId)
                  }}
                >
                  <ClearIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={expanded ? formatMessage(messages.collapse) : formatMessage(messages.expand)}
            >
              <IconButton
                classes={{ root: classes.button }}
                onClick={() => {
                  handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.expanded, !expanded)
                }}
              >
                {expanded ? (
                  <ExpandMoreIcon className={(classes.expand, classes.expandOpen)} fontSize='small' />
                ) : (
                  <ExpandMoreIcon className={classes.expand} fontSize='small' />
                )}
              </IconButton>
            </Tooltip>
          </CardActions>
        }
        classes={{
          title: classes.title,
          root: classes.root,
          action: classes.action
        }}
        title={formatMessage(messages.message) + ' ' + (canMessageId + 1)}
      />
      <Collapse in={expanded}>
        <CardContent
          classes={{
            root: classes.contentRoot
          }}
          style={{ padding: 16 }}
        >
          <Typography style={{ fontWeight: 'bold' }}>{formatMessage(messages.messageSettings)}</Typography>
          <Divider style={{ backgroundColor: 'black', margin: '0 0 20px 0' }} />
          <Grid container spacing={2}>
            {/* PORT */}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={canPortErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.canPort)}</InputLabel>
                  <Select
                    name={SEND_CAN_MESSAGES_FIELDS.canPort + '-' + canMessageId}
                    onChange={event => {
                      handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.canPort, event.target.value)
                    }}
                    value={canPort}
                  >
                    {optionsForCANMessageItemSelects.canPort.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{canPortErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.canPort)}</Typography>
                <label>{canPort}</label>
              </Grid>
            )}
            {/* Extended CAN ID */}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isExtendedCanId}
                      classes={{ root: classes.checkbox, checked: classes.checked }}
                      onClick={event => {
                        handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.isExtendedCanId, event.target.checked)
                      }}
                    />
                  }
                  label={formatMessage(messages.isExtendedCanId)}
                  style={{ marginTop: '15px', marginBottom: 0 }}
                />
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.isExtendedCanId)}</Typography>
                <label>{isExtendedCanId ? 'True' : 'False'}</label>
              </Grid>
            )}
            {/* CAN ID */}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={canIdErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel> {formatMessage(messages.canId)}</InputLabel>
                  <Input
                    id={SEND_CAN_MESSAGES_FIELDS.canId + '-' + canMessageId}
                    inputProps={{ maxLength: 8 }}
                    onChange={event =>
                      handleHexadecimalValueChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.canId, event)
                    }
                    placeholder={isExtendedCanId ? ' [0x00 - 0x1FFFFFFF]' : ' [0x00 - 0x1FF]'}
                    startAdornment={renderStartAdornment()}
                    value={canId}
                  />
                  <FormHelperText>{canIdErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.canId)}</Typography>
                <label>{canId}</label>
              </Grid>
            )}
            {/* Data source */}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={dataSourceErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.dataSource)}</InputLabel>
                  <Select
                    name={SEND_CAN_MESSAGES_FIELDS.dataSource + '-' + canMessageId}
                    onChange={event => {
                      handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.dataSource, event.target.value)
                    }}
                    value={dataSource}
                  >
                    {optionsForCANMessageItemSelects.dataSource.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{dataSourceErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.dataSource)}</Typography>
                <label>{optionsForCANMessageItemSelects.dataSource.find(ds => ds.value === dataSource)?.label}</label>
              </Grid>
            )}
            {isEditable && isDataLengthVisible ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={dataLengthErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.dataLength)}</InputLabel>
                  <Input
                    id={SEND_CAN_MESSAGES_FIELDS.dataLength + '-' + canMessageId}
                    inputProps={{ maxLength: 1, min: 1, max: 8 }}
                    onChange={event =>
                      handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.dataLength, event.target.value)
                    }
                    type='number'
                    value={dataLength}
                  />
                  <FormHelperText>{dataLengthErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : isDataLengthVisible ? (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.dataLength)}</Typography>
                <label>{dataLength}</label>
              </Grid>
            ) : null}
            {/* Virtual signal number: only if dataSource == 'Select virtual signal' */}
            {isEditable && dataSource === 0 ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={virtualSignalNumberErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.virtualSignalNumber)}</InputLabel>
                  <Input
                    id={SEND_CAN_MESSAGES_FIELDS.virtualSignalNumber + '-' + canMessageId}
                    inputProps={{ min: 0, max: 79 }}
                    onChange={event => {
                      handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.virtualSignalNumber, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    placeholder={formatMessage(messages.virtualSignalNumberPlaceholder)}
                    type='number'
                    value={virtualSignalNumber}
                  />
                  <FormHelperText>{virtualSignalNumberErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : 
              dataSource === 0 && (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.virtualSignalNumber)}</Typography>
                  <label>{virtualSignalNumber}</label>
                </Grid>
              )
            }
            {/* Data source == 0 (Select virtual signal) explanation */}
            {isEditable && dataSource === 0 && (
              <Grid item lg={9} md={8} sm={6} style={{ paddingTop: 16 }}>
                <div style={{ padding: 8, border: '1px solid #949494' }}>
                  <Typography style={{ paddingBottom: 0 }}>
                    {formatMessage(messages.dataSourceVirtualSignalMessage)}
                  </Typography>
                </div>
              </Grid>
            )}
            {/* Data source == 240 (GNSS position) explanation */}
            {isEditable && dataSource === 240 && (
              <Grid item lg={12} md={12} sm={12} style={{ paddingTop: 16 }}>
                <div style={{ padding: 8, border: '1px solid #949494' }}>
                  <Typography style={{ paddingBottom: 0 }}>{formatMessage(messages.dataSourceGNSSMessage)}</Typography>
                </div>
              </Grid>
            )}
            {/* Bytes: only if dataSource == 'Set bytes directly' */}
            {dataSource === 255 && (
              <Grid classes={{ container: classes.gridContainer }} container spacing={3}>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <Grid container spacing={3}>
                    {numOfBytes > 0 && renderByte(byte0, byte0ErrorMessage, 0)}
                    {numOfBytes > 1 && renderByte(byte1, byte1ErrorMessage, 1)}
                  </Grid>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <Grid container spacing={3}>
                    {numOfBytes > 2 && renderByte(byte2, byte2ErrorMessage, 2)}
                    {numOfBytes > 3 && renderByte(byte3, byte3ErrorMessage, 3)}
                  </Grid>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <Grid container spacing={3}>
                    {numOfBytes > 4 && renderByte(byte4, byte4ErrorMessage, 4)}
                    {numOfBytes > 5 && renderByte(byte5, byte5ErrorMessage, 5)}
                  </Grid>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <Grid container spacing={3}>
                    {numOfBytes > 6 && renderByte(byte6, byte6ErrorMessage, 6)}
                    {numOfBytes > 7 && renderByte(byte7, byte7ErrorMessage, 7)}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {triggerType !== 3 && triggerType !== 4 && triggerType !== 8 && (
            <Typography style={{ fontWeight: 'bold', paddingTop: 25 }}>
              {formatMessage(messages.transmitTimings)}
            </Typography>
          )}
          {triggerType !== 3 && triggerType !== 4 && triggerType !== 8 && (
            <Divider style={{ backgroundColor: 'black', margin: '0 0 20px 0' }} />
          )}
          {triggerType !== 3 && triggerType !== 4 && triggerType !== 8 && (
            <Grid container spacing={2}>
              {/* Period type */}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={periodTypeErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>{formatMessage(messages.periodType)}</InputLabel>
                    <Select
                      name={SEND_CAN_MESSAGES_FIELDS.periodType + '-' + canMessageId}
                      onChange={event => {
                        handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.periodType, event.target.value)
                      }}
                      value={periodType}
                    >
                      {optionsForCANMessageItemSelects.periodType.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{periodTypeErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.periodType)}</Typography>
                  <label>{optionsForCANMessageItemSelects.periodType.find(pt => pt.value === periodType)?.label}</label>
                </Grid>
              )}
              {/* Transmit period: only if period type == 'Set sample period' */}
              {isEditable && periodType === 1 ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={sendPeriodErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel> {formatMessage(messages.sendPeriod)}</InputLabel>
                    <Input
                      id={SEND_CAN_MESSAGES_FIELDS.sendPeriod + '-' + canMessageId}
                      onChange={event => {
                        handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.sendPeriod, event.target.value)
                      }}
                      placeholder='(ms)'
                      value={sendPeriod}
                    />
                    <FormHelperText>{sendPeriodErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : 
                periodType === 1 && (
                  <Grid item lg={1} md={2} sm={3} xs={4}>
                    <Typography>{formatMessage(messages.sendPeriod)}</Typography>
                    <label>{sendPeriod}</label>
                  </Grid>
                )
              }
            </Grid>
          )}
          <Typography style={{ fontWeight: 'bold', paddingTop: 25 }}>{formatMessage(messages.trigger)}</Typography>
          <Divider style={{ backgroundColor: 'black', margin: '0 0 20px 0' }} />
          <Grid container spacing={2}>
            {/* Trigger type */}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={triggerTypeErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.triggerType)}</InputLabel>
                  <Select
                    name={SEND_CAN_MESSAGES_FIELDS.triggerType + '-' + canMessageId}
                    onChange={event => {
                      handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.triggerType, event.target.value)
                    }}
                    value={triggerType}
                  >
                    {optionsForCANMessageItemSelects.triggerType.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{triggerTypeErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.triggerType)}</Typography>
                <label>{optionsForCANMessageItemSelects.triggerType.find(tt => tt.value === triggerType)?.label}</label>
              </Grid>
            )}
            {/* Trigger source */}
            {isEditable && triggerType !== 0 ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={triggerSourceErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.triggerSource)}</InputLabel>
                  <Input
                    id={SEND_CAN_MESSAGES_FIELDS.triggerSource + '-' + canMessageId}
                    inputProps={{ min: 0, max: 79 }}
                    onChange={event => {
                      handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.triggerSource, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    placeholder={formatMessage(messages.virtualSignalNumberPlaceholder)}
                    type='number'
                    value={triggerSource}
                  />
                  <FormHelperText>{triggerSourceErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : 
              triggerType !== 0 && (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.triggerSource)}</Typography>
                  <label>{triggerSource}</label>
                </Grid>
              )
            }
            {/* Trigger upper threshold */}
            {isEditable && triggerType !== 0 && triggerType !== 8 ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={upperThresholdErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.upperThreshold)}</InputLabel>
                  <Input
                    id={SEND_CAN_MESSAGES_FIELDS.upperThreshold + '-' + canMessageId}
                    inputProps={{ min: 0, max: 79 }}
                    onChange={event => {
                      handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.upperThreshold, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    type='number'
                    value={upperThreshold}
                  />
                  <FormHelperText>{upperThresholdErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : 
              triggerType !== 0 &&
              triggerType !== 8 && (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.upperThreshold)}</Typography>
                  <label>{upperThreshold}</label>
                </Grid>
              )
            }
            {/* Trigger lower threshold */}
            {isEditable && (triggerType === 5 || triggerType === 6) ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={lowerThresholdErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.lowerThreshold)}</InputLabel>
                  <Input
                    id={SEND_CAN_MESSAGES_FIELDS.lowerThreshold + '-' + canMessageId}
                    inputProps={{ min: 0, max: 79 }}
                    onChange={event => {
                      handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.lowerThreshold, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    type='number'
                    value={lowerThreshold}
                  />
                  <FormHelperText>{lowerThresholdErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : 
              (triggerType === 5 || triggerType === 6) && (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.lowerThreshold)}</Typography>
                  <label>{lowerThreshold}</label>
                </Grid>
              )
            }
            {/* Trigger threshold divider */}
            {isEditable && triggerType !== 8 && triggerType !== 0 ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={thresholdDividerErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.thresholdDivider)}</InputLabel>
                  <Input
                    id={SEND_CAN_MESSAGES_FIELDS.thresholdDivider + '-' + canMessageId}
                    inputProps={{ min: 0, max: 79 }}
                    onChange={event => {
                      handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.thresholdDivider, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    type='number'
                    value={thresholdDivider}
                  />
                  <FormHelperText>{triggerSourceErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : 
              triggerType !== 0 &&
              triggerType !== 8 && (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.thresholdDivider)}</Typography>
                  <label>{thresholdDivider}</label>
                </Grid>
              )
            }
            {/* Period type triggered */}
            {isEditable && triggerType !== 0 && triggerType !== 3 && triggerType !== 4 && triggerType !== 8 ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={periodTypeTriggeredErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.periodTypeTriggered)}</InputLabel>
                  <Select
                    name={SEND_CAN_MESSAGES_FIELDS.periodTypeTriggered + '-' + canMessageId}
                    onChange={event => {
                      handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.periodTypeTriggered, event.target.value)
                    }}
                    value={periodTypeTriggered}
                  >
                    {optionsForCANMessageItemSelects.periodType.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{periodTypeTriggeredErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : 
              triggerType !== 0 &&
              triggerType !== 3 &&
              triggerType !== 4 &&
              triggerType !== 8 && (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.periodTypeTriggered)}</Typography>
                  <label>
                    {optionsForCANMessageItemSelects.periodType.find(pt => pt.value === periodTypeTriggered)?.label}
                  </label>
                </Grid>
              )
            }
            {/* Transmit period triggered: only if period type triggered == 'Set sample period' */}
            {isEditable &&
            periodTypeTriggered === 1 &&
            triggerType !== 0 &&
            triggerType !== 3 &&
            triggerType !== 4 &&
            triggerType !== 8 ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={sendPeriodTriggeredErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel> {formatMessage(messages.sendPeriodTriggered)}</InputLabel>
                    <Input
                      id={SEND_CAN_MESSAGES_FIELDS.sendPeriodTriggered + '-' + canMessageId}
                      onChange={event => {
                        handleFieldChange(canMessageId, SEND_CAN_MESSAGES_FIELDS.sendPeriodTriggered, event.target.value)
                      }}
                      placeholder='(ms)'
                      value={sendPeriodTriggered}
                    />
                    <FormHelperText>{sendPeriodTriggeredErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : 
              periodTypeTriggered === 1 &&
              triggerType !== 0 &&
              triggerType !== 3 &&
              triggerType !== 4 &&
              triggerType !== 8 && (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.sendPeriodTriggered)}</Typography>
                  <label>{sendPeriodTriggered}</label>
                </Grid>
              )
            }
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  )
}

SendCANMessages.propTypes = {
  byte0: PropTypes.string.isRequired,
  byte0ErrorMessage: PropTypes.string.isRequired,
  byte1: PropTypes.string.isRequired,
  byte1ErrorMessage: PropTypes.string.isRequired,
  byte2: PropTypes.string.isRequired,
  byte2ErrorMessage: PropTypes.string.isRequired,
  byte3: PropTypes.string.isRequired,
  byte3ErrorMessage: PropTypes.string.isRequired,
  byte4: PropTypes.string.isRequired,
  byte4ErrorMessage: PropTypes.string.isRequired,
  byte5: PropTypes.string.isRequired,
  byte5ErrorMessage: PropTypes.string.isRequired,
  byte6: PropTypes.string.isRequired,
  byte6ErrorMessage: PropTypes.string.isRequired,
  byte7: PropTypes.string.isRequired,
  byte7ErrorMessage: PropTypes.string.isRequired,
  canId: PropTypes.string.isRequired,
  canIdErrorMessage: PropTypes.string.isRequired,
  canMessageId: PropTypes.number.isRequired,
  canPort: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  canPortErrorMessage: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  dataLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dataLengthErrorMessage: PropTypes.string.isRequired,
  dataSource: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dataSourceErrorMessage: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleMessageCopy: PropTypes.func.isRequired,
  handleMessageDelete: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isExtendedCanId: PropTypes.bool.isRequired,
  isLegacyVersion: PropTypes.bool.isRequired,
  lowerThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lowerThresholdErrorMessage: PropTypes.string.isRequired,
  noErrorInSendCANMessage: PropTypes.bool,
  periodType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  periodTypeErrorMessage: PropTypes.string.isRequired,
  periodTypeTriggered: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  periodTypeTriggeredErrorMessage: PropTypes.string.isRequired,
  sendCANMessageStatus: PropTypes.number,
  sendPeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sendPeriodErrorMessage: PropTypes.string.isRequired,
  sendPeriodTriggered: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sendPeriodTriggeredErrorMessage: PropTypes.string.isRequired,
  setValuePulse: PropTypes.func,
  signalDest: PropTypes.number.isRequired,
  thresholdDivider: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  thresholdDividerErrorMessage: PropTypes.string.isRequired,
  triggerSource: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  triggerSourceErrorMessage: PropTypes.string.isRequired,
  triggerType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  triggerTypeErrorMessage: PropTypes.string.isRequired,
  upperThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  upperThresholdErrorMessage: PropTypes.string.isRequired,
  virtualSignalNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  virtualSignalNumberErrorMessage: PropTypes.string.isRequired
}

SendCANMessages.defaultProps = {
  noErrorInSendCANMessage: true,
  sendCANMessageStatus: 0,
  setValuePulse: () => {}
}

export default withStyles(styles)(injectIntl(SendCANMessages))
