import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'redux-axios-middleware'

// Methods to generate action types used in external reducers
export const success = actionType => actionType + SUCCESS_SUFFIX
export const fail = actionType => actionType + ERROR_SUFFIX

const PREFIX = 'http/'
export const CLEAR_STALE_ENTRIES = PREFIX + 'CLEAR_STALE_ENTRIES'

export const LOAD_PROFILE = PREFIX + 'LOAD_PROFILE'
export const LOGOUT = PREFIX + 'LOGOUT'
export const THEME = PREFIX + 'THEME'
export const ACTIVITY_TYPES = PREFIX + 'ACTIVITY_TYPES'
export const ACTIVITIES = PREFIX + 'ACTIVITIES'
export const SET_LANGUAGE = 'SET_LANGUAGE'

// S3
export const LOAD_S3_BUCKET = PREFIX + 'LOAD_S3_BUCKET'
export const DOWNLOAD_CONFIGFILE = PREFIX + 'DOWNLOAD_CONFIG_FILE'
export const DOWNLOAD_MAPPINGFILE = PREFIX + 'DOWNLOAD_MAPPING_FILE'
export const UPDATE_DATALOGGER_CONFIG = PREFIX + 'UPDATE_DATALOGGER_CONFIG'
export const UPDATE_DATALOGGER_SW = PREFIX + 'UPDATE_DATALOGGER_SW'
export const UPDATE_DATALOGGER_SW_PC = PREFIX + 'UPDATE_DATALOGGER_SW_PC'

// Node management
export const DOWNLOAD_XML_FILE = PREFIX + 'DOWNLOAD_XML_FILE'

// Eeprom WRITER
export const WRITE_EEPROM = PREFIX + 'WRITE_EEPROM'

// Node management
export const NODE = PREFIX + 'NODE'

// Groups
export const GROUP_HIERARCHY = PREFIX + 'GROUP_HIERARCHY'

// Node specific details
export const HISTORIC = PREFIX + 'HISTORIC'
export const HISTORIC_DISCONNECTION = PREFIX + 'HISTORIC_DISCONNECTION'
export const HISTORIC_RAW = PREFIX + 'HISTORIC_RAW'
export const DOWNLOAD_RAW = PREFIX + 'DOWNLOAD_RAW'
export const ARCHIVED_RAW = PREFIX + 'ARCHIVED_RAW'

// Geofences
export const GEOFENCES = PREFIX + 'GEOFENCES'
export const GEOFENCE = PREFIX + 'GEOFENCE'
export const CREATE_GEOFENCE = PREFIX + 'CREATE_GEOFENCE'
export const EDIT_GEOFENCE = PREFIX + 'EDIT_GEOFENCE'
export const DELETE_GEOFENCE = PREFIX + 'DELETE_GEOFENCE'

// Rule instances
export const RULES = PREFIX + 'RULES'
export const RULE = PREFIX + 'RULE'
export const RULE_INSTANCES = PREFIX + 'RULE_INSTANCES'
export const RULE_INSTANCE = PREFIX + 'RULE_INSTANCE'
export const CREATE_RULE_INSTANCE = PREFIX + 'CREATE_RULE_INSTANCE'
export const EDIT_RULE_INSTANCE = PREFIX + 'EDIT_RULE_INSTANCE'
export const DELETE_RULE_INSTANCE = PREFIX + 'DELETE_RULE_INSTANCE'

// CSxxx nodes
export const PLUS1CONNECT_LOGIN = PREFIX + 'PLUS1CONNECT_LOGIN'
export const CS_NODE = PREFIX + 'CS_NODE'
export const CS_NODES_DETAIL = PREFIX + 'CS_NODES_DETAIL'
export const CS_NODES_DYNAMIC = PREFIX + 'CS_NODES_DYNAMIC'
export const CS_NODE_LOCATION = PREFIX + 'CS_NODE_LOCATION'
export const CS_NODES_LOCATION = PREFIX + 'CS_NODES_LOCATION'
export const CS_NODES_AND_LOCATIONS = PREFIX + 'CS_NODES_AND_LOCATIONS'
export const CS_NODES_LOCATIONS_NOTIFICATIONS_DYNAMIC = PREFIX + 'CS_NODES_LOCATIONS_NOTIFICATIONS_DYNAMIC'
export const CS_NODE_AND_LOCATION = PREFIX + 'CS_NODE_AND_LOCATION'
export const CS_NODE_TOKEN = PREFIX + 'CS_NODE_TOKEN'
export const CS_DEVICE = PREFIX + 'CS_DEVICE'
export const CS_NODE_ACTIVATE = PREFIX + 'CS_NODE_ACTIVATE'
export const CS_NODE_UNREGISTER = PREFIX + 'CS_NODE_UNREGISTER'
export const CS_FLEET = PREFIX + 'CS_FLEET'
export const CS_FOTA = PREFIX + 'CS_FOTA'
export const CS_FW_UPDATE = PREFIX + 'CS_FW_UPDATE'
export const CS_CONF_UPDATE = PREFIX + 'CS_CONF_UPDATE'
export const CS_NODE_EID = PREFIX + 'CS_NODE_EID'
export const CS_NODE_TYPE = PREFIX + 'CS_NODE_TYPE'
export const CS_NODE_UPDATE = PREFIX + 'UPDATE'
export const CS_NODE_SIM_ACTIVATE = 'CS_NODE_SIM_ACTIVATE'
export const CS_NODE_SIM_STATUS = 'CS_NODE_SIM_STATUS'
export const CS_NODE_METADATA = 'CS_NODE_METADATA'
export const CS_NODE_SIM_TEST = 'CS_NODE_SIM_TEST'
export const CS_NODE_SIM_PAUSE = 'CS_NODE_SIM_PAUSE'
export const CS_NODE_GET_NV_CONFIGURATION = 'CS_NODE_GET_NV_CONFIGURATION'
export const CS_NODE_SET_NV_CONFIGURATION = 'CS_NODE_SET_NV_CONFIGURATION'
export const CS_NODE_GET_NV_CONFIGURATION_BY_HASH = 'CS_NODE_GET_NV_CONFIGURATION_BY_HASH'
export const GET_NEWEST_NV_CONFIGURATION = 'GET_NEWEST_NV_CONFIGURATION'

// Report Schedules
export const SCHEDULE = PREFIX + 'SCHEDULE'

// Configurations
export const CONFIGURATION = PREFIX + 'CONFIGURATION'

// CAN protocols
export const CAN_PROTOCOLS = PREFIX + 'CAN_PROTOCOLS'
export const CAN_PROTOCOL = PREFIX + 'CAN_PROTOCOL'
export const DELETE_CAN_PROTOCOL = PREFIX + 'DELETE_CAN_PROTOCOL'
export const UPLOAD_DBC_FILE = PREFIX + 'UPLOAD_DBC_FILE'

// SSO
export const SSO_DASHBOARDS = PREFIX + 'SSO_DASHBOARDS'

//Digital Service Plans
export const DATA_PLAN = PREFIX + 'DATA_PLAN'

// Devices patch
export const DEVICES_PATCH = PREFIX + 'DEVICES_PATCH'

// Pune groups
export const GROUPS = PREFIX + 'GROUPS'
export const GROUP_USERS = PREFIX + 'GROUP_USERS'
export const GROUP_USERS_DETAIL = PREFIX + 'GROUP_USERS_DETAIL'
export const GROUP_CREATE = PREFIX + 'GROUP_CREATE'
export const GROUP_UPDATE = PREFIX + 'GROUP_UPDATE'
export const GROUP_DELETE = PREFIX + 'GROUP_DELETE'
export const GROUP_ADD_USERS = PREFIX + 'GROUP_ADD_USERS'
export const GROUP_REMOVE_USERS = PREFIX + 'GROUP_REMOVE_USERS'
export const GROUP_ADD_DEVICES = PREFIX + 'GROUP_ADD_DEVICES'
export const GROUP_REMOVE_DEVICES = PREFIX + 'GROUP_REMOVE_DEVICES'
export const GROUP_USER_DETAILS = PREFIX + 'GROUP_USER_DETAILS'
export const GROUP_ALL_ROLES = PREFIX + 'GROUP_ALL_ROLES'
export const GROUP_CREATE_ROLE = PREFIX + 'GROUP_CREATE_ROLE'
export const GROUP_ROLE_DETAILS = PREFIX + 'GROUP_ROLE_DETAILS'
export const GROUP_UPDATE_ROLE = PREFIX + 'GROUP_UPDATE_ROLE'
export const GROUP_ASSIGN_ROLE = PREFIX + 'GROUP_ASSIGN_ROLE'
export const GROUP_DETAILED_USERS = PREFIX + 'GROUP_DETAILED_USERS'
export const GROUP_DELETE_ROLE = PREFIX + 'GROUP_DELETE_ROLE'
export const GROUP_ADD_USER_ROLE = PREFIX + 'GROUP_ADD_USER_ROLE'

// Users
export const USER_UPDATE_CONSENT = PREFIX + 'USER_UPDATE_CONSENT'

// Access Passes
export const ACCESS_PASS = 'ACCESS_PASS'

// Set value pulse
export const SET_VALUE_PULSE = 'SET_VALUE_PULSE'

// Advanced signals
export const GET_MEASUREMENTS = 'GET_MEASUREMENTS'
export const GET_MEASUREMENT_RESULTS = 'GET_MEASUREMENT_RESULTS'
export const GET_MEASUREMENT_RESULTS_TO_EXPORT = 'GET_MEASUREMENT_RESULTS_TO_EXPORT'

//
export const GET_SENT_CAN_MESSAGES = 'GET_SENT_CAN_MESSAGES'
