import PropTypes from 'prop-types'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Route, Switch } from 'react-router-dom'

import Error404 from 'components/error404'

import Notifications from './Notifications'
import NotificationsDetailMobile from './NotificationsDetailMobile'
import { NOTIFICATIONS_TEMPLATE_URL, NOTIFICATIONS_DETAIL_TEMPLATE_URL } from './urls'

const NotificationsHome = props => {
  const { canReadNotifications, currentGroupId, notificationGroupId, defaultGroupId } = props
  const isNotificationGroupImplicit = notificationGroupId === ''
  const doesNotificationGroupExist =
    notificationGroupId !== '' && currentGroupId === notificationGroupId && notificationGroupId !== defaultGroupId ||
    isNotificationGroupImplicit

  return (
    <Switch>
      {canReadNotifications && doesNotificationGroupExist && (
        <Route component={Notifications} exact path={NOTIFICATIONS_TEMPLATE_URL} />
      )}
      {canReadNotifications && doesNotificationGroupExist && (
        <Route
          component={isMobile ? NotificationsDetailMobile : Notifications}
          exact
          path={NOTIFICATIONS_DETAIL_TEMPLATE_URL}
        />
      )}
      <Route component={Error404} path='*' />
    </Switch>
  )
}

NotificationsHome.propTypes = {
  canReadNotifications: PropTypes.bool.isRequired,
  currentGroupId: PropTypes.string.isRequired,
  defaultGroupId: PropTypes.string.isRequired,
  notificationGroupId: PropTypes.string.isRequired
}

export default NotificationsHome
