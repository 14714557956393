import _isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'

import { Map } from 'components/Map'

import GpsInfoPopup from './GpsInfoPopup'
import LoadingGps from './LoadingGps'
import MarkersAndLines from './MarkersAndLines'

const styles = {
  loadingWrapper: {
    background: '#f9f9f9',
    display: 'flex',
    height: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    position: 'absolute',
    right: 0,
    textAlign: 'center',
    zIndex: 10
  },
  mapContainer: {
    height: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    flexGrow: 3
  },
  map: {
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    height: '100%',
    width: '100%'
  }
}

class MapView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMapLoaded: false,
      popupInfo: null
    }

    this.defaultCenter = { latitude: 43.295645, longitude: -1.980012 }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_isEqual(this.props, nextProps) || !_isEqual(this.state, nextState)
  }

  componentDidUpdate(prevProps) {
    const { gpsTrackings } = this.props
    const { gpsTrackings: prevGpsTrackings } = prevProps

    const isAllGpsPointsReady =
      Object.values(gpsTrackings).length > 0 && Object.values(gpsTrackings).every(item => item.ready)
    const wereAllGpsPointsReady =
      Object.values(prevGpsTrackings).length > 0 && Object.values(prevGpsTrackings).every(item => item.ready)

    if (!wereAllGpsPointsReady && isAllGpsPointsReady) {
      this.fitAllGpsPointsOnMap()
    }
  }

  fitAllGpsPointsOnMap = () => {
    const { gpsTrackings, onFlyToMarkerClick } = this.props
    const { isMapLoaded } = this.state

    const deviceEIDsWithGpsTrackings = Object.keys(gpsTrackings)
    const isAllGpsPointsReady =
      deviceEIDsWithGpsTrackings.length > 0 &&
      deviceEIDsWithGpsTrackings.every(deviceEID => gpsTrackings[deviceEID].ready)

    if (isMapLoaded && isAllGpsPointsReady) {
      const allGpsPoints = deviceEIDsWithGpsTrackings.reduce((ret, deviceEID) => {
        let currentKeysPoints = []
        if (Array.isArray(gpsTrackings[deviceEID].points)) {
          currentKeysPoints = gpsTrackings[deviceEID].points
        }
        return [...ret, ...currentKeysPoints]
      }, [])

      onFlyToMarkerClick(allGpsPoints)
    }
  }

  handleMapLoad = () => {
    this.setState({ isMapLoaded: true })
  }

  handleMarkerClick = (device, gpsPoint) => {
    const { deviceColors } = this.props

    const { color } = deviceColors.find(deviceColor => deviceColor.EID === device.EID) || {}

    this.setState({ popupInfo: { ...device, color, position: gpsPoint } })
  }

  handlePopupClose = () => {
    this.setState({ popupInfo: null })
  }

  renderPopup = () => {
    const { popupInfo } = this.state
    if (popupInfo) {
      return <GpsInfoPopup onPopupClose={this.handlePopupClose} popupInfo={popupInfo} />
    }
  }

  render() {
    const {
      classes,
      currentPosition,
      currentZoom,
      deviceColors,
      getFilteredAndOrderedGpsPoints,
      getLimits,
      gpsTrackings,
      isGpsTrackingsLoading,
      isRealtimeGpsTrackingRunning,
      mapContainerRef,
      queriedDevices,
      selectedDevices,
      trail
    } = this.props
    const { isMapLoaded } = this.state

    const deviceEIDsWithGpsTrackings = Object.keys(gpsTrackings)

    const isInitialPositionsAvailable = selectedDevices.length > 0 && selectedDevices.some(item => item.position)
    const isMarkersAndLinesShown =
      isRealtimeGpsTrackingRunning ||
      !isGpsTrackingsLoading ||
      (deviceEIDsWithGpsTrackings.length === 0 && isInitialPositionsAvailable)

    const { from, to } = getLimits()

    return (
      <div ref={mapContainerRef} className={classes.mapContainer}>
        {!isMapLoaded ? (
          <div className={'col-md-12 ' + classes.loadingWrapper}>
            <LoadingGps />
          </div>
        ) : (
          ''
        )}

        <div style={{ height: '100%' }}>
          <Map
            center={currentPosition}
            className={classes.map}
            defaultCenter={this.defaultCenter}
            onLoad={this.handleMapLoad}
            sat
            zoom={currentZoom}
          >
            {isMarkersAndLinesShown ? (
              <MarkersAndLines
                deviceColors={deviceColors}
                from={from}
                getFilteredAndOrderedGpsPoints={getFilteredAndOrderedGpsPoints}
                gpsTrackings={gpsTrackings}
                isRealtimeGpsTrackingRunning={isRealtimeGpsTrackingRunning}
                onMarkerClick={this.handleMarkerClick}
                queriedDevices={queriedDevices}
                selectedDevices={selectedDevices}
                to={to}
                trail={trail}
              />
            ) : null}

            {this.renderPopup()}
          </Map>
        </div>
      </div>
    )
  }
}

MapView.propTypes = {
  classes: PropTypes.object.isRequired,
  currentPosition: PropTypes.object.isRequired,
  currentZoom: PropTypes.number.isRequired,
  deviceColors: PropTypes.array.isRequired,
  getFilteredAndOrderedGpsPoints: PropTypes.func.isRequired,
  getLimits: PropTypes.func.isRequired,
  gpsTrackings: PropTypes.object.isRequired,
  isGpsTrackingsLoading: PropTypes.bool.isRequired,
  isRealtimeGpsTrackingRunning: PropTypes.bool.isRequired,
  mapContainerRef: PropTypes.object.isRequired,
  onFlyToMarkerClick: PropTypes.func.isRequired,
  queriedDevices: PropTypes.array.isRequired,
  selectedDevices: PropTypes.array.isRequired,
  trail: PropTypes.number.isRequired
}

export default withStyles(styles)(MapView)
