import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import PageTitle from 'components/PageTitle'
import { client } from 'utils/http'

import NotificationsDetailContent from '../components/NotificationsDetailContent'
import ServerErrorAlert from '../components/alerts/ServerErrorAlert'
import { NOTIFICATIONS_DETAILS_MODE } from '../constants'
import messages from '../messages'
import { getAdaptedNotificationsData } from '../utils'

class NotificationsDetailMobile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      notification: '',
      isLoading: true,
      isApiFetchError: false
    }
  }

  componentDidMount() {
    this.getNotification()
  }

  getNotification = () => {
    const { notificationId, groupId } = this.props
    client
      .getNotification(notificationId, groupId)
      .then(response => {
        const [notification] = getAdaptedNotificationsData([response.data])
        this.setState({ notification, isApiFetchError: false })
      })
      .catch(() => {
        this.setState({ isApiFetchError: true })
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { intl } = this.props
    const { notification, isLoading, isApiFetchError } = this.state

    return (
      <React.Fragment>
        <Helmet>
          <meta content='width=device-width, initial-scale=1' name='viewport' />
        </Helmet>
        <div className='content-container' id='content'>
          {isLoading ? (
            <Grid container justifyContent='center'>
              <CircularProgress />
            </Grid>
          ) : isApiFetchError ? (
            <ServerErrorAlert />
          ) : (
            <React.Fragment>
              <div className='container-fluid'>
                <PageTitle
                  title={intl.formatMessage(messages.notificationTitle, { machineName: notification.deviceName })}
                />
              </div>
              <div className='row' style={{ margin: '20px 0 0 0' }}>
                <div className='col-md-12'>
                  <Paper style={{ borderRadius: 0 }}>
                    <Grid container item spacing={2} style={{ padding: '20px 20px 0px' }}>
                      <NotificationsDetailContent
                        mode={NOTIFICATIONS_DETAILS_MODE.MOBILE}
                        notificationId={notification.hashId}
                      />
                    </Grid>
                  </Paper>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    )
  }
}

NotificationsDetailMobile.propTypes = {
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  notificationId: PropTypes.string.isRequired
}

export default withRouter(injectIntl(NotificationsDetailMobile))
