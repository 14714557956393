import { defineMessages } from 'react-intl'

const messages = defineMessages({
  importDashboards: {
    id: 'ImportDashboard.importDashboards',
    description: 'Import Dashboards',
    defaultMessage: 'Import dashboards'
  },
  dropFilesHereOrClickToUpload: {
    id: 'ImportDashboard.dropFilesHereOrClickToUpload',
    description: 'Drop files here or click to upload',
    defaultMessage: 'Drop files here or click to upload'
  },
  import: {
    id: 'ImportDashboard.import',
    description: 'Import button label',
    defaultMessage: 'Import'
  },
  isNotJsonError: {
    id: 'ImportDashboard.isNotJsonError',
    description: 'Is not a json file error',
    defaultMessage: '\'\'{name}\'\' is not a json file'
  },
  exceedsMaximumError: {
    id: 'ImportDashboard.exceedsMaximumError',
    description: 'Exceed maximum file size error',
    defaultMessage: '\'\'{name}\'\' exceeds the maximum allowed file size of 10 MB'
  },
  selectedDevices: {
    id: 'ImportDashboard.selectedDevices',
    description: 'Selected devices',
    defaultMessage: 'Selected devices'
  },
  errorImportingDashboard: {
    id: 'ImportDashboard.errorImportingDashboard',
    description: 'There was an error importing the dashboard',
    defaultMessage: 'There was an error importing the dashboard'
  },
  successfulImport: {
    id: 'ImportDashboard.successfulImport',
    description: 'Dashboards importing process started successfully',
    defaultMessage: 'Dashboards importing process started successfully. See more details in \'Events history\' section.'
  },
  importingDashboards: {
    id: 'ImportDashboard.importingDashboards',
    description: 'Importing dashboards title',
    defaultMessage: 'Importing dashboards'
  },
  tooltipMessage: {
    id: 'ImportDashboard.tooltipMessage',
    description: 'Tooltip message',
    defaultMessage: 'Dashboards will be imported in the order below.'
  },
  invalidFilename: {
    id: 'ImportDashboard.invalidFilename',
    description: 'Invalid filename',
    defaultMessage: '\'\'{name}\'\' has invalid file name'
  }
})

export default messages
