import { defineMessages } from 'react-intl'

const messages = defineMessages({
  settings: {
    id: 'SignalCS500.settings',
    description: 'Settings',
    defaultMessage: 'Settings'
  },
  mfioOutputFormula: {
    id: 'SignalCS500.mfioOutputFormula',
    description: 'Signal source == MFIO output formula',
    defaultMessage: 'Output = (Input - Lower limit) * Multiplier + Offset'
  },
  mfioExplanation: {
    id: 'SignalCS500.mfioExplanation',
    description: 'Signal source == MFIO explanation',
    defaultMessage:
      'Values measured at an MFI which are above upper limit or below lower limit are interpreted as an error and will not be sent by the device.'
  },
  customCANOutputFormula: {
    id: 'SignalCS500.customCANOutputFormula',
    description: 'Signal source == MFIO output formula',
    defaultMessage: 'Output = Input * Multiplier + Offset'
  },
  signalId: {
    id: 'SignalCS500.signalId',
    description: 'Signal label',
    defaultMessage: 'Signal'
  },
  name: {
    id: 'SignalCS500.name',
    description: 'name',
    defaultMessage: 'Name'
  },
  signalSource: {
    id: 'SignalCS500.signasignalSourcelId',
    description: 'Signal source',
    defaultMessage: 'Signal source'
  },
  cch_J1939_custom: {
    id: 'SignalCS500.cch_J1939_custom',
    description: 'CAN Port select label',
    defaultMessage: 'CAN Port'
  },
  cch_MFIO: {
    id: 'SignalCS500.cch_MFIO',
    description: 'MFIO pin select label',
    defaultMessage: 'MFIO pin'
  },
  piu_J1939: {
    id: 'SignalCS500.piu_J1939',
    description: 'PGN',
    defaultMessage: 'PGN'
  },
  piu_custom: {
    id: 'SignalCS500.piu_custom',
    description: 'CAN ID',
    defaultMessage: 'CAN ID'
  },
  isExtendedCanId: {
    id: 'SignalCS500.isExtendedCanId',
    description: 'Extended CAN ID',
    defaultMessage: 'Extended CAN ID'
  },
  piu_MFIO: {
    id: 'SignalCS500.piu_MFIO',
    description: 'Upper limit',
    defaultMessage: 'Upper limit'
  },
  sml_J1939: {
    id: 'SignalCS500.sml_J1939',
    description: 'SPN',
    defaultMessage: 'SPN'
  },
  sml_custom: {
    id: 'SignalCS500.sml_custom',
    description: 'Mask',
    defaultMessage: 'Mask'
  },
  sml_MFIO: {
    id: 'SignalCS500.sml_MFIO',
    description: 'Lower limit',
    defaultMessage: 'Lower limit'
  },
  ss_J1939: {
    id: 'SignalCS500.ss_J1939',
    description: 'Source address',
    defaultMessage: 'Source address'
  },
  ss_custom: {
    id: 'SignalCS500.ss_custom',
    description: 'Start bit',
    defaultMessage: 'Start bit'
  },
  length: {
    id: 'SignalCS500.length',
    description: 'Length',
    defaultMessage: 'Length'
  },
  multiplier: {
    id: 'SignalCS500.multiplier',
    description: 'Multiplier',
    defaultMessage: 'Multiplier'
  },
  divider: {
    id: 'SignalCS500.divider',
    description: 'Divider',
    defaultMessage: 'Divider'
  },
  offset: {
    id: 'SignalCS500.offset',
    description: 'offset',
    defaultMessage: 'Offset'
  },
  unit: {
    id: 'SignalCS500.unit',
    description: 'unit',
    defaultMessage: 'Unit'
  },
  inputSignal: {
    id: 'SignalCS500.inputSignal',
    description: 'Pin mode',
    defaultMessage: 'Pin mode'
  },
  inputRange: {
    id: 'SignalCS500.inputRange',
    description: 'Voltage Range',
    defaultMessage: 'Voltage Range'
  },
  bias: {
    id: 'SignalCS500.bias',
    description: 'Input Circuit',
    defaultMessage: 'Input Circuit'
  },
  digThreshHigh: {
    id: 'SignalCS500.digThreshHigh',
    description: 'Digital threshold for high value',
    defaultMessage: 'Digital threshold for high value (mV)'
  },
  digThreshLow: {
    id: 'SignalCS500.digThreshLow',
    description: 'Digital threshold for low value',
    defaultMessage: 'Digital threshold for low value (mV)'
  },
  filter: {
    id: 'SignalCS500.filter',
    description: 'Filter',
    defaultMessage: 'Filter'
  },
  filterType: {
    id: 'SignalCS500.filterType',
    description: 'Filter type',
    defaultMessage: 'Filter type'
  },
  lastMinMaxAvgFilterTypeOptionAdvert: {
    id: 'SignalCS500.lastMinMaxAvgFilterTypeOptionAdvert',
    description: 'lastMinMaxAvgFilterTypeOptionAdvert text',
    defaultMessage:
      'Using the "Last/Min/Max/Avg" filter, device logs these four values once at the beginning of a "Cloud buffer 0 upload period" (defined on "Device settings" section).'
  },
  cornerFrequency: {
    id: 'SignalCS500.cornerFrequency',
    description: 'cornerFrequency',
    defaultMessage: 'Fc / 0.01 Hz'
  },
  quotient: {
    id: 'SignalCS500.quotient',
    description: 'Quotient',
    defaultMessage: 'Quotient / 0.01'
  },
  gain: {
    id: 'SignalCS500.gain',
    description: 'Gain',
    defaultMessage: 'Gain / 0.01 dB'
  },
  logging: {
    id: 'SignalCS500.logging',
    description: 'Logging',
    defaultMessage: 'Logging'
  },
  logToPortal: {
    id: 'SignalCS500.logToPortal',
    description: 'logToPortal',
    defaultMessage: 'Log to portal'
  },
  sendImmediately: {
    id: 'SignalCS500.sendImmediately',
    description: 'Send immediately',
    defaultMessage: 'Send immediately'
  },
  logType: {
    id: 'SignalCS500.logType',
    description: 'Log type',
    defaultMessage: 'Log type'
  },
  samplePeriod: {
    id: 'SignalCS500.samplePeriod',
    description: 'Sample period',
    defaultMessage: 'Sample period (ms)'
  },
  trigger: {
    id: 'SignalCS500.trigger',
    description: 'Trigger',
    defaultMessage: 'Trigger'
  },
  triggerType: {
    id: 'SignalCS500.triggerType',
    description: 'Trigger type',
    defaultMessage: 'Trigger type'
  },
  triggerSource: {
    id: 'SignalCS500.triggerSource',
    description: 'Trigger source',
    defaultMessage: 'Trigger source'
  },
  triggerLowerThreshold: {
    id: 'SignalCS500.triggerLowerThreshold',
    description: 'Trigger lower threshold',
    defaultMessage: 'Trigger lower threshold'
  },
  triggerUpperThreshold: {
    id: 'SignalCS500.triggerUpperThreshold',
    description: 'Trigger upper threshold',
    defaultMessage: 'Trigger upper threshold'
  },
  triggerThresholdDivider: {
    id: 'SignalCS500.triggerThresholdDivider',
    description: 'Trigger threshold divider',
    defaultMessage: 'Trigger threshold divider'
  },
  triggerSendImmediately: {
    id: 'SignalCS500.triggerSendImmediately',
    description: 'Send immediately',
    defaultMessage: 'Send immediately'
  },
  triggerLogType: {
    id: 'SignalCS500.triggerLogType',
    description: 'Trigger log type',
    defaultMessage: 'Trigger log type'
  },
  samplePeriodTriggered: {
    id: 'SignalCS500.samplePeriodTriggered',
    description: 'Trigger sample period',
    defaultMessage: 'Trigger sample period (ms)'
  },
  spnName: {
    id: 'SignalCS500.spnName',
    description: 'SPN name',
    defaultMessage: 'SPN name'
  },
  spn: {
    id: 'SignalCS500.spn',
    description: 'SPN',
    defaultMessage: 'SPN'
  },
  pgnName: {
    id: 'SignalCS500.pgnName',
    description: 'PGN name',
    defaultMessage: 'PGN name'
  },
  pgn: {
    id: 'SignalCS500.pgn',
    description: 'PGN',
    defaultMessage: 'PGN'
  },
  priority: {
    id: 'SignalCS500.priority',
    description: 'Priority',
    defaultMessage: 'Priority'
  },
  searchBy: {
    id: 'SignalCS500.searchBy',
    description: 'Search by NAME, SPN or PGN',
    defaultMessage: ' | Search by NAME, SPN or PGN'
  },
  error: {
    id: 'SignalCS500.error',
    description: 'Error icon tooltip',
    defaultMessage: 'Error'
  },
  duplicateSignal: {
    id: 'SignalCS500.duplicateSignal',
    description: 'Duplicate signal icon tooltip',
    defaultMessage: 'Duplicate signal'
  },
  deleteSignal: {
    id: 'SignalCS500.deleteSignal',
    description: 'Delete signal icon tooltip',
    defaultMessage: 'Delete signal'
  },
  expand: {
    id: 'SignalCS500.expand',
    description: 'Expand icon tooltip',
    defaultMessage: 'Expand'
  },
  collapse: {
    id: 'SignalCS500.collapse',
    description: 'Collapse icon tooltip',
    defaultMessage: 'Collapse'
  },
  editId: {
    id: 'SignalCS500.editId',
    description: 'Edit signal ID icon tooltip',
    defaultMessage: 'Edit signal ID'
  },
  validateId: {
    id: 'SignalCS500.validateId',
    description: 'Validate signal ID icon tooltip',
    defaultMessage: 'Validate signal ID'
  },
  importDbc: {
    id: 'SignalCS500.importDbc',
    description: 'Import DBC file',
    defaultMessage: 'Import DBC file'
  },
  selectDbcFileFromPC: {
    id: 'SignalCS500.selectDbcFileFromPC',
    description: 'Select DBC file to import from PC:',
    defaultMessage: 'Select DBC file to import from PC:'
  },
  saveDbcFile: {
    id: 'SignalCS500.saveDbcFile',
    description: 'Save DBC file',
    defaultMessage: 'Save DBC file'
  },
  cancel: {
    id: 'SignalCS500.cancel',
    description: 'Cancel',
    defaultMessage: 'Cancel'
  },
  fileSuccessfullyLoaded: {
    id: 'SignalCS500.fileSuccessfullyLoaded',
    description: 'File successfully loaded',
    defaultMessage: 'File successfully loaded'
  },
  loadedFile: {
    id: 'SignalCS500.loadedFile',
    description: 'Loaded file',
    defaultMessage: 'Loaded file:'
  },
  dbcFileName: {
    id: 'SignalCS500.dbcFileName',
    description: 'DBC file name',
    defaultMessage: 'DBC file name'
  },
  saveDbcFileAs: {
    id: 'SignalCS500.saveDbcFileAs',
    description: 'Save DBC file as',
    defaultMessage: 'Save DBC file as:'
  },
  signalAlreadyInUse: {
    id: 'SignalCS500.signalAlreadyInUse',
    description: 'Selected signal is already in use. Please, select a different one',
    defaultMessage: 'Selected signal is already in use. Please, select a different one'
  },
  sendImmediatelyExplanation: {
    id: 'SignalCS500.sendImmediatelyExplanation',
    description: 'The complete buffer-content gets uploaded to the cloud when this message gets received.',
    defaultMessage: 'The complete buffer-content gets uploaded to the cloud when this message gets received.'
  },
  unitType: {
    id: 'SignalCS500.unitType',
    description: 'Unit type',
    defaultMessage: 'Unit type'
  },
  canIDType: {
    id: 'SignalCS500.canIDType',
    description: 'CAN ID Type',
    defaultMessage: 'CAN ID Type'
  },
  extendedCANID: {
    id: 'SignalCS500.extendedCANID',
    defaultMessage: 'Extended CAN ID (29 bit)'
  },
  standardCANID: {
    id: 'SignalCS500.standardCANID',
    defaultMessage: 'Standard CAN ID (11 bit)'
  },
  endianness: {
    id: 'SignalCS500.endianness',
    defaultMessage: 'Endianness'
  },
  signedness: {
    id: 'SignalCS500.signedness',
    defaultMessage: 'Signedness'
  }
})

export default messages
