import PropTypes from 'prop-types'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import HomeIcon from '@material-ui/icons/Home'
import WarningIcon from '@material-ui/icons/Warning'

import messages from './messages'
import { error404, pageNotFound } from './style.module.css'

const Error404 = ({ user, intl }) => {
  const { formatMessage } = intl
  const pageNotFoundStyle = isMobile ? { fontSize: 40, textAlign: 'center' } : { fontSize: 48 }
  const textStyle = isMobile ? { fontSize: 17, textAlign: 'justify' } : { fontSize: 18 }
  const text404Style = isMobile ? { fontSize: '55vw', lineHeight: '0.8' } : { fontSize: '32vw' }
  let supportLink = process.env.REACT_APP_SUPPORT_LINK
  if (user?.email) supportLink += '&plus1_email=' + user.email
  supportLink += '#tab-overview'

  return (
    <div style={{ '--align-items': isMobile ? 'baseline' : 'center' }}>
      <Helmet>
        <meta content='width=device-width, initial-scale=1' name='viewport' />
      </Helmet>
      {isMobile && <div style={{ height: 40 }} />}
      <div className={error404}>
        <div className={pageNotFound} style={{ marginTop: isMobile ? '8%' : '0%' }}>
          <Grid container justifyContent='center'>
            <Grid item sm={0} xs={2} />
            <Grid container item spacing={2} style={{ maxWidth: 425 }} xs={8}>
              <Grid container item xs={12}>
                <p style={pageNotFoundStyle}>{formatMessage(messages.pageNotFound)}</p>
              </Grid>
              <Grid container item justifyContent='center' xs={12}>
                <p style={textStyle}>{formatMessage(messages.errorMessage)}</p>
              </Grid>
              <Grid container item justifyContent='center' sm={6} xs={12}>
                <Link to='/'>
                  <Button className='primary-action-button'>
                    <HomeIcon /> {formatMessage(messages.goToTheHomepage)}
                  </Button>
                </Link>
              </Grid>
              <Grid container item justifyContent='center' sm={6} xs={12}>
                <Link rel='noopener noreferrer' target='_blank' to={{ pathname: supportLink }}>
                  <Button className='secondary-action-button'>
                    <WarningIcon /> {formatMessage(messages.reportAnError)}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <Grid item sm={0} xs={2} />
          </Grid>
        </div>
        <p style={text404Style}>404</p>
      </div>
    </div>
  )
}

Error404.propTypes = {
  intl: PropTypes.object.isRequired,
  user: PropTypes.object
}

Error404.defaultProps = {
  user: null
}

export default injectIntl(Error404)
