import _omit from 'lodash/omit'

import * as c from './constants'

const getGPSTrackings = state => _omit(state[c.NAME], [c.GPS_TRACKINGS_LOADING])

const getGPSTrackingsMergedAndOrdered = state =>
  state[c.NAME] === null ? null : mergeAndOrderGPSTrackings(getGPSTrackings(state))

const mergeAndOrderGPSTrackings = gpsTrackings => {
  return Object.keys(gpsTrackings)
    .reduce((ret, key) => {
      let currentKeysPoints = []
      if (Array.isArray(gpsTrackings[key].points)) {
        currentKeysPoints = gpsTrackings[key].points
      }
      return [...ret, ...currentKeysPoints]
    }, [])
    .sort((a, b) => a.timestamp - b.timestamp)
}

const getIsGpsTrackingsLoading = state => state[c.NAME][c.GPS_TRACKINGS_LOADING].length > 0

export { getGPSTrackings, getGPSTrackingsMergedAndOrdered, getIsGpsTrackingsLoading }
