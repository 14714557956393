import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Callback from 'components/Callback'
import PortalLoading from 'components/PortalLoading'
import { utils } from 'ducks/login'
import { getUrlParameter } from 'routes'
import { LOGOUT, HOME, CALLBACK } from 'routes/constants'
import { getLastOpenURL, saveLastOpenURL } from 'utils/urlStorage'

import { useAuth0 } from '../../../react-auth0-spa'
import Home from '../home'
import { REDIRECTIONS, HOME_PATH, CALLBACK_PATH, getUnloggedRedirection } from '../url'

const LoginRouteHandler = ({ loginPath, location }) => {
  const { loginWithRedirect } = useAuth0()
  const isInternalPath =
    !location.pathname.includes(LOGOUT) &&
    !location.pathname.includes(HOME) &&
    !location.pathname.includes(CALLBACK) &&
    location.pathname !== '/'

  useEffect(() => {
    if (isInternalPath) {
      loginWithRedirect({})
    }
  }, [])

  if (!getLastOpenURL() && isInternalPath) {
    const { pathname = '', search = '' } = location
    let completeURL = pathname
    if (search.startsWith('?groupId=')) {
      completeURL += search
    }
    saveLastOpenURL(completeURL)
  }

  if (location.pathname.includes('register/signupcs10')) {
    utils.cacheRegisterPath(location.pathname)
    var eid = getUrlParameter(location.search, 'eid')
    var registerCode = getUrlParameter(location.search, 'regcode')
    var newParams = '?eid=' + encodeURIComponent(eid) + '&regcode=' + encodeURIComponent(registerCode)
    utils.cacheRegisterSearch(newParams)
  }

  let [, groupPath] = location.pathname.split('/')
  if (!groupPath || groupPath === 'logout') groupPath = loginPath

  if (isInternalPath) {
    return <PortalLoading />
  } else {
    return (
      <Switch>
        <Route component={Home} path={HOME_PATH} />
        <Route component={Callback} path={CALLBACK_PATH} />
        {REDIRECTIONS.map(({ from, to }) => (
          <Redirect key={from} from={from} to={to} />
        ))}
        <Redirect push to={getUnloggedRedirection(groupPath)} />
      </Switch>
    )
  }
}

LoginRouteHandler.propTypes = {
  location: PropTypes.object.isRequired,
  loginPath: PropTypes.string.isRequired
}

export { LoginRouteHandler }
