import { defineMessages } from 'react-intl'

const namespace = 'Devices'

const messages = defineMessages({
  title: {
    id: `${namespace}.title`,
    description: 'Activated devices title',
    defaultMessage: 'Device management'
  },
  addDevice: {
    id: `${namespace}.addDevice`,
    description: 'Register device button label',
    defaultMessage: 'Register device'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'First page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Last page button text',
    defaultMessage: 'Last'
  },
  showingRows: {
    id: `${namespace}.showingRows`,
    description: 'Nodes table total text showing rows',
    defaultMessage: 'Showing devices'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Nodes table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Nodes table total text of',
    defaultMessage: 'of'
  },
  noDevicesText: {
    id: `${namespace}.noDevicesText`,
    description: 'No devices text',
    defaultMessage: 'No devices available'
  },
  errorRequestingDevices: {
    id: `${namespace}.errorRequestingDevices`,
    description: 'Error requesting devices',
    defaultMessage: 'Error requesting devices'
  },
  error400Message: {
    id: `${namespace}.error400Message`,
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error403Message: {
    id: `${namespace}.error403Message`,
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: `${namespace}.error406Message`,
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  error500Message: {
    id: `${namespace}.error500Message`,
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error: {
    id: 'Configurations.error',
    description: 'Error text',
    defaultMessage: 'Error {number}'
  },
  deviceID: {
    id: `${namespace}.deviceID`,
    description: 'Device ID',
    defaultMessage: 'Device ID'
  },
  name: {
    id: `${namespace}.name`,
    description: 'name',
    defaultMessage: 'Name'
  },
  deviceType: {
    id: `${namespace}.deviceType`,
    description: 'deviceType',
    defaultMessage: 'Device type'
  },
  eid: {
    id: `${namespace}.eid`,
    description: 'EID',
    defaultMessage: 'EID'
  },
  creationDate: {
    id: `${namespace}.creationDate`,
    description: 'Creation date',
    defaultMessage: 'Creation date'
  },
  manufacturingDate: {
    id: `${namespace}.manufacturingDate`,
    description: 'Manufacturing date',
    defaultMessage: 'Manufact. date'
  },
  activationDate: {
    id: `${namespace}.activationDate`,
    description: 'Activation date',
    defaultMessage: 'Activation date'
  },
  actions: {
    id: `${namespace}.actions`,
    description: 'actions',
    defaultMessage: 'Actions'
  },
  updatedDevice: {
    id: `${namespace}.updatedDevice`,
    description: 'Device updated successfully',
    defaultMessage: 'Device updated successfully'
  },
  edit: {
    id: `${namespace}.edit`,
    description: 'Edit',
    defaultMessage: 'Edit'
  },
  deactivateDevice: {
    id: `${namespace}.deactivateDevice`,
    description: 'Deactivate device',
    defaultMessage: 'Deactivate device'
  },
  activateDeviceSIMCard: {
    id: `${namespace}.activateDeviceSIMCard`,
    description: 'Activate device SIM card',
    defaultMessage: 'Activate device SIM card'
  },
  deviceStatus: {
    id: `${namespace}.deviceStatus`,
    description: 'Device Status',
    defaultMessage: 'Device Status'
  },
  simStatus: {
    id: `${namespace}.simStatus`,
    description: 'SIM status',
    defaultMessage: 'SIM status'
  },
  simActivatedSuccessfully: {
    id: `${namespace}.simActivatedSuccessfully`,
    description: 'SIM card activated successfully',
    defaultMessage: 'SIM card activated successfully'
  },
  problemsObtainingSIMStatus: {
    id: `${namespace}.problemsObtainingSIMStatus`,
    description: 'Problems obtaining SIM status',
    defaultMessage: 'Problems obtaining SIM status'
  },
  dataPlan: {
    id: `${namespace}.dataPlan`,
    description: 'Data plan',
    defaultMessage: 'Data plan'
  },
  noAssignedDataPlan: {
    id: `${namespace}.noAssignedDataPlan`,
    description: 'No assigned data plan',
    defaultMessage: 'No assigned data plan'
  },
  dataPlanExpirationDate: {
    id: `${namespace}.dataPlanExpirationDate`,
    description: 'Data plan expiration date',
    defaultMessage: 'Data plan expires in'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  unregisterTitle: {
    id: `${namespace}.unregisterTitle`,
    description: 'Devices unregister dialog title',
    defaultMessage: 'Unregistering device:'
  },
  dsp: {
    id: `${namespace}.dsp`,
    description: 'DSP',
    defaultMessage: 'DSP'
  },
  timeToExpire: {
    id: `${namespace}.timeToExpire`,
    description: 'Time to expire header title',
    defaultMessage: 'Time to expire'
  },
  startedProductionTestMode: {
    id: `${namespace}.startedProductionTestMode`,
    description: 'Successfully started Production Test Mode',
    defaultMessage: 'Successfully started Production Test Mode'
  },
  endedProductionTestMode: {
    id: `${namespace}.endedProductionTestMode`,
    description: 'Successfully ended Production Test Mode',
    defaultMessage: 'Successfully ended Production Test Mode'
  },
  stoppedDigitalServicePlan: {
    id: `${namespace}.stoppedDigitalServicePlan`,
    description: 'Stopped Device`s Digital Service Plan auto-renewal subscription successfully',
    defaultMessage: 'Stopped Device`s Digital Service Plan auto-renewal subscription successfully'
  },
  autoRenewalActivated: {
    id: `${namespace}.autoRenewalActivated`,
    description: 'Successfully activated auto-renewal of the subscription to the device`s Digital Service Plan',
    defaultMessage: 'Successfully activated auto-renewal of the subscription to the device`s Digital Service Plan'
  },
  autoRenewalDeactivated: {
    id: `${namespace}.autoRenewalDeactivated`,
    description: 'Successfully deactivated auto-renewal of the subscription to the device`s Digital Service Plan',
    defaultMessage: 'Successfully deactivated auto-renewal of the subscription to the device`s Digital Service Plan'
  },
  useOverage: {
    id: `${namespace}.useOverage`,
    description: 'Successfully started using overage data',
    defaultMessage: 'Successfully started using overage data'
  },
  stopUsingOverage: {
    id: `${namespace}.stopUsingOverage`,
    description: 'Successfully stopped using overage data',
    defaultMessage: 'Successfully stopped using overage data'
  },
  totalData: {
    id: `${namespace}.totalData`,
    description: 'Total data header title',
    defaultMessage: 'Total data'
  },
  overageData: {
    id: `${namespace}.overageData`,
    description: 'Overage data header title',
    defaultMessage: 'Overage data'
  },
  subscription: {
    id: `${namespace}.subscription`,
    description: 'Subscription header title',
    defaultMessage: 'Subscription'
  },
  planID: {
    id: `${namespace}.planID`,
    description: 'Plan ID header title',
    defaultMessage: 'Plan ID'
  },
  renewalDate: {
    id: `${namespace}.renewalDate`,
    description: 'Renewal date header title',
    defaultMessage: 'Renewal date'
  },
  dspActivated: {
    id: `${namespace}.dspActivated`,
    description: 'Successfully activated Digital Service Plan',
    defaultMessage: 'Successfully activated Digital Service Plan'
  },
  placeholder: {
    id: `${namespace}.placeholder`,
    description: 'Placeholder text',
    defaultMessage: 'Enter {column}'
  },
  selectPlaceholder: {
    id: `${namespace}.selectPlaceholder`,
    description: 'Select placeholder text',
    defaultMessage: 'Select {column}'
  },
  accessPassConsumptionAllowed: {
    id: `${namespace}.accessPassConsumptionAllowed`,
    description: 'Successfully allowed Access Pass consumption',
    defaultMessage: 'Successfully allowed Access Pass consumption'
  },
  accessPassStatus: {
    id: `${namespace}.accessPassStatus`,
    description: 'Access Pass status column header',
    defaultMessage: 'Access Pass status'
  },
  accessPassesShared: {
    id: `${namespace}.accessPassesShared`,
    description: 'Successfully shared Access Passes',
    defaultMessage: 'Successfully shared {number} Access Passes with {name} group'
  },
  dataPlansShared: {
    id: `${namespace}.dataPlansShared`,
    description: 'Successfully shared Data Plans',
    defaultMessage: 'Successfully shared {number} Data Plans with {name} group'
  },
  accessPassConsumptionDisallowed: {
    id: `${namespace}.accessPassConsumptionDisallowed`,
    description: 'Successfully disallowed Access Pass consumption',
    defaultMessage: 'Successfully disallowed Access Pass consumption'
  },
  deviceRegistered: {
    id: `${namespace}.deviceRegistered`,
    description: 'Device successfully registered',
    defaultMessage: 'Device successfully registered'
  },
  registerDevice: {
    id: `${namespace}.registerDevice`,
    description: 'Register device',
    defaultMessage: 'Register device'
  },
  ioTDeviceInformation: {
    id: `${namespace}.ioTDeviceInformation`,
    description: 'IoT Device information',
    defaultMessage: 'IoT Device information'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  machineInformation: {
    id: `${namespace}.machineInformation`,
    description: 'Machine information',
    defaultMessage: 'Machine information'
  },
  deviceSerialNumber: {
    id: `${namespace}.deviceSerialNumber`,
    description: 'Device serial number',
    defaultMessage: 'Device serial number'
  },
  nameHelperMessage: {
    id: `${namespace}.nameHelperMessage`,
    description: 'Name text field helper message',
    defaultMessage: 'The device and the machine will share the same name.'
  },
  requiredFieldsMessage: {
    id: `${namespace}.requiredFieldsMessage`,
    description: 'Message about required fields',
    defaultMessage: 'Fields marked with an asterisk (*) are required.'
  },
  machineNameHelperMessage: {
    id: `${namespace}.machineNameHelperMessage`,
    description: 'Machine name helper message',
    defaultMessage: 'Same as "Name" at "Device information".'
  },
  machineType: {
    id: `${namespace}.machineType`,
    description: 'MachineType',
    defaultMessage: 'Machine type'
  },
  machineBrand: {
    id: `${namespace}.machineBrand`,
    description: 'Brand',
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    description: 'Machine serial number',
    defaultMessage: 'Machine serial number'
  },
  autocomplete: {
    id: `${namespace}.autocomplete`,
    description: 'Autocomplete',
    defaultMessage: 'Autocomplete'
  },
  autocompleteHelperText: {
    id: `${namespace}.autocompleteHelperText`,
    description: 'Autocomplete helper text',
    defaultMessage: 'Autocomplete form with previous information'
  },
  serverError: {
    id: `${namespace}.serverError`,
    description: 'Server error',
    defaultMessage: 'Server error'
  },
  serverErrorClarification: {
    id: `${namespace}.serverErrorClarification`,
    description: 'Server error clarification',
    defaultMessage:
      'Please try again or refresh the page. \
      If the error persists, contact <a>Plus+1 Connect Support</a>.'
  },
  success: {
    id: `${namespace}.success`,
    description: 'Success alert title',
    defaultMessage: 'Success'
  },
  deviceSuccessfullyRegistered: {
    id: `${namespace}.deviceSuccessfullyRegistered`,
    description: 'Success alert message',
    defaultMessage: 'Device successfully registered'
  },
  exit: {
    id: `${namespace}.exit`,
    description: 'Exit button label',
    defaultMessage: 'Exit'
  },
  editDeviceAndMachineInformation: {
    id: `${namespace}.editDeviceAndMachineInformation`,
    description: 'Edit device and machine information',
    defaultMessage: 'Edit device and machine information'
  },
  deviceSuccessfullySaved: {
    id: `${namespace}.deviceSuccessfullySaved`,
    description: 'Device successfully saved',
    defaultMessage: 'Device successfully saved'
  },
  lastTelemetryMessage: {
    id: `${namespace}.lastTelemetryMessage`,
    description: 'Last telemetry message',
    defaultMessage: 'Last telemetry message'
  },
  selectColumns: {
    id: `${namespace}.selectColumns`,
    description: 'Select columns',
    defaultMessage: 'Select columns'
  },
  showHideColumns: {
    id: `${namespace}.showHideColumns`,
    description: 'Show/hide columns',
    defaultMessage: 'Show/hide columns'
  },
  machineModel: {
    id: `${namespace}.machineModel`,
    description: 'Model',
    defaultMessage: 'Model'
  },
  registerCode: {
    id: `${namespace}.registerCode`,
    description: 'Register code',
    defaultMessage: 'Register code'
  },
  description: {
    id: `${namespace}.description`,
    description: 'Description',
    defaultMessage: 'Description'
  },
  mobileSignal: {
    id: `${namespace}.mobileSignal`,
    description: 'Mobile signal',
    defaultMessage: 'Mobile signal'
  },
  lastGPSMessage: {
    id: `${namespace}.lastGPSMessage`,
    description: 'Last GPS message',
    defaultMessage: 'Last GPS message'
  },
  lastGPSMessageOlderThan24Hours: {
    id: `${namespace}.lastGPSMessageOlderThan24Hours`,
    description: 'The last GPS message is older than 24 hours',
    defaultMessage: 'The last GPS message is older than 24 hours'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  thisFieldMustFollowEIDFormatting: {
    id: `${namespace}.thisFieldMustFollowEIDFormatting`,
    description: 'This field must follow EID formatting',
    defaultMessage: 'This field must follow EID formatting. Example: 4e:cd:07:a4:a3:a6'
  },
  thisFieldCannotExceedCharacters: {
    id: `${namespace}.thisFieldCannotExceedCharacters`,
    description: 'This field cannot exceed {characters} characters',
    defaultMessage: 'This field cannot exceed {chars} characters. Exceeds by {numOfExceedChars}.'
  },
  thisFieldCanOnlyContainAlphaNumericCharacters: {
    id: `${namespace}.thisFieldCanOnlyContainAlphaNumericCharacters`,
    description: 'This field can only contain alphanumeric characters',
    defaultMessage: 'This field can only contain alphanumeric characters. Example: 123abc'
  },
  thisFieldCanOnlyContainNumericCharacters: {
    id: `${namespace}.thisFieldCanOnlyContainNumericCharacters`,
    description: 'This field can only contain numeric characters',
    defaultMessage: 'This field can only contain numeric characters. Example: 123456'
  }
})

export default messages
