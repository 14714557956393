import { PropTypes } from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import messages from './messages'

const LinkedLogo = ({ isCollapsed = false, logo, link, intl, isClickable = true }) => {
  //const LinkedLogo = ({ logo, link, intl }) => {
  const { formatMessage } = intl
  if (isClickable) {
    return (
      <Link to={link}>
        {logo && (
          <img alt={formatMessage(messages.logo)} className={'logo-' + (isCollapsed ? 'small' : 'normal')} src={logo} />
        )}
      </Link>
    )
  } else if (logo) {
    return (
      <img alt={formatMessage(messages.logo)} className={'logo-' + (isCollapsed ? 'small' : 'normal')} src={logo} />
    )
  } else return null
}

LinkedLogo.propTypes = {
  isClickable: PropTypes.bool.isRequired,
  isCollapsed: PropTypes.bool,
  link: PropTypes.string.isRequired,
  logo: PropTypes.string
}

export default injectIntl(LinkedLogo)
