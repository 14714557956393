import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'

import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'

import messages from '../../messages'
import { getNotificationsUrl } from '../../urls'

const styles = {
  notificationsDeactivated: {
    height: '50px',
    width: '64px',
    paddingTop: 0,
    paddingBottom: 3,
    textAlign: 'end'
  },
  badge: {
    fontSize: 10
  },
  badgeHigh: {
    backgroundColor: '#b60710',
    color: '#fff'
  },
  badgeMedium: {
    backgroundColor: '#f0ad4e',
    color: '#fff'
  },
  badgeLow: {
    backgroundColor: '#0077FF',
    color: '#fff'
  }
}

class NotificationsBell extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props

    this.formatMessage = formatMessage

    this.state = {}
  }

  render() {
    const { classes, color, notificationsTotalsData, user } = this.props

    const badgeStyles = {
      high: classes.badgeHigh,
      medium: classes.badgeMedium,
      low: classes.badgeLow
    }

    let notificationPriority = ''
    if (notificationsTotalsData?.high?.open > 0) {
      notificationPriority = 'high'
    } else if (notificationsTotalsData?.medium?.open > 0) {
      notificationPriority = 'medium'
    } else if (notificationsTotalsData?.low?.open > 0) {
      notificationPriority = 'low'
    }

    const userWantsToReceiveNotifications = user !== null && user.notify
    const isNotificationsBeingReceived = Object.keys(notificationsTotalsData || {}).length > 0
    const isAnyNotification = Boolean(notificationPriority)
    const badgeValue = isAnyNotification ? notificationsTotalsData[notificationPriority].open : 0
    const badgeStyle = badgeStyles[notificationPriority] || ''

    return userWantsToReceiveNotifications ? 
      isNotificationsBeingReceived ? (
        <div
          className={isAnyNotification ? 'notifications-tab' : 'no-notifications-tab'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 50,
            width: 64
          }}
        >
          <NavLink
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            title={this.formatMessage(messages.notifications)}
            to={getNotificationsUrl()}
          >
            <Badge
              badgeContent={badgeValue}
              classes={{
                badge: classes.badge + ' ' + badgeStyle
              }}
              color='primary'
              max={99}
            >
              <i
                className='zmdi zmdi-notifications'
                style={{
                  color,
                  fontSize: 22
                }}
              />
            </Badge>
          </NavLink>
        </div>
      ) : (
        <div
          className={isAnyNotification ? 'notifications-tab' : 'no-notifications-tab'}
          style={{
            position: 'relative',
            height: 50,
            witdh: 64,
            paddingRight: 20
          }}
        >
          <div
            style={{
              position: 'relative',
              top: 5,
              fontSize: 14
            }}
          >
            <span className='zmdi-hc-stack zmdi-hc-lg'>
              <i className='zmdi zmdi-alert-triangle zmdi-hc-stack-1x' style={{ color }} />
            </span>
            <span style={{ color }}>{this.formatMessage(messages.notificationsNoReceived)}</span>
          </div>
        </div>
      )
      : (
        <div
          className={isAnyNotification ? 'notifications-tab' : 'no-notifications-tab'}
          style={{
            position: 'relative',
            height: 50,
            width: 64
          }}
        >
          <Button
            classes={{
              root: classes.notificationsDeactivated
            }}
            href=''
            label=''
          >
            <Icon
              className='zmdi zmdi-notifications-off no-notifications-icon'
              style={{ color, marginTop: 0, textAlign: 'center', fontSize: '22px' }}
              title={this.formatMessage(messages.notificationsDisabled)}
            />
          </Button>
        </div>
      )
  }
}

NotificationsBell.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  notificationsTotalsData: PropTypes.object,
  user: PropTypes.object
}

NotificationsBell.defaultProps = {
  notificationsTotalsData: null,
  user: null
}

export default withStyles(styles)(injectIntl(NotificationsBell))
