import { defineMessages } from 'react-intl'

const messages = defineMessages({
  map: {
    id: 'Privileges.map',
    description: 'Map privilege text',
    defaultMessage: 'Map'
  },
  nodes: {
    id: 'Privileges.nodes',
    description: 'Devices privilege text',
    defaultMessage: 'Devices'
  },
  view: {
    id: 'Privileges.view',
    description: 'View privilege text',
    defaultMessage: 'View'
  },
  edit: {
    id: 'Privileges.edit',
    description: 'Edit privilege text',
    defaultMessage: 'Edit'
  },
  nodeDetail: {
    id: 'Privileges.nodeDetail',
    description: 'Node detail privilege text',
    defaultMessage: 'Detail'
  },
  viewNodeDetails: {
    id: 'Privileges.viewNodeDetails',
    description: 'View node details privilege text',
    defaultMessage: 'View (receiver, transmitter, radio, crane, gen. info)'
  },
  alarms: {
    id: 'Privileges.alarms',
    description: 'Alarms privilege text',
    defaultMessage: 'Alarms'
  },
  config: {
    id: 'Privileges.config',
    description: 'Config privilege text',
    defaultMessage: 'Config'
  },
  applyConfig: {
    id: 'Privileges.applyConfig',
    description: 'Apply config privilege text',
    defaultMessage: 'Apply configuration & edit device info'
  },
  configFile: {
    id: 'Privileges.configFile',
    description: 'Configuration file privilege text',
    defaultMessage: 'Configuration file'
  },
  softwareFile: {
    id: 'Privileges.softwareFile',
    description: 'Software file privilege text',
    defaultMessage: 'Software file'
  },
  realtimeView: {
    id: 'Privileges.realtimeView',
    description: 'Realtime view privilege text',
    defaultMessage: 'Realtime view'
  },
  rawData: {
    id: 'Privileges.rawData',
    description: 'Raw data privilege text',
    defaultMessage: 'RAW data'
  },
  variableMappings: {
    id: 'Privileges.variableMappings',
    description: 'Variable mappings privilege text',
    defaultMessage: 'Variable mappings'
  },
  variableReader: {
    id: 'Privileges.variableReader',
    description: 'Variable reader privilege text',
    defaultMessage: 'Variable reader'
  },
  users: {
    id: 'Privileges.users',
    description: 'Users privilege text',
    defaultMessage: 'Users'
  },
  groups: {
    id: 'Privileges.groups',
    description: 'Groups privilege text',
    defaultMessage: 'Groups'
  },
  notifications: {
    id: 'Privileges.notifications',
    description: 'Notifications privilege text',
    defaultMessage: 'Notifications'
  },
  statisticalData: {
    id: 'Privileges.statisticalData',
    description: 'Node\'s statistical data reading privilege text',
    defaultMessage: 'Statistical data'
  },
  nodeHistory: {
    id: 'Privileges.nodeHistory',
    description: 'Node\'s history reading privilege text',
    defaultMessage: 'Node history'
  },
  globalAction: {
    id: 'Privileges.globalAction',
    description: 'Global actions privilege text',
    defaultMessage: 'Global action'
  },
  updates: {
    id: 'Privileges.updates',
    description: 'Updates privilege text',
    defaultMessage: 'Updates'
  },
  datalogger: {
    id: 'Privileges.datalogger',
    description: 'Datalogger privilege text',
    defaultMessage: 'Datalogger'
  },
  communicationModule: {
    id: 'Privileges.communicationModule',
    description: 'Communication module privilege text',
    defaultMessage: 'Communication Module'
  },
  configurationFile: {
    id: 'Privileges.configurationFile',
    description: 'Configuration file privilege text',
    defaultMessage: 'Configuration File'
  },
  eepromRecovery: {
    id: 'Privileges.eepromRecovery',
    description: 'Eeprom recovery privilege text',
    defaultMessage: 'EEPROM recovery'
  },
  eepromWriter: {
    id: 'Privileges.eepromWriter',
    description: 'Eeprom writer privilege text',
    defaultMessage: 'EEPROM writer'
  },
  systemDebugger: {
    id: 'Privileges.systemDebugger',
    description: 'System debugger privilege text',
    defaultMessage: 'System debugger'
  },
  canAnalyzer: {
    id: 'Privileges.canAnalyzer',
    description: 'CAN analyzer privilege text',
    defaultMessage: 'CAN analyzer'
  },
  actionsState: {
    id: 'Privileges.actionsState',
    description: 'Actions state privilege text',
    defaultMessage: 'Events history'
  },
  dashboards: {
    id: 'Privileges.dashboards',
    description: 'Dashboards privilege text',
    defaultMessage: 'Dashboards'
  },
  safety: {
    id: 'Privileges.safety',
    description: 'Safety privilege text',
    defaultMessage: 'Safety'
  },
  stats: {
    id: 'Privileges.stats',
    description: 'Stats privilege text',
    defaultMessage: 'Stats'
  },
  lifecycle: {
    id: 'Privileges.lifecycle',
    description: 'Lifecycle privilege text',
    defaultMessage: 'Lifecycle'
  },
  downloadUserActivity: {
    id: 'Privileges.downloadUserActivity',
    description: 'Download user activity privilege text',
    defaultMessage: 'Download user activity'
  },
  settings: {
    id: 'Privileges.settings',
    description: 'Settings privilege text',
    defaultMessage: 'Settings'
  },
  development: {
    id: 'Privileges.development',
    description: 'Development privilege text',
    defaultMessage: 'Development'
  },
  activityLog: {
    id: 'Privileges.activityLog',
    description: 'Activity log privilege text',
    defaultMessage: 'Activity log'
  },
  updateConfig: {
    id: 'Privileges.updateConfig',
    description: 'Update config',
    defaultMessage: 'Update config'
  },
  updateFirmware: {
    id: 'Privileges.updateFirmware',
    description: 'Update firmware',
    defaultMessage: 'Update firmware'
  },
  geoTracking: {
    id: 'Privileges.geoTracking',
    description: 'Geotracking',
    defaultMessage: 'Geotracking'
  },
  geolocation: {
    id: 'Privileges.geolocation',
    description: 'Geolocation',
    defaultMessage: 'Geolocation'
  },
  geofence: {
    id: 'Privileges.geofence',
    description: 'Geofence',
    defaultMessage: 'Geofence'
  },
  create: {
    id: 'Privileges.create',
    description: 'Create',
    defaultMessage: 'Create'
  },
  delete: {
    id: 'Privileges.delete',
    description: 'Delete',
    defaultMessage: 'Delete'
  },
  reports: {
    id: 'Privileges.reports',
    description: 'Reports',
    defaultMessage: 'Reports'
  },
  configurations: {
    id: 'Privileges.configurations',
    description: 'Configurations',
    defaultMessage: 'Configurations'
  },
  deviceManagement: {
    id: 'Privileges.deviceManagement',
    description: 'Device management',
    defaultMessage: 'Device management'
  },
  roles: {
    id: 'Privileges.roles',
    description: 'Roles',
    defaultMessage: 'Roles'
  },
  charts: {
    id: 'Privileges.charts',
    description: 'Charts',
    defaultMessage: 'Charts'
  },
  readFirmware: {
    id: 'Privileges.readFirmware',
    description: 'Read firmware',
    defaultMessage: 'Read firmware'
  },
  readConfig: {
    id: 'Privileges.readConfig',
    description: 'Read configuration',
    defaultMessage: 'Read configuration'
  },
  servicePlansAndSMSPacks: {
    id: 'Privileges.servicePlansAndSMSPacks',
    description: 'Service plans and SMS packs',
    defaultMessage: 'Service plans and SMS packs'
  },
  apply: {
    id: 'Privileges.apply',
    description: 'Apply',
    defaultMessage: 'Apply'
  },
  shareResources: {
    id: 'Privileges.shareResources',
    description: 'Share resources',
    defaultMessage: 'Share resources'
  },
  groupDashboards: {
    id: 'Privileges.groupDashboards',
    description: 'Group dashboards',
    defaultMessage: 'Group dashboards'
  },
  applyFirmware: {
    id: 'Privileges.applyFirmware',
    description: 'Apply firmware',
    defaultMessage: 'Apply firmware'
  },
  productionTestMode: {
    id: 'Privileges.productionTestMode',
    description: 'Production Test Mode',
    defaultMessage: 'Production Test Mode'
  },
  accessPasses: {
    id: 'Privileges.accessPasses',
    description: 'Access Passes',
    defaultMessage: 'Access Passes'
  },
  consume: {
    id: 'Privileges.consume',
    description: 'Consume',
    defaultMessage: 'Consume'
  },
  share: {
    id: 'Privileges.share',
    description: 'Share',
    defaultMessage: 'Share'
  }
})

export default messages
