import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'

import { adapter } from 'utils/cs500'
import { isValidFilename } from 'utils/file'
import ReactUploadFile from 'utils/fileHandler/ReactUploadFile'

import messages from './messages'

const styles = {
  button: {
    padding: '16px 24px 16px 24px'
  },
  dialog: {
    minWidth: 600
  },
  text: {
    width: '100%',
    padding: '0 24px 0 24px',
    fontSize: 16,
    color: '#5E5E5E'
  },
  title: {
    fontSize: 20,
    padding: '16px 24px 0 24px'
  }
}

class LoadConfigurationDialog extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      alertMessageText: '',
      alertMessageTitle: '',
      alertMessageType: 'success',
      alertVisibility: 'hidden',
      configFileToImport: null,
      loadConfigurationIsDisabled: true,
      loaded: false,
      loadedFileName: ''
    }
  }

  componentDidUpdate = prevProps => {
    const { dialogOpen } = this.props

    if (prevProps.dialogOpen !== dialogOpen) {
      this.deleteData()
    }
  }

  handleCloseAlertClick = () => {
    this.setState({
      alertMessageText: '',
      alertMessageTitle: '',
      alertVisibility: 'hidden'
    })
  }

  deleteData = () => {
    this.setState({
      alertMessageText: '',
      alertMessageTitle: '',
      alertMessageType: 'success',
      alertVisibility: 'hidden',
      configFileToImport: null,
      loadConfigurationIsDisabled: true,
      loaded: false,
      loadedFileName: ''
    })
  }

  isJSON = data => {
    try {
      JSON.parse(data)
    } catch (e) {
      return false
    }
    return true
  }

  handleLoadNonVolatileConfigurationClick = () => {
    const { onDialogClose, resetLocalConfiguration, setLocalConfiguration } = this.props
    const { configFileToImport } = this.state

    resetLocalConfiguration()
    setLocalConfiguration(configFileToImport)
    onDialogClose()
  }

  render() {
    const uploadButtonOptions = {
      baseUrl: process.env.REACT_APP_HTTP_API,
      dataType: 'json',
      multiple: false,
      numberLimit: 1,
      accept: '.json',
      fileFieldName: 'file',

      beforeUpload: files => {
        if (typeof files === 'string') {
          return true
        }
        if (files[0].size > 0) {
          return true
        }
        return false
      },
      didLoad: pcFile => {
        const reader = new FileReader()
        reader.readAsText(pcFile)
        reader.onload = () => {
          if (this.isJSON(reader.result)) {
            const jsonFile = JSON.parse(reader.result)
            try {
              if (!isValidFilename(pcFile.name, 'json')) throw new Error()
              const adaptedNonVolatileConfiguration = adapter.cs500ConfigToCs100Config(jsonFile)

              this.setState({
                loaded: true,
                loadedFileName: pcFile.name,
                configFileToImport: adaptedNonVolatileConfiguration,
                loadConfigurationIsDisabled: false,
                alertVisibility: '',
                alertMessageType: 'success',
                alertMessageTitle: this.formatMessage(messages.validConfigFile),
                alertMessageText: ''
              })
            } catch (error) {
              this.setState({
                loaded: true,
                loadedFileName: pcFile.name,
                configFileToImport: null,
                loadConfigurationIsDisabled: true,
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.invalidConfigFile),
                alertMessageText: this.formatMessage(messages.invalidCS500config)
              })
            }
          } else {
            this.setState({
              loaded: true,
              loadedFileName: pcFile.name,
              configFileToImport: null,
              loadConfigurationIsDisabled: true,
              alertVisibility: '',
              alertMessageType: 'danger',
              alertMessageTitle: this.formatMessage(messages.invalidConfigFile),
              alertMessageText: this.formatMessage(messages.mustBeJson)
            })
          }
        }
      }
    }

    const { classes, onDialogClose, dialogOpen, intl } = this.props
    const {
      alertMessageText,
      alertMessageTitle,
      alertMessageType,
      alertVisibility,
      loadConfigurationIsDisabled,
      loaded,
      loadedFileName
    } = this.state

    return (
      <Dialog classes={{ paper: classes.dialog }} onClose={onDialogClose} open={dialogOpen}>
        <DialogTitle classes={{ root: classes.title }}>
          {this.formatMessage(messages.selectFileFromPC)}
          <IconButton
            onClick={onDialogClose}
            style={{
              position: 'absolute',
              right: 3,
              top: 3,
              padding: 5
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {alertVisibility === '' && (
          <div className='col-md-12' style={{ padding: '16px 24px 0 24px' }}>
            <div className={alertVisibility}>
              <div
                className={'alert alert-' + alertMessageType + ' alert-dismissible animated fadeIn'}
                style={{ marginBottom: 0 }}
              >
                {alertMessageTitle !== '' && (
                  <h4
                    style={{
                      display: 'flex',
                      alignItems: alertMessageType === 'success' ? 'end' : 'flex-start',
                      margin: 0
                    }}
                  >
                    {alertMessageType === 'success' && <CheckCircleIcon style={{ marginRight: 10 }} />}
                    {alertMessageType === 'danger' && <ErrorIcon style={{ marginRight: 10 }} />}
                    {alertMessageTitle}
                  </h4>
                )}
                {alertMessageText !== '' && <h5 style={{ margin: '0 0 0 35px' }}>{alertMessageText}</h5>}
              </div>
            </div>
          </div>
        )}
        <div className='col-md-12' style={styles.button}>
          <ReactUploadFile
            chooseFileButton={
              <Button className='secondary-action-button'>{this.formatMessage(messages.browse)}</Button>
            }
            intl={intl}
            options={uploadButtonOptions}
          />
        </div>
        {loaded && (
          <div style={styles.text}>
            <DialogContentText color='textPrimary'>{this.formatMessage(messages.loadedFile)}</DialogContentText>
            <DialogContentText color='textPrimary' style={{ paddingBottom: 12 }}>
              {loadedFileName}
            </DialogContentText>
          </div>
        )}
        <DialogActions>
          <Button
            className='primary-action-button'
            disabled={loadConfigurationIsDisabled}
            onClick={this.handleLoadNonVolatileConfigurationClick}
          >
            {this.formatMessage(messages.loadConfiguration)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

LoadConfigurationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  resetLocalConfiguration: PropTypes.func.isRequired,
  setLocalConfiguration: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(LoadConfigurationDialog))
