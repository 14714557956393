import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import Highstock from 'highcharts/highstock'
import Boost from 'highcharts/modules/boost'
import ExportData from 'highcharts/modules/export-data'
import Exporting from 'highcharts/modules/exporting'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { StylesProvider, createGenerateClassName } from '@material-ui/styles'

import Favicon from 'components/Favicon'
import translations from 'i18n/locales'
import { RouteHandler } from 'routes'




import { HighchartsTheme } from 'utils/HighchartsTheme/HighchartsTheme'

import 'moment/locale/es'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'

HighchartsMore(Highcharts)
Boost(Highcharts)
Exporting(Highcharts)
ExportData(Highcharts)
Highcharts.setOptions(HighchartsTheme(localStorage.getItem('user_language')))
HighchartsMore(Highstock)
Boost(Highstock)
Exporting(Highstock)
ExportData(Highstock)
Highstock.setOptions(HighchartsTheme(localStorage.getItem('user_language')))
;(function (H) {
  H.wrap(H.Chart.prototype, 'getDataRows', function (proceed, multiLevelHeaders) {
    var rows = proceed.call(this, multiLevelHeaders)
    return rows.filter(row => !row[0].includes('1970-01-01'))
  })
})(Highstock)

moment.updateLocale('es', {
  longDateFormat: {
    L: 'DD-MM-YYYY'
  }
})

moment.updateLocale('en', {
  longDateFormat: {
    L: 'MM-DD-YYYY'
  }
})

const generateClassName = createGenerateClassName({
  productionPrefix: 'adi',
  disableGlobal: true
})

class Root extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount = () => {
    const { selectedLanguage } = this.props
    moment.locale(selectedLanguage)
  }

  componentDidUpdate = prevProps => {
    const { selectedLanguage } = this.props
    if (prevProps.selectedLanguage !== selectedLanguage) {
      moment.locale(selectedLanguage)
    }
  }

  render() {
    const { store, selectedLanguage, history } = this.props
    return (
      <StylesProvider generateClassName={generateClassName}>
        <Provider store={store}>
          <MuiPickersUtilsProvider locale={selectedLanguage} utils={MomentUtils}>
            <IntlProvider key={selectedLanguage} locale={selectedLanguage} messages={translations[selectedLanguage]}>
              <BrowserRouter>
                <div className='partm-full-height'>
                  <Favicon />
                  <RouteHandler history={history} />
                </div>
              </BrowserRouter>
            </IntlProvider>
          </MuiPickersUtilsProvider>
        </Provider>
      </StylesProvider>
    )
  }
}

Root.defaultProps = {
  selectedLanguage: 'en'
}

Root.propTypes = {
  history: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.string,
  store: PropTypes.object.isRequired
}

export { Root }
