import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import MobileSignal from 'components/MobileSignal'
import { MachineActions } from 'components/machines'
import { ReactComponent as WarningIcon } from 'icons/warning.svg'
import client from 'utils/http/client'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import messages from './messages'

const styles = {
  transitionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative'
  },
  mapPanelNode: {
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: '6px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px',
    boxSizing: 'border-box',
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    marginRight: '2vh',
    marginTop: '10vh',
    maxWidth: '600px',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    width: '100%',

    '&.fadein-appear': {
      opacity: '0.01'
    },
    '&.fadein-appear.fadein-appear-active': {
      opacity: '1',
      transition: 'opacity 500ms ease-in'
    }
  },

  title: {
    marginBottom: '20px'
  },
  dataLine: {
    marginBottom: '10px'
  },
  actionsContainer: {
    marginTop: '20px'
  },
  tooltip: {
    maxWidth: 200,
    backgroundColor: '#212121'
  },
  arrow: {
    color: '#212121'
  }
}

class Panel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobileSignalLoading: true,
      mobileSignal: {}
    }
    this.cardRef = React.createRef(null)
  }

  componentDidMount() {
    const { deviceStatus, getDevice, nodeId, groupId } = this.props
    if (deviceStatus === '') getDevice(nodeId, groupId)
    this.getMobileSignal()
  }

  getMobileSignal = () => {
    const { groupId, node } = this.props
    const cleanEID = 'm' + node.EID.replace(/:/g, '')

    client
      .getMobileSignals({ groupId, eids: [cleanEID] })
      .then(response => {
        const mobileSignal = response?.data?.content?.find(item => item.deviceEid === cleanEID) || {}
        this.setState({ mobileSignal })
      })
      .finally(() => {
        this.setState({ isMobileSignalLoading: false })
      })
  }

  getLastGPSMessage = timestamp => {
    const {
      classes,
      intl: { formatMessage }
    } = this.props
    const yesterdaySameTimeMiliseconds = moment().subtract(1, 'day').valueOf()
    if (timestamp < yesterdaySameTimeMiliseconds) {
      return (
        <span>
          {utcTimeToBrowserLocal(timestamp)}
          <Tooltip
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            placement='right'
            title={formatMessage(messages.lastGPSMessageOlderThan24Hours)}
          >
            <WarningIcon style={{ marginLeft: 8 }} />
          </Tooltip>
        </span>
      )
    } else {
      return utcTimeToBrowserLocal(timestamp)
    }
  }

  render() {
    const {
      classes,
      intl: { formatMessage },
      nodeId,
      unselectNode,
      node,
      deviceStatus
    } = this.props
    const { isMobileSignalLoading, mobileSignal } = this.state

    return (
      <TransitionGroup className={classes.transitionContainer}>
        <CSSTransition appear classNames='fadein' in nodeRef={this.cardRef} timeout={500}>
          <Card ref={this.cardRef} className={classes.mapPanelNode} id='node-details-panel-id'>
            <CardHeader
              action={
                <IconButton id='unselect-node-id' onClick={unselectNode}>
                  <CloseIcon />
                </IconButton>
              }
              title={node.name || ''}
            />
            <CardContent style={{ color: 'rgba(0,0,0,0.87)', fontSize: 14, paddingTop: 12 }}>
              <Grid container item xs={12}>
                <Typography style={{ fontSize: 18, marginBottom: 16 }}>
                  {formatMessage(messages.machineInformation)}
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Grid item lg={4} md={6} xs={6}>
                  <label>{formatMessage(messages.model)}</label>
                </Grid>
                <Grid item lg={8} md={6} xs={6}>
                  {node.machineModel || '-'}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item lg={4} md={6} xs={6}>
                  <label>{formatMessage(messages.machineType)}</label>
                </Grid>
                <Grid item lg={8} md={6} xs={6}>
                  {node.machineType || '-'}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item lg={4} md={6} xs={6}>
                  <label>{formatMessage(messages.brand)}</label>
                </Grid>
                <Grid item lg={8} md={6} xs={6}>
                  {node.machineBrand || '-'}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item lg={4} md={6} xs={6}>
                  <label>{formatMessage(messages.machineSerialNumber)}</label>
                </Grid>
                <Grid item lg={8} md={6} xs={6}>
                  {node.machineSerialNumber || '-'}
                </Grid>
              </Grid>
            </CardContent>
            <CardContent style={{ color: 'rgba(0,0,0,0.87)', fontSize: 14, paddingTop: 12 }}>
              <Grid container item xs={12}>
                <Typography style={{ fontSize: 18, marginBottom: 16 }}>
                  {formatMessage(messages.deviceInformation)}
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Grid item lg={4} md={6} xs={6}>
                  <label>{formatMessage(messages.cloudConnection)}</label>
                </Grid>
                <Grid item lg={8} md={6} xs={6}>
                  {deviceStatus ? deviceStatus : <CircularProgress size={14} />}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item lg={4} md={6} xs={6}>
                  <label>{formatMessage(messages.mobileSignal)}</label>
                </Grid>
                <Grid item lg={8} md={6} xs={6}>
                  {isMobileSignalLoading ? <CircularProgress size={14} /> : <MobileSignal data={mobileSignal} />}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item lg={4} md={6} xs={6}>
                  <label>{formatMessage(messages.lastTelemetryMessage)}</label>
                </Grid>
                <Grid item lg={8} md={6} xs={6}>
                  {node.lastActivityTime !== null ? utcTimeToBrowserLocal(node.lastActivityTime) : '--'}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item lg={4} md={6} xs={6}>
                  <label>{formatMessage(messages.lastGPSMessage)}</label>
                </Grid>
                <Grid item lg={8} md={6} xs={6}>
                  {node.lastGPSTimestamp ? this.getLastGPSMessage(node.lastGPSTimestamp) : '--'}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18 }}>{formatMessage(messages.actions)}</Typography>
                </Grid>
                <Grid container item justifyContent='flex-end' xs={6}>
                  <Grid item>
                    <MachineActions areAdvancedSignalsHidden nodeId={nodeId} style={{ justifyContent: 'flex-start' }} />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

Panel.propTypes = {
  classes: PropTypes.object.isRequired,
  deviceStatus: PropTypes.string,
  getDevice: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  name: PropTypes.string.isRequired,
  node: PropTypes.object.isRequired,
  nodeId: PropTypes.string.isRequired,
  unselectNode: PropTypes.func.isRequired
}

Panel.defaultProps = {
  deviceStatus: ''
}

export default injectIntl(withStyles(styles)(Panel))
