import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { NOTIFICATIONS_DETAILS_MODE } from '../../constants'
import messages from '../../messages'
import { dialogStyles, paperStyles, iconButtonStyles } from '../../styles'
import NotificationsDetailContent from '../NotificationsDetailContent'

const styles = theme => ({
  ...dialogStyles,
  ...paperStyles,
  ...iconButtonStyles(theme)
})

const NotificationsDetail = props => {
  const { classes, closeDialog, intl, notificationId, action } = props

  return (
    <Dialog PaperProps={{ classes: { root: classes.paperRoot } }} fullWidth maxWidth='md' onClose={closeDialog} open>
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        <Grid container>
          <Grid item xs={11}>
            {intl.formatMessage(messages.notificationDetails)}
          </Grid>
          <Grid container item justifyContent='flex-end' xs={1}>
            <IconButton classes={{ root: classes.iconButtonRoot }} onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Grid classes={{ root: classes.dialogContentGridContainer }} container spacing={2}>
          <NotificationsDetailContent
            action={action}
            closeDialog={closeDialog}
            mode={NOTIFICATIONS_DETAILS_MODE.PC}
            notificationId={notificationId}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

NotificationsDetail.propTypes = {
  action: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  notificationId: PropTypes.string.isRequired
}

export default injectIntl(withStyles(styles)(NotificationsDetail))
