import { defineMessages } from 'react-intl'

const messages = defineMessages({
  access: {
    id: 'Home.access',
    description: 'Access login button label',
    defaultMessage: 'Access'
  },
  learnMore: {
    id: 'Home.learnMore',
    description: 'Learn more button label',
    defaultMessage: 'Learn more'
  },
  logIn: {
    id: 'Home.logIn',
    description: 'Log in button label',
    defaultMessage: 'Log in'
  },
  signUp: {
    id: 'Home.signUp',
    description: 'Sign up button label',
    defaultMessage: 'Sign up'
  },
  subtitle: {
    id: 'Home.subtitle',
    description: 'Subtitle',
    defaultMessage: 'Maintain and diagnose your machine equipment in the field.'
  },
  description: {
    id: 'Home.description',
    description: 'Description text',
    defaultMessage:
      'A comprehensive tool to assemble packages and design app views that support your maintenance of machines. Prepare at home, install on the go.'
  },
  privacyPolicy: {
    id: 'Home.privacyPolicy',
    description: 'Privacy policy button label',
    defaultMessage: 'Privacy policy'
  },
  termsOfUse: {
    id: 'Home.termsOfUse',
    description: 'Terms of use button label',
    defaultMessage: 'Terms of use'
  },
  generalInformation: {
    id: 'Home.generalInformation',
    description: 'General information button label',
    defaultMessage: 'General information'
  },
  cookies: {
    id: 'Home.cookies',
    description: 'Cookies button label',
    defaultMessage: 'Cookies'
  },
  productOverview: {
    id: 'Home.productOverview',
    description: 'Product overview label',
    defaultMessage: 'Product overview'
  },
  signUpText: {
    id: 'Home.signUpText',
    defaultMessage: 'Don’t have an account yet? '
  }
})

export default messages
