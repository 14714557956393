import { generatePath } from 'react-router-dom'

const NOTIFICATIONS_BASE_URL = '/notifications'

export const NOTIFICATIONS_TEMPLATE_URL = NOTIFICATIONS_BASE_URL
export const NOTIFICATIONS_DETAIL_TEMPLATE_URL = NOTIFICATIONS_BASE_URL + '/:notificationId'

export function getNotificationsUrl() {
  return NOTIFICATIONS_TEMPLATE_URL
}

export function getNotificationDetailsUrl(notificationId) {
  return generatePath(NOTIFICATIONS_DETAIL_TEMPLATE_URL, { notificationId })
}
