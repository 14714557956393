import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import messages from './messages'
import { getOptionsForGeneralSettings } from '../config'
import { SETTINGS_FIELDS } from '../constants'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  },
  checkbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  checked: {}
}

const DeviceSettings = ({
  baudRate0,
  baudRate0ErrorMessage,
  baudRate1,
  baudRate1ErrorMessage,
  classes,
  fotaUpdateEnabled,
  handleFieldChange,
  interlinkEnabled,
  intl,
  isEditable,
  loggingUploadPeriod0,
  loggingUploadPeriod0ErrorMessage,
  loggingUploadPeriod1,
  loggingUploadPeriod1ErrorMessage,
  nodeId0,
  nodeId0ErrorMessage,
  nodeId1,
  nodeId1ErrorMessage,
  restartCellularModule,
  sa0,
  sa0ErrorMessage,
  sa1,
  sa1ErrorMessage,
  nodeId,
  nodeIdErrorMessage,
  isLegacyVersion
}) => {
  const { formatMessage } = intl

  return (
    <Card style={{ height: '100%', borderRadius: 0 }}>
      <CardHeader style={{ padding: 16 }} title={formatMessage(messages.deviceSettings)} />
      <CardContent style={{ padding: 16 }}>
        <Grid container spacing={3}>
          {isLegacyVersion ? null : isEditable ? (
            <Grid item style={{ padding: '12px 12px 0 12px' }} xs={12}>
              <FormControl error={nodeIdErrorMessage !== ''} style={{ width: '100%' }}>
                <InputLabel>{formatMessage(messages.nodeId)}</InputLabel>
                <Input
                  id={SETTINGS_FIELDS.nodeId}
                  inputProps={{ min: 0, max: 253 }}
                  onChange={event => {
                    handleFieldChange(SETTINGS_FIELDS.nodeId, event.target.value)
                  }}
                  onWheel={event => event.target.blur()}
                  placeholder='[0 - 253]'
                  type='number'
                  value={nodeId}
                />
                <FormHelperText>{nodeIdErrorMessage}</FormHelperText>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Typography>{formatMessage(messages.nodeId)}</Typography>
              <label>{nodeId}</label>
            </Grid>
          )}
          {isEditable ? (
            <Grid item md={6} style={{ padding: '12px 12px 0 12px' }} xs={12}>
              <FormControl error={nodeId0ErrorMessage !== ''} style={{ width: '100%' }}>
                <InputLabel>{formatMessage(messages.nodeId0)}</InputLabel>
                <Input
                  id={SETTINGS_FIELDS.nodeId0}
                  inputProps={{ min: 0, max: 253 }}
                  onChange={event => {
                    handleFieldChange(SETTINGS_FIELDS.nodeId0, event.target.value)
                  }}
                  onWheel={event => event.target.blur()}
                  placeholder='[0 - 253]'
                  type='number'
                  value={nodeId0}
                />
                <FormHelperText>{nodeId0ErrorMessage}</FormHelperText>
              </FormControl>
            </Grid>
          ) : (
            <Grid item md={3} xs={6}>
              <Typography>{formatMessage(messages.nodeId0)}</Typography>
              <label>{nodeId0}</label>
            </Grid>
          )}
          {isEditable ? (
            <Grid item md={6} style={{ padding: '12px 12px 0 12px' }} xs={12}>
              <FormControl error={nodeId1ErrorMessage !== ''} style={{ width: '100%' }}>
                <InputLabel>{formatMessage(messages.nodeId1)}</InputLabel>
                <Input
                  id={SETTINGS_FIELDS.nodeId1}
                  inputProps={{ min: 0, max: 253 }}
                  onChange={event => {
                    handleFieldChange(SETTINGS_FIELDS.nodeId1, event.target.value)
                  }}
                  onWheel={event => event.target.blur()}
                  placeholder='[0 - 253]'
                  type='number'
                  value={nodeId1}
                />
                <FormHelperText>{nodeId1ErrorMessage}</FormHelperText>
              </FormControl>
            </Grid>
          ) : (
            <Grid item md={3} xs={6}>
              <Typography>{formatMessage(messages.nodeId1)}</Typography>
              <label>{nodeId1}</label>
            </Grid>
          )}
          {isEditable ? (
            <Grid item md={6} style={{ padding: '12px 12px 0 12px' }} xs={12}>
              <FormControl error={baudRate0ErrorMessage !== ''} style={{ width: '100%' }}>
                <InputLabel>{formatMessage(messages.baudRate0)}</InputLabel>
                <Select
                  name={SETTINGS_FIELDS.baudRate0}
                  onChange={event => {
                    handleFieldChange(SETTINGS_FIELDS.baudRate0, event.target.value)
                  }}
                  value={baudRate0}
                >
                  {getOptionsForGeneralSettings.baudRate.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{baudRate0ErrorMessage}</FormHelperText>
              </FormControl>
            </Grid>
          ) : (
            <Grid item md={3} xs={6}>
              <Typography>{formatMessage(messages.baudRate0)}</Typography>
              <label>{getOptionsForGeneralSettings.baudRate.find(br => br.value === baudRate0)?.label}</label>
            </Grid>
          )}
          {isEditable ? (
            <Grid item md={6} style={{ padding: '12px 12px 0 12px' }} xs={12}>
              <FormControl error={baudRate1ErrorMessage !== ''} style={{ width: '100%' }}>
                <InputLabel>{formatMessage(messages.baudRate1)}</InputLabel>
                <Select
                  name={SETTINGS_FIELDS.baudRate1}
                  onChange={event => {
                    handleFieldChange(SETTINGS_FIELDS.baudRate1, event.target.value)
                  }}
                  value={baudRate1}
                >
                  {getOptionsForGeneralSettings.baudRate.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{baudRate1ErrorMessage}</FormHelperText>
              </FormControl>
            </Grid>
          ) : (
            <Grid item md={3} xs={6}>
              <Typography>{formatMessage(messages.baudRate1)}</Typography>
              <label>{getOptionsForGeneralSettings.baudRate.find(br => br.value === baudRate1)?.label}</label>
            </Grid>
          )}
          {isEditable ? (
            <Grid item md={6} style={{ padding: '12px 12px 0 12px' }} xs={12}>
              <FormControl error={sa0ErrorMessage !== ''} style={{ width: '100%' }}>
                <InputLabel>{formatMessage(messages.sa0)}</InputLabel>
                <Input
                  id={SETTINGS_FIELDS.sa0}
                  inputProps={{ min: 0, max: 255 }}
                  onChange={event => {
                    handleFieldChange(SETTINGS_FIELDS.sa0, event.target.value)
                  }}
                  onWheel={event => event.target.blur()}
                  placeholder='[0 - 255]'
                  type='number'
                  value={sa0}
                />
                <FormHelperText>{sa0ErrorMessage}</FormHelperText>
              </FormControl>
            </Grid>
          ) : (
            <Grid item md={3} xs={6}>
              <Typography>{formatMessage(messages.sa0)}</Typography>
              <label>{sa0}</label>
            </Grid>
          )}
          {isEditable ? (
            <Grid item md={6} style={{ padding: '12px 12px 0 12px' }} xs={12}>
              <FormControl error={sa1ErrorMessage !== ''} style={{ width: '100%' }}>
                <InputLabel>{formatMessage(messages.sa1)}</InputLabel>
                <Input
                  id={SETTINGS_FIELDS.sa1}
                  inputProps={{ min: 0, max: 255 }}
                  onChange={event => {
                    handleFieldChange(SETTINGS_FIELDS.sa1, event.target.value)
                  }}
                  onWheel={event => event.target.blur()}
                  placeholder='[0 - 255]'
                  type='number'
                  value={sa1}
                />
                <FormHelperText>{sa1ErrorMessage}</FormHelperText>
              </FormControl>
            </Grid>
          ) : (
            <Grid item md={3} xs={6}>
              <Typography>{formatMessage(messages.sa1)}</Typography>
              <label>{sa1}</label>
            </Grid>
          )}
          {isEditable ? (
            <Grid item md={6} style={{ padding: '12px 12px 0 12px' }} xs={12}>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                placement='top'
                title={formatMessage(messages.buffer0explanation)}
              >
                <FormControl error={loggingUploadPeriod0ErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.loggingUploadPeriod0)}</InputLabel>
                  <Input
                    id={SETTINGS_FIELDS.loggingUploadPeriod0}
                    inputProps={{ min: 10, max: 65535 }}
                    onChange={event => {
                      handleFieldChange(SETTINGS_FIELDS.loggingUploadPeriod0, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    placeholder='(s)'
                    type='number'
                    value={loggingUploadPeriod0}
                  />
                  <FormHelperText>{loggingUploadPeriod0ErrorMessage}</FormHelperText>
                </FormControl>
              </Tooltip>
            </Grid>
          ) : (
            <Grid item md={3} xs={6}>
              <Typography>{formatMessage(messages.loggingUploadPeriod0)}</Typography>
              <label>{loggingUploadPeriod0}</label>
            </Grid>
          )}
          {isEditable ? (
            <Grid item md={6} style={{ padding: '12px 12px 0 12px' }} xs={12}>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                placement='top'
                title={formatMessage(messages.buffer1explanation)}
              >
                <FormControl error={loggingUploadPeriod1ErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.loggingUploadPeriod1)}</InputLabel>
                  <Input
                    id={SETTINGS_FIELDS.loggingUploadPeriod1}
                    inputProps={{ min: 10, max: 65535 }}
                    onChange={event => {
                      handleFieldChange(SETTINGS_FIELDS.loggingUploadPeriod1, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    placeholder='(s)'
                    type='number'
                    value={loggingUploadPeriod1}
                  />
                  <FormHelperText>{loggingUploadPeriod1ErrorMessage}</FormHelperText>
                </FormControl>
              </Tooltip>
            </Grid>
          ) : (
            <Grid item md={3} xs={6}>
              <Typography>{formatMessage(messages.loggingUploadPeriod1)}</Typography>
              <label>{loggingUploadPeriod1}</label>
            </Grid>
          )}
          {isEditable ? (
            <Grid item md={6} style={{ padding: '12px 12px 0 12px' }} xs={12}>
              <div className='col-md-12' style={{ padding: 0 }}>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  placement='top'
                  title={formatMessage(messages.interlinkEnabledExplanation)}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={interlinkEnabled}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        id={SETTINGS_FIELDS.interlinkEnabled}
                        onClick={event => {
                          handleFieldChange(SETTINGS_FIELDS.interlinkEnabled, event.target.checked)
                        }}
                      />
                    }
                    label={formatMessage(messages.interlinkEnabled)}
                  />
                </Tooltip>
              </div>
            </Grid>
          ) : (
            <Grid item md={3} xs={6}>
              <Typography>{formatMessage(messages.interlinkEnabled)}</Typography>
              <label>{interlinkEnabled ? formatMessage(messages.true) : formatMessage(messages.false)}</label>
            </Grid>
          )}
          {isEditable ? (
            <Grid item md={6} style={{ padding: '12px 12px 0 12px' }} xs={12}>
              <div className='col-md-12' style={{ padding: 0 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fotaUpdateEnabled}
                      classes={{ root: classes.checkbox, checked: classes.checked }}
                      id={SETTINGS_FIELDS.fotaUpdateEnabled}
                      onClick={event => {
                        handleFieldChange(SETTINGS_FIELDS.fotaUpdateEnabled, event.target.checked)
                      }}
                    />
                  }
                  label={formatMessage(messages.fotaUpdateEnabled)}
                />
              </div>
            </Grid>
          ) : (
            <Grid item md={3} xs={6}>
              <Typography>{formatMessage(messages.fotaUpdateEnabled)}</Typography>
              <label>{fotaUpdateEnabled ? formatMessage(messages.true) : formatMessage(messages.false)}</label>
            </Grid>
          )}
          {isEditable ? (
            <Grid item md={6} style={{ padding: '12px 12px 0 12px' }} xs={12}>
              <div className='col-md-12' style={{ padding: 0 }}>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  placement='top'
                  title={formatMessage(messages.restartCellularModuleExplanation)}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={restartCellularModule}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        id={SETTINGS_FIELDS.restartCellularModule}
                        onClick={event => {
                          handleFieldChange(SETTINGS_FIELDS.restartCellularModule, event.target.checked)
                        }}
                      />
                    }
                    label={formatMessage(messages.restartCellularModule)}
                  />
                </Tooltip>
              </div>
            </Grid>
          ) : (
            <Grid item md={3} xs={6}>
              <Typography>{formatMessage(messages.restartCellularModule)}</Typography>
              <label>{restartCellularModule ? formatMessage(messages.true) : formatMessage(messages.false)}</label>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

DeviceSettings.propTypes = {
  baudRate0: PropTypes.number.isRequired,
  baudRate0ErrorMessage: PropTypes.string.isRequired,
  baudRate1: PropTypes.number.isRequired,
  baudRate1ErrorMessage: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  fotaUpdateEnabled: PropTypes.bool.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  interlinkEnabled: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isLegacyVersion: PropTypes.bool.isRequired,
  loggingUploadPeriod0: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  loggingUploadPeriod0ErrorMessage: PropTypes.string.isRequired,
  loggingUploadPeriod1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  loggingUploadPeriod1ErrorMessage: PropTypes.string.isRequired,
  nodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  nodeId0: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  nodeId0ErrorMessage: PropTypes.string.isRequired,
  nodeId1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  nodeId1ErrorMessage: PropTypes.string.isRequired,
  nodeIdErrorMessage: PropTypes.string.isRequired,
  restartCellularModule: PropTypes.bool.isRequired,
  sa0: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sa0ErrorMessage: PropTypes.string.isRequired,
  sa1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sa1ErrorMessage: PropTypes.string.isRequired
  // view: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(DeviceSettings))
