import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import messages from '../../../messages'
import { alertStyles } from '../../../styles'
import { getStatusFromAction } from '../../../utils'

const styles = {
  ...alertStyles
}

class NotificationUpdateSuccessAlert extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { classes, intl, action } = this.props

    const status = getStatusFromAction(action)
    const statusMessage = status ? intl.formatMessage(messages[status]) : ''

    return (
      <Alert classes={{ message: classes.alertMessage }} severity='success'>
        <AlertTitle classes={{ root: classes.alertTitle }}>
          <Grid container>
            <Grid item xs={11}>
              {intl.formatMessage(messages.success)}
            </Grid>
          </Grid>
        </AlertTitle>
        {intl.formatMessage(messages.notificationSuccessfullyUpdated, { status: statusMessage })}
      </Alert>
    )
  }
}

NotificationUpdateSuccessAlert.propTypes = {
  action: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(NotificationUpdateSuccessAlert))
