import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter, matchPath } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { withStyles } from '@material-ui/core/styles'

import PortalLoading from 'components/PortalLoading'
import { NOTIFICATIONS_DETAIL_TEMPLATE_URL } from 'components/notifications'
import { cacheDipToken } from 'ducks/login/cache'
import { useAuth0 } from 'react-auth0-spa'

import messages from './messages'

const styles = {
  title: {
    fontSize: '35px'
  },
  subheader: {
    fontSize: '20px'
  }
}

const Callback = ({ SSOLogin, unauthorize, userState, intl, classes, location }) => {
  const { getTokenSilently, getIdTokenClaims, isAuthenticated, loginWithRedirect, logout } = useAuth0()
  const { formatMessage } = intl
  const match = matchPath(location.pathname, { path: NOTIFICATIONS_DETAIL_TEMPLATE_URL, exact: true, strict: false })

  if (!isAuthenticated) {
    loginWithRedirect({})
    return null
  } else {
    try {
      getTokenSilently().then(ssoToken => {
        getIdTokenClaims().then(tokenClaims => {
          const token = ssoToken
          const email = tokenClaims.email
          const userId = tokenClaims['https://danfoss.com/analytics_user_id']
          //Get date token expires in (in seconds)
          const dateTokenExpiresIn = tokenClaims.exp
          if (userState !== 'failed') {
            SSOLogin(token, dateTokenExpiresIn, email, userId)
          } else {
            unauthorize()
            logout({ returnTo: process.env.REACT_APP_DIP_REDIRECT_LOGOUT_URI })
          }

          getTokenSilently({
            audience: 'https://MyDanfossAccountBackend.azurewebsites.net/',
            scope: 'read.users update.users'
          }).then(async accessTokenDIPProfileAPI => {
            cacheDipToken(accessTokenDIPProfileAPI)
          })
        })
      })
    } catch (error) {
      try {
        unauthorize()
        logout({ returnTo: process.env.REACT_APP_DIP_REDIRECT_LOGOUT_URI })
      } catch (error2) {
        unauthorize()
      }
    }
    if (userState === 'expired') {
      if (location?.pathname === `/notifications/${match?.params?.notificationId}`) {
        loginWithRedirect()
      } else {
        logout({ returnTo: process.env.REACT_APP_DIP_REDIRECT_LOGOUT_URI })
      }
      return null
    } else if (userState === 'failed') {
      return (
        <div style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}>
          <span
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: 200,
              margin: '-10px 0 0 -100px',
              textAlign: 'center'
            }}
          />
          <div>
            <Card>
              <CardHeader
                classes={{ title: classes.title, subheader: classes.subheader }}
                subheader={formatMessage(messages.subtitle)}
                title={formatMessage(messages.title)}
              />
              <CardContent style={{ textAlign: 'center' }}>
                <Button
                  className='primary-action-button'
                  onClick={() => {
                    unauthorize()
                    logout({ returnTo: process.env.REACT_APP_DIP_REDIRECT_LOGOUT_URI })
                  }}
                >
                  {formatMessage(messages.return)}
                </Button>
              </CardContent>
            </Card>
          </div>
        </div>
      )
    } else {
      return <PortalLoading />
    }
  }
}

Callback.propTypes = {
  SSOLogin: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  unauthorize: PropTypes.func.isRequired,
  userState: PropTypes.func.isRequired
}

export default withRouter(withStyles(styles)(injectIntl(Callback)))
