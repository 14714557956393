import { connect } from 'react-redux'

import { actions, utils as groupUtils } from 'ducks/groups'
import csNode from 'modules/csNode'

import UnfilledAssignMachinesToGroup from './AssignMachinesToGroup'
import { getGroupEditUrl } from '../urls'

const mapStateToProps = (state, { match, history }) => ({
  onCloseNoParentGroupErrorAlert: () => history.push(getGroupEditUrl(match.params.groupId)),
  getGroup: groupId => groupUtils.getCurrentGroup(state, groupId),
  groupsLoaded: groupUtils.groupsLoaded(state),
  groupEditUrl: getGroupEditUrl(match.params.groupId),
  groupId: match.params.groupId,
  onGroupEdited: () => {
    history.push(getGroupEditUrl(match.params.groupId), 'update')
  }
})

const mapDispatchToProps = dispatch => ({
  addDevicesToGroup: (groupId, deviceIds, deviceType, originGroupId) =>
    dispatch(actions.addDevicesToGroup(groupId, deviceIds, deviceType, originGroupId)),
  getAllGroups: () => dispatch(actions.getGroups()),
  // eslint-disable-next-line max-params
  getGroupDevices: (groupId, limit, offset, deviceFields, filters) =>
    dispatch(csNode.actions.fetchDynamicCSNodes(groupId, limit, offset, deviceFields, filters)),
  getDevicesDetail: (groupId, devicesEIDs) => dispatch(csNode.actions.fetchCSNodesDetails(groupId, devicesEIDs))
})

const AssignMachinesToGroup = connect(mapStateToProps, mapDispatchToProps)(UnfilledAssignMachinesToGroup)

export { AssignMachinesToGroup }
