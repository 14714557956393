import { createAction } from 'redux-actions'

import { parser } from 'utils/cs500'
import { actions } from 'utils/http'

import * as aT from './actionTypes'
import * as c from './constants'

const fetchDeviceNonVolatileConfiguration = (groupId, eid) => dispatch => {
  const { promise } = dispatch(actions.plus1Connect.getDeviceNonVolatileConfiguration(groupId, eid))
  return promise.then(({ payload }) => {
    const originalConfiguration = payload.data
    const parsedConfiguration = parser.cs500ConfigToCs100Config(originalConfiguration)

    const newPayload = {
      [eid]: {
        [c.ORIGINAL_CONFIGURATION]: originalConfiguration,
        [c.PARSED_CONFIGURATION]: parsedConfiguration
      }
    }

    dispatch({
      type: aT.SET_CS500_CONFIGURATIONS,
      payload: newPayload
    })

    return newPayload
  })
}

const fetchDeviceNonVolatileConfigurationByHash = (groupId, eid, configHash) => dispatch => {
  const { promise } = dispatch(actions.plus1Connect.getDeviceNonVolatileConfigurationByHash(groupId, eid, configHash))
  return promise.then(({ payload }) => {
    const originalConfiguration = payload.data
    const parsedConfiguration = parser.cs500ConfigToCs100Config(originalConfiguration)

    const newPayload = {
      [eid]: {
        [c.ORIGINAL_CONFIGURATION]: originalConfiguration,
        [c.PARSED_CONFIGURATION]: parsedConfiguration
      }
    }

    dispatch({
      type: aT.SET_CS500_CONFIGURATIONS,
      payload: newPayload
    })

    return newPayload
  })
}

const fetchNewestNVConfigurationDefinition = () => dispatch => {
  const { promise } = dispatch(actions.plus1Connect.getNewestNVConfigurationDefinition())
  return promise.then(({ payload }) => {
    return payload
  })
}

const changeLocalConfigurationField = (field, value) => dispatch =>
  dispatch(
    createAction(aT.NV_CONFIG_FIELD_CHANGE, () => ({
      field,
      value
    }))()
  )

const localConfigurationError = errors => dispatch => {
  return dispatch(createAction(aT.NV_CONFIG_ERROR, () => ({ errors }))())
}

const addLocalConfigurationSignal = () => dispatch => dispatch(createAction(aT.NV_CONFIG_ADD_SIGNAL)())

const addLocalConfigurationCANMessage = () => dispatch => dispatch(createAction(aT.NV_CONFIG_ADD_CAN_MESSAGE)())

const copyLocalConfigurationMessage = canMessageId => dispatch =>
  dispatch(createAction(aT.NV_CONFIG_COPY_CAN_MESSAGE, () => ({ canMessageId }))())

const deleteLocalConfigurationMessage = canMessageId => dispatch =>
  dispatch(createAction(aT.NV_CONFIG_DELETE_CAN_MESSAGE, () => ({ canMessageId }))())

const copyLocalConfigurationSignal = signalId => dispatch =>
  dispatch(createAction(aT.NV_CONFIG_COPY_SIGNAL, () => ({ signalId }))())

const deleteLocalConfigurationSignal = signalId => dispatch =>
  dispatch(createAction(aT.NV_CONFIG_DELETE_SIGNAL, () => ({ signalId }))())

const changeLocalConfigurationSignalField = (signalId, field, value) => dispatch =>
  dispatch(createAction(aT.NV_CONFIG_SIGNAL_FIELD_CHANGE, () => ({ signalId, field, value }))())

const changeLocalConfigurationCANMessageField = (canMessageId, field, value) => dispatch =>
  dispatch(createAction(aT.NV_CONFIG_CAN_MESSAGE_FIELD_CHANGE, () => ({ canMessageId, field, value }))())

const makeSignalIdEditable = (signalId, isEditable) => dispatch =>
  dispatch(createAction(aT.EDITABLE_SIGNAL_ID_NVC, () => ({ signalId, isEditable }))())

const changeLocalConfigurationSignalId = (signalId, newSignalId) => dispatch =>
  dispatch(createAction(aT.NV_CONFIG_SIGNAL_ID_CHANGE, () => ({ signalId, newSignalId }))())

const validateLocalConfigurationSignalId = signalId => dispatch =>
  dispatch(createAction(aT.NV_CONFIG_SIGNAL_ID_VALIDATE, () => ({ signalId }))())

const manageLocalConfigurationAlert = alertState => dispatch =>
  dispatch(createAction(aT.NV_CONFIG_MANAGE_ALERT, () => alertState)())

const setLocalConfiguration = state => dispatch => dispatch(createAction(aT.SET_NV_CONFIG, () => state)())

const resetLocalConfiguration = () => dispatch => dispatch(createAction(aT.RESET_NV_CONFIG)())

export {
  addLocalConfigurationCANMessage,
  addLocalConfigurationSignal,
  changeLocalConfigurationCANMessageField,
  changeLocalConfigurationField,
  changeLocalConfigurationSignalField,
  changeLocalConfigurationSignalId,
  copyLocalConfigurationMessage,
  copyLocalConfigurationSignal,
  deleteLocalConfigurationMessage,
  deleteLocalConfigurationSignal,
  fetchDeviceNonVolatileConfiguration,
  fetchDeviceNonVolatileConfigurationByHash,
  fetchNewestNVConfigurationDefinition,
  localConfigurationError,
  makeSignalIdEditable,
  manageLocalConfigurationAlert,
  resetLocalConfiguration,
  setLocalConfiguration,
  validateLocalConfigurationSignalId
}
