import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import { injectIntl } from 'react-intl'

import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'

import messages from '../../../messages'

const styles = {
  groupLabel: {
    color: 'black',
    fontWeight: 'bold'
  },
  tag: {
    height: '26px'
  },
  inputLabel: {
    color: 'inherit',
    fontSize: '19px',
    fontWeight: '500'
  },
  input: {
    '&::placeholder': {
      color: 'black',
      fontSize: '18px'
    }
  }
}

const MachineSelector = React.memo(props => {
  const { classes, intl, onDeviceSelect, selectedDevices, unselectedDevices } = props

  const [isInputFocused, setIsInputFocused] = useState(false)

  const handleSelectChange = useCallback(
    (event, [deviceOption]) => {
      if (deviceOption) {
        onDeviceSelect(deviceOption.value)
      }
    },
    [onDeviceSelect]
  )

  return (
    <Autocomplete
      classes={{ groupLabel: classes.groupLabel, tag: classes.tag }}
      disableClearable
      getOptionLabel={option => option.label || ''}
      getOptionSelected={(option, value) => option.value === value}
      multiple
      onChange={handleSelectChange}
      options={unselectedDevices.map(device => ({ label: device.name, value: device }))}
      renderInput={params => (
        <TextField
          {...params}
          InputLabelProps={{
            ...params.InputLabelProps,
            classes: { shrink: classes.inputLabel },
            shrink: isInputFocused || selectedDevices.length > 0
          }}
          InputProps={{
            ...params.InputProps,
            classes: {
              input: classes.input
            }
          }}
          label={intl.formatMessage(messages.selectMachines)}
          onBlur={() => setIsInputFocused(false)}
          onFocus={() => setIsInputFocused(true)}
          placeholder='...'
        />
      )}
      value={[]}
    />
  )
})

MachineSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  onDeviceSelect: PropTypes.func.isRequired,
  selectedDevices: PropTypes.array.isRequired,
  unselectedDevices: PropTypes.array.isRequired
}

export default injectIntl(withStyles(styles)(MachineSelector))
