import { defineMessages } from 'react-intl'

const namespace = 'NodePanel'

const messages = defineMessages({
  eid: {
    id: `${namespace}.eid`,
    defaultMessage: 'EID'
  },
  description: {
    id: `${namespace}.description`,
    defaultMessage: 'Description'
  },
  lastTelemetryMessage: {
    id: `${namespace}.lastTelemetryMessage`,
    defaultMessage: 'Last telemetry message'
  },
  actions: {
    id: `${namespace}.actions`,
    defaultMessage: 'Actions'
  },
  never: {
    id: `${namespace}.never`,
    defaultMessage: 'Never'
  },
  ago: {
    id: `${namespace}.ago`,
    defaultMessage: '{duration} ago'
  },
  model: {
    id: `${namespace}.model`,
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    defaultMessage: 'Machine type'
  },
  brand: {
    id: `${namespace}.brand`,
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    defaultMessage: 'Machine serial number'
  },
  cloudConnection: {
    id: `${namespace}.cloudConnection`,
    defaultMessage: 'Cloud connection'
  },
  mobileSignal: {
    id: `${namespace}.mobileSignal`,
    defaultMessage: 'Mobile signal'
  },
  lastGPSMessage: {
    id: `${namespace}.lastGPSMessage`,
    defaultMessage: 'Last GPS message'
  },
  lastGPSMessageOlderThan24Hours: {
    id: `${namespace}.lastGPSMessageOlderThan24Hours`,
    defaultMessage: 'The last GPS message is older than 24 hours'
  },
  machineInformation: {
    id: `${namespace}.machineInformation`,
    defaultMessage: 'Machine information'
  },
  deviceInformation: {
    id: `${namespace}.deviceInformation`,
    defaultMessage: 'Device information'
  },
  lastTelemetryMessage: {
    id: `${namespace}.lastTelemetryMessage`,
    defaultMessage: 'Last telemetry message'
  }
})

export default messages
