import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { actions } from 'ducks/configurationsCS500'
import { utils as groupUtils } from 'ducks/groups'
import csNode from 'modules/csNode'

import SentCANHistoricTable from './SentCANHistoricTable'

const mapStateToProps = (state, { match }) => ({
  deviceId: match.params.deviceId,
  groupId: groupUtils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSentCANHistoricMessages: csNode.actions.getSentCANHistoricMessages,
      getCSNode: csNode.actions.fetchCSNode,
      fetchDeviceNonVolatileConfigurationByHash: actions.fetchDeviceNonVolatileConfigurationByHash
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SentCANHistoricTable))
