import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import messages from './messages'
import { getOptionsForMachineStateDetermination } from '../config'
import { MACHINE_STATE_DETERMINATION_FIELDS } from '../constants'

const styles = theme => ({
  expand: {
    transform: 'rotate(0deg)'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  title: {
    paddingLeft: 16
  },
  root: {
    padding: 0
  },
  action: {
    margin: 0
  },
  actionsRoot: {
    paddingRight: 1
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  },
  button: {
    margin: 0
  },
  checkbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  checked: {}
})

const MachineStateDetermination = ({
  byte0,
  byte0ErrorMessage,
  byte1,
  byte1ErrorMessage,
  byte2,
  byte2ErrorMessage,
  byte3,
  byte3ErrorMessage,
  byte4,
  byte4ErrorMessage,
  byte5,
  byte5ErrorMessage,
  byte6,
  byte6ErrorMessage,
  byte7,
  byte7ErrorMessage,
  canId,
  canIdErrorMessage,
  canPort,
  canPortErrorMessage,
  changeLocalConfigurationField,
  classes,
  digitalInputPin,
  digitalInputPinErrorMessage,
  enableCan,
  enableDigitalInput,
  enableServiceToolButton,
  expanded,
  forceFullAccessMode,
  intl,
  isEditable
}) => {
  /* eslint-disable react/jsx-key */
  const { formatMessage } = intl
  const optionsForMachineStateDeterminationSelects = getOptionsForMachineStateDetermination()

  const handleHexadecimalValueChange = (variableId, event) => {
    const twoHexByte = /[^[0-9a-fA-F]$/
    changeLocalConfigurationField(variableId, event.target.value.replace(twoHexByte, ''))
  }

  const startAdornmentComponent = () => (
    <InputAdornment position='start' style={{ marginRight: 2 }}>
      <Typography
        style={{
          fontSize: 16,
          fontWeight: 'bold',
          color: 'black'
        }}
      >
        0x
      </Typography>
    </InputAdornment>
  )

  return (
    <Card style={{ height: '100%', borderRadius: 0 }}>
      <CardHeader
        action={
          <CardActions classes={{ root: classes.actionsRoot }}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={expanded ? formatMessage(messages.collapse) : formatMessage(messages.expand)}
            >
              <IconButton
                classes={{ root: classes.button }}
                onClick={() => {
                  changeLocalConfigurationField(
                    MACHINE_STATE_DETERMINATION_FIELDS.machineStateDeterminationExpanded,
                    !expanded
                  )
                }}
              >
                {expanded ? (
                  <ExpandMoreIcon className={(classes.expand, classes.expandOpen)} fontSize='small' />
                ) : (
                  <ExpandMoreIcon className={classes.expand} fontSize='small' />
                )}
              </IconButton>
            </Tooltip>
          </CardActions>
        }
        classes={{
          title: classes.title,
          root: classes.root,
          action: classes.action
        }}
        title={formatMessage(messages.machineStateDetermination)}
      />
      <Collapse in={expanded}>
        <CardContent style={{ padding: 16 }}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {formatMessage(messages.machineStateDeterminationExplanation)}
              </Typography>
            </Grid>
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} style={{ padding: '0px 12px 0px 12px' }} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={forceFullAccessMode}
                      classes={{ root: classes.checkbox, checked: classes.checked }}
                      id={MACHINE_STATE_DETERMINATION_FIELDS.forceFullAccessMode}
                      onClick={event => {
                        changeLocalConfigurationField(
                          MACHINE_STATE_DETERMINATION_FIELDS.forceFullAccessMode,
                          event.target.checked
                        )
                      }}
                    />
                  }
                  label={formatMessage(messages.forceFullAccessMode)}
                  style={{ marginTop: '15px', marginBottom: 0 }}
                />
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.forceFullAccessMode)}</Typography>
                <label>{forceFullAccessMode ? formatMessage(messages.true) : formatMessage(messages.false)}</label>
              </Grid>
            )}
          </Grid>
          {!forceFullAccessMode && (
            <Grid container spacing={3} style={{ paddingTop: 16 }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography noWrap style={{ fontWeight: 'bold' }}>
                  {formatMessage(messages.can)}
                </Typography>
                <Divider style={{ backgroundColor: 'black', margin: '0 0 20px 0' }} />
                <Typography>{formatMessage(messages.canExplanationMessage)}</Typography>
              </Grid>
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} style={{ padding: '0px 12px 0px 12px' }} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={enableCan}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        id={MACHINE_STATE_DETERMINATION_FIELDS.enableCan}
                        onClick={event => {
                          changeLocalConfigurationField(
                            MACHINE_STATE_DETERMINATION_FIELDS.enableCan,
                            event.target.checked
                          )
                        }}
                      />
                    }
                    label={formatMessage(messages.enable)}
                    style={{ marginTop: '15px', marginBottom: 0 }}
                  />
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.enabled)}</Typography>
                  <label>{enableCan ? formatMessage(messages.true) : formatMessage(messages.false)}</label>
                </Grid>
              )}
            </Grid>
          )}
          {!forceFullAccessMode && enableCan && (
            <Grid container spacing={3}>
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={canPortErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>{formatMessage(messages.canPort)}</InputLabel>
                    <Select
                      name={MACHINE_STATE_DETERMINATION_FIELDS.canPort}
                      onChange={event => {
                        changeLocalConfigurationField(MACHINE_STATE_DETERMINATION_FIELDS.canPort, event.target.value)
                      }}
                      value={canPort}
                    >
                      {optionsForMachineStateDeterminationSelects.canPort.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{canPortErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.canPort)}</Typography>
                  <label>
                    {optionsForMachineStateDeterminationSelects.canPort.find(p => p.value === canPort)?.label}
                  </label>
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={canIdErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>{formatMessage(messages.canId)}</InputLabel>
                    <Input
                      id={MACHINE_STATE_DETERMINATION_FIELDS.canId}
                      inputProps={{ maxLength: 8 }}
                      onChange={event => handleHexadecimalValueChange(MACHINE_STATE_DETERMINATION_FIELDS.canId, event)}
                      placeholder=' [0x00 - 0x1FFFFFFF]'
                      startAdornment={startAdornmentComponent()}
                      value={canId}
                    />
                    <FormHelperText>{canIdErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.canId)}</Typography>
                  <label>{'0x' + canId}</label>
                </Grid>
              )}
            </Grid>
          )}
          {!forceFullAccessMode && enableCan && (
            <Grid container spacing={3}>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Grid container spacing={3}>
                  {isEditable ? (
                    <Grid item xs={6}>
                      <FormControl error={byte0ErrorMessage !== ''} style={{ width: '100%' }}>
                        <InputLabel>{formatMessage(messages.byte, { number: 0 })}</InputLabel>
                        <Input
                          id={MACHINE_STATE_DETERMINATION_FIELDS.byte0}
                          inputProps={{ maxLength: 2 }}
                          onChange={event =>
                            handleHexadecimalValueChange(MACHINE_STATE_DETERMINATION_FIELDS.byte0, event)
                          }
                          placeholder=' [0x00 - 0xFF]'
                          startAdornment={startAdornmentComponent()}
                          value={byte0}
                        />
                        <FormHelperText>{byte0ErrorMessage}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item lg={1} md={2} sm={3} xs={4}>
                      <Typography>{formatMessage(messages.byte, { number: 0 })}</Typography>
                      <label>{'0x' + byte0}</label>
                    </Grid>
                  )}
                  {isEditable ? (
                    <Grid item xs={6}>
                      <FormControl error={byte1ErrorMessage !== ''} style={{ width: '100%' }}>
                        <InputLabel>{formatMessage(messages.byte, { number: 1 })}</InputLabel>
                        <Input
                          id={MACHINE_STATE_DETERMINATION_FIELDS.byte1}
                          inputProps={{ maxLength: 2 }}
                          onChange={event =>
                            handleHexadecimalValueChange(MACHINE_STATE_DETERMINATION_FIELDS.byte1, event)
                          }
                          placeholder=' [0x00 - 0xFF]'
                          startAdornment={startAdornmentComponent()}
                          value={byte1}
                        />
                        <FormHelperText>{byte1ErrorMessage}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item lg={1} md={2} sm={3} xs={4}>
                      <Typography>{formatMessage(messages.byte, { number: 1 })}</Typography>
                      <label>{'0x' + byte1}</label>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Grid container spacing={3}>
                  {isEditable ? (
                    <Grid item xs={6}>
                      <FormControl error={byte2ErrorMessage !== ''} style={{ width: '100%' }}>
                        <InputLabel>{formatMessage(messages.byte, { number: 2 })}</InputLabel>
                        <Input
                          id={MACHINE_STATE_DETERMINATION_FIELDS.byte2}
                          inputProps={{ maxLength: 2 }}
                          onChange={event =>
                            handleHexadecimalValueChange(MACHINE_STATE_DETERMINATION_FIELDS.byte2, event)
                          }
                          placeholder=' [0x00 - 0xFF]'
                          startAdornment={startAdornmentComponent()}
                          value={byte2}
                        />
                        <FormHelperText>{byte2ErrorMessage}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item lg={1} md={2} sm={3} xs={4}>
                      <Typography>{formatMessage(messages.byte, { number: 2 })}</Typography>
                      <label>{'0x' + byte2}</label>
                    </Grid>
                  )}
                  {isEditable ? (
                    <Grid item xs={6}>
                      <FormControl error={byte3ErrorMessage !== ''} style={{ width: '100%' }}>
                        <InputLabel>{formatMessage(messages.byte, { number: 3 })}</InputLabel>
                        <Input
                          id={MACHINE_STATE_DETERMINATION_FIELDS.byte3}
                          inputProps={{ maxLength: 2 }}
                          onChange={event =>
                            handleHexadecimalValueChange(MACHINE_STATE_DETERMINATION_FIELDS.byte3, event)
                          }
                          placeholder=' [0x00 - 0xFF]'
                          startAdornment={startAdornmentComponent()}
                          value={byte3}
                        />
                        <FormHelperText>{byte3ErrorMessage}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item lg={1} md={2} sm={3} xs={4}>
                      <Typography>{formatMessage(messages.byte, { number: 3 })}</Typography>
                      <label>{'0x' + byte3}</label>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Grid container spacing={3}>
                  {isEditable ? (
                    <Grid item xs={6}>
                      <FormControl error={byte4ErrorMessage !== ''} style={{ width: '100%' }}>
                        <InputLabel>{formatMessage(messages.byte, { number: 4 })}</InputLabel>
                        <Input
                          id={MACHINE_STATE_DETERMINATION_FIELDS.byte4}
                          inputProps={{ maxLength: 2 }}
                          onChange={event =>
                            handleHexadecimalValueChange(MACHINE_STATE_DETERMINATION_FIELDS.byte4, event)
                          }
                          placeholder=' [0x00 - 0xFF]'
                          startAdornment={startAdornmentComponent()}
                          value={byte4}
                        />
                        <FormHelperText>{byte4ErrorMessage}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item lg={1} md={2} sm={3} xs={4}>
                      <Typography>{formatMessage(messages.byte, { number: 4 })}</Typography>
                      <label>{'0x' + byte4}</label>
                    </Grid>
                  )}
                  {isEditable ? (
                    <Grid item xs={6}>
                      <FormControl error={byte5ErrorMessage !== ''} style={{ width: '100%' }}>
                        <InputLabel>{formatMessage(messages.byte, { number: 5 })}</InputLabel>
                        <Input
                          id={MACHINE_STATE_DETERMINATION_FIELDS.byte5}
                          inputProps={{ maxLength: 2 }}
                          onChange={event =>
                            handleHexadecimalValueChange(MACHINE_STATE_DETERMINATION_FIELDS.byte5, event)
                          }
                          placeholder=' [0x00 - 0xFF]'
                          startAdornment={startAdornmentComponent()}
                          value={byte5}
                        />
                        <FormHelperText>{byte5ErrorMessage}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item lg={1} md={2} sm={3} xs={4}>
                      <Typography>{formatMessage(messages.byte, { number: 5 })}</Typography>
                      <label>{'0x' + byte5}</label>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Grid container spacing={3}>
                  {isEditable ? (
                    <Grid item xs={6}>
                      <FormControl error={byte6ErrorMessage !== ''} style={{ width: '100%' }}>
                        <InputLabel>{formatMessage(messages.byte, { number: 6 })}</InputLabel>
                        <Input
                          id={MACHINE_STATE_DETERMINATION_FIELDS.byte6}
                          inputProps={{ maxLength: 2 }}
                          onChange={event =>
                            handleHexadecimalValueChange(MACHINE_STATE_DETERMINATION_FIELDS.byte6, event)
                          }
                          placeholder=' [0x00 - 0xFF]'
                          startAdornment={startAdornmentComponent()}
                          value={byte6}
                        />
                        <FormHelperText>{byte6ErrorMessage}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item lg={1} md={2} sm={3} xs={4}>
                      <Typography>{formatMessage(messages.byte, { number: 6 })}</Typography>
                      <label>{'0x' + byte6}</label>
                    </Grid>
                  )}
                  {isEditable ? (
                    <Grid item xs={6}>
                      <FormControl error={byte7ErrorMessage !== ''} style={{ width: '100%' }}>
                        <InputLabel>{formatMessage(messages.byte, { number: 7 })}</InputLabel>
                        <Input
                          id={MACHINE_STATE_DETERMINATION_FIELDS.byte7}
                          inputProps={{ maxLength: 2 }}
                          onChange={event =>
                            handleHexadecimalValueChange(MACHINE_STATE_DETERMINATION_FIELDS.byte7, event)
                          }
                          placeholder=' [0x00 - 0xFF]'
                          startAdornment={startAdornmentComponent()}
                          value={byte7}
                        />
                        <FormHelperText>{byte7ErrorMessage}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item lg={1} md={2} sm={3} xs={4}>
                      <Typography>{formatMessage(messages.byte, { number: 7 })}</Typography>
                      <label>{'0x' + byte7}</label>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          {!forceFullAccessMode && (
            <Grid container spacing={3} style={{ paddingTop: 16 }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography noWrap style={{ fontWeight: 'bold' }}>
                  {formatMessage(messages.digitalInput)}
                </Typography>
                <Divider style={{ backgroundColor: 'black', margin: '0 0 20px 0' }} />
                <Typography>{formatMessage(messages.digitalInputExplanationMessage)}</Typography>
              </Grid>
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} style={{ padding: '0px 12px 0px 12px' }} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={enableDigitalInput}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        id={MACHINE_STATE_DETERMINATION_FIELDS.enableDigitalInput}
                        onClick={event => {
                          changeLocalConfigurationField(
                            MACHINE_STATE_DETERMINATION_FIELDS.enableDigitalInput,
                            event.target.checked
                          )
                        }}
                      />
                    }
                    label={formatMessage(messages.enable)}
                    style={{ marginTop: '15px', marginBottom: 0 }}
                  />
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.enabled)}</Typography>
                  <label>{enableDigitalInput ? formatMessage(messages.true) : formatMessage(messages.false)}</label>
                </Grid>
              )}
            </Grid>
          )}
          {!forceFullAccessMode && enableDigitalInput && (
            <Grid container spacing={3}>
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={digitalInputPinErrorMessage !== ''} style={{ width: '100%' }}>
                    <InputLabel>{formatMessage(messages.digitalInput)}</InputLabel>
                    <Select
                      name={MACHINE_STATE_DETERMINATION_FIELDS.digitalInputPin}
                      onChange={event => {
                        changeLocalConfigurationField(
                          MACHINE_STATE_DETERMINATION_FIELDS.digitalInputPin,
                          event.target.value
                        )
                      }}
                      value={digitalInputPin}
                    >
                      {optionsForMachineStateDeterminationSelects.digitalInputPin.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{digitalInputPinErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.digitalInput)}</Typography>
                  <label>
                    {
                      optionsForMachineStateDeterminationSelects.digitalInputPin.find(p => p.value === digitalInputPin)
                        ?.label
                    }
                  </label>
                </Grid>
              )}
            </Grid>
          )}
          {!forceFullAccessMode && (
            <Grid container spacing={3} style={{ paddingTop: 16 }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography noWrap style={{ fontWeight: 'bold' }}>
                  {formatMessage(messages.serviceToolButton)}
                </Typography>
                <Divider style={{ backgroundColor: 'black', margin: '0 0 20px 0' }} />
                <Typography>{formatMessage(messages.serviceToolButtonExplanationMessage)}</Typography>
              </Grid>
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} style={{ padding: '0px 12px 0px 12px' }} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={enableServiceToolButton}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        id={MACHINE_STATE_DETERMINATION_FIELDS.enableServiceToolButton}
                        onClick={event => {
                          changeLocalConfigurationField(
                            MACHINE_STATE_DETERMINATION_FIELDS.enableServiceToolButton,
                            event.target.checked
                          )
                        }}
                      />
                    }
                    label={formatMessage(messages.enable)}
                    style={{ marginTop: '15px', marginBottom: 0 }}
                  />
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.enabled)}</Typography>
                  <label>
                    {enableServiceToolButton ? formatMessage(messages.true) : formatMessage(messages.false)}
                  </label>
                </Grid>
              )}
            </Grid>
          )}
        </CardContent>
      </Collapse>
    </Card>
  )
}

MachineStateDetermination.propTypes = {
  byte0: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  byte0ErrorMessage: PropTypes.string.isRequired,
  byte1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  byte1ErrorMessage: PropTypes.string.isRequired,
  byte2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  byte2ErrorMessage: PropTypes.string.isRequired,
  byte3: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  byte3ErrorMessage: PropTypes.string.isRequired,
  byte4: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  byte4ErrorMessage: PropTypes.string.isRequired,
  byte5: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  byte5ErrorMessage: PropTypes.string.isRequired,
  byte6: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  byte6ErrorMessage: PropTypes.string.isRequired,
  byte7: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  byte7ErrorMessage: PropTypes.string.isRequired,
  canId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  canIdErrorMessage: PropTypes.string.isRequired,
  canPort: PropTypes.number.isRequired,
  canPortErrorMessage: PropTypes.string.isRequired,
  changeLocalConfigurationField: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  digitalInputPin: PropTypes.number.isRequired,
  digitalInputPinErrorMessage: PropTypes.string.isRequired,
  enableCan: PropTypes.bool.isRequired,
  enableDigitalInput: PropTypes.bool.isRequired,
  enableServiceToolButton: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  forceFullAccessMode: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired
}

export default withStyles(styles)(injectIntl(MachineStateDetermination))
