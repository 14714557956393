import moment from 'moment'
import React from 'react'

import TreeListGroups from './TreeList'
import messages from './messages'

const groupsHeaderToListitems = formatMessage => [
  {
    // Item to "simulate" the TreeList Header
    title: (
      <TreeListGroups
        actionsTitle={formatMessage(messages.actions)}
        className='listHeader'
        createdDate={formatMessage(messages.createdAt)}
        description={formatMessage(messages.groupName)}
        hashId=''
        lastModifiedDate={formatMessage(messages.modifiedAt)}
        nodeCount={formatMessage(messages.machines)}
      />
    ),
    parentIndex: 0,
    depth: 1,
    disabled: !0
  }
]

const processGroupsHierarchyToListitems = (userGroup, groupsHierarchy, deleteGroup) => {
  const listItems = []

  const deleteGroupFunction = deleteGroup

  const addItem = (group, parentIndex = 0, depth = 1) => {
    const item = {
      title: (
        <TreeListGroups
          actionsTitle=''
          className=''
          createdDate={group.createdDate}
          deleteGroup={() => {
            deleteGroupFunction(group)
          }}
          description={group.description}
          groupName={group.name}
          hashId={group.hashId}
          lastModifiedDate={group.lastModifiedDate}
          name={group.name}
          nodeCount={group.nodeCount}
          userGroup={userGroup}
        />
      ),
      parentIndex,
      depth,
      disabled: !1
    }

    const index = listItems.length
    listItems.push(item)

    if (group.children.length > 0) {
      item.children = group.children.map(children => addItem(children, index, depth + 1))
    }

    return index
  }

  groupsHierarchy.map(group => addItem(group))

  return listItems
}

const processGroupsHierarchyToSelect = (groupsHierarchy, hashIdValue) => {
  const listItems = []

  const addItem = (group, parentIndex = 0, depth = 1) => {
    if (group.hashId !== hashIdValue) {
      const item = {
        title: group.description,
        hashId: group.hashId,
        parentIndex,
        depth,
        disabled: !1
      }

      const index = listItems.length
      listItems.push(item)

      if (group.children.length > 0) {
        item.children = group.children.map(children => addItem(children, index, depth + 1))
      }

      return index
    }
  }

  groupsHierarchy.map(group => addItem(group))

  return listItems
}

const getParentGroup = (groups = [], parentGroupHashId) => {
  const parentGroup = groups.filter(group => {
    return group.hashId === parentGroupHashId
  })

  return parentGroup[0]
}

const mapGroupProperties = groups => {
  const activeGroups = groups.filter(group => group.status_id === 1)
  const mappedGroups = activeGroups.map(group => ({
    hashId: group.id,
    name: group.group_name,
    createdDate: moment(group.createdAt).format('DD-MMM-YYYY'),
    lastModifiedDate: moment(group.updatedAt).format('DD-MMM-YYYY'),
    nodeCount: group.nodeCount,
    description: group.group_name,
    parent_id: group.parent_id,
    groupOwnerId: group.group_owner_id,
    children: []
  }))
  return mappedGroups
}

const getChildrenAndHashIds = (parentHashId, childrenGroups) => {
  const directChildren = childrenGroups.filter(group => group.parent_id === parentHashId)
  if (directChildren.length === 0) return [[], []]
  else {
    let offsprings = childrenGroups.filter(group => !group.parent_id !== parentHashId)
    return directChildren.reduce(
      (acc, cur) => {
        const [children, childrenHashIds] = getChildrenAndHashIds(cur.hashId, offsprings)
        offsprings = offsprings.filter(group => !childrenHashIds.includes(group.hashId))
        const currentWithChildren = { ...cur, children }
        const hashIds = [cur.hashId].concat(childrenHashIds)
        return [acc[0].concat([currentWithChildren]), acc[1].concat(hashIds)]
      },
      [[], []]
    )
  }
}

const mapToHierarchicalGroups = groups => {
  const groupsWithUpdatedProperties = mapGroupProperties(groups)
  const allHashIds = groupsWithUpdatedProperties.map(group => group.hashId)
  // eslint-disable-next-line prefer-const
  let [rootGroups, childrenGroups] = groupsWithUpdatedProperties.reduce(
    (acc, cur) => {
      if (allHashIds.includes(cur.parent_id)) return [acc[0], acc[1].concat([cur])]
      else return [acc[0].concat([cur]), acc[1]]
    },
    [[], []]
  )
  const hierarchicGroups = rootGroups.map(group => {
    const [children, hashIds] = getChildrenAndHashIds(group.hashId, childrenGroups)
    // eslint-disable-next-line no-shadow
    childrenGroups = childrenGroups.filter(group => !hashIds.includes(group.hashId))
    return { ...group, children }
  })
  return hierarchicGroups
}

const mapDevicesToTypeGroupedDevices = devices =>
  devices.reduce((acc, cur) => {
    const { device_type, id } = cur
    const curTypeGroup = [id]
    if (Object.keys(acc).includes(device_type)) {
      curTypeGroup.splice(0, 0, ...acc[device_type])
    }
    return { ...acc, [device_type]: curTypeGroup }
  }, {})

const mapToHierarchicalGroupsFromSpecificGroup = (groups, specificGroupId) => {
  const groupsWithUpdatedProperties = mapGroupProperties(groups)
  const allHashIds = groupsWithUpdatedProperties.map(group => group.hashId)
  const [rootGroups, childrenGroups] = groupsWithUpdatedProperties.reduce(
    (acc, cur) => {
      if (allHashIds.includes(cur.parent_id)) return [acc[0], acc[1].concat([cur])]
      else return [acc[0].concat([cur]), acc[1]]
    },
    [[], []]
  )

  const [children] = getChildrenAndHashIds(specificGroupId, childrenGroups)
  const myRootGroup = [...rootGroups, ...childrenGroups].filter(g => g.hashId === specificGroupId)

  return { ...myRootGroup[0], children }
}

export {
  groupsHeaderToListitems,
  processGroupsHierarchyToListitems,
  processGroupsHierarchyToSelect,
  getParentGroup,
  mapGroupProperties,
  mapToHierarchicalGroups,
  mapDevicesToTypeGroupedDevices,
  mapToHierarchicalGroupsFromSpecificGroup
}
