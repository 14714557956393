import { bbox, lineString } from '@turf/turf'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import WebMercatorViewport from 'viewport-mercator-project'

import MapView from './MapView'
import QueryPanel from './QueryPanel'

class Geotracking extends React.Component {
  constructor(props) {
    super(props)
    this.initialState = {
      currentPosition: { latitude: 43.295645, longitude: -1.980012 },
      currentZoom: 1.5,
      isRealtimeGpsTrackingRunning: false,
      selectedDevices: [],
      queriedDevices: [],
      trail: 50
    }
    this.state = {
      ...this.initialState,
      deviceColors: []
    }

    this.mapContainerRef = React.createRef()
  }

  getLimits = () => {
    const { mergedAndOrderedGpsTrackings } = this.props
    let from = 0
    let to = 0
    if (mergedAndOrderedGpsTrackings.legth > 0) {
      from = mergedAndOrderedGpsTrackings[0].timestamp
      const lastgpsPointIndex = mergedAndOrderedGpsTrackings.length > 1 ? mergedAndOrderedGpsTrackings.length > 1 : 0
      to = mergedAndOrderedGpsTrackings[lastgpsPointIndex].timestamp
    }
    return { from, to }
  }

  getFilteredAndOrderedGpsPoints = (gpsPoints, fromTimestamp, toTimestamp) => {
    const { isRealtimeGpsTrackingRunning } = this.state
    return gpsPoints
      .filter(
        (gpsPoint, index, self) =>
          isRealtimeGpsTrackingRunning ||
          self.length === 1 ||
          (fromTimestamp === 0 || gpsPoint.timestamp >= fromTimestamp) &&
            (toTimestamp === 0 || gpsPoint.timestamp <= toTimestamp)
      )
      .sort((pointA, pointB) => pointA.timestamp - pointB.timestamp)
  }

  resetInitialState = () => {
    this.setState(this.initialState)
  }

  handleDeviceColorsChange = deviceColors => {
    this.setState({ deviceColors })
  }

  handleSelectedDevicesChange = selectedDevices => {
    this.setState({
      selectedDevices
    })
  }

  handleQueriedDevicesChange = queriedDevices => {
    this.setState({
      queriedDevices
    })
  }

  handleTrailChange = value => {
    this.setState({
      trail: value
    })
  }

  handleFlyToMarkerClick = gpsTracking => {
    const { setCenter, setZoomLevel } = this.props
    const height = this.mapContainerRef.current ? this.mapContainerRef.current.clientHeight : 800
    const width = this.mapContainerRef.current ? this.mapContainerRef.current.clientWidth : 1200

    const { from, to } = this.getLimits()
    const filteredAndOrderedGpsPoints = this.getFilteredAndOrderedGpsPoints(gpsTracking, from, to)
    let longitude
    let latitude

    if (filteredAndOrderedGpsPoints.length > 1) {
      const locations = filteredAndOrderedGpsPoints.map(gpsPoint => [gpsPoint.longitude, gpsPoint.latitude])

      const feature = lineString(locations)
      const [minLongitude, minLatitude, maxLongitude, maxLatitude] = bbox(feature)
      const viewport = new WebMercatorViewport({ width, height }).fitBounds(
        [
          [minLongitude, minLatitude],
          [maxLongitude, maxLatitude]
        ],
        {
          padding: {
            top: 40,
            bottom: 40,
            left: 40,
            right: 40
          }
        }
      )

      setCenter({ latitude: viewport.latitude, longitude: viewport.longitude })
      setZoomLevel(viewport.zoom)
    } else if (filteredAndOrderedGpsPoints.length === 1) {
      longitude = filteredAndOrderedGpsPoints[0].longitude
      latitude = filteredAndOrderedGpsPoints[0].latitude
      setCenter({ latitude, longitude })
      setZoomLevel(15)
    }
  }

  handleRealtimeGpsTrackingRunningChange = isRealtimeGpsTrackingRunning => {
    this.setState({ isRealtimeGpsTrackingRunning })
  }

  render() {
    const { deviceColors, isRealtimeGpsTrackingRunning, queriedDevices, selectedDevices, trail } = this.state
    const { currentMapCenter, currentZoom } = this.props

    return (
      <div className='content-container' id='content' style={{ height: 'calc(100vh - 50px)' }}>
        <div style={{ padding: 0, height: '100%', display: 'flex' }}>
          <QueryPanel
            deviceColors={deviceColors}
            isRealtimeGpsTrackingRunning={isRealtimeGpsTrackingRunning}
            onDeviceColorsChange={this.handleDeviceColorsChange}
            onFlyToMarkerClick={this.handleFlyToMarkerClick}
            onQueriedDevicesChange={this.handleQueriedDevicesChange}
            onRealtimeGpsTrackingRunningChange={this.handleRealtimeGpsTrackingRunningChange}
            onSelectedDevicesChange={this.handleSelectedDevicesChange}
            onTrailChange={this.handleTrailChange}
            queriedDevices={queriedDevices}
            resetInitialState={this.resetInitialState}
            selectedDevices={selectedDevices}
            trail={trail}
          />
          <MapView
            currentPosition={currentMapCenter}
            currentZoom={currentZoom}
            deviceColors={deviceColors}
            getFilteredAndOrderedGpsPoints={this.getFilteredAndOrderedGpsPoints}
            getLimits={this.getLimits}
            isRealtimeGpsTrackingRunning={isRealtimeGpsTrackingRunning}
            mapContainerRef={this.mapContainerRef}
            onFlyToMarkerClick={this.handleFlyToMarkerClick}
            queriedDevices={queriedDevices}
            selectedDevices={selectedDevices}
            trail={trail}
          />
        </div>
      </div>
    )
  }
}

Geotracking.propTypes = {
  currentMapCenter: PropTypes.object.isRequired,
  currentZoom: PropTypes.number.isRequired,
  gpsTrackings: PropTypes.array.isRequired,
  mergedAndOrderedGpsTrackings: PropTypes.array.isRequired,
  setCenter: PropTypes.func.isRequired,
  setZoomLevel: PropTypes.func.isRequired
}

export default injectIntl(Geotracking)
