import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { selectors } from 'ducks/configurationsCS500'
import { getStaticData } from 'ducks/dashboards'
import { utils } from 'ducks/groups'
import csNode from 'modules/csNode'

import SendCANMessage from './SendCANMessage'
import SendCANMessageSettingsUnconnected from './SendCANMessageSettings'
import SendCANMessageTemplate from './SendCANMessageTemplate'

const mapStateToProps = state => ({
  sendCANMessages: selectors.getSendCANMessages(state),
  staticData: getStaticData(state),
  groupId: utils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCS500ValuePulse: csNode.actions.setCS500ValuePulse
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SendCANMessage)
const SendCANMessageSettings = connect(mapStateToProps, mapDispatchToProps)(SendCANMessageSettingsUnconnected)
export { SendCANMessageSettings, SendCANMessageTemplate }
