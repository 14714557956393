import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'
import { DateTimePicker } from '@material-ui/pickers'

import DataChip from 'components/DataChip'
import { utcTimeToBrowserLocalNoSeconds } from 'utils/timeFormat'

import messages from '../../messages'

class NotificationsFilter extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      dateFrom: null,
      dateTo: null,
      dateToError: '',

      criticality: {
        high: false,
        medium: false,
        low: false
      },
      status: {
        open: false,
        closed: false,
        acknowledged: false
      },
      archived: false
    }
  }

  handleDateFrom = date => {
    this.setState(
      {
        dateFrom: date,
        dateToError: ''
      },
      this.sendDates
    )
  }

  handleDateTo = date => {
    this.setState(
      {
        dateTo: date,
        dateToError: ''
      },
      this.sendDates
    )
  }

  sendDates = () => {
    let canSetFilterDates = false

    const { dateFrom, dateTo, dateToError } = this.state
    if (dateFrom !== null && dateTo !== null && dateToError === '') {
      if (moment(dateFrom).isSame(moment(dateTo), 'minute')) {
        this.setState({
          dateTo: null,
          dateToError: this.formatMessage(messages.fromAndToDatesAreTheSame)
        })
      } else if (moment(dateFrom).isSameOrAfter(moment(dateTo), 'minute')) {
        this.setState({
          dateTo: null,
          dateToError: this.formatMessage(messages.toDateIsBeforeFromDate)
        })
      } else {
        this.setState({
          dateToError: ''
        })

        canSetFilterDates = true
      }
    } else if (dateFrom === null && dateTo === null) {
      canSetFilterDates = true
    }

    if (canSetFilterDates) {
      const { setFilterDates } = this.props
      setFilterDates({
        from: dateFrom,
        to: dateTo
      })
    }
  }

  handleCriticalityCheck = event => {
    const element = event.target.value
    const { criticality } = this.state
    const newCriticality = { ...criticality }

    newCriticality[element] = !criticality[element]

    this.setState(
      {
        criticality: newCriticality
      },
      () => {
        const { setFilterCriticality } = this.props
        const { criticality: stateCriticality } = this.state
        setFilterCriticality(stateCriticality)
      }
    )
  }

  handleStatusCheck = event => {
    const element = event.target.value
    const { status } = this.state
    const newStatus = { ...status }

    newStatus[element] = !status[element]

    this.setState(
      {
        status: newStatus
      },
      () => {
        const { setFilterStatus } = this.props
        const { status: stateStatus } = this.state
        setFilterStatus(stateStatus)
      }
    )
  }

  handleArchive = event => {
    const { archived } = this.state
    this.setState(
      {
        archived: !archived
      },
      () => {
        const { setFilterArchived } = this.props
        const { archived: stateArchived } = this.state
        setFilterArchived(stateArchived)
      }
    )
  }

  render() {
    const { dateFrom, dateTo, dateToError, criticality, status, archived } = this.state
    const { intl } = this.props
    return (
      <Paper style={{ padding: '15px 10px 10px 10px', borderRadius: 0 }}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-md-6'>
              <div className='row'>
                <div className='col-md-11'>
                  <p>
                    <strong>{this.formatMessage(messages.dates)}</strong>
                  </p>
                  <div className='row' style={{ marginTop: 20 }}>
                    <div className='col-md-6'>
                      <DateTimePicker
                        ampm={false}
                        cancelLabel={this.formatMessage(messages.cancel)}
                        clearLabel={this.formatMessage(messages.clear)}
                        clearable
                        disableFuture
                        label={this.formatMessage(messages.from)}
                        labelFunc={() => utcTimeToBrowserLocalNoSeconds(dateFrom)}
                        okLabel={this.formatMessage(messages.ok)}
                        onChange={this.handleDateFrom}
                        style={{ width: '90%' }}
                        value={dateFrom}
                      />
                    </div>
                    <div className='col-md-6'>
                      <DateTimePicker
                        ampm={false}
                        cancelLabel={this.formatMessage(messages.cancel)}
                        clearLabel={this.formatMessage(messages.clear)}
                        clearable
                        disableFuture
                        error={dateToError !== ''}
                        helperText={dateToError}
                        label={this.formatMessage(messages.to)}
                        labelFunc={() => utcTimeToBrowserLocalNoSeconds(dateTo)}
                        okLabel={this.formatMessage(messages.ok)}
                        onChange={this.handleDateTo}
                        style={{ width: '90%' }}
                        value={dateTo}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-md-2'>
              <p>
                <strong>{this.formatMessage(messages.severityFilterTitle)}</strong>
              </p>
              <div className='row'>
                <div className='col-md-12'>
                  <FormControlLabel
                    className='filter-checkbox-line'
                    control={
                      <Checkbox checked={criticality.high} onChange={this.handleCriticalityCheck} value='high' />
                    }
                    label={<DataChip chipText='high' inline intl={intl} />}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <FormControlLabel
                    className='filter-checkbox-line'
                    control={
                      <Checkbox checked={criticality.medium} onChange={this.handleCriticalityCheck} value='medium' />
                    }
                    label={<DataChip chipText='medium' inline intl={intl} />}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <FormControlLabel
                    className='filter-checkbox-line'
                    control={<Checkbox checked={criticality.low} onChange={this.handleCriticalityCheck} value='low' />}
                    label={<DataChip chipText='low' inline intl={intl} />}
                  />
                </div>
              </div>
            </div>

            <div className='col-sm-4 col-md-2'>
              <p>
                <strong>{this.formatMessage(messages.statusFilterTitle)}</strong>
              </p>
              <div className='row'>
                <div className='col-md-12'>
                  <FormControlLabel
                    className='filter-checkbox-line'
                    control={<Checkbox checked={status.open} onChange={this.handleStatusCheck} value='open' />}
                    label={<DataChip chipText='open' inline intl={intl} />}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <FormControlLabel
                    className='filter-checkbox-line'
                    control={
                      <Checkbox checked={status.acknowledged} onChange={this.handleStatusCheck} value='acknowledged' />
                    }
                    label={<DataChip chipText='acknowledged' inline intl={intl} />}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <FormControlLabel
                    className='filter-checkbox-line'
                    control={<Checkbox checked={status.closed} onChange={this.handleStatusCheck} value='closed' />}
                    label={<DataChip chipText='closed' inline intl={intl} />}
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-md-2'>
              <p>
                <strong>{this.formatMessage(messages.archived)}</strong>
              </p>
              <div className='row'>
                <div className='col-md-12'>
                  <FormControlLabel
                    control={<Switch checked={archived} onChange={this.handleArchive} />}
                    label={this.formatMessage(messages.viewArchived)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    )
  }
}

NotificationsFilter.propTypes = {
  intl: PropTypes.object.isRequired,
  setFilterArchived: PropTypes.func.isRequired,
  setFilterCriticality: PropTypes.func.isRequired,
  setFilterDates: PropTypes.func.isRequired,
  setFilterStatus: PropTypes.func.isRequired
}

export default injectIntl(NotificationsFilter)
