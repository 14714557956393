const SendCANMessageTemplate = {
  id: '',
  width: 150,
  height: 120,
  x: 20,
  y: 20,
  minHeight: 120,
  minWidth: 150,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  content: {
    widgetType: 'sendCANmessage',
    params: {
      settings: {
        selectedMessage: '',
        nameValue: '',
        buttonColor: '#d1d6d4',
        responseTimeoutValue: 30,
        isConfirmationChecked: false
      }
    }
  }
}

export default SendCANMessageTemplate
