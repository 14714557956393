import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import Alert from 'components/Alert'
import { isValidFilename } from 'utils/file'
import { client } from 'utils/http'

import DashboardFilesList from './DashboardFilesList'
import messages from './messages'

const styles = {
  dialogContent: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  filesDropzone: {
    width: '100%',
    border: '3px dashed #cccccc',
    padding: 20
  },
  filesDropzoneActive: {
    backgroundColor: '#cccccc',
    color: '#ffffff'
  },
  divider: {
    margin: '24px 0'
  },
  input: {
    display: 'none'
  }
}

class ImportDashboards extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      files: [],
      alertMessages: [],
      alertType: ''
    }
  }

  handleFilesChange = event => {
    event.preventDefault()
    this.handleDragLeave()
    const eventFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files
    const files = [...eventFiles]
    document.getElementById('dashboard-files-input').value = null
    const invalidFileMessages = []
    const validFiles = []
    files.forEach(file => {
      if (file.type !== 'application/json' || !file.name.endsWith('.json')) {
        invalidFileMessages.push(this.formatMessage(messages.isNotJsonError, { name: file.name }))
      } else if (!isValidFilename(file.name, 'json')) {
        invalidFileMessages.push(this.formatMessage(messages.invalidFilename, { name: file.name }))
      } else if (file.size > 10000000) {
        invalidFileMessages.push(this.formatMessage(messages.exceedsMaximumError, { name: file.name }))
      } else {
        validFiles.push(file)
      }
    })
    if (invalidFileMessages.length > 0) {
      this.setState({
        alertMessages: invalidFileMessages,
        alertType: 'danger'
      })
    }
    validFiles.forEach(file => {
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = () => {
        const dashboardConfig = JSON.parse(reader.result)
        const description = JSON.stringify({
          description: dashboardConfig.description,
          height: dashboardConfig.height,
          width: dashboardConfig.width,
          zoom: dashboardConfig.zoom
        })
        this.setState(state => ({
          files: [
            ...state.files,
            {
              metadata: { fileName: file.name, name: dashboardConfig.name, description, fileIndex: state.files.length },
              file
            }
          ]
        }))
      }
    })
  }

  handleDropzoneClick = () => {
    document.getElementById('dashboard-files-input').click()
  }

  handleDropzoneDrop = event => {
    this.handleFilesChange(event)
  }

  handleDragEnter = () => {
    const { classes } = this.props
    const dropZone = document.getElementById('dropzone')
    dropZone.className = dropZone.className + ' ' + classes.filesDropzoneActive
  }

  handleDragLeave = () => {
    const { classes } = this.props
    const dropZone = document.getElementById('dropzone')
    dropZone.className = dropZone.className.replace(' ' + classes.filesDropzoneActive, '')
  }

  handleDragOver = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  handleRemoveFile = fileIndex => {
    const { files } = this.state
    const updatedFiles = [...files]
    updatedFiles.splice(fileIndex, 1)
    this.setState({
      files: updatedFiles
    })
  }

  handleChangeFileIndex = (oldIndex, newIndex) => {
    this.setState(({ files }) => {
      const updatedFiles = [...files]
      const [changingDashboard] = updatedFiles.splice(oldIndex, 1)
      updatedFiles.splice(newIndex, 0, changingDashboard)
      return { files: updatedFiles }
    })
  }

  handleDashboardsImport = () => {
    const { csNodes, groupId } = this.props
    const { files } = this.state

    const formData = new FormData()
    csNodes.forEach(({ id, name }, index) => {
      formData.append(`devices[${index}].name`, name)
      formData.append(`devices[${index}].deviceId`, id)
    })
    formData.append('groupId', groupId)
    files.forEach(({ metadata, file }, index) => {
      formData.append(`dashboardDefinitions[${index}].name`, metadata.name)
      formData.append(`dashboardDefinitions[${index}].description`, metadata.description)
      formData.append(`dashboardDefinitions[${index}].file`, file)
    })

    client
      .uploadDashboards(formData)
      .then(() => {
        this.setState({
          alertMessages: [this.formatMessage(messages.successfulImport)],
          alertType: 'success'
        })
      })
      .catch(() => {
        this.setState({
          alertMessages: [this.formatMessage(messages.errorImportingDashboard)],
          alertType: 'danger'
        })
      })
  }

  closeAlert = () => {
    this.setState({
      alertMessages: [],
      alertType: ''
    })
  }

  render() {
    const { handleClose, classes, intl } = this.props
    const { files, alertMessages, alertType } = this.state
    const isAlertDisplayed = alertMessages[0] !== undefined
    const importButtonDisabled = files.length === 0 || isAlertDisplayed

    return (
      <React.Fragment>
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.importDashboards)}
          <IconButton
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: 3,
              top: 3,
              padding: 5
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          {isAlertDisplayed ? (
            <Alert
              alertType={alertType}
              closeFunction={alertType === 'danger' ? this.closeAlert : undefined}
              messageText={alertMessages}
              showAlert={isAlertDisplayed}
            />
          ) : (
            <React.Fragment>
              <input
                accept='.json'
                id='dashboard-files-input'
                multiple
                onChange={this.handleFilesChange}
                style={{ display: 'none' }}
                type='file'
              />
              <div
                className={classes.filesDropzone}
                id='dropzone'
                onClick={this.handleDropzoneClick}
                onDragEnter={this.handleDragEnter}
                onDragLeave={this.handleDragLeave}
                onDragOver={this.handleDragOver}
                onDrop={this.handleFilesChange}
              >
                {this.formatMessage(messages.dropFilesHereOrClickToUpload)}
              </div>
            </React.Fragment>
          )}
          {files.length > 0 && <Divider classes={{ root: classes.divider }} />}
          {files.length > 0 && (
            <DashboardFilesList
              files={files}
              intl={intl}
              onChangeFileIndex={this.handleChangeFileIndex}
              onRemoveFile={this.handleRemoveFile}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className='primary-action-button'
            disabled={importButtonDisabled}
            onClick={this.handleDashboardsImport}
          >
            {this.formatMessage(messages.import)}
          </Button>
        </DialogActions>
      </React.Fragment>
    )
  }
}

ImportDashboards.propTypes = {
  classes: PropTypes.object.isRequired,
  csNodes: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

export default withStyles(styles)(ImportDashboards)
