import { defineMessages } from 'react-intl'

const namespace = 'error404'

const messages = defineMessages({
  pageNotFound: {
    id: `${namespace}.pageNotFound`,
    defaultMessage: 'PAGE NOT FOUND'
  },
  errorMessage: {
    id: `${namespace}.errorMessage`,
    defaultMessage:
      'The page you are looking for might have been removed, is temporarily unavailable or you don’t ' +
      'have permissions to access it.'
  },

  goToTheHomepage: {
    id: `${namespace}.goToTheHomepage`,
    defaultMessage: 'Go to the homepage'
  },
  reportAnError: {
    id: `${namespace}.reportAnError`,
    defaultMessage: 'Report an error'
  }
})

export default messages
