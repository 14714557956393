import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  menuItem: {
    height: '44px',
    padding: '10px 16px 16px 16px',
    gap: '12px',
    color: '#3F3F3F'
  },
  menuItemText: {
    color: '#3F3F3F',
    paddingLeft: 2
  },
  menuItemIcon: {
    padding: 0,
    margin: 0,
    color: '#686868'
  }
}

// Better than simply using MenuItem's onClick since
// it allows you to interact with the link in the browser.
const LinkedMenuItem = ({ to, label, icon, classes, ...others }) => (
  <Link style={{ textDecoration: 'none' }} to={to}>
    <MenuItem className={classes.menuItem} {...others}>
      <ListItemIcon className={classes.menuItemIcon}>{icon}</ListItemIcon>
      <ListItemText
        className={classes.menuItemText}
        inset
        primary={label}
        primaryTypographyProps={{ style: { color: '#3F3F3F' } }}
      />
    </MenuItem>
  </Link>
)

LinkedMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.node,
  label: PropTypes.node,
  to: PropTypes.string.isRequired
}

export default withStyles(styles)(LinkedMenuItem)
