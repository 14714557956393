import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { withRouter, matchPath } from 'react-router-dom'

import { createLoader } from 'components/LoadOnMount'
import { NOTIFICATIONS_DETAIL_TEMPLATE_URL } from 'components/notifications'
import { isEditing } from 'ducks/dashboards'
import { isEditing as isGroupDashboardEditing } from 'ducks/groupDashboards'
import { utils } from 'ducks/theme'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import Header from './Header'

const mapStateToProps = (state, { location }) => {
  const match = matchPath(location.pathname, { path: NOTIFICATIONS_DETAIL_TEMPLATE_URL, exact: true, strict: false })
  return {
    showSettingsDropdown: resolvePermissions(state, [c.USER_READ, c.GROUPS_READ, c.GROUPROLE_READ, c.RULE_READ]),
    canViewNotifications: resolvePermissions(state, c.NOTIFICATION_READ),
    appBarColor: utils.getAppBarColor(state),
    appFontColor: utils.getFontColor(state),
    isDashboardEditing: isEditing(state) || isGroupDashboardEditing(state),
    isMobile,
    isMobileNotificationDetails: isMobile && location.pathname === `/notifications/${match?.params?.notificationId}`
  }
}

export default createLoader(withRouter(connect(mapStateToProps)(Header)))
