import { defineMessages } from 'react-intl'

const messages = defineMessages({
  importDashboard: {
    id: 'ImportDashboard.importDashboard',
    description: 'Import Dashboard',
    defaultMessage: 'IMPORT DASHBOARD'
  },
  dropFilesHereOrClickToUpload: {
    id: 'ImportDashboard.dropFilesHereOrClickToUpload',
    description: 'Drop files here or click to upload',
    defaultMessage: 'Drop files here or click to upload'
  },
  uploadedFile: {
    id: 'ImportDashboard.uploadedFile',
    description: 'Uploaded file:',
    defaultMessage: 'Uploaded file:'
  },
  importDashboardAction: {
    id: 'ImportDashboard.importDashboardAction',
    description: 'Import Dashboard',
    defaultMessage: 'Import dashboard'
  },
  cancel: {
    id: 'ImportDashboard.cancel',
    description: 'Cancel',
    defaultMessage: 'Cancel'
  },
  invalidFilename: {
    id: 'ImportDashboard.invalidFilename',
    description: 'Invalid filename',
    defaultMessage: 'Invalid filename'
  }
})

export default messages
