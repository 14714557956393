export const DEVICE_CONFIGURATION_MODES = {
  REGISTER_DEVICE_CONFIGURATION: 'REGISTER_DEVICE_CONFIGURATION',
  EDIT_DEVICE_CONFIGURATION: 'EDIT_DEVICE_CONFIGURATION'
}

export const DEVICE_CONFIGURATION_FORM_FIELDS = {
  DEVICE_TYPE: 'deviceType',
  NAME: 'name',
  EID: 'eid',
  DEVICE_SERIAL_NUMBER: 'deviceSerialNumber',
  MACHINE_MODEL: 'machineModel',
  MACHINE_SERIAL_NUMBER: 'machineSerialNumber',
  MACHINE_TYPE: 'machineType',
  MACHINE_BRAND: 'machineBrand',
  DESCRIPTION: 'description'
}
