import { capitalize } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import DataChip from 'components/DataChip'
import { client } from 'utils/http'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import { NOTIFICATIONS_DETAILS_MODE, NOTIFICATION_ACTIONS, NOTIFICATION_TYPES } from '../../constants'
import messages from '../../messages'
import { iconButtonStyles, notificationDetailsStyles } from '../../styles'
import { getAdaptedNotificationsData } from '../../utils'
import NotificationsEvents from '../NotificationsEvents'
import NotAccessibleErrorAlert from '../alerts/NotAccessibleErrorAlert'
import NotificationUpdateSuccessAlert from '../alerts/NotificationUpdateSuccessAlert'
import ServerErrorAlert from '../alerts/ServerErrorAlert'

const styles = theme => ({
  ...iconButtonStyles(theme),
  ...notificationDetailsStyles
})

class NotificationsDetailContent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isNotificationLoading: true,
      isApiFetchNotificationError: false,
      isAPiFetchMethodsError: false,
      notification: {},
      methods: [],
      historyExpanded: false,
      currentAction: props.action,
      observations: '',
      isNotificationUpdateLoading: false,
      isApiSendError: false,
      isApiSendSucess: false
    }
  }

  componentDidMount() {
    this.getNotification()
  }

  getNotification = () => {
    const { notificationId, groupId, user } = this.props
    client
      .getNotification(notificationId, groupId)
      .then(response => {
        const [notification] = getAdaptedNotificationsData([response.data])
        this.setState({ notification })
        return client.getRuleInstanceUser(notification.ruleInstanceHashId, groupId, user.email)
      })
      .then(response => {
        const methods = response.data.actions.map(action => action.name)
        this.setState({ methods })
      })
      .catch(() => {
        const { notification } = this.state
        if (Object.keys(notification).length === 0) {
          this.setState({ isApiFetchNotificationError: true })
        } else this.setState({ isAPiFetchMethodsError: true })
      })
      .finally(() => {
        this.setState({ isNotificationLoading: false })
      })
  }

  getUpdatedNotification = () => {
    const { notification } = this.state
    const { currentAction, observations } = this.state

    let status = ''
    switch (currentAction) {
      case NOTIFICATION_ACTIONS.ACKNOWLEDGE:
        status = 'acknowledged'
        break
      case NOTIFICATION_ACTIONS.CLOSE:
        status = 'closed'
        break
      case NOTIFICATION_ACTIONS.REOPEN:
      case NOTIFICATION_ACTIONS.UNACKNOWLEDGE:
        status = 'open'
        break
    }

    const notificationToUpdate = {
      hashId: notification.hashId,
      status,
      criticality: notification.criticality,
      ruleInstanceHashId: notification.ruleInstanceHashId,
      ruleInstanceName: notification.ruleInstanceName,
      ruleInstanceDescription: notification.ruleInstanceDescription,
      source: notification.source,
      archived: notification.archived,
      version: notification.version,
      observations,
      deviceType: notification.deviceType,
      deviceName: notification.deviceName
    }

    return notificationToUpdate
  }

  handleSetCurrentAction = action => {
    this.setState({ currentAction: action, observations: '', isApiSendError: false })
  }

  handleConfirm = () => {
    const { groupId } = this.props
    const { notification } = this.state
    const notificationToUpdate = this.getUpdatedNotification()
    const { status } = notificationToUpdate

    this.setState({ isNotificationUpdateLoading: true })

    client
      .modifyNotificacion(notificationToUpdate, groupId)
      .then(() => {
        this.setState({ isApiSendSucess: true, notification: { ...notification, status } })
      })
      .catch(() => {
        this.setState({ isApiSendError: true, notification: {}, methods: [] })
      })
      .finally(() => {
        this.setState({ isNotificationUpdateLoading: false })
      })
  }

  handleExit = () => {
    const { closeDialog } = this.props
    const isNotificationsTableUpdateNeeded = true
    this.setState({ currentAction: '', observations: '' })
    closeDialog(isNotificationsTableUpdateNeeded)
  }

  handleObservationsChange = event => {
    const { value } = event.target
    this.setState({ observations: value })
  }

  handleExpandButtonClick = () => {
    this.setState(({ historyExpanded }) => ({ historyExpanded: !historyExpanded }))
  }

  renderMethods = () => {
    const { intl } = this.props
    const { methods } = this.state
    return (
      <Grid container item spacing={2} xs={12}>
        <Grid item md={5} sm={3} xs={6}>
          <b>{intl.formatMessage(messages.methods)}</b>
        </Grid>
        <Grid alignItems='center' container item md={7} sm={9} spacing={2} wrap='nowrap' xs={6}>
          {methods.includes(NOTIFICATION_TYPES.LIVE_NOTIFICATION) && (
            <Grid item>
              <svg fill='none' height='20' viewBox='0 0 18 20' width='18' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M9 20C10.2375 20 11.25 19.0769 11.25 17.9487H6.75C6.75 19.0769 7.75125 20 9 20ZM15.75 13.8462V8.71795C15.75 5.56923 13.905 2.93333 10.6875 2.2359V1.53846C10.6875 0.68718 9.93375 0 9 0C8.06625 0 7.3125 0.68718 7.3125 1.53846V2.2359C4.08375 2.93333 2.25 5.55897 2.25 8.71795V13.8462L0 15.8974V16.9231H18V15.8974L15.75 13.8462Z'
                  fill='#757575'
                />
              </svg>
            </Grid>
          )}
          {methods.includes(NOTIFICATION_TYPES.EMAIL_NOTIFICATION) && (
            <Grid item>
              <svg fill='none' height='16' viewBox='0 0 22 16' width='22' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M19.8 0H2.2C0.99 0 0.011 0.9 0.011 2L0 14C0 15.1 0.99 16 2.2 16H19.8C21.01 16 22 15.1 22 14V2C22 0.9 21.01 0 19.8 0ZM19.8 4L11 9L2.2 4V2L11 7L19.8 2V4Z'
                  fill='#757575'
                />
              </svg>
            </Grid>
          )}
          {methods.includes(NOTIFICATION_TYPES.SMS_NOTIFICATION) && (
            <Grid item>
              <svg fill='none' height='19' viewBox='0 0 13 19' width='13' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M11.1429 0.00863636L1.85714 0C0.835714 0 0 0.777273 0 1.72727V17.2727C0 18.2227 0.835714 19 1.85714 19H11.1429C12.1643 19 13 18.2227 13 17.2727V1.72727C13 0.777273 12.1643 0.00863636 11.1429 0.00863636ZM11.1429 15.5455H1.85714V3.45455H11.1429V15.5455Z'
                  fill='#757575'
                />
              </svg>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }

  renderActionButtons = () => {
    const { mode, intl } = this.props
    const { notification, currentAction, isApiSendSucess, isNotificationUpdateLoading } = this.state

    const notificationIsArchived = notification.archived
    const status = notification.status.toLowerCase()
    let showClose = false
    let showReopen = false
    let showAcknowledge = false
    let showUnacknowledge = false
    const showConfirm = currentAction !== '' && !isApiSendSucess
    const showCancel = showConfirm
    const isMobileMode = mode === NOTIFICATIONS_DETAILS_MODE.MOBILE

    if (!notificationIsArchived && currentAction === '') {
      switch (status) {
        case 'open':
          showClose = true
          showReopen = false
          showAcknowledge = true
          showUnacknowledge = false
          break
        case 'closed':
          showClose = false
          showReopen = true
          showAcknowledge = false
          showUnacknowledge = false
          break
        case 'acknowledged':
          showClose = true
          showReopen = false
          showAcknowledge = false
          showUnacknowledge = true
          break
        default:
          showClose = false
          showReopen = false
          showAcknowledge = false
          showUnacknowledge = false
      }
    }

    return (
      <Grid container item justifyContent={isMobileMode ? 'flex-start' : 'flex-end'} spacing={1} xs={12}>
        {showUnacknowledge && (
          <Grid item xs={isMobileMode && 6}>
            <Button
              className='primary-action-button'
              fullWidth={isMobileMode ? true : false}
              onClick={() => {
                this.handleSetCurrentAction(NOTIFICATION_ACTIONS.UNACKNOWLEDGE)
              }}
            >
              {!isMobileMode && (
                <svg
                  height='20'
                  style={{ marginRight: 6 }}
                  viewBox='0 0 20 20'
                  width='20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M7.26 3.01C7.97 1.91 9.21 1.19 10.61 1.19C12.82 1.19 14.61 2.98 14.61 5.19C14.61 6.59 13.89 7.83 12.79 8.54L7.26 3.01ZM18.61 14.36C18.59 13.26 17.98 12.25 17 11.74C16.46 11.46 15.87 11.2 15.23 10.98L18.61 14.36ZM19.8 18.38L1.42 0L0 1.41L8.89 10.3C7.08 10.53 5.5 11.09 4.22 11.75C3.22 12.26 2.61 13.29 2.61 14.41V17.19H15.78L18.39 19.8L19.8 18.38Z'
                    fill='white'
                  />
                </svg>
              )}
              {intl.formatMessage(messages.unacknowledge)}
            </Button>
          </Grid>
        )}
        {showReopen && (
          <Grid item xs={isMobileMode && 6}>
            <Button
              className='primary-action-button'
              fullWidth={isMobileMode ? true : false}
              onClick={() => {
                this.handleSetCurrentAction(NOTIFICATION_ACTIONS.REOPEN)
              }}
            >
              {!isMobileMode && <Icon className='zmdi zmdi-replay' />}
              {intl.formatMessage(messages.reopen)}
            </Button>
          </Grid>
        )}
        {showAcknowledge && (
          <Grid item xs={isMobileMode && 6}>
            <Button
              className='primary-action-button'
              fullWidth={isMobileMode ? true : false}
              onClick={() => {
                this.handleSetCurrentAction(NOTIFICATION_ACTIONS.ACKNOWLEDGE)
              }}
            >
              {!isMobileMode && <Icon className='zmdi zmdi-account' />}
              {intl.formatMessage(messages.acknowledge)}
            </Button>
          </Grid>
        )}
        {showClose && (
          <Grid item xs={isMobileMode && 6}>
            <Button
              className='primary-action-button'
              fullWidth={isMobileMode ? true : false}
              onClick={() => {
                this.handleSetCurrentAction(NOTIFICATION_ACTIONS.CLOSE)
              }}
            >
              {!isMobileMode && <Icon className='zmdi zmdi-close-circle-o' />}
              {intl.formatMessage(messages.close)}
            </Button>
          </Grid>
        )}
        {showCancel && (
          <Grid item xs={isMobileMode && 6}>
            <Button
              disabled={isNotificationUpdateLoading}
              fullWidth={isMobileMode ? true : false}
              onClick={() => {
                this.handleSetCurrentAction('')
              }}
            >
              {intl.formatMessage(messages.cancel)}
            </Button>
          </Grid>
        )}
        {showConfirm && (
          <Grid item xs={isMobileMode && 6}>
            <Button
              className='primary-action-button'
              disabled={isNotificationUpdateLoading}
              fullWidth={isMobileMode ? true : false}
              onClick={this.handleConfirm}
            >
              {isNotificationUpdateLoading && <CircularProgress size={20} style={{ position: 'absolute' }} />}
              {!isMobileMode && <CheckIcon fontSize='small' style={{ marginRight: 3 }} />}
              {intl.formatMessage(messages.confirm)}
            </Button>
          </Grid>
        )}
      </Grid>
    )
  }

  render() {
    const { intl, classes, mode } = this.props
    const {
      notification,
      isNotificationLoading,
      historyExpanded,
      currentAction,
      isApiFetchNotificationError,
      isAPiFetchMethodsError,
      isApiSendSucess,
      isApiSendError
    } = this.state

    const notificationStatusToShow = notification.status === 'acknowledged' ? 'ACKD' : notification.status

    if (isApiFetchNotificationError) {
      return (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <NotAccessibleErrorAlert />
          </Grid>
        </Grid>
      )
    } else if (isAPiFetchMethodsError) {
      return (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <ServerErrorAlert />
          </Grid>
        </Grid>
      )
    } else if (isNotificationLoading) {
      return (
        <Grid container justifyContent='center'>
          <CircularProgress />
        </Grid>
      )
    } else {
      return (
        <React.Fragment>
          <Grid container item justifyContent='flex-start' md={8} sm={6} spacing={1} xs={12}>
            <Grid item xs={12}>
              <Typography className={classes.header}>{notification.ruleInstanceName}</Typography>
            </Grid>
            <Grid item xs={12}>
              {notification.ruleInstanceDescription}
            </Grid>
          </Grid>
          {mode === NOTIFICATIONS_DETAILS_MODE.PC && (
            <Grid alignItems='flex-end' container item justifyContent='flex-end' md={4} sm={6} xs={12}>
              <Grid item>
                {intl.formatMessage(messages.status) + ': '}
                <DataChip chipText={notificationStatusToShow} inline intl={intl} />
              </Grid>
            </Grid>
          )}
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Divider light />
            </Grid>
          </Grid>
          <Grid alignItems='baseline' container item justifyContent='space-between' spacing={1} xs={12}>
            <Grid container item md={4} spacing={2} xs={12}>
              <Grid container item spacing={2} xs={12}>
                <Grid item md={5} sm={3} xs={6}>
                  <b>{intl.formatMessage(messages.machineName)}</b>
                </Grid>
                <Grid item md={7} sm={9} xs={6}>
                  {notification.deviceName}
                </Grid>
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item md={5} sm={3} xs={6}>
                  <b>{intl.formatMessage(messages.deviceType)}</b>
                </Grid>
                <Grid item md={7} sm={9} xs={6}>
                  {notification.deviceType}
                </Grid>
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item md={5} sm={3} xs={6}>
                  <b>{intl.formatMessage(messages.eid)}</b>
                </Grid>
                <Grid item md={7} sm={9} xs={6}>
                  {notification.source}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item md={5} spacing={2} xs={12}>
              <Grid container item spacing={2} xs={12}>
                <Grid item sm={3} xs={6}>
                  <b>{intl.formatMessage(messages.date)}</b>
                </Grid>
                <Grid item sm={9} xs={6}>
                  {notification.createdAt ? utcTimeToBrowserLocal(notification.createdAt) : '-'}
                </Grid>
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item sm={3} xs={6}>
                  <b>{intl.formatMessage(messages.type)}</b>
                </Grid>
                <Grid item sm={9} xs={6}>
                  {intl.formatMessage(messages[notification.notificationType])}
                </Grid>
              </Grid>
              {mode === NOTIFICATIONS_DETAILS_MODE.MOBILE && this.renderMethods()}
              <Grid container item spacing={2} xs={12}>
                <Grid item sm={3} xs={6}>
                  <b>{intl.formatMessage(messages.reason)}</b>
                </Grid>
                <Grid item sm={9} xs={6}>
                  {notification.notificationBody}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item md={3} spacing={2} xs={12}>
              <Grid container item spacing={2} xs={12}>
                <Grid item md={5} sm={3} xs={6}>
                  <b>{capitalize(intl.formatMessage(messages.archived).toLowerCase())}</b>
                </Grid>
                <Grid item md={7} sm={9} xs={6}>
                  {notification.archived ? intl.formatMessage(messages.yes) : intl.formatMessage(messages.no)}
                </Grid>
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item md={5} sm={3} xs={6}>
                  <b>{intl.formatMessage(messages.severity)}</b>
                </Grid>
                <Grid item md={7} sm={9} xs={6}>
                  <DataChip chipText={notification.criticality} intl={intl} />
                </Grid>
              </Grid>
              {mode === NOTIFICATIONS_DETAILS_MODE.MOBILE && (
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <b>{intl.formatMessage(messages.status)}</b>
                  </Grid>
                  <Grid item xs={6}>
                    <DataChip chipText={notificationStatusToShow} inline intl={intl} />
                  </Grid>
                </Grid>
              )}
              {mode === NOTIFICATIONS_DETAILS_MODE.PC && this.renderMethods()}
            </Grid>
          </Grid>
          {currentAction !== '' && !isApiSendSucess && (
            <Grid container item spacing={1} xs={12}>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  label={intl.formatMessage(messages.textareaTitle, {
                    action: intl.formatMessage(messages[currentAction.toLowerCase()])
                  })}
                  minRows={4}
                  multiline
                  onChange={this.handleObservationsChange}
                  placeholder={intl.formatMessage(messages.enterYourObservations)}
                  variant='outlined'
                />
              </Grid>
            </Grid>
          )}
          {isApiSendError && (
            <Grid container item spacing={1} xs={12}>
              <Grid item xs={12}>
                <ServerErrorAlert />
              </Grid>
            </Grid>
          )}
          {isApiSendSucess && (
            <Grid container item spacing={1} xs={12}>
              <Grid item xs={12}>
                <NotificationUpdateSuccessAlert action={currentAction === 'open' ? 'opened' : currentAction} />
              </Grid>
            </Grid>
          )}
          {this.renderActionButtons()}
          {isApiSendSucess && mode === NOTIFICATIONS_DETAILS_MODE.PC && (
            <Grid container item justifyContent='flex-end' spacing={1} xs={12}>
              <Grid item>
                <Button className='primary-action-button' onClick={this.handleExit} style={{ marginBottom: '37.5px' }}>
                  <ExitToAppIcon fontSize='small' style={{ marginRight: 6 }} />
                  {intl.formatMessage(messages.exit)}
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Divider light />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid alignItems='baseline' container item justifyContent='flex-start' style={{ marginTop: -5 }} xs={12}>
              <Grid item>
                <IconButton classes={{ root: classes.button }} onClick={this.handleExpandButtonClick}>
                  <ExpandMoreIcon className={historyExpanded ? classes.expandOpen : classes.expand} fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography className={classes.header}>{intl.formatMessage(messages.historyDetailLabel)}</Typography>
              </Grid>
            </Grid>
            <Grid item style={historyExpanded ? {} : { display: 'none' }} xs={12}>
              <NotificationsEvents intl={intl} mode={mode} notification={notification} />
            </Grid>
          </Grid>
        </React.Fragment>
      )
    }
  }
}

NotificationsDetailContent.propTypes = {
  action: PropTypes.string,
  classes: PropTypes.array.isRequired,
  closeDialog: PropTypes.func,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  notificationId: PropTypes.object.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired
}

NotificationsDetailContent.defaultProps = {
  action: '',
  closeDialog: i => i
}

export default withStyles(styles)(injectIntl(NotificationsDetailContent))
