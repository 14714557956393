import { cloneDeep } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { injectIntl } from 'react-intl'
import { Rnd } from 'react-rnd'

import Icon from '@material-ui/core/Icon'

import CloneWidget from 'modules/dashboards/Modals/CloneWidget'
import DeleteWidget from 'modules/dashboards/Modals/DeleteWidget'
import * as availableWidgets from 'modules/dashboards/Widgets'

import messages from './messages'

class Widget extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.dynamicWidgetTypes = ['realtimevalue', 'gauge', 'speedometer', 'columnchart', 'linechart']
    this.booleanWidgetTypes = ['box', 'image', 'text']

    this.state = {
      id: props.configuration.id,

      width: props.configuration.width,
      height: props.configuration.height,
      x: props.configuration.x,
      y: props.configuration.y,
      content: props.configuration.content,

      minHeight: props.configuration.minHeight,
      minWidth: props.configuration.minWidth,
      maxHeight: props.configuration.maxHeight,
      maxWidth: props.configuration.maxWidth,

      dashboardWidth: JSON.parse(this.props.zoom).width,
      dashboardZoom: JSON.parse(this.props.zoom).zoom,

      manipulating: false,
      editWidget: false,
      deleteWidgetModal: false,
      cloneWidgetModal: false
    }

    this.timer = undefined
    this.timerCycles = 10

    this.GRID_STEP = 10
  }

  componentDidMount() {
    if (JSON.parse(this.props.zoom).zoom === 'fit') {
      this.timer = setInterval(this.rearrangeWidgets, 10)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.containerWidth !== nextProps.containerWidth) {
      return false
    }

    return true
  }

  rearrangeWidgets = () => {
    this.timerCycles = this.timerCycles - 1

    if (this.timerCycles > 0) {
      this.setState({
        dashboardZoom: 'fit'
      })
    } else {
      clearInterval(this.timer)
    }
  }

  widgetSettings = () => {
    this.setState({
      editWidget: true
    })
  }

  onDragStart = (e, d) => {
    this.setState({
      manipulating: true
    })
  }

  onDragStop = (e, d) => {
    const HALF = 2
    let real_x
    let real_y

    if (d.x % this.GRID_STEP > this.GRID_STEP / HALF) {
      real_x = d.x - d.x % this.GRID_STEP + this.GRID_STEP
    } else {
      real_x = d.x - d.x % this.GRID_STEP
    }
    if (d.y % this.GRID_STEP > this.GRID_STEP / HALF) {
      real_y = d.y - d.y % this.GRID_STEP + this.GRID_STEP
    } else {
      real_y = d.y - d.y % this.GRID_STEP
    }

    if (d.x < 0) {
      real_x = 0
    }
    if (d.y < 0) {
      real_y = 0
    }

    this.setState({
      manipulating: false,
      x: real_x,
      y: real_y
    })

    this.props.widgetCommunication.onDrag(this.state.id, parseInt(this.state.x, 10), parseInt(this.state.y, 10))
  }

  enableResizing = () => ({
    bottom: false,
    bottomLeft: this.props.editing,
    bottomRight: this.props.editing,
    left: false,
    right: false,
    top: false,
    topLeft: this.props.editing,
    topRight: this.props.editing
  })

  onResizeStart = (e, d) => {
    this.setState({
      manipulating: true
    })
  }

  onResizeStop = (e, d) => {
    this.setState({
      manipulating: false
    })

    this.props.widgetCommunication.onResize(
      this.state.id,
      this.state.height,
      this.state.width,
      this.state.x,
      this.state.y
    )
  }

  // eslint-disable-next-line max-params
  onResize = (e, direction, ref, delta, position) => {
    this.setState({
      height: ref.style.height,
      width: ref.style.width,
      x: position.x,
      y: position.y
    })
  }

  resizeHandleClasses = () => ({
    topLeft: this.props.editing ? 'PD-widget_resizeHandler_topLeft' : '',
    topRight: this.props.editing ? 'PD-widget_resizeHandler_topRight' : '',
    bottomLeft: this.props.editing ? 'PD-widget_resizeHandler_bottomLeft' : '',
    bottomRight: this.props.editing ? 'PD-widget_resizeHandler_bottomRight' : ''
  })

  widgetZIndexPlus = event => {
    event.preventDefault()
    event.stopPropagation()
    this.props.widgetCommunication.onZIndexPlus(this.state.id)

    return false
  }

  widgetZIndexMinus = event => {
    event.preventDefault()
    event.stopPropagation()
    this.props.widgetCommunication.onZIndexMinus(this.state.id)

    return false
  }

  // DELETE WIDGET
  openDeleteWidgetModal = () => {
    this.setState({
      deleteWidgetModal: true
    })
  }

  closeDeleteWidgetModal = () => {
    this.setState({
      deleteWidgetModal: false
    })
  }

  deleteWidget = () => {
    this.props.widgetCommunication.onDelete(this.state.id)
  }

  closeSettings = () => {
    this.setState({
      editWidget: false
    })
  }

  saveSettings = data => {
    this.setState({
      editWidget: false
    })
    if (this.state.content.widgetType === 'line' && data.type !== this.state.content.params.type) {
      let width, height
      if (data.type === 'horizontal') {
        width = 200
        height = 10
      } else {
        height = 200
        width = 10
      }
      this.setState(
        {
          height,
          width
        },
        () => {
          this.props.widgetCommunication.onResize(
            this.state.id,
            this.state.height,
            this.state.width,
            this.state.x,
            this.state.y
          )
        }
      )
    }
    this.props.widgetCommunication.onSettingsChange(this.state.id, data)
  }

  openCloneWidgetModal = () => {
    this.setState({
      cloneWidgetModal: true
    })
  }

  closeCloneWidgetModal = () => {
    this.setState({
      cloneWidgetModal: false
    })
  }

  subscribeWidgetToTopic = () => {
    const widgetType = this.props.configuration.content.widgetType
    const lengthOfBits = this.props.configuration.content.params.lengthOfBits

    if (
      this.dynamicWidgetTypes.includes(widgetType) ||
      this.booleanWidgetTypes.includes(widgetType) && (typeof lengthOfBits === 'undefined' || lengthOfBits === 1)
    ) {
      const topic = process.env.REACT_APP_TOPIC + 'm' + this.props.eid.replaceAll(':', '') + '/u/ds'
      this.props.subscribeWidgetToWS(topic, this.props.eid)
    }
  }

  cloneWidget = () => {
    const data = this.props.configuration.content.params.data
    const widgetTemplate = cloneDeep({ ...this.props.configuration, x: 20, y: 20 })
    delete widgetTemplate.id
    this.props.widgetCommunication.onClone(widgetTemplate)
    if (data !== '') this.subscribeWidgetToTopic()
  }

  renderWidget = () => {
    const widgetType = this.props.configuration.content.widgetType
    let component
    let settings

    switch (widgetType) {
      case 'box':
        component = <availableWidgets.Box data={this.props.configuration.content.params} />
        settings = (
          <availableWidgets.BoxSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'text':
        component = <availableWidgets.Text data={this.props.configuration.content.params} />
        settings = (
          <availableWidgets.TextSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'image':
        component = <availableWidgets.Image data={this.props.configuration.content.params} />
        settings = (
          <availableWidgets.ImageSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'realtimevalue':
        component = <availableWidgets.RealTimeValue data={this.props.configuration.content.params} />
        settings = (
          <availableWidgets.RealTimeValueSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'value':
        component = <availableWidgets.Value data={this.props.configuration.content.params} />
        settings = (
          <availableWidgets.ValueSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'speedometer':
      case 'gauge':
        component = (
          <availableWidgets.Gauge
            data={this.props.configuration.content.params}
            editing={this.props.editing}
            height={this.state.height}
            width={this.state.width}
          />
        )
        settings = (
          <availableWidgets.GaugeSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'columnchart':
        component = <availableWidgets.ColumnChart data={this.props.configuration.content.params} />
        settings = (
          <availableWidgets.ColumnChartSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'linechart':
        component = <availableWidgets.LineChart data={this.props.configuration.content.params} />
        settings = (
          <availableWidgets.LineChartSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'line':
        component = <availableWidgets.Line data={this.props.configuration.content.params} />
        settings = (
          <availableWidgets.LineSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break
      case 'link':
        component = <availableWidgets.Link data={this.props.configuration.content.params} />
        settings = (
          <availableWidgets.LinkSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break
      case 'historic':
        component = (
          <availableWidgets.Historic
            data={this.props.configuration.content.params}
            editing={this.props.editing}
            height={this.state.height}
            width={this.state.width}
          />
        )
        settings = (
          <availableWidgets.HistoricSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break
      case 'map':
        component = <availableWidgets.Map data={this.props.configuration.content.params} />
        settings = (
          <availableWidgets.MapSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break
      case 'video':
        component = <availableWidgets.Video data={this.props.configuration.content.params} />
        settings = (
          <availableWidgets.VideoSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break
      case 'weather':
        component = (
          <availableWidgets.Weather
            data={this.props.configuration.content.params}
            editing={this.props.editing}
            width={this.state.width}
          />
        )
        settings = (
          <availableWidgets.WeatherSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'embeddedweb':
        component = <availableWidgets.EmbeddedWeb data={this.props.configuration.content.params} eid={this.props.eid} />
        settings = (
          <availableWidgets.EmbeddedWebSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            eid={this.props.eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'sendCANmessage':
        component = (
          <availableWidgets.SendCANMessage
            data={this.props.configuration.content.params}
            editing={this.props.editing}
            eid={this.props.eid}
          />
        )
        settings = (
          <availableWidgets.SendCANMessageSettings
            closeSettings={this.closeSettings}
            data={this.props.configuration.content.params}
            eid={this.props.eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      default:
        component = <availableWidgets.Undefined />
        settings = null
    }

    return { component, settings }
  }

  xPosition = () => {
    let xValue = this.state.x
    let reductionFactor = 0

    if (this.props.zoom !== undefined) {
      const containerWidth = this.props.containerWidth
      const width = this.state.dashboardWidth
      const zoom = this.state.dashboardZoom

      if (zoom === 'fit' && this.props.editing === false) {
        //if (width >= containerWidth) {
        reductionFactor = containerWidth / width
        xValue = this.state.x * reductionFactor
        //}
      }
    }

    return xValue
  }

  yPosition = () => {
    let yValue = this.state.y

    if (this.props.zoom !== undefined) {
      const containerWidth = this.props.containerWidth
      const width = this.state.dashboardWidth
      const zoom = this.state.dashboardZoom

      if (zoom === 'fit' && this.props.editing === false) {
        if (width >= containerWidth) {
          const reductionFactor = containerWidth / width
          yValue = this.state.y * reductionFactor
        }
      }
    }

    return yValue
  }

  render() {
    return (
      <Fragment>
        <Rnd
          className={
            'PD-widget' +
            (this.state.manipulating ? ' PD-widget_manipulating' : '') +
            (this.props.editing ? ' PD-edit' : '')
          }
          disableDragging={!this.props.editing}
          dragGrid={[this.GRID_STEP, this.GRID_STEP]}
          dragHandleClassName='PD-widget_dragHandler'
          enableResizing={this.enableResizing()}
          maxHeight={this.state.maxHeight}
          maxWidth={this.state.maxWidth}
          minHeight={this.state.minHeight}
          minWidth={this.state.minWidth}
          onDragStart={this.onDragStart}
          // bounds="parent"
          onDragStop={this.onDragStop}
          onResize={this.onResize}
          onResizeStart={this.onResizeStart}
          onResizeStop={this.onResizeStop}
          position={{ x: this.state.x, y: this.state.y }}
          resizeGrid={[this.GRID_STEP, this.GRID_STEP]}
          scale={
            !this.props.editing && this.state.dashboardZoom === 'fit'
              ? this.props.containerWidth / this.state.dashboardWidth
              : 1
          }
          size={{ width: this.state.width, height: this.state.height }}
          style={{ zIndex: this.props.configuration.zIndex, ...!this.props.editing && { touchAction: 'auto' } }}
          // resizeHandleWrapperClass={'PD-widget_resizeHandler'}
          // resizeHandleClasses={this.resizeHandleClasses()}
        >
          {this.renderWidget().component}

          <span
            className={this.props.editing ? 'PD-widget_dragHandler' : ''}
            title={this.formatMessage(messages.moveWidget)}
          />
          <span
            className={this.props.editing ? 'PD-widget_config' : 'PD-widget_config-hidden'}
            onClick={this.widgetSettings}
            title={this.formatMessage(messages.widgetConfiguration)}
          />
          <span
            className={this.props.editing ? 'PD-widget_zindex' : 'PD-widget_zindex-hidden'}
            title={this.formatMessage(messages.widgetDepth)}
          >
            <span
              className='PD-widget_zindex-plus'
              onClick={this.widgetZIndexPlus}
              title={this.formatMessage(messages.widgetUp)}
            >
              &#9652;
            </span>
            {this.props.configuration.zIndex}
            <span
              className='PD-widget_zindex-minus'
              onClick={this.widgetZIndexMinus}
              title={this.formatMessage(messages.widgetDown)}
            >
              &#9662;
            </span>
          </span>
          <span
            className={this.props.editing ? 'PD-widget_duplicate' : 'PD-widget_duplicate-hidden'}
            onClick={this.openCloneWidgetModal}
            title={this.formatMessage(messages.cloneWidget)}
          >
            <Icon className='zmdi zmdi-plus-circle-o-duplicate' fontSize='inherit' />
          </span>
          <span
            className={this.props.editing ? 'PD-widget_delete' : 'PD-widget_delete-hidden'}
            onClick={this.openDeleteWidgetModal}
            title={this.formatMessage(messages.deleteWidget)}
          >
            &#8855;
          </span>
        </Rnd>
        {this.state.editWidget ? this.renderWidget().settings : null}

        <DeleteWidget
          closeDeleteWidgetModal={this.closeDeleteWidgetModal}
          deleteWidget={this.deleteWidget}
          deleteWidgetModal={this.state.deleteWidgetModal}
          widgetData={this.props.configuration}
        />

        <CloneWidget
          cloneWidget={this.cloneWidget}
          cloneWidgetModal={this.state.cloneWidgetModal}
          closeCloneWidgetModal={this.closeCloneWidgetModal}
          widgetData={this.props.configuration}
        />
      </Fragment>
    )
  }
}

Widget.propTypes = {
  configuration: PropTypes.object.isRequired,
  containerWidth: PropTypes.number.isRequired,
  editing: PropTypes.bool.isRequired,
  widgetCommunication: PropTypes.object.isRequired,
  zoom: PropTypes.string.isRequired
}

export default injectIntl(Widget)
