import messages from './messages'

let csPrivileges = []
let csPrivilegesHierarchy = () => []

switch (process.env.REACT_APP_ENV) {
  case 'development':
    csPrivileges = [
      { '@objectId': 1, name: 'map.read', description: 'Can view map', hashId: 'e2adf5f4-aa56-4b4d-ae2d-62536304086f' },
      {
        '@objectId': 2,
        name: 'device.read',
        description: 'Can view devices',
        hashId: 'ae7e94b3-b907-4aa9-a2bc-76fa045fb40b'
      },
      {
        '@objectId': 3,
        name: 'deviceDashboard.read',
        description: 'Can view device dashboards',
        hashId: '76f0afcf-d610-437c-b7c2-1deadc394a0b'
      },
      {
        '@objectId': 4,
        name: 'deviceDashboard.edit',
        description: 'Can edit device dashboards',
        hashId: '0fe6dbb5-5682-494b-9104-0967315acd4d'
      },
      {
        '@objectId': 5,
        name: 'deviceDashboard.create',
        description: 'Can create device dashboards',
        hashId: '166d5407-6740-4020-8f88-44c93817fed1'
      },
      {
        '@objectId': 6,
        name: 'deviceDashboard.delete',
        description: 'Can edit device dashboards',
        hashId: 'adc077d5-2aff-4ac5-8152-69ad46420029'
      },
      {
        '@objectId': 7,
        name: 'nodeDetails.view',
        description: 'Can view device details',
        hashId: '67354dca-0740-4f23-9289-dbc965c8dd02'
      },
      {
        '@objectId': 8,
        name: 'deviceConfiguration.read',
        description: 'Can read device configuration',
        hashId: '15f18ccd-8d8d-4c4a-8a89-1031c2aeb76a'
      },
      {
        '@objectId': 9,
        name: 'device.edit',
        description: 'Can edit devices',
        hashId: '7faa65df-4889-4f70-8d67-85598290271c'
      },
      {
        '@objectId': 10,
        name: 'firmware.read',
        description: 'Can read device firmware',
        hashId: 'cc77c498-2c0a-485e-9885-0cd0d1f19513'
      },
      {
        '@objectId': 11,
        name: 'firmware.edit',
        description: 'Can edit device firmware',
        hashId: '65e31110-90f1-44c1-a1b8-b581444ab91a'
      },
      {
        '@objectId': 12,
        name: 'notification.read',
        description: 'Can view notifications',
        hashId: '3c03c22a-6e4f-4fa7-8754-3d86c0eebbec'
      },
      {
        '@objectId': 13,
        name: 'notification.edit',
        description: 'Can edit notification',
        hashId: '0a645ba9-aae5-44a4-ae46-beeb20f26748'
      },
      {
        '@objectId': 14,
        name: 'geoTracking.view',
        description: 'Can view Geotracking',
        hashId: 'eb6f9b4c-b1dd-4201-a348-ecfe08f90f7d'
      },
      {
        '@objectId': 15,
        name: 'geoTracking.edit',
        description: 'Can edit Geotracking',
        hashId: 'bc5251f5-f850-4203-b05a-fe0a3383ad48'
      },
      {
        '@objectId': 16,
        name: 'geoFence.read',
        description: 'Can read Geofences',
        hashId: '61e2733b-0d3e-422f-afb4-50d33e9e3a5d'
      },
      {
        '@objectId': 17,
        name: 'geoFence.create',
        description: 'Can create Geofences',
        hashId: '558c5418-5633-4a56-83d3-bdeaa7f673f3'
      },
      {
        '@objectId': 18,
        name: 'geoFence.edit',
        description: 'Can edit Geofences',
        hashId: 'e2d34c42-ce6c-4eef-be16-880759edfcda'
      },
      {
        '@objectId': 19,
        name: 'geoFence.delete',
        description: 'Can delete Geofences',
        hashId: '4d9952a0-3cad-4f10-ba39-c86091c1fbae'
      },
      {
        '@objectId': 20,
        name: 'schedule.read',
        description: 'Can read schedules',
        hashId: '2ebd8ce1-97d7-44b4-a7b5-220194bcbd00'
      },
      {
        '@objectId': 21,
        name: 'schedule.create',
        description: 'Can create schedules',
        hashId: '0087c5f8-582e-4b00-afd9-d8926c3a536f'
      },
      {
        '@objectId': 22,
        name: 'schedule.edit',
        description: 'Can edit schedules',
        hashId: '49ab8073-e291-4bbd-a0e4-122c2fc1b754'
      },
      {
        '@objectId': 23,
        name: 'schedule.delete',
        description: 'Can delete reports',
        hashId: '0dc02971-7402-4372-9535-8207a552d1b7'
      },
      {
        '@objectId': 24,
        name: 'charts.read',
        description: 'Can read charts',
        hashId: 'fe244abe-2035-4288-ba79-88bfd4fe13ae'
      },
      {
        '@objectId': 25,
        name: 'charts.create',
        description: 'Can create charts',
        hashId: 'de876485-cd6e-486f-b628-62f5bf544e1c'
      },
      {
        '@objectId': 26,
        name: 'charts.edit',
        description: 'Can edit reports',
        hashId: 'ad953910-d55d-49dd-8f47-e02a3633991a'
      },
      {
        '@objectId': 27,
        name: 'charts.delete',
        description: 'Can delete reports',
        hashId: '497b2fc6-2364-4d39-8e4f-a6140a5e1ca0'
      },
      {
        '@objectId': 28,
        name: 'eventsHistory.view',
        description: 'Can view Events history',
        hashId: 'e9918f2a-2bd0-45db-a8a5-ea9110faf4f4'
      },
      {
        '@objectId': 29,
        name: 'eventsHistory.edit',
        description: 'Can edit Events history',
        hashId: 'a65b6daa-e6ac-47ef-ac40-7d544ba8f69b'
      },
      {
        '@objectId': 30,
        name: 'deviceConfiguration.read',
        description: 'Can read device configurations',
        hashId: '15f18ccd-8d8d-4c4a-8a89-1031c2aeb76a'
      },
      {
        '@objectId': 31,
        name: 'deviceConfiguration.create',
        description: 'Can create device configurations',
        hashId: '9d453222-76f8-4e1b-ad27-2fb87c34711b'
      },
      {
        '@objectId': 32,
        name: 'deviceConfiguration.edit',
        description: 'Can edit device configurations',
        hashId: 'e9633d49-24ac-4420-9a2d-813fd79df9a8'
      },
      {
        '@objectId': 33,
        name: 'deviceConfiguration.delete',
        description: 'Can delete device configurations',
        hashId: '22e0f0fd-ede5-449f-9db2-785430aafe13'
      },
      {
        '@objectId': 34,
        name: 'device.read',
        description: 'Can view device',
        hashId: 'ae7e94b3-b907-4aa9-a2bc-76fa045fb40b'
      },
      {
        '@objectId': 35,
        name: 'deviceSim.edit',
        description: 'Can activate production test mode',
        hashId: 'e03a9f14-57f1-40b6-ae4e-518e43246f60'
      },
      {
        '@objectId': 36,
        name: 'device.create',
        description: 'Can register devices',
        hashId: '4af31d78-d1da-4d8e-8685-4b990387c04b'
      },
      {
        '@objectId': 37,
        name: 'device.edit',
        description: 'Can edit devices',
        hashId: '7faa65df-4889-4f70-8d67-85598290271c'
      },
      {
        '@objectId': 38,
        name: 'dataplan.read',
        description: 'Can view dataplans',
        hashId: 'f84871f7-4af4-4260-9022-d7c6722b344d'
      },
      {
        '@objectId': 39,
        name: 'dataplan.apply',
        description: 'Can apply dataplans',
        hashId: 'a102ebb5-7663-4c60-800b-8c6a01245578'
      },
      {
        '@objectId': 40,
        name: 'dataplan.share',
        description: 'Can share dataplans',
        hashId: 'ee75868d-5d17-4775-ad4c-04111ab1edaf'
      },
      {
        '@objectId': 41,
        name: 'accessPass.consume',
        description: 'Can consume Access Pass',
        hashId: 'df52c089-aad6-4b85-b626-e718571da7e8'
      },
      {
        '@objectId': 42,
        name: 'accessPass.share',
        description: 'Can share Access Pass',
        hashId: '25caf295-18e5-450a-94bf-32bcbf4ca0ac'
      },
      {
        '@objectId': 43,
        name: 'groupDashboard.read',
        description: 'Can read group dashboards',
        hashId: '2bfa84fb-8d16-44f3-9bb2-22a2c890f743'
      },
      {
        '@objectId': 44,
        name: 'groupDashboard.edit',
        description: 'Can edit group dashboards',
        hashId: 'e06c543f-fab0-4941-8293-0be6bb3d1192'
      },
      {
        '@objectId': 45,
        name: 'groupDashboard.create',
        description: 'Can create group dashboards',
        hashId: '9e78cf8c-3140-4cf8-a077-f4cecd9f6894'
      },
      {
        '@objectId': 46,
        name: 'groupDashboard.delete',
        description: 'Can delete group dashboards',
        hashId: '6cb1cf9e-0be7-494a-a968-927dc008a596'
      },
      {
        '@objectId': 47,
        name: 'user.read',
        description: 'Can view users',
        hashId: '56e24d06-27e4-486a-9016-74ac4427b901'
      },
      {
        '@objectId': 48,
        name: 'user.create',
        description: 'Can create users',
        hashId: '8b52c270-0116-4c9a-919d-8f473c9aba14'
      },
      {
        '@objectId': 49,
        name: 'user.edit',
        description: 'Can edit users',
        hashId: 'b6a15397-4a35-404d-ac17-edec1786fa8b'
      },
      {
        '@objectId': 50,
        name: 'groupRole.read',
        description: 'Can read roles',
        hashId: '44d5bc3a-f1c6-488a-9c90-a707e07ac357'
      },
      {
        '@objectId': 51,
        name: 'groupRole.edit',
        description: 'Can edit roles',
        hashId: '83a11ae6-a820-412f-8d5d-eef4b42180fc'
      },
      {
        '@objectId': 52,
        name: 'groupRole.create',
        description: 'Can create roles',
        hashId: 'bd9c0eed-664f-408d-9faf-6201130eaa24'
      },
      {
        '@objectId': 53,
        name: 'groupRole.delete',
        description: 'Can delete roles',
        hashId: '2cbca0fd-5fb4-4ae8-9715-ce68156c1a35'
      },
      {
        '@objectId': 54,
        name: 'groups.read',
        description: 'Can view groups',
        hashId: '0c837eb7-d30c-4241-9bbe-a7be75e4b58f'
      },
      {
        '@objectId': 55,
        name: 'groups.create',
        description: 'Can create groups',
        hashId: '236c35e7-7b41-4bb8-9007-9df0004611eb'
      },
      {
        '@objectId': 56,
        name: 'groups.edit',
        description: 'Can edit groups',
        hashId: '9818993b-43b9-46d0-9449-32b00c9361ed'
      },
      {
        '@objectId': 57,
        name: 'groups.delete',
        description: 'Can delete groups',
        hashId: '9433c7ac-6c08-4db8-afac-1f299323475a'
      },
      {
        '@objectId': 58,
        name: 'resourceSharing.allow',
        description: 'Can share resources in group',
        hashId: '2d3ca1db-1ea8-4536-ac65-8602ac85ff75'
      },
      {
        '@objectId': 59,
        name: 'rule.read',
        description: 'Can read notification rules',
        hashId: '21189e69-c2ef-4cc6-abfe-19c03e0b4bf9'
      },
      {
        '@objectId': 60,
        name: 'rule.create',
        description: 'Can create notification rules',
        hashId: 'b9575565-0623-4605-9b36-15e87ede0d16'
      },
      {
        '@objectId': 61,
        name: 'rule.edit',
        description: 'Can edit notification rules',
        hashId: 'f8dafe0a-07ff-447b-994f-c7207466c61a'
      },
      {
        '@objectId': 62,
        name: 'rule.delete',
        description: 'Can delete notification rules',
        hashId: 'f0cd861f-ec41-48af-80ae-1e251db9f0b4'
      }
    ]

    csPrivilegesHierarchy = formatMessage => [
      {
        '@objectId': 1,
        name: 'map.read',
        description: formatMessage(messages.map),
        hashId: 'e2adf5f4-aa56-4b4d-ae2d-62536304086f',
        children: []
      },
      {
        '@objectId': 'fake',
        name: 'DEVICES',
        description: formatMessage(messages.nodes),
        hashId: 'fake',
        childrenHashes: [
          'ae7e94b3-b907-4aa9-a2bc-76fa045fb40b',
          '76f0afcf-d610-437c-b7c2-1deadc394a0b',
          '0fe6dbb5-5682-494b-9104-0967315acd4d',
          '166d5407-6740-4020-8f88-44c93817fed1',
          'adc077d5-2aff-4ac5-8152-69ad46420029',
          '67354dca-0740-4f23-9289-dbc965c8dd02',
          '15f18ccd-8d8d-4c4a-8a89-1031c2aeb76a',
          '7faa65df-4889-4f70-8d67-85598290271c',
          'cc77c498-2c0a-485e-9885-0cd0d1f19513',
          '65e31110-90f1-44c1-a1b8-b581444ab91a'
        ],
        children: [
          {
            '@objectId': 2,
            name: 'device.read',
            description: formatMessage(messages.view),
            hashId: 'ae7e94b3-b907-4aa9-a2bc-76fa045fb40b',
            children: []
          },
          {
            '@objectId': 'fake',
            name: 'DASHBOARDS',
            description: formatMessage(messages.dashboards),
            hashId: 'fake',
            childrenHashes: [
              '76f0afcf-d610-437c-b7c2-1deadc394a0b',
              '0fe6dbb5-5682-494b-9104-0967315acd4d',
              '166d5407-6740-4020-8f88-44c93817fed1',
              'adc077d5-2aff-4ac5-8152-69ad46420029'
            ],
            children: [
              {
                '@objectId': 3,
                name: 'deviceDashboard.read',
                description: formatMessage(messages.view),
                hashId: '76f0afcf-d610-437c-b7c2-1deadc394a0b',
                children: []
              },
              {
                '@objectId': 4,
                name: 'deviceDashboard.edit',
                description: formatMessage(messages.edit),
                hashId: '0fe6dbb5-5682-494b-9104-0967315acd4d',
                children: []
              },
              {
                '@objectId': 5,
                name: 'deviceDashboard.create',
                description: formatMessage(messages.create),
                hashId: '166d5407-6740-4020-8f88-44c93817fed1',
                children: []
              },
              {
                '@objectId': 6,
                name: 'deviceDashboard.delete',
                description: formatMessage(messages.delete),
                hashId: 'adc077d5-2aff-4ac5-8152-69ad46420029',
                children: []
              }
            ]
          },
          {
            '@objectId': 7,
            name: 'nodeDetails.view',
            description: formatMessage(messages.nodeDetail),
            hashId: '67354dca-0740-4f23-9289-dbc965c8dd02',
            children: []
          },
          {
            '@objectId': 8,
            name: 'deviceConfiguration.read',
            description: formatMessage(messages.readConfig),
            hashId: '15f18ccd-8d8d-4c4a-8a89-1031c2aeb76a',
            children: []
          },
          {
            '@objectId': 9,
            name: 'device.edit',
            description: formatMessage(messages.applyConfig),
            hashId: '7faa65df-4889-4f70-8d67-85598290271c',
            children: []
          },
          {
            '@objectId': 10,
            name: 'firmware.read',
            description: formatMessage(messages.readFirmware),
            hashId: 'cc77c498-2c0a-485e-9885-0cd0d1f19513',
            children: []
          },
          {
            '@objectId': 11,
            name: 'firmware.edit',
            description: formatMessage(messages.applyFirmware),
            hashId: '65e31110-90f1-44c1-a1b8-b581444ab91a',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'NOTIFICATIONS',
        description: formatMessage(messages.notifications),
        hashId: 'fake',
        childrenHashes: ['3c03c22a-6e4f-4fa7-8754-3d86c0eebbec', '0a645ba9-aae5-44a4-ae46-beeb20f26748'],
        children: [
          {
            '@objectId': 12,
            name: 'notification.read',
            description: formatMessage(messages.view),
            hashId: '3c03c22a-6e4f-4fa7-8754-3d86c0eebbec',
            children: []
          },
          {
            '@objectId': 13,
            name: 'notification.edit',
            description: formatMessage(messages.edit),
            hashId: '0a645ba9-aae5-44a4-ae46-beeb20f26748',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'GEOTRACKING',
        description: formatMessage(messages.geolocation),
        hashId: 'fake',
        childrenHashes: [
          'eb6f9b4c-b1dd-4201-a348-ecfe08f90f7d',
          'bc5251f5-f850-4203-b05a-fe0a3383ad48',
          '61e2733b-0d3e-422f-afb4-50d33e9e3a5d',
          '558c5418-5633-4a56-83d3-bdeaa7f673f3',
          'e2d34c42-ce6c-4eef-be16-880759edfcda',
          '4d9952a0-3cad-4f10-ba39-c86091c1fbae'
        ],
        children: [
          {
            '@objectId': 'fake',
            name: 'GEOTRACKING',
            description: formatMessage(messages.geoTracking),
            hashId: 'fake',
            childrenHashes: ['eb6f9b4c-b1dd-4201-a348-ecfe08f90f7d', 'bc5251f5-f850-4203-b05a-fe0a3383ad48'],
            children: [
              {
                '@objectId': 14,
                name: 'geoTracking.view',
                description: formatMessage(messages.view),
                hashId: 'eb6f9b4c-b1dd-4201-a348-ecfe08f90f7d',
                children: []
              },
              {
                '@objectId': 15,
                name: 'geoTracking.edit',
                description: formatMessage(messages.edit),
                hashId: 'bc5251f5-f850-4203-b05a-fe0a3383ad48',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'GEOFENCE',
            description: formatMessage(messages.geofence),
            hashId: 'fake',
            childrenHashes: [
              '61e2733b-0d3e-422f-afb4-50d33e9e3a5d',
              '558c5418-5633-4a56-83d3-bdeaa7f673f3',
              'e2d34c42-ce6c-4eef-be16-880759edfcda',
              '4d9952a0-3cad-4f10-ba39-c86091c1fbae'
            ],
            children: [
              {
                '@objectId': 16,
                name: 'geoFence.read',
                description: formatMessage(messages.view),
                hashId: '61e2733b-0d3e-422f-afb4-50d33e9e3a5d',
                children: []
              },
              {
                '@objectId': 17,
                name: 'geoFence.create',
                description: formatMessage(messages.create),
                hashId: '558c5418-5633-4a56-83d3-bdeaa7f673f3',
                children: []
              },
              {
                '@objectId': 18,
                name: 'geoFence.edit',
                description: formatMessage(messages.edit),
                hashId: 'e2d34c42-ce6c-4eef-be16-880759edfcda',
                children: []
              },
              {
                '@objectId': 19,
                name: 'geoFence.delete',
                description: formatMessage(messages.delete),
                hashId: '4d9952a0-3cad-4f10-ba39-c86091c1fbae',
                children: []
              }
            ]
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'REPORTS',
        description: formatMessage(messages.reports),
        hashId: 'fake',
        childrenHashes: [
          '2ebd8ce1-97d7-44b4-a7b5-220194bcbd00',
          '0087c5f8-582e-4b00-afd9-d8926c3a536f',
          '49ab8073-e291-4bbd-a0e4-122c2fc1b754',
          '0dc02971-7402-4372-9535-8207a552d1b7'
        ],
        children: [
          {
            '@objectId': 20,
            name: 'schedule.read',
            description: formatMessage(messages.view),
            hashId: '2ebd8ce1-97d7-44b4-a7b5-220194bcbd00',
            children: []
          },
          {
            '@objectId': 21,
            name: 'schedule.create',
            description: formatMessage(messages.create),
            hashId: '0087c5f8-582e-4b00-afd9-d8926c3a536f',
            children: []
          },
          {
            '@objectId': 22,
            name: 'schedule.edit',
            description: formatMessage(messages.edit),
            hashId: '49ab8073-e291-4bbd-a0e4-122c2fc1b754',
            children: []
          },
          {
            '@objectId': 23,
            name: 'schedule.delete',
            description: formatMessage(messages.delete),
            hashId: '0dc02971-7402-4372-9535-8207a552d1b7',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'CHARTS',
        description: formatMessage(messages.charts),
        hashId: 'fake',
        childrenHashes: [
          'fe244abe-2035-4288-ba79-88bfd4fe13ae',
          'de876485-cd6e-486f-b628-62f5bf544e1c',
          'ad953910-d55d-49dd-8f47-e02a3633991a',
          '497b2fc6-2364-4d39-8e4f-a6140a5e1ca0'
        ],
        children: [
          {
            '@objectId': 24,
            name: 'charts.read',
            description: formatMessage(messages.view),
            hashId: 'fe244abe-2035-4288-ba79-88bfd4fe13ae',
            children: []
          },
          {
            '@objectId': 25,
            name: 'charts.create',
            description: formatMessage(messages.create),
            hashId: 'de876485-cd6e-486f-b628-62f5bf544e1c',
            children: []
          },
          {
            '@objectId': 26,
            name: 'charts.edit',
            description: formatMessage(messages.edit),
            hashId: 'ad953910-d55d-49dd-8f47-e02a3633991a',
            children: []
          },
          {
            '@objectId': 27,
            name: 'charts.delete',
            description: formatMessage(messages.delete),
            hashId: '497b2fc6-2364-4d39-8e4f-a6140a5e1ca0',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'EVENTS HISTORY',
        description: formatMessage(messages.actionsState),
        hashId: 'fake',
        childrenHashes: ['e9918f2a-2bd0-45db-a8a5-ea9110faf4f4', 'a65b6daa-e6ac-47ef-ac40-7d544ba8f69b'],
        children: [
          {
            '@objectId': 28,
            name: 'eventsHistory.view',
            description: formatMessage(messages.view),
            hashId: 'e9918f2a-2bd0-45db-a8a5-ea9110faf4f4',
            children: []
          },
          {
            '@objectId': 29,
            name: 'eventsHistory.edit',
            description: formatMessage(messages.delete),
            hashId: 'a65b6daa-e6ac-47ef-ac40-7d544ba8f69b',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'CONFIGURATIONS',
        description: formatMessage(messages.configurations),
        hashId: 'fake',
        childrenHashes: [
          '15f18ccd-8d8d-4c4a-8a89-1031c2aeb76a',
          '9d453222-76f8-4e1b-ad27-2fb87c34711b',
          'e9633d49-24ac-4420-9a2d-813fd79df9a8',
          '22e0f0fd-ede5-449f-9db2-785430aafe13'
        ],
        children: [
          {
            '@objectId': 30,
            name: 'deviceConfiguration.read',
            description: formatMessage(messages.view),
            hashId: '15f18ccd-8d8d-4c4a-8a89-1031c2aeb76a',
            children: []
          },
          {
            '@objectId': 31,
            name: 'deviceConfiguration.create',
            description: formatMessage(messages.create),
            hashId: '9d453222-76f8-4e1b-ad27-2fb87c34711b',
            children: []
          },
          {
            '@objectId': 32,
            name: 'deviceConfiguration.edit',
            description: formatMessage(messages.edit),
            hashId: 'e9633d49-24ac-4420-9a2d-813fd79df9a8',
            children: []
          },
          {
            '@objectId': 33,
            name: 'deviceConfiguration.delete',
            description: formatMessage(messages.delete),
            hashId: '22e0f0fd-ede5-449f-9db2-785430aafe13',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'DEVICE MANAGEMENT',
        description: formatMessage(messages.deviceManagement),
        hashId: 'fake',
        childrenHashes: [
          'ae7e94b3-b907-4aa9-a2bc-76fa045fb40b',
          'e03a9f14-57f1-40b6-ae4e-518e43246f60',
          '4af31d78-d1da-4d8e-8685-4b990387c04b',
          //'7faa65df-4889-4f70-8d67-85598290271c',
          'f84871f7-4af4-4260-9022-d7c6722b344d',
          'a102ebb5-7663-4c60-800b-8c6a01245578',
          'ee75868d-5d17-4775-ad4c-04111ab1edaf',
          'df52c089-aad6-4b85-b626-e718571da7e8',
          '25caf295-18e5-450a-94bf-32bcbf4ca0ac'
        ],
        children: [
          {
            '@objectId': 34,
            name: 'device.read',
            description: formatMessage(messages.view),
            hashId: 'ae7e94b3-b907-4aa9-a2bc-76fa045fb40b',
            children: []
          },
          {
            '@objectId': 35,
            name: 'deviceSim.edit',
            description: formatMessage(messages.productionTestMode),
            hashId: 'e03a9f14-57f1-40b6-ae4e-518e43246f60',
            children: []
          },
          {
            '@objectId': 36,
            name: 'device.create',
            description: formatMessage(messages.create),
            hashId: '4af31d78-d1da-4d8e-8685-4b990387c04b',
            children: []
          },
          /*{
            '@objectId': 36,
            name: 'device.edit',
            description: formatMessage(messages.edit),
            hashId: '7faa65df-4889-4f70-8d67-85598290271c',
            children: []
          },*/
          {
            '@objectId': 'fake',
            name: 'SERVICE PLANS AND SMS PACKS',
            description: formatMessage(messages.servicePlansAndSMSPacks),
            hashId: 'fake',
            childrenHashes: [
              'f84871f7-4af4-4260-9022-d7c6722b344d',
              'a102ebb5-7663-4c60-800b-8c6a01245578',
              'ee75868d-5d17-4775-ad4c-04111ab1edaf'
            ],
            children: [
              {
                '@objectId': 37,
                name: 'dataplan.read',
                description: formatMessage(messages.view),
                hashId: 'f84871f7-4af4-4260-9022-d7c6722b344d',
                children: []
              },
              {
                '@objectId': 38,
                name: 'dataplan.apply',
                description: formatMessage(messages.apply),
                hashId: 'a102ebb5-7663-4c60-800b-8c6a01245578',
                children: []
              },
              {
                '@objectId': 39,
                name: 'dataplan.share',
                description: formatMessage(messages.share),
                hashId: 'ee75868d-5d17-4775-ad4c-04111ab1edaf',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'ACCESS PASSES',
            description: formatMessage(messages.accessPasses),
            hashId: 'fake',
            childrenHashes: ['df52c089-aad6-4b85-b626-e718571da7e8', '25caf295-18e5-450a-94bf-32bcbf4ca0ac'],
            children: [
              {
                '@objectId': 40,
                name: 'accessPass.consume',
                description: formatMessage(messages.consume),
                hashId: 'df52c089-aad6-4b85-b626-e718571da7e8'
              },
              {
                '@objectId': 41,
                name: 'accessPass.share',
                description: formatMessage(messages.share),
                hashId: '25caf295-18e5-450a-94bf-32bcbf4ca0ac'
              }
            ]
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'GROUP DASHBOARDS',
        description: formatMessage(messages.groupDashboards),
        hashId: 'fake',
        childrenHashes: [
          '2bfa84fb-8d16-44f3-9bb2-22a2c890f743',
          'e06c543f-fab0-4941-8293-0be6bb3d1192',
          '9e78cf8c-3140-4cf8-a077-f4cecd9f6894',
          '6cb1cf9e-0be7-494a-a968-927dc008a596'
        ],
        children: [
          {
            '@objectId': 42,
            name: 'groupDashboard.read',
            description: formatMessage(messages.view),
            hashId: '2bfa84fb-8d16-44f3-9bb2-22a2c890f743',
            children: []
          },
          {
            '@objectId': 43,
            name: 'groupDashboard.edit',
            description: formatMessage(messages.edit),
            hashId: 'e06c543f-fab0-4941-8293-0be6bb3d1192',
            children: []
          },
          {
            '@objectId': 44,
            name: 'groupDashboard.create',
            description: formatMessage(messages.create),
            hashId: '9e78cf8c-3140-4cf8-a077-f4cecd9f6894',
            children: []
          },
          {
            '@objectId': 45,
            name: 'groupDashboard.delete',
            description: formatMessage(messages.delete),
            hashId: '6cb1cf9e-0be7-494a-a968-927dc008a596',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'SETTINGS',
        description: formatMessage(messages.settings),
        hashId: 'fake',
        childrenHashes: [
          '56e24d06-27e4-486a-9016-74ac4427b901',
          '8b52c270-0116-4c9a-919d-8f473c9aba14',
          'b6a15397-4a35-404d-ac17-edec1786fa8b',
          '44d5bc3a-f1c6-488a-9c90-a707e07ac357',
          '83a11ae6-a820-412f-8d5d-eef4b42180fc',
          'bd9c0eed-664f-408d-9faf-6201130eaa24',
          '2cbca0fd-5fb4-4ae8-9715-ce68156c1a35',
          '0c837eb7-d30c-4241-9bbe-a7be75e4b58f',
          '236c35e7-7b41-4bb8-9007-9df0004611eb',
          '9818993b-43b9-46d0-9449-32b00c9361ed',
          '9433c7ac-6c08-4db8-afac-1f299323475a',
          '2d3ca1db-1ea8-4536-ac65-8602ac85ff75',
          '21189e69-c2ef-4cc6-abfe-19c03e0b4bf9',
          'b9575565-0623-4605-9b36-15e87ede0d16',
          'f8dafe0a-07ff-447b-994f-c7207466c61a',
          'f0cd861f-ec41-48af-80ae-1e251db9f0b4'
        ],
        children: [
          {
            '@objectId': 'fake',
            name: 'USERS',
            description: formatMessage(messages.users),
            hashId: 'fake',
            childrenHashes: [
              '56e24d06-27e4-486a-9016-74ac4427b901',
              '8b52c270-0116-4c9a-919d-8f473c9aba14',
              'b6a15397-4a35-404d-ac17-edec1786fa8b'
            ],
            children: [
              {
                '@objectId': 46,
                name: 'user.read',
                description: formatMessage(messages.view),
                hashId: '56e24d06-27e4-486a-9016-74ac4427b901',
                children: []
              },
              {
                '@objectId': 47,
                name: 'user.create',
                description: formatMessage(messages.create),
                hashId: '8b52c270-0116-4c9a-919d-8f473c9aba14'
              },
              {
                '@objectId': 48,
                name: 'user.edit',
                description: formatMessage(messages.edit),
                hashId: 'b6a15397-4a35-404d-ac17-edec1786fa8b',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'ROLES',
            description: formatMessage(messages.roles),
            hashId: 'fake',
            childrenHashes: [
              '44d5bc3a-f1c6-488a-9c90-a707e07ac357',
              '83a11ae6-a820-412f-8d5d-eef4b42180fc',
              'bd9c0eed-664f-408d-9faf-6201130eaa24',
              '2cbca0fd-5fb4-4ae8-9715-ce68156c1a35'
            ],
            children: [
              {
                '@objectId': 49,
                name: 'groupRole.read',
                description: formatMessage(messages.view),
                hashId: '44d5bc3a-f1c6-488a-9c90-a707e07ac357',
                children: []
              },
              {
                '@objectId': 50,
                name: 'groupRole.edit',
                description: formatMessage(messages.edit),
                hashId: '83a11ae6-a820-412f-8d5d-eef4b42180fc',
                children: []
              },
              {
                '@objectId': 51,
                name: 'groupRole.create',
                description: formatMessage(messages.create),
                hashId: 'bd9c0eed-664f-408d-9faf-6201130eaa24',
                children: []
              },
              {
                '@objectId': 52,
                name: 'groupRole.delete',
                description: formatMessage(messages.delete),
                hashId: '2cbca0fd-5fb4-4ae8-9715-ce68156c1a35',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'GROUPS',
            description: formatMessage(messages.groups),
            hashId: 'fake',
            childrenHashes: [
              '0c837eb7-d30c-4241-9bbe-a7be75e4b58f',
              '236c35e7-7b41-4bb8-9007-9df0004611eb',
              '9818993b-43b9-46d0-9449-32b00c9361ed',
              '9433c7ac-6c08-4db8-afac-1f299323475a',
              '2d3ca1db-1ea8-4536-ac65-8602ac85ff75'
            ],
            children: [
              {
                '@objectId': 53,
                name: 'groups.read',
                description: formatMessage(messages.view),
                hashId: '0c837eb7-d30c-4241-9bbe-a7be75e4b58f',
                children: []
              },
              {
                '@objectId': 54,
                name: 'groups.create',
                description: formatMessage(messages.create),
                hashId: '236c35e7-7b41-4bb8-9007-9df0004611eb',
                children: []
              },
              {
                '@objectId': 55,
                name: 'groups.edit',
                description: formatMessage(messages.edit),
                hashId: '9818993b-43b9-46d0-9449-32b00c9361ed',
                children: []
              },
              {
                '@objectId': 56,
                name: 'groups.delete',
                description: formatMessage(messages.delete),
                hashId: '9433c7ac-6c08-4db8-afac-1f299323475a',
                children: []
              },
              {
                '@objectId': 57,
                name: 'resourceSharing.allow',
                description: formatMessage(messages.shareResources),
                hashId: '2d3ca1db-1ea8-4536-ac65-8602ac85ff75',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'NOTIFICATIONS',
            description: formatMessage(messages.notifications),
            hashId: 'fake',
            childrenHashes: [
              '21189e69-c2ef-4cc6-abfe-19c03e0b4bf9',
              'b9575565-0623-4605-9b36-15e87ede0d16',
              'f8dafe0a-07ff-447b-994f-c7207466c61a',
              'f0cd861f-ec41-48af-80ae-1e251db9f0b4'
            ],
            children: [
              {
                '@objectId': 58,
                name: 'rule.read',
                description: formatMessage(messages.view),
                hashId: '21189e69-c2ef-4cc6-abfe-19c03e0b4bf9',
                children: []
              },
              {
                '@objectId': 59,
                name: 'rule.create',
                description: formatMessage(messages.create),
                hashId: 'b9575565-0623-4605-9b36-15e87ede0d16',
                children: []
              },
              {
                '@objectId': 60,
                name: 'rule.edit',
                description: formatMessage(messages.edit),
                hashId: 'f8dafe0a-07ff-447b-994f-c7207466c61a',
                children: []
              },
              {
                '@objectId': 61,
                name: 'rule.delete',
                description: formatMessage(messages.delete),
                hashId: 'f0cd861f-ec41-48af-80ae-1e251db9f0b4',
                children: []
              }
            ]
          }
        ]
      }
    ]

    break

  case 'staging':
    csPrivileges = [
      {
        '@objectId': 1,
        name: 'map.read',
        description: 'Can view map',
        hashId: 'ad97153c-d6fa-4fa8-a39f-8fe9b4a3ed92'
      },
      {
        '@objectId': 2,
        name: 'device.read',
        description: 'Can view devices',
        hashId: '199a856d-2df4-45eb-bd6f-a75a8da07734'
      },
      {
        '@objectId': 3,
        name: 'deviceDashboard.read',
        description: 'Can view device dashboards',
        hashId: '9e36723c-8b3b-445b-a4bc-17507b5b9a74'
      },
      {
        '@objectId': 4,
        name: 'deviceDashboard.edit',
        description: 'Can edit device dashboards',
        hashId: '2ea68da0-28c0-4b87-bb66-73ad5d630d41'
      },
      {
        '@objectId': 5,
        name: 'deviceDashboard.create',
        description: 'Can create device dashboards',
        hashId: '8b9a66e1-a098-4d01-baae-833cc09fb3f2'
      },
      {
        '@objectId': 6,
        name: 'deviceDashboard.delete',
        description: 'Can edit device dashboards',
        hashId: '068764b7-f43d-4b9f-bcaa-e558c1b9ee37'
      },
      {
        '@objectId': 7,
        name: 'nodeDetails.view',
        description: 'Can view device details',
        hashId: 'e5f08670-d10a-40cd-b0e7-9f6f47812e70'
      },
      {
        '@objectId': 8,
        name: 'deviceConfiguration.read',
        description: 'Can read device configuration',
        hashId: '7b07e010-d91d-48be-81be-ee054afe32e4'
      },
      {
        '@objectId': 9,
        name: 'device.edit',
        description: 'Can edit devices',
        hashId: 'ec5e7767-f4d5-4b14-a8d5-1fa536e743cc'
      },
      {
        '@objectId': 10,
        name: 'firmware.read',
        description: 'Can read device firmware',
        hashId: 'fdd99390-6d6b-407e-b685-2451a9566bfb'
      },
      {
        '@objectId': 11,
        name: 'firmware.edit',
        description: 'Can edit device firmware',
        hashId: 'f1f889e6-47d8-4325-b65c-53b2effa2c39'
      },
      {
        '@objectId': 12,
        name: 'notification.read',
        description: 'Can view notifications',
        hashId: '4ca4e511-71eb-4406-bc48-4e4b643a828f'
      },
      {
        '@objectId': 13,
        name: 'notification.edit',
        description: 'Can edit notification',
        hashId: 'd05e536e-4e7b-4ac6-bfbe-ed34caae9de0'
      },
      {
        '@objectId': 14,
        name: 'geoTracking.view',
        description: 'Can view Geotracking',
        hashId: '3bbb4d6c-cdda-46a6-b7e2-fa6a5cb4df31'
      },
      {
        '@objectId': 15,
        name: 'geoTracking.edit',
        description: 'Can edit Geotracking',
        hashId: 'be2a60c4-3f96-44be-83b4-982c5072fa0d'
      },
      {
        '@objectId': 16,
        name: 'geoFence.read',
        description: 'Can read Geofences',
        hashId: '07a37da3-8529-492e-b669-fc96874b2da2'
      },
      {
        '@objectId': 17,
        name: 'geoFence.create',
        description: 'Can create Geofences',
        hashId: 'f9b99951-8f2f-46b3-ba1b-cc379dbf8d21'
      },
      {
        '@objectId': 18,
        name: 'geoFence.edit',
        description: 'Can edit Geofences',
        hashId: '72991a55-ecdf-4a34-bfef-e1b736139e90'
      },
      {
        '@objectId': 19,
        name: 'geoFence.delete',
        description: 'Can delete Geofences',
        hashId: '85e22e08-cf9e-4f5f-83f4-d9eb0234a5fb'
      },
      {
        '@objectId': 20,
        name: 'schedule.read',
        description: 'Can read schedules',
        hashId: '12d89c41-b7ea-46af-bcc1-d006027c6574'
      },
      {
        '@objectId': 21,
        name: 'schedule.create',
        description: 'Can create schedules',
        hashId: 'a8a053a1-e525-4690-8c2b-6b3902cca3b0'
      },
      {
        '@objectId': 22,
        name: 'schedule.edit',
        description: 'Can edit schedules',
        hashId: '4570a32d-e8a6-4c5b-9bae-b0b85f2216bb'
      },
      {
        '@objectId': 23,
        name: 'schedule.delete',
        description: 'Can delete reports',
        hashId: 'c3ed4b6c-9dd8-4eb2-b11d-e9af8d3df136'
      },
      {
        '@objectId': 24,
        name: 'charts.read',
        description: 'Can read charts',
        hashId: '5644ce26-fb61-4f91-899f-a155ab1b6bd0'
      },
      {
        '@objectId': 25,
        name: 'charts.create',
        description: 'Can create charts',
        hashId: '3defccc6-0661-4029-bdd2-447d173d0f21'
      },
      {
        '@objectId': 26,
        name: 'charts.edit',
        description: 'Can edit reports',
        hashId: '2657b831-897e-4f21-b2a1-4654c6fb6e16'
      },
      {
        '@objectId': 27,
        name: 'charts.delete',
        description: 'Can delete reports',
        hashId: 'c1049a6b-37c0-4eef-ac35-225e784a5eb2'
      },
      {
        '@objectId': 28,
        name: 'eventsHistory.view',
        description: 'Can view Events history',
        hashId: '46391ba5-0e9f-4e71-b5e1-fbde0c070d48'
      },
      {
        '@objectId': 29,
        name: 'eventsHistory.edit',
        description: 'Can edit Events history',
        hashId: '21495580-db6c-4b9f-9c17-6abe19932dc9'
      },
      {
        '@objectId': 30,
        name: 'deviceConfiguration.read',
        description: 'Can read device configurations',
        hashId: '7b07e010-d91d-48be-81be-ee054afe32e4'
      },
      {
        '@objectId': 31,
        name: 'deviceConfiguration.create',
        description: 'Can create device configurations',
        hashId: 'c839d4ff-efa9-470e-8713-670fbfed4339'
      },
      {
        '@objectId': 32,
        name: 'deviceConfiguration.edit',
        description: 'Can edit device configurations',
        hashId: '85258720-40e4-4211-b9c5-576e5e7efae2'
      },
      {
        '@objectId': 33,
        name: 'deviceConfiguration.delete',
        description: 'Can delete device configurations',
        hashId: 'ff69f719-dda9-4bb2-8209-2c30f8c69b0c'
      },
      {
        '@objectId': 34,
        name: 'device.read',
        description: 'Can view device',
        hashId: '199a856d-2df4-45eb-bd6f-a75a8da07734'
      },
      {
        '@objectId': 35,
        name: 'deviceSim.edit',
        description: 'Can activate production test mode',
        hashId: '757dded7-5205-4b2c-8f0b-5b35f357ebe9'
      },
      {
        '@objectId': 36,
        name: 'device.create',
        description: 'Can register devices',
        hashId: 'a3200110-f421-4d33-841d-24cec6625c1f'
      },
      {
        '@objectId': 37,
        name: 'device.edit',
        description: 'Can edit devices',
        hashId: 'ec5e7767-f4d5-4b14-a8d5-1fa536e743cc'
      },
      {
        '@objectId': 38,
        name: 'dataplan.read',
        description: 'Can view dataplans',
        hashId: '89ddc712-c632-4525-9d72-912263f1375d'
      },
      {
        '@objectId': 39,
        name: 'dataplan.apply',
        description: 'Can apply dataplans',
        hashId: '9669705e-ce82-49fe-b386-5df49c88d964'
      },
      {
        '@objectId': 40,
        name: 'dataplan.share',
        description: 'Can share dataplans',
        hashId: '95621043-e5f7-441e-b246-93e99907f276'
      },
      {
        '@objectId': 41,
        name: 'accessPass.consume',
        description: 'Can consume Access Pass',
        hashId: '5e5fbd07-fb43-4043-8b79-ba943ccbe2a3'
      },
      {
        '@objectId': 42,
        name: 'accessPass.share',
        description: 'Can share Access Pass',
        hashId: '90eb4156-6ea6-49e3-9e7b-81345f499fbc'
      },
      {
        '@objectId': 43,
        name: 'groupDashboard.read',
        description: 'Can read group dashboards',
        hashId: 'fa715fc8-9d83-45bc-a752-1e0ae60dfaa1'
      },
      {
        '@objectId': 44,
        name: 'groupDashboard.edit',
        description: 'Can edit group dashboards',
        hashId: '1d97721b-27fc-42d5-9238-e9757647f677'
      },
      {
        '@objectId': 45,
        name: 'groupDashboard.create',
        description: 'Can create group dashboards',
        hashId: 'f42ebe1e-6ff2-4adb-8bca-4b86842eee2a'
      },
      {
        '@objectId': 46,
        name: 'groupDashboard.delete',
        description: 'Can delete group dashboards',
        hashId: '323f49a8-1e5a-4d7f-9cbd-a5b4d6e63a90'
      },
      {
        '@objectId': 47,
        name: 'user.read',
        description: 'Can view users',
        hashId: '356d9b5a-c204-42ad-aa82-a532be5f8fb1'
      },
      {
        '@objectId': 48,
        name: 'user.create',
        description: 'Can create users',
        hashId: '63e59d44-5530-4f52-b055-f4557b8aaa6c'
      },
      {
        '@objectId': 49,
        name: 'user.edit',
        description: 'Can edit users',
        hashId: 'ddd11f9a-4945-4e8d-a5fc-de92d1670593'
      },
      {
        '@objectId': 50,
        name: 'groupRole.read',
        description: 'Can read roles',
        hashId: '3fa1b71d-ba45-4cc8-9e8f-8e5b209371c1'
      },
      {
        '@objectId': 51,
        name: 'groupRole.edit',
        description: 'Can edit roles',
        hashId: 'dbc48d48-d5fc-4450-ad1a-6df225555df5'
      },
      {
        '@objectId': 52,
        name: 'groupRole.create',
        description: 'Can create roles',
        hashId: 'c1c75185-52cd-445c-b16c-301f92568a18'
      },
      {
        '@objectId': 53,
        name: 'groupRole.delete',
        description: 'Can delete roles',
        hashId: '8c84afd7-0fbf-4e20-b72f-92f3ca5dd79f'
      },
      {
        '@objectId': 54,
        name: 'groups.read',
        description: 'Can view groups',
        hashId: '45567a4c-23c3-47c0-83be-a132e633ca39'
      },
      {
        '@objectId': 55,
        name: 'groups.create',
        description: 'Can create groups',
        hashId: 'd52d8cc9-ee78-44f1-bb58-5c1b37521296'
      },
      {
        '@objectId': 56,
        name: 'groups.edit',
        description: 'Can edit groups',
        hashId: '1dff37d9-9eb2-46f6-9773-f483c3b7b496'
      },
      {
        '@objectId': 57,
        name: 'groups.delete',
        description: 'Can delete groups',
        hashId: 'c4dad741-65e8-4e2e-a747-64d03696f049'
      },
      {
        '@objectId': 58,
        name: 'resourceSharing.allow',
        description: 'Can share resources in group',
        hashId: '1ce02721-9d15-451a-9c5f-b482bc26f609'
      },
      {
        '@objectId': 59,
        name: 'rule.read',
        description: 'Can read notification rules',
        hashId: '84deb5c1-2d72-4fee-a9fe-786314deedb3'
      },
      {
        '@objectId': 60,
        name: 'rule.create',
        description: 'Can create notification rules',
        hashId: 'a2c63b3f-6c2d-4aaa-917c-a129fcd28417'
      },
      {
        '@objectId': 61,
        name: 'rule.edit',
        description: 'Can edit notification rules',
        hashId: 'fa78c5db-9204-49db-9abd-82aab767b90e'
      },
      {
        '@objectId': 62,
        name: 'rule.delete',
        description: 'Can delete notification rules',
        hashId: '3071d0cd-387e-441c-823f-2afad808faf7'
      }
    ]

    csPrivilegesHierarchy = formatMessage => [
      {
        '@objectId': 1,
        name: 'map.read',
        description: formatMessage(messages.map),
        hashId: 'ad97153c-d6fa-4fa8-a39f-8fe9b4a3ed92',
        children: []
      },
      {
        '@objectId': 'fake',
        name: 'DEVICES',
        description: formatMessage(messages.nodes),
        hashId: 'fake',
        childrenHashes: [
          '199a856d-2df4-45eb-bd6f-a75a8da07734',
          '9e36723c-8b3b-445b-a4bc-17507b5b9a74',
          '2ea68da0-28c0-4b87-bb66-73ad5d630d41',
          '8b9a66e1-a098-4d01-baae-833cc09fb3f2',
          '068764b7-f43d-4b9f-bcaa-e558c1b9ee37',
          'e5f08670-d10a-40cd-b0e7-9f6f47812e70',
          '7b07e010-d91d-48be-81be-ee054afe32e4',
          'ec5e7767-f4d5-4b14-a8d5-1fa536e743cc',
          'fdd99390-6d6b-407e-b685-2451a9566bfb',
          'f1f889e6-47d8-4325-b65c-53b2effa2c39'
        ],
        children: [
          {
            '@objectId': 2,
            name: 'device.read',
            description: formatMessage(messages.view),
            hashId: '199a856d-2df4-45eb-bd6f-a75a8da07734',
            children: []
          },
          {
            '@objectId': 'fake',
            name: 'DASHBOARDS',
            description: formatMessage(messages.dashboards),
            hashId: 'fake',
            childrenHashes: [
              '9e36723c-8b3b-445b-a4bc-17507b5b9a74',
              '2ea68da0-28c0-4b87-bb66-73ad5d630d41',
              '8b9a66e1-a098-4d01-baae-833cc09fb3f2',
              '068764b7-f43d-4b9f-bcaa-e558c1b9ee37'
            ],
            children: [
              {
                '@objectId': 3,
                name: 'deviceDashboard.read',
                description: formatMessage(messages.view),
                hashId: '9e36723c-8b3b-445b-a4bc-17507b5b9a74',
                children: []
              },
              {
                '@objectId': 4,
                name: 'deviceDashboard.edit',
                description: formatMessage(messages.edit),
                hashId: '2ea68da0-28c0-4b87-bb66-73ad5d630d41',
                children: []
              },
              {
                '@objectId': 5,
                name: 'deviceDashboard.create',
                description: formatMessage(messages.create),
                hashId: '8b9a66e1-a098-4d01-baae-833cc09fb3f2',
                children: []
              },
              {
                '@objectId': 6,
                name: 'deviceDashboard.delete',
                description: formatMessage(messages.delete),
                hashId: '068764b7-f43d-4b9f-bcaa-e558c1b9ee37',
                children: []
              }
            ]
          },
          {
            '@objectId': 7,
            name: 'nodeDetails.view',
            description: formatMessage(messages.nodeDetail),
            hashId: 'e5f08670-d10a-40cd-b0e7-9f6f47812e70',
            children: []
          },
          {
            '@objectId': 8,
            name: 'deviceConfiguration.read',
            description: formatMessage(messages.readConfig),
            hashId: '7b07e010-d91d-48be-81be-ee054afe32e4',
            children: []
          },
          {
            '@objectId': 9,
            name: 'device.edit',
            description: formatMessage(messages.applyConfig),
            hashId: 'ec5e7767-f4d5-4b14-a8d5-1fa536e743cc',
            children: []
          },
          {
            '@objectId': 10,
            name: 'firmware.read',
            description: formatMessage(messages.readFirmware),
            hashId: 'fdd99390-6d6b-407e-b685-2451a9566bfb',
            children: []
          },
          {
            '@objectId': 11,
            name: 'firmware.edit',
            description: formatMessage(messages.applyFirmware),
            hashId: 'f1f889e6-47d8-4325-b65c-53b2effa2c39',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'NOTIFICATIONS',
        description: formatMessage(messages.notifications),
        hashId: 'fake',
        childrenHashes: ['4ca4e511-71eb-4406-bc48-4e4b643a828f', 'd05e536e-4e7b-4ac6-bfbe-ed34caae9de0'],
        children: [
          {
            '@objectId': 12,
            name: 'notification.read',
            description: formatMessage(messages.view),
            hashId: '4ca4e511-71eb-4406-bc48-4e4b643a828f',
            children: []
          },
          {
            '@objectId': 13,
            name: 'notification.edit',
            description: formatMessage(messages.edit),
            hashId: 'd05e536e-4e7b-4ac6-bfbe-ed34caae9de0',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'GEOTRACKING',
        description: formatMessage(messages.geolocation),
        hashId: 'fake',
        childrenHashes: [
          '3bbb4d6c-cdda-46a6-b7e2-fa6a5cb4df31',
          'be2a60c4-3f96-44be-83b4-982c5072fa0d',
          '07a37da3-8529-492e-b669-fc96874b2da2',
          'f9b99951-8f2f-46b3-ba1b-cc379dbf8d21',
          '72991a55-ecdf-4a34-bfef-e1b736139e90',
          '85e22e08-cf9e-4f5f-83f4-d9eb0234a5fb'
        ],
        children: [
          {
            '@objectId': 'fake',
            name: 'GEOTRACKING',
            description: formatMessage(messages.geoTracking),
            hashId: 'fake',
            childrenHashes: ['3bbb4d6c-cdda-46a6-b7e2-fa6a5cb4df31', 'be2a60c4-3f96-44be-83b4-982c5072fa0d'],
            children: [
              {
                '@objectId': 14,
                name: 'geoTracking.view',
                description: formatMessage(messages.view),
                hashId: '3bbb4d6c-cdda-46a6-b7e2-fa6a5cb4df31',
                children: []
              },
              {
                '@objectId': 15,
                name: 'geoTracking.edit',
                description: formatMessage(messages.edit),
                hashId: 'be2a60c4-3f96-44be-83b4-982c5072fa0d',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'GEOFENCE',
            description: formatMessage(messages.geofence),
            hashId: 'fake',
            childrenHashes: [
              '07a37da3-8529-492e-b669-fc96874b2da2',
              'f9b99951-8f2f-46b3-ba1b-cc379dbf8d21',
              '72991a55-ecdf-4a34-bfef-e1b736139e90',
              '85e22e08-cf9e-4f5f-83f4-d9eb0234a5fb'
            ],
            children: [
              {
                '@objectId': 16,
                name: 'geoFence.read',
                description: formatMessage(messages.view),
                hashId: '07a37da3-8529-492e-b669-fc96874b2da2',
                children: []
              },
              {
                '@objectId': 17,
                name: 'geoFence.create',
                description: formatMessage(messages.create),
                hashId: 'f9b99951-8f2f-46b3-ba1b-cc379dbf8d21',
                children: []
              },
              {
                '@objectId': 18,
                name: 'geoFence.edit',
                description: formatMessage(messages.edit),
                hashId: '72991a55-ecdf-4a34-bfef-e1b736139e90',
                children: []
              },
              {
                '@objectId': 19,
                name: 'geoFence.delete',
                description: formatMessage(messages.delete),
                hashId: '85e22e08-cf9e-4f5f-83f4-d9eb0234a5fb',
                children: []
              }
            ]
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'REPORTS',
        description: formatMessage(messages.reports),
        hashId: 'fake',
        childrenHashes: [
          '12d89c41-b7ea-46af-bcc1-d006027c6574',
          'a8a053a1-e525-4690-8c2b-6b3902cca3b0',
          '4570a32d-e8a6-4c5b-9bae-b0b85f2216bb',
          'c3ed4b6c-9dd8-4eb2-b11d-e9af8d3df136'
        ],
        children: [
          {
            '@objectId': 20,
            name: 'schedule.read',
            description: formatMessage(messages.view),
            hashId: '12d89c41-b7ea-46af-bcc1-d006027c6574',
            children: []
          },
          {
            '@objectId': 21,
            name: 'schedule.create',
            description: formatMessage(messages.create),
            hashId: 'a8a053a1-e525-4690-8c2b-6b3902cca3b0',
            children: []
          },
          {
            '@objectId': 22,
            name: 'schedule.edit',
            description: formatMessage(messages.edit),
            hashId: '4570a32d-e8a6-4c5b-9bae-b0b85f2216bb',
            children: []
          },
          {
            '@objectId': 23,
            name: 'schedule.delete',
            description: formatMessage(messages.delete),
            hashId: 'c3ed4b6c-9dd8-4eb2-b11d-e9af8d3df136',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'CHARTS',
        description: formatMessage(messages.charts),
        hashId: 'fake',
        childrenHashes: [
          '5644ce26-fb61-4f91-899f-a155ab1b6bd0',
          '3defccc6-0661-4029-bdd2-447d173d0f21',
          '2657b831-897e-4f21-b2a1-4654c6fb6e16',
          'c1049a6b-37c0-4eef-ac35-225e784a5eb2'
        ],
        children: [
          {
            '@objectId': 24,
            name: 'charts.read',
            description: formatMessage(messages.view),
            hashId: '5644ce26-fb61-4f91-899f-a155ab1b6bd0',
            children: []
          },
          {
            '@objectId': 25,
            name: 'charts.create',
            description: formatMessage(messages.create),
            hashId: '3defccc6-0661-4029-bdd2-447d173d0f21',
            children: []
          },
          {
            '@objectId': 26,
            name: 'charts.edit',
            description: formatMessage(messages.edit),
            hashId: '2657b831-897e-4f21-b2a1-4654c6fb6e16',
            children: []
          },
          {
            '@objectId': 27,
            name: 'charts.delete',
            description: formatMessage(messages.delete),
            hashId: 'c1049a6b-37c0-4eef-ac35-225e784a5eb2',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'EVENTS HISTORY',
        description: formatMessage(messages.actionsState),
        hashId: 'fake',
        childrenHashes: ['46391ba5-0e9f-4e71-b5e1-fbde0c070d48', '21495580-db6c-4b9f-9c17-6abe19932dc9'],
        children: [
          {
            '@objectId': 28,
            name: 'eventsHistory.view',
            description: formatMessage(messages.view),
            hashId: '46391ba5-0e9f-4e71-b5e1-fbde0c070d48',
            children: []
          },
          {
            '@objectId': 29,
            name: 'eventsHistory.edit',
            description: formatMessage(messages.delete),
            hashId: '21495580-db6c-4b9f-9c17-6abe19932dc9',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'CONFIGURATIONS',
        description: formatMessage(messages.configurations),
        hashId: 'fake',
        childrenHashes: [
          '7b07e010-d91d-48be-81be-ee054afe32e4',
          'c839d4ff-efa9-470e-8713-670fbfed4339',
          '85258720-40e4-4211-b9c5-576e5e7efae2',
          'ff69f719-dda9-4bb2-8209-2c30f8c69b0c'
        ],
        children: [
          {
            '@objectId': 30,
            name: 'deviceConfiguration.read',
            description: formatMessage(messages.view),
            hashId: '7b07e010-d91d-48be-81be-ee054afe32e4',
            children: []
          },
          {
            '@objectId': 31,
            name: 'deviceConfiguration.create',
            description: formatMessage(messages.create),
            hashId: 'c839d4ff-efa9-470e-8713-670fbfed4339',
            children: []
          },
          {
            '@objectId': 32,
            name: 'deviceConfiguration.edit',
            description: formatMessage(messages.edit),
            hashId: '85258720-40e4-4211-b9c5-576e5e7efae2',
            children: []
          },
          {
            '@objectId': 33,
            name: 'deviceConfiguration.delete',
            description: formatMessage(messages.delete),
            hashId: 'ff69f719-dda9-4bb2-8209-2c30f8c69b0c',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'DEVICE MANAGEMENT',
        description: formatMessage(messages.deviceManagement),
        hashId: 'fake',
        childrenHashes: [
          '199a856d-2df4-45eb-bd6f-a75a8da07734',
          '757dded7-5205-4b2c-8f0b-5b35f357ebe9',
          'a3200110-f421-4d33-841d-24cec6625c1f',
          //'ec5e7767-f4d5-4b14-a8d5-1fa536e743cc',
          '89ddc712-c632-4525-9d72-912263f1375d',
          '9669705e-ce82-49fe-b386-5df49c88d964',
          '95621043-e5f7-441e-b246-93e99907f276',
          '5e5fbd07-fb43-4043-8b79-ba943ccbe2a3',
          '90eb4156-6ea6-49e3-9e7b-81345f499fbc'
        ],
        children: [
          {
            '@objectId': 34,
            name: 'device.read',
            description: formatMessage(messages.view),
            hashId: '199a856d-2df4-45eb-bd6f-a75a8da07734',
            children: []
          },
          {
            '@objectId': 35,
            name: 'deviceSim.edit',
            description: formatMessage(messages.productionTestMode),
            hashId: '757dded7-5205-4b2c-8f0b-5b35f357ebe9',
            children: []
          },
          {
            '@objectId': 36,
            name: 'device.create',
            description: formatMessage(messages.create),
            hashId: 'a3200110-f421-4d33-841d-24cec6625c1f',
            children: []
          },
          /*{
              '@objectId': 36,
              name: 'device.edit',
              description: formatMessage(messages.edit),
              hashId: 'ec5e7767-f4d5-4b14-a8d5-1fa536e743cc',
              children: []
            },*/
          {
            '@objectId': 'fake',
            name: 'SERVICE PLANS AND SMS PACKS',
            description: formatMessage(messages.servicePlansAndSMSPacks),
            hashId: 'fake',
            childrenHashes: [
              '89ddc712-c632-4525-9d72-912263f1375d',
              '9669705e-ce82-49fe-b386-5df49c88d964',
              '95621043-e5f7-441e-b246-93e99907f276'
            ],
            children: [
              {
                '@objectId': 37,
                name: 'dataplan.read',
                description: formatMessage(messages.view),
                hashId: '89ddc712-c632-4525-9d72-912263f1375d',
                children: []
              },
              {
                '@objectId': 38,
                name: 'dataplan.apply',
                description: formatMessage(messages.apply),
                hashId: '9669705e-ce82-49fe-b386-5df49c88d964',
                children: []
              },
              {
                '@objectId': 39,
                name: 'dataplan.share',
                description: formatMessage(messages.share),
                hashId: '95621043-e5f7-441e-b246-93e99907f276',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'ACCESS PASSES',
            description: formatMessage(messages.accessPasses),
            hashId: 'fake',
            childrenHashes: ['5e5fbd07-fb43-4043-8b79-ba943ccbe2a3', '90eb4156-6ea6-49e3-9e7b-81345f499fbc'],
            children: [
              {
                '@objectId': 40,
                name: 'accessPass.consume',
                description: formatMessage(messages.consume),
                hashId: '5e5fbd07-fb43-4043-8b79-ba943ccbe2a3'
              },
              {
                '@objectId': 41,
                name: 'accessPass.share',
                description: formatMessage(messages.share),
                hashId: '90eb4156-6ea6-49e3-9e7b-81345f499fbc'
              }
            ]
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'GROUP DASHBOARDS',
        description: formatMessage(messages.groupDashboards),
        hashId: 'fake',
        childrenHashes: [
          'fa715fc8-9d83-45bc-a752-1e0ae60dfaa1',
          '1d97721b-27fc-42d5-9238-e9757647f677',
          'f42ebe1e-6ff2-4adb-8bca-4b86842eee2a',
          '323f49a8-1e5a-4d7f-9cbd-a5b4d6e63a90'
        ],
        children: [
          {
            '@objectId': 42,
            name: 'groupDashboard.read',
            description: formatMessage(messages.view),
            hashId: 'fa715fc8-9d83-45bc-a752-1e0ae60dfaa1',
            children: []
          },
          {
            '@objectId': 43,
            name: 'groupDashboard.edit',
            description: formatMessage(messages.edit),
            hashId: '1d97721b-27fc-42d5-9238-e9757647f677',
            children: []
          },
          {
            '@objectId': 44,
            name: 'groupDashboard.create',
            description: formatMessage(messages.create),
            hashId: 'f42ebe1e-6ff2-4adb-8bca-4b86842eee2a',
            children: []
          },
          {
            '@objectId': 45,
            name: 'groupDashboard.delete',
            description: formatMessage(messages.delete),
            hashId: '323f49a8-1e5a-4d7f-9cbd-a5b4d6e63a90',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'SETTINGS',
        description: formatMessage(messages.settings),
        hashId: 'fake',
        childrenHashes: [
          '356d9b5a-c204-42ad-aa82-a532be5f8fb1',
          '63e59d44-5530-4f52-b055-f4557b8aaa6c',
          'ddd11f9a-4945-4e8d-a5fc-de92d1670593',
          '3fa1b71d-ba45-4cc8-9e8f-8e5b209371c1',
          'dbc48d48-d5fc-4450-ad1a-6df225555df5',
          'c1c75185-52cd-445c-b16c-301f92568a18',
          '8c84afd7-0fbf-4e20-b72f-92f3ca5dd79f',
          '45567a4c-23c3-47c0-83be-a132e633ca39',
          'd52d8cc9-ee78-44f1-bb58-5c1b37521296',
          '1dff37d9-9eb2-46f6-9773-f483c3b7b496',
          'c4dad741-65e8-4e2e-a747-64d03696f049',
          '1ce02721-9d15-451a-9c5f-b482bc26f609',
          '84deb5c1-2d72-4fee-a9fe-786314deedb3',
          'a2c63b3f-6c2d-4aaa-917c-a129fcd28417',
          'fa78c5db-9204-49db-9abd-82aab767b90e',
          '3071d0cd-387e-441c-823f-2afad808faf7'
        ],
        children: [
          {
            '@objectId': 'fake',
            name: 'USERS',
            description: formatMessage(messages.users),
            hashId: 'fake',
            childrenHashes: [
              '356d9b5a-c204-42ad-aa82-a532be5f8fb1',
              '63e59d44-5530-4f52-b055-f4557b8aaa6c',
              'ddd11f9a-4945-4e8d-a5fc-de92d1670593'
            ],
            children: [
              {
                '@objectId': 46,
                name: 'user.read',
                description: formatMessage(messages.view),
                hashId: '356d9b5a-c204-42ad-aa82-a532be5f8fb1',
                children: []
              },
              {
                '@objectId': 47,
                name: 'user.create',
                description: formatMessage(messages.create),
                hashId: '63e59d44-5530-4f52-b055-f4557b8aaa6c'
              },
              {
                '@objectId': 48,
                name: 'user.edit',
                description: formatMessage(messages.edit),
                hashId: 'ddd11f9a-4945-4e8d-a5fc-de92d1670593',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'ROLES',
            description: formatMessage(messages.roles),
            hashId: 'fake',
            childrenHashes: [
              '3fa1b71d-ba45-4cc8-9e8f-8e5b209371c1',
              'dbc48d48-d5fc-4450-ad1a-6df225555df5',
              'c1c75185-52cd-445c-b16c-301f92568a18',
              '8c84afd7-0fbf-4e20-b72f-92f3ca5dd79f'
            ],
            children: [
              {
                '@objectId': 49,
                name: 'groupRole.read',
                description: formatMessage(messages.view),
                hashId: '3fa1b71d-ba45-4cc8-9e8f-8e5b209371c1',
                children: []
              },
              {
                '@objectId': 50,
                name: 'groupRole.edit',
                description: formatMessage(messages.edit),
                hashId: 'dbc48d48-d5fc-4450-ad1a-6df225555df5',
                children: []
              },
              {
                '@objectId': 51,
                name: 'groupRole.create',
                description: formatMessage(messages.create),
                hashId: 'c1c75185-52cd-445c-b16c-301f92568a18',
                children: []
              },
              {
                '@objectId': 52,
                name: 'groupRole.delete',
                description: formatMessage(messages.delete),
                hashId: '8c84afd7-0fbf-4e20-b72f-92f3ca5dd79f',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'GROUPS',
            description: formatMessage(messages.groups),
            hashId: 'fake',
            childrenHashes: [
              '45567a4c-23c3-47c0-83be-a132e633ca39',
              'd52d8cc9-ee78-44f1-bb58-5c1b37521296',
              '1dff37d9-9eb2-46f6-9773-f483c3b7b496',
              'c4dad741-65e8-4e2e-a747-64d03696f049',
              '1ce02721-9d15-451a-9c5f-b482bc26f609'
            ],
            children: [
              {
                '@objectId': 53,
                name: 'groups.read',
                description: formatMessage(messages.view),
                hashId: '45567a4c-23c3-47c0-83be-a132e633ca39',
                children: []
              },
              {
                '@objectId': 54,
                name: 'groups.create',
                description: formatMessage(messages.create),
                hashId: 'd52d8cc9-ee78-44f1-bb58-5c1b37521296',
                children: []
              },
              {
                '@objectId': 55,
                name: 'groups.edit',
                description: formatMessage(messages.edit),
                hashId: '1dff37d9-9eb2-46f6-9773-f483c3b7b496',
                children: []
              },
              {
                '@objectId': 56,
                name: 'groups.delete',
                description: formatMessage(messages.delete),
                hashId: 'c4dad741-65e8-4e2e-a747-64d03696f049',
                children: []
              },
              {
                '@objectId': 57,
                name: 'resourceSharing.allow',
                description: formatMessage(messages.shareResources),
                hashId: '1ce02721-9d15-451a-9c5f-b482bc26f609',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'NOTIFICATIONS',
            description: formatMessage(messages.notifications),
            hashId: 'fake',
            childrenHashes: [
              '84deb5c1-2d72-4fee-a9fe-786314deedb3',
              'a2c63b3f-6c2d-4aaa-917c-a129fcd28417',
              'fa78c5db-9204-49db-9abd-82aab767b90e',
              '3071d0cd-387e-441c-823f-2afad808faf7'
            ],
            children: [
              {
                '@objectId': 58,
                name: 'rule.read',
                description: formatMessage(messages.view),
                hashId: '84deb5c1-2d72-4fee-a9fe-786314deedb3',
                children: []
              },
              {
                '@objectId': 59,
                name: 'rule.create',
                description: formatMessage(messages.create),
                hashId: 'a2c63b3f-6c2d-4aaa-917c-a129fcd28417',
                children: []
              },
              {
                '@objectId': 60,
                name: 'rule.edit',
                description: formatMessage(messages.edit),
                hashId: 'fa78c5db-9204-49db-9abd-82aab767b90e',
                children: []
              },
              {
                '@objectId': 61,
                name: 'rule.delete',
                description: formatMessage(messages.delete),
                hashId: '3071d0cd-387e-441c-823f-2afad808faf7',
                children: []
              }
            ]
          }
        ]
      }
    ]
    break

  case 'sandbox':
    csPrivileges = [
      {
        '@objectId': 1,
        name: 'map.read',
        description: 'Can view map',
        hashId: 'ca3ae8e6-ddc4-4a20-abcb-7d8ee35ea950'
      },
      {
        '@objectId': 2,
        name: 'device.read',
        description: 'Can view devices',
        hashId: '416147c7-b2ea-4c2c-b4d6-7c318994249a'
      },
      {
        '@objectId': 3,
        name: 'deviceDashboard.read',
        description: 'Can view device dashboards',
        hashId: '558d3f13-2538-453b-8a62-58c74aee5a2c'
      },
      {
        '@objectId': 4,
        name: 'deviceDashboard.edit',
        description: 'Can edit device dashboards',
        hashId: '7fee0b2a-a55b-42eb-9113-f51cd44670b9'
      },
      {
        '@objectId': 5,
        name: 'deviceDashboard.create',
        description: 'Can create device dashboards',
        hashId: '1aecccc7-ceaf-4639-b5ac-0a958f7c980c'
      },
      {
        '@objectId': 6,
        name: 'deviceDashboard.delete',
        description: 'Can edit device dashboards',
        hashId: '10ebf0cc-29dd-4253-905b-b311cfe29213'
      },
      {
        '@objectId': 7,
        name: 'nodeDetails.view',
        description: 'Can view device details',
        hashId: '4b8af85b-65ec-4ef6-9f11-1434c97de862'
      },
      {
        '@objectId': 8,
        name: 'deviceConfiguration.read',
        description: 'Can read device configuration',
        hashId: '19adc113-3f4f-46ca-9aa4-a0caa3e58474'
      },
      {
        '@objectId': 9,
        name: 'device.edit',
        description: 'Can edit devices',
        hashId: '0664954f-4372-4b05-8a2a-95e480193d42'
      },
      {
        '@objectId': 10,
        name: 'firmware.read',
        description: 'Can read device firmware',
        hashId: 'b23b271b-4edd-4e7f-9aec-208159f9dca9'
      },
      {
        '@objectId': 11,
        name: 'firmware.edit',
        description: 'Can edit device firmware',
        hashId: '4df97eb7-135b-48d7-bbc3-c8435ac6ea1b'
      },
      {
        '@objectId': 12,
        name: 'notification.read',
        description: 'Can view notifications',
        hashId: '709caa7d-cea9-400b-8206-70aa91ed48a3'
      },
      {
        '@objectId': 13,
        name: 'notification.edit',
        description: 'Can edit notification',
        hashId: 'efc1f741-3665-4b13-9bae-821959c7e9d0'
      },
      {
        '@objectId': 14,
        name: 'geoTracking.view',
        description: 'Can view Geotracking',
        hashId: '0872053d-ac41-44f7-b642-767cd8ad2177'
      },
      {
        '@objectId': 15,
        name: 'geoTracking.edit',
        description: 'Can edit Geotracking',
        hashId: 'a7cd933e-2c08-4bf7-a626-8e334c036788'
      },
      {
        '@objectId': 16,
        name: 'geoFence.read',
        description: 'Can read Geofences',
        hashId: '19a8ae40-0df9-4045-9197-606118aefed2'
      },
      {
        '@objectId': 17,
        name: 'geoFence.create',
        description: 'Can create Geofences',
        hashId: 'a59ec861-1cdb-46d5-b4e7-849fad14c40d'
      },
      {
        '@objectId': 18,
        name: 'geoFence.edit',
        description: 'Can edit Geofences',
        hashId: '8c005d08-a16f-43bc-b1e9-fc6ac2188e46'
      },
      {
        '@objectId': 19,
        name: 'geoFence.delete',
        description: 'Can delete Geofences',
        hashId: '2ab5f3ca-338b-4d8d-84e3-080f3f0e3d2e'
      },
      {
        '@objectId': 20,
        name: 'schedule.read',
        description: 'Can read schedules',
        hashId: '64488429-1dc4-4e84-9686-cb5279f732ae'
      },
      {
        '@objectId': 21,
        name: 'schedule.create',
        description: 'Can create schedules',
        hashId: '1cc38d8b-b576-4168-afcb-19f5d7d62dab'
      },
      {
        '@objectId': 22,
        name: 'schedule.edit',
        description: 'Can edit schedules',
        hashId: '5fdcfb3e-b9a0-45eb-9efc-7f385edc571a'
      },
      {
        '@objectId': 23,
        name: 'schedule.delete',
        description: 'Can delete reports',
        hashId: 'eefef0dc-bd5e-4a31-9202-c0b1972a6b50'
      },
      {
        '@objectId': 24,
        name: 'charts.read',
        description: 'Can read charts',
        hashId: '4ba87e6b-0e02-4560-a8a0-2aa011a47b0f'
      },
      {
        '@objectId': 25,
        name: 'charts.create',
        description: 'Can create charts',
        hashId: '658a2f98-aaad-4f84-95b8-a6f86eb79ec9'
      },
      {
        '@objectId': 26,
        name: 'charts.edit',
        description: 'Can edit reports',
        hashId: '17e07097-bad0-47ea-a799-b3d25719e3ae'
      },
      {
        '@objectId': 27,
        name: 'charts.delete',
        description: 'Can delete reports',
        hashId: '0c10177c-b408-4d31-b510-c7d3b26f0b27'
      },
      {
        '@objectId': 28,
        name: 'eventsHistory.view',
        description: 'Can view Events history',
        hashId: '9152fdf8-0ee0-415e-b1a9-de7716115b34'
      },
      {
        '@objectId': 29,
        name: 'eventsHistory.edit',
        description: 'Can edit Events history',
        hashId: 'bf3810cd-d09c-4893-b758-64c70be1969a'
      },
      {
        '@objectId': 30,
        name: 'deviceConfiguration.read',
        description: 'Can read device configurations',
        hashId: '19adc113-3f4f-46ca-9aa4-a0caa3e58474'
      },
      {
        '@objectId': 31,
        name: 'deviceConfiguration.create',
        description: 'Can create device configurations',
        hashId: '5d9b2373-f701-40fb-bd37-e54c6d594063'
      },
      {
        '@objectId': 32,
        name: 'deviceConfiguration.edit',
        description: 'Can edit device configurations',
        hashId: '4121e861-08a6-477b-adac-7f10c25089d7'
      },
      {
        '@objectId': 33,
        name: 'deviceConfiguration.delete',
        description: 'Can delete device configurations',
        hashId: '737181fb-ccac-45a3-befd-f1b1cc7d1263'
      },
      {
        '@objectId': 34,
        name: 'device.read',
        description: 'Can view device',
        hashId: '416147c7-b2ea-4c2c-b4d6-7c318994249a'
      },
      {
        '@objectId': 35,
        name: 'deviceSim.edit',
        description: 'Can activate production test mode',
        hashId: 'e0f802d8-5b8c-4c51-9f40-4782dd42ac43'
      },
      {
        '@objectId': 36,
        name: 'device.create',
        description: 'Can register devices',
        hashId: '79aa7a30-79fa-463e-bd58-18a7256fc243'
      },
      {
        '@objectId': 37,
        name: 'device.edit',
        description: 'Can edit devices',
        hashId: '0664954f-4372-4b05-8a2a-95e480193d42'
      },
      {
        '@objectId': 38,
        name: 'dataplan.read',
        description: 'Can view dataplans',
        hashId: 'ff67a361-d59a-461e-9111-28188e6df70a'
      },
      {
        '@objectId': 39,
        name: 'dataplan.apply',
        description: 'Can apply dataplans',
        hashId: '4851fd58-0c52-494a-a4b5-06e841ec3a67'
      },
      {
        '@objectId': 40,
        name: 'dataplan.share',
        description: 'Can share dataplans',
        hashId: '19d9267e-1a74-490b-8ab1-daa5b6ca951e'
      },
      {
        '@objectId': 41,
        name: 'accessPass.consume',
        description: 'Can consume Access Pass',
        hashId: '135740f9-d54a-4cea-90e5-d6a344fea612'
      },
      {
        '@objectId': 42,
        name: 'accessPass.share',
        description: 'Can share Access Pass',
        hashId: 'd9b5331c-e7ef-47fd-9251-ab85817edac3'
      },
      {
        '@objectId': 43,
        name: 'groupDashboard.read',
        description: 'Can read group dashboards',
        hashId: '2c090b1c-231d-4c1f-91f1-a6852884e88f'
      },
      {
        '@objectId': 44,
        name: 'groupDashboard.edit',
        description: 'Can edit group dashboards',
        hashId: 'c7e8b6ed-a07e-4d12-8e45-4519e2f74bf9'
      },
      {
        '@objectId': 45,
        name: 'groupDashboard.create',
        description: 'Can create group dashboards',
        hashId: 'f516f2d2-cfc7-4fd0-b1e0-5f3ad4bd762a'
      },
      {
        '@objectId': 46,
        name: 'groupDashboard.delete',
        description: 'Can delete group dashboards',
        hashId: '6e7ed92d-7ae1-459b-a42e-ff35b381065a'
      },
      {
        '@objectId': 47,
        name: 'user.read',
        description: 'Can view users',
        hashId: '079c376a-f527-44b8-bd36-0ada8f8860f3'
      },
      {
        '@objectId': 48,
        name: 'user.create',
        description: 'Can create users',
        hashId: '48b3dcf5-5a13-445c-842b-caa5dfb9afdb'
      },
      {
        '@objectId': 49,
        name: 'user.edit',
        description: 'Can edit users',
        hashId: 'd61c0964-aaef-4239-ab08-324628177c6a'
      },
      {
        '@objectId': 50,
        name: 'groupRole.read',
        description: 'Can read roles',
        hashId: '83fabd49-94e7-4bc4-8369-42c9e21469f5'
      },
      {
        '@objectId': 51,
        name: 'groupRole.edit',
        description: 'Can edit roles',
        hashId: 'd15b874a-a669-41d1-8814-8b83d4bfd53b'
      },
      {
        '@objectId': 52,
        name: 'groupRole.create',
        description: 'Can create roles',
        hashId: '6d893db4-d8ca-42fa-bd93-d9bb118eadae'
      },
      {
        '@objectId': 53,
        name: 'groupRole.delete',
        description: 'Can delete roles',
        hashId: '02529bba-4f8e-44d9-bdd6-a3c05f232b19'
      },
      {
        '@objectId': 54,
        name: 'groups.read',
        description: 'Can view groups',
        hashId: '10b4752d-8f98-4e92-8059-49b0ea8f8d8b'
      },
      {
        '@objectId': 55,
        name: 'groups.create',
        description: 'Can create groups',
        hashId: 'cbe4a76b-d533-4593-acad-7945fdd444e7'
      },
      {
        '@objectId': 56,
        name: 'groups.edit',
        description: 'Can edit groups',
        hashId: 'f78a1667-71e9-4094-a5d8-b35839c42961'
      },
      {
        '@objectId': 57,
        name: 'groups.delete',
        description: 'Can delete groups',
        hashId: 'b1cc7140-406f-4a78-ab1e-acb24535a94f'
      },
      {
        '@objectId': 58,
        name: 'resourceSharing.allow',
        description: 'Can share resources in group',
        hashId: 'c7bf49ca-d88f-4294-9890-5e4b823cc13b'
      },
      {
        '@objectId': 59,
        name: 'rule.read',
        description: 'Can read notification rules',
        hashId: '9b5fcd3f-2f58-4a26-a93a-290f088ca867'
      },
      {
        '@objectId': 60,
        name: 'rule.create',
        description: 'Can create notification rules',
        hashId: '5318445c-dd92-445d-81ba-2db72b0742b4'
      },
      {
        '@objectId': 61,
        name: 'rule.edit',
        description: 'Can edit notification rules',
        hashId: '914f3c83-6c9e-4b0a-bc84-eb61719009b2'
      },
      {
        '@objectId': 62,
        name: 'rule.delete',
        description: 'Can delete notification rules',
        hashId: '62d1b3c0-ac27-4caf-b8e0-77cd83244972'
      }
    ]

    csPrivilegesHierarchy = formatMessage => [
      {
        '@objectId': 1,
        name: 'map.read',
        description: formatMessage(messages.map),
        hashId: 'ca3ae8e6-ddc4-4a20-abcb-7d8ee35ea950',
        children: []
      },
      {
        '@objectId': 'fake',
        name: 'DEVICES',
        description: formatMessage(messages.nodes),
        hashId: 'fake',
        childrenHashes: [
          '416147c7-b2ea-4c2c-b4d6-7c318994249a',
          '558d3f13-2538-453b-8a62-58c74aee5a2c',
          '7fee0b2a-a55b-42eb-9113-f51cd44670b9',
          '1aecccc7-ceaf-4639-b5ac-0a958f7c980c',
          '10ebf0cc-29dd-4253-905b-b311cfe29213',
          '4b8af85b-65ec-4ef6-9f11-1434c97de862',
          '19adc113-3f4f-46ca-9aa4-a0caa3e58474',
          '0664954f-4372-4b05-8a2a-95e480193d42',
          'b23b271b-4edd-4e7f-9aec-208159f9dca9',
          '4df97eb7-135b-48d7-bbc3-c8435ac6ea1b'
        ],
        children: [
          {
            '@objectId': 2,
            name: 'device.read',
            description: formatMessage(messages.view),
            hashId: '416147c7-b2ea-4c2c-b4d6-7c318994249a',
            children: []
          },
          {
            '@objectId': 'fake',
            name: 'DASHBOARDS',
            description: formatMessage(messages.dashboards),
            hashId: 'fake',
            childrenHashes: [
              '558d3f13-2538-453b-8a62-58c74aee5a2c',
              '7fee0b2a-a55b-42eb-9113-f51cd44670b9',
              '1aecccc7-ceaf-4639-b5ac-0a958f7c980c',
              '10ebf0cc-29dd-4253-905b-b311cfe29213'
            ],
            children: [
              {
                '@objectId': 3,
                name: 'deviceDashboard.read',
                description: formatMessage(messages.view),
                hashId: '558d3f13-2538-453b-8a62-58c74aee5a2c',
                children: []
              },
              {
                '@objectId': 4,
                name: 'deviceDashboard.edit',
                description: formatMessage(messages.edit),
                hashId: '7fee0b2a-a55b-42eb-9113-f51cd44670b9',
                children: []
              },
              {
                '@objectId': 5,
                name: 'deviceDashboard.create',
                description: formatMessage(messages.create),
                hashId: '1aecccc7-ceaf-4639-b5ac-0a958f7c980c',
                children: []
              },
              {
                '@objectId': 6,
                name: 'deviceDashboard.delete',
                description: formatMessage(messages.delete),
                hashId: '10ebf0cc-29dd-4253-905b-b311cfe29213',
                children: []
              }
            ]
          },
          {
            '@objectId': 7,
            name: 'nodeDetails.view',
            description: formatMessage(messages.nodeDetail),
            hashId: '4b8af85b-65ec-4ef6-9f11-1434c97de862',
            children: []
          },
          {
            '@objectId': 8,
            name: 'deviceConfiguration.read',
            description: formatMessage(messages.readConfig),
            hashId: '19adc113-3f4f-46ca-9aa4-a0caa3e58474',
            children: []
          },
          {
            '@objectId': 9,
            name: 'device.edit',
            description: formatMessage(messages.applyConfig),
            hashId: '0664954f-4372-4b05-8a2a-95e480193d42',
            children: []
          },
          {
            '@objectId': 10,
            name: 'firmware.read',
            description: formatMessage(messages.readFirmware),
            hashId: 'b23b271b-4edd-4e7f-9aec-208159f9dca9',
            children: []
          },
          {
            '@objectId': 11,
            name: 'firmware.edit',
            description: formatMessage(messages.applyFirmware),
            hashId: '4df97eb7-135b-48d7-bbc3-c8435ac6ea1b',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'NOTIFICATIONS',
        description: formatMessage(messages.notifications),
        hashId: 'fake',
        childrenHashes: ['709caa7d-cea9-400b-8206-70aa91ed48a3', 'efc1f741-3665-4b13-9bae-821959c7e9d0'],
        children: [
          {
            '@objectId': 12,
            name: 'notification.read',
            description: formatMessage(messages.view),
            hashId: '709caa7d-cea9-400b-8206-70aa91ed48a3',
            children: []
          },
          {
            '@objectId': 13,
            name: 'notification.edit',
            description: formatMessage(messages.edit),
            hashId: 'efc1f741-3665-4b13-9bae-821959c7e9d0',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'GEOTRACKING',
        description: formatMessage(messages.geoTracking),
        hashId: 'fake',
        childrenHashes: [
          '0872053d-ac41-44f7-b642-767cd8ad2177',
          'a7cd933e-2c08-4bf7-a626-8e334c036788',
          '19a8ae40-0df9-4045-9197-606118aefed2',
          'a59ec861-1cdb-46d5-b4e7-849fad14c40d',
          '8c005d08-a16f-43bc-b1e9-fc6ac2188e46',
          '2ab5f3ca-338b-4d8d-84e3-080f3f0e3d2e'
        ],
        children: [
          {
            '@objectId': 'fake',
            name: 'GEOTRACKING',
            description: formatMessage(messages.geoTracking),
            hashId: 'fake',
            childrenHashes: ['0872053d-ac41-44f7-b642-767cd8ad2177', 'a7cd933e-2c08-4bf7-a626-8e334c036788'],
            children: [
              {
                '@objectId': 14,
                name: 'geoTracking.view',
                description: formatMessage(messages.view),
                hashId: '0872053d-ac41-44f7-b642-767cd8ad2177',
                children: []
              },
              {
                '@objectId': 15,
                name: 'geoTracking.edit',
                description: formatMessage(messages.edit),
                hashId: 'a7cd933e-2c08-4bf7-a626-8e334c036788',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'GEOFENCE',
            description: formatMessage(messages.geofence),
            hashId: 'fake',
            childrenHashes: [
              '19a8ae40-0df9-4045-9197-606118aefed2',
              'a59ec861-1cdb-46d5-b4e7-849fad14c40d',
              '8c005d08-a16f-43bc-b1e9-fc6ac2188e46',
              '2ab5f3ca-338b-4d8d-84e3-080f3f0e3d2e'
            ],
            children: [
              {
                '@objectId': 16,
                name: 'geoFence.read',
                description: formatMessage(messages.view),
                hashId: '19a8ae40-0df9-4045-9197-606118aefed2',
                children: []
              },
              {
                '@objectId': 17,
                name: 'geoFence.create',
                description: formatMessage(messages.create),
                hashId: 'a59ec861-1cdb-46d5-b4e7-849fad14c40d',
                children: []
              },
              {
                '@objectId': 18,
                name: 'geoFence.edit',
                description: formatMessage(messages.edit),
                hashId: '8c005d08-a16f-43bc-b1e9-fc6ac2188e46',
                children: []
              },
              {
                '@objectId': 19,
                name: 'geoFence.delete',
                description: formatMessage(messages.delete),
                hashId: '2ab5f3ca-338b-4d8d-84e3-080f3f0e3d2e',
                children: []
              }
            ]
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'REPORTS',
        description: formatMessage(messages.reports),
        hashId: 'fake',
        childrenHashes: [
          '64488429-1dc4-4e84-9686-cb5279f732ae',
          '1cc38d8b-b576-4168-afcb-19f5d7d62dab',
          '5fdcfb3e-b9a0-45eb-9efc-7f385edc571a',
          'eefef0dc-bd5e-4a31-9202-c0b1972a6b50'
        ],
        children: [
          {
            '@objectId': 20,
            name: 'schedule.read',
            description: formatMessage(messages.view),
            hashId: '64488429-1dc4-4e84-9686-cb5279f732ae',
            children: []
          },
          {
            '@objectId': 21,
            name: 'schedule.create',
            description: formatMessage(messages.create),
            hashId: '1cc38d8b-b576-4168-afcb-19f5d7d62dab',
            children: []
          },
          {
            '@objectId': 22,
            name: 'schedule.edit',
            description: formatMessage(messages.edit),
            hashId: '5fdcfb3e-b9a0-45eb-9efc-7f385edc571a',
            children: []
          },
          {
            '@objectId': 23,
            name: 'schedule.delete',
            description: formatMessage(messages.delete),
            hashId: 'eefef0dc-bd5e-4a31-9202-c0b1972a6b50',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'CHARTS',
        description: formatMessage(messages.charts),
        hashId: 'fake',
        childrenHashes: [
          '4ba87e6b-0e02-4560-a8a0-2aa011a47b0f',
          '658a2f98-aaad-4f84-95b8-a6f86eb79ec9',
          '17e07097-bad0-47ea-a799-b3d25719e3ae',
          '0c10177c-b408-4d31-b510-c7d3b26f0b27'
        ],
        children: [
          {
            '@objectId': 24,
            name: 'charts.read',
            description: formatMessage(messages.view),
            hashId: '4ba87e6b-0e02-4560-a8a0-2aa011a47b0f',
            children: []
          },
          {
            '@objectId': 25,
            name: 'charts.create',
            description: formatMessage(messages.create),
            hashId: '658a2f98-aaad-4f84-95b8-a6f86eb79ec9',
            children: []
          },
          {
            '@objectId': 26,
            name: 'charts.edit',
            description: formatMessage(messages.edit),
            hashId: '17e07097-bad0-47ea-a799-b3d25719e3ae',
            children: []
          },
          {
            '@objectId': 27,
            name: 'charts.delete',
            description: formatMessage(messages.delete),
            hashId: '0c10177c-b408-4d31-b510-c7d3b26f0b27',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'EVENTS HISTORY',
        description: formatMessage(messages.actionsState),
        hashId: 'fake',
        childrenHashes: ['9152fdf8-0ee0-415e-b1a9-de7716115b34', 'bf3810cd-d09c-4893-b758-64c70be1969a'],
        children: [
          {
            '@objectId': 28,
            name: 'eventsHistory.view',
            description: formatMessage(messages.view),
            hashId: '9152fdf8-0ee0-415e-b1a9-de7716115b34',
            children: []
          },
          {
            '@objectId': 29,
            name: 'eventsHistory.edit',
            description: formatMessage(messages.delete),
            hashId: 'bf3810cd-d09c-4893-b758-64c70be1969a',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'CONFIGURATIONS',
        description: formatMessage(messages.configurations),
        hashId: 'fake',
        childrenHashes: [
          '19adc113-3f4f-46ca-9aa4-a0caa3e58474',
          '5d9b2373-f701-40fb-bd37-e54c6d594063',
          '4121e861-08a6-477b-adac-7f10c25089d7',
          '737181fb-ccac-45a3-befd-f1b1cc7d1263'
        ],
        children: [
          {
            '@objectId': 30,
            name: 'deviceConfiguration.read',
            description: formatMessage(messages.view),
            hashId: '19adc113-3f4f-46ca-9aa4-a0caa3e58474',
            children: []
          },
          {
            '@objectId': 31,
            name: 'deviceConfiguration.create',
            description: formatMessage(messages.create),
            hashId: '5d9b2373-f701-40fb-bd37-e54c6d594063',
            children: []
          },
          {
            '@objectId': 32,
            name: 'deviceConfiguration.edit',
            description: formatMessage(messages.edit),
            hashId: '4121e861-08a6-477b-adac-7f10c25089d7',
            children: []
          },
          {
            '@objectId': 33,
            name: 'deviceConfiguration.delete',
            description: formatMessage(messages.delete),
            hashId: '737181fb-ccac-45a3-befd-f1b1cc7d1263',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'DEVICE MANAGEMENT',
        description: formatMessage(messages.deviceManagement),
        hashId: 'fake',
        childrenHashes: [
          '416147c7-b2ea-4c2c-b4d6-7c318994249a',
          'e0f802d8-5b8c-4c51-9f40-4782dd42ac43',
          '79aa7a30-79fa-463e-bd58-18a7256fc243',
          //'0664954f-4372-4b05-8a2a-95e480193d42',
          'ff67a361-d59a-461e-9111-28188e6df70a',
          '4851fd58-0c52-494a-a4b5-06e841ec3a67',
          '19d9267e-1a74-490b-8ab1-daa5b6ca951e',
          '135740f9-d54a-4cea-90e5-d6a344fea612',
          'd9b5331c-e7ef-47fd-9251-ab85817edac3'
        ],
        children: [
          {
            '@objectId': 34,
            name: 'device.read',
            description: formatMessage(messages.view),
            hashId: '416147c7-b2ea-4c2c-b4d6-7c318994249a',
            children: []
          },
          {
            '@objectId': 35,
            name: 'deviceSim.edit',
            description: formatMessage(messages.productionTestMode),
            hashId: 'e0f802d8-5b8c-4c51-9f40-4782dd42ac43',
            children: []
          },
          {
            '@objectId': 36,
            name: 'device.create',
            description: formatMessage(messages.create),
            hashId: '79aa7a30-79fa-463e-bd58-18a7256fc243',
            children: []
          },
          /*{
              '@objectId': 36,
              name: 'device.edit',
              description: formatMessage(messages.edit),
              hashId: '0664954f-4372-4b05-8a2a-95e480193d42',
              children: []
            },*/
          {
            '@objectId': 'fake',
            name: 'SERVICE PLANS AND SMS PACKS',
            description: formatMessage(messages.servicePlansAndSMSPacks),
            hashId: 'fake',
            childrenHashes: [
              'ff67a361-d59a-461e-9111-28188e6df70a',
              '4851fd58-0c52-494a-a4b5-06e841ec3a67',
              '19d9267e-1a74-490b-8ab1-daa5b6ca951e'
            ],
            children: [
              {
                '@objectId': 37,
                name: 'dataplan.read',
                description: formatMessage(messages.view),
                hashId: 'ff67a361-d59a-461e-9111-28188e6df70a',
                children: []
              },
              {
                '@objectId': 38,
                name: 'dataplan.apply',
                description: formatMessage(messages.apply),
                hashId: '4851fd58-0c52-494a-a4b5-06e841ec3a67',
                children: []
              },
              {
                '@objectId': 39,
                name: 'dataplan.share',
                description: formatMessage(messages.share),
                hashId: '19d9267e-1a74-490b-8ab1-daa5b6ca951e',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'ACCESS PASSES',
            description: formatMessage(messages.accessPasses),
            hashId: 'fake',
            childrenHashes: ['135740f9-d54a-4cea-90e5-d6a344fea612', 'd9b5331c-e7ef-47fd-9251-ab85817edac3'],
            children: [
              {
                '@objectId': 40,
                name: 'accessPass.consume',
                description: formatMessage(messages.consume),
                hashId: '135740f9-d54a-4cea-90e5-d6a344fea612'
              },
              {
                '@objectId': 41,
                name: 'accessPass.share',
                description: formatMessage(messages.share),
                hashId: 'd9b5331c-e7ef-47fd-9251-ab85817edac3'
              }
            ]
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'GROUP DASHBOARDS',
        description: formatMessage(messages.groupDashboards),
        hashId: 'fake',
        childrenHashes: [
          '2c090b1c-231d-4c1f-91f1-a6852884e88f',
          'c7e8b6ed-a07e-4d12-8e45-4519e2f74bf9',
          'f516f2d2-cfc7-4fd0-b1e0-5f3ad4bd762a',
          '6e7ed92d-7ae1-459b-a42e-ff35b381065a'
        ],
        children: [
          {
            '@objectId': 42,
            name: 'groupDashboard.read',
            description: formatMessage(messages.view),
            hashId: '2c090b1c-231d-4c1f-91f1-a6852884e88f',
            children: []
          },
          {
            '@objectId': 43,
            name: 'groupDashboard.edit',
            description: formatMessage(messages.edit),
            hashId: 'c7e8b6ed-a07e-4d12-8e45-4519e2f74bf9',
            children: []
          },
          {
            '@objectId': 44,
            name: 'groupDashboard.create',
            description: formatMessage(messages.create),
            hashId: 'f516f2d2-cfc7-4fd0-b1e0-5f3ad4bd762a',
            children: []
          },
          {
            '@objectId': 45,
            name: 'groupDashboard.delete',
            description: formatMessage(messages.delete),
            hashId: '6e7ed92d-7ae1-459b-a42e-ff35b381065a',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'SETTINGS',
        description: formatMessage(messages.settings),
        hashId: 'fake',
        childrenHashes: [
          '079c376a-f527-44b8-bd36-0ada8f8860f3',
          '48b3dcf5-5a13-445c-842b-caa5dfb9afdb',
          'd61c0964-aaef-4239-ab08-324628177c6a',
          '83fabd49-94e7-4bc4-8369-42c9e21469f5',
          'd15b874a-a669-41d1-8814-8b83d4bfd53b',
          '6d893db4-d8ca-42fa-bd93-d9bb118eadae',
          '02529bba-4f8e-44d9-bdd6-a3c05f232b19',
          '10b4752d-8f98-4e92-8059-49b0ea8f8d8b',
          'cbe4a76b-d533-4593-acad-7945fdd444e7',
          'f78a1667-71e9-4094-a5d8-b35839c42961',
          'b1cc7140-406f-4a78-ab1e-acb24535a94f',
          'c7bf49ca-d88f-4294-9890-5e4b823cc13b',
          '9b5fcd3f-2f58-4a26-a93a-290f088ca867',
          '5318445c-dd92-445d-81ba-2db72b0742b4',
          '914f3c83-6c9e-4b0a-bc84-eb61719009b2',
          '62d1b3c0-ac27-4caf-b8e0-77cd83244972'
        ],
        children: [
          {
            '@objectId': 'fake',
            name: 'USERS',
            description: formatMessage(messages.users),
            hashId: 'fake',
            childrenHashes: [
              '079c376a-f527-44b8-bd36-0ada8f8860f3',
              '48b3dcf5-5a13-445c-842b-caa5dfb9afdb',
              'd61c0964-aaef-4239-ab08-324628177c6a'
            ],
            children: [
              {
                '@objectId': 46,
                name: 'user.read',
                description: formatMessage(messages.view),
                hashId: '079c376a-f527-44b8-bd36-0ada8f8860f3',
                children: []
              },
              {
                '@objectId': 47,
                name: 'user.create',
                description: formatMessage(messages.create),
                hashId: '48b3dcf5-5a13-445c-842b-caa5dfb9afdb'
              },
              {
                '@objectId': 48,
                name: 'user.edit',
                description: formatMessage(messages.edit),
                hashId: 'd61c0964-aaef-4239-ab08-324628177c6a',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'ROLES',
            description: formatMessage(messages.roles),
            hashId: 'fake',
            childrenHashes: [
              '83fabd49-94e7-4bc4-8369-42c9e21469f5',
              'd15b874a-a669-41d1-8814-8b83d4bfd53b',
              '6d893db4-d8ca-42fa-bd93-d9bb118eadae',
              '02529bba-4f8e-44d9-bdd6-a3c05f232b19'
            ],
            children: [
              {
                '@objectId': 49,
                name: 'groupRole.read',
                description: formatMessage(messages.view),
                hashId: '83fabd49-94e7-4bc4-8369-42c9e21469f5',
                children: []
              },
              {
                '@objectId': 50,
                name: 'groupRole.edit',
                description: formatMessage(messages.edit),
                hashId: 'd15b874a-a669-41d1-8814-8b83d4bfd53b',
                children: []
              },
              {
                '@objectId': 51,
                name: 'groupRole.create',
                description: formatMessage(messages.create),
                hashId: '6d893db4-d8ca-42fa-bd93-d9bb118eadae',
                children: []
              },
              {
                '@objectId': 52,
                name: 'groupRole.delete',
                description: formatMessage(messages.delete),
                hashId: '02529bba-4f8e-44d9-bdd6-a3c05f232b19',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'GROUPS',
            description: formatMessage(messages.groups),
            hashId: 'fake',
            childrenHashes: [
              '10b4752d-8f98-4e92-8059-49b0ea8f8d8b',
              'cbe4a76b-d533-4593-acad-7945fdd444e7',
              'f78a1667-71e9-4094-a5d8-b35839c42961',
              'b1cc7140-406f-4a78-ab1e-acb24535a94f',
              'c7bf49ca-d88f-4294-9890-5e4b823cc13b'
            ],
            children: [
              {
                '@objectId': 53,
                name: 'groups.read',
                description: formatMessage(messages.view),
                hashId: '10b4752d-8f98-4e92-8059-49b0ea8f8d8b',
                children: []
              },
              {
                '@objectId': 54,
                name: 'groups.create',
                description: formatMessage(messages.create),
                hashId: 'cbe4a76b-d533-4593-acad-7945fdd444e7',
                children: []
              },
              {
                '@objectId': 55,
                name: 'groups.edit',
                description: formatMessage(messages.edit),
                hashId: 'f78a1667-71e9-4094-a5d8-b35839c42961',
                children: []
              },
              {
                '@objectId': 56,
                name: 'groups.delete',
                description: formatMessage(messages.delete),
                hashId: 'b1cc7140-406f-4a78-ab1e-acb24535a94f',
                children: []
              },
              {
                '@objectId': 57,
                name: 'resourceSharing.allow',
                description: formatMessage(messages.shareResources),
                hashId: 'c7bf49ca-d88f-4294-9890-5e4b823cc13b',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'NOTIFICATIONS',
            description: formatMessage(messages.notifications),
            hashId: 'fake',
            childrenHashes: [
              '9b5fcd3f-2f58-4a26-a93a-290f088ca867',
              '5318445c-dd92-445d-81ba-2db72b0742b4',
              '914f3c83-6c9e-4b0a-bc84-eb61719009b2',
              '62d1b3c0-ac27-4caf-b8e0-77cd83244972'
            ],
            children: [
              {
                '@objectId': 58,
                name: 'rule.read',
                description: formatMessage(messages.view),
                hashId: '9b5fcd3f-2f58-4a26-a93a-290f088ca867',
                children: []
              },
              {
                '@objectId': 59,
                name: 'rule.create',
                description: formatMessage(messages.create),
                hashId: '5318445c-dd92-445d-81ba-2db72b0742b4',
                children: []
              },
              {
                '@objectId': 60,
                name: 'rule.edit',
                description: formatMessage(messages.edit),
                hashId: '914f3c83-6c9e-4b0a-bc84-eb61719009b2',
                children: []
              },
              {
                '@objectId': 61,
                name: 'rule.delete',
                description: formatMessage(messages.delete),
                hashId: '62d1b3c0-ac27-4caf-b8e0-77cd83244972',
                children: []
              }
            ]
          }
        ]
      }
    ]
    break

  case 'production':
    csPrivileges = [
      {
        '@objectId': 1,
        name: 'map.read',
        description: 'Can view map',
        hashId: '63851cf6-eacc-4449-8cc1-920678662d94'
      },
      {
        '@objectId': 2,
        name: 'device.read',
        description: 'Can view devices',
        hashId: 'ed952325-b669-4d27-be41-3e28841c6003'
      },
      {
        '@objectId': 3,
        name: 'deviceDashboard.read',
        description: 'Can view device dashboards',
        hashId: 'bb3c81f1-271d-42ec-bcf5-67ac9988a87a'
      },
      {
        '@objectId': 4,
        name: 'deviceDashboard.edit',
        description: 'Can edit device dashboards',
        hashId: '72877b9e-e583-43c6-8b46-dae28ba3e43b'
      },
      {
        '@objectId': 5,
        name: 'deviceDashboard.create',
        description: 'Can create device dashboards',
        hashId: 'ca6a2a8f-f4e8-4a47-9655-7f9bb7217d3d'
      },
      {
        '@objectId': 6,
        name: 'deviceDashboard.delete',
        description: 'Can edit device dashboards',
        hashId: '4aed7bfd-1c9f-4f5b-9fd7-79797eb6948d'
      },
      {
        '@objectId': 7,
        name: 'nodeDetails.view',
        description: 'Can view device details',
        hashId: 'd822ddc2-4694-4de6-8d1b-17cffcabf737'
      },
      {
        '@objectId': 8,
        name: 'deviceConfiguration.read',
        description: 'Can read device configuration',
        hashId: '0df3c5cb-a91f-4025-964e-739542ab9b85'
      },
      {
        '@objectId': 9,
        name: 'device.edit',
        description: 'Can edit devices',
        hashId: '227d6ced-7b3b-40e7-a85d-712f715e4e1a'
      },
      {
        '@objectId': 10,
        name: 'firmware.read',
        description: 'Can read device firmware',
        hashId: '5c8f483a-4423-407d-9592-db918db343db'
      },
      {
        '@objectId': 11,
        name: 'firmware.edit',
        description: 'Can edit device firmware',
        hashId: '76a96030-5864-45aa-9312-9331160825ea'
      },
      {
        '@objectId': 12,
        name: 'notification.read',
        description: 'Can view notifications',
        hashId: '40e822de-6513-4acc-acf6-a665f6ed7f40'
      },
      {
        '@objectId': 13,
        name: 'notification.edit',
        description: 'Can edit notification',
        hashId: 'ea105879-2278-4ae5-aa72-02ee0c6f06cb'
      },
      {
        '@objectId': 14,
        name: 'geoTracking.view',
        description: 'Can view Geotracking',
        hashId: 'a62e0cfc-bc4f-4427-b9a5-7b8ae4a25afe'
      },
      {
        '@objectId': 15,
        name: 'geoTracking.edit',
        description: 'Can edit Geotracking',
        hashId: '88c6c844-f489-4ea3-b3e8-7e5dca396d4e'
      },
      {
        '@objectId': 16,
        name: 'geoFence.read',
        description: 'Can read Geofences',
        hashId: '49e00ae3-c5ed-43e4-a47c-a0f1ec508b3d'
      },
      {
        '@objectId': 17,
        name: 'geoFence.create',
        description: 'Can create Geofences',
        hashId: '78861401-a44a-49d8-88bd-3a540dcc8160'
      },
      {
        '@objectId': 18,
        name: 'geoFence.edit',
        description: 'Can edit Geofences',
        hashId: '15eae247-6938-481a-a789-a4d032c0437c'
      },
      {
        '@objectId': 19,
        name: 'geoFence.delete',
        description: 'Can delete Geofences',
        hashId: 'dc29fe3c-36ed-49b2-8eea-97fde4d75776'
      },
      {
        '@objectId': 20,
        name: 'schedule.read',
        description: 'Can read schedules',
        hashId: '34d1eeb8-30ab-4327-96bc-d96599cd159f'
      },
      {
        '@objectId': 21,
        name: 'schedule.create',
        description: 'Can create schedules',
        hashId: '3b9b629f-25ce-482f-907f-25b6a687d232'
      },
      {
        '@objectId': 22,
        name: 'schedule.edit',
        description: 'Can edit schedules',
        hashId: '1faf10ed-e00c-4c07-92b8-6a4dbea45bc8'
      },
      {
        '@objectId': 23,
        name: 'schedule.delete',
        description: 'Can delete reports',
        hashId: 'a09bb249-e2ea-4eb9-b610-e268c9797a7c'
      },
      {
        '@objectId': 24,
        name: 'charts.read',
        description: 'Can read charts',
        hashId: '1278d782-c7bf-4481-9efe-ab82b6a173b9'
      },
      {
        '@objectId': 25,
        name: 'charts.create',
        description: 'Can create charts',
        hashId: '7afaf52b-bae5-4708-9384-d13f4337c9d3'
      },
      {
        '@objectId': 26,
        name: 'charts.edit',
        description: 'Can edit reports',
        hashId: '5de7243c-9ba8-4a27-898a-2c3df01d4708'
      },
      {
        '@objectId': 27,
        name: 'charts.delete',
        description: 'Can delete reports',
        hashId: 'c6a42a43-198c-44f0-b357-a928a8f02e23'
      },
      {
        '@objectId': 28,
        name: 'eventsHistory.view',
        description: 'Can view Events history',
        hashId: '9345d281-2b4f-4569-a03a-97b2d7fcb94a'
      },
      {
        '@objectId': 29,
        name: 'eventsHistory.edit',
        description: 'Can edit Events history',
        hashId: '07dabfad-d4ef-4448-8ce5-7ce4610a927a'
      },
      {
        '@objectId': 30,
        name: 'deviceConfiguration.read',
        description: 'Can read device configurations',
        hashId: '0df3c5cb-a91f-4025-964e-739542ab9b85'
      },
      {
        '@objectId': 31,
        name: 'deviceConfiguration.create',
        description: 'Can create device configurations',
        hashId: 'f7cfd0e6-89cd-486e-94f3-0f1e55b46bce'
      },
      {
        '@objectId': 32,
        name: 'deviceConfiguration.edit',
        description: 'Can edit device configurations',
        hashId: '2cef7812-7283-4a5f-9808-7c80f44d59f7'
      },
      {
        '@objectId': 33,
        name: 'deviceConfiguration.delete',
        description: 'Can delete device configurations',
        hashId: 'a49e6cc5-be56-4f35-9a34-748c1f4d2899'
      },
      {
        '@objectId': 34,
        name: 'device.read',
        description: 'Can view device',
        hashId: 'ed952325-b669-4d27-be41-3e28841c6003'
      },
      {
        '@objectId': 35,
        name: 'deviceSim.edit',
        description: 'Can activate production test mode',
        hashId: '763fb013-b370-4d4e-863a-e7ffe82e6e0d'
      },
      {
        '@objectId': 36,
        name: 'device.create',
        description: 'Can register devices',
        hashId: 'a1c10596-1d01-43db-ad87-4eb045e3dff0'
      },
      {
        '@objectId': 37,
        name: 'device.edit',
        description: 'Can edit devices',
        hashId: '227d6ced-7b3b-40e7-a85d-712f715e4e1a'
      },
      {
        '@objectId': 38,
        name: 'dataplan.read',
        description: 'Can view dataplans',
        hashId: '8211b9ce-1837-4615-8ccb-a6ee2267153a'
      },
      {
        '@objectId': 39,
        name: 'dataplan.apply',
        description: 'Can apply dataplans',
        hashId: '3571e1da-a2d5-4753-816c-adcf53382c0c'
      },
      {
        '@objectId': 40,
        name: 'dataplan.share',
        description: 'Can share dataplans',
        hashId: '76dd3569-bfa8-426b-940f-745e25b80d54'
      },
      {
        '@objectId': 41,
        name: 'accessPass.consume',
        description: 'Can consume Access Pass',
        hashId: '783a17a7-071a-49c1-8c91-2dcd8de84abf'
      },
      {
        '@objectId': 42,
        name: 'accessPass.share',
        description: 'Can share Access Pass',
        hashId: '969ec7ca-ebbd-4a18-a756-d567347c91dd'
      },
      {
        '@objectId': 43,
        name: 'groupDashboard.read',
        description: 'Can read group dashboards',
        hashId: 'f724ec5f-84e4-48aa-99f2-aa378987cc27'
      },
      {
        '@objectId': 44,
        name: 'groupDashboard.edit',
        description: 'Can edit group dashboards',
        hashId: 'b015d02c-9081-425f-9a1c-795e518011b6'
      },
      {
        '@objectId': 45,
        name: 'groupDashboard.create',
        description: 'Can create group dashboards',
        hashId: 'b5474232-8c29-4ea1-9eb8-29abd94e5658'
      },
      {
        '@objectId': 46,
        name: 'groupDashboard.delete',
        description: 'Can delete group dashboards',
        hashId: '45ed626e-2b11-46b6-af74-03e07e979909'
      },
      {
        '@objectId': 47,
        name: 'user.read',
        description: 'Can view users',
        hashId: '9aef0eb1-7d12-4914-a7e0-3e11519ae781'
      },
      {
        '@objectId': 48,
        name: 'user.create',
        description: 'Can create users',
        hashId: '6b8e6e1f-7c27-4786-bdd5-72b169287be9'
      },
      {
        '@objectId': 49,
        name: 'user.edit',
        description: 'Can edit users',
        hashId: '364c3f45-315f-42fc-aa60-e228132a7594'
      },
      {
        '@objectId': 50,
        name: 'groupRole.read',
        description: 'Can read roles',
        hashId: '36100da8-a7b9-47c0-812c-4a0f8bf6d82d'
      },
      {
        '@objectId': 51,
        name: 'groupRole.edit',
        description: 'Can edit roles',
        hashId: 'bff0873d-90d0-492d-9551-0a0cbfe85a0d'
      },
      {
        '@objectId': 52,
        name: 'groupRole.create',
        description: 'Can create roles',
        hashId: 'c641dd03-40e2-44be-b246-6c6898fbbab1'
      },
      {
        '@objectId': 53,
        name: 'groupRole.delete',
        description: 'Can delete roles',
        hashId: '31bc0bd9-72e4-4273-a814-26d99e9ec593'
      },
      {
        '@objectId': 54,
        name: 'groups.read',
        description: 'Can view groups',
        hashId: '7de58a0a-e4ca-4b38-a80d-6a48e36f82d4'
      },
      {
        '@objectId': 55,
        name: 'groups.create',
        description: 'Can create groups',
        hashId: '72b0a554-7e9c-4c73-8421-54d0ca3abe3d'
      },
      {
        '@objectId': 56,
        name: 'groups.edit',
        description: 'Can edit groups',
        hashId: '35e52b50-2cdd-4f36-a6dd-8b9ff4fd8b9c'
      },
      {
        '@objectId': 57,
        name: 'groups.delete',
        description: 'Can delete groups',
        hashId: 'abefc800-3717-4263-a514-cd058ff48048'
      },
      {
        '@objectId': 58,
        name: 'resourceSharing.allow',
        description: 'Can share resources in group',
        hashId: '9f79f833-7acb-47b4-9bff-03538a6a174d'
      },
      {
        '@objectId': 59,
        name: 'rule.read',
        description: 'Can read notification rules',
        hashId: 'ef64aecd-8e81-4e6b-a467-7f5ba5967e29'
      },
      {
        '@objectId': 60,
        name: 'rule.create',
        description: 'Can create notification rules',
        hashId: '25944435-196d-4d7b-84cb-d2b34f59915f'
      },
      {
        '@objectId': 61,
        name: 'rule.edit',
        description: 'Can edit notification rules',
        hashId: '28d708f3-a545-4293-823b-05e2ffa90439'
      },
      {
        '@objectId': 62,
        name: 'rule.delete',
        description: 'Can delete notification rules',
        hashId: '571b08dd-05cd-497f-857d-a49d098717d4'
      }
    ]

    csPrivilegesHierarchy = formatMessage => [
      {
        '@objectId': 1,
        name: 'map.read',
        description: formatMessage(messages.map),
        hashId: '63851cf6-eacc-4449-8cc1-920678662d94',
        children: []
      },
      {
        '@objectId': 'fake',
        name: 'DEVICES',
        description: formatMessage(messages.nodes),
        hashId: 'fake',
        childrenHashes: [
          'ed952325-b669-4d27-be41-3e28841c6003',
          'bb3c81f1-271d-42ec-bcf5-67ac9988a87a',
          '72877b9e-e583-43c6-8b46-dae28ba3e43b',
          'ca6a2a8f-f4e8-4a47-9655-7f9bb7217d3d',
          '4aed7bfd-1c9f-4f5b-9fd7-79797eb6948d',
          'd822ddc2-4694-4de6-8d1b-17cffcabf737',
          '0df3c5cb-a91f-4025-964e-739542ab9b85',
          '227d6ced-7b3b-40e7-a85d-712f715e4e1a',
          '5c8f483a-4423-407d-9592-db918db343db',
          '76a96030-5864-45aa-9312-9331160825ea'
        ],
        children: [
          {
            '@objectId': 2,
            name: 'device.read',
            description: formatMessage(messages.view),
            hashId: 'ed952325-b669-4d27-be41-3e28841c6003',
            children: []
          },
          {
            '@objectId': 'fake',
            name: 'DASHBOARDS',
            description: formatMessage(messages.dashboards),
            hashId: 'fake',
            childrenHashes: [
              'bb3c81f1-271d-42ec-bcf5-67ac9988a87a',
              '72877b9e-e583-43c6-8b46-dae28ba3e43b',
              'ca6a2a8f-f4e8-4a47-9655-7f9bb7217d3d',
              '4aed7bfd-1c9f-4f5b-9fd7-79797eb6948d'
            ],
            children: [
              {
                '@objectId': 3,
                name: 'deviceDashboard.read',
                description: formatMessage(messages.view),
                hashId: 'bb3c81f1-271d-42ec-bcf5-67ac9988a87a',
                children: []
              },
              {
                '@objectId': 4,
                name: 'deviceDashboard.edit',
                description: formatMessage(messages.edit),
                hashId: '72877b9e-e583-43c6-8b46-dae28ba3e43b',
                children: []
              },
              {
                '@objectId': 5,
                name: 'deviceDashboard.create',
                description: formatMessage(messages.create),
                hashId: 'ca6a2a8f-f4e8-4a47-9655-7f9bb7217d3d',
                children: []
              },
              {
                '@objectId': 6,
                name: 'deviceDashboard.delete',
                description: formatMessage(messages.delete),
                hashId: '4aed7bfd-1c9f-4f5b-9fd7-79797eb6948d',
                children: []
              }
            ]
          },
          {
            '@objectId': 7,
            name: 'nodeDetails.view',
            description: formatMessage(messages.nodeDetail),
            hashId: 'd822ddc2-4694-4de6-8d1b-17cffcabf737',
            children: []
          },
          {
            '@objectId': 8,
            name: 'deviceConfiguration.read',
            description: formatMessage(messages.readConfig),
            hashId: '0df3c5cb-a91f-4025-964e-739542ab9b85',
            children: []
          },
          {
            '@objectId': 9,
            name: 'device.edit',
            description: formatMessage(messages.applyConfig),
            hashId: '227d6ced-7b3b-40e7-a85d-712f715e4e1a',
            children: []
          },
          {
            '@objectId': 10,
            name: 'firmware.read',
            description: formatMessage(messages.readFirmware),
            hashId: '5c8f483a-4423-407d-9592-db918db343db',
            children: []
          },
          {
            '@objectId': 11,
            name: 'firmware.edit',
            description: formatMessage(messages.applyFirmware),
            hashId: '76a96030-5864-45aa-9312-9331160825ea',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'NOTIFICATIONS',
        description: formatMessage(messages.notifications),
        hashId: 'fake',
        childrenHashes: ['40e822de-6513-4acc-acf6-a665f6ed7f40', 'ea105879-2278-4ae5-aa72-02ee0c6f06cb'],
        children: [
          {
            '@objectId': 12,
            name: 'notification.read',
            description: formatMessage(messages.view),
            hashId: '40e822de-6513-4acc-acf6-a665f6ed7f40',
            children: []
          },
          {
            '@objectId': 13,
            name: 'notification.edit',
            description: formatMessage(messages.edit),
            hashId: 'ea105879-2278-4ae5-aa72-02ee0c6f06cb',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'GEOTRACKING',
        description: formatMessage(messages.geoTracking),
        hashId: 'fake',
        childrenHashes: [
          'a62e0cfc-bc4f-4427-b9a5-7b8ae4a25afe',
          '88c6c844-f489-4ea3-b3e8-7e5dca396d4e',
          '49e00ae3-c5ed-43e4-a47c-a0f1ec508b3d',
          '78861401-a44a-49d8-88bd-3a540dcc8160',
          '15eae247-6938-481a-a789-a4d032c0437c',
          'dc29fe3c-36ed-49b2-8eea-97fde4d75776'
        ],
        children: [
          {
            '@objectId': 'fake',
            name: 'GEOTRACKING',
            description: formatMessage(messages.geoTracking),
            hashId: 'fake',
            childrenHashes: ['a62e0cfc-bc4f-4427-b9a5-7b8ae4a25afe', '88c6c844-f489-4ea3-b3e8-7e5dca396d4e'],
            children: [
              {
                '@objectId': 14,
                name: 'geoTracking.view',
                description: formatMessage(messages.view),
                hashId: 'a62e0cfc-bc4f-4427-b9a5-7b8ae4a25afe',
                children: []
              },
              {
                '@objectId': 15,
                name: 'geoTracking.edit',
                description: formatMessage(messages.edit),
                hashId: '88c6c844-f489-4ea3-b3e8-7e5dca396d4e',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'GEOFENCE',
            description: formatMessage(messages.geofence),
            hashId: 'fake',
            childrenHashes: [
              '49e00ae3-c5ed-43e4-a47c-a0f1ec508b3d',
              '78861401-a44a-49d8-88bd-3a540dcc8160',
              '15eae247-6938-481a-a789-a4d032c0437c',
              'dc29fe3c-36ed-49b2-8eea-97fde4d75776'
            ],
            children: [
              {
                '@objectId': 16,
                name: 'geoFence.read',
                description: formatMessage(messages.view),
                hashId: '49e00ae3-c5ed-43e4-a47c-a0f1ec508b3d',
                children: []
              },
              {
                '@objectId': 17,
                name: 'geoFence.create',
                description: formatMessage(messages.create),
                hashId: '78861401-a44a-49d8-88bd-3a540dcc8160',
                children: []
              },
              {
                '@objectId': 18,
                name: 'geoFence.edit',
                description: formatMessage(messages.edit),
                hashId: '15eae247-6938-481a-a789-a4d032c0437c',
                children: []
              },
              {
                '@objectId': 19,
                name: 'geoFence.delete',
                description: formatMessage(messages.delete),
                hashId: 'dc29fe3c-36ed-49b2-8eea-97fde4d75776',
                children: []
              }
            ]
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'REPORTS',
        description: formatMessage(messages.reports),
        hashId: 'fake',
        childrenHashes: [
          '34d1eeb8-30ab-4327-96bc-d96599cd159f',
          '3b9b629f-25ce-482f-907f-25b6a687d232',
          '1faf10ed-e00c-4c07-92b8-6a4dbea45bc8',
          'a09bb249-e2ea-4eb9-b610-e268c9797a7c'
        ],
        children: [
          {
            '@objectId': 20,
            name: 'schedule.read',
            description: formatMessage(messages.view),
            hashId: '34d1eeb8-30ab-4327-96bc-d96599cd159f',
            children: []
          },
          {
            '@objectId': 21,
            name: 'schedule.create',
            description: formatMessage(messages.create),
            hashId: '3b9b629f-25ce-482f-907f-25b6a687d232',
            children: []
          },
          {
            '@objectId': 22,
            name: 'schedule.edit',
            description: formatMessage(messages.edit),
            hashId: '1faf10ed-e00c-4c07-92b8-6a4dbea45bc8',
            children: []
          },
          {
            '@objectId': 23,
            name: 'schedule.delete',
            description: formatMessage(messages.delete),
            hashId: 'a09bb249-e2ea-4eb9-b610-e268c9797a7c',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'CHARTS',
        description: formatMessage(messages.charts),
        hashId: 'fake',
        childrenHashes: [
          '1278d782-c7bf-4481-9efe-ab82b6a173b9',
          '7afaf52b-bae5-4708-9384-d13f4337c9d3',
          '5de7243c-9ba8-4a27-898a-2c3df01d4708',
          'c6a42a43-198c-44f0-b357-a928a8f02e23'
        ],
        children: [
          {
            '@objectId': 24,
            name: 'charts.read',
            description: formatMessage(messages.view),
            hashId: '1278d782-c7bf-4481-9efe-ab82b6a173b9',
            children: []
          },
          {
            '@objectId': 25,
            name: 'charts.create',
            description: formatMessage(messages.create),
            hashId: '7afaf52b-bae5-4708-9384-d13f4337c9d3',
            children: []
          },
          {
            '@objectId': 26,
            name: 'charts.edit',
            description: formatMessage(messages.edit),
            hashId: '5de7243c-9ba8-4a27-898a-2c3df01d4708',
            children: []
          },
          {
            '@objectId': 27,
            name: 'charts.delete',
            description: formatMessage(messages.delete),
            hashId: 'c6a42a43-198c-44f0-b357-a928a8f02e23',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'EVENTS HISTORY',
        description: formatMessage(messages.actionsState),
        hashId: 'fake',
        childrenHashes: ['9345d281-2b4f-4569-a03a-97b2d7fcb94a', '07dabfad-d4ef-4448-8ce5-7ce4610a927a'],
        children: [
          {
            '@objectId': 28,
            name: 'eventsHistory.view',
            description: formatMessage(messages.view),
            hashId: '9345d281-2b4f-4569-a03a-97b2d7fcb94a',
            children: []
          },
          {
            '@objectId': 29,
            name: 'eventsHistory.edit',
            description: formatMessage(messages.delete),
            hashId: '07dabfad-d4ef-4448-8ce5-7ce4610a927a',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'CONFIGURATIONS',
        description: formatMessage(messages.configurations),
        hashId: 'fake',
        childrenHashes: [
          '0df3c5cb-a91f-4025-964e-739542ab9b85',
          'f7cfd0e6-89cd-486e-94f3-0f1e55b46bce',
          '2cef7812-7283-4a5f-9808-7c80f44d59f7',
          'a49e6cc5-be56-4f35-9a34-748c1f4d2899'
        ],
        children: [
          {
            '@objectId': 30,
            name: 'deviceConfiguration.read',
            description: formatMessage(messages.view),
            hashId: '0df3c5cb-a91f-4025-964e-739542ab9b85',
            children: []
          },
          {
            '@objectId': 31,
            name: 'deviceConfiguration.create',
            description: formatMessage(messages.create),
            hashId: 'f7cfd0e6-89cd-486e-94f3-0f1e55b46bce',
            children: []
          },
          {
            '@objectId': 32,
            name: 'deviceConfiguration.edit',
            description: formatMessage(messages.edit),
            hashId: '2cef7812-7283-4a5f-9808-7c80f44d59f7',
            children: []
          },
          {
            '@objectId': 33,
            name: 'deviceConfiguration.delete',
            description: formatMessage(messages.delete),
            hashId: 'a49e6cc5-be56-4f35-9a34-748c1f4d2899',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'DEVICE MANAGEMENT',
        description: formatMessage(messages.deviceManagement),
        hashId: 'fake',
        childrenHashes: [
          'ed952325-b669-4d27-be41-3e28841c6003',
          '763fb013-b370-4d4e-863a-e7ffe82e6e0d',
          'a1c10596-1d01-43db-ad87-4eb045e3dff0',
          //'227d6ced-7b3b-40e7-a85d-712f715e4e1a',
          '8211b9ce-1837-4615-8ccb-a6ee2267153a',
          '3571e1da-a2d5-4753-816c-adcf53382c0c',
          '76dd3569-bfa8-426b-940f-745e25b80d54',
          '783a17a7-071a-49c1-8c91-2dcd8de84abf',
          '969ec7ca-ebbd-4a18-a756-d567347c91dd'
        ],
        children: [
          {
            '@objectId': 34,
            name: 'device.read',
            description: formatMessage(messages.view),
            hashId: 'ed952325-b669-4d27-be41-3e28841c6003',
            children: []
          },
          {
            '@objectId': 35,
            name: 'deviceSim.edit',
            description: formatMessage(messages.productionTestMode),
            hashId: '763fb013-b370-4d4e-863a-e7ffe82e6e0d',
            children: []
          },
          {
            '@objectId': 36,
            name: 'device.create',
            description: formatMessage(messages.create),
            hashId: 'a1c10596-1d01-43db-ad87-4eb045e3dff0',
            children: []
          },
          /*{
              '@objectId': 36,
              name: 'device.edit',
              description: formatMessage(messages.edit),
              hashId: '227d6ced-7b3b-40e7-a85d-712f715e4e1a',
              children: []
            },*/
          {
            '@objectId': 'fake',
            name: 'SERVICE PLANS AND SMS PACKS',
            description: formatMessage(messages.servicePlansAndSMSPacks),
            hashId: 'fake',
            childrenHashes: [
              '8211b9ce-1837-4615-8ccb-a6ee2267153a',
              '3571e1da-a2d5-4753-816c-adcf53382c0c',
              '76dd3569-bfa8-426b-940f-745e25b80d54'
            ],
            children: [
              {
                '@objectId': 37,
                name: 'dataplan.read',
                description: formatMessage(messages.view),
                hashId: '8211b9ce-1837-4615-8ccb-a6ee2267153a',
                children: []
              },
              {
                '@objectId': 38,
                name: 'dataplan.apply',
                description: formatMessage(messages.apply),
                hashId: '3571e1da-a2d5-4753-816c-adcf53382c0c',
                children: []
              },
              {
                '@objectId': 39,
                name: 'dataplan.share',
                description: formatMessage(messages.share),
                hashId: '76dd3569-bfa8-426b-940f-745e25b80d54',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'ACCESS PASSES',
            description: formatMessage(messages.accessPasses),
            hashId: 'fake',
            childrenHashes: ['783a17a7-071a-49c1-8c91-2dcd8de84abf', '969ec7ca-ebbd-4a18-a756-d567347c91dd'],
            children: [
              {
                '@objectId': 40,
                name: 'accessPass.consume',
                description: formatMessage(messages.consume),
                hashId: '783a17a7-071a-49c1-8c91-2dcd8de84abf'
              },
              {
                '@objectId': 41,
                name: 'accessPass.share',
                description: formatMessage(messages.share),
                hashId: '969ec7ca-ebbd-4a18-a756-d567347c91dd'
              }
            ]
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'GROUP DASHBOARDS',
        description: formatMessage(messages.groupDashboards),
        hashId: 'fake',
        childrenHashes: [
          'f724ec5f-84e4-48aa-99f2-aa378987cc27',
          'b015d02c-9081-425f-9a1c-795e518011b6',
          'b5474232-8c29-4ea1-9eb8-29abd94e5658',
          '45ed626e-2b11-46b6-af74-03e07e979909'
        ],
        children: [
          {
            '@objectId': 42,
            name: 'groupDashboard.read',
            description: formatMessage(messages.view),
            hashId: 'f724ec5f-84e4-48aa-99f2-aa378987cc27',
            children: []
          },
          {
            '@objectId': 43,
            name: 'groupDashboard.edit',
            description: formatMessage(messages.edit),
            hashId: 'b015d02c-9081-425f-9a1c-795e518011b6',
            children: []
          },
          {
            '@objectId': 44,
            name: 'groupDashboard.create',
            description: formatMessage(messages.create),
            hashId: 'b5474232-8c29-4ea1-9eb8-29abd94e5658',
            children: []
          },
          {
            '@objectId': 45,
            name: 'groupDashboard.delete',
            description: formatMessage(messages.delete),
            hashId: '45ed626e-2b11-46b6-af74-03e07e979909',
            children: []
          }
        ]
      },
      {
        '@objectId': 'fake',
        name: 'SETTINGS',
        description: formatMessage(messages.settings),
        hashId: 'fake',
        childrenHashes: [
          '9aef0eb1-7d12-4914-a7e0-3e11519ae781',
          '6b8e6e1f-7c27-4786-bdd5-72b169287be9',
          '364c3f45-315f-42fc-aa60-e228132a7594',
          '36100da8-a7b9-47c0-812c-4a0f8bf6d82d',
          'bff0873d-90d0-492d-9551-0a0cbfe85a0d',
          'c641dd03-40e2-44be-b246-6c6898fbbab1',
          '31bc0bd9-72e4-4273-a814-26d99e9ec593',
          '7de58a0a-e4ca-4b38-a80d-6a48e36f82d4',
          '72b0a554-7e9c-4c73-8421-54d0ca3abe3d',
          '35e52b50-2cdd-4f36-a6dd-8b9ff4fd8b9c',
          'abefc800-3717-4263-a514-cd058ff48048',
          '9f79f833-7acb-47b4-9bff-03538a6a174d',
          'ef64aecd-8e81-4e6b-a467-7f5ba5967e29',
          '25944435-196d-4d7b-84cb-d2b34f59915f',
          '28d708f3-a545-4293-823b-05e2ffa90439',
          '571b08dd-05cd-497f-857d-a49d098717d4'
        ],
        children: [
          {
            '@objectId': 'fake',
            name: 'USERS',
            description: formatMessage(messages.users),
            hashId: 'fake',
            childrenHashes: [
              '9aef0eb1-7d12-4914-a7e0-3e11519ae781',
              '6b8e6e1f-7c27-4786-bdd5-72b169287be9',
              '364c3f45-315f-42fc-aa60-e228132a7594'
            ],
            children: [
              {
                '@objectId': 46,
                name: 'user.read',
                description: formatMessage(messages.view),
                hashId: '9aef0eb1-7d12-4914-a7e0-3e11519ae781',
                children: []
              },
              {
                '@objectId': 47,
                name: 'user.create',
                description: formatMessage(messages.create),
                hashId: '6b8e6e1f-7c27-4786-bdd5-72b169287be9'
              },
              {
                '@objectId': 48,
                name: 'user.edit',
                description: formatMessage(messages.edit),
                hashId: '364c3f45-315f-42fc-aa60-e228132a7594',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'ROLES',
            description: formatMessage(messages.roles),
            hashId: 'fake',
            childrenHashes: [
              '36100da8-a7b9-47c0-812c-4a0f8bf6d82d',
              'bff0873d-90d0-492d-9551-0a0cbfe85a0d',
              'c641dd03-40e2-44be-b246-6c6898fbbab1',
              '31bc0bd9-72e4-4273-a814-26d99e9ec593'
            ],
            children: [
              {
                '@objectId': 49,
                name: 'groupRole.read',
                description: formatMessage(messages.view),
                hashId: '36100da8-a7b9-47c0-812c-4a0f8bf6d82d',
                children: []
              },
              {
                '@objectId': 50,
                name: 'groupRole.edit',
                description: formatMessage(messages.edit),
                hashId: 'bff0873d-90d0-492d-9551-0a0cbfe85a0d',
                children: []
              },
              {
                '@objectId': 51,
                name: 'groupRole.create',
                description: formatMessage(messages.create),
                hashId: 'c641dd03-40e2-44be-b246-6c6898fbbab1',
                children: []
              },
              {
                '@objectId': 52,
                name: 'groupRole.delete',
                description: formatMessage(messages.delete),
                hashId: '31bc0bd9-72e4-4273-a814-26d99e9ec593',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'GROUPS',
            description: formatMessage(messages.groups),
            hashId: 'fake',
            childrenHashes: [
              '7de58a0a-e4ca-4b38-a80d-6a48e36f82d4',
              '72b0a554-7e9c-4c73-8421-54d0ca3abe3d',
              '35e52b50-2cdd-4f36-a6dd-8b9ff4fd8b9c',
              'abefc800-3717-4263-a514-cd058ff48048',
              '9f79f833-7acb-47b4-9bff-03538a6a174d'
            ],
            children: [
              {
                '@objectId': 53,
                name: 'groups.read',
                description: formatMessage(messages.view),
                hashId: '7de58a0a-e4ca-4b38-a80d-6a48e36f82d4',
                children: []
              },
              {
                '@objectId': 54,
                name: 'groups.create',
                description: formatMessage(messages.create),
                hashId: '72b0a554-7e9c-4c73-8421-54d0ca3abe3d',
                children: []
              },
              {
                '@objectId': 55,
                name: 'groups.edit',
                description: formatMessage(messages.edit),
                hashId: '35e52b50-2cdd-4f36-a6dd-8b9ff4fd8b9c',
                children: []
              },
              {
                '@objectId': 56,
                name: 'groups.delete',
                description: formatMessage(messages.delete),
                hashId: 'abefc800-3717-4263-a514-cd058ff48048',
                children: []
              },
              {
                '@objectId': 57,
                name: 'resourceSharing.allow',
                description: formatMessage(messages.shareResources),
                hashId: '9f79f833-7acb-47b4-9bff-03538a6a174d',
                children: []
              }
            ]
          },
          {
            '@objectId': 'fake',
            name: 'NOTIFICATIONS',
            description: formatMessage(messages.notifications),
            hashId: 'fake',
            childrenHashes: [
              'ef64aecd-8e81-4e6b-a467-7f5ba5967e29',
              '25944435-196d-4d7b-84cb-d2b34f59915f',
              '28d708f3-a545-4293-823b-05e2ffa90439',
              '571b08dd-05cd-497f-857d-a49d098717d4'
            ],
            children: [
              {
                '@objectId': 58,
                name: 'rule.read',
                description: formatMessage(messages.view),
                hashId: 'ef64aecd-8e81-4e6b-a467-7f5ba5967e29',
                children: []
              },
              {
                '@objectId': 59,
                name: 'rule.create',
                description: formatMessage(messages.create),
                hashId: '25944435-196d-4d7b-84cb-d2b34f59915f',
                children: []
              },
              {
                '@objectId': 60,
                name: 'rule.edit',
                description: formatMessage(messages.edit),
                hashId: '28d708f3-a545-4293-823b-05e2ffa90439',
                children: []
              },
              {
                '@objectId': 61,
                name: 'rule.delete',
                description: formatMessage(messages.delete),
                hashId: '571b08dd-05cd-497f-857d-a49d098717d4',
                children: []
              }
            ]
          }
        ]
      }
    ]
    break

  default:
}

export { csPrivileges, csPrivilegesHierarchy }
