import { defineMessages } from 'react-intl'

const namespace = 'machines'

const messages = defineMessages({
  title: {
    id: `${namespace}.title`,
    description: 'Page title',
    defaultMessage: 'Machines'
  },
  thereAreCurrentlyNoMachines: {
    id: `${namespace}.thereAreCurrentlyNoMachines`,
    description: 'There are currently no machines message',
    defaultMessage: 'There are currently no machines.'
  },
  fleetActionsTitle: {
    id: `${namespace}.fleetActionsTitle`,
    description: 'Fleet actions title',
    defaultMessage: 'Fleet actions'
  },
  selectAction: {
    id: `${namespace}.selectAction`,
    description: 'Select action text',
    defaultMessage: 'Select action'
  },
  close: {
    id: `${namespace}.close`,
    description: 'Machine close button text',
    defaultMessage: 'Close'
  },
  showingMachines: {
    id: `${namespace}.showingMachines`,
    description: 'Machines table total text showing rows',
    defaultMessage: 'Showing machines'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Machines table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Machines table total text of',
    defaultMessage: 'of'
  },
  noDataText: {
    id: `${namespace}.noDataText`,
    description: 'No data text',
    defaultMessage: 'No machines assigned'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Machine previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Machine next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'Machine first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Machine last page button text',
    defaultMessage: 'Last'
  },
  selected: {
    id: `${namespace}.selected`,
    description: 'Selected machines text',
    defaultMessage: 'Selected'
  },
  clearSelection: {
    id: `${namespace}.clearSelection`,
    description: 'Clear selection text',
    defaultMessage: 'CLEAR SELECTION'
  },
  fleetActionsText: {
    id: `${namespace}.fleetActionsText`,
    description: 'Fleet actions text',
    defaultMessage: 'FLEET ACTIONS'
  },
  searchPlaceholder: {
    id: `${namespace}.searchPlaceholder`,
    description: 'Saaerch placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  locationID: {
    id: `${namespace}.locationID`,
    description: 'Location ID',
    defaultMessage: 'Location ID'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name title',
    defaultMessage: 'Name'
  },
  fleetName: {
    id: `${namespace}.fleetName`,
    description: 'Fleet name title',
    defaultMessage: 'Fleet name'
  },
  manufacturer: {
    id: `${namespace}.manufacturer`,
    description: 'Manufacturer title',
    defaultMessage: 'Manufacturer'
  },
  mfr: {
    id: `${namespace}.mfr`,
    description: 'Manufacturer abbreviation title',
    defaultMessage: 'Mfr.'
  },
  mfrCode: {
    id: `${namespace}.code`,
    description: 'Manufacturer code title',
    defaultMessage: '{Mfr} code'
  },
  imeiOrMac: {
    id: `${namespace}.imeiOrMac`,
    description: 'IMEI or MAC title',
    defaultMessage: 'IMEI or MAC'
  },
  installDate: {
    id: `${namespace}.installDate`,
    description: 'Install date title',
    defaultMessage: 'Install date'
  },
  actions: {
    id: `${namespace}.actions`,
    description: 'Actions title',
    defaultMessage: 'Actions'
  },
  errorRequestingData: {
    id: `${namespace}.errorRequestingData`,
    description: 'No machines available message',
    defaultMessage: 'No machines available'
  },
  firmwareUpdateWarning: {
    id: `${namespace}.firmwareUpdateWarning`,
    description: 'Firmware update warning',
    defaultMessage:
      'WARNING! You can`t update the firmware or the configuration of different type of machines at the same time.'
  },
  idColumn: {
    id: `${namespace}.idColumn`,
    description: 'Id',
    defaultMessage: 'Id'
  },
  deviceType: {
    id: `${namespace}.deviceType`,
    description: 'Device type',
    defaultMessage: 'Device type'
  },
  eid: {
    id: `${namespace}.eid`,
    description: 'eid',
    defaultMessage: 'EID'
  },
  lastTelemetryMessage: {
    id: `${namespace}.lastTelemetryMessage`,
    description: 'Last telemetry message',
    defaultMessage: 'Last telemetry message'
  },
  actionsText: {
    id: `${namespace}.actionsText`,
    description: 'Actions text',
    defaultMessage: 'ACTIONS'
  },
  deviceConfiguration: {
    id: `${namespace}.deviceConfiguration`,
    description: 'Device configuration text',
    defaultMessage: 'Device configuration'
  },
  deviceConfigurationVersion: {
    id: `${namespace}.deviceConfigurationVersion`,
    description: 'Device configuration version text',
    defaultMessage: 'Device configuration version'
  },
  placeholder: {
    id: `${namespace}.placeholder`,
    description: 'Placeholder text',
    defaultMessage: 'Enter {column}'
  },
  selectPlaceholder: {
    id: `${namespace}.selectPlaceholder`,
    description: 'Select placeholder text',
    defaultMessage: 'Select {column}'
  },
  downloadCSV: {
    id: `${namespace}.downloadCSV`,
    defaultMessage: 'Download CSV'
  },
  firmwareVersion: {
    id: `${namespace}.firmwareVersion`,
    description: 'Firmware version',
    defaultMessage: 'Firmware version'
  },
  machineModel: {
    id: `${namespace}.machineModel`,
    description: 'Model column',
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    description: 'Machine type',
    defaultMessage: 'Machine type'
  },
  machine: {
    id: `${namespace}.machine`,
    description: 'Machine',
    defaultMessage: 'Machine'
  },
  selectColumns: {
    id: `${namespace}.selectColumns`,
    description: 'Select columns',
    defaultMessage: 'Select columns'
  },
  machineBrand: {
    id: `${namespace}.machineBrand`,
    description: 'Brand',
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    description: 'Machine serial number',
    defaultMessage: 'Machine serial number'
  },
  showHideColumns: {
    id: `${namespace}.showHideColumns`,
    description: 'Show/hide columns',
    defaultMessage: 'Show/hide columns'
  },
  editMachine: {
    id: `${namespace}.editMachine`,
    description: 'Edit machine',
    defaultMessage: 'Edit machine'
  },
  reset: {
    id: `${namespace}.reset`,
    description: 'Reset fleet action title',
    defaultMessage: 'Reset'
  },
  updateFirmware: {
    id: `${namespace}.updateFirmware`,
    description: 'CSx firmware update fleet action title',
    defaultMessage: 'Device firmware update'
  },
  updateConfiguration: {
    id: `${namespace}.updateConfiguration`,
    description: 'CSx configuration update action title',
    defaultMessage: 'Configuration update'
  },
  importDashboards: {
    id: `${namespace}.importDashboards`,
    description: 'Import dashboards action title',
    defaultMessage: 'Import dashboards'
  },
  deleteDashboards: {
    id: `${namespace}.deleteDashboards`,
    description: 'Delete dashboards action title',
    defaultMessage: 'Delete dashboards'
  },
  importAdvancedSignals: {
    id: `${namespace}.importAdvancedSignals`,
    description: 'Import advanced signals action title',
    defaultMessage: 'Import advanced signals'
  },
  dashboards: {
    id: `${namespace}.dashboards`,
    description: 'Dashboards',
    defaultMessage: 'Dashboards'
  },
  machineDetails: {
    id: `${namespace}.machineDetails`,
    description: 'Machine details',
    defaultMessage: 'Machine details'
  },
  systemOverview: {
    id: `${namespace}.systemOverview`,
    description: 'System overview',
    defaultMessage: 'System overview'
  },
  nodeHistory: {
    id: `${namespace}.nodeHistory`,
    description: 'Node history',
    defaultMessage: 'Node history'
  },
  editNode: {
    id: `${namespace}.editNode`,
    description: 'Edit node',
    defaultMessage: 'Edit node'
  },
  advancedSignalsConfiguration: {
    id: `${namespace}.advancedSignalsConfiguration`,
    description: 'Advanced signals configuration',
    defaultMessage: 'Advanced signals configuration'
  },
  mobileSignal: {
    id: `${namespace}.mobileSignal`,
    description: 'Mobile signal',
    defaultMessage: 'Mobile signal'
  },
  lastGPSMessage: {
    id: `${namespace}.lastGPSMessage`,
    description: 'Last GPS message',
    defaultMessage: 'Last GPS message'
  },
  lastGPSMessageOlderThan24Hours: {
    id: `${namespace}.lastGPSMessageOlderThan24Hours`,
    description: 'The last GPS message is older than 24 hours',
    defaultMessage: 'The last GPS message is older than 24 hours'
  }
})

export default messages
