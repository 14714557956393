import { defineMessages } from 'react-intl'

const namespace = 'ViewConfiguration'

const messages = defineMessages({
  download: {
    id: `${namespace}.download`,
    description: 'Download button label',
    defaultMessage: 'Download'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  createdDate: {
    id: `${namespace}.createdDate`,
    description: 'Created date',
    defaultMessage: 'Created date'
  },
  generalSettings: {
    id: `${namespace}.generalSettings`,
    description: 'General settings card title',
    defaultMessage: 'Device settings'
  },
  gpsSettings: {
    id: `${namespace}.gpsSettings`,
    description: 'GPS settings card title',
    defaultMessage: 'GPS settings'
  },
  modelName: {
    id: `${namespace}.modelName`,
    description: 'Model name input label',
    defaultMessage: 'Configuration name'
  },
  nodeId: {
    id: `${namespace}.nodeId`,
    description: 'Node id input label',
    defaultMessage: 'PLUS+1 Node ID'
  },
  baudRate1: {
    id: `${namespace}.baudRate1`,
    description: 'Baud rate 1 select label',
    defaultMessage: 'CAN port 0 baud rate (bps)'
  },
  baudRate2: {
    id: `${namespace}.baudRate2`,
    description: 'Baud rate 2 select label',
    defaultMessage: 'CAN port 1 baud rate (bps)'
  },
  loggingUploadPeriod: {
    id: `${namespace}.loggingUploadPeriod`,
    description: 'LoggingUploadPeriod input label',
    defaultMessage: 'Logging upload period (s)'
  },
  loggingBufferThreshold: {
    id: `${namespace}.loggingBufferThreshold`,
    description: 'LoggingBufferThreshold input label',
    defaultMessage: 'Logging buffer threshold (No. of messages)'
  },
  dataLogging: {
    id: `${namespace}.dataLogging`,
    description: 'Data logging checkbox label',
    defaultMessage: 'Data logging'
  },
  enableGPSLogging: {
    id: `${namespace}.enableGPSLogging`,
    description: 'enableGPSLogging checkbox label',
    defaultMessage: 'Enable GPS logging'
  },
  frequency: {
    id: `${namespace}.frequency`,
    description: 'Frequency texfield label',
    defaultMessage: 'Frequency'
  },
  loggingTime: {
    id: 'Signal.loggingTime',
    description: 'Logging time texfield label',
    defaultMessage: 'Logging time (s)'
  },
  canbus: {
    id: `${namespace}.canbus`,
    description: 'CAN bus paper title',
    defaultMessage: 'CAN bus'
  },
  mfio: {
    id: `${namespace}.mfio`,
    description: 'MFIO paper title',
    defaultMessage: 'Multi-Function I/O'
  },
  history: {
    id: `${namespace}.history`,
    description: 'History card title',
    defaultMessage: 'History'
  },
  version: {
    id: `${namespace}.version`,
    description: 'version',
    defaultMessage: 'Version'
  },
  published: {
    id: `${namespace}.published`,
    description: 'published',
    defaultMessage: 'Published'
  },
  parentRoot: {
    id: `${namespace}.parentRoot`,
    description: 'Parent root message',
    defaultMessage: 'This is a root parent configuration'
  },
  speed: {
    id: `${namespace}.speed`,
    description: 'speed',
    defaultMessage: 'Speed'
  },
  altitude: {
    id: `${namespace}.altitude`,
    description: 'altitude',
    defaultMessage: 'Altitude'
  },
  heading: {
    id: `${namespace}.heading`,
    description: 'heading',
    defaultMessage: 'Heading'
  },
  error400Message: {
    id: `${namespace}.error400Message`,
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: `${namespace}.error403Message`,
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: `${namespace}.error406Message`,
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error409Message: {
    id: `${namespace}.error409Message`,
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: Configuration name it`s already in use'
  },
  error415Message: {
    id: `${namespace}.error415Message`,
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: `${namespace}.error422Message`,
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  }
})

export default messages
