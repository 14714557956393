import { defineMessages } from 'react-intl'

const namespace = 'GpsTrackings'

const messages = defineMessages({
  error400Message: {
    id: `${namespace}.error400Message`,
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: `${namespace}.error403Message`,
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: `${namespace}.error406Message`,
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  thereAreCurrentlyNoGpsTrackings: {
    id: `${namespace}.thereAreCurrentlyNoGpsTrackings`,
    description: 'There are currently no gpsTrackings message',
    defaultMessage: `There are currently no ${namespace}.`
  },
  ok: {
    id: `${namespace}.ok`,
    description: 'Ok button label',
    defaultMessage: 'OK'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'CANCEL'
  },
  delete: {
    id: `${namespace}.delete`,
    description: 'Delete button label',
    defaultMessage: 'DELETE'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name dialog content text',
    defaultMessage: 'Name:'
  },
  description: {
    id: `${namespace}.description`,
    description: 'Description dialog content text',
    defaultMessage: 'Description:'
  },
  group: {
    id: `${namespace}.group`,
    description: 'Group dialog content text',
    defaultMessage: 'Group:'
  },
  status: {
    id: `${namespace}.status`,
    description: 'Status dialog content text',
    defaultMessage: 'Status:'
  },
  edit: {
    id: `${namespace}.edit`,
    description: 'Edit button label',
    defaultMessage: 'Edit'
  },
  searchGroup: {
    id: `${namespace}.searchGroup`,
    description: 'Search Groups autocomplete',
    defaultMessage: 'Select the group(s)'
  },
  deleteLow: {
    id: `${namespace}.deleteLow`,
    description: 'Delete button label lowcase',
    defaultMessage: 'Delete'
  },
  title: {
    id: `${namespace}.title`,
    description: 'GpsTrackings title',
    defaultMessage: 'Geotrackings'
  },
  createGpsTracking: {
    id: `${namespace}.createGpsTracking`,
    description: 'Create gpsTracking button label',
    defaultMessage: 'Create Geotracking'
  },
  selectGroup: {
    id: `${namespace}.selectGroup`,
    description: 'Select group option label',
    defaultMessage: 'Select group'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  timePeriod: {
    id: `${namespace}.timePeriod`,
    description: 'Select a time period  message',
    defaultMessage: 'Select a time period'
  },
  selectMachines: {
    id: `${namespace}.selectMachines`,
    description: 'Select machines message',
    defaultMessage: 'Select machines'
  },
  selectedGroups: {
    id: `${namespace}.selectedGroups`,
    description: 'Selected Groups message',
    defaultMessage: 'Selected groups'
  },
  geotracking: {
    id: `${namespace}.geotracking`,
    description: 'Geotracking Tittle',
    defaultMessage: 'Geotracking'
  },
  goTo: {
    id: `${namespace}.goTo`,
    description: 'Go To tooltip',
    defaultMessage: 'Go to'
  },
  noGPS: {
    id: `${namespace}.noGPS`,
    description: 'No GPS Location tooltip',
    defaultMessage: 'No GPS location'
  },
  removeMachine: {
    id: `${namespace}.removeMachine`,
    description: 'Remove machine tooltip',
    defaultMessage: 'Remove machine'
  },
  nextPoints: {
    id: `${namespace}.nextPoints`,
    description: 'Next Points  tooltip',
    defaultMessage: 'Next'
  },
  prevPoints: {
    id: `${namespace}.prevPoints`,
    description: 'Previous Points  tooltip',
    defaultMessage: 'Prev'
  },
  reset: {
    id: `${namespace}.reset`,
    description: 'Reset',
    defaultMessage: 'Reset'
  },
  stop: {
    id: `${namespace}.stop`,
    description: 'Stop',
    defaultMessage: 'Stop'
  },
  start: {
    id: `${namespace}.start`,
    description: 'Start',
    defaultMessage: 'Start'
  },
  search: {
    id: `${namespace}.search`,
    description: 'Search',
    defaultMessage: 'Search'
  },
  showing: {
    id: `${namespace}.showing`,
    description: 'Points info message part 1',
    defaultMessage: 'Showing'
  },
  points: {
    id: `${namespace}.points`,
    description: 'Points info message part 2',
    defaultMessage: 'points'
  },
  historical: {
    id: 'GPSQuerySelector.historical',
    description: 'Historical tracking source option label',
    defaultMessage: 'Historical'
  },
  realTime: {
    id: 'GPSQuerySelector.realTime',
    description: 'Real time tracking source option label',
    defaultMessage: 'Real time'
  },
  noGpsTrackingsTooltip: {
    id: `${namespace}.noGpsTrackingsTooltip`,
    description: 'Message for the tooltip when a machine has no data whitin the selected range of dates',
    defaultMessage: 'No data in the selected range of dates'
  },
  download: {
    id: `${namespace}.download`,
    description: 'Download',
    defaultMessage: 'Download'
  }
})

export default messages
