import React from 'react'

import { useAuth0 } from '../../../react-auth0-spa'

const SSOSignInButton = props => {
  const { loginWithRedirect } = useAuth0()
  return (
    <button
      className={props.classValue}
      id='log-in-button'
      onClick={() => {
        loginWithRedirect({})
      }}
    >
      {props.buttonText}
    </button>
  )
}

export default SSOSignInButton
