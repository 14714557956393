import { saveAs } from 'file-saver'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import writeXlsxFile from 'write-excel-file'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import { devicesEndpointAdapter } from 'utils/adapters'
import { logError } from 'utils/http'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import ActionButtons from './ActionButtons'
import GpsQuerySelector from './GpsQuerySelector'
import SelectedMachinesManager from './SelectedMachinesManager'
import { cleanEID } from '../../../utils'
import messages from '../messages'

const styles = {
  indicator: {
    backgroundColor: 'rgba(0, 0, 0, 0)'
  }
}

class QueryPanel extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      dateFromTimestamp: 0,
      dateToTimestamp: 0
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.TRACKING_SOURCE_HISTORICAL = 'TRACKING_SOURCE_HISTORICAL'
    this.TRACKING_SOURCE_REALTIME = 'TRACKING_SOURCE_REALTIME'
    this.TRACKING_SOURCE_OPTIONS = [
      { label: formatMessage(messages.historical), value: this.TRACKING_SOURCE_HISTORICAL },
      { label: formatMessage(messages.realTime), value: this.TRACKING_SOURCE_REALTIME }
    ]

    this.state = {
      isMenuOpen: true,
      trackingSource: this.TRACKING_SOURCE_HISTORICAL,
      isAssetsLoading: false,
      devices: [],

      ...this.initialState
    }
  }

  componentDidMount() {
    this.resetInitialState()
    this.getAssets()
  }

  componentDidUpdate(prevProps) {
    const { groupId } = this.props

    if (groupId && prevProps.groupId !== groupId) {
      this.resetInitialState()
      this.getAssets()
    }
  }

  getAssets = () => {
    const { groupId, onSelectedDevicesChange } = this.props

    this.setState({ isAssetsLoading: true })
    onSelectedDevicesChange([])
    this.getAssetsPaged(groupId, 200, 0)
  }

  getAssetsPaged = (groupId, limit, offset, result = []) => {
    const { getCSNodes, onDeviceColorsChange } = this.props

    const deviceFields = {
      Device: ['id', 'name', 'eid']
    }

    getCSNodes(groupId, limit, offset, deviceFields)
      .then(payload => {
        const mappedDevices = devicesEndpointAdapter(payload.data.devices)
        const totalDevices = [...result, ...mappedDevices]
        if (payload.data.total > totalDevices.length) {
          this.getAssetsPaged(groupId, limit, offset + limit, totalDevices)
        } else {
          this.setState({
            devices: totalDevices,
            isAssetsLoading: false
          })
          const deviceColors = this.getNewDeviceColors(totalDevices)
          onDeviceColorsChange(deviceColors)
        }
      })
      .catch(({ error }) => {
        const { response } = { ...error }
        switch (response.status) {
          case 400: // Bad request
          case 401: // Invalid credentials
          case 403: // Access denied
          case 404: // API url not found
          case 406: // Not acceptable
          case 500: // Server errors
            this.setState({
              devices: [],
              isAssetsLoading: false
            })
            break
          default:
            this.setState({
              devices: [],
              isAssetsLoading: false
            })
            logError(response)
        }
      })
  }

  getNewDeviceColors = devices => {
    return devices.map(device => {
      const newDeviceColor =
        '#' +
        Math.floor(Math.random() * (Math.pow(2, 24) - 1))
          .toString(16)
          .padStart(6, '0')

      return {
        EID: device.EID,
        color: newDeviceColor
      }
    })
  }

  getUnselectedDevices = () => {
    const { selectedDevices } = this.props
    const { devices } = this.state

    const unselectedDevices = devices.filter(device => {
      return !selectedDevices.map(selectedDevice => selectedDevice.id).includes(device.id)
    })

    return unselectedDevices
  }

  connectAllRealTimeWebSockets = () => {
    const { connectRealtimeGPSWebS, nodeCredentials, selectedDevices } = this.props

    selectedDevices.forEach(selectedDevice => {
      const topic = process.env.REACT_APP_TOPIC + 'm' + cleanEID(selectedDevice.EID) + '/geo'
      connectRealtimeGPSWebS(selectedDevice.EID, topic, nodeCredentials ? nodeCredentials[selectedDevice.id] : {})
    })
  }

  disconnectAllRealtimeWebSockets = () => {
    const { disconnectRealtimeGPSWebS, selectedDevices } = this.props

    selectedDevices.forEach(selectedDevice => {
      const topic = process.env.REACT_APP_TOPIC + 'm' + cleanEID(selectedDevice.EID) + '/geo'
      disconnectRealtimeGPSWebS(selectedDevice.EID, topic)
    })
  }

  resetInitialState = () => {
    const { deleteGPSTrackings, resetInitialState } = this.props

    deleteGPSTrackings()
    this.disconnectAllRealtimeWebSockets()
    this.setState(this.initialState)
    resetInitialState()
  }

  handleTabChange = (event, value) => {
    const { history } = this.props
    const { isMenuOpen } = this.state

    if (value === 0) {
      this.setState({
        isMenuOpen: !isMenuOpen
      })
    } else {
      history.push('/geofences')
    }
  }

  handleTrackingSourceChange = value => {
    this.setState({
      trackingSource: value
    })
  }

  handleDateFromChange = value => {
    const date = value ? value.valueOf() : 0
    this.setState({
      dateFromTimestamp: date
    })
  }

  handleDateToChange = value => {
    const date = value ? value.valueOf() : 0
    this.setState({
      dateToTimestamp: date
    })
  }

  handleResetButtonClick = () => {
    this.resetInitialState()
  }

  handleDownloadXlsxFileButtonClick = () => {
    const { gpsTrackings, queriedDevices } = this.props

    const schema = [
      {
        column: 'Timestamp',
        type: String,
        value: point => point.timestamp
      },
      {
        column: 'Latitude',
        type: Number,
        value: point => point.latitude
      },
      {
        column: 'Longitude',
        type: Number,
        value: point => point.longitude
      },
      {
        column: 'Altitude(m)',
        type: Number,
        value: point => {
          let altitude = point.altitude
          if (isNaN(altitude)) altitude = undefined
          return altitude
        }
      },
      {
        column: 'Speed(km/h)',
        type: Number,
        value: point => {
          let speed = point.speed
          if (isNaN(speed)) speed = undefined
          return speed
        }
      },
      {
        column: 'Heading',
        type: Number,
        value: point => {
          let heading = point.heading
          if (isNaN(heading)) heading = undefined
          return heading
        }
      }
    ]

    const sheets = Object.entries(gpsTrackings).reduce((acc, [EID, deviceGPSTrackings]) => {
      const sortedGPSPoints = deviceGPSTrackings.points.sort((a, b) => a.timestamp - b.timestamp)
      const formattedAndSortedGPSPoints = sortedGPSPoints.map(point => {
        const { timestamp } = point
        const formattedTimestamp = utcTimeToBrowserLocal(timestamp)
        return { ...point, timestamp: formattedTimestamp }
      })
      const { name: deviceName } = queriedDevices.find(device => device.EID.replaceAll(':', '') === EID)
      return { ...acc, [deviceName]: formattedAndSortedGPSPoints }
    }, {})
    const sheetNames = Object.keys(sheets)
    const objects = Object.values(sheets)
    const schemas = sheetNames.length > 0 ? Array(sheetNames.length).fill(schema) : [schema]

    const xlsxFileConfiguration = {
      schema: schemas,
      ...sheetNames.length > 0 && { sheets: sheetNames },
      fileName: 'Geotracking.xlsx'
    }

    writeXlsxFile(objects, xlsxFileConfiguration).catch(error => {
      console.log('Xlsx file generation error: ', error)
    })
  }

  handleHistoricalGpsTrackingSearchClick = () => {
    const { deleteGPSTrackings, getGPSTrackingTimeSeries, onQueriedDevicesChange, selectedDevices, trail } = this.props
    const { dateFromTimestamp, dateToTimestamp } = this.state
    const formattedDateFromTimestamp = dateFromTimestamp || ''
    const formattedDateToTimestamp = dateToTimestamp || ''

    deleteGPSTrackings()
    selectedDevices.forEach(selectedDevice => {
      getGPSTrackingTimeSeries(
        cleanEID(selectedDevice.EID),
        formattedDateFromTimestamp,
        formattedDateToTimestamp,
        trail
      )
    })
    onQueriedDevicesChange(selectedDevices)
  }

  handleRealtimeGpsTrackingStartClick = () => {
    const { deleteGPSTrackings, onQueriedDevicesChange, onRealtimeGpsTrackingRunningChange } = this.props

    deleteGPSTrackings()
    this.connectAllRealTimeWebSockets()
    onRealtimeGpsTrackingRunningChange(true)
    onQueriedDevicesChange([])
  }

  handleRealtimeGpsTrackingStopClick = () => {
    const { deleteGPSTrackings, onQueriedDevicesChange, onRealtimeGpsTrackingRunningChange } = this.props

    deleteGPSTrackings()
    this.disconnectAllRealtimeWebSockets()
    onRealtimeGpsTrackingRunningChange(false)
    onQueriedDevicesChange([])
  }

  render() {
    const {
      classes,
      deviceColors,
      isGpsTrackingsLoading,
      isRealtimeGpsTrackingRunning,
      onDeviceColorsChange,
      onFlyToMarkerClick,
      onSelectedDevicesChange,
      onTrailChange,
      privileges,
      queriedDevices,
      selectedDevices,
      trail
    } = this.props
    const { dateFromTimestamp, dateToTimestamp, isAssetsLoading, isMenuOpen, trackingSource } = this.state

    const unselectedDevices = this.getUnselectedDevices()

    return (
      <div
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          height: '100%',
          flexGrow: isMenuOpen ? 1 : 0,
          flexShrink: 0,
          flexBasis: 0,
          minWidth: isMenuOpen ? '315px' : 0,
          position: 'relative',
          zIndex: 10
        }}
      >
        <div
          id='gpsTracking'
          style={{
            height: '100%',
            width: isMenuOpen ? '100%' : 0,
            display: 'inline-block'
          }}
        >
          <Paper
            square
            style={{
              width: '100%',
              height: '100%',
              overflow: 'auto'
            }}
          >
            <Grid
              container
              spacing={3}
              style={{
                height: '55px',
                margin: 0,
                width: '97%'
              }}
            >
              <Grid item xs={12}>
                <Typography
                  color='inherit'
                  style={{
                    padding: '0 12px 0 12px',
                    width: '100%',
                    float: 'left'
                  }}
                  variant='h6'
                >
                  {this.formatMessage(messages.geotracking)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{
                margin: 0,
                width: '97%'
              }}
            >
              <Grid
                item
                style={{
                  paddingLeft: '24px',
                  paddingRight: '24px'
                }}
                xs={12}
              >
                <GpsQuerySelector
                  dateFromTimestamp={dateFromTimestamp}
                  dateToTimestamp={dateToTimestamp}
                  isRealtimeGpsTrackingRunning={isRealtimeGpsTrackingRunning}
                  isRealtimeTrackingSourceSelected={trackingSource === this.TRACKING_SOURCE_REALTIME}
                  onDateFromChange={this.handleDateFromChange}
                  onDateToChange={this.handleDateToChange}
                  onTrackingSourceChange={this.handleTrackingSourceChange}
                  onTrailChange={onTrailChange}
                  trackingSource={trackingSource}
                  trackingSourceOptions={this.TRACKING_SOURCE_OPTIONS}
                  trail={trail}
                />
              </Grid>
              <Grid
                item
                style={{
                  paddingLeft: '24px',
                  paddingRight: '24px'
                }}
                xs={12}
              >
                <SelectedMachinesManager
                  deviceColors={deviceColors}
                  isAssetsLoading={isAssetsLoading}
                  isRealtimeGpsTrackingRunning={isRealtimeGpsTrackingRunning}
                  onDeviceColorsChange={onDeviceColorsChange}
                  onFlyToMarkerClick={onFlyToMarkerClick}
                  onSelectedDevicesChange={onSelectedDevicesChange}
                  queriedDevices={queriedDevices}
                  selectedDevices={selectedDevices}
                  unselectedDevices={unselectedDevices}
                />
              </Grid>

              <ActionButtons
                isGpsTrackingsLoading={isGpsTrackingsLoading}
                isInvalidGpsTrackingDateRange={dateFromTimestamp > dateToTimestamp}
                isMenuOpen={isMenuOpen}
                isRealtimeGpsTrackingRunning={isRealtimeGpsTrackingRunning}
                isRealtimeTrackingSourceSelected={trackingSource === this.TRACKING_SOURCE_REALTIME}
                onDownloadButtonClick={this.handleDownloadXlsxFileButtonClick}
                onHistoricalGpsTrackingSearchClick={this.handleHistoricalGpsTrackingSearchClick}
                onRealtimeGpsTrackingStartClick={this.handleRealtimeGpsTrackingStartClick}
                onRealtimeGpsTrackingStopClick={this.handleRealtimeGpsTrackingStopClick}
                onResetButtonClick={this.handleResetButtonClick}
                queriedDevices={queriedDevices}
                selectedDevices={selectedDevices}
              />
            </Grid>
          </Paper>
        </div>
        <div
          style={{
            transformOrigin: 'left top',
            display: 'inline-block',
            position: 'absolute',
            right: '-33px',
            transform: 'translateX(100%) rotate(90deg) scale(0.7)'
          }}
        >
          <Tabs
            classes={{ indicator: classes.indicator }}
            onChange={this.handleTabChange}
            style={{ float: 'left' }}
            value={0}
          >
            <Tab
              label='Geotracking'
              style={{
                backgroundColor: 'white'
              }}
            />
            <Tab
              disabled={!privileges.canViewGeofence}
              label={privileges.canViewGeofence ? 'Geofence' : ''}
              style={{
                backgroundColor: !privileges.canViewGeofence ? 'transparent' : '#dcdcdc'
              }}
            />
          </Tabs>
        </div>
      </div>
    )
  }
}

QueryPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  connectRealtimeGPSWebS: PropTypes.func.isRequired,
  deleteGPSTrackings: PropTypes.func.isRequired,
  deviceColors: PropTypes.array.isRequired,
  disconnectRealtimeGPSWebS: PropTypes.func.isRequired,
  getCSNodes: PropTypes.func.isRequired,
  getGPSTrackingTimeSeries: PropTypes.func.isRequired,
  gpsTrackings: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isGpsTrackingsLoading: PropTypes.bool.isRequired,
  isRealtimeGpsTrackingRunning: PropTypes.bool.isRequired,
  nodeCredentials: PropTypes.object.isRequired,
  onDeviceColorsChange: PropTypes.func.isRequired,
  onFlyToMarkerClick: PropTypes.func.isRequired,
  onQueriedDevicesChange: PropTypes.func.isRequired,
  onRealtimeGpsTrackingRunningChange: PropTypes.func.isRequired,
  onSelectedDevicesChange: PropTypes.func.isRequired,
  onTrailChange: PropTypes.func.isRequired,
  privileges: PropTypes.shape({ canViewGeofence: PropTypes.bool.isRequired }).isRequired,
  queriedDevices: PropTypes.array.isRequired,
  resetInitialState: PropTypes.func.isRequired,
  selectedDevices: PropTypes.array.isRequired,
  trail: PropTypes.number.isRequired
}

export default withStyles(styles)(injectIntl(QueryPanel))
