import * as R from './constants'

// DEPRECATED
// If you find yourself defining functions in this file, please note that with
// React Router v4 each router handles its own subroutes and that each
// constant should be inside the component it belongs to.
// Check components/settings to see an example which handles many subroutes.

// Ikerlan: new router version routes
const getMapUrl = () => R.MAP

const getActionsStateRoute = () => R.ACTIONSSTATE

const getDashboardsRoute = (nodeFamily, nodeId) =>
  R.NODES_DASHBOARDS.replace(':nodeFamily', nodeFamily).replace(':nodeId', nodeId)

const getGeotrackingRoute = () => R.GEOTRACKING

export { getMapUrl, getActionsStateRoute, getDashboardsRoute, getGeotrackingRoute }
