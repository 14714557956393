import { defineMessages } from 'react-intl'

const namespace = 'AssignMachinesToNotification'

const messages = defineMessages({
  error400Text: {
    id: `${namespace}.error400Text`,
    description: 'Error 400 text',
    defaultMessage: 'Bad request to get data'
  },
  error404Text: {
    id: `${namespace}.error404Text`,
    description: 'Error 404 text',
    defaultMessage: 'API url not found'
  },
  error406Text: {
    id: `${namespace}.error406Text`,
    description: 'Error 406 text',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedText: {
    id: `${namespace}.errorUndefinedText`,
    description: 'Error undefined text',
    defaultMessage: 'No description avaliable.'
  },
  title: {
    id: `${namespace}.title`,
    description: 'Title text',
    defaultMessage: 'Assign machines to notification'
  },
  return: {
    id: `${namespace}.return`,
    description: 'Return button label',
    defaultMessage: 'Return'
  },
  otherError400Text: {
    id: `${namespace}.otherError400Text`,
    description: 'Other error 400 text',
    defaultMessage: 'Bad request'
  },
  error403Text: {
    id: `${namespace}.error403Text`,
    description: 'Error 403 text',
    defaultMessage: 'Access denied'
  },
  error500Text: {
    id: `${namespace}.error500Text`,
    description: 'Error 500 text',
    defaultMessage: 'Access denied'
  },
  otherError404Text: {
    id: `${namespace}.otherError404Text`,
    description: 'Other error 404 text',
    defaultMessage: 'API not available at the moment. Try it later.'
  },
  error409Text: {
    id: `${namespace}.error409Text`,
    description: 'Error 409 text',
    defaultMessage: 'Data integrity violation.'
  },
  error422Text: {
    id: `${namespace}.error422Text`,
    description: 'Error 422 text',
    defaultMessage: 'Validation failed'
  },
  error500ToSHow: {
    id: `${namespace}.error500ToSHow`,
    description: 'Error 500 to show message',
    defaultMessage: 'Server error'
  },
  selectedMachines: {
    id: `${namespace}.selectedMachines`,
    description: 'Selected machines button label',
    defaultMessage: 'Selected'
  },
  clearSelection: {
    id: `${namespace}.clearSelection`,
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },
  assignMachines: {
    id: `${namespace}.assignMachines`,
    description: 'Assign machines button label',
    defaultMessage: 'Assign machines'
  },
  showingMachines: {
    id: `${namespace}.showingMachines`,
    description: 'Assign machines table total text showing rows',
    defaultMessage: 'Showing machines'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Assign machines table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Assign machines table total text of',
    defaultMessage: 'of'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Notifications previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Notifications next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'Notifications first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Notifications last page button text',
    defaultMessage: 'Last'
  },
  filterByOrderableColumns: {
    id: `${namespace}.filterByOrderableColumns`,
    description: 'Filter by orderable columns placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  locationID: {
    id: `${namespace}.locationID`,
    description: 'Location ID table header column label',
    defaultMessage: 'Last'
  },
  manufacturerCode: {
    id: `${namespace}.manufacturerCode`,
    description: 'Manufacturer code table header column label',
    defaultMessage: 'Manufacturer code'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name table header column label',
    defaultMessage: 'Name'
  },
  eid: {
    id: `${namespace}.eid`,
    description: 'EID  table header column label',
    defaultMessage: 'EID'
  },
  creationDate: {
    id: `${namespace}.creationDate`,
    description: 'Creation date table header column label',
    defaultMessage: 'Creation date'
  },
  noMachines: {
    id: `${namespace}.noMachines`,
    description: 'No machines available message',
    defaultMessage: 'No machines are available.'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Text: {
    id: `${namespace}.error401Text`,
    description: 'Error 401 text',
    defaultMessage: 'Invalid credentials'
  },
  searchPlaceholder: {
    id: `${namespace}.searchPlaceholder`,
    description: 'Search placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  errorText: {
    id: `${namespace}.errorText`,
    description: 'Error title',
    defaultMessage: 'Error'
  },
  errorRequestingMachines: {
    id: `${namespace}.errorRequestingMachines`,
    description: 'Error requesting machines',
    defaultMessage: 'Error requesting machines'
  },
  errorLoadingNotification: {
    id: `${namespace}.errorLoadingNotification`,
    description: 'Error loading notification description',
    defaultMessage: 'It was not possible to load the requested notification.'
  },
  machineConditionText: {
    id: `${namespace}.machineConditionText`,
    description: 'Machine condition explanation',
    defaultMessage:
      'IMPORTANT: To assign several machines to notification, the machines must have the same configuration file.'
  },
  machineConditionTextNoSelectableMachines: {
    id: `${namespace}.machineConditionTextNoSelectableMachines`,
    description: 'Machine no selectables explanation',
    defaultMessage: 'Non-selectable machines are already assigned to the notification.'
  },
  machineSelectedWithDifferentConfigurationFile: {
    id: `${namespace}.machineSelectedWithDifferentConfigurationFile`,
    description: 'Machine selected with different configuration file',
    defaultMessage:
      'To assign several machines, they must have the same configuration file. The selected machines have a different configuration file compared to the machines already assigned to the notification.'
  },
  machineSelectedWithoutConfigurationFile: {
    id: `${namespace}.machineSelectedWithoutConfigurationFile`,
    description: 'Machine selected without configuration file',
    defaultMessage:
      'The selected machines have no associated Configuration file. Choose at least one machine with configuraction file.'
  },
  deviceType: {
    id: `${namespace}.deviceType`,
    description: 'Device type table column header label',
    defaultMessage: 'Device type'
  },
  activeConfiguration: {
    id: `${namespace}.activeConfiguration`,
    description: 'Active configuration text',
    defaultMessage: 'Active configuration'
  },
  activeConfigurationVersion: {
    id: `${namespace}.activeConfigurationVersion`,
    description: 'Active configuration version text',
    defaultMessage: 'Active configuration version'
  },
  placeholder: {
    id: `${namespace}.placeholder`,
    description: 'Placeholder text',
    defaultMessage: 'Enter {column}'
  },
  selectPlaceholder: {
    id: `${namespace}.selectPlaceholder`,
    description: 'Select Placeholder text',
    defaultMessage: 'Select {column}'
  },
  machineModel: {
    id: `${namespace}.machineModel`,
    description: 'Machine model',
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    description: 'Machine type',
    defaultMessage: 'Machine type'
  },
  machineBrand: {
    id: `${namespace}.machineBrand`,
    description: 'Machine brand',
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    description: 'Machine serial number',
    defaultMessage: 'Machine serial number'
  },
  lastTelemetryMessage: {
    id: `${namespace}.lastTelemetryMessage`,
    description: 'Last telemetry message',
    defaultMessage: 'Last telemetry message'
  },
  firmwareVersion: {
    id: `${namespace}.firmwareVersion`,
    description: 'Firmware version',
    defaultMessage: 'Firmware version'
  },
  deviceConfiguration: {
    id: `${namespace}.deviceConfiguration`,
    description: 'Device configuration',
    defaultMessage: 'Device configuration'
  },
  deviceConfigurationVersion: {
    id: `${namespace}.deviceConfigurationVersion`,
    description: 'Device configuration version',
    defaultMessage: 'Device configuration version'
  }
})

export default messages
