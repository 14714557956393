import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  colorPrimary: {
    color: '#5d5d5d'
  }
}
const PortalLoading = ({ logo, classes, isMobileNotificationDetails }) => (
  <React.Fragment>
    {isMobileNotificationDetails && (
      <Helmet>
        <meta content='width=device-width, initial-scale=1' name='viewport' />
      </Helmet>
    )}
    <div className='partm-loading'>
      <div className='partm-loading-danfoss-logo'>
        <img height='100%' src={logo} />
      </div>
      <CircularProgress classes={{ colorPrimary: classes.colorPrimary }} />
    </div>
  </React.Fragment>
)

PortalLoading.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobileNotificationDetails: PropTypes.bool.isRequired,
  logo: PropTypes.string.isRequired
}

export default withStyles(styles)(PortalLoading)
