import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error400Message: {
    id: 'Groups.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'Groups.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'Groups.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'Groups.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'Groups.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'Groups.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  title: {
    id: 'Groups.title',
    description: 'Groups title',
    defaultMessage: 'Groups management'
  },
  addGroup: {
    id: 'Groups.addGroup',
    description: 'Add group button label',
    defaultMessage: 'Add group'
  },
  newGroup: {
    id: 'Groups.newGroup',
    description: 'New group button label',
    defaultMessage: 'New group'
  },
  groupUpdatedSuccessfully: {
    id: 'Groups.groupUpdatedSuccessfully',
    description: 'Group updated successfully text',
    defaultMessage: 'Group updated successfully.'
  },
  groupCreatedSuccessfully: {
    id: 'Groups.groupCreatedSuccessfully',
    description: 'Group created successfully text',
    defaultMessage: 'Group created successfully.'
  },
  groupDeletedSuccessfully: {
    id: 'Groups.groupDeletedSuccessfully',
    description: 'Group deleted successfully text',
    defaultMessage: 'Group deleted successfully.'
  },
  youCantDeleteYourOwnGroup: {
    id: 'Groups.youCantDeleteYourOwnGroup',
    description: 'You can\'t delete your own group text',
    defaultMessage: 'You can\'t delete your own group.'
  },
  errorDeletingGroup: {
    id: 'Groups.errorDeletingGroup',
    description: 'Error deleting group text',
    defaultMessage: 'Error deleting group'
  },
  cantDeleteGroupWithChildGroups: {
    id: 'Groups.cantDeleteGroupWithChildGroups',
    description: 'Can\'t delete group with child groups text',
    defaultMessage: 'Can\'t delete group with child groups.'
  },
  confirmGroupToDelete: {
    id: 'Groups.confirmGroupToDelete',
    description: 'Confirm group to delete text',
    defaultMessage: 'Confirm group to delete'
  },
  error412Message: {
    id: 'Groups.error412Message',
    description: 'Error 412 message',
    defaultMessage: 'Out of date'
  },
  error428Message: {
    id: 'Groups.error428Message',
    description: 'Error 428 message',
    defaultMessage: 'If-Match header required'
  },
  cancel: {
    id: 'Groups.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  delete: {
    id: 'Groups.delete',
    description: 'Delete button label',
    defaultMessage: 'Delete'
  },
  error: {
    id: 'Groups.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'Groups.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error409Message: {
    id: 'Groups.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Conflict'
  }
})

export default messages
