import PropTypes from 'prop-types'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'

import Divider from '@material-ui/core/Divider'

import PortalLoading from 'components/PortalLoading'
import { client } from 'utils/http'

import SSOSignInButton from './SSOSignInButton'
import SSOSignUp from './SSOSignUp'
import messages from './messages'
import {
  getDanfossPrivacyPolicyUrl,
  getDanfossTermsOfUseUrl,
  getDanfossGeneralInformationUrl,
  getDanfossProductOverviewUrl
} from './url'

class HomeView extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      isThemeLoading: true,
      theme: {}
    }
  }

  componentDidMount() {
    const { defaultTheme, location, history, setTheme } = this.props
    const [, groupPath] = location.pathname.split('/')
    let theme = {}
    client
      .getGroupThemeByLoginPath(groupPath)
      .then(({ data }) =>
        data.file ? client.getGroupThemeConfigUrl(data.groupId, data.hashId) : Promise.reject('No theme config file')
      )
      .then(({ data: url }) => client.getGroupThemeConfig(url))
      .then(response => {
        theme = { ...defaultTheme, ...response.data }
        setTheme(theme)
        const { groupId } = response.data
        sessionStorage.setItem('group_id', groupId)
        localStorage.setItem('group_id', groupId)
      })
      .catch(() => {
        if (groupPath === 'default') {
          theme = { ...defaultTheme }
          setTheme(theme)
          sessionStorage.removeItem('group_id')
          localStorage.removeItem('group_id')
        } else {
          history.push('/default')
        }
      })
      .finally(() => {
        this.setState({ isThemeLoading: false, theme })
      })
  }

  getFooterSponsorsHeight = (isGroupThemed, isSponsorsVisible) => {
    let footerSponsorsHeight = 0
    if (!isGroupThemed) {
      footerSponsorsHeight = isMobile ? 79 : 61
      if (isSponsorsVisible) footerSponsorsHeight += 120
    }
    return footerSponsorsHeight.toString() + 'px'
  }

  reopenCookies = event => {
    event.preventDefault()
    window.CookieConsent.renew()
  }

  renderThemedHome = () => {
    const { theme } = this.state
    const isGroupThemed = theme.loginPath && theme.loginPath !== 'default'
    const getDynamicClassName = className => {
      const suffix = isMobile ? '-mobile' : ''
      return className + suffix
    }
    const headerHeight = isMobile ? '66px' : '76px'
    const isSponsorsVisible =
      !isGroupThemed &&
      !isMobile &&
      (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'development')

    const footerSponsorsHeight = this.getFooterSponsorsHeight(isGroupThemed, isSponsorsVisible)

    return (
      <div
        className='partm-home'
        style={{
          '--home-border-top': theme.appBarColor,
          '--app-font-color': theme.fontColor || '#FFFFFF',
          '--footer-sponsors-height-lg': footerSponsorsHeight,
          '--home-title-font': isGroupThemed ? 'Roboto' : 'MyriadPro-bold',
          '--home-title-font-weight': isGroupThemed ? 500 : 700,
          '--home-text-font': isGroupThemed ? 'Roboto' : 'MyriadPro',
          '--header-height': headerHeight
        }}
      >
        <div className='partm-home-header'>
          <img alt='Danfoss' className='partm-home-header-logo' src={theme.logoBig} />
        </div>
        <div className='partm-home-diapo partm-home-diapo--only-content' style={{ overflow: 'hidden' }}>
          <div
            style={{
              zIndex: '0',
              position: 'absolute',
              inset: `${headerHeight} 0 0 0`
            }}
          >
            <img
              src={theme.loginImage}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </div>
          <div
            style={{
              backgroundColor: isGroupThemed ? 'transparent' : '#B6070F',
              position: 'absolute',
              width: '100%',
              height: '100%',
              mixBlendMode: 'multiply'
            }}
          />
          <div className='partm-home-diapo-text-block'>
            <h1 className={getDynamicClassName('partm-home-diapo-title')}>{theme.title}</h1>
            <p className={getDynamicClassName('partm-home-lead')}>{theme.loginText}</p>
            <div className={getDynamicClassName('partm-home-sign-up-text')}>
              <SSOSignInButton buttonText={this.formatMessage(messages.logIn)} classValue='partm-home-btn' />
              <div>
                <SSOSignUp buttonText={this.formatMessage(messages.signUp)} />
              </div>
            </div>
          </div>
        </div>
        {!isGroupThemed && (
          <div className='partm-home-footer' style={{ height: isMobile ? 79 : 61 }}>
            <div className='partm-home-footer-menu'>
              <a
                className='partm-home-footer-anchor'
                href={getDanfossPrivacyPolicyUrl()}
                rel='noopener noreferrer'
                target='_blank'
              >
                {this.formatMessage(messages.privacyPolicy)}
              </a>
              <Divider className={getDynamicClassName('partm-home-footer-divider')} orientation='vertical' />
              <a
                className='partm-home-footer-anchor'
                href={getDanfossTermsOfUseUrl()}
                rel='noopener noreferrer'
                target='_blank'
              >
                {this.formatMessage(messages.termsOfUse)}
              </a>
              <Divider className={getDynamicClassName('partm-home-footer-divider')} orientation='vertical' />
              <a
                className='partm-home-footer-anchor'
                href={getDanfossGeneralInformationUrl()}
                rel='noopener noreferrer'
                target='_blank'
              >
                {this.formatMessage(messages.generalInformation)}
              </a>
              <Divider className={getDynamicClassName('partm-home-footer-divider')} orientation='vertical' />
              <a className='partm-home-footer-anchor' href='#' onClick={this.reopenCookies}>
                {this.formatMessage(messages.cookies)}
              </a>
              <Divider className={getDynamicClassName('partm-home-footer-divider')} orientation='vertical' />
              <a
                className='partm-home-footer-anchor'
                href={getDanfossProductOverviewUrl()}
                rel='noopener noreferrer'
                target='_blank'
              >
                {this.formatMessage(messages.productOverview)}
              </a>
            </div>
          </div>
        )}
        {isSponsorsVisible && (
          <div className='container-fluid' style={{ background: 'white', height: 120 }}>
            <div className='col-xs-12' style={{ textAlign: 'center', paddingTop: '5px' }}>
              <span style={{ fontSize: 16, fontWeight: 'bold' }}>Sponsored by:</span>
            </div>
            <div className='col-xs-12' style={{ textAlign: 'center', paddingTop: '10px' }}>
              <div className='col-xs-6' style={{ textAlign: 'right', paddingRight: '20px' }}>
                <img
                  alt=''
                  src='/images/Hazitek.png'
                  style={{ display: 'inline-flex', height: '60px', textAlign: 'center' }}
                />
                <span style={{ fontSize: 14, display: 'block' }}>HAZITEK</span>
              </div>
              <div className='col-xs-6' style={{ textAlign: 'left', paddingLeft: '20px' }}>
                <img
                  alt=''
                  src='/images/Feder.png'
                  style={{ display: 'inline-flex', height: '60px', textAlign: 'center' }}
                />
                <span style={{ fontSize: 14, display: 'block' }}>FEDER</span>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    const { isThemeLoading } = this.state
    return isThemeLoading ? (
      <PortalLoading />
    ) : (
      <React.Fragment>
        <Helmet>
          <meta content='width=device-width, initial-scale=1' name='viewport' />
        </Helmet>
        {this.renderThemedHome()}
      </React.Fragment>
    )
  }
}

HomeView.propTypes = {
  defaultTheme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  setTheme: PropTypes.func.isRequired
}

export default injectIntl(HomeView)
