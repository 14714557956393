import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { actions, utils as configUtils } from 'ducks/configurationsCS500'
import { utils as groupUtils } from 'ducks/groups'
import { utils as template } from 'ducks/template'
import csNode from 'modules/csNode'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import EditConfigurationConnected from './EditConfiguration'
import { getCS500ConfigurationsUrl } from '../url'

const mapStateToProps = (state, { match, history }) => ({
  canEditDevice: resolvePermissions(state, c.DEVICE_EDIT),
  configState: configUtils.getState(state),
  configurationsUrl: getCS500ConfigurationsUrl(),
  device: csNode.utils.getCSNodeByEID(state, match.params.deviceEid),
  deviceEid: match.params.deviceEid,
  groupId: groupUtils.getSelectedGroup(state).id,
  isSidebarCollapsed: template.isCollapsed(state),
  onEditConfiguration: () => history.push(getCS500ConfigurationsUrl())
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addLocalConfigurationCANMessage: actions.addLocalConfigurationCANMessage,
      addLocalConfigurationSignal: actions.addLocalConfigurationSignal,
      getDeviceByEid: csNode.actions.fetchCSNodeByEid,
      getDeviceNonVolatileConfiguration: csNode.actions.fetchDeviceNonVolatileConfiguration,
      localConfigurationError: actions.localConfigurationError,
      resetLocalConfiguration: actions.resetLocalConfiguration,
      setDeviceNonVolatileConfiguration: csNode.actions.setDeviceNonVolatileConfiguration,
      setLocalConfiguration: actions.setLocalConfiguration,
      getNewestNVConfigurationDefinition: actions.fetchNewestNVConfigurationDefinition
    },
    dispatch
  )

const EditConfiguration = withRouter(connect(mapStateToProps, mapDispatchToProps)(EditConfigurationConnected))

export { EditConfiguration }
