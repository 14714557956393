import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import CloseIcon from '@material-ui/icons/Close'

import Alert from 'components/Alert'
import { logError } from 'utils/http'

import messages from './messages'

class ActivateDataPlanDialog extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      selectedDeviceId: '',
      loading: false,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  handleChange = event => {
    this.setState({
      selectedDeviceId: event.target.value
    })
  }

  handleActivateDataPlan = () => {
    this.setState(
      {
        loading: true
      },
      () => {
        const { devices, activateDataPlan, dataPlanId, groupId } = this.props
        const { selectedDeviceId } = this.state
        const { eid } = devices.find(device => device.id === selectedDeviceId)
        activateDataPlan(groupId, dataPlanId, eid)
          .then(() => {
            const { onClose, updateDevices, onActivateDSP } = this.props
            onClose()
            updateDevices()
            onActivateDSP()
          })
          .catch(response => {
            const { error } = { ...response }
            if (error && error.response) {
              switch (error.response.status) {
                case 400:
                case 401:
                case 403:
                case 404:
                case 406:
                case 409:
                case 415:
                case 422:
                case 500:
                case 510:
                  this.setState({
                    loading: false,
                    alertMessages: true,
                    alertMessagesType: 'danger',
                    alertMessagesTitle: this.formatMessage(messages.error, {
                      number: error.response.status.toString()
                    }),
                    alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
                  })
                  break
                default:
                  this.setState({
                    loading: false,
                    alertMessages: true,
                    alertMessagesType: 'danger',
                    alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                    alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
                  })
                  logError(error)
              }
            } else {
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(error)
            }
          })
      }
    )
  }

  handleExitedDialog = () => {
    this.setState({
      selectedDeviceId: '',
      loading: false,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  render() {
    const { loading, selectedDeviceId, alertMessages, alertMessagesType, alertMessagesText, alertMessagesTitle } =
      this.state
    const { dSPDeviceType, devices, open, dataPlanName, onClose } = this.props

    const activationButtonDisabled = loading || selectedDeviceId === '' || alertMessages

    const deviceType = dSPDeviceType
    const filteredDevices = devices.filter(device => device.device_type === deviceType)

    return (
      <Dialog disableBackdropClick fullWidth maxWidth='md' onExited={this.handleExitedDialog} open={open}>
        <DialogTitle>
          <Grid alignItems='center' container>
            <Grid item xs={10}>
              {this.formatMessage(messages.title, { planName: dataPlanName })}
            </Grid>
            <Grid container item justify='flex-end' xs={2}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12}>
              {alertMessages ? (
                <Alert
                  alertType={alertMessagesType}
                  messageText={alertMessagesText}
                  messageTitle={alertMessagesTitle}
                  showAlert={alertMessages}
                />
              ) : (
                <FormControl fullWidth>
                  <InputLabel htmlFor='device-label-placeholder' shrink>
                    {this.formatMessage(messages.device)}
                  </InputLabel>
                  <Select
                    displayEmpty
                    input={<Input id='selectedDeviceId-label-placeholder' name='selectedDeviceId' />}
                    name='selectedDeviceId'
                    onChange={this.handleChange}
                    value={selectedDeviceId}
                  >
                    <MenuItem key={0} value=''>
                      {this.formatMessage(messages.selectExistingDevice)}
                    </MenuItem>
                    {filteredDevices.map(device => {
                      return (
                        <MenuItem key={device.id} value={device.id}>
                          {device.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className='primary-action-button'
            disabled={activationButtonDisabled}
            onClick={this.handleActivateDataPlan}
          >
            <span>{this.formatMessage(messages.activate)}</span>
            {loading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ActivateDataPlanDialog.propTypes = {
  activateDataPlan: PropTypes.func.isRequired,
  dSPDeviceType: PropTypes.string,
  dataPlanId: PropTypes.string.isRequired,
  dataPlanName: PropTypes.string.isRequired,
  devices: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onActivateDSP: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updateDevices: PropTypes.func.isRequired
}

ActivateDataPlanDialog.defaultProps = {
  dSPDeviceType: 'CS100'
}

export default injectIntl(ActivateDataPlanDialog)
