import translations from 'i18n/locales'

import { SIGNEDNESS_VALID_VALUES, ENDIANNESS_VALID_VALUES } from './config'

export const getOptionsForGeneralSettings = {
  baudRate: [
    { value: 0, label: '50 kbps' },
    { value: 1, label: '100 kbps' },
    { value: 2, label: '125 kbps' },
    { value: 3, label: '250 kbps' },
    { value: 4, label: '500 kbps' },
    { value: 5, label: '1000 kbps' }
  ]
}

export const getOptionsForMFIOItem = () => {
  const i18n = translations[localStorage.getItem('user_language')]
  return {
    inputSignal0: [
      { value: 0, label: i18n['ConfigsCS500.voltageOption'] },
      { value: 1, label: i18n['ConfigsCS500.digitalOption'] },
      { value: 2, label: i18n['ConfigsCS500.frequencyOption'] },
      { value: 3, label: i18n['ConfigsCS500.resistanceOption'] },
      { value: 4, label: i18n['ConfigsCS500.currentOption'] }
    ],
    inputSignal1: [
      { value: 0, label: i18n['ConfigsCS500.voltageOption'] },
      { value: 1, label: i18n['ConfigsCS500.digitalOption'] },
      { value: 2, label: i18n['ConfigsCS500.frequencyOption'] },
      { value: 3, label: i18n['ConfigsCS500.resistanceOption'] },
      { value: 4, label: i18n['ConfigsCS500.currentOption'] }
    ],
    inputSignal2: [
      { value: 0, label: i18n['ConfigsCS500.voltageOption'] },
      { value: 1, label: i18n['ConfigsCS500.digitalOption'] },
      { value: 2, label: i18n['ConfigsCS500.frequencyOption'] }
    ],
    inputSignal3: [
      { value: 0, label: i18n['ConfigsCS500.voltageOption'] },
      { value: 1, label: i18n['ConfigsCS500.digitalOption'] },
      { value: 2, label: i18n['ConfigsCS500.frequencyOption'] }
    ],
    bias: [
      { value: 0, label: 'No pull-up/down' },
      { value: 1, label: 'Pull-up to 5 V' },
      { value: 2, label: 'Pull-down to GND' },
      { value: 3, label: 'Pull-up to 5 V, pull-down' }
    ],
    inputRange: [
      { value: 0, label: '0 - 5,25 V' },
      { value: 1, label: '0 - 35,3 V' },
      { value: 2, label: '0 - 0,3675 V' },
      { value: 3, label: '0 - 2,465 V' }
    ]
  }
}

export const getOptionsForDM1 = () => {
  const i18n = translations[localStorage.getItem('user_language')]

  return {
    dm1Port: [
      { value: 1, label: i18n['ConfigsCS500.port0Option'] },
      { value: 2, label: i18n['ConfigsCS500.port1Option'] },
      { value: 3, label: i18n['ConfigsCS500.lookAtBothPortsOption'] }
    ]
  }
}

export const getOptionsForMachineStateDetermination = () => {
  const i18n = translations[localStorage.getItem('user_language')]

  return {
    canPort: [
      { value: 0, label: i18n['ConfigsCS500.port0Option'] },
      { value: 1, label: i18n['ConfigsCS500.port1Option'] }
    ],
    digitalInputPin: [
      { value: 0, label: 'C1P05' },
      { value: 1, label: 'C1P06' }
    ]
  }
}

export const getOptionsForCANMessageItem = () => {
  const i18n = translations[localStorage.getItem('user_language')]

  return {
    canPort: [
      { value: 0, label: i18n['ConfigsCS500.port0Option'] },
      { value: 1, label: i18n['ConfigsCS500.port1Option'] }
    ],
    dataSource: [
      { value: 255, label: i18n['ConfigsCS500.setBytesDirectlyOption'] },
      { value: 0, label: i18n['ConfigsCS500.selectVirtualSignalOption'] },
      { value: 240, label: i18n['ConfigsCS500.gnssPositionOption'] }
    ],
    periodType: [
      { value: 0, label: i18n['ConfigsCS500.sendNeverOption'] },
      { value: 1, label: i18n['ConfigsCS500.setSendPeriodOption'] }
    ],
    triggerType: [
      { value: 0, label: i18n['ConfigsCS500.noTriggerOption'] },
      { value: 1, label: i18n['ConfigsCS500.aboveThresholdOption'] },
      { value: 2, label: i18n['ConfigsCS500.belowThresholdOption'] },
      { value: 3, label: i18n['ConfigsCS500.risingEdgeOption'] },
      { value: 4, label: i18n['ConfigsCS500.fallingEdgeOption'] },
      { value: 5, label: i18n['ConfigsCS500.betweenThresholdsOption'] },
      { value: 6, label: i18n['ConfigsCS500.outsideThresholdsOption'] },
      { value: 7, label: i18n['ConfigsCS500.matchOption'] },
      { value: 8, label: i18n['ConfigsCS500.onChangeOption'] }
    ]
  }
}

export const getOptionsForSignalItem = () => {
  const i18n = translations[localStorage.getItem('user_language')]

  return {
    // Signal source: defines from which port the data shall be taken
    signalSource: [
      { value: 1, label: i18n['ConfigsCS500.canJ1939Option'] },
      { value: 2, label: i18n['ConfigsCS500.canCustomizedOption'] },
      { value: 3, label: i18n['ConfigsCS500.mfioOption'] }
    ],
    cch_J1939_custom: [
      { value: 0, label: i18n['ConfigsCS500.port0Option'] },
      { value: 1, label: i18n['ConfigsCS500.port1Option'] },
      { value: 2, label: i18n['ConfigsCS500.lookAtBothPortsOption'] }
    ],
    cch_MFIO: [
      { value: 0, label: '1' },
      { value: 1, label: '2' },
      { value: 2, label: '3' },
      { value: 3, label: '4' }
    ],
    unitType: [
      { value: 0, label: i18n['ConfigsCS500.predefinedUnitType'] },
      { value: 1, label: i18n['ConfigsCS500.customUnitType'] }
    ],
    unit: [
      { value: 0, label: '' },
      { value: 8, label: '%' },
      { value: 9, label: 'A' },
      { value: 13, label: 'Ah' },
      { value: 28, label: 'dB' },
      { value: 29, label: 'dBm' },
      { value: 32, label: 'g/min' },
      { value: 23, label: 'h' },
      { value: 27, label: 'Hz' },
      { value: 10, label: 'kA' },
      { value: 5, label: 'kg' },
      { value: 30, label: 'kg/s' },
      { value: 31, label: 'kJ/l' },
      { value: 2, label: 'km' },
      { value: 1, label: 'km/h' },
      { value: 25, label: 'kN' },
      { value: 3, label: 'kPa' },
      { value: 12, label: 'kV' },
      { value: 16, label: 'kW' },
      { value: 18, label: 'kWh' },
      { value: 4, label: 'l' },
      { value: 7, label: 'l/h' },
      { value: 22, label: 'min' },
      { value: 20, label: 'ms' },
      { value: 24, label: 'N' },
      { value: 26, label: 'Nm' },
      { value: 19, label: 'rpm' },
      { value: 21, label: 's' },
      { value: 11, label: 'V' },
      { value: 14, label: 'VA' },
      { value: 15, label: 'W' },
      { value: 17, label: 'Wh' },
      { value: 6, label: 'ºC' }
    ],
    filterType: [
      { value: 0, label: i18n['ConfigsCS500.noFilterOption'] },
      { value: 1, label: i18n['ConfigsCS500.lowPassOption'] },
      { value: 2, label: i18n['ConfigsCS500.highPassOption'] },
      { value: 3, label: i18n['ConfigsCS500.bandPassOption'] },
      { value: 4, label: i18n['ConfigsCS500.notchOption'] },
      { value: 5, label: i18n['ConfigsCS500.peakOption'] },
      { value: 6, label: i18n['ConfigsCS500.lastMinMaxAvgOption'] },
      { value: 7, label: i18n['ConfigsCS500.serialDifferenceOption'] }
    ],
    triggerType: [
      { value: 0, label: i18n['ConfigsCS500.noTriggerOption'] },
      { value: 1, label: i18n['ConfigsCS500.aboveThresholdOption'] },
      { value: 2, label: i18n['ConfigsCS500.belowThresholdOption'] },
      { value: 3, label: i18n['ConfigsCS500.risingEdgeOption'] },
      { value: 4, label: i18n['ConfigsCS500.fallingEdgeOption'] },
      { value: 5, label: i18n['ConfigsCS500.betweenThresholdsOption'] },
      { value: 6, label: i18n['ConfigsCS500.outsideThresholdsOption'] },
      { value: 7, label: i18n['ConfigsCS500.matchOption'] },
      { value: 8, label: i18n['ConfigsCS500.onChangeOption'] }
    ],
    logType: [
      { value: 1, label: i18n['ConfigsCS500.logNeverOption'] },
      { value: 2, label: i18n['ConfigsCS500.setSamplePeriodOption'] }
    ],
    canIDType: [
      { value: false, label: 'Standard CAN ID (11 bit)' /*i18n['ConfigsCS500.standardCANID']*/ },
      { value: true, label: 'Extended CAN ID (29 bit)' /*i18n['ConfigsCS500.extendedCANID']*/ }
    ],
    endianness: [
      { value: ENDIANNESS_VALID_VALUES.LITTLE_ENDIAN, label: i18n['ConfigsCS500.littleEndian'] || 'Little endian' },
      { value: ENDIANNESS_VALID_VALUES.BIG_ENDIAN, label: i18n['ConfigsCS500.bigEndian'] || 'Big endian' }
    ],
    signedness: [
      { value: SIGNEDNESS_VALID_VALUES.UNSIGNED, label: i18n['ConfigsCS500.unsigned'] || 'Unsigned' },
      { value: SIGNEDNESS_VALID_VALUES.SIGNED, label: i18n['ConfigsCS500.signed'] || 'Signed' }
    ]
  }
}

export const getUnitForLimits = inputSignal => {
  switch (inputSignal) {
    case 0:
      return ' (mV)'
    case 1:
      return ' (mV)'
    case 2:
      return ' (Hz)'
    case 3:
      return ' (Ohm)'
    case 4:
      return ' (µA)'
    default:
      return ''
  }
}
