import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import { ReactComponent as AverageSignalIcon } from 'icons/average-signal.svg'
import { ReactComponent as ExcellentSignalIcon } from 'icons/excellent-signal.svg'
import { ReactComponent as GoodSignalIcon } from 'icons/good-signal.svg'
import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as NoSignalIcon } from 'icons/no-signal.svg'
import { ReactComponent as PoorSignalIcon } from 'icons/poor-signal.svg'

import messages from './messages'

const styles = () => ({
  tooltip: {
    backgroundColor: '#212121'
  },
  arrow: {
    color: '#212121'
  }
})

const MOBILE_SIGNAL_QUALITY_CATEGORIES = {
  NO_SIGNAL: 'noSignal',
  POOR: 'poor',
  AVERAGE: 'average',
  GOOD: 'good',
  EXCELLENT: 'excellent',
  GREAT: 'great',
  UNKNOWN: 'unknown'
}

const MobileSignal = ({ intl, classes, data }) => {
  const { formatMessage } = intl

  const { accessTechnologyLong = '', accessTechnologyShort = '', mobileSignalQuality = 0 } = data
  const mobileSignalQualityCategory =
    data.mobileSignalQualityCategory === null ? 'Unknown' : data.mobileSignalQualityCategory || 'No signal'

  const mobileSignalQualityCategoryId = mobileSignalQualityCategory
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase()
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join('')

  let signalStrengthIcon = null
  switch (mobileSignalQualityCategoryId) {
    case MOBILE_SIGNAL_QUALITY_CATEGORIES.POOR:
      signalStrengthIcon = <PoorSignalIcon style={{ marginRight: 5 }} />
      break
    case MOBILE_SIGNAL_QUALITY_CATEGORIES.AVERAGE:
      signalStrengthIcon = <AverageSignalIcon style={{ marginRight: 5 }} />
      break
    case MOBILE_SIGNAL_QUALITY_CATEGORIES.GOOD:
      signalStrengthIcon = <GoodSignalIcon style={{ marginRight: 5 }} />
      break
    case MOBILE_SIGNAL_QUALITY_CATEGORIES.EXCELLENT:
    case MOBILE_SIGNAL_QUALITY_CATEGORIES.GREAT:
      signalStrengthIcon = <ExcellentSignalIcon style={{ marginRight: 5 }} />
      break
    case MOBILE_SIGNAL_QUALITY_CATEGORIES.UNKNOWN:
      signalStrengthIcon = null
      break
    case MOBILE_SIGNAL_QUALITY_CATEGORIES.NO_SIGNAL:
    default:
      signalStrengthIcon = <NoSignalIcon style={{ marginRight: 5 }} />
      break
  }

  const tooltipMessage = `${formatMessage(
    messages[mobileSignalQualityCategoryId]
  )} (${mobileSignalQuality}dB) - ${accessTechnologyLong}`

  return (
    <React.Fragment>
      {signalStrengthIcon}
      {mobileSignalQualityCategoryId === MOBILE_SIGNAL_QUALITY_CATEGORIES.NO_SIGNAL ? 
        formatMessage(messages[mobileSignalQualityCategoryId])
        : (
          <React.Fragment>
            {formatMessage(messages[mobileSignalQualityCategoryId])} - {accessTechnologyShort}{' '}
            <Tooltip
              arrow
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              placement='right'
              title={tooltipMessage}
            >
              <InfoIcon />
            </Tooltip>
          </React.Fragment>
        )}
    </React.Fragment>
  )
}

MobileSignal.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(MobileSignal))
